import React, { useState } from 'react';
import styled from 'styled-components';

import { ColorCircle } from './ColorCircle';
import HexColorPicker from './HexColorPicker';
import Chevron, { ChevronDirection } from '../Chevron';
import { useInnerField } from '../Field';
import { Popover } from '../Popover';

import { IconButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const StyledButton = styled(IconButton)`
    padding: 6px 7px;
`;

const StyledInlineHexPicker = styled(HexColorPicker)`
    width: 200px;
    padding: 16px;
`;

interface Props extends DataAutomationSupportProps {
    /**
     * The initial color of the color picker - must be in hex format,
     * starting with # and having 6 or 8 chars
     */
    value?: Color;

    /**
     * Function that will run everytime the color changes
     * @param newColor - The new color
     */
    onChange?(newColor: Color): void;

    /**
     * Name to pass to the form field
     */
    name?: string;

    /**
     * The aria label for the picker open button
     */
    ariaLabel?: string;

    /**
     * Is picker with alpha
     */
    alpha?: boolean;

    /**
     * Is the picker disabled
     */
    disabled?: boolean;

    /**
     * Is the picker flat
     */
    flat?: boolean;

    /**
     * Should we hid all background color
     */
    noBackgroundColor?: boolean;

    showBorder?: boolean;

    className?: string;
}

const PopoverHexColorPicker: React.FC<Props> = ({
    onChange: onChangeProps,
    alpha = false,
    ariaLabel = 'open color picker',
    disabled,
    dataAutomation,
    flat,
    noBackgroundColor,
    showBorder = false,
    className,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [{ value: fieldValue, id: fieldId, name: fieldName }] = useInnerField({
        type: 'color',
        multiple: false,
        ...props,
    });

    return (
        <Popover
            show={isOpen}
            onClose={() => setIsOpen(false)}
            content={
                <StyledInlineHexPicker
                    id={fieldId}
                    name={fieldName}
                    value={fieldValue}
                    onChange={onChangeProps}
                    alpha={alpha}
                    dataAutomation={`${dataAutomation}-input`}
                />
            }
            placement="bottom-end"
            noPadding
        >
            <StyledButton
                flat={flat}
                noBackgroundColor={noBackgroundColor}
                showBorder={showBorder}
                aria-label={ariaLabel}
                rightIcon={<Chevron direction={ChevronDirection.DOWN} />}
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                data-automation={dataAutomation}
                className={className}
            >
                <ColorCircle $selectedColor={fieldValue} />
            </StyledButton>
        </Popover>
    );
};

export default PopoverHexColorPicker;
