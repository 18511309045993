import TonkeanType from './TonkeanType';

const typeGuard = <T extends Partial<Record<TonkeanType, string>>>(obj: T) => obj;

const tonkeanTypeToIdentifyingPrefixMap = typeGuard({
    [TonkeanType.INITIATIVE]: 'INIT',
    [TonkeanType.GROUP]: 'GRUP',
    [TonkeanType.CUSTOM_TRIGGER]: 'CUTR',
    [TonkeanType.PROJECT_INTEGRATION]: 'PRIN',
    [TonkeanType.INTEGRATION]: 'INTG',
    [TonkeanType.PROJECT]: 'PROJ',
    [TonkeanType.PERSON]: 'PRSN',
    [TonkeanType.AUTH_TOKEN]: 'ATKN',
    [TonkeanType.COMMENT]: 'CMNT',
    [TonkeanType.ACTIVITY]: 'ACTI',
    [TonkeanType.ORGANIZATION]: 'ORGN',
    [TonkeanType.WORKFLOW_VERSION]: 'WOVE',
    [TonkeanType.FUNCTION]: 'FNCT',
    [TonkeanType.PROJECT_INTEGRATION_ACTION]: 'PIAC',
    [TonkeanType.FIELD]: 'FILD',
    [TonkeanType.FIELD_DEFINITION]: 'FIDE',
    [TonkeanType.INITIATIVE_LINK]: 'INLI',
    [TonkeanType.SYNC_CONFIG]: 'SYCO',
    [TonkeanType.SCHEDULED_REPORT]: 'SCRE',
    [TonkeanType.OUTGOING_WEBHOOK]: 'OGWH',
    [TonkeanType.INCOMING_WEBHOOK]: 'INWE',
    [TonkeanType.WORKER_RUN]: 'WORU',
    [TonkeanType.ENTERPRISE]: 'ETRS',
    [TonkeanType.FORM]: 'FORM',
    [TonkeanType.ADMIN]: 'ADMN',
    [TonkeanType.TEMPLATE]: 'TMPL',
    [TonkeanType.API_JOB]: 'APJO',
    [TonkeanType.CATEGORY]: 'CATE',
    [TonkeanType.TRAINED_KEYWORD]: 'TRKE',
    [TonkeanType.WORKFLOW_FOLDER]: 'WOFO',
    [TonkeanType.BACKGROUND_PROCESS]: 'BAPR',
    [TonkeanType.OPERATION_NODE]: 'OPNO',
    [TonkeanType.OPERATION_CONFIGURATION]: 'OPCO',
    [TonkeanType.OPERATION_EDGE]: 'OPED',
    [TonkeanType.SOLUTION_BUSINESS_REPORT]: 'SOBR',
    [TonkeanType.ITEM_INTERFACE]: 'ITIN',
    [TonkeanType.ITEM_INTERFACE_WIDGET]: 'IIWI',
    [TonkeanType.ENTERPRISE_COMPONENT_VARIABLE]: 'ECVR',
    [TonkeanType.PEOPLE_DIRECTORY]: 'PEDI',
    [TonkeanType.TRAINING_SET]: 'TRSE',
    [TonkeanType.ENTERPRISE_COMPONENT_VARIABLE_VALUE]: 'ECVV',
    [TonkeanType.PROJECT_INTEGRATION_ENTITY]: 'PIEN',
    [TonkeanType.PROJECT_INTEGRATION_ENTITY_FIELD_METADATA]: 'PIFM',
    [TonkeanType.PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG]: 'PISE',
    [TonkeanType.WORKFLOW_FOLDER_CATEGORY]: 'WOCA',
    [TonkeanType.SOLUTION_MAPPER]: 'SOMA',
    [TonkeanType.PROCESS_PARTICIPANT]: 'PRPA',
    [TonkeanType.MARKETPLACE_ITEM]: 'MAIT',
    [TonkeanType.SOLUTION_SITE]: 'SOSI',
    [TonkeanType.SOLUTION_SITE_PAGE]: 'SSPA',
    [TonkeanType.ENTITY_VERSION]: 'ENVE',
    [TonkeanType.ITEM_DETAILS]: 'ITDE',
    [TonkeanType.PROCESS_MAPPER]: 'PRMA',
    [TonkeanType.PROCESS_MAPPER_NODE]: 'PMNO',
    [TonkeanType.PROCESS_MAPPER_EDGE]: 'PMED',
    [TonkeanType.CONTRACT]: 'CONT',
    [TonkeanType.CONTRACT_FIELD]: 'COFI',
} as const);

export default tonkeanTypeToIdentifyingPrefixMap;
