import React from 'react';
import styled from 'styled-components';

import NotFoundLogoUrl from '../../../../apps/tracks/images/icons/404.svg?url';

import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.main`
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 36px;

    font-size: ${FontSize.XXXXLARGE_24};
    line-height: 1.2;
    color: ${Theme.colors.promotion};
`;

interface Props {
    text: string;
    dataAutomation?: string;
}

const NotFoundPage: React.FC<Props> = ({ text, dataAutomation }) => {
    return (
        <Wrapper>
            <Content data-automation={dataAutomation}>
                <img src={NotFoundLogoUrl} />
                {text}
            </Content>
        </Wrapper>
    );
};

export default NotFoundPage;
