import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TriggerDisabledIcon } from '../../../images/trigger-disabled.svg';
import { Tooltip } from '../TUI';

import { colorSvg } from '@tonkean/utils';

const StyledTriggerDisabledIcon = styled(TriggerDisabledIcon)`
    ${colorSvg('#f0771e')};
`;

interface Props {
    show?: boolean;
}

const DataNotUpToDateIndicator: React.FC<Props> = ({ show }) => {
    return (
        <>
            {show && (
                <Tooltip content="This module is turned off. Data is not up to date.">
                    <StyledTriggerDisabledIcon />
                </Tooltip>
            )}
        </>
    );
};

export default DataNotUpToDateIndicator;
