import type { AngularServices } from 'angulareact';

import { FieldDefinitionTargetType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

function fieldsClassifier(
    workflowVersionId: string,
    customTriggerManager: any,
    fieldDefinitions: any[],
    syncConfigCacheManager: AngularServices['syncConfigCacheManager'],
) {
    const viewDataEntity = syncConfigCacheManager.getSyncConfig(workflowVersionId)?.viewData;
    const formulaSymbol = Symbol('formula');
    const customFieldsSymbol = Symbol('customFields');
    const rootItemSymbol = Symbol('rootItem');
    const fieldsFromCustomTriggerSymbol = Symbol('fieldsFromCustomTriggerSymbol');
    const idRelatedFieldsSymbol = Symbol('idRelatedFieldsSymbol');
    const directlyIdRelationSymbol = Symbol('directlyIdRelationSymbol');
    const globalFieldsSymbol = Symbol('globalFields');

    const {
        [formulaSymbol]: formulaFields = [],
        [customFieldsSymbol]: customFields = [],
        [rootItemSymbol]: rootItemFields = [],
        [fieldsFromCustomTriggerSymbol]: linkedCustomTriggerFields = [],
        [idRelatedFieldsSymbol]: idRelatedFields = [],
        [globalFieldsSymbol]: globalFields = [],
    } = utils.groupBy(fieldDefinitions, (field) => {
        // Group formulas fields
        if (field?.definition?.formulaType) {
            return formulaSymbol;
        }

        // Group all manual created matched entities
        if (field?.definition?.matchConfiguration?.idRelationFieldDefinitionId) {
            return idRelatedFieldsSymbol;
        }

        // Group fields by custom trigger id they are related to
        if (field.linkedCustomTrigger?.id || field?.definition?.matchConfiguration?.creatingCustomTriggerId) {
            return fieldsFromCustomTriggerSymbol;
        }

        // Everything else is custom field related
        if (field.idRelationField) {
            return idRelatedFieldsSymbol;
        }

        // Group root fields by entity type
        if (field?.definition?.ExternalType && field?.definition?.ExternalType === viewDataEntity?.entity) {
            return rootItemSymbol;
        }

        // Group root fields by entity type
        if (field?.targetType === FieldDefinitionTargetType.GLOBAL) {
            return globalFieldsSymbol;
        }

        return customFieldsSymbol;
    });

    const idRelationFieldsDefinitionIdToFields = utils.groupBy(idRelatedFields, (field: any) => {
        return field?.definition?.matchConfiguration?.idRelationFieldDefinitionId || field.id;
    });

    const fieldFromCustomTriggers = utils.groupBy(linkedCustomTriggerFields, (field: any) => {
        return field.linkedCustomTrigger?.id || field?.definition?.matchConfiguration?.creatingCustomTriggerId;
    });

    return {
        formulaFields,
        idRelationFieldsDefinitionIdToFields,
        fieldFromCustomTriggers,
        customFields,
        rootItemFields,
        globalFields,
    };
}

export default fieldsClassifier;
