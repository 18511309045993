enum WorkerRunLogicInspectTabType {
    MANUAL_NLP = 'MANUAL_NLP',
    TRAINING_SET_NLP = 'TRAINING_SET_NLP',
    PEOPLE_DIRECTORY = 'PEOPLE_DIRECTORY',
    REQUEST_JSON = 'REQUEST_JSON',
    RESPONSE_JSON = 'RESPONSE_JSON',
    FIELDS = 'FIELDS',
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
}

export default WorkerRunLogicInspectTabType;
