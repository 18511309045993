/**
 * Function to generate className for react (or anywhere else)
 * conditionally.
 *
 * @example
 * const isError = false;
 * const isWarning = true;
 * classNames('dialog', isError && 'error', { warning: isWarning });
 * // Returns 'dialog warning'
 *
 * @param names - a string, a falsy value, an object with boolean
 * as values or an array with falsy values and strings.
 * @returns a string that filters out the falsy values from the
 * arguments, objects and arrays.
 */
export function classNames(...names: className[]): string {
    return names
        .flatMap((name) => {
            const type = typeof name;

            // If the value is falsy, filter it out.
            if (!name) {
                return false;
            }

            // If name is a string or a number (IDK why it would
            // be, but OK), it will simply return it.
            if (type === 'string' || type === 'number') {
                return name.toString();
            }
            // If name is an array, it will execute the function
            // recursively to evaluate each item.
            if (Array.isArray(name)) {
                return classNames(...name);
            }
            // If name is an object, first it will filter out the
            // items with the falsy values, and then return an
            // array from their keys, which will be flatten because
            // it's flatMap.
            if (type === 'object') {
                return Object.entries(name)
                    .filter(([, val]) => !!val)
                    .map(([key]) => key);
            }
            // If it's type is unknown, it will be considered falsy.
            return false;
        })
        .filter(Boolean)
        .join(' ');
}

type className = string | false | null | undefined | Record<string, boolean | null | undefined> | className[];
