import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div<{ inert?: string | null }>`
    position: relative;
`;

const Blocker = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
`;
const BlockerBackground = styled.div`
    background: ${Theme.colors.gray_600};
    opacity: 0.1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const BlockerMessage = styled.div`
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 70%;
    padding: 10px 15px;
    text-align: center;
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 8px;
    z-index: 1; // Make it higher than the background
`;

interface Props extends StyledComponentsSupportProps {
    blocked?: boolean;
    message?: string;
    productionMessage?: boolean;
    noMessage?: boolean;
}
const BlockedElement: React.FC<React.PropsWithChildren<Props>> = ({
    blocked = true,
    message,
    className,
    productionMessage,
    noMessage,
    children,
}) => {
    return (
        <Wrapper inert={blocked ? '' : null} className={className}>
            {children}
            {blocked && (
                <Blocker>
                    {!noMessage && (
                        <BlockerMessage>
                            {message ||
                                (productionMessage && 'Move to build environment to edit') ||
                                'This section is blocked from being edited'}
                        </BlockerMessage>
                    )}
                    <BlockerBackground />
                </Blocker>
            )}
        </Wrapper>
    );
};

export default BlockedElement;
