import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import type React from 'react';
import { useContext, useEffect } from 'react';

import FieldContext from './FieldContext';
import useUUID from '../../../hooks/useUUID';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { EMPTY_ARRAY } from '@tonkean/utils';

// List of field types that when clicking on the label it should NOT focus on the field.
const notLabelFocusingTypes = new Set(['radio']);

interface Options {
    name?: string;
    // the type can be any tag type, for example - 'checkbox' | 'radio' | 'select'
    type: string;
    multiple: boolean;

    // Needed if used outside of formik:
    id?: string;
    value?: any;
    initialValue?: 0 | [] | '' | null;
    checked?: boolean;

    onChange?(event: React.ChangeEvent<any>): void;

    onBlur?(event: React.FocusEvent<any>): void;

    mapError?: (error: any) => string | undefined;
}

function useInnerField({
    name,
    type,
    multiple,
    value: valueProp,
    initialValue = multiple ? EMPTY_ARRAY : '',
    checked: checkedProp,
    onBlur: onBlurProp,
    onChange: onChangeProp,
    id: idProp,
    mapError = (error) => error,
}: Options) {
    const formik = useContext(FormikContext) as FormikContextType<unknown> | undefined;
    const field = useContext(FieldContext);

    const defaultId = useUUID();
    const id = idProp ?? defaultId;

    // Register formik field
    const formikRegisterField = formik?.registerField;
    const formikUnregisterField = formik?.unregisterField;
    useEffect(() => {
        // If no name was passed, it means it's not part of the formik form.
        if (name) {
            formikRegisterField?.(name, {});

            return () => formikUnregisterField?.(name);
        }
    }, [formikRegisterField, formikUnregisterField, name]);

    const formikFieldProps = name
        ? formik?.getFieldProps({
              name,
              type,
              multiple,
              value: valueProp,
          })
        : undefined;
    const formikFieldMeta = name ? formik?.getFieldMeta(name) : undefined;
    const formikFieldHelpers = name ? formik?.getFieldHelpers(name) : undefined;

    const canShowFormikError = !field?.touchedOnlyErrors || formikFieldMeta?.touched;
    const showFormikError = canShowFormikError ? formikFieldMeta?.error : undefined;

    const constantRefMapError = useConstantRefCallback(mapError);
    // Register field with Field component
    const addField = field?.addField;
    useEffect(() => {
        const labelFocusing = !notLabelFocusingTypes.has(type);
        const removeFunc = addField?.({
            id,
            labelFocusing,
            error: constantRefMapError(showFormikError),
        });

        return removeFunc;
    }, [addField, constantRefMapError, id, showFormikError, type]);

    // Create props for the component.
    const onBlur = useCompositeEventCallback(onBlurProp, formikFieldProps?.onBlur);
    const onChange = useCompositeEventCallback(onChangeProp, formikFieldProps?.onChange);
    // If formik is not used, we will use the value from the props.
    const value = valueProp ?? formikFieldProps?.value ?? initialValue;
    const checked = checkedProp ?? formikFieldProps?.checked;
    const props = { ...formikFieldProps, checked, value, onBlur, onChange, id, name };

    const hasError = field?.fieldInError || !!showFormikError;

    return [props, hasError, formikFieldHelpers, formikFieldMeta] as const;
}

export default useInnerField;
