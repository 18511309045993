import React from 'react';
import styled, { css } from 'styled-components';

import Input from './Input/Input';

import { ClickableButton } from '@tonkean/tui-buttons/Button';
import type { ClickableProps } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ContentWrapper = styled.div`
    display: flex;
`;

const InputWrapper = styled(Input)<{ $isLeftButton: boolean }>`
  direction: ltr;

  ${({ $isLeftButton }) =>
      $isLeftButton
          ? css`
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            `
          : css`
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            `}
}`;

const StyledClickableButton = styled(ClickableButton)<{ $isLeftButton: boolean }>`
  border: 1px solid ${Theme.colors.gray_400};

  ${({ $isLeftButton }) =>
      $isLeftButton
          ? css`
                border-right: none;
                border-radius: 3px 0 0 3px;
            `
          : css`
                border-left: none;
                border-radius: 0 3px 3px 0;
            `}
}`;

enum InputWithButtonSize {
    /*
     * Sadly Only medium button height = medium input height
     */
    MEDIUM,
}

interface Props {
    /**
     * button props.
     */
    buttonClickableProps: ClickableProps;
    /**
     * The button direction.
     */
    buttonDirection?: 'rtl' | 'ltr';
    /**
     * height of the input with button.
     */
    size?: InputWithButtonSize;
    /**
     * On every input change.
     */
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * The text which will be used in the button.
     */
    buttonText?: string;
    /**
     * Optional input placeholder.
     */
    placeholder?: string;
    /**
     * Optional input value.
     */
    inputValue?: string;
    /**
     * Should the input be disabled.
     */
    disabled?: boolean;
}

/**
 * UI component for displaying an input component next to button component. [input][button].
 * Common use of this component is for when you need to to output some text into an input and then
 * give the user the ability to copy the content.
 */
const InputWithButton: React.FC<Props> = ({
    buttonClickableProps,
    buttonDirection = 'rtl',
    onInputChange,
    inputValue = '',
    placeholder = '',
    buttonText = 'Send',
    // Sadly Only medium button height equals the medium input height
    size = InputWithButtonSize.MEDIUM,
    disabled = false,
}) => {
    const isLeftButton = buttonDirection === 'ltr';
    return (
        <ContentWrapper dir={buttonDirection}>
            <StyledClickableButton
                $isLeftButton={isLeftButton}
                {...buttonClickableProps}
                size={ButtonSize.MEDIUM}
                highlighted
            >
                {buttonText}
            </StyledClickableButton>

            <InputWrapper
                $isLeftButton={isLeftButton}
                size={InputSize.MEDIUM}
                onChange={onInputChange}
                value={inputValue}
                placeholder={placeholder}
                disabled={disabled}
            />
        </ContentWrapper>
    );
};

export default InputWithButton;
