import InputSize from './InputSize';

export const CheckboxComponentSizesConst = [
    InputSize.SMALL,
    InputSize.MEDIUM,
    InputSize.MEDIUM_LARGE,
    InputSize.LARGE,
    InputSize.XLARGE,
] as const;
export type CheckboxComponentSizes = (typeof CheckboxComponentSizesConst)[number];
