import React, { useRef } from 'react';
import type ReactDatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import type { DatepickerProps } from './DatePicker/Datepicker';
import Datepicker from './DatePicker/Datepicker';
import { Input as TUIInput } from './Input';
import { ReactComponent as DatepickerCalendarIcon } from '../../../images/datepicker-calendar-2.svg';
import { ReactComponent as ModalXIcon } from '../../../images/modal-x.svg';

import { Theme } from '@tonkean/tui-theme';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const Wrapper = styled.div<{ disabled?: boolean; size?: InputComponentSizes }>`
    display: flex;
    overflow: hidden;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: ${({ size }) => (size ? `${Theme.sizes.input[size].radius}px` : '3px')};

    ${({ disabled }) =>
        !disabled &&
        css`
            &:focus,
            &:focus-within {
                border-color: ${Theme.colors.focus};
            }
        `}
`;

const Input = styled(TUIInput)<{ size?: InputComponentSizes }>`
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    border: none !important;

    ${({ size }) =>
        size && size === InputSize.LARGE
            ? css`
                  border-radius: 6px 0 0 6px !important;
                  margin-bottom: -2px;
              `
            : css`
                  padding: 0 10px;
                  height: 32px;
              `}

    &:hover,
    &:focus {
        border: none !important;
        outline: none !important;
    }
`;

const ClearButton = styled.button<{ size?: InputComponentSizes }>`
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    border: none;
    display: grid;
    align-items: center;
    .tnk-icon {
        line-height: 0;
        display: inline-block;
    }

    ${({ size }) =>
        size && size === InputSize.LARGE
            ? css`
                  width: 22px;
                  background: ${Theme.colors.white};

                  .tnk-icon svg {
                      height: 16px;
                      width: 16px;
                  }
              `
            : css`
                  width: 15px;
                  background: transparent;
                  .tnk-icon svg {
                      height: 12px;
                      width: 12px;
                  }
              `}

    .tnk-icon svg {
        path {
            fill: #cccccc;
        }
    }
`;

const TriggerButton = styled.button<{ size?: InputComponentSizes }>`
    flex-shrink: 0;
    border: none;
    align-items: center;

    ${({ size }) =>
        size && size === InputSize.LARGE
            ? css`
                  background: ${Theme.colors.white};
                  padding: 6px 6px 0 0;
              `
            : css`
                  width: 15px;
                  margin: 0;
                  padding: 0;
                  background: transparent;
                  display: grid;
                  .tnk-icon {
                      line-height: 0;
                      display: inline-block;
                  }

                  width: 26px;
                  background: ${Theme.colors.HEX_F2F3F6};
              `}
`;

const CustomInput: React.ForwardRefRenderFunction<
    HTMLInputElement,
    {
        value?: string;
        onClick?(): void;
        onClear(): void;
        setFocus(): void;
        size?: React.ComponentPropsWithoutRef<typeof TUIInput>['size'];
        disabled?: boolean;
        customButton?: React.ReactNode;
    }
> = ({ value, onClick, onClear, setFocus, customButton, ...props }, ref) => {
    const onTriggerClick = () => {
        onClick?.();
        setFocus();
    };

    return (
        <Wrapper disabled={props.disabled} size={props.size}>
            <Input value={value} onClick={onClick} {...props} ref={ref} size={props.size} />

            {value && !props.disabled && (
                <ClearButton type="button" onClick={onClear} size={props.size}>
                    <span className="tnk-icon">
                        <ModalXIcon />
                    </span>
                </ClearButton>
            )}

            <TriggerButton type="button" onClick={onTriggerClick} className="date-picker-button" size={props.size}>
                {customButton || (
                    <span className="tnk-icon">
                        <DatepickerCalendarIcon />
                    </span>
                )}
            </TriggerButton>
        </Wrapper>
    );
};
const CustomInputForwardRef = React.forwardRef(CustomInput);

const DatepickerInput = <SelectRange extends boolean = false>(
    {
        dataAutomation,
        ...props
    }: DatepickerProps<SelectRange> &
        DataAutomationSupportProps & {
            size?: React.ComponentPropsWithoutRef<typeof TUIInput>['size'];
        },
    ref: React.ForwardedRef<HTMLInputElement>,
) => {
    const datepickerRef = useRef<ReactDatePicker>(null);

    const onClear = () => {
        datepickerRef.current?.['clear']();
    };

    const setFocus = () => {
        datepickerRef.current?.setFocus();
    };

    return (
        <Datepicker
            {...props}
            customInput={
                <CustomInputForwardRef
                    data-automation={dataAutomation}
                    onClear={onClear}
                    setFocus={setFocus}
                    ref={ref}
                    size={props.size}
                    disabled={props.disabled}
                    customButton={props.customButton}
                />
            }
            ref={datepickerRef}
        />
    );
};

type ComponentType = <SelectRange extends boolean = false>(
    props: DatepickerProps<SelectRange> &
        DataAutomationSupportProps &
        React.RefAttributes<HTMLInputElement> & {
            size?: React.ComponentPropsWithoutRef<typeof TUIInput>['size'];
        },
) => React.ReactElement;

export default React.forwardRef(DatepickerInput) as ComponentType;
