import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg';

import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TextStyle = styled.div`
    color: ${Theme.colors.gray_600};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

interface Props {
    setState: React.Dispatch<React.SetStateAction<number>>;
    state: number;
    minState?: number;
    max: number;
    text?: string;
}

const PageBrowser: React.FC<Props> = ({ text = 'Page', state, setState, minState = 1, max }) => {
    return (
        <Wrapper>
            <IconButton
                disabled={state === minState}
                onClick={() => {
                    setState((oldState) => oldState - 1);
                }}
                flat
            >
                <ArrowLeft />
            </IconButton>
            <TextStyle>
                {text} {state}/{max}
            </TextStyle>

            <IconButton
                disabled={state === max}
                onClick={() => {
                    setState((oldState) => oldState + 1);
                }}
                flat
            >
                <ArrowRight />
            </IconButton>
        </Wrapper>
    );
};

export default PageBrowser;
