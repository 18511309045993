import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../../../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../images/arrow-right.svg';

import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-md, 8px);

    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
`;

interface NavigationCarouselProps {
    current: number;
    total: number;
    onNextClick(): void;
    onPreviousClick(): void;
}
const NavigationCarousel: React.FC<NavigationCarouselProps> = ({ current, total, onNextClick, onPreviousClick }) => {
    const shouldShowNavigation = !!total;

    const currentItemNotFoundInList = current === 0;
    if (currentItemNotFoundInList) {
        return <></>;
    }

    return shouldShowNavigation ? (
        <Wrapper>
            <IconButton onClick={onPreviousClick} disabled={current === 1} aria-label="Go to previous item" flat>
                <ArrowLeft />
            </IconButton>
            {current}
            {total > 0 ? `/${total}` : ''}
            <IconButton onClick={onNextClick} disabled={current === total} aria-label="Go to next item" flat>
                <ArrowRight />
            </IconButton>
        </Wrapper>
    ) : (
        <></>
    );
};

export default NavigationCarousel;
