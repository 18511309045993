import type CollectParameter from '../ProjectIntegrationEntity/CollectParameter';
import type TonkeanExpressionDefinition from '../TonkeanExpressionDefinition';

export enum ContainsCriteriaType {
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
}

interface ContainsCriteria {
    type: ContainsCriteriaType;
    keywords: string[];
}

interface PaginationDefinition {
    nextPageParameters: CollectParameter[];
    enabled: boolean;
    stopCriteria: ContainsCriteria;
    stopCriteriaExpression: TonkeanExpressionDefinition;
}

export default PaginationDefinition;
