import styled, { css } from 'styled-components';

export enum PlaceholderGridDirection {
    COLUMNS = 'columns',
    ROWS = 'rows',
}

const oppositeGridDirection: { [key in PlaceholderGridDirection]: Exclude<PlaceholderGridDirection, key> } = {
    [PlaceholderGridDirection.COLUMNS]: PlaceholderGridDirection.ROWS,
    [PlaceholderGridDirection.ROWS]: PlaceholderGridDirection.COLUMNS,
};

const PlaceholderGrid = styled.div<{ direction: PlaceholderGridDirection; gap?: string; template?: string }>`
    flex-grow: 1;

    display: grid;
    grid-gap: ${({ gap = '6px' }) => gap};
    grid-auto-flow: ${({ direction }) => direction};
    grid-template-${({ direction }) => oppositeGridDirection[direction]}: 1fr;
    align-items: center;
    justify-content: start;

    ${({ direction, template }) =>
        template &&
        css`
            grid-template-${direction}: ${template};
        `}
`;

export default PlaceholderGrid;
