import React from 'react';
import type { MultiValueProps } from 'react-select';
import { components } from 'react-select';

import TextEllipsis from '../../TextEllipsis';

const CustomMultiValueContainerSelection: React.FC<React.PropsWithChildren<MultiValueProps>> = ({
    children,
    ...props
}) => {
    return (
        <components.MultiValue {...props}>
            <TextEllipsis numberOfLines={1} tooltip>
                {children}
            </TextEllipsis>
        </components.MultiValue>
    );
};

export default CustomMultiValueContainerSelection;
