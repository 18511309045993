import { useSyncExternalStore } from 'react';

function subscribeToVisibilityChange(onStoreChange: () => void) {
    document.addEventListener('visibilitychange', onStoreChange);
    return () => document.removeEventListener('visibilitychange', onStoreChange);
}

// Using this to subscribe to visibility change events. This is a workaround for the fact that the is Tab Active is not inside the React tree.
// use useIsTabActive in order to get the 'IsTabActive' value of the tab
function useSyncIsTabActiveFromDocument() {
    return useSyncExternalStore(
        subscribeToVisibilityChange,
        () => document.visibilityState === 'visible',
        () => true,
    );
}

export default useSyncIsTabActiveFromDocument;
