import React from 'react';

import type FieldOptions from './FieldOptions';

interface FieldContext {
    addField(options: FieldOptions): () => void;
    touchedOnlyErrors: boolean;
    // If error passed to the field component, notify the inputs.
    fieldInError: boolean;
}

const FieldContext = React.createContext<FieldContext | undefined>(undefined);

export default FieldContext;
