export const FindWordsExtractionTypeToMessage: Record<FindWordsExtractionType, string> = {
    NO_ARBITRARY_ANCHORS: 'Keywords mismatch',
    NOT_FOUND_IN_DOCUMENT_COLLECTION: 'Not found in data collections',
    NOT_EXTRACTED: 'Nothing extracted',
    NO_MATCH: 'No Match found',
    MATCH: 'Success',
};

enum FindWordsExtractionType {
    NO_ARBITRARY_ANCHORS = 'NO_ARBITRARY_ANCHORS',
    NOT_FOUND_IN_DOCUMENT_COLLECTION = 'NOT_FOUND_IN_DOCUMENT_COLLECTION',
    NOT_EXTRACTED = 'NOT_EXTRACTED',
    NO_MATCH = 'NO_MATCH',
    MATCH = 'MATCH',
}

export default FindWordsExtractionType;
