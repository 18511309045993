import React from 'react';
import { components } from 'react-select';
import type { MultiValueRemoveProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled from 'styled-components';

import { XIcon } from '@tonkean/svg';
import type { PersonSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const StyledXIcon = styled(XIcon)`
    width: 8px;
    height: 8px;
    margin-right: 8px;
    display: flex;
    &:hover {
        ${colorSvg(Theme.colors.gray_600)}
    }
`;

const MainContainer = styled.div`
    align-self: center;
`;

const CustomMultiValueRemove: React.FC<MultiValueRemoveProps<PersonSummary, boolean, GroupBase<PersonSummary>>> = (
    props,
) => {
    if (props.selectProps.isDisabled) {
        return null;
    }
    return (
        <MainContainer data-automation="custom-multi-value-remove-element">
            <components.MultiValueRemove<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
                <StyledXIcon />
            </components.MultiValueRemove>
        </MainContainer>
    );
};
export default CustomMultiValueRemove;
