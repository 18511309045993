import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const ZebraTableBodyRow = styled.tr<{ $skipOddColor?: boolean }>`
    display: flex;

    ${({ $skipOddColor = false }) =>
        !$skipOddColor &&
        css`
            &:nth-child(odd) {
                background-color: ${Theme.colors.brightestGray};
            }
        `}

    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

export default ZebraTableBodyRow;
