import React, { useCallback } from 'react';

import Tab from './Tab';
import Tabs from './Tabs';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

export enum ModuleBrowserSolutionBoxTabsState {
    MODULES = 'modules',
    REPORTS = 'reports',
    SITES = 'sites',
    PROCESS = 'process',
    PROCESS_BUILDER = 'process_builder',
}

interface Props extends StyledComponentsSupportProps {
    value?: ModuleBrowserSolutionBoxTabsState;

    onChange(workflowFolderId: string, value: ModuleBrowserSolutionBoxTabsState): void;

    workflowFolderId: string;
}

const ModuleTabs: React.FC<Props> = ({
    value = ModuleBrowserSolutionBoxTabsState.MODULES,
    onChange: onChangeProps,
    workflowFolderId,
    className,
}) => {
    const onChange = useCallback(
        (newValue) => onChangeProps(workflowFolderId, newValue),
        [onChangeProps, workflowFolderId],
    );

    return (
        <Tabs value={value} onChange={onChange} className={className} showBackground={false}>
            <Tab label={ModuleBrowserSolutionBoxTabsState.MODULES}>Modules</Tab>
            <Tab label={ModuleBrowserSolutionBoxTabsState.REPORTS}>Business Reports</Tab>
        </Tabs>
    );
};

export default ModuleTabs;
