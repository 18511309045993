enum FailedNlpMatchingReason {
    NO_MATCH = 'NO_MATCH',
    MATCHED_NEGATIVE_EXAMPLES = 'MATCHED_NEGATIVE_EXAMPLES',
    ARBITRARY_ANCHORS = 'ARBITRARY_ANCHORS',
}

export const FailedNlpMatchingReasonToDisplayName: Record<FailedNlpMatchingReason, string> = {
    [FailedNlpMatchingReason.NO_MATCH]: 'No match found',
    [FailedNlpMatchingReason.MATCHED_NEGATIVE_EXAMPLES]: 'Negative examples found',
    [FailedNlpMatchingReason.ARBITRARY_ANCHORS]: 'Did not match pre-defined keywords definition',
};

export default FailedNlpMatchingReason;
