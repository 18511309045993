import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TonkeanIcon } from '../../images/tonkean.svg';

import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled(Clickable)`
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${Theme.colors.gray_700};
    background-color: ${Theme.colors.basicBackground};

    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 6px;

    width: 180px;
    height: 28px;
    padding: 4px 16px;

    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        width: 73px;
        height: 20px;

        position: unset;
        top: unset;
        left: unset;
    }
`;

interface Props extends StyledComponentsSupportProps {}

const PoweredByTonkean: React.FC<Props> = ({ className }) => {
    return (
        <Wrapper
            className={className}
            aria-label="Launch tonkean.com"
            href="https://tonkean.com/"
            rel="noopener"
            openInNewTab
        >
            Powered By
            <TonkeanIcon />
        </Wrapper>
    );
};

export default PoweredByTonkean;
