import React, { useLayoutEffect, useRef } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled, { css } from 'styled-components';

import { MODAL_Z_INDEX } from '../Modal/ModalWrapper';

import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { TooltipColor } from '@tonkean/tui-theme/colors';
import type { TooltipSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const tooltipTheme = Theme.current.palette.TUI.tooltip;
const tooltipSizesTheme = Theme.sizes.tooltip;

// Should be the same as the modal. It will be added after it in the dom order, so it will be above it, but if a
// new modal will open, it will cover it, as expected.
const TOOLTIP_Z_INDEX = MODAL_Z_INDEX;
const ANIMATION_DURATION = 200;

const TooltipArrow = styled.div<{ tooltipColor: TooltipColor; tooltipSize: TooltipSize }>`
    &,
    ::before {
        position: absolute;
        width: ${({ tooltipSize }) => tooltipSizesTheme[tooltipSize].arrowSize}px;
        height: ${({ tooltipSize }) => tooltipSizesTheme[tooltipSize].arrowSize}px;
        z-index: -1;
    }

    ::before {
        content: '';
        display: block;
        transform: rotate(45deg);

        ${({ tooltipColor }) => css`
            background-color: ${tooltipTheme[tooltipColor].backgroundColor};
            border: ${tooltipTheme[tooltipColor].borderWidth}px solid ${tooltipTheme[tooltipColor].borderColor};
        `}
    }
`;

const TooltipWrapper = styled.div<{
    tooltipColor: TooltipColor;
    tooltipSize: TooltipSize;
    popover: boolean;
    inline: boolean;
    limitWidth?: number;
}>`
    ${({ inline }) => {
        if (inline) {
            return css`
                display: inline-flex;
            `;
        }

        return css`
            position: absolute;
            display: flex;
        `;
    }};

    border-radius: 8px;
    z-index: ${TOOLTIP_Z_INDEX};

    ${({ limitWidth }) =>
        limitWidth &&
        css`
            max-width: ${limitWidth}vw;
        `};

    ${({ tooltipColor }) => css`
        background-color: ${tooltipTheme[tooltipColor].backgroundColor};
        border: ${tooltipTheme[tooltipColor].borderWidth}px solid ${tooltipTheme[tooltipColor].borderColor};
        box-shadow: ${tooltipTheme[tooltipColor].boxShadow};
    `}

    ${({ popover }) =>
        !popover &&
        css`
            /* Tooltip should not be clickable nor focusable. To prevent triggering  onClickOutside , we are blocking */
            /* pointer events from the tooltip - so clicking on it will click on what s behind it. */
            pointer-events: none;
        `}
    
    &.tooltip-animation-enter, &.tooltip-animation-appear {
        opacity: 0;
    }

    &.tooltip-animation-enter-active,
    &.tooltip-animation-appear-active {
        transition: opacity ${ANIMATION_DURATION}ms;
        opacity: 1;
    }

    &.tooltip-animation-exit {
        opacity: 1;
    }

    &.tooltip-animation-exit-active {
        transition: opacity ${ANIMATION_DURATION}ms;
        opacity: 0;
    }

    ${({ tooltipSize, tooltipColor }) => {
        // Only the dark has a border
        const borderSize = tooltipTheme[tooltipColor].borderWidth;
        const arrowDistance = tooltipSizesTheme[tooltipSize].arrowSize / 2 + borderSize;

        return css`
            &[data-popper-placement^='top'] > ${TooltipArrow} {
                bottom: -${arrowDistance}px;
                left: calc(50% - ${arrowDistance}px);

                ::before {
                    border-top: none;
                    border-left: none;
                }
            }

            &[data-popper-placement^='bottom'] > ${TooltipArrow} {
                top: -${arrowDistance}px;
                left: calc(50% - ${arrowDistance}px);

                ::before {
                    border-bottom: none;
                    border-right: none;
                }
            }

            &[data-popper-placement^='left'] > ${TooltipArrow} {
                right: -${arrowDistance}px;
                top: calc(50% - ${arrowDistance}px);

                ::before {
                    border-bottom: none;
                    border-left: none;
                }
            }

            &[data-popper-placement^='right'] > ${TooltipArrow} {
                left: -${arrowDistance}px;
                top: calc(50% - ${arrowDistance}px);

                ::before {
                    border-right: none;
                    border-top: none;
                }
            }
        `;
    }}

    &:focus {
        outline: none;
    }

    ${childrenStyledFocus};
`;

const ContentWrapper = styled.div<{
    tooltipColor: TooltipColor;
    tooltipSize: TooltipSize;
    popover: boolean;
    textAlignLeft?: boolean;
    noPadding: boolean;
    limitWidth?: number;
    width?: string;
    overflowVisible: boolean;
}>`
    font-size: ${FontSize.SMALL_12};
    overflow: ${({ overflowVisible }) => (overflowVisible ? 'visible' : 'auto')};
    color: ${({ tooltipColor }) => tooltipTheme[tooltipColor].textColor};
    padding: ${({ tooltipSize, noPadding }) =>
        noPadding
            ? 0
            : `${tooltipSizesTheme[tooltipSize].paddingTopBottom}px ${tooltipSizesTheme[tooltipSize].paddingRightLeft}px`};

    ${({ limitWidth }) =>
        limitWidth &&
        css`
            overflow-wrap: break-word;
        `};

    ${({ popover, textAlignLeft }) =>
        !popover &&
        !textAlignLeft &&
        css`
            text-align: center;
        `}

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `};
`;

interface Props extends StyledComponentsSupportProps, Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
    shown?: boolean;
    popperStyles?: React.CSSProperties;
    popperAttributes?: Record<string, string>;
    setArrowElement?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    arrowStyles?: React.CSSProperties;
    showArrow: boolean;
    color: TooltipColor;
    size: TooltipSize;
    width?: string;
    textAlignLeft?: boolean;
    popover?: boolean;
    inline?: boolean;
    noPadding?: boolean;
    limitWidth?: number;
    overflowVisible?: boolean;

    onExited?(): void;
    onExit?(): void;
    onExiting?(): void;
    onEnter?(): void;
    onEntering?(): void;
    onEntered?(): void;
}

const TooltipElement: React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<Props>> = (
    {
        className,
        popperStyles,
        popperAttributes,
        setArrowElement,
        arrowStyles,
        showArrow,
        color,
        size,
        width,
        textAlignLeft = false,
        inline = false,
        noPadding = false,
        popover = false,
        shown = true,
        overflowVisible = false,
        children,
        limitWidth,
        onExited,
        onExit,
        onExiting,
        onEnter,
        onEntering,
        onEntered,
        ...props
    },
    ref,
) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);

    const sharedWrapperRefCallback = useMultipleRefCallback(wrapperRef, ref);

    useLayoutEffect(() => {
        setArrowElement?.(showArrow ? arrowRef.current : null);
    }, [setArrowElement, showArrow]);

    return (
        <CSSTransition
            classNames="tooltip-animation"
            nodeRef={wrapperRef}
            in={shown}
            timeout={ANIMATION_DURATION}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            appear
            unmountOnExit
        >
            {/* The onClick is to prevent from bubbling the onClick event to the JSX parent element - react portal forwards events to the JSX parent even if they are in a different DOM hierarchy. */}
            {/* eslint-disable-next-line styled-components-a11y/click-events-have-key-events,styled-components-a11y/no-static-element-interactions */}
            <TooltipWrapper
                style={popperStyles}
                {...popperAttributes}
                tooltipSize={size}
                tooltipColor={color}
                popover={popover}
                inline={inline}
                onClick={(event) => event.stopPropagation()}
                {...props}
                tabIndex={-1}
                limitWidth={limitWidth}
                ref={sharedWrapperRefCallback}
            >
                <ContentWrapper
                    className={className}
                    tooltipSize={size}
                    tooltipColor={color}
                    popover={popover}
                    noPadding={noPadding}
                    textAlignLeft={textAlignLeft}
                    width={width}
                    overflowVisible={overflowVisible}
                    data-automation="tooltip-element-content"
                >
                    {children}
                </ContentWrapper>
                {showArrow && (
                    <TooltipArrow style={arrowStyles} tooltipSize={size} tooltipColor={color} ref={arrowRef} />
                )}
            </TooltipWrapper>
        </CSSTransition>
    );
};

export default React.forwardRef(TooltipElement);
