import styled from 'styled-components';

interface Props {
    height?: number;
    width?: number;
}

const Spacer = styled.div<Props>`
    min-height: ${({ height }) => (height ? `${height}px` : 0)};
    min-width: ${({ width }) => (width ? `${width}px` : 0)};
`;

export default Spacer;
