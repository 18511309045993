import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckedCheckboxIcon } from '../../../../images/checked-checkbox.svg';
import Chevron, { ChevronDirection } from '../Chevron';
import { useInnerField } from '../Field';
import { Popover } from '../Popover';

import { DisableableButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.colors;

const Wrapper = styled(DisableableButton)`
    height: 26px;
    width: 40px;
    padding: 0 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 3px;

    cursor: pointer;
    background-color: transparent;

    .tnk-icon {
        display: inline-flex;
    }

    &:hover {
        background-color: ${theme.gray_300};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${theme.brightestGray};
        `}
`;

const ColorIcon = styled.button<{
    $isSelected: boolean;
    $isWhite: boolean;
}>`
    width: 20px;
    height: 20px;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    border: ${({ $isWhite }) => ($isWhite ? `1px solid ${theme.gray_500}` : 'none')};
    background-color: ${({ color }) => color};
    border-radius: 50%;
    padding: 0;

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            transform: scale(1.2);
        `};

    &:hover {
        ${({ $isSelected }) =>
            !$isSelected &&
            css`
                transform: scale(1.2);
            `};
    }

    &:focus {
        ${({ $isSelected, theme }) =>
            !$isSelected &&
            theme.isKeyboardFocus &&
            css`
                transform: scale(1.2);
            `};
    }

    .checkmark-icon {
        height: 100%;
        width: 100%;

        .tnk-icon {
            padding: ${({ $isWhite, $isSelected }) => ($isWhite && $isSelected ? '3px 4px 4px' : '4px')};
            display: inline-block;
            height: 100%;
            width: 100%;

            svg path {
                ${({ $isWhite }) =>
                    $isWhite &&
                    css`
                        stroke: ${theme.gray_800};
                    `}
            }
        }
    }
`;

interface ColorDisplayProps {
    isWhite: boolean;
}

const ColorDisplay = styled.span<ColorDisplayProps>`
    height: 16px;
    width: 16px;
    border: ${({ isWhite }) => (isWhite ? `1px solid ${theme.gray_500}` : 'none')};
    border-radius: 50%;
    background-color: ${({ color }) => color};
`;

const Colors = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    grid-gap: 8px;
    gap: 8px;
`;

export const defaultFormColors = {
    primaryColor: Theme.current.palette.colorPicker.HEX_34393E,
    secondaryColor: Theme.current.palette.colorPicker.HEX_39B3D6,
};

const defaultColors = [
    ...Object.values(defaultFormColors),
    Theme.current.palette.colorPicker.HEX_FF5454,
    Theme.current.palette.colorPicker.HEX_F0611E,
    Theme.current.palette.colorPicker.HEX_FFCA7A,
    Theme.current.palette.colorPicker.HEX_12D4B8,
    Theme.current.palette.colorPicker.HEX_20C5F5,
    Theme.current.palette.colorPicker.HEX_2F84DC,
    Theme.current.palette.colorPicker.HEX_605CE4,
    Theme.current.palette.colorPicker.HEX_D76BD9,
    Theme.current.palette.colorPicker.HEX_FFFFFF,
    Theme.current.palette.colorPicker.HEX_5B636C,
];

export interface ColorPickerProps {
    colors?: Color[];
    value?: Color;
    onChange?: (color: Color) => void;
    disabled?: boolean;
    name?: string;
}

const GridColorPicker: React.FC<ColorPickerProps> = ({
    colors = defaultColors,
    value: pickedColorProp,
    onChange: onColorChosen,
    disabled = false,
    name,
}) => {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const [fieldProps, , formikHelpers] = useInnerField({
        value: pickedColorProp,
        name,
        type: 'color',
        multiple: false,
    });

    const onColorClick = (newColor: Color) => {
        onColorChosen?.(newColor);
        setPopoverOpen(false);
        formikHelpers?.setValue(newColor);
    };

    const pickedColor = fieldProps.value;

    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            placement="bottom-start"
            content={
                <Colors>
                    {colors.map((color) => (
                        <ColorIcon
                            data-automation="color-picker-icon"
                            data-automation-color={color}
                            color={color}
                            key={color}
                            onClick={() => onColorClick(color)}
                            $isSelected={pickedColor?.toUpperCase() === color.toUpperCase()}
                            $isWhite={color?.toUpperCase() === '#FFFFFF'}
                        >
                            {color === pickedColor && (
                                <span className="checkmark-icon">
                                    <span className="tnk-icon">
                                        <CheckedCheckboxIcon />
                                    </span>
                                </span>
                            )}
                        </ColorIcon>
                    ))}
                </Colors>
            }
            showArrow={false}
        >
            <Wrapper
                onClick={() => setPopoverOpen(true)}
                disabled={disabled}
                data-automation="color-picker-open-colors-options"
                data-automation-selected-color={pickedColor}
                type="button"
            >
                <ColorDisplay color={pickedColor} isWhite={pickedColor?.toUpperCase() === '#FFFFFF'} />
                <Chevron direction={popoverOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
            </Wrapper>
        </Popover>
    );
};

export default GridColorPicker;
