import React from 'react';

export interface ButtonToggleGroupContextValue {
    toggleValue: (key: string) => void;
    // The current state of the values, if doesnt key doesnt exist it should be treated as unchecked
    valuesState: Record<string, { value: any; checked: boolean }>;
}

const ButtonToggleGroupContext = React.createContext<ButtonToggleGroupContextValue>({
    toggleValue: () => {},
    valuesState: {},
});

export default ButtonToggleGroupContext;
