import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const SideMenuContainer = styled.div`
    background-color: ${Theme.colors.gray_100};
    border-right: 1px solid ${Theme.colors.gray_300};
    height: 100%;

    padding-top: 24px;
    padding-left: 14px;
    width: 126px;
`;

export default SideMenuContainer;
