/**
 * Represents the status result of an overview validation step
 */
enum EnterpriseComponentOverviewStepStatus {
    VALID = 'VALID',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    EMPTY = 'EMPTY',
}

export default EnterpriseComponentOverviewStepStatus;
