import React from 'react';
import styled, { css } from 'styled-components';

import type { Color } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

export enum MessageType {
    INFO,
    ERROR,
    WARNING,
    SUCCESS,
}

const messageTypeToTheme: Record<MessageType, { background: Color; text: Color; border: Color }> = {
    [MessageType.ERROR]: {
        background: 'rgba(255, 84, 84, 0.05)',
        text: Theme.colors.error,
        border: 'rgba(255, 84, 84, 0.2)',
    },
    [MessageType.WARNING]: {
        background: 'rgba(255, 178, 122, 0.1)',
        text: Theme.colors.warning,
        border: 'rgba(255, 178, 122, 0.4)',
    },
    [MessageType.INFO]: {
        background: Theme.colors.naturalInfoMessage,
        text: Theme.colors.gray_800,
        border: 'rgba(253, 233, 181, 0.8)',
    },
    [MessageType.SUCCESS]: {
        background: Theme.colors.successBright,
        text: Theme.colors.gray_700,
        border: 'rgba(83, 202, 185, 0.2)',
    },
};

const Wrapper = styled.div<{ type: MessageType }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid ${({ type }) => messageTypeToTheme[type].border};
    border-radius: 4px;
    min-height: 28px;
    padding: 8px 12px;
    background-color: ${({ type }) => messageTypeToTheme[type].background};
`;
const HeaderWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    .tnk-icon {
        display: block;
        width: 14px;
        height: 14px;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    svg {
        width: 14px;
        height: 14px;
    }
`;

const Icon = styled.span`
    flex-shrink: 0;
    display: inline-grid;
    margin-right: 6px;
`;

const Text = styled.span<{ $type: MessageType; $bold?: boolean }>`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${({ $type }) => messageTypeToTheme[$type].text};
    overflow-wrap: break-word;

    ${({ $bold }) =>
        $bold &&
        css`
            font-weight: 500;
        `}
`;
const SubTitle = styled.span`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    icon?: React.ReactNode;
    type?: MessageType;
    subTitle?: string;
}

const Message: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    dataAutomation,
    icon,
    type = MessageType.INFO,
    className,
    subTitle,
}) => {
    return (
        <Wrapper data-automation={dataAutomation} type={type} className={className}>
            <HeaderWrapper>
                {icon && <Icon>{icon}</Icon>}
                <Text $type={type} $bold={!!subTitle} data-automation="message-status-error">
                    {children}
                </Text>
            </HeaderWrapper>

            {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Wrapper>
    );
};

export default Message;
