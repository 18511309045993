import React from 'react';
import styled, { css } from 'styled-components';

import useToggle from '../../hooks/useToggle';
import CollapsibleContent from '../CollapsibleContent';
import Chevron, { ChevronDirection } from '../TUI/Chevron';

import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const StyledClickableRow = styled(Clickable)<{ $hasLowerNode: boolean; $isFirstNode: boolean; $isOpen: boolean }>`
    width: 100%;
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
    display: inline-flex;
    min-height: 40px;
    line-height: 40px;

    border-radius: 3px;

    ${({ $isFirstNode }) =>
        !$isFirstNode &&
        css`
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        `}

    ${({ $hasLowerNode, $isOpen }) =>
        ($hasLowerNode || $isOpen) &&
        css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `}

  ${({ $hasLowerNode, $isOpen }) =>
        $hasLowerNode &&
        !$isOpen &&
        css`
            border-bottom: none;
        `}
`;

const ContentWrapper = styled.div<{ $hasLowerNode: boolean; $defaultBackgroundColor: boolean }>`
    width: 100%;

    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 0 0 3px 3px;
    border-top: none;

    ${({ $hasLowerNode }) =>
        $hasLowerNode &&
        css`
            border-bottom: none;
            border-radius: 0 0;
        `}

    ${({ $defaultBackgroundColor }) =>
        $defaultBackgroundColor &&
        css`
            background-color: ${Theme.colors.gray_100};
        `}
`;

const StyledChevron = styled(Chevron)<{ $position: 'left' | 'right' }>`
    height: 100%;
    margin: ${({ $position }) =>
        `auto ${$position === 'right' ? 15 : 10}px auto ${$position === 'left' ? '15px' : 'auto'}`};
`;

export interface ExtendableRowProps {
    /**
     * Indicated where to put the Chevron
     */
    chevronPosition?: 'right' | 'left';

    /**
     * The initialized state of the isOpen the extendable row
     */
    isOpenByDefault?: boolean;
    /**
     * Node for the header content.
     * No need to add border.
     */
    header: React.ReactNode;
    /**
     * Should add default background color for content.
     */
    defaultBackgroundColor?: boolean;

    // hasLowerSibling & isFirstNode - handles the css in case there in 2 or more grouped ExtendableRow.
    // Auto injected when wrap in GroupedExtendableRows
    hasLowerSibling?: boolean;
    isFirstNode?: boolean;
}

/**
 * Generic Component for extendable row
 * Note: when you want to put 2 or more ExtendableRow without space between them please wrap those in GroupedExtendableRows
 *
 * @example Single extendable row:
 *  <ExtendableRow rowHeaderContent="Hey There" />
 *
 * @example Grouped extendable rows:
 *  <GroupedExtendableRows>
 *      <ExtendableRow rowHeaderContent="Hey There A" />
 *      <ExtendableRow rowHeaderContent="Hey There B">hello world</ExtendableRow>
 *  </GroupedExtendableRows>
 */
const ExtendableRow: React.FC<React.PropsWithChildren<ExtendableRowProps>> = ({
    header,
    chevronPosition = 'left',
    isOpenByDefault = false,
    defaultBackgroundColor = true,
    hasLowerSibling = false,
    isFirstNode = true,
    children,
}) => {
    const [isContentOpen, toggleIsContentOpen] = useToggle(isOpenByDefault);

    // On click function only when extendable prop exists.
    const onclickExpendRow = children ? () => toggleIsContentOpen() : undefined;

    return (
        <>
            <StyledClickableRow
                $isOpen={isContentOpen}
                onClick={onclickExpendRow}
                $hasLowerNode={hasLowerSibling}
                $isFirstNode={isFirstNode}
            >
                {children && chevronPosition === 'left' && (
                    <StyledChevron
                        $position="left"
                        direction={isContentOpen ? ChevronDirection.DOWN : ChevronDirection.RIGHT}
                    />
                )}

                {header}

                {children && chevronPosition === 'right' && (
                    <StyledChevron
                        $position="right"
                        direction={isContentOpen ? ChevronDirection.DOWN : ChevronDirection.LEFT}
                    />
                )}
            </StyledClickableRow>

            <CollapsibleContent open={isContentOpen && !!children}>
                <ContentWrapper $defaultBackgroundColor={defaultBackgroundColor} $hasLowerNode={hasLowerSibling}>
                    {children}
                </ContentWrapper>
            </CollapsibleContent>
        </>
    );
};

export default ExtendableRow;
