import type React from 'react';

import ModalSize from '../TUI/Modal/ModalSize';

export enum DialogType {
    /** Dialog with single button of OK */
    ALERT,
    /** Dialog with OK and cancel buttons */
    CONFIRM,
}

export interface BaseDialog {
    /** Dialog's type */
    type: DialogType;
    /** Dialog's title */
    title?: React.ReactNode;
    /** Dialog's content */
    content: React.ReactNode;
    /** Text for the OK button */
    okLabel?: string;
    /** Size for the modal */
    size?: ModalSize;
    /** Flag for save button disabled */
    saveDisabled?: boolean;
}

export interface AlertDialog extends BaseDialog {
    /** Dialog's type */
    type: DialogType.ALERT;
}

export interface ConfirmDialog extends BaseDialog {
    /** Dialog's type */
    type: DialogType.CONFIRM;
    /** Text for the cancel button */
    cancelLabel?: string;
    /** Flag for a warning theme */
    warning?: boolean;
    /** Flag for a delete theme */
    delete?: boolean;

    /**
     * If when the user clicks on OK, an async operation (i.e. http request) is triggered, you can return it's
     * promise here, and it will show loading circle near the submit button instead of closing. When the promise
     * resolves it will close the dialog. If it rejects, it will show the error message.
     */
    onOk?(): Promise<any>;
    /**
     * If async operation finished with error, this method will be called to return custom error
     */
    onCreateCustomError?(error: any): string | undefined;
}

type Dialog = AlertDialog | ConfirmDialog;

interface InternalDialogBase {
    /** Dialog's id */
    id: number;
    /** Unmount the dialog and removes from the dialogs list. Must be triggered after exit animation completes. */
    remove(): void;
}

interface InternalAlertDialog extends AlertDialog, InternalDialogBase {
    /** Function to trigger when the alert dialog closed */
    callback(): void;
}

interface InternalConfirmDialog extends ConfirmDialog, InternalDialogBase {
    /** Function to trigger when the confirm dialog closed, and pass the selected response */
    callback(value: boolean): void;
}

export type InternalDialog = InternalAlertDialog | InternalConfirmDialog;

export default Dialog;
