import type MainColorsPaletteDefinition from './MainColorsPaletteDefinition';
import { PaletteType } from '../PaletteType';

/**
 * The Tonkean main colors palette configuration
 */
const mainColorsPaletteConfiguration: Record<PaletteType, MainColorsPaletteDefinition> = {
    [PaletteType.LIGHT]: {
        primary: '#39B3D6',
        primaryActive: '#20C5F5',
        primaryHighlight: '#2F84DC',

        focus: '#3799FF',
        disabled: '#F7F8FA',

        success: '#12D4B8',
        successBright: '#F4FDFB',
        successHover: '#53CAB9',

        marker: '#FFB27A',
        naturalInfoMessage: '#FFFBF1',
        error: '#FF5454',
        warning: '#F0771E',
        yellow: '#F0DB1E',
        promotion: '#605CE4',

        // White
        basicBackground: '#FFFFFF',
        invertedText: '#FFFFFF',

        // Grays
        gray_900: '#F8F8F8',
        gray_800: '#34393E',
        gray_700: '#5B636C',
        gray_600: '#838A92',
        gray_500: '#9CA6B2',
        gray_400: '#CDD2D8',
        gray_300: '#E9ECF1',
        gray_200: '#F2F4F7',
        gray_100: '#FCFCFB',
        gray: '#F7F8FA',
        white: '#FFFFFF',
        green: '#2C9166',
        purple: '#7F56D9',
        light_purple: '#8e6ca9',
        gray_blue: '#3E4784',

        tabsHover: '#F3F8FD',
        brightestGray: '#F8F8F8',
        HEX_333333: '#333333',
        HEX_F2F3F6: '#F2F3F6',
        HEX_F2F2F2: '#F2F2F2',
        HEX_FFF8EE: '#FFF8EE',
        HEX_CACACA: '#CACACA',
        HEX_CCCCCC: '#CCCCCC',
        HEX_F9F9F7: '#F9F9F7',
        HEX_3799FF: '#3799FF',
        HEX_F7F8FA: '#F7F8FA',

        // Guest colors
        guest_text: '#883e08',
        guest_background: '#ffb27a',
        guest_border: '#e7a06e',
    },
};

export default mainColorsPaletteConfiguration;
