import type { Either } from '@tonkean/utils';

export enum SystemBeingUsedType {
    INTEGRATION = 'INTEGRATION',
    CUSTOM_INTERFACE = 'CUSTOM_INTERFACE',
    FORM = 'FORM',
}

export interface ProcessParticipantSystemBeingUsedSimple {
    name: string;
    type: Exclude<SystemBeingUsedType, SystemBeingUsedType.INTEGRATION>;
}

export interface ProcessParticipantSystemBeingUsedIntegration {
    name: string;
    type: SystemBeingUsedType.INTEGRATION;
    integrationType: string;
}

type ProcessParticipantSystemBeingUsed = Either<
    ProcessParticipantSystemBeingUsedIntegration,
    ProcessParticipantSystemBeingUsedSimple
>;

export default ProcessParticipantSystemBeingUsed;
