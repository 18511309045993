import RegexValidationType from './RegexValidationType';

export const EmailRegexValidation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const UrlRegexValidation = /[-\w@:%.+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b([-\w()@:%+.~#?&/=]*)/;
export const PhoneNumberRegexValidation = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?(?:[-.\s]?\d{1,4}){2}[-.\s]?\d{1,9}$/;

export const ValidationTypeToRegexMap: Record<RegexValidationType, string> = {
    [RegexValidationType.EMAIL_VALIDATION]: EmailRegexValidation.source,
    [RegexValidationType.URL_VALIDATION]: UrlRegexValidation.source,
    [RegexValidationType.PHONE_NUMBER_VALIDATION]: PhoneNumberRegexValidation.source,
    [RegexValidationType.CUSTOM_VALIDATION]: '',
};

export const ValidationTypeToErrorMessage: Record<RegexValidationType, string> = {
    [RegexValidationType.EMAIL_VALIDATION]: 'Field is not a valid Email',
    [RegexValidationType.URL_VALIDATION]: 'Field is not valid URL',
    [RegexValidationType.PHONE_NUMBER_VALIDATION]: 'Field is not a valid phone number',
    [RegexValidationType.CUSTOM_VALIDATION]: 'Field is not valid',
};

export function testRegex(validationType: RegexValidationType, stringToCheck: string, customRegex?: string) {
    switch (validationType) {
        case RegexValidationType.EMAIL_VALIDATION:
            return EmailRegexValidation.test(stringToCheck);
        case RegexValidationType.URL_VALIDATION:
            return UrlRegexValidation.test(stringToCheck);
        case RegexValidationType.PHONE_NUMBER_VALIDATION:
            return PhoneNumberRegexValidation.test(stringToCheck);
        case RegexValidationType.CUSTOM_VALIDATION: {
            if (!customRegex) {
                return false;
            }

            const convertedCustomRegex = new RegExp(customRegex);
            return convertedCustomRegex.test(stringToCheck);
        }
    }
}
