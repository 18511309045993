import React, { useMemo } from 'react';
import styled from 'styled-components';

import SimplecCheckboxesSelect from './SimpleCheckboxesSelect';
import SimpleRadioButtonsSelect from './SimpleRadioButtonsSelect';
import { getBorderColor } from '../../Input';
import type { SimpleSelectSingleOption } from '../SimpleSelectTypes';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ $isError?: boolean; $isHighlighted?: boolean }>`
    width: 100%;
    background-color: ${Theme.colors.white};
    padding: 12px 8px;
    border-radius: 6px;
    border: 1px solid
        ${({ $isError, $isHighlighted }) => getBorderColor($isError ?? false, $isHighlighted ?? false, false)};
`;

type SimpleListSelectProps<Option extends SimpleSelectSingleOption<any>> = {
    options: readonly Option[];
    selectedValues: any[];
    onChange: (newOptions: Option[]) => void;
    isMulti?: boolean;
    maximumOptionsToSelect?: number;
    isError?: boolean;
    isHighlighted?: boolean;
    selectionColor?: Color;
};

const SimpleListSelect = <Option extends SimpleSelectSingleOption<any>>({
    options,
    selectedValues,
    onChange,
    isMulti,
    maximumOptionsToSelect,
    isError,
    isHighlighted,
    selectionColor,
}: SimpleListSelectProps<Option>) => {
    const selectedOptions = useMemo(() => {
        return options.filter((option) => selectedValues.includes(option.value));
    }, [options, selectedValues]);

    const displayCheckboxesFurMultiSelection = isMulti && (!maximumOptionsToSelect || maximumOptionsToSelect > 1);

    return (
        <Wrapper $isError={isError} $isHighlighted={isHighlighted} data-automation="tnk-simple-select-list">
            {displayCheckboxesFurMultiSelection ? (
                <SimplecCheckboxesSelect
                    options={options}
                    selectedOptions={selectedOptions}
                    maximumOptionsToSelect={maximumOptionsToSelect}
                    selectionColor={selectionColor}
                    onChange={onChange}
                />
            ) : (
                <SimpleRadioButtonsSelect
                    options={options}
                    selectedOption={selectedOptions.length > 0 ? selectedOptions[0] : undefined}
                    selectionColor={selectionColor}
                    onChange={(option: Option) => {
                        onChange([option]);
                    }}
                />
            )}
        </Wrapper>
    );
};

export default SimpleListSelect;
