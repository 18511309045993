/**
 * A throttler function based on request animation frame.
 *
 * @returns a function to pass the latest callback. When the animation frame comes, it will trigger the last callback
 * provided.
 */
function requestAnimationFrameThrottler() {
    let lastCallback: () => void;
    let animationFrameQueued: boolean;

    return (callback: () => void) => {
        lastCallback = callback;

        if (!animationFrameQueued) {
            requestAnimationFrame(() => {
                lastCallback();
                animationFrameQueued = false;
            });

            animationFrameQueued = true;
        }
    };
}

export default requestAnimationFrameThrottler;
