import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import { Placeholder, Tooltip } from './TUI';
import useGetProjectIntegrationById from '../hooks/useGetProjectIntegrationById';

import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { classNames } from '@tonkean/utils';

const Icon = styled.div<{ width: number; height: number }>`
    background-size: contain !important;
    background: no-repeat;

    ${({ width, height }) => css`
        height: ${height}px;
        width: ${width}px;
    `}
`;

interface Props extends StyledComponentsSupportProps {
    projectIntegrationId?: string | undefined | null;
    iconUrl?: string | undefined | null;
    integrationType?: string | undefined | null;
    width?: number;
    height?: number;
    placeholderWidth?: number;
    isValid?: boolean;
    tooltipContent?: ReactNode;
}

/**
 * Will show the project integration icon. If project integration id is given, it will try tov load it, and
 * if not, or if failed, it will use the provided iconUrl and integrationType.
 * It will show placeholder while loading or if no project integration and no fallback props.
 *
 * **To use this component you must have `projectIntegrationCache` in reactToAngular services list**
 */
const ProjectIntegrationIcon: React.FC<Props> = ({
    className: classNameProp,
    projectIntegrationId,
    iconUrl: iconUrlProp,
    integrationType: integrationTypeProp,
    width = 24,
    height = width,
    placeholderWidth = width,
    isValid = true,
    tooltipContent,
}) => {
    const { loading, projectIntegration } = useGetProjectIntegrationById(projectIntegrationId || undefined);

    const integrationType = projectIntegration?.integrationType || integrationTypeProp || '';
    const iconUrl = projectIntegration?.iconUrl || iconUrlProp;

    const hasMissingInformation = !integrationType && !iconUrl;

    const backgroundImage = iconUrl ? `url('${iconUrl}')` : undefined;
    const className = classNames(
        'initiative-integration-icon',
        !backgroundImage && integrationType && `mod-${integrationType.toLowerCase()}`,
        classNameProp,
        !isValid && 'mod-error',
    );

    return (
        <>
            {loading || hasMissingInformation ? (
                <Placeholder className={classNameProp} $width={`${placeholderWidth}px`} $circle />
            ) : (
                <Tooltip placement="top" content={tooltipContent} disabled={!tooltipContent}>
                    <Icon className={className} style={{ backgroundImage }} width={width} height={height} />
                </Tooltip>
            )}
        </>
    );
};

export default ProjectIntegrationIcon;
