enum SolutionBusinessReportAccessPermission {
    ALL_PROCESS_CONTRIBUTORS = 'ALL_PROCESS_CONTRIBUTORS',
    SPECIFIC_PEOPLE = 'SPECIFIC_PEOPLE',
    NO_ONE = 'NO_ONE',
}

export const businessReportSharePermissionTypeDisplayName: Record<SolutionBusinessReportAccessPermission, string> = {
    [SolutionBusinessReportAccessPermission.ALL_PROCESS_CONTRIBUTORS]: 'All Process Contributors',
    [SolutionBusinessReportAccessPermission.SPECIFIC_PEOPLE]: 'Specific People',
    [SolutionBusinessReportAccessPermission.NO_ONE]: 'No One',
};

export default SolutionBusinessReportAccessPermission;
