import styled, { css } from 'styled-components';

import ModalSize from './ModalSize';

import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

export interface ModalContentProps {
    /** Modal width */
    size?: ModalSize | number;
    /** Should the width of the modal be fixed (match the width provided in the size prop)? Ignored if full screen */
    fixedWidth?: boolean;
    /** Should the modal be full screen */
    fullScreen?: boolean;
    /** Should the modal be full screen but still look like a modal */
    windowedFullScreen?: boolean;
    /** Should the modal be scrollable? if not overflow will be visible */
    scrollable?: boolean;
    /** Modal height */
    height?: string;
}

const ModalContent = styled.div<ModalContentProps>`
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;

    height: ${({ height }) => height || 'auto'};
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
    overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'unset')};

    // The modal content has tabIndex of -1 to act as a fallback focus for the focus trap. There should be no focus
    // indication. Because the new focus indication uses box shadow, we duplicating the box shadow to the focus state
    // as well.
    &,
    &:focus {
        outline: none;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
    }

    ${({ fullScreen, windowedFullScreen }) => {
        if (fullScreen) {
            return css`
                margin: 0;
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 0;
            `;
        }

        if (windowedFullScreen) {
            return css`
                width: 100%;
                height: 95vh;
                margin: 27px;
            `;
        }

        return css<ModalContentProps>`
            margin: 35px;
            max-height: 90vh;
            ${({ fixedWidth = false, size = ModalSize.MEDIUM }) => `${fixedWidth ? 'width' : 'max-width'}: ${size}px;`}
        `;
    }}

    ${childrenStyledFocus}
`;

ModalContent.displayName = 'ModalContent';

export default ModalContent;
