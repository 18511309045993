import { useMemo } from 'react';

import useAllInitiativeFieldsValues from './useAllInitiativeFieldsValues';

import type { Initiative, MatchConditionExpression } from '@tonkean/tonkean-entities';

function useInitiativeValuesFromExpressionsQueries(
    initiative: Initiative | undefined,
    expressions: MatchConditionExpression[],
) {
    const initiativeFieldsMap = useAllInitiativeFieldsValues(initiative);

    return useMemo(() => {
        const allQueryDefinitions = expressions.map((expression) => expression.queryDefinition);
        const allQueryDefinitionsStr = JSON.stringify(allQueryDefinitions);

        const initiativeFieldsFoundInExpressions = {};
        Object.keys(initiativeFieldsMap).forEach((fieldId) => {
            if (allQueryDefinitionsStr.includes(fieldId)) {
                initiativeFieldsFoundInExpressions[fieldId] = initiativeFieldsMap[fieldId];
            }
        });

        return initiativeFieldsFoundInExpressions;
    }, [initiativeFieldsMap, expressions]);
}

export default useInitiativeValuesFromExpressionsQueries;
