import { useContext, useEffect, useRef, useState } from 'react';

import { ResizeObserverContext } from '../components/ResizeObserverManager';

interface UseResizeObserverReturnValue {
    setNode(node: Element | null | undefined): void;
}

/**
 * React hook for listening for an element size change.
 *
 * @param callback - a function that will be triggered every time the element size changes.
 * @returns an object with setNode as a function for setting the observed element.
 */
function useResizeObserver(callback?: (entry: ResizeObserverEntry) => void): UseResizeObserverReturnValue {
    const addObserved = useContext(ResizeObserverContext);

    const [node, setNode] = useState<Element | null>();

    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const hasCallback = !!callback;
    useEffect(() => {
        // if no callback there is no reason to calculate as nothing will get done anyways, skip it
        if (node && hasCallback && typeof addObserved === 'function') {
            const unobserve = addObserved(node, (entry) => {
                callbackRef.current?.(entry);
            });

            return () => unobserve();
        }
    }, [node, addObserved, hasCallback]);

    return { setNode };
}

export default useResizeObserver;
