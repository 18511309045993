enum TemplateHeaderBackgroundClassName {
    NEW = 'mod-new',
    EXECUTIVE = 'mod-executive',
    ONGOING = 'mod-ongoing',
    TRACKER = 'mod-tracker',
    UPDATES = 'mod-updates',
    EXECUTE = 'mod-execute',
    OVERVIEW = 'mod-overview',
}

export default TemplateHeaderBackgroundClassName;
