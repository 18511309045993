// React table component gives key to the tr and td so we can suppress it with eslint-disable
/* eslint-disable react/jsx-key */
import React, { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import type { Column, TableCommonProps, TableOptions } from 'react-table';
import { useTable } from 'react-table';
import styled, { css } from 'styled-components';

import TableStyle from './TableStyle';
import CollapsibleContent from '../../CollapsibleContent';
import { INDENTATION } from '../Accordion/Accordion';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { TableSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledTable = styled.table<{ growTable: boolean; border: boolean }>`
    border-spacing: 0;
    width: 100%;

    ${({ border }) =>
        border &&
        css`
            border-collapse: separate;
            overflow: hidden;
            border: 1px solid ${Theme.colors.gray_400};
            border-radius: 3px;
        `}
    ${({ growTable }) =>
        growTable &&
        css`
            flex-grow: 1;
        `}
    & .table-row:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

const HeaderRow = styled.tr`
    background: ${Theme.colors.basicBackground};
`;

const SharedPadding = css<{ indentationLevel: number; isFirst: boolean }>`
    padding: 10px 10px 10px ${({ isFirst, indentationLevel }) => 10 + (isFirst ? indentationLevel * INDENTATION : 0)}px;
`;

const HeaderCell = styled.th<{
    size: TableSize;
    paddingLeft?: number;
    paddingRight?: number;
    sticky?: boolean;
    stickyHeaderZIndex?: number;
    indentationLevel: number;
    isFirst: boolean;
    borderBottom: boolean;
}>`
    ${SharedPadding};

    height: ${({ size }) => Theme.sizes.table[size].headerHeight}px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;

    ${({ borderBottom }) =>
        borderBottom &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_400};
        `}

    ${({ sticky, stickyHeaderZIndex }) =>
        sticky &&
        css`
            position: sticky;
            z-index: ${stickyHeaderZIndex};
            top: 0;
            background-color: ${Theme.colors.basicBackground};
        `}

    ${({ paddingRight }) =>
        paddingRight &&
        css`
            padding-right: ${paddingRight}px;
        `}

    ${({ paddingLeft }) =>
        paddingLeft &&
        css`
            padding-left: ${paddingLeft}px;
        `}
`;

const Cell = styled.td<{
    size: TableSize;
    paddingLeft?: number;
    paddingRight?: number;
    showCellBorder?: boolean;
    indentationLevel: number;
    isFirst: boolean;
}>`
    ${SharedPadding};

    margin: 0;
    height: ${({ size }) => Theme.sizes.table[size].cellHeight}px;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_500};

    ${({ showCellBorder }) =>
        showCellBorder &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}

    ${({ paddingRight }) =>
        paddingRight &&
        css`
            padding-right: ${paddingRight}px;
        `}

    ${({ paddingLeft }) =>
        paddingLeft &&
        css`
            padding-left: ${paddingLeft}px;
        `}
`;

const ExpendableCell = styled.td<{
    showCellBorder?: boolean;
}>`
    ${({ showCellBorder }) =>
        showCellBorder &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}
`;

const ExpendableContent = styled(CollapsibleContent)<{
    showCellBorder?: boolean;
}>`
    ${({ showCellBorder }) =>
        showCellBorder &&
        css`
            border-top: 1px solid ${Theme.colors.gray_300};
        `}
`;

type RowData<T extends Record<string, unknown>> = Omit<T, 'expendable'>;

interface TableProps<T extends Record<string, unknown>> extends StyledComponentsSupportProps {
    size?: TableSize;
    style?: TableStyle;
    paddingLeft?: number;
    paddingRight?: number;
    tableRowComponent?: React.ComponentType<PropsWithChildren<TableCommonProps & { data: RowData<T> }>>;
    showCellBorder?: boolean;
    growTable?: boolean;
    stickyHeader?: boolean;
    border?: boolean;
    stickyHeaderZIndex?: number;
    hiddenColumns?: Extract<keyof T, string>[];
    indentationLevel?: number;
    lastBorderBottom?: boolean;
    expendableRows?: boolean;
}

type Props<T extends Record<string, unknown>> = TableProps<T> & TableOptions<RowData<T>>;

const Table = <T extends Record<string, unknown>>({
    size = TableSize.MEDIUM,
    style = TableStyle.BORDER,
    columns,
    className,
    tableRowComponent: TableRowComponent,
    paddingLeft,
    paddingRight,
    showCellBorder = true,
    growTable = true,
    stickyHeader = false,
    stickyHeaderZIndex = 1,
    border = false,
    hiddenColumns,
    indentationLevel = 0,
    lastBorderBottom = true,
    expendableRows = false,
    ...tableOptions
}: Props<T>): React.ReactElement => {
    const [openRowIds, setOpenRowIds] = useState<Set<string>>(new Set());

    const modifiedColumns = useMemo<Column<RowData<T>>[]>(() => {
        const recursivelyAddDefaultWidth = (column: Column<RowData<T>>) => {
            return {
                ...column,
                // If not set (undefined) useTable will set a default value.
                // To prevent it we set null instead of undefined which wont be replaced with default value.
                width: column.width ?? null,
                minWidth: column.minWidth ?? null,
                maxWidth: column.maxWidth ?? null,
                columns: 'columns' in column ? column.columns.map(recursivelyAddDefaultWidth) : undefined,
            };
        };

        return columns.map(recursivelyAddDefaultWidth);
    }, [columns]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<RowData<T>>(
        {
            ...tableOptions,
            columns: modifiedColumns,
        },
    );

    useEffect(() => {
        if (hiddenColumns) {
            setHiddenColumns(hiddenColumns);
        }
    }, [hiddenColumns, setHiddenColumns]);

    return (
        <StyledTable border={border} growTable={growTable} {...getTableProps({ className })}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                            <HeaderCell
                                {...column.getHeaderProps({
                                    style: {
                                        width: column.width,
                                        minWidth: column.minWidth,
                                        maxWidth: column.maxWidth,
                                    },
                                })}
                                size={size}
                                paddingLeft={paddingLeft}
                                paddingRight={paddingRight}
                                sticky={stickyHeader}
                                stickyHeaderZIndex={stickyHeaderZIndex}
                                indentationLevel={indentationLevel}
                                isFirst={index === 0}
                                borderBottom={lastBorderBottom || !!rows.length}
                            >
                                {column.render('Header')}
                            </HeaderCell>
                        ))}
                    </HeaderRow>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const { key, ...rowProps } = row.getRowProps();
                    const open = openRowIds.has(row.id);
                    const expendable = !!row.original.expendable && expendableRows;
                    const cellBorder = showCellBorder && (lastBorderBottom || rowIndex + 1 !== rows.length);
                    const rowBackgroundColor = row.original.highlighted
                        ? 'rgba(18, 212, 184, 0.05)'
                        : Theme.colors.basicBackground;
                    const sharedRowProps: React.DetailedHTMLProps<
                        React.HTMLAttributes<HTMLTableRowElement>,
                        HTMLTableRowElement
                    > = {
                        // todo: should be changed, this element shouldn't be clickable
                        onClick: () => {
                            if (!expendableRows) {
                                return;
                            }
                            if (openRowIds.has(row.id)) {
                                setOpenRowIds(
                                    (currentValue) => new Set([...currentValue].filter((rowId) => rowId !== row.id)),
                                );
                            } else {
                                setOpenRowIds((currentValue) => new Set([...currentValue, row.id]));
                            }
                        },
                        ...rowProps,
                        className: `table-row ${expendable && `pointer`}`,
                        style: {
                            backgroundColor: rowBackgroundColor,
                        },
                    };

                    const innerRowComponents = (
                        <>
                            {row.cells.map((cell, index) => {
                                return (
                                    <Cell
                                        {...cell.getCellProps()}
                                        size={size}
                                        paddingLeft={paddingLeft}
                                        paddingRight={paddingRight}
                                        showCellBorder={expendable ? false : cellBorder}
                                        indentationLevel={indentationLevel}
                                        isFirst={index === 0}
                                    >
                                        {cell.render('Cell', row.original.expendable && expendableRows ? { open } : {})}
                                    </Cell>
                                );
                            })}
                        </>
                    );
                    return (
                        <React.Fragment key={key}>
                            {TableRowComponent ? (
                                <TableRowComponent data={row.original} {...sharedRowProps}>
                                    {innerRowComponents}
                                </TableRowComponent>
                            ) : (
                                <tr {...sharedRowProps}>{innerRowComponents}</tr>
                            )}

                            {expendable && (
                                <tr {...rowProps}>
                                    <ExpendableCell colSpan={row.cells.length} showCellBorder={cellBorder}>
                                        <ExpendableContent open={open} showCellBorder={!!row.original.expendableBorder}>
                                            {row.original.expendable}
                                        </ExpendableContent>
                                    </ExpendableCell>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </StyledTable>
    );
};

type TableComponentType = <T extends Record<string, unknown>>(props: Props<T>) => React.ReactElement;
export default React.memo(Table) as TableComponentType;
