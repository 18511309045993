import { useEffect, useState } from 'react';

import useEventListener from './useEventListener';

import { createSymbolStack } from '@tonkean/utils';

export const addToOnClickOutsideStack = createSymbolStack();

/**
 * React hook for listening for click outside the container.
 *
 * To exclude an element from triggering the callback, you can add to it `data-dont-trigger-click-outside`.
 *
 * @param callback - The callback function to trigger.
 * @param active - Should the listener be active? If not, it will remove it from the stack and won't block
 * others users of useOnClickOutside.
 * @param onlyIfNewest - If true, it will call the callback only if this is the 'newest' useOnClickOutside.
 * for example, if you have modal inside modal, it an escape will be triggered only in the child modal.
 * @param ignoreDontTriggerClickOutside - Whether it should trigger the callback when clicking on an element with the
 * data attribute dont-trigger-click-outside.
 * @param triggerOnRightClick - Whether it should trigger when it's a right click.
 * @returns function that expects to receive the container element as it's param. If passed
 * undefined, it will deactivate the on click outside.
 */
function useOnClickOutside(
    callback: (event: MouseEvent) => void,
    active: boolean = true,
    onlyIfNewest: boolean = true,
    ignoreDontTriggerClickOutside: boolean = false,
    triggerOnRightClick: boolean = false,
) {
    const [element, setElement] = useState<HTMLElement | null | undefined>();

    useEventListener(
        'mousedown',
        (event) => {
            const triggeringRightClick = triggerOnRightClick && event.button === 2;
            const triggeringLeftClick = event.button === 0;
            if (!triggeringLeftClick && !triggeringRightClick) {
                return;
            }

            if (!element || !event.target) {
                return;
            }

            if (element.contains(event.target as HTMLElement)) {
                return;
            }

            if (!ignoreDontTriggerClickOutside) {
                const path: EventTarget[] | undefined = event['path'] || event.composedPath?.();
                const clickedOnAllowedZone = path?.some(
                    (element: HTMLElement) => element.dataset?.dontTriggerClickOutside !== undefined,
                );
                if (clickedOnAllowedZone) {
                    return;
                }
            }

            callback(event);
        },
        true,
        active && !!element,
        onlyIfNewest,
        addToOnClickOutsideStack,
    );

    return setElement;
}

export default useOnClickOutside;

export function useIgnoreClickOutside(active: boolean = true) {
    useEffect(() => {
        if (active) {
            const stackItem = addToOnClickOutsideStack();

            return () => stackItem.remove();
        }
    }, [active]);
}
