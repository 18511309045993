import useEventListener from './useEventListener';

import { createSymbolStack } from '@tonkean/utils';

type AddToSymbolStack = ReturnType<typeof createSymbolStack>;

export const addToKeyShortcutCallbackStack = createSymbolStack();

export enum CommandKeys {
    COMMAND = 0,
    OPTION = 1,
}

/**
 * React hook for listening for an escape key press.
 *
 * @param key - the keyboard key clicked after the commandKey such as 'd'.
 * @param callback - The callback function to trigger.
 * @param capture - should events of this type be dispatched this callback before being dispatched to any other
 * event listener?
 * @param active - Should the listener be active? If not, it will remove it from the stack and won't block
 * others users of useEscapeCallback.
 * @param onlyIfNewest - If true, it will call the callback only if this is the 'newest' useEscapeCallback.
 * for example, if you have modal inside modal, it an escape will be triggered only in the child modal.
 * @param addToStack - Function to add to the symbol stack. **Calling `symbolStack()` *MUST* happen outside of the
 * hook!**, otherwise it will re-create the stack on every use of the hook, so it will never have more then one
 * symbol in the stack, and all hook users will be considered 'newest', and it will re-run the useEffect on every
 * render. This is a required parameter if `onlyIfNewest` is true.
 * @param commandKey - the command key clicked COMMAND/CTRL OPTION/ALT - Optional.
 */
function useKeyShortcutCallback(
    key: string,
    callback: (event: KeyboardEvent) => void,
    capture: boolean = false,
    active: boolean = true,
    onlyIfNewest: boolean = false,
    addToStack?: AddToSymbolStack,
    commandKey?: CommandKeys,
) {
    useEventListener(
        'keydown',
        (event) => {
            if (commandKey === CommandKeys.COMMAND && !(event.metaKey || event.ctrlKey)) {
                return;
            }

            if (commandKey === CommandKeys.OPTION && !event.altKey) {
                return;
            }

            if (event.key != key) {
                return;
            }

            return callback(event);
        },
        capture,
        active,
        onlyIfNewest,
        addToStack ?? addToKeyShortcutCallbackStack,
    );
}

export default useKeyShortcutCallback;
