import React, { useContext, useEffect, useMemo, useRef } from 'react';

import { ItemInterfaceSection } from './ItemInterfaceSection';
import type { UpdateFieldsValuesManager } from './UpdateFieldsValuesManager';
import type { ReactUseState } from '../utils';

import type {
    FieldDefinition,
    FullProject,
    IncludedWidgetsSummaryByInterface,
    Initiative,
    InterfaceCTASavedAction,
    InterfaceTab,
    ItemInterface,
    ItemInterfaceWidget,
    PluggableActionType,
    SelectedPluggableAction,
    SequenceState,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';

const RELOAD_ITEM_INTERFACE_DATA_INTERVAL_MS = 2000;
const RELOAD_ITEM_INTERFACE_DATA_INTERVALS = 3;
const EMPTY_OBJECT = {};

/**
 * Context for the essentials to viewing the item interface
 */
export interface ItemInterfaceContextData {
    loadingRequiredInformation: boolean;
    itemInterface?: ItemInterface;
    initiative?: Initiative | undefined;
    initiativeInitialLoading: boolean;
    workflowVersion?: WorkflowVersion;
    project?: FullProject;
    section?: ItemInterfaceSection;
    actionsDisabledLiveDisplay?: Record<InterfaceCTASavedAction['id'], boolean> | {};
    actionsHiddenLiveDisplay?: Record<InterfaceCTASavedAction['id'], boolean> | {};
    tabs?: InterfaceTab[];
    includedWidgetsSummaryByInterfaceId?: IncludedWidgetsSummaryByInterface;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    widgetsToDisplay: ItemInterfaceWidget[];
    allInterfaceWidgets: ItemInterfaceWidget[];
    itemInterfaceContextDataError?: any;
    itemInterfaceFieldDefinitions: FieldDefinition[];
    loadingItemInterfaceFieldDefinitions: boolean;
    reloadInitiativeFromServer?(updatedInitiative?: Initiative | undefined): void;
    applyFastReloadInterval: (() => void) | undefined;
    selectedPluggableActionState?: ReactUseState<SelectedPluggableAction | undefined>;
    pluggableActionHoverState?: ReactUseState<boolean>;
    pluggableActionsToShowSettings?: PluggableActionType[];
    widgetsInnerItems: { [widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>]: Initiative[] };
    updateWidgetsInnerItems: (widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>, innerItems: Initiative[]) => void;
    intakeWorkflowVersion?: WorkflowVersion;
    intakeInitiative?: Initiative;
    currentSequenceState?: SequenceState;
    setCurrentSequenceState?: (sequenceState: SequenceState) => void;
    updateFieldsValuesManager: UpdateFieldsValuesManager;
}

export const ItemInterfaceContext = React.createContext<ItemInterfaceContextData>({
    loadingRequiredInformation: false,
    initiativeInitialLoading: false,
    applyFastReloadInterval: undefined,
    widgetsToDisplay: [],
    allInterfaceWidgets: [],
    itemInterfaceFieldDefinitions: [],
    loadingItemInterfaceFieldDefinitions: false,
    widgetsInnerItems: {},
    updateWidgetsInnerItems: () => {},
    updateFieldsValuesManager: {
        updateGlobalField: async () => {},
        updateInitiativeFields: () => {
            throw new Error('updateInitiativeFields not implemented');
        },
        inActiveFieldsValuesUpdate: false,
    },
});

export default function useItemInterfaceContext() {
    const {
        itemInterface,
        initiativeInitialLoading,
        initiative,
        section,
        workflowVersion,
        project,
        actionsDisabledLiveDisplay,
        actionsHiddenLiveDisplay,
        tabs,
        includedWidgetsSummaryByInterfaceId,
        originatedCustomTriggerId,
        currentCustomTriggerId,
        widgetsToDisplay,
        allInterfaceWidgets,
        itemInterfaceContextDataError,
        itemInterfaceFieldDefinitions,
        loadingItemInterfaceFieldDefinitions,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        selectedPluggableActionState,
        pluggableActionHoverState,
        pluggableActionsToShowSettings,
        widgetsInnerItems,
        updateWidgetsInnerItems,
        intakeWorkflowVersion,
        intakeInitiative,
        currentSequenceState,
        setCurrentSequenceState,
        updateFieldsValuesManager,
    } = useContext(ItemInterfaceContext);

    if (!itemInterface) {
        throw new Error('item interface is undefined');
    }
    if (!project) {
        throw new Error('project is undefined');
    }

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const stopTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        return () => {
            stopTimer(); // Cleanup function: stop timer when component is unmounted
        };
    }, []);

    const reloadInitiativeFromServerWrapper = (updatedInitiative?: Initiative | undefined) => {
        if (reloadInitiativeFromServer) {
            // first reload immediately
            reloadInitiativeFromServer(updatedInitiative);
        }
        if (intervalRef.current) return; // Timer already started

        let count = 0;
        intervalRef.current = setInterval(() => {
            count++;
            if (count > RELOAD_ITEM_INTERFACE_DATA_INTERVALS) {
                stopTimer();
            } else {
                reloadInitiativeFromServer?.();
            }
        }, RELOAD_ITEM_INTERFACE_DATA_INTERVAL_MS);
    };

    // Memoize because the empty objects will trigger change on reference change
    const actionsHiddenLiveDisplayMemoized = useMemo(() => {
        if (actionsHiddenLiveDisplay && Object.keys(actionsHiddenLiveDisplay).length === 0) {
            return EMPTY_OBJECT;
        }
        return actionsHiddenLiveDisplay;
    }, [actionsHiddenLiveDisplay]);

    const actionsDisabledLiveDisplayMemoized = useMemo(() => {
        if (actionsDisabledLiveDisplay && Object.keys(actionsDisabledLiveDisplay).length === 0) {
            return EMPTY_OBJECT;
        }
        return actionsDisabledLiveDisplay;
    }, [actionsDisabledLiveDisplay]);

    return {
        itemInterface,
        initiative,
        initiativeInitialLoading,
        section: section || ItemInterfaceSection.MAIN,
        workflowVersion,
        intakeWorkflowVersion,
        project,
        actionsDisabledLiveDisplay: actionsDisabledLiveDisplayMemoized,
        actionsHiddenLiveDisplay: actionsHiddenLiveDisplayMemoized,
        tabs,
        includedWidgetsSummaryByInterfaceId,
        originatedCustomTriggerId,
        currentCustomTriggerId,
        widgetsToDisplay,
        allInterfaceWidgets,
        itemInterfaceContextDataError,
        itemInterfaceFieldDefinitions,
        loadingItemInterfaceFieldDefinitions,
        reloadInitiativeFromServer: reloadInitiativeFromServerWrapper,
        applyFastReloadInterval,
        selectedPluggableActionState,
        pluggableActionHoverState,
        pluggableActionsToShowSettings,
        widgetsInnerItems,
        updateWidgetsInnerItems,
        intakeInitiative,
        currentSequenceState,
        setCurrentSequenceState,
        updateFieldsValuesManager,
    } as const;
}
