enum BusinessAppsAnalyticsEvents {
    UPDATE_APP = 'update solution site',

    CREATE_EXTERNAL_URL = 'create external URL',
    UPDATE_EXTERNAL_URL = 'update external URL',
    DELETE_EXTERNAL_URL = 'delete external URL',

    CREATE_SOLUTION_SITE_PAGE = 'create solution site page',
    UPDATE_SOLUTION_SITE_PAGE = 'update solution site page',
    DELETE_SOLUTION_SITE_PAGE = 'delete solution site page',
    PREVIEW_SOLUTION_SITE_PAGE = 'preview solution site page',

    CREATE_SOLUTION_SITE_PAGE_WIDGET = 'create solution site page widget',
    DUPLICATE_SOLUTION_SITE_PAGE_WIDGET = 'duplicate solution site page widget',
    UPDATE_SOLUTION_SITE_PAGE_WIDGET = 'update solution site page widget',
    DELETE_SOLUTION_SITE_PAGE_WIDGET = 'delete solution site page widget',

    CREATE_ITEM_INTERFACE = 'create item interface',
    UPDATE_ITEM_INTERFACE = 'update item interface',
    DELETE_ITEM_INTERFACE = 'delete item interface',
    PREVIEW_ITEM_INTERFACE = 'preview item interface',

    CREATE_ITEM_INTERFACE_WIDGET = 'create item interface widget',
    DUPLICATE_ITEM_INTERFACE_WIDGET = 'duplicate item interface widget',
    UPDATE_ITEM_INTERFACE_WIDGET = 'update item interface widget',
    DELETE_ITEM_INTERFACE_WIDGET = 'delete item interface widget',
}
export default BusinessAppsAnalyticsEvents;
