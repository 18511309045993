import styled, { css } from 'styled-components';

import { Theme, FontSize } from '@tonkean/tui-theme';

const ZebraTableHeaderCell = styled.th<{ width?: number }>`
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${Theme.colors.gray_600};
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;

    &:first-child {
        padding-left: 30px;
    }

    ${({ width }) =>
        width &&
        css`
            max-width: ${width}px;
            min-width: ${width}px;
            width: ${width}px;
        `}
`;

export default ZebraTableHeaderCell;
