/**
 * Function to manage stack of symbols, to keep track of a basic stack.
 * For example, it's being used to keep track of which modal is "higher", or "newer", so when you click on escape,
 * it will close only the newest, and not it's parent modals.
 *
 * @returns function to add items to the stack.
 */
function createSymbolStack() {
    let symbolList: symbol[] = [];

    /**
     * Will add an item to the stack.
     *
     * @returns isLast method to check if the item is currently the last in the stack, and remove method to
     * remove from the stack.
     */
    return () => {
        const symbol = Symbol();
        symbolList.push(symbol);

        return {
            /**
             * @returns is this is the last in the stack?
             */
            isLast(): boolean {
                return symbolList[symbolList.length - 1] === symbol;
            },
            /**
             * Removes itself from stack.
             */
            remove(): void {
                symbolList = symbolList.filter((symbolInList) => symbolInList !== symbol);
            },
        };
    };
}

export default createSymbolStack;
