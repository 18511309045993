/**
 * Represents the different types of menu items.
 */
enum ProjectIntegrationPageMenuItemType {
    INFORMATION = 'information',
    CONNECTIONS = 'connections',
    DATA_RETENTION = 'data-retention',
    VIEW_DATA = 'view-data',
    FRONT_DOOR = 'front-door',
    ACTIONS = 'actions',
    SOLUTIONS_ACCESS = 'solution-access',
    ADMINS = 'admins',
    AUDIT_LOG = 'audit-log',
    EXTRACTED_FIELDS = 'extracted-fields',
    EXTRACTION_MODELS = 'extraction-models',
    TRAINING_DATA = 'training-data',
    DOCUMENT_TYPES = 'document-types',
    OVERVIEW = 'overview',
    ENTITIES = 'entities',
    SYSTEM_LOG = 'system-log',
    VARIABLES = 'variables',
    AUTHORIZATION = 'authorization',
    FIELD_MAPPER = 'field-mapper',
}

export default ProjectIntegrationPageMenuItemType;
