/**
 * Represents the different types of integrations we have.
 */
enum IntegrationType {
    GIT = 'GIT',
    GITHUB = 'GITHUB',
    BITBUCKET = 'BITBUCKET',
    VISUAL_STUDIO_ONLINE = 'VISUAL_STUDIO_ONLINE',
    JIRA = 'JIRA',
    SLACK = 'SLACK',
    ZENDESK = 'ZENDESK',
    TRELLO = 'TRELLO',
    HUBSPOT = 'HUBSPOT',
    WUNDERLIST = 'WUNDERLIST',
    SALESFORCE = 'SALESFORCE',
    ASANA = 'ASANA',
    SQL = 'SQL',
    HARVEST = 'HARVEST',
    GOOGLE = 'GOOGLE',
    GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
    SMARTSHEET = 'SMARTSHEET',
    NUTSHELL = 'NUTSHELL',
    PIPEDRIVE = 'PIPEDRIVE',
    SALESFORCEIQ = 'SALESFORCEIQ',
    WEBHOOK = 'WEBHOOK',
    GOOGLESHEETS = 'GOOGLESHEETS',
    TEAMWORK = 'TEAMWORK',
    PROSPERWORKS = 'PROSPERWORKS',
    GITLAB = 'GITLAB',
    EXCEL365 = 'EXCEL365',
    DYNAMICS365 = 'DYNAMICS365',
    MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
    MICROSOFTTEAMSAPP = 'MICROSOFTTEAMSAPP',
    EXCELONLINE = 'EXCELONLINE',
    AGILECRM = 'AGILECRM',
    ZOHOCRM = 'ZOHOCRM',
    TABLEAU = 'TABLEAU',
    INTERCOM = 'INTERCOM',
    STRIPEAPP = 'STRIPEAPP',
    WRIKE = 'WRIKE',
    BASECAMP = 'BASECAMP',
    RSS = 'RSS',
    FACEBOOKADS = 'FACEBOOKADS',
    GMAIL = 'GMAIL',
    MONDAY = 'MONDAY',
    KANBANIZE = 'KANBANIZE',
    SENDGRID = 'SENDGRID',
    GOOGLEDRIVE = 'GOOGLEDRIVE',
    FRESHDESK = 'FRESHDESK',
    DROPBOX = 'DROPBOX',
    SLACK_APP = 'SLACK_APP',
    TWILIOCHAT = 'TWILIOCHAT',
    TWILIOSMS = 'TWILIOSMS',
    EMAILWEBHOOK = 'EMAILWEBHOOK',
    ZUORA = 'ZUORA',
    GREENHOUSE = 'GREENHOUSE',
    NAMELY = 'NAMELY',
    FRONTAPP = 'FRONTAPP',
    GOOGLECLOUD = 'GOOGLECLOUD',
    DOCUSIGN = 'DOCUSIGN',
    OKTA = 'OKTA',
    OUTLOOK = 'OUTLOOK',
    MAVENLINK = 'MAVENLINK',
    TEAMCONNECT = 'TEAMCONNECT',
    IRONCLAD = 'IRONCLAD',
    SMARTSHEETWORKSPACE = 'SMARTSHEETWORKSPACE',
    SERVICENOW = 'SERVICENOW',
    AMAZONS3 = 'AMAZONS3',
    GOOGLECHAT = 'GOOGLECHAT',
    SAPOPENCONNECTOR = 'SAPOPENCONNECTOR',
    NETSUITE = 'NETSUITE',
    AMAZONKINESIS = 'AMAZONKINESIS',
    WORKDAYREPORTS = 'WORKDAYREPORTS',
    GONG = 'GONG',
    MAILCHIMPMARKETING = 'MAILCHIMPMARKETING',
    MAILCHIMPTRANSACTIONAL = 'MAILCHIMPTRANSACTIONAL',
    MARKETO = 'MARKETO',
    SQLDATABASE = 'SQLDATABASE',
    TYPEFORM = 'TYPEFORM',
    MICROSOFT_TEAMS_USER = 'MICROSOFT_TEAMS_USER',
    SHAREPOINT = 'SHAREPOINT',
    AIRTABLE = 'AIRTABLE',
    SFTP = 'SFTP',
    SHOPIFY = 'SHOPIFY',
    ORIGAMIRISK = 'ORIGAMIRISK',
    GOODTIME = 'GOODTIME',
    LATTICE = 'LATTICE',
    BOX = 'BOX',
    SPRINGCM = 'SPRINGCM',
    KUSTOMER = 'KUSTOMER',
    MICROSOFTADMIN = 'MICROSOFTADMIN',
    WORKDAY = 'WORKDAY',
    GOOGLEGROUPS = 'GOOGLEGROUPS',
    DATADOG = 'DATADOG',
    CONCUR = 'CONCUR',
    LEVER = 'LEVER',
    GOOGLECLOUDSTORAGE = 'GOOGLECLOUDSTORAGE',
    ONEDRIVE365 = 'ONEDRIVE365',
    ONEDRIVE = 'ONEDRIVE',
    UIPATH = 'UIPATH',
    OUTREACH = 'OUTREACH',
    NETDOCUMENTS = 'NETDOCUMENTS',
    AZUREBLOBSTORAGE = 'AZUREBLOBSTORAGE',
    EVISORT = 'EVISORT',
    SIMPLELEGAL = 'SIMPLELEGAL',
    NOTION = 'NOTION',
    GOOGLESLIDES = 'GOOGLESLIDES',
    WORKFRONT = 'WORKFRONT',
    DYNAMICS365FO = 'DYNAMICS365FO',
    PARDOT = 'PARDOT',
    PAGERDUTY = 'PAGERDUTY',
    CLOUDCONVERT = 'CLOUDCONVERT',
    BRAZE = 'BRAZE',
    NO_CODE_DATA_SOURCE = 'NO_CODE_DATA_SOURCE',
    ADOBESIGN = 'ADOBESIGN',
    MIUROS = 'MIUROS',
    COUPA = 'COUPA',
    UKG = 'UKG',
    ALLOCADIA = 'ALLOCADIA',
    NEXTCLOUD = 'NEXTCLOUD',
    AMAZONTEXTRACT = 'AMAZONTEXTRACT',
    // Add integration type here - DO NOT DELETE OR MODIFY THIS LINE
}

export default IntegrationType;
