/**
 * Defines modal width
 */
enum ModalSize {
    XSMALL = 300,
    SMALL = 400,
    SMEDIUM = 500,
    MEDIUM = 600,
    LARGE = 800,
    XLARGE = 900,
}

export default ModalSize;
