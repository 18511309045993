import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';

// We use this so the reference won't change on every render.
const EMPTY_OBJECT = {};

export interface StateLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    /**
     * Absolute state name or relative state path.
     */
    state: string;
    /**
     * A map of the parameters that will be sent to the state, will populate $stateParams. Any parameters that are not
     * specified will be inherited from currently defined parameters. **Should be memoized.**
     */
    params?: Record<string, any>;
    options?: {
        /**
         * If true will update the url in the location bar, if false will not. If string, must be "replace", which
         * will update url and also replace last history record.
         */
        location?: boolean | 'replace';
        /** If true will inherit url parameters from current url. */
        inherit?: boolean;
        /** Should notify to angular about the state change? */
        notify?: boolean;
    };
    /**
     * if true, this anchor element will do nothing, and will be used only to set href. It's same as using
     * `event.preventDefault` in the onClick prop.
     */
    dontChangeState?: boolean;
    openInNewTab?: boolean;

    /**
     * A callback that's triggered when clicking on the state link. If the callback returns false, it won't change state.
     * If it returns a promise, it will wait for it to resolve before continuing.
     */
    onClick?(event: React.MouseEvent<HTMLAnchorElement>): void | boolean | Promise<boolean> | Promise<void>;
}

/**
 * Anchor element that will redirect users to a new AngularJs state, like using `$state.go`.
 */
const StateLink: React.ForwardRefRenderFunction<HTMLAnchorElement, React.PropsWithChildren<StateLinkProps>> = (
    {
        children,
        state,
        params = EMPTY_OBJECT,
        options = EMPTY_OBJECT,
        dontChangeState = false,
        openInNewTab = false,

        // Angular adds preventDefault to all anchor elements without a target, but it will block the
        // useCompositeEventCallback hook, because it checks if the event is prevented before calling the callback. If
        // an anchor has a target, angular will ignore it.
        target = openInNewTab ? '_blank' : '_self',

        onClick: onClickProp,
        onFocus,
        onMouseEnter,
        ...props
    },
    ref,
) => {
    const $state = useAngularService('$state');
    const $timeout = useAngularService('$timeout');

    const [concatenatedParams, setConcatenatedParams] = useState<Record<string, unknown>>({});

    const concatenateParams = useCallback(() => {
        setConcatenatedParams({ ...$state.params, ...params });
    }, [$state.params, params]);

    useEffect(() => {
        concatenateParams();
    }, [concatenateParams]);

    // when $state.params changes, it not creates a re-render, so storing the concatenated params in a useMemo
    // or just in a variable might hold the old value. To prevent it, we re-generate the concatenated params by
    // accessing $state.params when the user focuses or hovers over the anchor element.
    const compositeOnFocus = useCompositeEventCallback(onFocus, concatenateParams);
    const compositeOnMouseEnter = useCompositeEventCallback(onMouseEnter, concatenateParams);

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
        const onClickResult = onClickProp?.(event);

        // If should open with a new tab, or the user holds the alt key (which will download the page), or ctrl or meta
        // (which is command in mac), let the browser handle the click.
        if (openInNewTab || event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }

        // Prevent browser redirect
        event.preventDefault();

        const onClickResultAwaited = await onClickResult;
        if (onClickResultAwaited !== false && !dontChangeState) {
            const modifiedOptions = { ...(state === '.' ? { notify: false } : EMPTY_OBJECT), ...options };
            $timeout(() => {
                $state.go(state, concatenatedParams, modifiedOptions);
            });
        }
    };

    const url = useMemo(() => {
        return $state.href(state, concatenatedParams);
    }, [$state, state, concatenatedParams]);

    return (
        <a
            {...props}
            href={url}
            target={target}
            onClick={onClick}
            onFocus={compositeOnFocus}
            onMouseEnter={compositeOnMouseEnter}
            ref={ref}
        >
            {children}
        </a>
    );
};

export default React.forwardRef(StateLink);
