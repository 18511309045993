import useKeyShortcutCallback from './useKeyShortcutCallback';

import { createSymbolStack } from '@tonkean/utils';

export const addToEscapeCallbackStack = createSymbolStack();

/**
 * React hook for listening for an escape key press.
 *
 * @param callback - The callback function to trigger.
 * @param active - Should the listener be active? If not, it will remove it from the stack and won't block
 * others users of useEscapeCallback.
 * @param onlyIfNewest - If true, it will call the callback only if this is the 'newest' useEscapeCallback.
 * for example, if you have modal inside modal, it an escape will be triggered only in the child modal.
 */
function useEscapeCallback(
    callback: (event: KeyboardEvent) => void,
    active: boolean = true,
    onlyIfNewest: boolean = true,
) {
    useKeyShortcutCallback('Escape', callback, false, active, onlyIfNewest, addToEscapeCallbackStack);
}

export default useEscapeCallback;
