import React from 'react';
import { REMINDERS } from '@tonkean/constants';
import Dropdown from './Dropdown';
import { TrackActions } from '@tonkean/flux';
import { MenuItem } from './TUI';
import { Separator } from './TUI';
import styled from 'styled-components';
import { calculateNextReminderHour } from '@tonkean/tonkean-utils';
import { analyticsWrapper } from '@tonkean/analytics';

const NewMenuHeader = styled(MenuItem)`
    font-weight: bold;
`;

const OldMenuItem = ({ children, onClick, ...props }) => {
    return (
        <li {...props}>
            <a onClick={onClick}>{children}</a>
        </li>
    );
};

/**
 * A components that renders the track's set reminder (next gather update) dropdown menu.
 */
export default class TrackSetReminderDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        // The dropdown should only render itself if its isOpen status has changed (from true to false or vice versa).
        if (this.props.isOpen !== nextProps.isOpen) {
            return true;
        }
        return false;
    }

    // region: UI Actions

    /**
     * Toggles the dropdown open\close
     * @param value - if is boolean will take as is, else will toggle the current isOpen.
     */
    toggleDropdown(value) {
        if (typeof value !== 'boolean') {
            value = !this.props.isOpen;
        }

        TrackActions.toggleTrackSetReminderDropdown(this.props.id, this.props.editorId, value);
    }

    onAskForUpdatesClicked() {
        this.toggleDropdown(false);
        const owner = this.props.realTrack.owner;
        if (!owner.isTemporary) {
            analyticsWrapper.track('Ask for updates', {
                category: this.props.callerForAnalyticsForAskForUpdates,
            });
            const name = owner.name;
            const modal = this.props.modalUtils.openAskForUpdateModal(this.props.realTrack, name);
            modal.result.then(() => this.onReminderSet());
        }
    }

    onSetReminderClicked(reminder) {
        this.toggleDropdown(false);
        if (this.props.callerForAnalytics && this.props.callerForAnalytics.length) {
            analyticsWrapper.track('Set reminder', { category: this.props.callerForAnalytics });
        }
        let promise;
        if (reminder.isNone) {
            promise = this.props.trackHelper.updateNextReminderTime(this.props.realTrack, null);
        } else {
            const hour = calculateNextReminderHour(reminder);
            promise = this.props.trackHelper.updateNextReminder(this.props.realTrack, reminder.days, hour);
        }

        promise.then(() => this.onReminderSet());
    }

    onOpenSettingsModalClicked() {
        this.toggleDropdown(false);

        if (this.props.overrideOpenSettingsModal) {
            this.props.overrideOpenSettingsModal(this.props.realTrack, true, this.props.callerForAnalytics);
        } else {
            if (this.props.callerForAnalytics && this.props.callerForAnalytics.length) {
                analyticsWrapper.track('Set reminder', { category: this.props.callerForAnalytics });
            }

            if (this.props.onOpenSettingsModal) {
                this.props.onOpenSettingsModal(this.props.realTrack);
            }

            this.props.modalUtils.openInitiativeSettingsModal(this.props.realTrack, true, null, () =>
                this.onReminderSet(),
            );
        }
    }

    onReminderSet() {
        if (this.props.onReminderSet) {
            this.props.onReminderSet();
        }
    }

    // endregion: UI Actions

    render() {
        const realTrack = this.props.realTrack;
        const newMenu = this.props.newMenu;
        const ItemComponent = newMenu ? MenuItem : OldMenuItem;

        // Gather update now element (only exist if the owner is not the current user).
        let gatherUpdateNowElement = null;
        if (realTrack.owner && realTrack.owner.id !== this.props.currentUserId) {
            gatherUpdateNowElement = (
                <ItemComponent onClick={this.onAskForUpdatesClicked.bind(this)}>Now</ItemComponent>
            );
        }

        // Reminder options elements.
        const reminderOptions = REMINDERS.map((reminder, index) => (
            <ItemComponent key={`${this.props.id}-${index}`} onClick={this.onSetReminderClicked.bind(this, reminder)}>
                {reminder.label}
            </ItemComponent>
        ));

        // Render the dropdown itself.
        const content = (
            <>
                {newMenu ? (
                    <NewMenuHeader>Set next follow-up to:</NewMenuHeader>
                ) : (
                    <li className="dropdown-header">Set next follow-up to:</li>
                )}

                {gatherUpdateNowElement}
                {reminderOptions}
                <Separator />
                <ItemComponent onClick={this.onOpenSettingsModalClicked.bind(this)}>Custom</ItemComponent>
            </>
        );

        if (newMenu) {
            return content;
        }

        if (this.props.isOpen) {
            return (
                <Dropdown classes="dropdown-menu-right" onClickOutside={this.toggleDropdown.bind(this)}>
                    {content}
                </Dropdown>
            );
        }

        return null;
    }
}
