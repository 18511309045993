import { useId } from 'react';

/**
 * @deprecated use the native `useId` hook instead for id generation, of `utils.guid()` with useRef or useMemo for uuid.
 *
 * Will generate a new unique UUID. It safe to use in react because it will keep it's value between renders.
 *
 * @return a unique UUID string.
 */
function useUUID(): string {
    return useId();
}

export default useUUID;
