import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export enum ChipStatusOption {
    SUCCESS,
    WARNING,
    ERROR,
}

const statusToColor: Record<ChipStatusOption, string> = {
    [ChipStatusOption.SUCCESS]: Theme.colors.success,
    [ChipStatusOption.WARNING]: Theme.colors.warning,
    [ChipStatusOption.ERROR]: Theme.colors.error,
};

interface Props {
    status: ChipStatusOption;
}

const StatusChip = styled.div<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    padding: 0 5px;
    background: ${({ status }) => statusToColor[status]};
    border-radius: 100px;
    font-weight: 500;
    font-size: ${FontSize.XXSMALL_8};
    color: ${Theme.colors.invertedText};
    height: 14px;
    // This is to fix an alignment problem with all-uppercased text
    line-height: 11px;
    min-width: 48px;
`;

export default StatusChip;
