import type { OverflowInlineProperty } from 'csstype';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import type AccordionProps from './AccordionProps';
import CollapsibleContent from '../../CollapsibleContent';
import Chevron, { ChevronDirection } from '../Chevron';

import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { AccordionSize } from '@tonkean/tui-theme/sizes';

const sizes = Theme.sizes.accordion;

const Wrapper = styled.div<{
    $borderBottom: boolean;
    $borderTop: boolean;
    $sideBorders: boolean;
    $shadow: boolean;
    $borderRadius: boolean;
}>`
    background-color: ${Theme.colors.basicBackground};

    ${({ $borderBottom, $borderTop, $sideBorders }) => css`
        border-color: ${Theme.colors.gray_300};
        border-style: solid;
        border-width: ${$borderTop ? '1px' : '0'} ${$sideBorders ? '1px' : '0'} ${$borderBottom ? '1px' : '0'}
            ${$sideBorders ? '1px' : '0'};
    `};

    ${({ $shadow }) =>
        $shadow &&
        css`
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
        `}

    ${({ $borderRadius }) =>
        $borderRadius &&
        css`
            border-radius: 3px;
        `}
`;

export const INDENTATION = 20;

const HeaderButton = styled(DisableableButton)<{
    $size: AccordionSize;
    $titleIndentationLevel: number;
    $titleAlignmentRight: boolean;
    $headerNoPadding: boolean;
}>`
    display: flex;
    min-height: ${({ $size }) => sizes[$size].height}px;
    ${({ $headerNoPadding, $titleIndentationLevel }) =>
        !$headerNoPadding &&
        css`
            padding: 20px 15px 20px ${15 + $titleIndentationLevel * INDENTATION}px;
        `};

    background-color: transparent;
    border: none;
    width: 100%;
    align-items: center;
    text-align: left;
    ${({ $titleAlignmentRight }) =>
        $titleAlignmentRight &&
        css`
            flex-direction: row-reverse;
        `};
    position: relative;

    ${childrenStyledFocus}

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                background-color: ${Theme.colors.brightestGray};

                ${Chevron} svg path {
                    stroke: ${Theme.colors.gray_700};
                }
            }
        `};
`;

const StyledChevron = styled(Chevron)`
    margin-right: 10px;
`;

const Content = styled.div<{
    $paddingTop?: boolean;
    $whiteContentBackground: boolean;
    $contentSeparator: boolean;
    $overflow: OverflowInlineProperty;
}>`
    overflow: ${({ $overflow }) => $overflow};
    padding-top: ${({ $paddingTop }) => ($paddingTop ? 15 : 0)}px;

    ${({ $whiteContentBackground }) =>
        $whiteContentBackground
            ? css`
                  background-color: ${Theme.colors.basicBackground};
              `
            : css`
                  background-color: ${Theme.colors.gray_100};
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.04);
              `};

    ${({ $contentSeparator }) =>
        $contentSeparator &&
        css`
            border-top: 1px solid ${Theme.colors.gray_300};
        `};

    ${childrenStyledFocus}
`;

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
    open: openProp,
    onChange: onChangeProp,
    initialOpen = false,
    title,
    children,
    disabled,
    className,
    shadow = false,
    radius = false,
    headerNoPadding = false,
    contentPadding = false,
    borderBottom = true,
    borderTop = false,
    whiteContentBackground = false,
    contentSeparator = true,
    size = AccordionSize.MEDIUM,
    sideBorders = false,
    titleIndentationLevel = 0,
    titleAlignmentRight = false,
    overflow = 'hidden',
    dataAutomation,
}) => {
    const [openState, setOpenState] = useState(initialOpen);

    const open = openProp ?? openState;
    const onChange = onChangeProp ?? setOpenState;

    return (
        <Wrapper
            $borderBottom={borderBottom}
            $borderTop={borderTop}
            $sideBorders={sideBorders}
            $shadow={shadow}
            $borderRadius={radius}
            className={className}
            data-automation={dataAutomation}
        >
            <HeaderButton
                onClick={() => onChange(!open)}
                type="button"
                disabled={disabled}
                $size={size}
                $titleIndentationLevel={titleIndentationLevel}
                $titleAlignmentRight={titleAlignmentRight}
                $headerNoPadding={headerNoPadding}
            >
                <StyledChevron direction={open ? ChevronDirection.DOWN : ChevronDirection.RIGHT} />
                {title}
            </HeaderButton>
            <CollapsibleContent open={open}>
                <Content
                    $paddingTop={contentPadding}
                    $whiteContentBackground={whiteContentBackground}
                    $contentSeparator={contentSeparator}
                    $overflow={overflow}
                >
                    {children}
                </Content>
            </CollapsibleContent>
        </Wrapper>
    );
};

export default Accordion;
