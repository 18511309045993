import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import TabsBorderType from './TabsBorderType';
import useUUID from '../../../hooks/useUUID';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export const TabsContext = React.createContext<{
    showIndicator: boolean;
    indicatorClassName: string | undefined;
    setIndicatorClassName: React.Dispatch<React.SetStateAction<string | undefined>>;
    value: any;
    setValue?(value: any): void;
    showBackground: boolean;
    small: boolean;
    borderType: TabsBorderType;
    uuid: string;
    indicatorHeight: number;
    selectedColor: Color;
    notSelectedColor: Color;
    indicatorColor: Color;
    disableIndicatorAnimation?: boolean;
}>({} as any);

const Wrapper = styled.div<{ borderType: TabsBorderType; height?: string }>`
    display: flex;
    position: relative;

    ${({ borderType }) =>
        borderType === TabsBorderType.RAILWAY &&
        css`
            border-bottom: 2px solid ${Theme.current.palette.tabs.railwayColor};
        `};

    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `}
`;

interface Props extends StyledComponentsSupportProps {
    hideIndicator?: boolean;
    value: any;
    onChange?(value: any): void;
    borderType?: TabsBorderType;
    height?: string;
    showBackground?: boolean;
    small?: boolean;
    indicatorHeight?: number;
    selectedColor?: Color;
    notSelectedColor?: Color;
    indicatorColor?: Color;
    disableIndicatorAnimation?: boolean;
}

const Tabs: React.FC<React.PropsWithChildren<Props>> = ({
    value,
    onChange,
    hideIndicator,
    children,
    className,
    borderType = TabsBorderType.RAILWAY,
    small = false,
    height = small ? '35px' : '40px',
    showBackground = true,
    indicatorHeight = 2,
    selectedColor = Theme.current.palette.tabs.selected,
    notSelectedColor = Theme.current.palette.TUI.notSelectedText,
    indicatorColor = Theme.current.palette.tabs.selected,
    disableIndicatorAnimation,
}) => {
    const uuid = useUUID();
    const [indicatorClassName, setIndicatorClassName] = useState();

    const contextValue = useMemo(
        () => ({
            showIndicator: !hideIndicator,
            indicatorClassName,
            setIndicatorClassName,
            value,
            setValue: (newValue) => {
                // If it's the same value as the selected value no onChange should be triggered
                if (newValue !== value) {
                    onChange?.(newValue);
                }
            },
            showBackground,
            small,
            borderType,
            uuid,
            indicatorHeight,
            selectedColor,
            notSelectedColor,
            indicatorColor,
            disableIndicatorAnimation,
        }),
        [
            hideIndicator,
            indicatorClassName,
            value,
            showBackground,
            small,
            borderType,
            uuid,
            indicatorHeight,
            selectedColor,
            notSelectedColor,
            indicatorColor,
            onChange,
            disableIndicatorAnimation,
        ],
    );

    return (
        <TabsContext.Provider value={contextValue}>
            <Wrapper className={className} borderType={borderType} height={height}>
                {children}
            </Wrapper>
        </TabsContext.Provider>
    );
};

export default Tabs;
