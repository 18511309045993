import React, { useContext } from 'react';

import DialogsContext from './DialogsContext';
import SingleDialog from './SingleDialog';

const Dialogs: React.FC = () => {
    const dialogs = useContext(DialogsContext);

    return (
        <>
            {dialogs.map((dialog) => (
                <SingleDialog key={dialog.id} dialog={dialog} />
            ))}
        </>
    );
};

export default Dialogs;
