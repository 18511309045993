import { createFocusTrap } from 'focus-trap';
import type React from 'react';
import { useEffect, useState } from 'react';

/**
 * React hook that will trap the focus inside the container element. Useful for modals,
 * to prevent the focus from getting outside of the modal where the user can't currently
 * reach.
 * If more then one focus trap will be triggered, it will trap the focus only on the 'newest'
 * component, you should deactivate the focus trap when not in use, by passing undefined or
 * null to the containerElement param.
 *
 * @param active - is the focus trap active?
 * @param initialFocusRef - an html element to set the focus when the focus trap is activated.
 * @param restoreFocus - when the focus trap deactivates, should the original focus be restored?
 * @param restoreFocusElementRef - An element ref to restore focus to on deactivation.
 * @param clickOutsideDeactivates - If true would disable focus trap on click outside
 * @returns function that expects to receive the container element as it's param. If passed
 * undefined, it will deactivate the focus trap.
 */
function useFocusTrap(
    active: boolean = true,
    initialFocusRef?: React.RefObject<HTMLElement>,
    restoreFocus: boolean = true,
    restoreFocusElementRef?: React.RefObject<HTMLElement>,
    clickOutsideDeactivates?: boolean,
) {
    const [containerElement, setContainerElement] = useState<HTMLElement | undefined | null>();

    useEffect(() => {
        if (!containerElement || !active) {
            return;
        }

        const focusTrap = createFocusTrap(containerElement, {
            returnFocusOnDeactivate: restoreFocus,
            escapeDeactivates: false,
            clickOutsideDeactivates,
            allowOutsideClick: () => true,
            initialFocus: initialFocusRef?.current || undefined,
            fallbackFocus: containerElement,
            delayInitialFocus: true,
            setReturnFocus: (nodeFocusedBeforeActivation) =>
                restoreFocusElementRef?.current ?? nodeFocusedBeforeActivation,
            preventScroll: true,
        });

        // This is to wait for the current javascript call stack and previous items in the event queue to complete,
        // especially, all the focus changes are done (for example, the focus restore operation in a previous focus
        // trap).
        setTimeout(() => focusTrap.activate(), 0);

        return () => {
            focusTrap.deactivate({ returnFocus: restoreFocus });
        };
    }, [active, clickOutsideDeactivates, containerElement, initialFocusRef, restoreFocus, restoreFocusElementRef]);

    return setContainerElement;
}

export default useFocusTrap;
