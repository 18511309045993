import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ColorCircle } from './ColorCircle';
import type { CustomColor } from './CustomColor';
import CustomColorListPicker from './CustomColorListPicker';
import HexColorPicker from './HexColorPicker';
import Chevron, { ChevronDirection } from '../Chevron';
import { Popover } from '../Popover';
import { Tab, Tabs } from '../Tabs';

import { IconButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const StyledButton = styled(IconButton)`
    padding: 6px 7px;
`;

const StyledTabs = styled(Tabs)`
    width: 347px;
`;

const TabsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 0;
    background-color: ${Theme.colors.basicBackground};
    z-index: 1;
`;

const ColorPickers = styled.div`
    height: 240px;
`;

const StyledPickerContainer = styled(HexColorPicker)`
    margin: 10px;
`;

enum HexColorPickerTabs {
    GLOBAL_COLOR = 'Global Colors',
    CUSTOM = 'Custom',
}

interface HexColorPickerWithListProps extends DataAutomationSupportProps {
    onChange(newColor: Color, newCustomColorId?: string): void;
    name?: string;
    ariaLabel?: string;
    alpha?: boolean;
    disabled?: boolean;
    flat?: boolean;
    noBackgroundColor?: boolean;
    showBorder?: boolean;
    className?: string;
    customColors: CustomColor[];
    selectedColor?: Color;
    selectedCustomColorId?: string;
}
const PopoverHexColorPickerWithList: React.FC<HexColorPickerWithListProps> = ({
    selectedColor,
    selectedCustomColorId,
    onChange: onChangeProps,
    alpha = false,
    ariaLabel = 'open color picker',
    disabled,
    dataAutomation,
    flat,
    noBackgroundColor,
    showBorder = false,
    className,
    customColors,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(
        selectedCustomColorId && customColors.some((color) => color.id === selectedCustomColorId)
            ? HexColorPickerTabs.GLOBAL_COLOR
            : HexColorPickerTabs.CUSTOM,
    );

    const onClose = useCallback(() => {
        setIsOpen(false);
        setSelectedTab(selectedCustomColorId ? HexColorPickerTabs.GLOBAL_COLOR : HexColorPickerTabs.CUSTOM);
    }, [selectedCustomColorId]);

    return (
        <Popover
            show={isOpen}
            onClose={onClose}
            content={
                <>
                    <TabsWrapper>
                        <StyledTabs value={selectedTab} onChange={setSelectedTab} height="46px" showBackground={false}>
                            <Tab label="Global Colors" width="50%" dataAutomation="popover-hex-color-picker-with-list">
                                Global Colors
                            </Tab>
                            <Tab label="Custom" width="50%" dataAutomation="popover-hex-color-picker-with-list">
                                Custom
                            </Tab>
                        </StyledTabs>
                    </TabsWrapper>
                    <ColorPickers>
                        {selectedTab === HexColorPickerTabs.GLOBAL_COLOR ? (
                            <CustomColorListPicker
                                customColors={customColors}
                                onChange={onChangeProps}
                                selectedCustomColorId={selectedCustomColorId}
                            />
                        ) : (
                            <StyledPickerContainer
                                value={selectedColor}
                                onChange={onChangeProps}
                                alpha={alpha}
                                dataAutomation={`${dataAutomation}-input`}
                            />
                        )}
                    </ColorPickers>
                </>
            }
            placement="bottom-end"
            noPadding
        >
            <StyledButton
                flat={flat}
                noBackgroundColor={noBackgroundColor}
                showBorder={showBorder}
                aria-label={ariaLabel}
                rightIcon={<Chevron direction={ChevronDirection.DOWN} />}
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                data-automation={dataAutomation}
                className={className}
            >
                <ColorCircle $selectedColor={selectedColor} />
            </StyledButton>
        </Popover>
    );
};

export default PopoverHexColorPickerWithList;
