import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TnkSelect } from './TUI';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { ActionType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

interface ProjectIntegrationActionOption {
    label: string;
    value: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
}

const ErrorText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
    margin-top: 10px;
`;

interface Props {
    selectedProjectIntegrationActionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined;
    projectIntegration: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    onProjectIntegrationActionChanged: (
        projectIntegrationAction: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined,
    ) => void;
    actionTypes: ActionType[];
}

const ProjectIntegrationActionSelector: React.FC<Props> = ({
    selectedProjectIntegrationActionId,
    projectIntegration,
    onProjectIntegrationActionChanged,
    actionTypes,
}) => {
    const [selectedAction, setSelectedAction] = useState<ProjectIntegrationActionOption | undefined>(undefined);

    const {
        data: customActions,
        loading: storageIntegrationActionLoading,
        error: storageIntegrationActionError,
    } = useTonkeanService('getProjectIntegrationActions', projectIntegration, actionTypes);

    // If we have already selected action we find the action and set our state accordingly it.
    useEffect(() => {
        if (selectedProjectIntegrationActionId && customActions?.entities) {
            const selectedProjectIntegrationAction = customActions.entities.find(
                (customAction) => customAction.id === selectedProjectIntegrationActionId,
            );
            if (selectedProjectIntegrationAction) {
                setSelectedAction({
                    label: selectedProjectIntegrationAction.displayName,
                    value: selectedProjectIntegrationAction.id,
                });
                onProjectIntegrationActionChanged(selectedProjectIntegrationAction.id);
            }
        }
    }, [customActions?.entities, onProjectIntegrationActionChanged, selectedProjectIntegrationActionId]);

    // If we don't have any selected action we want to choose the first in the list
    useEffect(() => {
        if (!selectedAction && customActions?.entities?.[0] !== undefined && !selectedProjectIntegrationActionId) {
            setSelectedAction({
                label: customActions.entities[0].displayName,
                value: customActions.entities[0].id,
            });
            onProjectIntegrationActionChanged(customActions.entities[0].id);
        }
    }, [
        customActions?.entities,
        onProjectIntegrationActionChanged,
        selectedAction,
        selectedProjectIntegrationActionId,
    ]);

    const customActionsOptions = useMemo<ProjectIntegrationActionOption[]>(() => {
        return (
            customActions?.entities.map((customAction) => ({
                value: customAction.id,
                label: customAction.displayName,
            })) || []
        );
    }, [customActions?.entities]);

    return (
        <>
            {storageIntegrationActionError && <ErrorText> Error loading the download actions </ErrorText>}

            {customActions?.entities && (
                <TnkSelect
                    placeholder="Select action"
                    options={customActionsOptions}
                    isLoading={storageIntegrationActionLoading}
                    value={selectedAction}
                    onChange={(customActionsOption) => {
                        if (customActionsOption !== null) {
                            setSelectedAction(customActionsOption);
                        }
                        onProjectIntegrationActionChanged(customActionsOption?.value);
                    }}
                    thin
                />
            )}
        </>
    );
};

export default ProjectIntegrationActionSelector;
