import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import type { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';
import styled, { css } from 'styled-components';

import { useInnerField } from './Field';
import { InputStyling } from './Input/Input';

import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';

const getVerticalPadding = ({ $size = InputSize.MEDIUM }) => {
    return (Theme.sizes.input[$size].height - Theme.sizes.input[$size].lineHeight) / 2;
};

const StyledTextarea = styled(TextareaAutosize)<{
    $isError: boolean;
    $size: InputComponentSizes;
    $isHighlighted: boolean;
    $hasMaxRows: boolean;
}>`
    ${InputStyling};
    display: block;
    padding-top: ${getVerticalPadding}px;
    padding-bottom: ${getVerticalPadding}px;
    resize: none;

    ${({ $hasMaxRows }) =>
        !$hasMaxRows &&
        css`
            overflow-y: hidden;
        `}
`;

interface Props extends TextareaAutosizeProps {
    isError?: boolean;
    size?: InputComponentSizes;
    isHighlighted?: boolean;
}

const Textarea: React.ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
    { size = InputSize.MEDIUM as InputComponentSizes, isError: hasErrorProp, isHighlighted = false, ...props },
    ref,
) => {
    const [fieldProps, hasError] = useInnerField({ type: 'textarea', multiple: false, ...props });

    return (
        <StyledTextarea
            $isError={hasError || hasErrorProp || false}
            $isHighlighted={isHighlighted}
            $hasMaxRows={props.maxRows !== undefined}
            $size={size}
            {...props}
            {...fieldProps}
            ref={ref}
        />
    );
};

export default React.forwardRef(Textarea);
