import type EnrichedTrainingSetItemResponse from './EnrichedTrainingSetItemResponse';
import type TrainingSetFieldIdentifiers from './TrainingSetFieldIdentifiers';
import type TrainingSetModelSummary from './TrainingSetModelSummary';
import type { TrainingSetEvaluation } from '../TrainingSetItem';

export enum TrainingSetItemsRowType {
    NOT_EVALUATED = 'NOT_EVALUATED',
    PARTIAL = 'PARTIAL',
    FULL = 'FULL',
}

interface TrainingSetItemsAndEvaluationRelatedEntities {
    enrichedItems: EnrichedTrainingSetItemResponse[];
    evaluationRelatedFields: TrainingSetFieldIdentifiers[];
    evaluationRelatedModels: TrainingSetModelSummary[];
    trainingSetEvaluation?: TrainingSetEvaluation;
}

export default TrainingSetItemsAndEvaluationRelatedEntities;
