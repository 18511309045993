import React from 'react';
import styled from 'styled-components';

import { ReactComponent as XIcon } from '../../../../images/red-x.svg';

import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div<{ largeWrapper: boolean }>`
    width: ${({ largeWrapper }) => (largeWrapper ? '350' : '300')}px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${Theme.colors.gray_500};
    margin-bottom: 18px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 1fr) 1fr;
    grid-gap: 14px 7px;
    align-items: baseline;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;
`;

const ClearButton = styled(Button)`
    .tnk-icon {
        margin-right: 3px;

        svg {
            width: 8px;
            height: 8px;
        }
    }
`;

interface Props {
    title?: string;

    clearDisabled?: boolean;
    clearDataAutomation?: string;

    /** Will be triggered when the clear button is pressed. If undefined, the button won't be shown. */
    onClear?(): void;

    submitDisabled?: boolean;
    submitDataAutomation?: string;

    /** Will be triggered when the submit button is pressed. If undefined, the button won't be shown. */
    onSubmit?(): void;

    GridTemplate?: React.JSXElementConstructor<any>;
    className?: string;

    largeWrapper?: boolean;
}

const FiltersGroup: React.FC<React.PropsWithChildren<Props>> = ({
    title = 'Filter By',
    children,
    submitDisabled = false,
    clearDisabled = false,
    clearDataAutomation,
    submitDataAutomation,
    onClear,
    onSubmit,
    GridTemplate = Grid,
    className = '',
    largeWrapper = false,
}) => {
    const hasButtons = !!onClear && !!onSubmit;

    return (
        <Wrapper className={className} largeWrapper={largeWrapper}>
            <Title>{title}</Title>

            <GridTemplate>{children}</GridTemplate>

            {hasButtons && (
                <Buttons>
                    {!!onClear && (
                        <ClearButton
                            className={!!onSubmit ? 'margin-right' : undefined}
                            onClick={onClear}
                            size={ButtonSize.SMALL}
                            disabled={clearDisabled}
                            data-automation={clearDataAutomation}
                            outlined
                        >
                            <span className="tnk-icon">
                                <XIcon />
                            </span>
                            Clear filters
                        </ClearButton>
                    )}

                    {!!onSubmit && (
                        <Button
                            onClick={onSubmit}
                            size={ButtonSize.SMALL}
                            disabled={submitDisabled}
                            data-automation={submitDataAutomation}
                            outlined
                        >
                            Apply
                        </Button>
                    )}
                </Buttons>
            )}
        </Wrapper>
    );
};

export default FiltersGroup;
