import React, { useEffect, useRef, useState } from 'react';
import { tabbable } from 'tabbable';

import useEscapeCallback from '../../../hooks/useEscapeCallback';
import useFocusTrap from '../../../hooks/useFocusTrap';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import TooltipElement from '../Tooltip/TooltipElement';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import type { TooltipColor } from '@tonkean/tui-theme/colors';
import type { TooltipSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    popperStyles: React.CSSProperties;
    popperAttributes: Record<string, string> | undefined;
    showArrow: boolean;
    color: TooltipColor;
    size: TooltipSize;
    width?: string;
    /**
     * If true, that focus will be trapped in the popover, and exiting the popover will be only using click outside
     * or pressing the escape key. If false, when the focus exists from the popover, it will close the modal.
     */
    trapFocus?: boolean;
    /**
     * Whether to manage the focus, if false, the trap focus property value will be ignored.
     */
    manageFocus?: boolean;
    /** Whether the initially focused element be the wrapper. Otherwise, the first focusable element will be used. */
    initiallyFocusOnWrapper?: boolean;
    /** Whether the original focus be restored when closing the popover. */
    restoreFocusWhenClosed?: boolean;
    /** If the popover is opened by hover, it won't be closed by clicking outside and won't be added to the stack. */
    openedByHover?: boolean;
    noPadding?: boolean;
    overflowVisible: boolean;
    closeOnOutsideRightClick?: boolean;
    setArrowElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    arrowStyles: React.CSSProperties;
    show: boolean;
    onClose(): void;
    onExited?(): void;
    /** Element to restore focus to when PopoverElement is removed from the DOM. */
    restoreFocusElementRef?: React.RefObject<HTMLElement>;
}

const PopoverElement: React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<Props>> = (
    {
        popperStyles,
        popperAttributes,
        arrowStyles,
        setArrowElement,
        children,
        showArrow,
        color,
        size,
        width,
        trapFocus = true,
        initiallyFocusOnWrapper = true,
        restoreFocusWhenClosed = true,
        openedByHover = false,
        className,
        closeOnOutsideRightClick = false,
        noPadding = false,
        show,
        onClose,
        onExited,
        manageFocus = true,
        overflowVisible,
        restoreFocusElementRef,
    },
    ref,
) => {
    const tooltipRef = useRef<HTMLDivElement>(null);

    // This is used to keep the hooks that support onlyIfNewest (like the useOnClickOutside) active until the modal
    // fully closes and removed from the DOM, to prevent bugs like that triggers an clickOutside event on the parent
    // modal when pressing on this modal while it's closing.
    const [modalIsInDOM, setModalIsInDOM] = useState(show);

    useEffect(() => {
        const tooltipElement = tooltipRef.current;

        if (!show || !tooltipElement || trapFocus) {
            return;
        }

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key !== 'Tab') {
                return;
            }

            // Get tabbable elements within this element
            const tabbableElements = tabbable(tooltipElement, { includeContainer: true });

            const firstIsFocused = event.target === tabbableElements[0];
            const lastIsFocused = event.target === tabbableElements[tabbableElements.length - 1];

            const escapingFromFirstFocusable = event.shiftKey && firstIsFocused;
            const escapingFromLastFocusable = !event.shiftKey && lastIsFocused;

            if (escapingFromFirstFocusable || escapingFromLastFocusable) {
                event.preventDefault();
                onClose();
            }
        };
        tooltipElement.addEventListener('keydown', onKeyDown, true);

        return () => {
            tooltipElement.removeEventListener('keydown', onKeyDown, true);
        };
    }, [show, trapFocus, onClose]);

    useEscapeCallback(onClose, modalIsInDOM);
    const setOnClickOutsideElementRef = useOnClickOutside(
        onClose,
        modalIsInDOM && !openedByHover,
        true,
        false,
        closeOnOutsideRightClick,
    );
    const setFocusTrapContainerElementRef = useFocusTrap(
        manageFocus && modalIsInDOM,
        initiallyFocusOnWrapper ? tooltipRef : undefined,
        restoreFocusWhenClosed,
        restoreFocusElementRef,
    );

    const sharedRef = useMultipleRefCallback<HTMLDivElement>(
        ref,
        tooltipRef,
        setOnClickOutsideElementRef,
        setFocusTrapContainerElementRef,
    );

    return (
        <TooltipElement
            shown={show}
            onEnter={() => {
                setModalIsInDOM(true);
            }}
            onExited={() => {
                setModalIsInDOM(false);
                onExited?.();
            }}
            popperStyles={popperStyles}
            popperAttributes={popperAttributes}
            showArrow={showArrow}
            color={color}
            size={size}
            width={width}
            noPadding={noPadding}
            arrowStyles={arrowStyles}
            setArrowElement={setArrowElement}
            className={className}
            ref={sharedRef}
            overflowVisible={overflowVisible}
            popover
        >
            {children}
        </TooltipElement>
    );
};

export default React.forwardRef(PopoverElement);
