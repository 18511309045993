import type React from 'react';

import useConstantRefCallback from './useConstantRefCallback';

/**
 * React hook for handling multiple callbacks for the same event. It works by checking before each callback trigger
 * if the event has been prevented, and if not, will trigger the next callback. Because of that, to prevent from the
 * next callback to be executed, you should run `event.preventDefault()` in the previous callback.
 *
 * @param eventCallbacks - queue of event callbacks to run. Undefined items in the queue will be skipped.
 * @returns a composite event callback to pass to the JSX element.
 */
function useCompositeEventCallback<T extends React.SyntheticEvent<HTMLElement, Event>>(
    ...eventCallbacks: (React.EventHandler<T> | undefined)[]
) {
    const compositeEventCallback = useConstantRefCallback((event: T) => {
        eventCallbacks.every((eventCallback) => {
            if (event.defaultPrevented) {
                return false;
            }

            eventCallback?.(event);
            return true;
        });
    });

    return compositeEventCallback;
}

export default useCompositeEventCallback;
