enum FindWordsExtractionStepType {
    TAKE_BETWEEN_BEFORE_AND_AFTER = 'TAKE_BETWEEN_BEFORE_AND_AFTER',
    TAKE_X_WORDS = 'TAKE_X_WORDS',
    TAKE_X_LINES = 'TAKE_X_LINES',
    TAKE_X_WORDS_BACKWARDS = 'TAKE_X_WORDS_BACKWARDS',
    TAKE_X_LINES_BACKWARDS = 'TAKE_X_LINES_BACKWARDS',
    TAKE_X_CHARACTERS = 'TAKE_X_CHARACTERS',
    TAKE_END_OF_LINE = 'TAKE_END_OF_LINE',
    TAKE_END_OF_DOCUMENT = 'TAKE_END_OF_DOCUMENT',
    TAKE_FIRST_WORD_OF_TYPE = 'TAKE_FIRST_WORD_OF_TYPE',
    TAKE_FIRST_WORD_OF_TYPE_FROM_END = 'TAKE_FIRST_WORD_OF_TYPE_FROM_END',
    TAKE_ENGLISH_CHARACTERS_ONLY = 'TAKE_ENGLISH_CHARACTERS_ONLY',
}

export default FindWordsExtractionStepType;
