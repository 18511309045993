import React from 'react';
import styled, { css } from 'styled-components';

import useResponsiveInputSize from '../../../hooks/useResponsiveInputSize';
import { useInnerField } from '../Field';

import { Theme } from '@tonkean/tui-theme';
import { InputSize, type InputComponentSizes } from '@tonkean/tui-theme/sizes';

export function getBorderColor(isError: boolean, isHighlighted: boolean, focused: boolean) {
    if (isError) {
        return Theme.colors.error;
    } else if (isHighlighted) {
        return Theme.colors.primaryHighlight;
    } else if (focused) {
        return Theme.colors.focus;
    } else {
        return Theme.colors.gray_400;
    }
}

export interface InputStylingProps {
    $isError: boolean;
    $size: InputComponentSizes;
    $isHighlighted?: boolean;
    disabled?: boolean | undefined;
}

export const InputStyling = css<InputStylingProps>`
    ${({ disabled }) => disabled && `background-color: ${Theme.colors.disabled};`}
    width: 100%;
    border-radius: ${({ $size }) => Theme.sizes.input[$size].radius}px;
    padding: ${({ $size }) =>
            Theme.sizes.input[$size].paddingTopBottom === 0 ? 0 : `${Theme.sizes.input[$size].paddingTopBottom}px`}
        ${({ $size }) => Theme.sizes.input[$size].paddingRightLeft}px;
    height: ${({ $size }) => Theme.sizes.input[$size].height}px;
    font-size: ${({ $size }) => Theme.sizes.input[$size].fontSize}px;
    line-height: ${({ $size }) => Theme.sizes.input[$size].lineHeight}px;
    color: ${Theme.colors.gray_800};
    border: 1px solid ${({ $isError, $isHighlighted = false }) => getBorderColor($isError, $isHighlighted, false)};
    font-weight: 400;
    outline: none;

    &::placeholder {
        color: ${Theme.colors.gray_500};
    }

    ${({ disabled, $isError, $isHighlighted = false }) =>
        !disabled &&
        css`
            &:focus {
                border: 1px solid ${getBorderColor($isError, $isHighlighted, true)};
            }
        `}
`;

const StyledInput = styled.input<InputStylingProps>`
    ${InputStyling};
`;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    isError?: boolean;
    size?: InputComponentSizes;
    mobileSize?: InputComponentSizes;
    isHighlighted?: boolean;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        type = 'text',
        size = InputSize.MEDIUM as InputComponentSizes,
        mobileSize = InputSize.XLARGE as InputComponentSizes,
        isError: hasErrorProp,
        isHighlighted = false,
        ...props
    },
    ref,
) => {
    const [fieldProps, hasError] = useInnerField({ type, multiple: false, ...props });
    const inputSize = useResponsiveInputSize(size, mobileSize) as InputComponentSizes;

    return (
        <StyledInput
            type={type}
            $isError={hasError || hasErrorProp || false}
            $isHighlighted={isHighlighted}
            $size={inputSize}
            {...props}
            {...fieldProps}
            ref={ref}
        />
    );
};

export default React.forwardRef(Input);
