enum InputSize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    MEDIUM_LARGE = 'MEDIUM_LARGE',
    XMEDIUM_LARGE = 'XMEDIUM_LARGE',
    LARGE = 'LARGE',
    XLARGE = 'XLARGE',
}

export type InputComponentSizes =
    | InputSize.MEDIUM
    | InputSize.MEDIUM_LARGE
    | InputSize.XMEDIUM_LARGE
    | InputSize.LARGE
    | InputSize.XLARGE;

export default InputSize;
