enum RegexValidationType {
    EMAIL_VALIDATION = 'EMAIL_VALIDATION',
    URL_VALIDATION = 'URL_VALIDATION',
    PHONE_NUMBER_VALIDATION = 'PHONE_NUMBER_VALIDATION',
    CUSTOM_VALIDATION = 'CUSTOM_VALIDATION',
}

export const validationToDisplayName: Record<RegexValidationType, string> = {
    EMAIL_VALIDATION: 'Valid Email',
    URL_VALIDATION: 'Valid URL',
    PHONE_NUMBER_VALIDATION: 'Valid Phone Number ',
    CUSTOM_VALIDATION: 'Custom Validation',
};

export default RegexValidationType;
