import { useEffect, useMemo, useRef, useState } from 'react';

import type TransitionCallbacks from './TransitionCallbacks';
import type TransitionOptions from './TransitionOptions';
import appearTransitionState from './TransitionState/appearTransitionState';
import enterTransitionState from './TransitionState/enterTransitionState';
import exitTransitionState from './TransitionState/exitTransitionState';
import type TransitionState from './TransitionState/TransitionState';
import TransitionSubState from './TransitionSubState';
import requestCleanupableAnimationFrame from './utils/requestCleanupableAnimationFrame';

/**
 * A react hook to trigger a CSS transition, inspired by CSSTransition.
 *
 * @param options - the CSS transition options.
 * @returns an array with the first item as the classname, and the second one indicates whether the component should be
 * mounted.
 */
function useCSSTransition(options: TransitionOptions): [string, boolean] {
    const {
        classNames: classNameParam,
        timeout,
        show,
        appear = false,
        enter = true,
        exit = true,
        mountOnEnter = false,
        unmountOnExit = false,
        ...callbacks
    } = options;

    const isHookMountRef = useRef(true);

    // We store many params in a reference to allow omitting them from the deps array of the useEffect as it
    // shouldn't cause a re-run over the sub stages.
    const callbackRef = useRef<TransitionCallbacks>(callbacks);
    callbackRef.current = callbacks;
    const timeoutDurationRef = useRef(timeout);
    timeoutDurationRef.current = timeout;
    const unmountOnExitRef = useRef(unmountOnExit);
    unmountOnExitRef.current = unmountOnExit;
    const allowAppearAnimationRef = useRef(appear);
    allowAppearAnimationRef.current = appear;
    const allowEnterAnimationRef = useRef(enter);
    allowEnterAnimationRef.current = enter;
    const allowExitAnimationRef = useRef(exit);
    allowExitAnimationRef.current = exit;

    // If the initial show is true, then mounted should be initially true. If it's false and mountOnEnter is false, the
    // component will be auto-mounted. If mountOnEnter is true, it will mount only when on becomes true.
    const [mounted, setMounted] = useState(show || !mountOnEnter);
    const [transitionState, setTransitionState] = useState<TransitionState>();
    const [transitionSubState, setTransitionSubState] = useState<TransitionSubState>();

    /**
     * Update the state based on the show value.
     */
    useEffect(() => {
        const isMount = isHookMountRef.current;
        isHookMountRef.current = false;

        if (isMount) {
            if (show && allowAppearAnimationRef.current) {
                setTransitionState(appearTransitionState);
            }
        } else {
            if (show) {
                if (allowEnterAnimationRef.current) {
                    setTransitionState(enterTransitionState);
                }
            } else {
                if (allowExitAnimationRef.current) {
                    setTransitionState(exitTransitionState);
                }
            }
        }

        setTransitionSubState(TransitionSubState.START);
    }, [show]);

    /**
     * Update the sub state based on the params and trigger the callbacks.
     */
    useEffect(() => {
        if (transitionState === undefined) {
            return;
        }

        setMounted(true);

        // Set the start sub state on the next render cycle.
        return requestCleanupableAnimationFrame(() => {
            transitionState.setStartSubState(setTransitionSubState, callbackRef.current);

            // Wait for the next animation frame to apply the active sub state to allow the browser render the start
            // sub state - otherwise, it will paint both sub states on the same render, and the active value will
            // immediately override it and the start sub state classes won't be seen as the initial.
            return requestCleanupableAnimationFrame(() => {
                transitionState.setActiveSubState(setTransitionSubState, callbackRef.current);

                // Set the done sub state after the timeout passes.
                const doneTimeoutId = setTimeout(() => {
                    transitionState.setDoneSubState(
                        setTransitionSubState,
                        callbackRef.current,
                        unmountOnExitRef.current,
                        () => setMounted(false),
                    );
                }, timeoutDurationRef.current);

                return () => {
                    clearTimeout(doneTimeoutId);
                };
            });
        });
    }, [transitionState]);

    const className = useMemo(() => {
        if (!transitionState || !transitionSubState) {
            return '';
        }

        return transitionState.getClassName(classNameParam, transitionSubState);
    }, [classNameParam, transitionState, transitionSubState]);

    return [className, mounted];
}

export default useCSSTransition;
