import type { FieldType } from '../../FieldDefinition';
import type FormulaField from '../FormulaField';
import type { FormulaTreeNodeType } from '../FormulaTreeNodeType';
import type { ServerFormulaExpressionNode } from '../ServerFormulaExpressionNode';
import ValidationError from '../validationErrors/ValidationError';

abstract class FormulaTreeNodeBase {
    /**
     * Counter to create a unique id for each node.
     */
    private static idCounter: number = 0;
    /**
     * Node's data type. undefined if invalid.
     */
    public dataType: FieldType | undefined;
    /**
     * Boolean indication whether the node and it's children are valid.
     */
    public valid: boolean;
    /**
     * Boolean indicates if the node is the source of the errors, or if it's invalid only because it has
     * invalid children. If validation fails and this value is not set, it will be auto-set to true. If there
     * are no validation errors, it will override it's value to false as there are no errors.
     */
    public errorSource: boolean;
    /**
     * List of validation errors.
     */
    public validationErrors: ValidationError[] = [];

    /**
     * @param type - Node's type.
     * @param field - the field of the node.
     * @param id - Node's unique id.
     */
    protected constructor(
        public type: FormulaTreeNodeType,
        public field: FormulaField,
        public id: number = FormulaTreeNodeBase.idCounter++,
    ) {}

    /**
     * Should return the type of the node.
     *
     * @return undefined if field is empty, otherwise it's field type.
     */
    protected abstract getType(): FieldType | undefined;

    /**
     * Clones the tree node.
     *
     * @param field - the new field of the node.
     */
    public abstract clone(field: FormulaField): FormulaTreeNodeBase;

    /**
     * Should convert this formula node to string formula.
     *
     * @param evaluated - should the string contain variable ids instead of names?
     * @return a formula string that can be used as the formula.
     */
    public abstract toString(evaluated?: boolean): string;

    public abstract getServerFormulaNode(): ServerFormulaExpressionNode | undefined;

    /**
     * Label to display when describing the node by its conceptual type (parameter\function) and not its value
     */
    public toNodeDisplayName(): string {
        return this.field.displayName;
    }

    /**
     * The value that will be displayed in the formula builder
     */
    public toDisplayString(): string {
        return this.toString();
    }

    /**
     * Validates the node and stores the result. If valid, it will 'calculate' the data type.
     */
    public preformValidationAndDataTypeCalculation() {
        // This process can be run only once
        if (this.valid !== undefined) {
            return;
        }

        const validation = this.validate();
        if (validation) {
            this.valid = true;
            this.errorSource = false;
            this.dataType = this.getType();
            return;
        }

        this.valid = false;
    }

    /**
     * Will add an error to the node and set the node as the error source
     *
     * @param error - the error message or the error instance
     */
    public setError(error: string | ValidationError): void {
        const errorInstance = typeof error === 'string' ? new ValidationError(error) : error;

        // Setting as any because FormulaTreeNodeBase !== formulaTreeNode even thought in reality it is
        errorInstance.validationSource = this as any;

        this.validationErrors.push(errorInstance);
        this.errorSource = true;
        this.valid = false;
    }

    /**
     * Validates the node.
     *
     * @return true if the node is valid, otherwise an array with validation error objects.
     */
    protected validate(): boolean {
        if (!this.field) {
            this.setError('This field is redundant');
            return false;
        }

        return true;
    }
}

export default FormulaTreeNodeBase;
