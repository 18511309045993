import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import FieldContext from './FieldContext';
import type FieldOptions from './FieldOptions';
import InformationTooltip from '../InformationTooltip/InformationTooltip';

import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div<{ $showLabelInline: boolean }>`
    position: relative;
    display: ${({ $showLabelInline }) => ($showLabelInline ? 'flex' : 'block')};
    align-items: center;
`;

const Label = styled.label<{ $light: boolean; $showLabelInline: boolean }>`
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    font-weight: ${({ $light }) => ($light ? 400 : 500)};
    margin-bottom: ${({ $showLabelInline }) => ($showLabelInline ? '0' : '10')}px;

    ${({ $showLabelInline }) =>
        $showLabelInline &&
        css`
            margin-right: 5px;
        `};
`;

const ExpressionDescription = styled.div`
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${Theme.colors.gray_600};
    margin-bottom: 10px;
`;

const LabelText = styled.div<{ $tag: boolean; $fullWidthLabel: boolean }>`
    ${({ $fullWidthLabel }) =>
        $fullWidthLabel &&
        css`
            flex-grow: 1;
        `}

    ${({ $tag }) =>
        $tag &&
        css`
            color: ${Theme.colors.gray_700};
            background-color: ${Theme.colors.gray_300};
            padding: 3px 8px;
            border-radius: 3px;
        `};
`;

const Optional = styled.span`
    color: ${Theme.colors.gray_500};
    font-weight: 400;
`;

const StyledToolTip = styled(InformationTooltip)`
    margin-left: 5px;
`;

export const FieldError = styled.div<{ $inlineError: boolean; $textAlign?: React.CSSProperties['textAlign'] }>`
    margin-top: 6px;
    color: ${Theme.colors.error};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    text-align: ${({ $textAlign = 'unset' }) => $textAlign};

    ${({ $inlineError }) =>
        !$inlineError &&
        css`
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
        `}
`;

interface SharedProps extends StyledComponentsSupportProps {
    light?: boolean;
    error?: React.ReactNode;
    inlineError?: boolean;
    /** Whether we should show errors only if the user touched the field or submitted the form. */
    touchedOnlyErrors?: boolean;
    fullWidthLabel?: boolean;
    dataAutomation?: string;
    /**
     *  Prevents default behavior when clicking on label. By default it will focus on the first focusable child.
     */
    preventLabelOnClick?: boolean;
}

interface PropsWithLabel extends SharedProps {
    tag?: boolean;
    label: React.ReactNode;
    /** Whether we should show `(optional)` text on the field label (will only be visible when there is a label) */
    showOptional?: boolean;
    /** Wether to show the label on the same line of the field */
    showLabelInline?: boolean;
    /** Tooltip information */
    informationTooltip?: string | React.ReactNode;
    /** Show a description under the label */
    description?: string;
}

interface PropsWithoutLabel extends SharedProps {
    tag?: undefined;
    label?: undefined;
    /** Whether we should show `(optional)` text on the field label (will only be visible when there is a label) */
    showOptional?: false;
    /** Wether to show the label on the same line of the field */
    showLabelInline?: false;
    /** Tooltip information */
    informationTooltip?: undefined;
    description?: undefined;
}

type Props = PropsWithLabel | PropsWithoutLabel;

const Field: React.FC<React.PropsWithChildren<Props>> = ({
    label,
    light = false,
    tag = false,
    touchedOnlyErrors = true,
    error: unfilteredErrorProp,
    children,
    className,
    showOptional = false,
    showLabelInline = false,
    informationTooltip,
    description,
    inlineError = false,
    fullWidthLabel = false,
    dataAutomation = undefined,
    preventLabelOnClick = false,
}) => {
    // When the field is an array, the error might be an array of inner errors.
    // We want to show errors only directly on the field.
    const errorProp: React.ReactNode | undefined = Array.isArray(unfilteredErrorProp) ? undefined : unfilteredErrorProp;
    const [fields, setFields] = useState<FieldOptions[]>([]);

    const addField = useCallback((options: FieldOptions) => {
        setFields((currentField) => [
            ...currentField.filter((singleFieldOptions) => singleFieldOptions.id !== options.id),
            options,
        ]);

        return () => {
            setFields((currentFields) =>
                currentFields.filter((singleFieldOptions) => singleFieldOptions.id !== options.id),
            );
        };
    }, []);

    const contextValue = useMemo(
        () => ({ addField, fieldInError: !!errorProp, touchedOnlyErrors }),
        [addField, errorProp, touchedOnlyErrors],
    );

    const htmlFor = useMemo(() => {
        if (fields.length === 0) {
            return;
        }
        return fields.find((field) => field.labelFocusing)?.id;
    }, [fields]);

    const errors = useMemo(() => {
        const innerErrors = [
            ...(errorProp ? [{ id: 'prop', value: errorProp }] : []),
            ...fields.filter((field) => field.error).map((field) => ({ id: field.id, value: field.error! })),
        ];

        const invalidErrors = innerErrors.filter((error) => typeof error.value !== 'string');
        if (invalidErrors.length > 0) {
            throw new Error(
                `Found invalid Formik error values, adding mapError to useInnerField might help. ${JSON.stringify(
                    invalidErrors,
                )}`,
            );
        }

        return innerErrors;
    }, [errorProp, fields]);

    const errorsLabel = useMemo(() => {
        return errors.map((error) => error.value).join(',');
    }, [errors]);

    return (
        <Wrapper className={className} $showLabelInline={showLabelInline} data-automation={dataAutomation}>
            {label && (
                <Label
                    $showLabelInline={showLabelInline}
                    $light={light}
                    htmlFor={htmlFor}
                    data-automation="field-label"
                    onClick={(e) => {
                        if (preventLabelOnClick) {
                            e.preventDefault();
                        }
                    }}
                >
                    <LabelText $tag={tag} $fullWidthLabel={fullWidthLabel}>
                        {label}
                        {showOptional && <Optional> (optional)</Optional>}
                    </LabelText>
                    {informationTooltip && (
                        <StyledToolTip tooltipLimitWidth={15} iconSize={InformationIconSize.SMALL} placement="top">
                            {informationTooltip}
                        </StyledToolTip>
                    )}
                </Label>
            )}

            {description && <ExpressionDescription> {description}</ExpressionDescription>}

            <FieldContext.Provider value={contextValue}>{children}</FieldContext.Provider>

            {!!errors.length && (
                <FieldError
                    $inlineError={inlineError}
                    data-automation="field-error-validation-form"
                    data-automation-label={errorsLabel}
                >
                    {errors.map((error) => (
                        <div key={error.id}>{error.value}</div>
                    ))}
                </FieldError>
            )}
        </Wrapper>
    );
};

export default Field;
