enum ProjectSourceChangeBehavior {
    KEEP_EXISTING = 'KEEP_EXISTING',
    KEEP_EXISTING_PROCESS_CONTRIBUTORS = 'KEEP_EXISTING_PROCESS_CONTRIBUTORS',
    KEEP_EXISTING_SYSTEM_USERS = 'KEEP_EXISTING_SYSTEM_USERS',
    NO_OP = 'NO_OP',
    DEACTIVATE = 'DEACTIVATE',
    DEACTIVATE_PROCESS_CONTRIBUTORS = 'DEACTIVATE_PROCESS_CONTRIBUTORS',
    DEACTIVATE_SYSTEM_USERS = 'DEACTIVATE_SYSTEM_USERS',
}

export default ProjectSourceChangeBehavior;
