enum InitiativeStatus {
    FUTURE = 'FUTURE',
    ACTIVE = 'ACTIVE',
    DONE = 'DONE',
    WARN = 'WARN',
    ON_HOLD = 'ON_HOLD',
    INTAKE = 'INTAKE',

    /** @Deprecated */
    ARCHIVE = 'ARCHIVE',
}

export default InitiativeStatus;
