import TransitionState from './TransitionState';

class EnterTransitionState extends TransitionState {
    public readonly stateName = 'enter';

    protected setStartSubStateImplementation(callbacks) {
        callbacks.onEnter?.(false);
    }
    protected setActiveSubStateImplementation(callbacks) {
        callbacks.onEntering?.(false);
    }
    protected setDoneSubStateImplementation(callbacks) {
        callbacks.onEntered?.(false);
    }
}

const enterTransitionState = new EnterTransitionState();
export default enterTransitionState;
