import React, { useMemo } from 'react';
import type { ActionMeta } from 'react-select';
import type { GroupBase } from 'react-select';

import SimpleListSelect from './list/SimpleListSelect';
import SimpleDropdownSelect from './SimpleDropDownSelect';
import { DEFAULT_MAX_OPTIONS_TO_DISPLAY_AS_LIST } from './SimpleSelectConsts';
import type {
    SimpleSelectProps,
    SimpleSelectSingleOption,
    SimpleSelectIsMultiAndValueProps,
} from './SimpleSelectTypes';
import { useInnerField } from '../Field';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

/**
 * A select component similar to TnkSelect, but unlike TnkSelect, the onChange and the value props return and accept
 * only the value in the options objects, and not the entire object. It supports, with strong type inference, the
 * optional isMulti prop.
 *
 * All objects passed to object must have value and a label. The label is what shown and searched by, by default, and
 * value is the item we use in the value prop and what we return to onChange.
 *
 * @example of regular TnkSelect, **without SimpleSelect**
 * const options = [
 *     { value: "slack", label: "Slack" },
 *     { value: "teams", label: "Microsoft Teams" },
 *     { value: "google-chat", label: "Google Chat" },
 * ];
 * const SelectCommunicationIntegrationType = ({ selectedCommunicationIntegrationType, onSelectedCommunicationIntegrationTypeChanged }) => {
 *     const value = useMemo(() => {
 *         return options.find(option => option.value === selectedCommunicationIntegrationType);
 *     }, [options, selectedCommunicationIntegrationType]);
 *
 *     const onChange = useCallback((communicationIntegrationTypeObject) => {
 *         onSelectedCommunicationIntegrationTypeChanged(communicationIntegrationTypeObject.value);
 *     }, [onSelectedCommunicationIntegrationTypeChanged])
 *
 *     return (
 *         <TnkSelect
 *             value={value}
 *             onChange={(communicationIntegrationType) => onSelectedCommunicationIntegrationTypeChanged(communicationIntegrationType.value)}
 *             options={options}
 *         />
 *     );
 * };
 *
 * @example of SimpleSelect
 * const options = [
 *     { value: "slack", label: "Slack" },
 *     { value: "teams", label: "Microsoft Teams" },
 *     { value: "google-chat", label: "Google Chat" },
 * ];
 * const SelectCommunicationIntegrationType = ({ selectedCommunicationIntegrationType, onSelectedCommunicationIntegrationTypeChanged }) => {
 *     return (
 *         <SimpleSelect
 *             value={selectedCommunicationIntegrationType}
 *             onChange={onSelectedCommunicationIntegrationTypeChanged}
 *             options={options}
 *         />
 *     );
 * };
 */
const SimpleSelect = <
    Option extends SimpleSelectSingleOption<any> = SimpleSelectSingleOption<string>,
    Group extends GroupBase<Option> = GroupBase<Option>,
    IsMulti extends boolean = false,
    IsCreatable extends boolean = false,
>(
    props: SimpleSelectProps<Option, IsMulti, IsCreatable, Group>,
): React.ReactElement => {
    type ValueType = Option['value'];

    const [fieldProps, hasError, formikHelpers] = useInnerField({
        name: props.name,
        multiple: props.isMulti ?? false,
        type: 'select',
        value: props.value,
        onBlur: props['onBlur'],
        id: props['inputId'],
    });

    const haveGroupedOptions = useMemo(() => props.options?.some((option) => 'options' in option), [props.options]);

    const onChange = useConstantRefCallback(
        (newOptions: Option[] | Option | undefined, actionMeta?: ActionMeta<Option>) => {
            if (props.isMulti) {
                const onChangeParam = props.onChange as SimpleSelectIsMultiAndValueProps<ValueType, true>['onChange'];

                const newArrayOptions = newOptions as Option[] | undefined;
                const newValueArray = newArrayOptions?.map((singleValue) => singleValue.value) || [];
                onChangeParam?.(newValueArray, newArrayOptions || [], actionMeta);
                formikHelpers?.setValue(newValueArray);
            } else {
                const onChangeParam = props.onChange as SimpleSelectIsMultiAndValueProps<ValueType, false>['onChange'];

                const newSingleOption = newOptions as Option | undefined;
                onChangeParam?.(newSingleOption?.value, newSingleOption, actionMeta);
                formikHelpers?.setValue(newSingleOption?.value);
            }
        },
    );

    // Even if requested to present as list, will do so only for simple cases
    const diaplayAsList =
        props?.displayAsList &&
        !haveGroupedOptions &&
        !props.isCreatable &&
        props.options?.length <= (props.maxOptionsToDisplayAsList ?? DEFAULT_MAX_OPTIONS_TO_DISPLAY_AS_LIST);

    return diaplayAsList ? (
        <SimpleListSelect
            options={props.options as Option[]}
            selectedValues={props.isMulti ? fieldProps.value : [fieldProps.value]}
            onChange={(newOptions: Option[]) => {
                onChange(props.isMulti ? newOptions : newOptions[0]);
            }}
            isMulti={props.isMulti}
            maximumOptionsToSelect={props.multiMaximumToSelect}
            isError={props.isError || hasError}
            isHighlighted={props.isHighlighted}
            selectionColor={props.listSelectionColor}
        />
    ) : (
        <SimpleDropdownSelect {...props} onChange={onChange} />
    );
};

export type SimpleSelectComponentType = <
    Option extends SimpleSelectSingleOption<any>,
    Group extends GroupBase<Option> = GroupBase<Option>,
    IsMulti extends boolean = false,
    IsCreatable extends boolean = false,
>(
    props: SimpleSelectProps<Option, IsMulti, IsCreatable, Group>,
) => React.ReactElement;

export default React.memo(SimpleSelect) as SimpleSelectComponentType;
