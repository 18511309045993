/*
 * this is a wrapper on usage of mixpanel to make sure it is fail-safe and that it only used after initialized.
 */

const mixpanelWrapper = {
    init: (id, authenticationService, projectManager, $rootScope, $log, $timeout) => {
        try {
            mixpanel.init(id, { ip: false }); // "ip": false will disable geo-tracking

            const userAgent = navigator.userAgent;
            mixpanel.register({ 'User Agent': userAgent });
            if (userAgent.endsWith('DatadogSynthetics')) {
                // see: https://docs.mixpanel.com/docs/debugging/bot-traffic
                mixpanel.register({ $ignore: true });
            }

            mixpanel.register({
                noBounce: false,
            });

            $rootScope.$on('newUserCreated', function (e, userId) {
                mixpanel.alias(userId);
            });

            // Setting the user ID.
            const trackUser = function () {
                const user = authenticationService.currentUser;
                if (user?.id) {
                    mixpanel.identify(user.id);

                    // to all events
                    mixpanel.register({
                        email: user.email,
                        name: user.name,
                        personId: user.id,
                    });

                    if ($rootScope.mobileApp) {
                        mixpanel.register({
                            mobileApp: true,
                        });
                    }

                    // to user object
                    mixpanel.people.set({
                        $email: user.email, // only special properties need the $
                        $created: new Date(user.created),
                        $last_login: new Date(),
                        $name: user.name,
                        $first_name: user.firstName,
                        $avatar: user.avatarUri,
                        $phone: user.metadata?.phoneNumber ? user.metadata.phoneNumber : null,
                        personId: user.id,
                        title: user.title,
                        userCreated: new Date(user.created),
                    });
                }
            };
            trackUser();
            $rootScope.$on('currentUserChanged', trackUser);

            // track project id
            const trackProject = function () {
                const project = projectManager.project;
                if (project) {
                    const projectContext = authenticationService.currentUser.projectContexts[project.id];

                    // to all events
                    mixpanel.register({
                        projectId: project.id,
                        projectName: project.name,
                        isFullUser: !!projectContext?.isLicensed,
                    });

                    // to user object
                    mixpanel.people.set({
                        projectId: project.id,
                        projectName: project.name,
                        isOwner: !!projectManager.isOwner,
                        isFullUser: !!projectContext?.isLicensed,
                        isFullUserPreview: !!projectManager.isFullUserPreview,
                        usecases: project.usecases?.length ? project.usecases.join(', ') : null,
                        trial_expiration: project.expirationDate ? new Date(project.expirationDate) : null,
                        is_trial_expired: !!project.isExpired,
                        licensed: !!project.license,
                        limited: !!project.isLimitedLicense,
                        seats: project.license ? project.license.seats : 1,
                        plan: project.license ? project.license.plan : project.expirationDate ? 'TRIAL' : 'FREE',
                        monthly_spend: project.license ? project.license.seats * 69.99 : 0,
                    });
                }
            };
            trackProject();
            $rootScope.$on('currentProjectChanged', trackProject);

            $rootScope.$on('emailLoginSent', function (e, email) {
                mixpanel.register({
                    email,
                });
                mixpanel.people.set({
                    $email: email, // only special properties need the $
                });
            });

            const logPageViewed = function (stateName) {
                mixpanel.track('Page Viewed', {
                    title: document.title,
                    label: stateName,
                    page: window.location.pathname,
                    url: window.location,
                });
            };

            // track state change as a "view" even
            $rootScope.$on('$stateChangeSuccess', function (e, toState) {
                logPageViewed(toState.name);
            });

            // if session is more than 5 seconds add "long session" to all future events
            $timeout(function () {
                // console.log('no bounce');
                mixpanel.register({
                    noBounce: true,
                });
                // log page view with the new flag just in case
                logPageViewed($rootScope.currentRouteStateName);
            }, 5000);
        } catch (error) {
            $log.error(error);
        }
    },

    track: (eventName: string, properties?: { [index: string]: any }, callback?: () => void) => {
        try {
            if (!!mixpanel?.track) {
                return mixpanel.track(eventName, properties, callback);
            }
        } catch (error: any) {
            console.error(`mixpanel.track threw error: ${error}`);
        }
        return null;
    },

    peopleUnion: (prop: string, values: any, callback?: () => void) => {
        try {
            if (!!mixpanel?.people) {
                return mixpanel.people.union(prop, values, callback);
            }
        } catch (error: any) {
            console.error(`mixpanel.people.union threw error: ${error}`);
        }
        return null;
    },
};

// this is intentionally set here. The goal is not to pre-load the mixpanel library.
// We prefer to load it dynamically.
declare let mixpanel: any;

export default mixpanelWrapper;
