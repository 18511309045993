import { useAngularService } from 'angulareact';
import { useEffect } from 'react';

import useFetchManager, { LIMIT_PARAM, SKIP_PARAM } from '../useFetchManager';
import useIsTabActive from '../useIsTabActive/useIsTabActive';

import type { Activity, ActivityType, ActivitiesResponse } from '@tonkean/tonkean-entities';

const AUTO_RELOAD_ACTIVITIES_INTERVAL_MS = 10_000;

function getItems(_activities: ActivitiesResponse): Activity[] {
    return _activities.activity;
}

const useInitiativeActivityFetchManager = (
    initiativeId: string | undefined,
    activityTypes: ActivityType[],
    includeIntake: boolean,
    excludePrivateComments: boolean = false,
    includeOnlyComments: boolean = false,
) => {
    const tonkeanService = useAngularService('tonkeanService');

    const isTabActive = useIsTabActive();
    const [
        [getInitiativeActivity, cancelFetcher],
        { data: activities, hasMorePages, loadNextPage, loading, manuallyReload },
    ] = useFetchManager(tonkeanService, 'getInitiativeActivity', {
        getItems,
        limit: 20,
        autoReloadInterval: isTabActive ? AUTO_RELOAD_ACTIVITIES_INTERVAL_MS : undefined,
    });

    useEffect(() => {
        if (initiativeId) {
            getInitiativeActivity(
                initiativeId,
                LIMIT_PARAM,
                SKIP_PARAM,
                activityTypes,
                true,
                true,
                includeIntake,
                excludePrivateComments,
                includeOnlyComments,
            );
        }

        return () => cancelFetcher();
    }, [
        activityTypes,
        cancelFetcher,
        getInitiativeActivity,
        includeIntake,
        initiativeId,
        excludePrivateComments,
        includeOnlyComments,
    ]);

    return {
        activities,
        loading,
        manuallyReload,
        cancelFetcher,
        hasMorePages,
        loadNextPage,
    };
};

export default useInitiativeActivityFetchManager;
