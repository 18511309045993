import * as React from 'react';
import { components } from 'react-select';
import type { GroupBase } from 'react-select';
import type { MenuListProps } from 'react-select';

import useEscapeCallback from '../../../hooks/useEscapeCallback';
import { useIgnoreClickOutside } from '../../../hooks/useOnClickOutside';

const CustomMenuList: React.FC<React.PropsWithChildren<MenuListProps<unknown, boolean, GroupBase<unknown>>>> = ({
    children,
    innerRef,
    ...props
}) => {
    useEscapeCallback(() => {});
    useIgnoreClickOutside();

    return (
        <components.MenuList {...props} innerRef={innerRef}>
            {children}
        </components.MenuList>
    );
};

export default CustomMenuList;
