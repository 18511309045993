enum PeopleDirectorySendStatus {
    SUCCESS = 'SUCCESS',
    PARTIAL = 'PARTIAL',
    FAIL = 'FAIL',
}

export const PeopleDirectorySendStatusLabel: Record<PeopleDirectorySendStatus, string> = {
    [PeopleDirectorySendStatus.SUCCESS]: 'Message was sent successfully',
    [PeopleDirectorySendStatus.PARTIAL]: 'Fallback method',
    [PeopleDirectorySendStatus.FAIL]: 'Message was not sent',
};

export default PeopleDirectorySendStatus;
