import type PopperJS from '@popperjs/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from './TUI/Tooltip';
import useResizeObserver from '../hooks/useResizeObserver';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import useStillMounted from '@tonkean/tui-hooks/useStillMounted';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { requestAnimationFrameThrottler } from '@tonkean/utils';

const LinedText = styled.div<{
    $numberOfLines: number;
    $shouldCollapse: boolean;
    $overflowHidden: boolean;
    $fullWords?: boolean;
}>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: ${({ $overflowHidden }) => ($overflowHidden ? 'hidden' : 'visible')};

    ${({ $fullWords }) =>
        $fullWords
            ? css`
                  overflow-wrap: anywhere;
              `
            : css`
                  word-break: break-all;
              `};

    ${({ $numberOfLines, $shouldCollapse }) =>
        $shouldCollapse &&
        css`
            -webkit-line-clamp: ${$numberOfLines};
        `};
`;

export const ShowButton = styled.button`
    display: flex;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    font-weight: normal;
    height: fit-content;
    color: ${Theme.colors.primary};
`;

export interface TextEllipsisProps extends StyledComponentsSupportProps {
    /** How many lines at which the text collapses. */
    numberOfLines?: number;
    /** If true, it won't show ellipsis. */
    disabled?: boolean;
    /** Should show the show more button if the text collapses? */
    showMore?: boolean;
    /** Should show the tooltip on hover if the text collapses? */
    tooltip?: boolean;
    /**
     * If `true`, is will add the three dots after the last fitting word. If `false`, it will allow half words.
     * Default is `false` if number of lines is 1, otherwise it's `true`.
     *
     * @example if `true`:
     * "Hello and welcome!" => "Hello and...".
     * @example if `false`:
     * "Hello and welcome!" => "Hello and wel...".
     */
    fullWords?: boolean;
    tooltipPlacement?: PopperJS.Placement;
    /** The width of the tooltip will limited to. the default is 40vm */
    tooltipLimitWidth?: number;
    /** The content of the tooltip. */
    tooltipContentOverride?: React.ReactNode;
    dataAutomation?: string;

    overflowHidden?: boolean;
}

/**
 * Shows collapsible text with a show more/less button.
 */
const TextEllipsis: React.FC<React.PropsWithChildren<TextEllipsisProps>> = ({
    children,
    numberOfLines = 2,
    disabled = false,
    showMore = false,
    tooltip = false,
    fullWords = disabled || numberOfLines !== 1,
    tooltipPlacement = 'top',
    tooltipLimitWidth = 40,
    tooltipContentOverride,
    dataAutomation,
    overflowHidden = true,
    className,
}) => {
    const [collapsing, setCollapsing] = useState(false);
    const [showingMore, setShowingMore] = useState(false);

    const stillMountedRef = useStillMounted();
    const nodeRef = useRef<HTMLDivElement>(null);

    const throttler = useMemo(() => requestAnimationFrameThrottler(), []);

    const updateCollapsing = () => {
        throttler(() => {
            if (!nodeRef.current || !stillMountedRef.current) {
                return;
            }

            const currentlyCollapsing =
                nodeRef.current.scrollHeight > nodeRef.current.clientHeight ||
                nodeRef.current.scrollWidth > nodeRef.current.clientWidth;
            if (collapsing !== currentlyCollapsing) {
                setCollapsing(currentlyCollapsing);
            }
        });
    };

    const { setNode } = useResizeObserver(updateCollapsing);

    /**
     * Trigger the updateCollapsing function on every render.
     * We don't use deps array because when children changes, we should re-calculate if it's collapsing or not, and
     * because memorizing the children is not recommended, a deps array is useless.
     */
    useEffect(() => {
        updateCollapsing();

        // This useEffect has no deps array. Read the comment above to useEffect.
    });

    // If we don't need a resize observer, we can prevent it from being added by not setting the node.
    const shouldUseResizeObserver = (tooltip || showMore) && !disabled;

    const setMultiNode = useMultipleRefCallback(nodeRef, shouldUseResizeObserver ? setNode : undefined);

    const notDisabled = !disabled;
    const notShowingMore = !showingMore;
    const showEllipsis = notDisabled && notShowingMore;
    const showShowMore = notDisabled && (collapsing || showingMore) && showMore;
    const showTooltip = notDisabled && collapsing && tooltip;

    return (
        <>
            <Tooltip
                placement={tooltipPlacement}
                content={tooltipContentOverride || children}
                disabled={!showTooltip}
                nodeRef={nodeRef}
                limitWidth={tooltipLimitWidth}
            >
                <LinedText
                    data-automation={dataAutomation}
                    $shouldCollapse={showEllipsis}
                    $numberOfLines={numberOfLines}
                    $fullWords={fullWords}
                    $overflowHidden={overflowHidden}
                    ref={setMultiNode}
                    className={className}
                >
                    {children}
                </LinedText>
            </Tooltip>

            {showShowMore && (
                <ShowButton onClick={() => setShowingMore(notShowingMore)}>
                    {showingMore ? 'Show Less' : 'Show More'}
                </ShowButton>
            )}
        </>
    );
};
export default TextEllipsis;
