import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Checkbox from '../../Checkbox/Checkbox';
import type { SimpleSelectSingleOption } from '../SimpleSelectTypes';

import { ButtonShape } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    gap: 24px;
    display: flex;
    flex-direction: column;
`;

const StyledDescription = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-style: italic;
    font-weight: 400;
    color: ${Theme.colors.gray_600};
    line-height: 16px;
`;

const StyledLabel = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 400;
    color: ${Theme.colors.gray_800};
    line-height: 16px;
`;

const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: flex-start;
`;

type SimpleCheckboxesSelectProps<Option extends SimpleSelectSingleOption<any>> = {
    options: readonly Option[];
    selectedOptions: Option[];
    maximumOptionsToSelect?: number;
    selectionColor?: Color;
    onChange: (selectionOptions: Option[]) => void;
};

const SimplecCheckboxesSelect = <Option extends SimpleSelectSingleOption<any>>({
    options,
    selectedOptions,
    maximumOptionsToSelect,
    selectionColor,
    onChange,
}: SimpleCheckboxesSelectProps<Option>) => {
    const selectedOptionsValues = useMemo(() => {
        return new Set(selectedOptions.map((option) => option.value));
    }, [selectedOptions]);

    const optionSelectionChanged = useCallback(
        (changedOption: Option, isSelected: boolean) => {
            const updatedSelectedOptions = options.filter(
                (option) =>
                    (option.value === changedOption.value && isSelected) ||
                    (selectedOptionsValues.has(option.value) && changedOption.value !== option.value),
            );

            onChange(updatedSelectedOptions);
        },
        [options, selectedOptionsValues, onChange],
    );

    return (
        <Wrapper data-automation="select-checkbox-list">
            {options.map((option) => (
                <StyledCheckbox
                    key={option.value}
                    boldLabel={false}
                    size={InputSize.MEDIUM_LARGE}
                    shape={ButtonShape.RECTANGULAR}
                    customUncheckedColor={Theme.colors.gray_500}
                    customColor={selectionColor}
                    changeLabelColor={false}
                    checked={selectedOptionsValues.has(option.value)}
                    applyDisabledStyleOnIcon={false}
                    disabledLabelColorOrOpacity={0.2}
                    disabled={
                        maximumOptionsToSelect !== undefined &&
                        selectedOptions.length >= maximumOptionsToSelect &&
                        !selectedOptionsValues.has(option.value)
                    }
                    onChange={(event) => {
                        optionSelectionChanged(option, event.target.checked);
                    }}
                    dataAutomation={`select-checkbox-list-option-${option.label?.toString()}`}
                >
                    <StyledLabel>{option.label}</StyledLabel>
                    {option.description && <StyledDescription>{option.description}</StyledDescription>}
                </StyledCheckbox>
            ))}
        </Wrapper>
    );
};

export default SimplecCheckboxesSelect;
