import { AddOwnerIcon } from '@tonkean/svg';
import React from 'react';
import Utils from '@tonkean/utils';

/**
 * A components that renders an avatar for the track list item.
 */
export default class TonkeanAvatar extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        // If the owner (passed in the props) has changed, allow the component to re-render.
        if (!Utils.shallowEqual(this.props.owner, nextProps.owner)) {
            return true;
        }

        return false;
    }

    render() {
        const owner = this.props.owner;
        const onBehalfOfFullName = this.props.onBehalfOfFullName;
        const onBehalfOfInitials = onBehalfOfFullName && Utils.getInitials(onBehalfOfFullName);
        if (owner) {
            // If an owner is set.
            if (owner.name) {
                // Owner has a name - we can show the initials under the avatar (to handle cases where the avatar is transparent).
                return (
                    <div>
                        {!onBehalfOfInitials && owner.avatarUri && (
                            <img
                                className="avatar-img img-circle"
                                src={owner.avatarUri}
                                data-automation="tonkean-avatar"
                            />
                        )}
                        {onBehalfOfInitials ? (
                            <div className="avatar-initials mod-dash">
                                <span className="avatar-on-behalf-initials-text">
                                    {onBehalfOfInitials.toUpperCase()}
                                </span>
                            </div>
                        ) : (
                            <div className="avatar-initials mod-primary">
                                <span className="avatar-initials-text">{owner.initials}</span>
                            </div>
                        )}
                    </div>
                );
            } else {
                // No owner name, just show the avatar we got (shouldn't happen).
                return <img className="avatar-img img-circle" src={owner.avatarUri} />;
            }
        } else {
            // If no owner.
            return (
                <span className="avatar-img img-circle svg-icon-lg">
                    <span className="tnk-icon">
                        <AddOwnerIcon />
                    </span>
                </span>
            );
        }
    }
}
