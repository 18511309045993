import * as React from 'react';
import { useCallback, useState } from 'react';

import CloseCallbackContext from './CloseCallbackContext';
import { OnBeforeCloseContext } from './useAddOnBeforeClose';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

function useOnBeforeCloseQueueManager(onClose: () => void) {
    const [onBeforeCloseArray, setOnBeforeCloseArray] = useState<(() => Promise<boolean> | boolean)[]>([]);

    const internalOnClose = useConstantRefCallback(async () => {
        const results = await Promise.all(onBeforeCloseArray.map((onBeforeCloseFunc) => onBeforeCloseFunc())).catch(
            () => [false],
        );

        const isAllResultsTrue = results.every((result) => result);
        if (isAllResultsTrue) {
            onClose();
        }

        return isAllResultsTrue;
    });

    const CloseContextProvider = useCallback<React.FC<React.PropsWithChildren<Record<string, unknown>>>>(
        ({ children }) => {
            return (
                <OnBeforeCloseContext.Provider value={setOnBeforeCloseArray}>
                    <CloseCallbackContext.Provider value={internalOnClose}>{children}</CloseCallbackContext.Provider>
                </OnBeforeCloseContext.Provider>
            );
        },
        [internalOnClose],
    );

    return { CloseContextProvider, onClose: internalOnClose };
}

export default useOnBeforeCloseQueueManager;
