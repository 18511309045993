import React from 'react';
import styled from 'styled-components';

import Radio from '../../RadioButton/Radio';
import RadioGroup from '../../RadioButton/RadioGroup';
import type { SimpleSelectSingleOption } from '../SimpleSelectTypes';

import type { Color } from '@tonkean/tui-theme';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledRadioGroup = styled(RadioGroup)`
    gap: 14px;
`;

const StyledDescription = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-style: italic;
    font-weight: 400;
    color: ${Theme.colors.gray_600};
    line-height: 16px;
`;

const StyledLabel = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 400;
    color: ${Theme.colors.gray_800};
    line-height: 16px;
`;

const StyledRadio = styled(Radio)`
    display: flex;
    align-items: flex-start;
`;

type SimpleRadioButtonsSelectProps<Option extends SimpleSelectSingleOption<any>> = {
    options: readonly Option[];
    selectedOption?: Option;
    selectionColor?: Color;
    onChange: (option: Option) => void;
};

const SimpleRadioButtonsSelect = <Option extends SimpleSelectSingleOption<any>>({
    options,
    selectedOption,
    selectionColor,
    onChange,
}: SimpleRadioButtonsSelectProps<Option>) => {
    return (
        <StyledRadioGroup
            value={selectedOption}
            size={InputSize.MEDIUM_LARGE}
            selectedColor={selectionColor}
            unselectedColor={Theme.colors.gray_500}
            changeLabelColor={false}
            boldLabel={false}
            onChange={onChange}
            dataAutomation="select-radio-list"
        >
            {options.map((option) => (
                <StyledRadio
                    key={option.value}
                    value={option}
                    dataAutomation={`select-radio-list-option-${option.value}`}
                >
                    <StyledLabel>{option.label}</StyledLabel>
                    {option.description && <StyledDescription>{option.description}</StyledDescription>}
                </StyledRadio>
            ))}
        </StyledRadioGroup>
    );
};

export default SimpleRadioButtonsSelect;
