import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';

const Wrapper = styled.div`
    position: relative;
    line-height: 1;
`;

const Input = styled.input<{ disabled?: boolean; $zeroHeight: boolean }>`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 0 !important;
    padding: 0;
    opacity: 0;
    width: 100%;
    height: ${({ $zeroHeight }) => ($zeroHeight ? 0 : '100%')};

    /* If disabled, make important to override the cursor: not-allowed. */
    cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Child element is required and must be a single element */
    children: React.ReactComponentElement<any>;
    indeterminate?: boolean;
    dataAutomation?: string;
    zeroHeight?: boolean;
}

/**
 * An invisible input component. It will place the real input above the child, and pass to the child
 * the focused prop.
 *
 * The child must be single, and should handle the focused prop.
 */
const InvisibleInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    { children, indeterminate = false, zeroHeight = false, ...props },
    ref,
) => {
    const [focused, setFocused] = useState(false);
    const indeterminateRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (indeterminateRef.current) {
            indeterminateRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    const onFocus = () => {
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
    };

    const sharedOnFocus = useCompositeEventCallback(props.onFocus, onFocus);
    const sharedOnBlur = useCompositeEventCallback(props.onBlur, onBlur);

    const inputRef = useMultipleRefCallback(ref, indeterminateRef);

    return (
        <Wrapper>
            <Input {...props} onFocus={sharedOnFocus} onBlur={sharedOnBlur} $zeroHeight={zeroHeight} ref={inputRef} />
            {/* TODO IS IT OK?*/}
            {children && React.cloneElement(children, { $focused: focused })}
        </Wrapper>
    );
};

export default React.forwardRef(InvisibleInput);
