import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledHeader = styled.div`
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;

    a,
    button {
        ${styledFocus}
    }
`;

const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_800};
    margin: 0 0 0 18px;
    flex-grow: 1;
    line-height: 54px;
`;

interface Props extends StyledComponentsSupportProps {
    className?: string;
    title?: ReactNode;
    rightSideOfHeader: ReactNode;
}

const Header: React.FC<Props> = ({ className, title, rightSideOfHeader }) => {
    return (
        <StyledHeader className={className}>
            <Title>{title}</Title>

            {rightSideOfHeader}
        </StyledHeader>
    );
};

export default Header;
