import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as DoneIcon } from '../../../images/done.svg';
import { ReactComponent as XIcon } from '../../../images/red-x.svg';
import { ReactComponent as SpinnerIcon } from '../../../images/spinner.svg';
import usePrevious from '../../hooks/usePrevious';

import { SavingState } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const theme = Theme.current.palette;
const CHECK_MARK_LENGTH = 12;
const SPINNER_LENGTH = 45;
const ANIMATION_PART_TIMING = 1 / 3;

const DrawCheckMark = keyframes`
    0% {
        stroke-dashoffset: ${CHECK_MARK_LENGTH};
    }

    100% {
        stroke-dashoffset: 0;
    }
`;

const CompleteCircle = keyframes`
    0% {
        stroke-dashoffset: {spinnerLength / 2};
    }

    100% {
        stroke-dashoffset: 0;
    }
`;

const ShowAndHide = keyframes`
    0% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const Show = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

const Hide = keyframes`
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
    margin-left: 5px;
    height: 16px;
    width: 16px;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    display: inline-block;
    vertical-align: middle;
`;

const IconsWrappers = css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const SpinWrapper = styled.div`
    ${IconsWrappers};

    transform-origin: 50% 50%;
    animation: ${Rotate} ${ANIMATION_PART_TIMING * 2}s infinite linear;
`;

const Spinner = styled.div<{ $reveal: boolean; $completeAndHide: boolean; $error: boolean }>`
    opacity: 0;

    circle {
        stroke-dasharray: ${SPINNER_LENGTH};
        stroke-dashoffset: ${SPINNER_LENGTH / 2};
        stroke-linecap: round;
    }

    ${({ $reveal }) =>
        $reveal &&
        css`
            animation: ${Show} ${ANIMATION_PART_TIMING}s linear forwards;
        `};

    ${({ $error }) =>
        $error &&
        css`
            circle {
                transition: stroke ${ANIMATION_PART_TIMING}s;
                stroke: ${theme.mainColors.error};
            }
        `};

    ${({ $completeAndHide }) =>
        $completeAndHide &&
        css`
            opacity: 1;
            animation: ${Hide} ${ANIMATION_PART_TIMING}s ${ANIMATION_PART_TIMING * 2}s linear forwards;

            circle {
                animation: ${CompleteCircle} ${ANIMATION_PART_TIMING}s linear forwards;
            }
        `};
`;

const SavedWrapper = styled.div<{ $revealAndHide: boolean }>`
    ${IconsWrappers};

    display: inline-flex;
    justify-content: center;
    opacity: 0;

    .tnk-icon {
        display: inline-flex;
        align-items: center;

        svg {
            height: 8px;
            width: 8px;

            path {
                stroke: ${theme.mainColors.success};
                stroke-dasharray: ${CHECK_MARK_LENGTH};
            }
        }
    }

    ${({ $revealAndHide }) =>
        $revealAndHide &&
        css`
            animation: ${ShowAndHide} ${ANIMATION_PART_TIMING * 3}s linear forwards;

            path {
                animation: ${DrawCheckMark} ${ANIMATION_PART_TIMING}s linear forwards;
            }
        `};
`;

const ErrorWrapper = styled.div<{ $revealAndHide: boolean }>`
    ${IconsWrappers};

    display: inline-flex;
    justify-content: center;
    opacity: 0;

    .tnk-icon {
        display: inline-flex;
        align-items: center;

        svg {
            height: 6px;
            width: 6px;
        }
    }

    ${({ $revealAndHide }) =>
        $revealAndHide &&
        css`
            animation: ${ShowAndHide} ${ANIMATION_PART_TIMING * 3}s linear forwards;
        `};
`;

interface StateProps {
    state?: SavingState;
    loading?: undefined;
    error?: undefined;
}

interface LoadingErrorProps {
    state?: undefined;
    loading: boolean;
    error: unknown;
}

type SavingIndicatorProps = StateProps | LoadingErrorProps;

function getState(props: SavingIndicatorProps): SavingState {
    if (props.state !== undefined) {
        return props.state;
    }

    if (props.loading) {
        return SavingState.LOADING;
    }

    if (props.error) {
        return SavingState.ERROR;
    }

    return SavingState.SAVED;
}

type Props = StyledComponentsSupportProps & SavingIndicatorProps;

const SavingIndicator: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { className, state, error, loading },
    ref,
) => {
    const inferredState = getState({ state, error, loading } as SavingIndicatorProps);
    const prevState = usePrevious(inferredState);

    const successAnimation = inferredState === SavingState.SAVED && prevState === SavingState.LOADING;
    const errorAnimation = inferredState === SavingState.ERROR && prevState === SavingState.LOADING;
    const completeAnimation = errorAnimation || successAnimation;

    return (
        <Wrapper
            data-automation="saving-indicator-icon"
            data-automation-state={inferredState}
            ref={ref}
            className={className}
        >
            <SpinWrapper>
                <Spinner
                    $reveal={inferredState === SavingState.LOADING}
                    $completeAndHide={completeAnimation}
                    $error={errorAnimation}
                >
                    <span className="tnk-icon">
                        <SpinnerIcon />
                    </span>
                </Spinner>
            </SpinWrapper>
            <SavedWrapper $revealAndHide={successAnimation}>
                <span className="tnk-icon">
                    <DoneIcon />
                </span>
            </SavedWrapper>
            <ErrorWrapper $revealAndHide={errorAnimation}>
                <span className="tnk-icon">
                    <XIcon />
                </span>
            </ErrorWrapper>
        </Wrapper>
    );
};

export default React.forwardRef(SavingIndicator);
