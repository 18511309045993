import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Theme, type Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Bounce = keyframes`
    0%,20% {
      transform: translate(0,0);
    }
    50% {
        transform: translate(0,-2px);
    }
    80%, 100% {
        transform: translate(0,0);
    }
`;

const LoadingDotsContainer = styled.div<{ border: boolean }>`
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    justify-content: center;
    align-items: flex-end;

    ${({ border }) =>
        border &&
        css`
            border: 1px solid ${Theme.colors.gray_500};
            align-items: center;
        `};
`;

const Dot = styled.div<{ color: Color }>`
    height: 2px;
    width: 2px;
    border-radius: 50%;
    background: ${({ color }) => color};
    animation: ${Bounce} 3s infinite ease-in-out;
`;

const Dot2 = styled(Dot)`
    animation-delay: 0.5s;
    margin: 0 1px;
`;

const Dot3 = styled(Dot)`
    animation-delay: 1s;
`;

interface Props extends StyledComponentsSupportProps {
    border?: boolean;
    color?: Color;
}

const LoadingDots: React.FC<Props> = ({ className, border = false, color = Theme.colors.gray_500 }) => {
    return (
        <LoadingDotsContainer border={border} className={className}>
            <Dot color={color} />
            <Dot2 color={color} />
            <Dot3 color={color} />
        </LoadingDotsContainer>
    );
};

export default LoadingDots;
