import React from 'react';
import { components } from 'react-select';
import type { ClearIndicatorProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled from 'styled-components';

import { XIcon } from '@tonkean/svg';
import type { PersonSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const StyledXIcon = styled(XIcon)`
    width: 10px;
    height: 10px;
    &:hover {
        cursor: pointer;
        ${colorSvg(Theme.colors.gray_600)}
    }
`;

const CustomClearIndicator: React.FC<ClearIndicatorProps<PersonSummary, boolean, GroupBase<PersonSummary>>> = (
    props,
) => {
    return (
        <div data-automation="custom-clear-indicator-element">
            <components.ClearIndicator<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
                <StyledXIcon />
            </components.ClearIndicator>
        </div>
    );
};
export default CustomClearIndicator;
