import TransitionState from './TransitionState';

class ExitTransitionState extends TransitionState {
    public readonly stateName = 'exit';

    protected setStartSubStateImplementation(callbacks) {
        callbacks.onExit?.();
    }
    protected setActiveSubStateImplementation(callbacks) {
        callbacks.onExiting?.();
    }
    protected setDoneSubStateImplementation(callbacks, shouldUnmountOnExit, triggerUnmount) {
        callbacks.onExited?.();

        if (shouldUnmountOnExit) {
            triggerUnmount();
        }
    }
}

const exitTransitionState = new ExitTransitionState();
export default exitTransitionState;
