import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';

interface CollapsibleProps<T extends keyof JSX.IntrinsicElements | React.ComponentType<any>> {
    /**
     * Current state of the animation.
     * This props will trigger collapse/expand.
     */
    open: boolean;

    /**
     * Should animate on the initial state.
     */
    animateInitialState?: boolean;

    /**
     * Should animate opacity.
     */
    animateOpacity?: boolean;

    /**
     * The component to use for the element
     */
    component?: T;
}

type Props<T extends keyof JSX.IntrinsicElements | React.ComponentType<any>> = React.PropsWithChildren<
    CollapsibleProps<T> & React.ComponentProps<T>
>;

const CollapsibleContent = <T extends keyof JSX.IntrinsicElements | React.ComponentType<any> = 'div'>({
    children,
    open,
    animateInitialState = false,
    animateOpacity = false,
    component = 'div' as T,
    ...props
}: Props<T>): React.ReactElement => {
    const Component = useMemo(() => motion(component), [component]);

    return (
        <AnimatePresence initial={animateInitialState}>
            {open && (
                <Component
                    /*
                     * We don't use variants because of a bug of nested AnimatePresence (Used in History's SingleWorkerRunFlowRunTableLogic)
                     * @see https://github.com/framer/motion/issues/820
                     */
                    initial={{ opacity: animateOpacity ? 0 : 1, height: 0, overflow: 'hidden' }}
                    animate={{
                        opacity: 1,
                        height: 'auto',
                        transitionEnd: { overflow: 'initial' },
                    }}
                    exit={{ opacity: animateOpacity ? 0 : 1, height: 0, overflow: 'hidden' }}
                    transition={{ duration: 0.4, ease: [0.22, 0.61, 0.36, 1] }}
                    {...props}
                >
                    {children}
                </Component>
            )}
        </AnimatePresence>
    );
};

export default CollapsibleContent;

export type CollapsibleContentComponentType = <
    T extends keyof JSX.IntrinsicElements | React.ComponentType<any> = 'div',
>(
    props: Props<T>,
) => React.ReactElement;
