import React, { useRef, useState } from 'react';

import type { InternalDialog } from './Dialog';
import { DialogType } from './Dialog';
import { Modal, ModalBody, ModalHeader, ModalSize } from '../TUI/Modal';
import ModalFooterActions from '../TUI/Modal/ModalFooterActions';

import { getStateError } from '@tonkean/utils';

interface Props {
    dialog: InternalDialog;
}

const SingleDialog: React.FC<Props> = ({ dialog }) => {
    const okRef = useRef<HTMLButtonElement>(null);

    const [open, setOpen] = useState(true);
    const [asyncState, setAsyncState] = useState<{ loading: boolean; error?: string }>({ loading: false });

    const onOk = () => {
        if (dialog.type !== DialogType.CONFIRM || !dialog.onOk) {
            onClose(true);
            return;
        }

        setAsyncState({ loading: true });
        dialog
            .onOk()
            .then(() => {
                setAsyncState({ loading: false });
                onClose(true);
            })
            .catch((error) => {
                let errorToDisplay: string | undefined;
                if (dialog.type === DialogType.CONFIRM && dialog.onCreateCustomError) {
                    errorToDisplay = dialog.onCreateCustomError(error);
                }
                setAsyncState({
                    loading: false,
                    error: getStateError(error, { overrideErrorMessage: errorToDisplay }),
                });
            });
    };

    const onCancel = () => {
        onClose(false);
    };

    const onClose = (value: boolean) => {
        setOpen(false);

        switch (dialog.type) {
            case DialogType.CONFIRM:
                dialog.callback(value);
                break;

            case DialogType.ALERT:
                dialog.callback();
                break;
        }
    };

    const onExited = () => {
        dialog.remove();
    };

    return (
        <Modal
            open={open}
            initiallyFocusedElementRef={okRef}
            onClose={onCancel}
            onExited={onExited}
            size={dialog.size || ModalSize.MEDIUM}
            ariaRole="alertdialog"
            fixedWidth
        >
            {dialog.title && <ModalHeader>{dialog.title}</ModalHeader>}

            <ModalBody>{dialog.content}</ModalBody>

            <ModalFooterActions
                error={asyncState.error}
                loading={asyncState.loading}
                showCancel={dialog.type === DialogType.CONFIRM}
                cancelButtonProps={{ 'data-automation': 'single-dialog-cancel-button' }}
                cancelLabel={(dialog.type === DialogType.CONFIRM && dialog.cancelLabel) || 'Cancel'}
                onCancel={onCancel}
                saveButtonProps={{ 'data-automation': 'single-dialog-ok-button', ref: okRef }}
                saveDisabled={dialog.saveDisabled}
                saveLabel={dialog.okLabel || 'OK'}
                onSave={onOk}
                isWarning={dialog.type === DialogType.CONFIRM && dialog.warning}
                isDelete={dialog.type === DialogType.CONFIRM && dialog.delete}
            />
        </Modal>
    );
};

export default SingleDialog;
