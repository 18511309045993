import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BackIcon } from '../../../../images/back.svg';

import { Theme } from '@tonkean/tui-theme';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ArrowButton = styled.button<{
    $size: InputComponentSizes;
}>`
    padding:0px;
    display: grid;
    align-items: center;
    background: none;
    border: none;
    border-radius: 2px;
    height: ${({ $size }) => Theme.sizes.inputButton[$size].height}px;
    width: ${({ $size }) => Theme.sizes.inputButton[$size].width}px;

    &:hover {
      background: ${Theme.colors.gray_300};

      svg {
        path {
          fill: ${Theme.colors.gray_700};
        }
      }
    }

    &:focus {
        border: 1px solid ${Theme.colors.focus};
        outline: none;
    }

    svg {
        width:6px;
        height: 6px;
        margin: 0 auto;

        path {
            fill: ${Theme.colors.gray_600};
        }
    }
    
}
`;

const ArrowIcon = styled(BackIcon)`
    -webkit-transform: ${({ transform }) => transform};
`;

interface Props {
    size?: InputComponentSizes;
    number: number;
    transform?: string;
    onArrowButtonClickEvent(number: number): void;
}

const DoNotUseNumberInputArrowButton: React.FC<Props> = ({
    size = InputSize.MEDIUM as InputComponentSizes,
    number,
    transform = 'rotate(90deg)',
    onArrowButtonClickEvent,
}) => {
    return (
        <ArrowButton
            onMouseDown={() => {
                onArrowButtonClickEvent(number);
            }}
            onMouseUp={() => {
                onArrowButtonClickEvent(0);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onArrowButtonClickEvent(number);
                }
            }}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    onArrowButtonClickEvent(0);
                }
            }}
            $size={size}
        >
            <ArrowIcon transform={transform} />
        </ArrowButton>
    );
};

export default DoNotUseNumberInputArrowButton;
