import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';

import type { ModalFooterStateProps } from './ModalFooterState';
import ModalFooterState from './ModalFooterState';
import useCloseCallback from '../CloseCallback/useCloseCallback';

import { Button } from '@tonkean/tui-buttons/Button';

interface Props extends ModalFooterStateProps {
    isWarning?: boolean;
    isDelete?: boolean;
    loading?: boolean;
    onCancel?: () => void;
    onSave?: () => void;
    cancelLabel?: React.ReactNode;
    saveLabel?: React.ReactNode;
    saveDisabled?: boolean;
    showCancel?: boolean;
    saveButtonProps?: React.ComponentProps<typeof Button>;
    cancelButtonProps?: React.ComponentProps<typeof Button>;
}

const ModalFooterActions: React.FC<React.PropsWithChildren<Props>> = ({
    isWarning = false,
    isDelete = false,
    loading: loadingProp,
    onCancel,
    onSave,
    cancelLabel = 'Cancel',
    saveLabel = 'Save',
    saveDisabled = false,
    showCancel = true,
    saveButtonProps,
    cancelButtonProps,
    children,
    ...props
}) => {
    const formik = useContext(FormikContext) as FormikContextType<unknown> | undefined;
    const loading = loadingProp || formik?.isSubmitting;

    const closeCallback = useCloseCallback();

    return (
        <ModalFooterState {...props} loading={loading}>
            {children}
            {showCancel && (
                <Button
                    disabled={loading}
                    onClick={onCancel || closeCallback}
                    data-automation="modal-footer-action-cancel-button"
                    cancel
                    {...cancelButtonProps}
                >
                    {cancelLabel}
                </Button>
            )}

            <Button
                type="submit"
                onClick={onSave}
                disabled={loading || saveDisabled}
                warning={isWarning}
                deleteButton={isDelete}
                {...saveButtonProps}
                data-automation="modal-footer-action-save-button"
            >
                {saveLabel}
            </Button>
        </ModalFooterState>
    );
};

export default ModalFooterActions;
