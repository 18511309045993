import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckedCheckboxIcon } from '../../../../images/checked-checkbox.svg';
import { ReactComponent as PartialCheckedCheckboxIcon } from '../../../../images/partial-checked-checkbox.svg';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { ButtonShape } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { CheckboxComponentSizes } from '@tonkean/tui-theme/sizes';

const SvgWrapper = styled.span`
    line-height: 0;
`;

const Box = styled.div<{
    $checked: boolean;
    $disabled?: boolean;
    $size: CheckboxComponentSizes;
    $shape: ButtonShape;
    $focused: boolean;
    $indeterminate: boolean;
    $color: Color;
    $uncheckedColor: Color;
}>`
    height: ${({ $size }) => Theme.sizes.checkbox[$size].size}px;
    width: ${({ $size }) => Theme.sizes.checkbox[$size].size}px;
    padding: ${({ $size, $shape }) => Theme.sizes.checkbox[$size].padding + ($shape === ButtonShape.ROUND ? 1 : 0)}px;
    margin: 0;

    transition: all 200ms;
    transition-delay: 100ms;
    background: transparent;
    border: 1px solid ${({ $uncheckedColor }) => $uncheckedColor};
    border-radius: ${({ $shape }) => ($shape === ButtonShape.RECTANGULAR ? '2px' : '50%')};

    display: flex;
    align-items: center;
    justify-content: center;

    ${SvgWrapper} {
        width: 100%;
        height: 100%;

        path {
            transition: stroke-dashoffset 200ms;
            transition-delay: 0ms;
            stroke-dasharray: 100%;
            stroke-dashoffset: 100%;
        }
    }

    ${({ $checked, $indeterminate, $color }) =>
        ($checked || $indeterminate) &&
        css`
            transition-delay: 0ms;
            border-color: ${$color};
            background: ${$color};

            ${SvgWrapper} path {
                transition-delay: 100ms;
                stroke-dashoffset: 0;
            }
        `}

    ${styledFocus} /* TODO change disabled state */ ${({ $disabled }) =>
        $disabled &&
        css`
            background: ${Theme.colors.gray_500};
            border: ${Theme.colors.gray_500};
        `}
`;

const CenteredIcon = styled.span`
    height: 100%;
    display: flex;
    align-items: center;
`;

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
    /** should be passed from the InvisibleInput cloneElement */
    focused?: boolean;
    checked?: boolean;
    disabled?: boolean;
    size: CheckboxComponentSizes;
    shape: ButtonShape;

    /**
     * If the checkbox is checked but with partial check. e.g. some items in list are selected, so the select all checkbox is partial.
     */
    indeterminate?: boolean;

    /**
     * A color for the box
     */
    color?: Color;

    /**
     * A color for the box when it's unchecked. Defaults to gray_700
     */
    uncheckedColor?: Color;
}

const CheckboxIcon: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    {
        focused = false,
        checked = false,
        disabled = false,
        size,
        shape,
        indeterminate = false,
        color = Theme.colors.primary,
        uncheckedColor = Theme.colors.gray_700,
        ...props
    },
    ref,
) => {
    return (
        <Box
            {...props}
            $checked={checked}
            $indeterminate={indeterminate}
            $disabled={disabled}
            $size={size}
            $shape={shape}
            $focused={focused}
            ref={ref}
            $color={color}
            $uncheckedColor={uncheckedColor}
        >
            <SvgWrapper>
                <CenteredIcon className="tnk-icon">
                    {indeterminate ? <PartialCheckedCheckboxIcon /> : <CheckedCheckboxIcon />}
                </CenteredIcon>
            </SvgWrapper>
        </Box>
    );
};

export default React.forwardRef(CheckboxIcon);
