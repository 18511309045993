import React from 'react';

import type { InternalDialog } from './Dialog';

/**
 * Context to store dialogs list.
 */
const DialogsContext = React.createContext<InternalDialog[]>([]);
DialogsContext.displayName = 'DialogsContext';

export default DialogsContext;
