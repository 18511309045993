export enum OperatorKey {
    DIVIDE = 'DIVIDE',
    MULTIPLY = 'MULTIPLY',
    POW = 'POW',
    SUBTRACTION = 'SUBTRACTION',
    SUM = 'SUM',
    AVG = 'AVG',
    CONDITIONAL_VALUES = 'CONDITIONAL_VALUES',
    STRING_LOOK_UP_TABLE = 'STRING_LOOK_UP_TABLE',
    DAYS_DIFF = 'DAYS_DIFF',
    MAX = 'MAX',
    MIN = 'MIN',
    SQRT = 'SQRT',
    TRUNC = 'TRUNC',
    MOD = 'MOD',
    ROUND = 'ROUND',
    STRING_CONCAT = 'STRING_CONCAT',
    STRING_JOIN = 'STRING_JOIN',
    FALLBACK = 'FALLBACK',
    IS_EMPTY = 'IS_EMPTY',
    IS_BOOLEAN = 'IS_BOOLEAN',
    IS_NUMBER = 'IS_NUMBER',
    IS_DATE = 'IS_DATE',
    NOT = 'NOT',
    PREVIOUS_VALUE = 'PREVIOUS_VALUE',
    STRING_INDEX_OF = 'STRING_INDEX_OF',
    SUB_STRING = 'SUB_STRING',
    STRING_CHAR_AT = 'STRING_CHAR_AT',
    STRING_REPLACE = 'STRING_REPLACE',
    JSON_PATH = 'JSON_PATH',
    JSLT = 'JSLT',
    JSON_TRAVERSE = 'JSON_TRAVERSE',
    HMAC_SHA256_ENCRYPTION = 'HMAC_SHA256_ENCRYPTION',
    SPLIT_AND_TAKE = 'SPLIT_AND_TAKE',
    ARRAY_LENGTH = 'ARRAY_LENGTH',
    ARRAY_SUM = 'ARRAY_SUM',
    ARRAY_DIFF = 'ARRAY_DIFF',
    ARRAY_DISTINCT = 'ARRAY_DISTINCT',
    STRING_TRIM = 'STRING_TRIM',
    STRING_STARTS_WITH = 'STRING_STARTS_WITH',
    STRING_UPPER_CASE = 'STRING_UPPER_CASE',
    STRING_TO_LOWER_CASE = 'STRING_TO_LOWER_CASE',
    STRING_LAST_INDEX_OF = 'STRING_LAST_INDEX_OF',
    TO_DATE = 'TO_DATE',
    STRING_LENGTH = 'STRING_LENGTH',
    STRIP_HTML = 'STRIP_HTML',
    ENCODE_URL = 'ENCODE_URL',
    DECODE_URL = 'DECODE_URL',
    ESCAPE_JSON = 'ESCAPE_JSON',
    DECODE_HTML_URL = 'DECODE_HTML_URL',
    TO_STRING = 'TO_STRING',
    TO_NUMBER = 'TO_NUMBER',
    EQUALS = 'EQUALS',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
    LOGICAL_AND = 'LOGICAL_AND',
    LOGICAL_OR = 'LOGICAL_OR',
    NOT_EQUALS = 'NOT_EQUALS',
    COLUMN_AVG = 'COLUMN_AVG',
    COLUMN_SUM = 'COLUMN_SUM',
    COLUMN_MIN = 'COLUMN_MIN',
    COLUMN_MAX = 'COLUMN_MAX',
    AT_MENTION = 'AT_MENTION',
    GENERATE_SEQUENCE_ID = 'GENERATE_SEQUENCE_ID',
    COMMENT_TRANSCRIPT = 'COMMENT_TRANSCRIPT',
    INNER_ITEMS_COUNT = 'INNER_ITEMS_COUNT',
    HOUR = 'HOUR',
    MINUTE = 'MINUTE',
    TO_TIME = 'TO_TIME',
    DAY_OF_WEEK = 'DAY_OF_WEEK',
    MONTH = 'MONTH',
    DECIMAL_FORMAT = 'DECIMAL_FORMAT',
    ADD_TIME = 'ADD_TIME',
    REGEX_FIND = 'REGEX_FIND',
    REGEX_FIND_ALL = 'REGEX_FIND_ALL',
    TEXT_ANALYSIS = 'TEXT_ANALYSIS',
    HTML_PATH = 'HTML_PATH',
    TO_PASCAL_CASE = 'TO_PASCAL_CASE',
    GREEDY_HTML_PATH = 'GREEDY_HTML_PATH',
    PREVIOUS_DAY_OF_WEEK = 'PREVIOUS_DAY_OF_WEEK',
    NEXT_DAY_OF_WEEK = 'NEXT_DAY_OF_WEEK',
    OCR_OUTPUT_CONTAINS_WORDS = 'OCR_OUTPUT_CONTAINS_WORDS',
    FIND_WORDS = 'FIND_WORDS',
    UNIQUE_IDENTIFIER = 'UNIQUE_IDENTIFIER',
    RANDOM = 'RANDOM',
    XML_PATH = 'XML_PATH',
    CONVERT_TO_BOOLEAN = 'CONVERT_TO_BOOLEAN',
    CONVERT_TO_DATE = 'CONVERT_TO_DATE',
    INNER_TRACK_AGGREGATION = 'INNER_TRACK_AGGREGATION',
    DATE_FORMAT = 'DATE_FORMAT',
    CONVERT_TO_STRING = 'CONVERT_TO_STRING',
    CONVERT_TO_NUMBER = 'CONVERT_TO_NUMBER',
    TO_TIMESTAMP = 'TO_TIMESTAMP',
    DECRYPT = 'DECRYPT',
    CONVERT_TO_ARRAY = 'CONVERT_TO_ARRAY',
    TAKE_NOT_EMPTY = 'TAKE_NOT_EMPTY',
    CONVERT_INNER_ITEMS_TO_ARRAY = 'CONVERT_INNER_ITEMS_TO_ARRAY',
    FILTER_ARRAY_OF_OBJECTS_WITH_EMPTY_FIELD = 'FILTER_ARRAY_OF_OBJECTS_WITH_EMPTY_FIELD',
    FIELD_GROUP_TAKE_NOT_EMPTY = 'FIELD_GROUP_TAKE_NOT_EMPTY',
    PAIRS = 'PAIRS',
    CREATE_LIST = 'CREATE_LIST',
    FORMAT_FULL_NAME = 'FORMAT_FULL_NAME',
    FILE_TO_BASE64 = 'FILE_TO_BASE64',
    REGEX_REPLACE = 'REGEX_REPLACE',
    MAP = 'MAP',
    EVALUATE_TONKEAN_EXPRESSION = 'EVALUATE_TONKEAN_EXPRESSION',
    ENCODE_BASE64 = 'ENCODE_BASE64',
    DECODE_BASE64 = 'DECODE_BASE64',
    ARRAY_INDEX_OF = 'ARRAY_INDEX_OF',
    DATE_DIFF = 'DATE_DIFF',
    WEEK_NUM = 'WEEK_NUM',
    ARRAY_COMMON = 'ARRAY_COMMON',
    SPLIT_TO_PARTS = 'SPLIT_TO_PARTS',
    CONVERT_OBJECT_TO_KEYED_ARRAY = 'CONVERT_OBJECT_TO_KEYED_ARRAY',
    STRING_CONCAT_WITH_LINE_BREAKS = 'STRING_CONCAT_WITH_LINE_BREAKS',
    WRAP_HTML = 'WRAP_HTML',
    STRING_CONVERT_ARRAY = 'STRING_CONVERT_ARRAY',
    XML_TO_JSON = 'XML_TO_JSON',
    ESCAPE_HTML = 'ESCAPE_HTML',
    ENCODE_MD5 = 'ENCODE_MD5',
    GET_SEQUENCE_LINK_BY_TRIGGER_NAME = 'GET_SEQUENCE_LINK_BY_TRIGGER_NAME',
    GET_INTERFACE_LINK_BY_NAME = 'GET_INTERFACE_LINK_BY_NAME',
    CSV_TO_JSON = 'CSV_TO_JSON',
    UNESCAPE_JSON = 'UNESCAPE_JSON',
    IS_IN_LIST = 'IS_IN_LIST',
}
