import React, { useContext, useMemo } from 'react';

import BreadcrumbsContext from './BreadcrumbsContext';
import type { BreadcrumbsSettingsObject } from './BreadcrumbsSettings';
import type BreadcrumbsSettings from './BreadcrumbsSettings';

import { toArray } from '@tonkean/utils';

interface Props {
    settings?:
        | BreadcrumbsSettings[]
        | BreadcrumbsSettings
        | ((parentSettings: BreadcrumbsSettingsObject[]) => BreadcrumbsSettings[] | BreadcrumbsSettings);
    append?: BreadcrumbsSettings;
}

const BreadcrumbsZone: React.FC<React.PropsWithChildren<Props>> = ({ settings: settingsProp, append, children }) => {
    const parentSettings = useContext(BreadcrumbsContext);

    const settings = useMemo(() => {
        if (!settingsProp) {
            return parentSettings;
        }

        const newSettings = typeof settingsProp === 'function' ? settingsProp(parentSettings) : settingsProp;
        return toArray(newSettings);
    }, [parentSettings, settingsProp]);

    const appended = useMemo(() => {
        if (!append) {
            return settings;
        }

        return [...settings, append];
    }, [append, settings]);

    const convertedSettings = useMemo<BreadcrumbsSettingsObject[]>(() => {
        return appended.map((breadcrumb) =>
            typeof breadcrumb === 'string' ? { id: breadcrumb, displayName: breadcrumb } : breadcrumb,
        );
    }, [appended]);

    return <BreadcrumbsContext.Provider value={convertedSettings}>{children}</BreadcrumbsContext.Provider>;
};

export default BreadcrumbsZone;
