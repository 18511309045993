import type PopperJS from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import Portal from './Portal';

/**
 * Props when there is no nodeRef - there must be one single child, and we will use React.CloneElement on it.
 */
export interface CloneElementProps {
    nodeRef?: undefined;
    children: any;
}

/**
 * Props when there is nodeRef - it can contain any type of children or none at all.
 */
export interface NodeRefProps {
    nodeRef?: React.RefObject<HTMLElement>;
    children?: React.ReactNode;
}

export type PopperChildrenRefProps = NodeRefProps | CloneElementProps;

interface Props {
    /**
     * The element to show in the popper. If a component passed, it must be an HTML element
     * or a component with forwardRef. If a function is passed, it should add them to the
     * element that will be the popper element. To add an arrow, you must use the function.
     */
    popper:
        | React.ReactComponentElement<any>
        /**
         * @param setPopperElement - should pass to the ref prop of the popper element.
         * @param popperStyles - should pass to the style prop of the popper element.
         * @param popperAttributes - should pass to the popper element (example: `<div {...popperAttributes} />`)
         * @param setArrowElement - should pass to the ref prop of the arrow element.
         * @param arrowStyles - should pass to the style prop of the arrow element.
         * @returns a react component to render.
         */
        | ((
              setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
              popperStyles: React.CSSProperties,
              popperAttributes: Record<string, string> | undefined,
              setArrowElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
              arrowStyles: React.CSSProperties,
          ) => React.ReactElement);

    placement?: PopperJS.Placement;

    shown?: boolean;

    popoverSameWidthAsOpeningElement?: boolean;

    offset?: number;
}

const Popper: React.FC<Props & PopperChildrenRefProps> = ({
    children,
    popper,
    placement = 'top',
    shown = true,
    nodeRef,
    popoverSameWidthAsOpeningElement,
    offset = 10,
}) => {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

    const modifiers = useMemo(
        () => [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    padding: 5,
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    mainAxis: true,
                    padding: 10,
                },
            },
            {
                name: 'flip',
                options: {
                    padding: 8,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, offset],
                },
            },
            {
                name: 'hide',
            },
            maxSize,
            {
                name: 'applyMaxSize',
                enabled: true,
                phase: 'beforeWrite' as const,
                requires: ['maxSize'],
                fn({ state }) {
                    const { height } = state.modifiersData.maxSize;
                    state.elements.popper.style.maxHeight = `${Math.max(window.innerHeight * 0.35, height - 10)}px`;
                },
            },
            /**
             * Taken from Poppers community modifiers
             * https://codesandbox.io/s/bitter-sky-pe3z9?file=/src/index.js
             */
            {
                name: 'popoverSameWidthAsOpeningElement',
                enabled: !!popoverSameWidthAsOpeningElement,
                phase: 'beforeWrite' as const,
                requires: ['computeStyles'],
                fn: ({ state }) => {
                    state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                effect: ({ state }) => {
                    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
                },
            },
        ],
        [arrowElement, popoverSameWidthAsOpeningElement, offset],
    );

    const { styles, attributes, update } = usePopper(
        referenceElement ?? nodeRef?.current,
        shown ? popperElement : undefined,
        {
            placement,
            modifiers,
        },
    );

    const popperComponent =
        typeof popper === 'function'
            ? popper(setPopperElement, styles.popper || {}, attributes.popper, setArrowElement, styles.arrow || {})
            : React.cloneElement(popper, {
                  ...attributes.popper,
                  style: { ...popper.props.style, ...styles.popper },
                  ref: setPopperElement,
              });

    useEffect(() => {
        if (shown) {
            update?.();
            const timeout = setTimeout(() => update?.());

            return () => clearTimeout(timeout);
        }
    }, [shown, update]);

    const modifiedChildren = nodeRef
        ? children
        : React.cloneElement(React.Children.only(children), {
              ref: setReferenceElement,
          });

    return (
        <>
            {modifiedChildren}
            <Portal>{shown && popperComponent}</Portal>
        </>
    );
};

export default Popper;
