import mime from 'mime/lite';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { toArray } from '@tonkean/utils';

class DragAndDropValidations {
    private static sequentialIdentifier = 0;

    public static getNextSequentialIdentifier() {
        this.sequentialIdentifier += 1;
        return this.sequentialIdentifier;
    }

    public static onFilesAddedGetNewFiles(
        existingFiles: TonkeanUploadedFile[],
        addedFiles: TonkeanUploadedFile[],
        maximumFilesAllowed: number = existingFiles.length + addedFiles.length,
        maxFileSizeMB,
    ): { files: TonkeanUploadedFile[]; errors: string[] } {
        const errors: string[] = [];

        // Remove files that bigger then 5MB
        const smallFiles = addedFiles.filter((file) => {
            const maxFileSize = maxFileSizeMB * 1024 * 1024;
            return file.blob && file.blob.size <= maxFileSize;
        });

        if (addedFiles.length !== smallFiles.length) {
            errors.push(`File size must be below ${maxFileSizeMB}MB.`);
        }

        // Remove existing images
        const filteredNewFiles = smallFiles.filter((file) => {
            return existingFiles.every(
                (existingFile) =>
                    existingFile.name !== file.name ||
                    existingFile?.blob?.size !== file?.blob?.size ||
                    existingFile?.blob?.type !== file?.blob?.type,
            );
        });

        if (filteredNewFiles.length !== smallFiles.length) {
            errors.push('Duplicate files found and have been removed.');
        }

        // Add only the first images if added more then allowed
        const countOfAllowedFiles = maximumFilesAllowed - existingFiles.length;

        if (filteredNewFiles.length > countOfAllowedFiles) {
            errors.push(`Can only upload ${maximumFilesAllowed} file${maximumFilesAllowed === 1 ? '' : 's'}.`);
        }

        return { files: [...existingFiles, ...filteredNewFiles.slice(0, countOfAllowedFiles)], errors };
    }

    // When we add files in pirate way (ex. when adding file from url) we must validate types
    public static isAllowedType(acceptedFileTypes: string | string[] | undefined, fileType: string | null) {
        if (!acceptedFileTypes) {
            return true;
        }

        if (!fileType) {
            return false;
        }

        const acceptedFileTypesArray = toArray(acceptedFileTypes)
            .map((acceptedFileType) => {
                if (acceptedFileType.startsWith('.')) {
                    const mimeType = mime.getType(acceptedFileType);
                    if (!mimeType) {
                        throw new Error(`Can't find mime type for ${acceptedFileType}`);
                    }
                    return mimeType;
                }
                return acceptedFileType;
            })
            // If wildcard (ends with `/*`), we remove the * to be able to use startsWith when checking types.
            .map((mimeType) => mimeType.replaceAll('/*', '/'));

        return acceptedFileTypesArray.some((mimeType) => {
            if (mimeType.endsWith('/')) {
                return fileType.startsWith(mimeType);
            }
            return mimeType === fileType;
        });
    }
}

export default DragAndDropValidations;
