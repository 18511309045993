import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import ButtonRadioGroupContext from './ButtonRadioGroupContext';
import InvisibleInput from '../InvisibleInput';
import useRadio from '../RadioButton/useRadio';

import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface WrapperProps {
    size?: InputSize;
    checked?: boolean;
    equalWidth: boolean;
    highlighted?: boolean;
    backgroundColor?: string;
}

const Wrapper = styled.div<WrapperProps>`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;

    border: 1px solid ${Theme.colors.gray_400};

    ${({ checked, highlighted, backgroundColor }) =>
        checked &&
        (highlighted
            ? css`
                  border-color: ${Theme.colors.primaryHighlight};
              `
            : backgroundColor
              ? css`
                    border-color: ${backgroundColor};
                `
              : css`
                    border-color: ${Theme.colors.primary};
                `)}

    transition: border-color 0.3s ease-in-out;

    &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    &:not(:first-child) {
        border-left: none;
    }

    &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    ${({ equalWidth }) =>
        equalWidth &&
        css`
            flex: 1 0 0;
        `}
    input[type="radio"]:unchecked + div[type="button"] {
        color: ${Theme.colors.gray_600};
    }

    div[type='button'] {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 0;
    }
`;

const AsButtonRadio = styled(Button)<{
    $fillWidth: boolean;
    outlined?: boolean;
    $checked?: boolean;
    $backgroundColor?: string;
}>`
    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            background-color: ${$backgroundColor};
            &:hover {
                background-color: ${$backgroundColor};
            }
        `}

    border: none;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;

    ${({ $fillWidth }) =>
        $fillWidth &&
        css`
            width: 100%;
        `}

    ${({ outlined }) =>
        outlined &&
        css`
            height: 55px !important;
            border: 1px solid ${Theme.colors.gray_400};
            color: ${Theme.colors.gray_600};
        `}

    ${({ $checked, outlined, $backgroundColor }) =>
        outlined &&
        $checked &&
        css`
            background-color: rgba(47, 132, 220, 0.1);
            color: ${Theme.colors.primaryHighlight};
            border: 1.5px solid ${Theme.colors.primaryHighlight};
            path {
                stroke: ${Theme.colors.primaryHighlight};
                fill: ${Theme.colors.primaryHighlight} !important;
            }
            &:focus {
                path {
                    stroke: ${Theme.colors.white};
                    fill: ${Theme.colors.white} !important;
                }
            }
        `}

        ${({ $checked, $backgroundColor }) =>
        !!$backgroundColor &&
        !$checked &&
        css`
            color: ${Theme.colors.gray_600};
            background-color: ${Theme.colors.white};
            &:hover {
                background: ${Theme.colors.white} !important;
                path {
                    fill: ${$backgroundColor} !important;
                }
            }
        `}
`;

function inputSizeToButtonSize(size: InputSize): ButtonSize {
    switch (size) {
        case InputSize.SMALL: {
            return ButtonSize.SMALL;
        }
        case InputSize.MEDIUM: {
            return ButtonSize.MEDIUM;
        }
        case InputSize.MEDIUM_LARGE: {
            return ButtonSize.MEDIUM_BIG;
        }
        case InputSize.XMEDIUM_LARGE: {
            return ButtonSize.XMEDIUM_BIG;
        }
        case InputSize.LARGE: {
            return ButtonSize.BIG;
        }
        case InputSize.XLARGE: {
            return ButtonSize.HUGE;
        }
    }
}

interface Props extends DataAutomationSupportProps, StyledComponentsSupportProps {
    /** Is this radio disabled? */
    disabled?: boolean;

    /**
     * The value of the button. It is not the value that will be passed to the input.
     * It will generate a random fake value, and will return the value supplied in the onChange.
     */
    value: any;

    /**
     * If the highlighted is true, the button will be in highlighted color theme
     */
    highlighted?: boolean;

    outlined?: boolean;
    /** custom background color */
    backgroundColor?: string;
}

const ButtonRadioGroupButton: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<Props>> = (
    { className, dataAutomation, value, disabled = false, highlighted = false, outlined, backgroundColor, children },
    ref,
) => {
    const { equalWidth } = useContext(ButtonRadioGroupContext);
    const { props, onChange, radioGroupDisabled, fakeValue, size } = useRadio(value);

    return (
        <Wrapper
            checked={props.checked}
            equalWidth={equalWidth}
            highlighted={highlighted}
            backgroundColor={backgroundColor}
        >
            <InvisibleInput
                {...props}
                type="radio"
                disabled={disabled || radioGroupDisabled}
                ref={ref}
                className={className}
                value={fakeValue}
                onChange={onChange}
                zeroHeight
            >
                <AsButtonRadio
                    as="label"
                    htmlFor={props.id}
                    cancel={!props.checked}
                    data-automation={dataAutomation}
                    size={inputSizeToButtonSize(size)}
                    highlighted={highlighted}
                    $fillWidth={equalWidth}
                    $checked={props.checked}
                    outlined={outlined}
                    $backgroundColor={backgroundColor}
                >
                    {children}
                </AsButtonRadio>
            </InvisibleInput>
        </Wrapper>
    );
};

export default React.forwardRef(ButtonRadioGroupButton);
