import React from 'react';
import type { CSSObject } from 'styled-components';
import styled, { css } from 'styled-components';

export interface RevealedProps {
    /**
     * What the component will be rendered as. (Whats the actual input component)
     */
    as: React.ElementType;
    /**
     * Whats the bordered element we wish to hide\show the border on.
     * Will default to self.
     *
     * For help with selectors review MDN: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors
     */
    borderedSelector?: string | string[];

    /**
     * Custom css override for the bordered element when its border is hidden.
     * Use to ensure no jumps in UI happens when hiding\showing border.
     */
    $borderedElementCssOverride?: CSSObject;

    /**
     * Whats the outlined element we wish the hide\show the border on?
     * Will default to the bordered selector.
     */
    outlinedSelector?: string | string[];

    /**
     *  A selector for elements that should have their box-shadow removed.
     */
    boxShadowSelector?: string;

    /**
     * Additional selectors for elements to hide.
     * Note: this should be used sparingly, if possible its better to take care of this in the inner component by taking
     * the readOnly property into account.
     */
    selectorsToHide?: string[];

    /**
     * If this input is readonly, it will remain in the hidden state.
     */
    readOnly?: boolean;

    /**
     * Whether the input should be revealed even without mouse hover.
     */
    $revealByDefault?: boolean;
}

const hidingCss = css<RevealedProps>`
    ${({ borderedSelector }) => {
        if (typeof borderedSelector === 'string') {
            return borderedSelector;
        } else if (Array.isArray(borderedSelector)) {
            return borderedSelector.join(', ');
        }

        return '&';
    }} {
        // We don't remove the border but make it invisible, this is much more subtle and will result in less UI 'jumps'
        border-color: transparent ${({ $borderedElementCssOverride }) => !$borderedElementCssOverride && '!important'};
        ${({ $borderedElementCssOverride }) => $borderedElementCssOverride}
    }

    ${({ outlinedSelector, borderedSelector }) => {
        if (outlinedSelector) {
            if (typeof outlinedSelector === 'string') {
                return outlinedSelector;
            }
            if (Array.isArray(outlinedSelector)) {
                return outlinedSelector.join(', ');
            }
        }
        return borderedSelector ?? '&';
    }} {
        outline: none;
    }

    ${({ boxShadowSelector }) => boxShadowSelector} {
        box-shadow: none;
    }

    ${({ selectorsToHide = [] }) =>
        !!selectorsToHide.length &&
        css`
            ${selectorsToHide.join(', ')} {
                display: none;
            }
        `}
`;
type OnChange<T = unknown> = RevealedProps['as'] extends { onChange: T } ? { onChange: T } : undefined;
const InputReveal = styled.div<RevealedProps & { onChange?: OnChange }>`
    ${({ readOnly, $revealByDefault: revealByDefault = false }) => {
        if (revealByDefault) {
            return;
        }

        if (readOnly) {
            return css`
                ${hidingCss}
                &:focus {
                    ${hidingCss}
                }
            `;
        } else {
            return css`
                &:not(:active):not(:hover):not(:focus):not(:focus-visible):not(:focus-within) {
                    ${hidingCss}
                }
            `;
        }
    }}
`;

/**
 * Hack for when you want to use InputReveal on angularToReact components.
 */
export const InputRevealContainer: React.FC<React.PropsWithChildren<Omit<RevealedProps, 'as'>>> = ({
    children,
    ...revealedProps
}) => {
    return (
        <InputReveal as="div" {...revealedProps}>
            {children}
        </InputReveal>
    );
};

export default InputReveal;
