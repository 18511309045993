import styled, { css } from 'styled-components';

import LinkUnderline from './LinkUnderline';
import { Clickable } from '../Clickable';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

type LinkStyle = { textColor: Color };
const linkStyles: Record<'primary' | 'black' | 'highlight', LinkStyle> = {
    primary: {
        textColor: Theme.colors.primary,
    },
    black: {
        textColor: Theme.colors.gray_700,
    },
    highlight: {
        textColor: Theme.colors.primaryHighlight,
    },
};

const getLinkStyle = ({ black, highlight }): LinkStyle => {
    if (black) {
        return linkStyles.black;
    }
    if (highlight) {
        return linkStyles.highlight;
    }

    return linkStyles.primary;
};

interface LinkProps {
    underline?: LinkUnderline;
    black?: boolean;
    disableColoring?: boolean;
    disabled?: boolean;
    highlight?: boolean;
}

const propsToExclude: (keyof LinkProps)[] = ['underline', 'black', 'highlight', 'disableColoring'];

const Link = styled.a.withConfig({
    shouldForwardProp: (prop: any) => !propsToExclude.includes(prop),
})<LinkProps>`
    font-size: inherit;
    text-decoration: ${({ underline = LinkUnderline.NEVER }) =>
        underline === LinkUnderline.ALWAYS ? 'underline' : 'none'};

    ${({ disabled, black, highlight, disableColoring, underline = LinkUnderline.NEVER }) => {
        const linkStyle = getLinkStyle({ black, highlight });
        return !disabled
            ? css`
                  & {
                      ${disableColoring
                          ? css`
                                color: unset;
                            `
                          : css`
                                color: ${linkStyle.textColor};
                            `}
                  }

                  &:hover,
                  &:focus {
                      ${disableColoring
                          ? css`
                                color: unset;
                            `
                          : css`
                                color: ${Theme.colors.focus};
                            `}
                  }

                  &:hover {
                      text-decoration: ${underline === LinkUnderline.ON_HOVER || underline === LinkUnderline.ALWAYS
                          ? 'underline'
                          : 'none'};
                  }

                  &:not(:is(button, a, [role='button'])):hover {
                      text-decoration: ${underline === LinkUnderline.ON_HOVER || underline === LinkUnderline.ALWAYS
                          ? 'underline'
                          : 'none'};
                  }
              `
            : css`
                  &,
                  &:focus,
                  &:hover {
                      ${disableColoring
                          ? css`
                                color: unset;
                            `
                          : css`
                                color: ${Theme.colors.gray_400};
                            `}
                  }
              `;
    }}
`;

export default Link;
export const ClickableLink = styled(Link).attrs({ as: Clickable })``;
