import React, { useCallback, useMemo, useState } from 'react';

export const KeyboardArrowFocusSwitchContext = React.createContext<{
    addNode(node: HTMLElement): () => void;
    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void;
}>({ addNode: () => () => {}, onKeyDown() {} });

function sortDomNodes(nodes: HTMLElement[]) {
    const DOCUMENT_POSITION_PRECEDING = 2;

    return nodes.sort((a, b) => {
        if (a.compareDocumentPosition(b) & DOCUMENT_POSITION_PRECEDING) {
            return 1;
        }
        return -1;
    });
}

function getNextNodeToFocus(nodes: HTMLElement[], currentlyActiveNode: HTMLElement, toPreviousNode: boolean) {
    const currentlyActiveNodeIndex = nodes.indexOf(currentlyActiveNode);

    let nextNodeIndex: number;
    if (toPreviousNode) {
        const previousIndex = currentlyActiveNodeIndex - 1;
        nextNodeIndex = previousIndex >= 0 ? previousIndex : nodes.length - 1;
    } else {
        const nextIndex = currentlyActiveNodeIndex + 1;
        nextNodeIndex = nextIndex >= nodes.length ? 0 : nextIndex;
    }

    return nodes[nextNodeIndex];
}

/**
 * This react component allows to use the up and down keys to change focus over children, like in an options menu.
 * To use it, you should use `const {addNode, onKeyDown} = useContext(KeyboardArrowFocusSwitchContext)` on the
 * component of the focusable element, and add the ref of it using addNode, and add an onKeyDown to it.
 *
 * @example When you will focus on an OptionsItem in Options, you'll be able to switch focus using the keyboard arrows.
 * const OptionsItem = ({onClick, children}) => {
 *     const ref = useRef(null);
 *     const {addNode, onKeyDown} = useContext(KeyboardArrowFocusSwitchContext);
 *
 *     useEffect(() => {
 *         const remove = addNode(ref.current);
 *
 *         return () => {
 *             remove();
 *         };
 *     }, []);
 *
 *     return (
 *         <button onClick={onClick} onKeyDown={onKeyDown} ref={ref}>{children}</button>
 *     );
 * };
 *
 * const Options = () => {
 *     return (
 *         <div className="options-menu-wrapper">
 *             <KeyboardArrowFocusSwitch>
 *                 <OptionsItem onClick={() => alert("hi")}>I'll alert hi</OptionsItem>
 *                 <OptionsItem onClick={() => console.log("hi")}>I'll console log hi</OptionsItem>
 *             </KeyboardArrowFocusSwitch>
 *         </div>
 *     );
 * };
 */
const KeyboardArrowFocusSwitch: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [nodes, setNodes] = useState<HTMLElement[]>([]);

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            const toPreviousItem = event.key === 'ArrowUp';
            const toNextItem = event.key === 'ArrowDown';

            const currentlyActiveNode = document.activeElement as HTMLElement | null;
            if ((!toPreviousItem && !toNextItem) || !currentlyActiveNode) {
                return;
            }

            const sortedMenuNodes = sortDomNodes(nodes);
            const nextNodeToFocus = getNextNodeToFocus(sortedMenuNodes, currentlyActiveNode, toPreviousItem);

            nextNodeToFocus?.focus();
            event.preventDefault();
        },
        [nodes],
    );

    const addNode = useCallback((node: HTMLElement) => {
        setNodes((currentNodes) => [...currentNodes, node]);

        return () => {
            setNodes((currentNodes) => currentNodes.filter((menuNode) => menuNode !== node));
        };
    }, []);

    const contextValue = useMemo(() => ({ addNode, onKeyDown }), [addNode, onKeyDown]);

    return (
        <KeyboardArrowFocusSwitchContext.Provider value={contextValue}>
            {children}
        </KeyboardArrowFocusSwitchContext.Provider>
    );
};

export default KeyboardArrowFocusSwitch;
