import type TonkeanId from '../TonkeanId';
import type TonkeanType from '../TonkeanType';

export enum SmartSearchLookupRelevanceType {
    INITIATIVE = 'INITIATIVE',
    EXTERNAL_ACTIVITY = 'EXTERNAL_ACTIVITY',
}

export interface ExternalActivitySmartSearchLookupRelevance extends BaseSmartSearchLookupEntityRelevance {
    type: SmartSearchLookupRelevanceType.EXTERNAL_ACTIVITY;
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    entityId: string;
    title: string;
    integrationType: string;
    externalTypeDisplayName: string;
    externalUrl: string;
}

export interface InitiativeSmartSearchLookupRelevance extends BaseSmartSearchLookupEntityRelevance {
    entityId: TonkeanId<TonkeanType.INITIATIVE>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    title: string;
    groupName: string;
    type: SmartSearchLookupRelevanceType.INITIATIVE;
}

interface BaseSmartSearchLookupEntityRelevance {
    type: SmartSearchLookupRelevanceType;
    entityId: string;
}

export type SmartSearchLookupEntityRelevance =
    | InitiativeSmartSearchLookupRelevance
    | ExternalActivitySmartSearchLookupRelevance;

export default interface SmartSearchLookupResponse {
    entities: SmartSearchLookupEntityRelevance[];
    totalHits: number;
}
