import { Theme, type Color } from '@tonkean/tui-theme';
import { memoize } from '@tonkean/utils';

export const CONTRAST_GUIDELINES_DARK_TEXT = Theme.colors.gray_800;
export const CONTRAST_GUIDELINES_LIGHT_TEXT = Theme.colors.white;

/**
 * Returns a color (either white or grey 800) depends on the given background color
 * Break the hex code into 3 pieces to get the individual red, green, and blue intensities.
 * Calculate the RGB brightness and compare it with a constant 186 brightness threshold.
 * If the calculated RGB brightness higher than the threshold return dark text color and vice versa.
 * see more info here:
 * https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
 * https://www.w3.org/TR/AERT/#color-contrast
 *
 * @param backgroundColor - The color of the background
 * @returns The text color that fits according to the brightness of the background color
 */
function getTextColorByBackgroundColor(backgroundColor: Color): Color {
    // brightness threshold constant
    const brightnessThreshold = 186;

    // Convert to long hex if needed and remove the '#'
    const parsedBackgroundColor = convertShortToLongHexCodeIfNeeded(backgroundColor).slice(1);

    // break the hex code into red, green and blue
    const r = Number.parseInt(parsedBackgroundColor.slice(0, 2), 16);
    const g = Number.parseInt(parsedBackgroundColor.slice(2, 4), 16);
    const b = Number.parseInt(parsedBackgroundColor.slice(4, 6), 16);

    // color brightness is determined by the following formula. see https://www.w3.org/TR/AERT/#color-contrast
    const grey = r * 0.299 + g * 0.587 + b * 0.114;

    // if the color brightness higher than the brightness threshold return dark text color or white text color if it is lower
    return grey > brightnessThreshold ? CONTRAST_GUIDELINES_DARK_TEXT : CONTRAST_GUIDELINES_LIGHT_TEXT;
}

/**
 * To convert for colors that are in short form hex code you just double each character
 * This method checks if you need to do that and returns a new string if needed;
 * Example:
 * #f9c => #ff99cc
 */
function convertShortToLongHexCodeIfNeeded(color: Color) {
    if (color.length === 4) {
        const r = color[1];
        const g = color[2];
        const b = color[3];
        return `#${r}${r}${g}${g}${b}${b}`;
    }

    return color;
}

export default memoize(getTextColorByBackgroundColor);
