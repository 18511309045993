import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledResponsiveCarousel = styled(ResponsiveCarousel)`
    background: black;
`;
interface Props extends StyledComponentsSupportProps {
    images: string[];
    size: string;
}
const Carousel: React.FC<Props> = ({ images, size, className }) => {
    return (
        <StyledResponsiveCarousel className={className} showThumbs={false} swipeable emulateTouch>
            {images.map((image, index) => (
                <div key={index}>
                    <img style={{ height: size, width: 'auto' }} src={image} alt="" />
                </div>
            ))}
        </StyledResponsiveCarousel>
    );
};

export default Carousel;
