import { AnimatePresence, motion, type Transition } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import type SidePaneProps from './SidePaneProps';
import useEscapeCallback from '../../../hooks/useEscapeCallback';
import useFocusTrap from '../../../hooks/useFocusTrap';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useOnBeforeCloseQueueManager } from '../CloseCallback';
import { MODAL_Z_INDEX } from '../Modal/ModalWrapper';

import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { Theme } from '@tonkean/tui-theme';

export const SIDE_PANE_ANIMATION_DURATION_SECONDS = 0.2;

const boxShadowSettings = '0px 2px 10px';
const boxShadowHidden = `${boxShadowSettings} rgba(0, 0, 0, 0)`;
const boxShadowShown = `${boxShadowSettings} rgba(0, 0, 0, 0.09)`;

type WrapperProps = {
    width: number;
    side: 'right' | 'left';
    top: string;
    sideOffset: string;
    inline: boolean;
    allowPeekOverflow: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    // We use another container with overflow hidden so that the overflowing side-pane (nudged with transitionX) wont take up more space
    overflow: ${({ allowPeekOverflow }) => (allowPeekOverflow ? 'visible' : 'hidden')};

    pointer-events: none; // Disable pointer events because this wrapper doesn't slide, so we dont want to block actions when closed

    position: ${({ inline }) => (inline ? 'absolute' : 'fixed')};
    top: ${({ top }) => top};

    ${({ side }) => side}: ${({ sideOffset }) => sideOffset};

    bottom: 0;
    // We make the container a little larger so that the box-shadow shows
    width: ${({ width }) => width + 10}px;
    z-index: ${MODAL_Z_INDEX};
`;

type AnimatedWrapperProps = {
    width: number;
    side: 'right' | 'left';
    borderLabel: 'border-left' | 'border-right';
    $allowPeekOverflow: boolean;
};

const AnimatedWrapper = styled(motion.div)<AnimatedWrapperProps>`
    pointer-events: auto; // re-enable pointer-events on actual content
    position: absolute;
    ${({ side }) => side}: 0;
    width: ${({ width }) => width}px;
    height: 100%;
    overflow: ${({ $allowPeekOverflow }) => ($allowPeekOverflow ? 'visible' : 'hidden')};
    background: ${Theme.colors.basicBackground};
    box-shadow: ${boxShadowShown};
    ${({ borderLabel }) => ({
        [borderLabel]: `1px solid ${Theme.colors.gray_400};`,
    })}

    ${childrenStyledFocus}
`;

const Container = styled.div<{ width: string; $allowPeekOverflow: boolean }>`
    min-width: ${({ width }) => width};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: ${({ $allowPeekOverflow }) => ($allowPeekOverflow ? 'visible' : 'auto')};
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: -1;
`;

const defaultTransition: Transition = {
    ease: 'easeInOut',
    duration: SIDE_PANE_ANIMATION_DURATION_SECONDS,
};

const SidePane: React.FC<React.PropsWithChildren<SidePaneProps>> = ({
    children,
    open,
    onClose: onCloseProp,
    clickOutsideCloses = true,
    escapeCloses = true,
    focusTrap = true,
    skipInitialAnimation = false,
    initiallyFocusedElementRef,
    width,
    side = 'right',
    sideOffset = '0',
    top = '0',
    onExitComplete,
    className,
    inline = false,
    peek,
    showShadow = true,
    backdrop = false,
    allowPeekOverflow = false,
    transition = defaultTransition,
}) => {
    const focusTrapRef = useFocusTrap(open && focusTrap, initiallyFocusedElementRef);

    const { CloseContextProvider, onClose } = useOnBeforeCloseQueueManager(onCloseProp);

    const onClickOutsideRef = useOnClickOutside(
        () => {
            if (clickOutsideCloses) {
                onClose();
            }
        },
        open && (!inline || clickOutsideCloses),
    );

    useEscapeCallback(
        () => {
            if (escapeCloses) {
                onClose();
            }
        },
        open && (!inline || escapeCloses),
    );

    const ref = useMultipleRefCallback(onClickOutsideRef, focusTrapRef);
    const widthString = `${width}px`;

    const oppositeBorderLabel = side === 'left' ? 'border-right' : 'border-left';
    const borderWidthStyleKey = side === 'left' ? ('borderLeftWidth' as const) : ('borderRightWidth' as const);
    const variants = {
        closed: {
            x: side === 'left' ? `-${widthString}` : widthString,
            [borderWidthStyleKey]: 0,
            boxShadow: boxShadowHidden,
        },
        open: {
            x: 0,
            [borderWidthStyleKey]: 1,
            boxShadow: showShadow ? boxShadowShown : boxShadowHidden,
        },
        peek: peek
            ? {
                  x: `${side === 'left' ? '-' : ''}${width - peek}px`,
                  [borderWidthStyleKey]: 1,
                  boxShadow: showShadow ? boxShadowShown : boxShadowHidden,
              }
            : {},
    };

    let animateState: keyof typeof variants;
    if (open) {
        animateState = 'open';
    } else {
        animateState = peek ? 'peek' : 'closed';
    }

    return (
        <CloseContextProvider>
            {/* I dont understand what Portal does here, from what i can see the sidepane works as intended without it,
              I disable it because with this the sidepane in the solution site would stay on when moving to another page.
               If there are problems because of this, talk to yftach */}
            {/* <Portal disabled={inline}>*/}
            <AnimatePresence onExitComplete={onExitComplete}>
                {(open || peek !== undefined) && (
                    <Wrapper
                        width={width}
                        side={side}
                        top={top}
                        sideOffset={sideOffset}
                        inline={inline}
                        allowPeekOverflow={allowPeekOverflow}
                    >
                        <AnimatedWrapper
                            key="side-pane"
                            initial={skipInitialAnimation ? false : variants.closed}
                            animate={animateState}
                            exit={variants.closed}
                            variants={variants}
                            transition={transition}
                            width={width}
                            side={side}
                            borderLabel={oppositeBorderLabel}
                            className={className}
                            $allowPeekOverflow={allowPeekOverflow}
                            ref={ref}
                        >
                            <Container tabIndex={-1} width={widthString} $allowPeekOverflow={allowPeekOverflow}>
                                {children}
                            </Container>
                        </AnimatedWrapper>
                        {!inline && open && backdrop && <Backdrop />}
                    </Wrapper>
                )}
            </AnimatePresence>
            {/* </Portal>*/}
        </CloseContextProvider>
    );
};

export default SidePane;
