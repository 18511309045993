import React, { useContext, useEffect, useState } from 'react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';

import { RouterContext } from './RouterLinkContext';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { EMPTY_OBJECT } from '@tonkean/utils';

const prefetchedRouters: Record<string, boolean> = {};

interface Props extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    path: string | undefined;
    query: Record<string, string> | undefined;
    replace?: boolean | undefined;
    options?: Record<string, string>;
    children: React.ReactNode;
    openInNewTab?: boolean;
    /**
     * if true, this anchor element will do nothing, and will be used only to set href. It's same as using
     * `event.preventDefault` in the onClick prop.
     */
    dontChangeState?: boolean;

    /**
     * A callback that's triggered when clicking on the state link. If the callback returns false, it won't change state.
     * If it returns a promise, it will wait for it to resolve before continuing.
     */
    onClick?(event: React.MouseEvent<HTMLAnchorElement>): void | boolean | Promise<boolean> | Promise<void>;
}

const RouterLink: React.ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
    {
        children,
        openInNewTab,
        path,
        query = EMPTY_OBJECT,
        replace = false,
        options = {},
        dontChangeState = false,
        onClick: onClickProp,
        onFocus: onFocusProp,
        onMouseEnter: onMouseEnterProp,
        // Angular adds preventDefault to all anchor elements without a target, but it will block the
        // useCompositeEventCallback hook, because it checks if the event is prevented before calling the callback. If
        // an anchor has a target, angular will ignore it.
        target = openInNewTab ? '_blank' : '_self',
        ...props
    },
    ref,
) => {
    const routerContextValue = useContext(RouterContext);
    if (!routerContextValue) {
        throw new Error(
            'Attempted to use RouterLink (or Clickable with state/params) without passing TonkeanRouter to ReactRoot',
        );
    }

    const { go, getHref: getHrefContext, prefetch } = routerContextValue;

    const getHref = () => {
        return getHrefContext(path, query);
    };
    const [href, setHref] = useState(getHref);
    const onInteraction = useConstantRefCallback(() => {
        const hrefValue = getHref();
        setHref(hrefValue);

        if (prefetch && !prefetchedRouters[hrefValue]) {
            prefetch(path, query);
            prefetchedRouters[hrefValue] = true;
        }
    });

    const [intersectionObserverRef, { entry }] = useIntersectionObserver();
    const visible = entry?.isIntersecting;
    useEffect(() => {
        if (visible) {
            onInteraction();
        }
    }, [onInteraction, visible]);

    const compositeRef = useMultipleRefCallback(ref, intersectionObserverRef);

    // when $state.params changes, it not creates a re-render, so storing the concatenated params in a useMemo
    // or just in a variable might hold the old value. To prevent it, we re-generate the concatenated params by
    // accessing $state.params when the user focuses or hovers over the anchor element.
    const onFocus = useCompositeEventCallback(onFocusProp, onInteraction);
    const onMouseEnter = useCompositeEventCallback(onMouseEnterProp, onInteraction);

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
        const onClickResult = onClickProp?.(event);

        // If should open with a new tab, or the user holds the alt key (which will download the page), or ctrl or meta
        // (which is command in mac), let the browser handle the click.
        if (openInNewTab || event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }

        // Prevent browser redirect
        event.preventDefault();

        const onClickResultAwaited = await onClickResult;
        if (onClickResultAwaited !== false && !dontChangeState) {
            go(path, query, replace, options);
        }
    };

    return (
        <a
            {...props}
            onClick={onClick}
            target={target}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            href={href}
            ref={compositeRef}
        >
            {children}
        </a>
    );
};

export default React.forwardRef(RouterLink);
