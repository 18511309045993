export const STATES_COLORS = {
    blue: '#2F84DC',
    yellow: '#F0CA27',
    purple: '#6C58A7',
    black: '#292929',
    grey: '#6A6C6D',
    green: '#23930D',
    orange: '#FF611C',
    red: '#D10027',
    darkBlue: '#396394',
    // New colors.
    hotPink: '#FF5770',
    brown: '#9B6F4B',
    royalBlue: '#27709C',
    parakeetGreen: '#36B67A',
    lilachPurple: '#C869AA',
    turquoise: '#00ADC9',
    Pink: '#FF82AB',
    Teal: '#009E73',
    Lime: '#89CA61',
    Navy: '#003F9F',
    Maroon: '#8C2329',
    Coral: '#FF7F50',
    Gold: '#FFD700',
    Silver: '#C0C0C0',
    Emerald: '#50C878',
    Lavender: '#E6E6FA',
    Ruby: '#E0115F',
    Indigo: '#4B0082',
    Salmon: '#FA8072',
    Peach: '#FFDAB9',
    Cyan: '#00FFFF',
    Magenta: '#FF00FF',
};

export function getStatesColors() {
    return structuredClone(STATES_COLORS);
}
