import React from 'react';
import styled, { css } from 'styled-components';

import useFileUrl from './hooks/useFileUrl';
import { ReactComponent as GreyError } from '../../../../images/grey-error.svg';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const RemoveButton = styled(Clickable)`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    display: flex;
`;

const ImportedFile = styled.div<{ imageUrl?: string }>`
    ${({ imageUrl }) =>
        imageUrl &&
        css`
            background-image: url('${imageUrl}');
        `};
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    border: 1px solid ${Theme.current.palette.general.border};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0967821);
    border-radius: 3px;

    position: relative;
    height: 100%;
    width: 100%;
`;

interface Props {
    file: TonkeanUploadedFile;
    isInPreview: boolean;
    onDelete(): void;
}

const DragAndDropImagePreview: React.FC<Props> = ({ file, onDelete, isInPreview }) => {
    const imageUrl = useFileUrl(file);

    return (
        <ImportedFile imageUrl={imageUrl}>
            {!isInPreview && (
                <RemoveButton onClick={onDelete}>
                    <GreyError />
                </RemoveButton>
            )}
        </ImportedFile>
    );
};

export default DragAndDropImagePreview;
