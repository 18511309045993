import React from 'react';
import styled, { css } from 'styled-components';

import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div<{ flex?: boolean }>`
    ${({ flex }) =>
        flex
            ? css`
                  display: flex;
                  align-items: center;
              `
            : ``}
`;
/**
 * @deprecated use TUI/breadcrumbs instead!
 */
export const BreadCrumb = styled.span<{ bold?: boolean; flex?: boolean }>`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    text-transform: capitalize;
    color: ${Theme.colors.gray_500};

    ${({ bold }) =>
        bold &&
        css`
            font-weight: 500;

            &:last-child {
                color: ${Theme.colors.gray_800};
            }
        `}

    ${({ flex }) =>
        flex
            ? css`
                  display: inline-flex;
                  align-items: center;
              `
            : ``}

    &:not(:last-child)::after {
        content: '/';
        margin: 0 1ch;
    }
`;

interface Props extends StyledComponentsSupportProps {
    crumbs?: string[];
    flex?: boolean;
}

/**
 * @deprecated use TUI/breadcrumbs instead!
 */
const BreadCrumbs: React.FC<React.PropsWithChildren<Props>> = ({ crumbs, flex, className, children }) => {
    return (
        <Wrapper flex={flex} className={className}>
            {crumbs?.map((item) => <BreadCrumb key={item}>{item}</BreadCrumb>)}
            {children}
        </Wrapper>
    );
};

export default BreadCrumbs;
