import styled, { css } from 'styled-components';

import { Checkbox } from '../TUI';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

export const PickerListPart = styled(Clickable)<{ clickable?: boolean; customPadding?: number }>`
    display: flex;
    padding: 0 ${({ customPadding }) => customPadding || 14}px;
    align-items: center;
    font-weight: unset;
    letter-spacing: unset;
    margin: unset;

    ${({ clickable }) =>
        clickable &&
        css`
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: ${Theme.current.palette.mainColors.brightestGray};
            }
        `};

    ${styledFocus}
`;
export const ITEM_HEIGHT = 34;
export const RowCheckbox = styled(Checkbox)`
    margin-right: 8px;
`;
export const RowText = styled.div<{ greyed?: boolean }>`
    line-height: normal;

    ${({ greyed }) =>
        greyed &&
        css`
            color: ${Theme.colors.gray_500};
        `}
`;
