import React from 'react';
import styled from 'styled-components';

import ImportInnerItemsFromCSVModal from './ImportInnerItemsFromCSVModal';
import { useToggle } from '../../hooks';

import { ImportIcon } from '@tonkean/svg';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${FontSize.SMALL_12};
    font-weight: 400;
`;

interface Props {
    fieldIdsToFieldNames: Record<string, string>;
    formName: string;
    onMappingsCreated(newInitiatives: Record<string, string>[]): Promise<number>;
    onExited(): void;
    disabled?: boolean;
}

const ImportInnerItemsFromCSV: React.FC<Props> = ({
    fieldIdsToFieldNames,
    formName,
    onMappingsCreated,
    onExited,
    disabled = false,
}) => {
    const [isOpen, toggleOpen] = useToggle(false);
    return (
        <>
            <Button
                size={ButtonSize.MEDIUM}
                shape={ButtonShape.RECTANGULAR}
                onClick={() => toggleOpen()}
                disabled={disabled}
                outlined
            >
                <ButtonContent>
                    <ImportIcon />
                    Import
                </ButtonContent>
            </Button>
            <ImportInnerItemsFromCSVModal
                formName={formName}
                isOpen={isOpen}
                onExited={onExited}
                toggleOpen={toggleOpen}
                fieldIdsToFieldNames={fieldIdsToFieldNames}
                onMappingsCreated={onMappingsCreated}
            />
        </>
    );
};

export default ImportInnerItemsFromCSV;
