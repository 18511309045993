import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled.div`
    display: flex;
    margin-right: 6px;
    vertical-align: middle;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    flex-shrink: 0;

    .tnk-icon,
    svg {
        width: 16px;
        height: 16px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
    }

    img {
        background-size: contain !important;
        background-repeat: no-repeat !important;
        width: 16px;
        height: 16px;
    }
`;

const Text = styled.div`
    flex-grow: 1;
    position: relative;
    display: grid;
    align-items: center;
    vertical-align: middle;
`;

interface Props {
    icon?: React.ReactElement;
}

const TnkIconSelection: React.FC<React.PropsWithChildren<Props>> = ({ icon, children }) => {
    return (
        <Wrapper>
            {icon && <StyledIcon>{icon}</StyledIcon>}
            <Text data-automation="tnk-icon-selection">{children}</Text>
        </Wrapper>
    );
};

export default TnkIconSelection;
