enum ActionParameterType {
    TONKEAN_EXPRESSION = 'TONKEAN_EXPRESSION',
    FIELD_LIST = 'FIELD_LIST',
    NUMBER_TONKEAN_EXPRESSION = 'NUMBER_TONKEAN_EXPRESSION',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    CLOSE_LIST = 'CLOSE_LIST',
}

export default ActionParameterType;
