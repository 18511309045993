import React, { useCallback, useRef, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled, { css } from 'styled-components';

import ModalAnimationType from './ModalAnimationType';
import ModalBackdrop from './ModalBackdrop';
import type { ModalContentProps } from './ModalContent';
import ModalContent from './ModalContent';
import ModalSize from './ModalSize';
import ModalWrapper from './ModalWrapper';
import useEscapeCallback from '../../../hooks/useEscapeCallback';
import useFocusTrap from '../../../hooks/useFocusTrap';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import type { BreadcrumbsSettingsObject } from '../Breadcrumbs';
import BreadcrumbsZone from '../Breadcrumbs/BreadcrumbsZone';
import { useOnBeforeCloseQueueManager } from '../CloseCallback';
import Portal from '../Portal';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const ModalWrapperWithAnimations = styled(ModalWrapper)<{
    animationDuration: number;
    modalAnimationType: ModalAnimationType;
    // Todo use -  FlattenSimpleInterpolation is missing in styled component
    $customAnimation: any;
}>`

    ${({ modalAnimationType, animationDuration, $customAnimation }) => {
        switch (modalAnimationType) {
            case ModalAnimationType.ANIMATE_OPACITY: {
                return css`
                    &.modal-animation-enter,
                    &.modal-animation-appear {
                        ${ModalBackdrop}, ${ModalContent} {
                            opacity: 0;
                        }

                        ${ModalContent} {
                            transform: scale(0.9);
                            display: flex;
                        }

                        ${ModalBackdrop} {
                            display: block;
                        }
                    }

                    &.modal-animation-enter-active,
                    &.modal-animation-appear-active {
                        ${ModalBackdrop}, ${ModalContent} {
                            transition: all ${animationDuration}ms;
                            opacity: 1;
                        }

                        ${ModalContent} {
                            transform: scale(1);
                        }
                    }

                    &.modal-animation-exit {
                        ${ModalBackdrop}, ${ModalContent} {
                            opacity: 1;
                        }

                        ${ModalContent} {
                            transform: scale(1);
                        }
                    }

                    &.modal-animation-exit-active {
                        ${ModalBackdrop}, ${ModalContent} {
                            transition: all ${animationDuration}ms;
                            opacity: 0;
                        }

                        ${ModalContent} {
                            transform: scale(0.9);
                        }
                    }

                    &.modal-animation-exit-done {
                        ${ModalBackdrop}, ${ModalContent} {
                            display: none;
                        }
                    }
                `;
            }
            case ModalAnimationType.ANIMATE_OPACITY_ONLY: {
                return css`
                    &.modal-animation-enter,
                    &.modal-animation-appear {
                        ${ModalBackdrop}, ${ModalContent} {
                            opacity: 0;
                        }

                        ${ModalBackdrop} {
                            display: block;
                        }

                        ${ModalContent} {
                            display: flex;
                        }
                    }

                    &.modal-animation-enter-active,
                    &.modal-animation-appear-active {
                        ${ModalBackdrop}, ${ModalContent} {
                            transition: all ${animationDuration}ms;
                            opacity: 1;
                        }
                    }

                    &.modal-animation-exit {
                        ${ModalBackdrop}, ${ModalContent} {
                            opacity: 1;
                        }
                    }

                    &.modal-animation-exit-active {
                        ${ModalBackdrop}, ${ModalContent} {
                            transition: all ${animationDuration}ms;
                            opacity: 0;
                        }
                    }

                    &.modal-animation-exit-done {
                        ${ModalBackdrop}, ${ModalContent} {
                            display: none;
                        }
                    }
                `;
            }
            case ModalAnimationType.ANIMATE_BOTTOM_TO_TOP: {
                return css`
                    &.modal-animation-enter,
                    &.modal-animation-appear {
                        ${ModalContent} {
                            bottom: 0;
                            transform: translateY(100%);
                        }

                        ${ModalBackdrop} {
                            display: block;
                        }

                        ${ModalContent} {
                            display: flex;
                        }
                    }

                    &.modal-animation-enter-active,
                    &.modal-animation-appear-active {
                        ${ModalContent} {
                            transform: translateY(0);
                            transition: transform ${animationDuration}ms ease;
                        }
                    }

                    &.modal-animation-exit {
                        ${ModalContent} {
                            transform: translateY(0);
                        }
                    }

                    &.modal-animation-exit-active {
                        ${ModalContent} {
                            transform: translateY(100%);
                            transition: transform ${animationDuration}ms ease;
                        }
                    }

                    &.modal-animation-exit-done {
                        ${ModalBackdrop}, ${ModalContent} {
                            display: none;
                        }
                    }
                `;
            }
            case ModalAnimationType.ANIMATE_CUSTOM: {
                return $customAnimation;
            }
            default: {
                break;
            }
        }
    }}
}

`;

export interface ModalProps {
    /** Does the modal is open? */
    open: boolean;

    /**
     * Callback function that is being emitted when the modal should be closed. It's a controlled component,
     * so the user should set open to false when this is being triggered
     */
    onClose(): void;
}

export interface ExtendedModalProps
    extends ModalProps,
        ModalContentProps,
        StyledComponentsSupportProps,
        DataAutomationSupportProps {
    /** Should backdrop be shown? */
    hasBackdrop?: boolean;

    /** Should a click on the backdrop close the modal? */
    backdropCloses?: boolean;

    /**
     * Will be used to define the backdrop background
     */
    backdropBackground?: string;

    /** Should a press on escape close the modal? */
    escapeCloses?: boolean;

    /** Element to be initially focused when the modal opens */
    initiallyFocusedElementRef?: React.RefObject<HTMLElement>;

    /**
     * Whether the initial focused element be the first focusable element in the modal. If not, it will be the modal
     * itself. If initiallyFocusedElementRef is set, initializeFocusInsideModal will be ignored.
     */
    initializeFocusInsideModal?: boolean;

    /** Show the modal enter with an animation? */
    animate?: boolean;

    /** The duration of the enter animation. Ignored if `animate={false}`. */
    animationDuration?: number;

    /** the aria role (role attribute) to pass to the modal */
    ariaRole?: 'alertdialog' | 'dialog';

    /**
     * Will be triggered if backdrop is clicked, before onClose. Allows to prevent the modal
     * close by returning false.
     *
     * @param event - the mouse event.
     * @returns false to prevent the close. Otherwise, it will close the modal.
     */
    onBackdropClicked?(event: MouseEvent): boolean | void | null | undefined;

    /**
     * Will be triggered if the escape key is pressed, before onClose. Allows to prevent the modal
     * close by returning false.
     *
     * @param event - the keyboard event.
     * @returns false to prevent the close. Otherwise, it will close the modal.
     */
    onEscapeKey?(event: KeyboardEvent): boolean | void | null | undefined;

    /**
     * Will be triggered when the enter animation is about to start.
     */
    onEnter?(): void;

    /**
     * Will be triggered after the enter animation completes.
     */
    onEntered?(): void;

    /**
     * Will be triggered when the exit animation is about to start
     */
    onExit?(): void;

    /**
     * Will be triggered when the exit animation completes.
     */
    onExited?(): void;

    /**
     * Will be used to define the modal animation type
     */
    modalAnimationType?: ModalAnimationType;

    // Todo use -  FlattenSimpleInterpolation is missing in styled component
    customAnimation?: any;

    /**
     * If true will deactivate the focus trap when clicking outside.
     * This can help with popovers that are written in angular that dont handle the focus correctly with their own focus trap.
     * The downside of activating this is that when returning to this modal, the focus would reset and not retain its original focused element.
     */
    clickOutsideDeactivatesFocusTrap?: boolean;

    dataAutomation?: string;
}

const Modal: React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<ExtendedModalProps>> = (
    {
        className,
        hasBackdrop = true,
        backdropCloses = true,
        escapeCloses = true,
        size = ModalSize.MEDIUM,
        fixedWidth = false,
        fullScreen = false,
        windowedFullScreen = false,
        animate = true,
        scrollable = true,
        animationDuration = 200,
        initiallyFocusedElementRef,
        initializeFocusInsideModal = false,
        ariaRole = 'dialog',
        modalAnimationType = ModalAnimationType.ANIMATE_OPACITY,
        customAnimation = undefined,
        backdropBackground,
        open,
        onClose: onCloseProp,
        onBackdropClicked,
        onEscapeKey,
        children,
        onEnter,
        onEntered,
        onExit,
        onExited,
        height,
        clickOutsideDeactivatesFocusTrap,
        dataAutomation,
    },
    ref,
) => {
    const modalWrapperRef = useRef<HTMLDivElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const { CloseContextProvider, onClose } = useOnBeforeCloseQueueManager(onCloseProp);

    // This is used to keep the hooks that support onlyIfNewest (like the useOnClickOutside) active until the modal
    // fully closes and removed from the DOM, to prevent bugs like that triggers an clickOutside event on the parent
    // modal when pressing on this modal while it's closing.
    const [modalFadingOut, setModalFadingOut] = useState(false);

    const modalIsInDOM = open || modalFadingOut;

    useEscapeCallback((event) => {
        if (!escapeCloses || !open) {
            return;
        }

        if (onEscapeKey?.(event) === false) {
            return;
        }

        onClose();
    }, modalIsInDOM);

    const setOnClickOutsideElementRef = useOnClickOutside((event: MouseEvent) => {
        if (!backdropCloses || !open) {
            return;
        }

        if (onBackdropClicked?.(event) === false) {
            return;
        }

        onClose();
    }, modalIsInDOM);

    const defaultFocusedElement = initializeFocusInsideModal ? undefined : modalContentRef;
    const setFocusTrapContainerElementRef = useFocusTrap(
        modalIsInDOM,
        initiallyFocusedElementRef || defaultFocusedElement,
        undefined,
        undefined,
        clickOutsideDeactivatesFocusTrap,
    );

    const modalContentRefCallback = useMultipleRefCallback<HTMLDivElement>(
        modalContentRef,
        setOnClickOutsideElementRef,
        setFocusTrapContainerElementRef,
        ref,
    );

    // When clicking on any of the parent breadcrumbs
    const modifyParentBreadcrumbs = useCallback(
        (parentBreadcrumbs: BreadcrumbsSettingsObject[]): BreadcrumbsSettingsObject[] => {
            return parentBreadcrumbs.map((breadcrumb) => {
                const onClick = async (
                    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>,
                ): Promise<boolean> => {
                    const closed = await onClose();

                    const originalOnClick = breadcrumb.clickable?.onClick;
                    // We need the if and can't use ?. because it might be false.
                    if (closed && originalOnClick) {
                        originalOnClick(event);
                    }

                    return closed;
                };

                return {
                    ...breadcrumb,
                    clickable: {
                        ...breadcrumb.clickable,
                        onClick,
                    },
                };
            });
        },
        [onClose],
    );

    // If the modal has not focusable content, the focus trap will fallback to the container itself, but if it has
    // not tab index, it will fail and throw an error. Tab index of -1 will make it focusable only using code
    // (`modalRef.focus()`) but not by the keyboard.
    const modalTabIndex = -1;

    return (
        <CloseContextProvider>
            <BreadcrumbsZone settings={modifyParentBreadcrumbs}>
                <CSSTransition
                    classNames="modal-animation"
                    nodeRef={modalWrapperRef}
                    in={open}
                    timeout={animationDuration}
                    onEnter={onEnter}
                    onEntered={onEntered}
                    onExit={() => {
                        setModalFadingOut(true);
                        onExit?.();
                    }}
                    onExited={() => {
                        setModalFadingOut(false);
                        onExited?.();
                    }}
                    appear={animate}
                    show={animate}
                    exit={animate}
                    unmountOnExit
                >
                    <Portal>
                        <ModalWrapperWithAnimations
                            animationDuration={animationDuration}
                            ref={modalWrapperRef}
                            modalAnimationType={modalAnimationType}
                            $customAnimation={customAnimation}
                        >
                            {hasBackdrop && <ModalBackdrop background={backdropBackground} />}
                            <ModalContent
                                data-automation={dataAutomation}
                                className={className}
                                ref={modalContentRefCallback}
                                size={size}
                                fixedWidth={fixedWidth}
                                fullScreen={fullScreen}
                                windowedFullScreen={windowedFullScreen}
                                scrollable={scrollable}
                                role={ariaRole}
                                tabIndex={modalTabIndex}
                                height={height}
                                aria-modal
                            >
                                {children}
                            </ModalContent>
                        </ModalWrapperWithAnimations>
                    </Portal>
                </CSSTransition>
            </BreadcrumbsZone>
        </CloseContextProvider>
    );
};

export default React.forwardRef(Modal);
