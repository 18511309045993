import React from 'react';
import styled, { keyframes } from 'styled-components';

const strokeAnimation = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const scaleAnimation = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const fillAnimation = (color: string) => keyframes`
  0% {
    box-shadow: inset 0px 0px 0px 0px ${color};
  }
  100% {
    box-shadow: inset 0px 0px 0px 60px ${color};
  }
`;

const CheckmarkCircle = styled.circle<{ color?: string; $isBig?: boolean }>`
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${({ color }) => color};
    fill: none;
    animation: ${strokeAnimation} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

const AnimationCheckmark = styled.svg<{ color?: string; $isBig?: boolean }>`
    width: 112px;
    height: 112px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: ${({ color }) => color};
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px 60px ${({ color }) => color};
    animation:
        ${({ color }) => fillAnimation(color || '#7ac142')} 0.4s ease-in-out 0.4s forwards,
        ${scaleAnimation} 0.3s ease-in-out 0.9s both;
`;

const CheckmarkCheck = styled.path<{ color?: string; $isBig?: boolean }>`
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke: white;
    animation: ${strokeAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`;

const AnimationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Props {
    color?: string;
    isBig?: boolean;
}

const Checkmark: React.FC<Props> = ({ color, isBig }) => {
    return (
        <AnimationWrapper>
            <AnimationCheckmark
                data-automation="checkmark-icon"
                className="animation-checkmark"
                viewBox="0 0 52 52"
                $isBig={isBig}
                color={color}
            >
                <CheckmarkCircle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                    color={color}
                    $isBig={isBig}
                />
                <CheckmarkCheck
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    color={color}
                    $isBig={isBig}
                />
            </AnimationCheckmark>
        </AnimationWrapper>
    );
};

export default Checkmark;
