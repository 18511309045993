enum FontSize {
    XXSMALL_8 = '8px',
    XSMALL_10 = '10px',
    SMALL_12 = '12px',
    MSMALL_13 = '13px',
    MEDIUM_14 = '14px',
    LARGE_16 = '16px',
    XLARGE_18 = '18px',
    XXLARGE_20 = '20px',
    XXXLARGE_22 = '22px',
    XXXXLARGE_24 = '24px',
    XXXXXLARGE_28 = '28px',
    XXXXXXLARGE_32 = '32px',
    XXXXXXXLARGE_36 = '36px',
}

export default FontSize;
