enum TimeRange {
    NoRange = 'NoRange',
    LastDay = 'LastDay',
    Last7Days = 'Last7Days',
    Last30Days = 'Last30Days',
    Last2Months = 'Last2Months',
    Last6Months = 'Last6Months',
    LastYear = 'LastYear',
}

export default TimeRange;
