import { useContext } from 'react';

import type { Breakpoint } from '../components/BreakpointManager';
import { BreakpointContext } from '../components/BreakpointManager';

export { Breakpoint } from '../components/BreakpointManager';

/**
 * A hook that returns the effective size of the screen by breakpoints.
 *
 * @returns the effective breakpoint
 */
export default function useBreakpoint(): Breakpoint {
    const breakpoint = useContext(BreakpointContext);

    if (breakpoint === null || breakpoint === undefined) {
        throw new Error('Cannot useBreakpoint if not wrapper in Provider');
    }

    return breakpoint;
}
