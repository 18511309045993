import React, { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { AnimatedSwitchContext } from './AnimatedSwitch';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

const SWITCH_ANIMATION_DURATION_MS = 150;

const getDisplay = ({ flex }: { flex: boolean }) => (flex ? 'flex' : 'block');

const SwitchingWrapper = styled.div<{ shown: boolean; flex: boolean }>`
    display: ${({ flex, shown }) => (shown ? getDisplay({ flex }) : 'none')};

    &.switch-animation-enter {
        opacity: 0;
        display: ${getDisplay};
    }
    &.switch-animation-enter-active {
        opacity: 1;
        transition: opacity ${SWITCH_ANIMATION_DURATION_MS}ms ease-in-out;
    }
    &.switch-animation-exit {
        opacity: 1;
        display: ${getDisplay};
    }
    &.switch-animation-exit-active {
        opacity: 0;
        transition: opacity ${SWITCH_ANIMATION_DURATION_MS}ms ease-in-out;
    }
`;

interface Props extends StyledComponentsSupportProps {
    label: string;
    unmountOnExit?: boolean;
    flex?: boolean;
}

const AnimatedSwitchItem: React.FC<React.PropsWithChildren<Props>> = ({
    label,
    className,
    children,
    unmountOnExit,
    flex = false,
}) => {
    const activeLabel = useContext(AnimatedSwitchContext);

    const [showing, setShowing] = useState(label === activeLabel);
    const wrapperRef = useRef<HTMLDivElement>(null);

    /**
     * Handles the fade in and out animation timing.
     *
     * If this item is active but not showing, set timeout to wait for the previous item to finish fading out, then
     * show this item. If the active item, showing or the label changes, cancel the timeout, and if needed, set it
     * again. If it is showing but is not active anymore, don't show it.
     */
    useEffect(() => {
        const thisItemIsActive = activeLabel === label;

        if (thisItemIsActive && !showing) {
            const timeout = setTimeout(() => {
                setShowing(true);
            }, SWITCH_ANIMATION_DURATION_MS);

            return () => clearTimeout(timeout);
        } else if (!thisItemIsActive && showing) {
            setShowing(false);
        }
    }, [showing, activeLabel, label]);

    return (
        <CSSTransition
            nodeRef={wrapperRef}
            in={showing}
            timeout={SWITCH_ANIMATION_DURATION_MS}
            classNames="switch-animation"
            unmountOnExit={unmountOnExit}
        >
            <SwitchingWrapper className={className} flex={flex} shown={showing} ref={wrapperRef}>
                {children}
            </SwitchingWrapper>
        </CSSTransition>
    );
};

export default AnimatedSwitchItem;
