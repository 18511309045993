import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { DragAndDropImagesModal } from './templates';
import { ReactComponent as TrashIcon } from '../../../../images/trash.svg';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';

const ImagePreviewContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cdd2d8;
    border-radius: 4px;
    width: 100%;
    height: 70px;
    padding: 5px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;
const ImagePreview = styled.img`
    height: 100%;
`;

const ControlsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

const RemoveButton = styled(ClickableLink)`
    display: flex;
    font-size: ${FontSize.SMALL_12};

    svg {
        margin-right: 5px;
        * {
            fill: ${Theme.colors.gray_500};
        }
        rect[stroke] {
            stroke: ${Theme.colors.gray_500};
        }
    }
`;

interface Props {
    disabled?: boolean;
    ariaLabel?: string;
    value?: string;
    onChange: (file: TonkeanUploadedFile | undefined) => void;
}

const DragAndDropSingleImageUpload: React.FC<Props> = ({ disabled, ariaLabel, value, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const onFileSelected = useCallback(
        (files: TonkeanUploadedFile[]) => {
            setModalOpen(false);
            onChange(files?.[0]);
            return Promise.resolve();
        },
        [onChange],
    );

    return (
        <>
            <ImagePreviewContainer>
                {value ? (
                    <ImagePreview
                        data-automation="drag-and-drop-single-image-upload-image-preview"
                        aria-label={ariaLabel}
                        src={value}
                    />
                ) : (
                    'No image uploaded'
                )}
            </ImagePreviewContainer>
            <ControlsRow>
                <ClickableLink onClick={() => setModalOpen(true)} disabled={disabled}>
                    Upload
                </ClickableLink>
                <RemoveButton onClick={() => onChange(undefined)} disabled={disabled} black>
                    <TrashIcon />
                    Remove
                </RemoveButton>
            </ControlsRow>
            <DragAndDropImagesModal
                isUploadImageModalOpen={modalOpen}
                onCancel={() => setModalOpen(false)}
                onSave={onFileSelected}
                maximumFiles={1}
                acceptedFileTypes={['image/jpeg', 'image/png']}
                limitationsDescriptionText="The maximum file size is 5mb and the supported file types are JPG, JPEG and PNG"
            />
        </>
    );
};

export default DragAndDropSingleImageUpload;
