import { FieldDefinitionType } from './FieldDefinitionType';
import type FormulaField from './FormulaField';
import FormulaPopoverTab from './FormulaPopoverTab';

const rootFormulaField: FormulaField = {
    fieldDefinitionType: FieldDefinitionType.SINGLE,
    dataType: undefined,
    displayName: 'Root',
    defaultTab: FormulaPopoverTab.FORMULA,
    metadata: {
        fieldDefinitionType: FieldDefinitionType.SINGLE,
        inOperandIndex: 0,
        firstInOperand: true,
        lastInOperand: true,
    },
};

export default rootFormulaField;
