import styled, { css } from 'styled-components';

import searchImage from '../../images/search.svg?url';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme, FontSize } from '@tonkean/tui-theme';

type Borders = 'all' | 'bottom' | 'none';

const SearchBox = styled.input.attrs(({ placeholder = 'Search' }) => ({ type: 'search', placeholder }))<{
    /**
     * The max width of the input.
     * @default unset (has no max width)
     */
    $maxWidth?: string;
    /**
     * The width of the input.
     * @default 100%
     */
    $width?: string;
    /**
     * The height of the input.
     * @default 28px
     */
    $height?: string;
    /**
     * The padding from the left side of the input to the start of the icon.
     * @default 7px (meaning the text starts after 24px)
     */
    $leftIconPadding?: number;
    /**
     * Which borders should be used?
     * @default "all"
     */
    $borders?: Borders;

    /**
     * How round should the search box borders be
     * @default 0px
     */
    $borderRadius?: string;
}>`
    color: ${Theme.colors.gray_800};
    height: ${({ height = '28px' }) => height};
    padding: 0 24px 0 ${({ $leftIconPadding = 7 }) => 17 + $leftIconPadding}px;
    width: ${({ width = '100%' }) => width};
    max-width: ${({ $maxWidth = 'unset' }) => $maxWidth};
    font-size: ${FontSize.SMALL_12};

    background-color: ${Theme.colors.basicBackground};
    background-image: url(${searchImage});
    background-repeat: no-repeat;
    background-position-x: ${({ $leftIconPadding = 7 }) => $leftIconPadding}px;
    background-position-y: center;

    ${({ $borders = 'all' }) => {
        switch ($borders) {
            case 'all':
                return css`
                    border: 1px solid ${Theme.colors.gray_400};
                    border-radius: 3px;
                `;
            case 'bottom':
                return css`
                    border: none;
                    border-bottom: 1px solid ${Theme.colors.gray_400};
                `;
            case 'none':
                return css`
                    border: none;
                `;
        }
    }}

    border-radius: ${({ $borderRadius = '0px' }) => $borderRadius};

    ${styledFocus};

    ::placeholder {
        color: ${Theme.colors.gray_500};
    }
`;

export default SearchBox;
