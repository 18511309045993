import toPath from 'lodash.topath';

import { memoize } from '@tonkean/utils';

/**
 * This is used to match formik's `getIn` to get the fields by it's string name. Unlike in formik, we
 * "compile" the toPath result in advance and use the result instead of running toPath every time.
 */
function compliedGet(pathString: string) {
    // Convert string path (for example 'person.friends[0]') to array (['person', 'friends', '0'])
    const path = toPath(pathString);

    return (object: any) => {
        return path.reduce((prev, pathPart) => prev?.[pathPart], object);
    };
}

export default memoize(compliedGet);
