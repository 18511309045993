import React from 'react';
import styled from 'styled-components';

import useCloseCallback from './useCloseCallback';
import { ReactComponent as ModalXIcon } from '../../../../images/modal-x.svg';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { classNames } from '@tonkean/utils';

const CloseButton = styled.button`
    ${styledFocus}

    svg {
        height: 20px;
        width: 20px;
    }
`;

const CloseIcon: React.FC<StyledComponentsSupportProps> = ({ className }) => {
    const onClick = useCloseCallback();

    return (
        <CloseButton
            className={classNames('common-close-btn inline-button mod-outline', className)}
            onClick={onClick}
            data-automation="close-icon-modal"
        >
            <span className="tnk-icon">
                <ModalXIcon />
            </span>
        </CloseButton>
    );
};

export default CloseIcon;
