import React from 'react';

import BreakpointManager from './BreakpointManager';
import DialogsManager from './Dialogs/DialogsManager';
import OptimizedImageContext from './OptimizedImage/OptimizedImageContext';
import type { OptimizedImageProps } from './OptimizedImage/OptimizedImageProps';
import ResizeObserverManager from './ResizeObserverManager';
import StyledComponentsThemeManager from './StyledComponentsThemeManager';
import IsSSRContext from '../hooks/useIsSSR/IsSSRContext';

import { RouterLinkContext } from '@tonkean/tui-basic/RouterLink';
import type { TonkeanRouter } from '@tonkean/tui-basic/RouterLink';

interface Props {
    router: TonkeanRouter;
    optimizedImage: React.ComponentType<OptimizedImageProps> | undefined;
}

const ContextWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, router, optimizedImage }) => {
    const isSSR = typeof window === 'undefined';

    return (
        <RouterLinkContext router={router}>
            <OptimizedImageContext.Provider value={optimizedImage}>
                <DialogsManager>
                    <IsSSRContext.Provider value={isSSR}>
                        <StyledComponentsThemeManager>
                            <ResizeObserverManager>
                                <BreakpointManager>{children}</BreakpointManager>
                            </ResizeObserverManager>
                        </StyledComponentsThemeManager>
                    </IsSSRContext.Provider>
                </DialogsManager>
            </OptimizedImageContext.Provider>
        </RouterLinkContext>
    );
};

export default ContextWrapper;
