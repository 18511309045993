import React from 'react';
import styled from 'styled-components';

import { Input } from './Input';
import useCopyToClipboardFromInput from '../../hooks/useCopyToClipboardFromInput';

import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInput = styled(Input)`
    margin-right: 6px;
    color: ${Theme.colors.gray_600};
`;

interface Props extends StyledComponentsSupportProps, React.ComponentProps<typeof Input> {}

const CopyInput: React.FC<Props> = ({ className, readOnly = true, disabled = true, ...props }) => {
    const { copyToClipboard, inputRef } = useCopyToClipboardFromInput();

    return (
        <Wrapper className={className}>
            <StyledInput
                ref={inputRef}
                readOnly={readOnly}
                size={InputSize.MEDIUM_LARGE}
                disabled={disabled}
                {...props}
            />
            <Button onClick={copyToClipboard} shape={ButtonShape.RECTANGULAR} size={ButtonSize.MEDIUM_BIG}>
                Copy
            </Button>
        </Wrapper>
    );
};

export default CopyInput;
