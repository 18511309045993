import { useMedia } from 'use-media';

export enum PointerType {
    FINE,
    COARSE,
    NONE,
}

/**
 * A hook to get the pointer type (fine, coarse) in js.
 */
function usePointerType(): PointerType {
    const fine = useMedia('(pointer: fine)');
    const coarse = useMedia('(pointer: coarse)');

    if (fine) {
        return PointerType.FINE;
    }

    if (coarse) {
        return PointerType.COARSE;
    }

    return PointerType.NONE;
}

export default usePointerType;
