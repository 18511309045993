/**
 * Represents the different sections of integration we have.
 */
enum DataSourcesSectionType {
    CLOUD = 'CLOUD',
    SPREADSHEET = 'SPREADSHEET',
    DATABASE = 'DATABASE',
    COMMUNICATION = 'COMMUNICATION',
    EMAIL_INBOX = 'EMAIL_INBOX',
    CUSTOM = 'CUSTOM',
}

export default DataSourcesSectionType;
