import { Form } from 'formik';
import styled from 'styled-components';

const ModalForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    align-self: stretch;
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
`;

export default ModalForm;
