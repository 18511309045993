import type MarketplaceItemKeyMetricTrendType from './MarketplaceItemKeyMetricTrendType';

import { Theme } from '@tonkean/tui-theme';

const marketplaceItemKeyMetricTrendTypeToColor: Record<MarketplaceItemKeyMetricTrendType, string> = {
    GREEN_UP: Theme.colors.successHover,
    GREEN_DOWN: Theme.colors.successHover,
    RED_UP: Theme.colors.error,
    RED_DOWN: Theme.colors.error,
} as const;

export default marketplaceItemKeyMetricTrendTypeToColor;
