import React, { type PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

type Alignment = 'LEFT' | 'CENTER' | 'RIGHT';

const Container = styled.div<{
    $leftIntersectionRatio: number;
    $rightIntersectionRatio: number;
    $alignment: Alignment;
}>`
    position: relative;

    /**
    * Theres a very strange css bug where if you have overflow-x and justify center the content will leak outside the bounds
    * of container. So instead we align to center with margin auto.
    * (There is justify-content: safe center; that fixes this, but no safari support)
    */
    ${({ $alignment }) => {
        if ($alignment === 'CENTER') {
            return css`
                max-width: 100%;
                width: fit-content;
                margin: 0 auto;
            `;
        }
    }}

    &:before {
        opacity: ${({ $leftIntersectionRatio }) => 1 - $leftIntersectionRatio};
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 300px;
        background: radial-gradient(circle at 100%, transparent 0%, transparent 65%, white 100%);
        pointer-events: none;
        z-index: 1;
    }

    &:after {
        opacity: ${({ $rightIntersectionRatio }) => 1 - $rightIntersectionRatio};
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 300px;
        pointer-events: none;

        background: radial-gradient(circle at 0%, transparent 0%, transparent 65%, white 100%);
        z-index: 1;
    }
`;

const ScrollContainer = styled.div<{ $alignment: Alignment }>`
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;
    ${({ $alignment }) => {
        if ($alignment === 'RIGHT') {
            return css`
                flex-direction: row-reverse;
            `;
        }
    }}
`;

interface Props extends StyledComponentsSupportProps {
    alignment?: Alignment;
}

/**
 * A container component to have horizontal scrolling on the content.
 * Adds white see through edges to indicate more content is available (only if needed).
 * @param justifyCenter - If true will center the content horizontally
 * @param className
 * @param children
 * @constructor
 */
const HorizontalScroller: React.FC<PropsWithChildren<Props>> = ({ alignment = 'LEFT', className, children }) => {
    const scrollerRef = useRef<HTMLDivElement>(null);
    const [firstElementIntersectionRatio, setFirstElementIntersectionRatio] = useState(0);
    const [lastElementIntersectionRatio, setLastElementIntersectionRatio] = useState(0);

    useEffect(() => {
        if (!scrollerRef.current) {
            return;
        }

        // If alignment is to the right, make sure to flip the first and last elements
        const firstElement =
            alignment === 'RIGHT' ? scrollerRef.current.lastElementChild : scrollerRef.current.firstElementChild;
        const lastElement =
            alignment === 'RIGHT' ? scrollerRef.current.firstElementChild : scrollerRef.current.lastElementChild;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.target === firstElement) {
                        setFirstElementIntersectionRatio(entry.intersectionRatio);
                    }
                    // If there is only one element both of these should run, so dont else if
                    if (entry.target === lastElement) {
                        setLastElementIntersectionRatio(entry.intersectionRatio);
                    }
                });
            },
            {
                root: scrollerRef.current,
                threshold: [0, 0.2, 0.5, 0.8, 1],
            },
        );

        if (firstElement) {
            observer.observe(firstElement);
        }
        if (lastElement) {
            observer.observe(lastElement);
        }

        return () => {
            observer.disconnect();
        };
    }, [scrollerRef, scrollerRef?.current?.firstElementChild, scrollerRef?.current?.lastElementChild, alignment]);

    return (
        <Container
            $leftIntersectionRatio={firstElementIntersectionRatio}
            $rightIntersectionRatio={lastElementIntersectionRatio}
            $alignment={alignment}
        >
            <ScrollContainer ref={scrollerRef} className={className} $alignment={alignment}>
                {children}
            </ScrollContainer>
        </Container>
    );
};

export default HorizontalScroller;
