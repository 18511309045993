import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from '../../../hooks';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled(motion.div)<{ presentationStyle?: HamburgerMenuPresentationStyle; backgroundColor?: Color }>`
    padding: 34px 80px 34px 28px;
    position: fixed;
    width: 80%;
    height: ${({ presentationStyle }) =>
        presentationStyle === HamburgerMenuPresentationStyle.HORIZONTAL_CENTERED ? 'auto' : '100vh'};
    left: 0px;
    top: ${({ presentationStyle }) =>
        presentationStyle === HamburgerMenuPresentationStyle.HORIZONTAL_CENTERED ? '50%' : '0'};
    background: ${({ backgroundColor }) => backgroundColor ?? Theme.colors.basicBackground};
    box-shadow: inset 2px -2px 5px rgba(0, 0, 0, 0.25);
    z-index: 1050;
    border-radius: ${({ presentationStyle }) =>
        presentationStyle === HamburgerMenuPresentationStyle.HORIZONTAL_CENTERED ? '0 8px 8px 0' : 'undefined'};
    width: ${({ presentationStyle }) =>
        presentationStyle === HamburgerMenuPresentationStyle.FULL_SCREEN ? '100%' : 'auto'};
`;

const MenuBackdrop = styled(motion.div)`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: inset 2px -2px 5px rgba(0, 0, 0, 0.25);
`;

const horizontallyCenteredWrapperVariants = {
    open: { x: 0, y: '-50%' },
    closed: { x: '-100%', y: '-50%' },
};

const fullSizeWrapperVariants = {
    open: { x: 0, y: '0%' },
    closed: { x: '-100%', y: '0%' },
};

const menuBackdropVariants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

export enum HamburgerMenuPresentationStyle {
    FULL_SCREEN = 'FULL_SCREEN',
    FULL_HEIGHT = 'FULL_HEIGHT',
    HORIZONTAL_CENTERED = 'HORIZONTAL_CENTERED',
}

export interface HamburgerMenuProps {
    menuOpen: boolean;
    showMenuBackdrop?: boolean;
    closeOnClickOutside?: boolean;
    disableBackgroudScrollWhenOpen?: boolean;
    onMenuClose: () => void;
    presentationStyle?: HamburgerMenuPresentationStyle;
    backgroundColor?: Color;
}

const HamburgerMenu: React.FC<React.PropsWithChildren<HamburgerMenuProps>> = ({
    onMenuClose,
    menuOpen,
    showMenuBackdrop = true,
    closeOnClickOutside = true,
    disableBackgroudScrollWhenOpen = false,
    presentationStyle,
    backgroundColor,
    children,
}) => {
    const wrapperRef = useOnClickOutside((event: MouseEvent) => {
        if (closeOnClickOutside) {
            onMenuClose();
        }
    }, menuOpen);

    const menuRef = useRef<HTMLDivElement>(null);
    const backDropRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (menuOpen && disableBackgroudScrollWhenOpen) {
            menuRef?.current?.scrollIntoView();
            document.body.style.overflow = 'hidden';
        } else document.body.style.overflow = 'visible';
    }, [menuOpen, menuRef, disableBackgroudScrollWhenOpen]);

    return (
        <div ref={menuRef}>
            {showMenuBackdrop && (
                <MenuBackdrop
                    ref={backDropRef}
                    animate={menuOpen ? 'open' : 'closed'}
                    variants={menuBackdropVariants}
                    transition={{ duration: 0.25, type: 'Inertia' }}
                    onAnimationStart={() => {
                        if (menuOpen && backDropRef.current) {
                            backDropRef.current.style.visibility = 'visible';
                        }
                    }}
                    onAnimationComplete={() => {
                        if (!menuOpen && backDropRef.current) {
                            backDropRef.current.style.visibility = 'hidden';
                        }
                    }}
                />
            )}
            <Wrapper
                presentationStyle={presentationStyle}
                backgroundColor={backgroundColor}
                ref={wrapperRef}
                animate={menuOpen ? 'open' : 'closed'}
                variants={
                    presentationStyle === HamburgerMenuPresentationStyle.HORIZONTAL_CENTERED
                        ? horizontallyCenteredWrapperVariants
                        : fullSizeWrapperVariants
                }
                transition={{ duration: 0.25, type: 'Inertia' }}
            >
                {children}
            </Wrapper>
        </div>
    );
};

export default HamburgerMenu;
