import createEmptyFormulaNode from './createEmptyFormulaNode';
import type FormulaField from './FormulaField';
import { FormulaTreeTreeNode } from './formulaTreeNodes';
import type { formulaTreeNode } from './formulaTreeNodes';
import type { FormulaOperatorDefinitionBase } from './operators';
import rootFormulaField from './rootFormulaFiled';

/**
 * Creates a new tree node filled with empty fields based on the fields in the definition.
 * Will clone the existing operands and set the new field on them.
 *
 * @param operator - the operator to build the tree from
 * @param field - the field of the function operand
 * @param existingOperands - list of existing operands. They will replace empty nodes.
 * @returns the new tree node
 */
function getOperandTree(
    operator: FormulaOperatorDefinitionBase,
    field: FormulaField = rootFormulaField,
    existingOperands: formulaTreeNode[] = [],
): FormulaTreeTreeNode {
    const fields = operator.getFieldsList(existingOperands.length);

    const operands = fields.map(
        (field, index) => existingOperands[index]?.clone(field) || createEmptyFormulaNode(field),
    );

    return new FormulaTreeTreeNode(operator, operands, field);
}

export default getOperandTree;
