import KnowledgeBase from './knowledgeBase';

export const INVITE_MESSAGES = {
    default: `Hello,\r\nI'm inviting you to collaborate in Tonkean, where we’re automating our processes and building great experiences.\r\nSign in to Tonkean using the link provided in this email and start exploring our solutions.\r\nTo learn more, visit the Tonkean Docs: ${KnowledgeBase.baseUrl}. There, you’ll find information on how to automate processes across various business functions by connecting with your existing systems.`,
};

/**
 * The enrichment replaces the {} jokers in the given strings with parameters from the param array,
 * in the order they are given. So the first {} joker is replaced with the first param and so on.
 * If no joker is found, the parameter is ignored.
 * @param string - the string to enrich.
 * @param params - an array of string parameters to enrich the string with.
 */
function enrichString(string: string, params: string[]) {
    // Make sure we've got everything we need.
    if (string && params?.length) {
        for (const param of params) {
            string = string.replace('{}', param);
        }
    }

    return string;
}

export function getInviteMessages(projectName: string) {
    // Create a copy so we don't run over the original const.
    const inviteMessagesCopy = structuredClone(INVITE_MESSAGES);

    // Go over the messages and enrich them with the project name.
    for (const key in inviteMessagesCopy) {
        if (inviteMessagesCopy.hasOwnProperty(key)) {
            inviteMessagesCopy[key] = enrichString(inviteMessagesCopy[key], [projectName]);
        }
    }

    return inviteMessagesCopy;
}
