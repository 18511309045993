import { useCallback, useRef, useState } from 'react';

/**
 * Generates a unique sequential identifier that can be used for display arrays and provide that identifier as a key.
 */
function useSequentialIdentifier(initialValueProp: number | (() => number) = 0) {
    // This is to allow passing a function in the initial value prop, and running it only once. It will not cause any
    // re-renders because we won't update the state.
    const [initialValue] = useState(initialValueProp);

    const counterRef = useRef(initialValue);

    const incrementValue = useCallback(() => {
        counterRef.current = counterRef.current + 1;

        return counterRef.current;
    }, []);

    return {
        incrementValue,
    };
}

export default useSequentialIdentifier;
