import React from 'react';
import { components } from 'react-select';
import type { DropdownIndicatorProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled from 'styled-components';

import Chevron, { ChevronDirection } from '../TUI/Chevron';

import type { PersonSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledChevron = styled(Chevron)<{ $isDisabled?: boolean }>`
    svg > path {
        stroke: ${({ $isDisabled }) => ($isDisabled ? Theme.colors.gray_300 : Theme.colors.gray_500)};
    }

    padding: 0;
`;

const CustomPeopleSelectorArrow: React.FC<DropdownIndicatorProps<PersonSummary, boolean, GroupBase<PersonSummary>>> = (
    props,
) => {
    return (
        <components.DropdownIndicator<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
            <StyledChevron
                $isDisabled={props.isDisabled}
                direction={props.selectProps.menuIsOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
            />
        </components.DropdownIndicator>
    );
};

export default CustomPeopleSelectorArrow;
