import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';

import useSSRDocument from '../../hooks/useSSRDocument';

interface Props {
    /** The container for the children to be rendered in. Default: `body` */
    container?: HTMLElement | string | null;
    /** If true, the content will not be rendered on the container but in the regular JSX hierarchy */
    disabled?: boolean;
}

/**
 * Used to place a JSX nodes in a different container and not in the regular JSX DOM hierarchy.
 */
const Portal: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    disabled = false,
    container: containerProps,
}) => {
    const defaultContainer = useSSRDocument();

    const containerElement = useMemo(() => {
        if (!containerProps) {
            return defaultContainer;
        }
        if (typeof containerProps === 'string') {
            return document.querySelector(containerProps);
        }
        return containerProps;
    }, [containerProps, defaultContainer]);

    if (disabled) {
        return <>{children}</>;
    }

    if (!containerElement) {
        return <></>;
    }

    return ReactDOM.createPortal(children, containerElement);
};

export default Portal;
