import type { ValueOf } from 'type-fest';

import type LogicBlockConfigType from './LogicBlockConfigType';

export enum AutonomousSecondaryType {
    AUTONOMOUS_CREATED_FROM_FORM = 'AUTONOMOUS_CREATED_FROM_FORM',
    AUTONOMOUS_INTERFACE_SUBMITTED = 'AUTONOMOUS_INTERFACE_SUBMITTED',
    AUTONOMOUS_FIELD_CHANGED = 'AUTONOMOUS_FIELD_CHANGED',
    AUTONOMOUS_SCHEDULE = 'AUTONOMOUS_SCHEDULE',
    AUTONOMOUS_ITEM_CREATED = 'AUTONOMOUS_ITEM_CREATED',
    AUTONOMOUS_MATCH_CONDITIONS = 'AUTONOMOUS_MATCH_CONDITIONS',
    USER_INTERFACE_BUTTON_CLICK = 'USER_INTERFACE_BUTTON_CLICK',
    AUTO_CLOSE = 'AUTO_CLOSE',
}

export enum MonitorTracksSecondaryType {
    MONITOR_TRACKS_MATCH_CONDITIONS = 'MONITOR_TRACKS_MATCH_CONDITIONS',
    MONITOR_TRACKS_ITEM_CREATED = 'MONITOR_TRACKS_ITEM_CREATED',
    AUTONOMOUS_ITEM_CREATED = 'AUTONOMOUS_ITEM_CREATED',
}

export type LogicBlockConfigSecondaryTypes = {
    [LogicBlockConfigType.MONITOR_TRACKS]: MonitorTracksSecondaryType;
    [LogicBlockConfigType.AUTONOMOUS]: AutonomousSecondaryType;
};
export type LogicBlockConfigSecondaryTypesValues = ValueOf<LogicBlockConfigSecondaryTypes>;
