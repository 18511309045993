import type React from 'react';
import { useCallback } from 'react';

/**
 * React hook for updating multiple refs at once. It returns a callback function to pass to the ref prop,
 * and when called, it will update the refs. Useful when using React.forwardRef and needing to access the
 * reference in the component itself as well.
 *
 * @example
 * const SomeComponent = (props, ref) => {
 *     const divRef = React.useRef();
 *     const divRefCallback = useMultipleRefCallback(ref, divRef);
 *
 *     useEffect(() => {
 *         console.log(divRef.current);
 *     }, []);
 *
 *     return <div ref={divRefCallback}>I'm being console logged!</div>
 * };
 *
 * export default React.forwardRef(SomeComponent);
 *
 * @param refs - list or react refs to update.
 * @returns a callback function that can be passed in the ref prop.
 */
function useMultipleRefCallback<T>(
    ...refs: (React.Ref<T> | React.Dispatch<React.SetStateAction<T>> | undefined)[]
): (newRef: T) => void {
    const refCallback = useCallback(
        (newRef: T) => {
            refs.forEach((ref) => {
                if (!ref) {
                    return;
                }

                if (typeof ref === 'function') {
                    ref(newRef);
                    return;
                }

                // A hack to disable the readonly error in current
                (ref.current as unknown) = newRef;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...refs],
    );

    return refCallback;
}

export default useMultipleRefCallback;
