import { css } from 'styled-components';

import { Breakpoint } from '../../hooks/useBreakpoint';

export enum LayoutGridElements {
    ELEMENT_COLUMNS_COUNT = '--element-columns-count',
    CONTAINER_WIDTH = '--container-width',
    WIDTH = '--width',
}

/**
 * This css snippet sets the width of the element according to the layout grid system.
 * To avoid re-renders in React component, this snippet uses pure CSS with CSS variables.
 *
 * To use it, you must declare a CSS variable named `--element-columns-count` using the `ELEMENT_COLUMNS_COUNT` constant.
 * You should adjust `--element-columns-count` in different breakpoints by using media queries.
 *
 * In the example below we configure the element to span 8 columns by default, 4 columns on mobile and 10 on all other screens smaller then 1920px.
 * @example```
const Wrapper = styled.div`
  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
  
  @media screen and (max-width: ${Breakpoint.XSMALL_640}px) {
      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
  }
  
  @media screen and (min-width: ${Breakpoint.XSMALL_640}px) and (max-width: ${Breakpoint.MEDIUM_1920}px) {
      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;
  }
  
  ${widthByLayoutGridColumns};
`
```
 */
const widthByLayoutGridColumns = css`
    // What is the total number of columns the screen should contain in the current breakpoint
    --columns-on-screen: 12;

    // Gutters are the empty space on the sides of the layout. On the screen we have 2 gutters.
    --gutter-width: 20px;

    // Margins are the empty spaces between columns.
    --margin-width: 24px;
    --number-of-margins: calc(var(--columns-on-screen) - 1);

    --effective-container-width: calc(var(${LayoutGridElements.CONTAINER_WIDTH}, 100vw));

    // The width of both gutters.
    --total-gutters-width: calc(2 * var(--gutter-width));

    // The width of all margins between columns.
    --total-margins-width: calc(var(--number-of-margins) * var(--margin-width));

    // The width of all the columns on screen, without gutters and margins.
    --all-columns-width: calc(
        var(--effective-container-width) - var(--total-gutters-width) - var(--total-margins-width)
    );

    // The width of a single columns.
    --single-column-width: calc(var(--all-columns-width) / var(--columns-on-screen));

    ${LayoutGridElements.WIDTH}: calc(
        var(--single-column-width) * var(${LayoutGridElements.ELEMENT_COLUMNS_COUNT}, var(--columns-on-screen)) +
            ((var(${LayoutGridElements.ELEMENT_COLUMNS_COUNT}) - 1) * var(--margin-width))
    );

    // To get the final width, we take the width of a single column, multiply it by the number of columns requested and add the margins.
    width: var(${LayoutGridElements.WIDTH});

    // To set different columns count on screen in different breakpoints we should use media queries.
    @media screen and (max-width: ${Breakpoint.XSMALL_640}px) {
        --columns-on-screen: 4;
    }
`;

export default widthByLayoutGridColumns;
