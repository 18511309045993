import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as MoreActionsVerticalIcon } from '../../images/more-actions-vertical.svg';
import { ReactComponent as MoreActionsIcon } from '../../images/more-actions.svg';

import type { IconButtonProps } from '@tonkean/tui-buttons/Button';
import { IconButton } from '@tonkean/tui-buttons/Button';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const StyledIconButton = styled(IconButton)<{ $thin: boolean }>`
    ${({ $thin }) =>
        $thin &&
        css`
            width: 16px;
        `}
`;

interface Props extends IconButtonProps, DataAutomationSupportProps {
    horizontal?: boolean;
    thin?: boolean;
}

const KebabMenuButton: React.ForwardRefRenderFunction<HTMLElement, Props> = (
    { horizontal = false, thin = false, ...props },
    ref,
) => {
    return (
        <StyledIconButton $thin={thin} {...props} ref={ref}>
            {horizontal ? <MoreActionsIcon /> : <MoreActionsVerticalIcon />}
        </StyledIconButton>
    );
};

export default React.forwardRef(KebabMenuButton);
