import React from 'react';
import { areEqual } from 'react-window';
import styled, { css } from 'styled-components';

import type PickerListItem from './PickerListItem';
import type { PickerListItems } from './PickerListItems';
import { ITEM_HEIGHT, PickerListPart, RowCheckbox, RowText } from './pickerListSharedStyles';
import HighlightableText from '../HighlightableText';
import TextEllipsis from '../TextEllipsis';

import { FontSize, Theme } from '@tonkean/tui-theme';

const Highlighted = styled.span`
    font-weight: bold;
    color: ${Theme.current.palette.mainColors.gray_800};
`;

interface Props<T> {
    data: {
        pickerListItemIds: string[];
        itemsById: Record<string, PickerListItem<T>>;
        onCheckChanged?(changedItems: PickerListItems<T>);
        onSelectChanged?(selectedItem: PickerListItem<T>);
        showCheckboxes: boolean;
        highlightText?: string;
        customPadding?: number;
        onClickSelect: boolean;
        selectedId?: string;
    };
    index: number;
    style: React.CSSProperties;
}

export const PickerListRow = styled(PickerListPart)<{ selected?: boolean }>`
    font-size: ${FontSize.SMALL_12};
    height: ${ITEM_HEIGHT}px;

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${Theme.current.palette.mainColors.brightestGray};
        `};
`;

const PickerRow = <T,>({
    data: {
        pickerListItemIds,
        itemsById,
        onCheckChanged,
        onSelectChanged,
        showCheckboxes,
        highlightText,
        customPadding,
        onClickSelect,
        selectedId,
    },
    index,
    style,
}: Props<T>) => {
    const pickerListItem = itemsById[pickerListItemIds[index]!]!;

    const onItemChecked = (checked: boolean) => {
        onCheckChanged?.({ [pickerListItem.id]: { ...pickerListItem, checked } });
    };

    return (
        <>
            <PickerListRow
                customPadding={customPadding}
                clickable={showCheckboxes || onClickSelect}
                selected={selectedId === pickerListItem.id}
                style={style}
                buttonAsDiv={!onClickSelect && !showCheckboxes}
                buttonAsLabel={!onClickSelect && showCheckboxes}
                onClick={(e) => {
                    if (e.target.hasOwnProperty('checked')) {
                        return;
                    }

                    if (onClickSelect && onSelectChanged) {
                        onSelectChanged(pickerListItem);
                    }
                }}
            >
                {showCheckboxes && (
                    <RowCheckbox
                        checked={pickerListItem.checked || false}
                        onChange={(e) => onItemChecked(e.target.checked)}
                    />
                )}
                <RowText>
                    <TextEllipsis numberOfLines={1} tooltip>
                        <HighlightableText
                            text={pickerListItem.label}
                            highlightText={highlightText}
                            highlightedTextWrapper={<Highlighted />}
                        />
                    </TextEllipsis>
                </RowText>
            </PickerListRow>
        </>
    );
};

export default React.memo(PickerRow, areEqual);
