import TransitionState from './TransitionState';

class AppearTransitionState extends TransitionState {
    public readonly stateName = 'appear';

    protected setStartSubStateImplementation(callbacks) {
        callbacks.onEnter?.(true);
    }
    protected setActiveSubStateImplementation(callbacks) {
        callbacks.onEntering?.(true);
    }
    protected setDoneSubStateImplementation(callbacks) {
        callbacks.onEntered?.(true);
    }
}

const appearTransitionState = new AppearTransitionState();
export default appearTransitionState;
