import React from 'react';
import styled, { css } from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 28px;
    color: ${Theme.colors.gray_800};
`;
const Field = styled.div<{ fullWidth?: boolean }>`
    align-self: center;

    ${({ fullWidth = false }) =>
        fullWidth &&
        css`
            grid-column: 1 / -1;
        `};
`;

interface Props extends DataAutomationSupportProps {
    /** Label for the field. If not supplied, the field will be full width. */
    label?: React.ReactNode;
}

const FilterRow: React.FC<React.PropsWithChildren<Props>> = ({ label, dataAutomation, children }) => {
    return (
        <>
            {label && <Label data-automation={dataAutomation}>{label}</Label>}
            <Field fullWidth={!label}>{children}</Field>
        </>
    );
};

export default FilterRow;
