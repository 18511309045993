import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import type { JsonPickerProps } from './JsonPicker';
import JsonPicker from './JsonPicker';
import { ReactComponent as ArrowDown } from '../../../images/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../images/arrow-up.svg';
import { ReactComponent as ExpandInputIcon } from '../../../images/expand-input.svg';
import XCloseButton from '../TUI/CloseButton/XCloseButton';
import { Modal, ModalBody, ModalHeader } from '../TUI/Modal';

import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const JsonPickerWrapper = styled.div<{ width: string; height: string }>`
    background-color: ${Theme.colors.gray_100};
    border: 1px solid ${Theme.colors.gray_300};
    color: grey;
    border-radius: 3px;
    padding: 12px 0 12px 12px;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    overflow-y: auto;
    word-break: break-all;
`;

const IconsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    top: 8px;
    margin-right: 8px;
    height: 0;
`;

const StyledXCloseButton = styled(XCloseButton)`
    margin-left: auto;
`;

interface Props extends JsonPickerProps {
    width?: string;
    height?: string;
    expendable?: boolean;
}

const JsonPickerBlock: React.FC<Props> = ({
    width = 'auto',
    height = 'auto',
    expendable = true,
    disabled,
    ...props
}) => {
    // Indicated whether the expanded full-screen modal is open
    const [isModalOpen, setIsModalOpen] = useState(false);

    const jsonWrapperRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
        const currentWrapper = ref.current;
        currentWrapper?.scroll(0, currentWrapper?.scrollHeight);
    };

    const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
        const currentWrapper = ref.current;
        currentWrapper?.scroll(0, 0);
    };

    return (
        <>
            <JsonPickerWrapper ref={jsonWrapperRef} height={height} width={width}>
                {expendable && (
                    <IconsWrapper dir="rtl">
                        <IconButton onClick={() => setIsModalOpen(true)} flat>
                            <ExpandInputIcon />
                        </IconButton>

                        <IconButton onClick={() => scrollToBottom(jsonWrapperRef)} flat>
                            <ArrowDown />
                        </IconButton>

                        <IconButton onClick={() => scrollToTop(jsonWrapperRef)} flat>
                            <ArrowUp />
                        </IconButton>
                    </IconsWrapper>
                )}

                <JsonPicker disabled={disabled} {...props} />
            </JsonPickerWrapper>

            <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen} fullScreen>
                <ModalHeader $shadow>
                    <StyledXCloseButton size={ButtonSize.BIG} onClick={() => setIsModalOpen(false)} />
                </ModalHeader>
                <ModalBody>
                    <JsonPicker disabled={disabled} {...props} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default JsonPickerBlock;
