import React from 'react';
import { useMedia } from 'use-media';
import type { MediaQueryObject } from 'use-media/lib/types';

export enum Breakpoint {
    MINIATURE_0 = 0,
    XSMALL_640 = 620,
    MID_XSMALL_768 = 768,
    SMALL_1366 = 1346,
    MEDIUM_1920 = 1900,
    LARGE_2560 = 2540,
}

export const BreakpointContext = React.createContext<Breakpoint | null>(null);

const mediaQueries: Record<Breakpoint, MediaQueryObject> = {
    [Breakpoint.MINIATURE_0]: { minWidth: Breakpoint.MINIATURE_0, maxWidth: Breakpoint.XSMALL_640 },
    [Breakpoint.XSMALL_640]: { minWidth: Breakpoint.XSMALL_640, maxWidth: Breakpoint.MID_XSMALL_768 },
    [Breakpoint.MID_XSMALL_768]: { minWidth: Breakpoint.MID_XSMALL_768, maxWidth: Breakpoint.SMALL_1366 },
    [Breakpoint.SMALL_1366]: { minWidth: Breakpoint.SMALL_1366, maxWidth: Breakpoint.MEDIUM_1920 },
    [Breakpoint.MEDIUM_1920]: { minWidth: Breakpoint.MEDIUM_1920, maxWidth: Breakpoint.LARGE_2560 },
    [Breakpoint.LARGE_2560]: { minWidth: Breakpoint.LARGE_2560 },
};

function BreakpointManager({ children }: React.PropsWithChildren<Record<never, any>>) {
    const breakpointResults: Record<Breakpoint, boolean> = {
        [Breakpoint.MINIATURE_0]: useMedia(mediaQueries[Breakpoint.MINIATURE_0]),
        [Breakpoint.XSMALL_640]: useMedia(mediaQueries[Breakpoint.XSMALL_640]),
        [Breakpoint.MID_XSMALL_768]: useMedia(mediaQueries[Breakpoint.MID_XSMALL_768]),
        [Breakpoint.SMALL_1366]: useMedia(mediaQueries[Breakpoint.SMALL_1366]),
        [Breakpoint.MEDIUM_1920]: useMedia(mediaQueries[Breakpoint.MEDIUM_1920]),
        [Breakpoint.LARGE_2560]: useMedia(mediaQueries[Breakpoint.LARGE_2560]),
    };

    // we wrap with number since the keys of objects are always strings, even if created using numbers ( keys are '0', '1366'... not 0, 1366...)
    const breakpoint = Number(
        Object.entries(breakpointResults).find(([, matches]: [unknown, boolean]) => matches)?.[0] ??
            Breakpoint.MINIATURE_0,
    );

    return <BreakpointContext.Provider value={breakpoint}>{children}</BreakpointContext.Provider>;
}

export default BreakpointManager;
