import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { RadioGroupContextValue } from './RadioGroupContext';
import RadioGroupContext from './RadioGroupContext';
import useUUID from '../../../hooks/useUUID';
import { InputLabel } from '../IconInputWithLabel/IconInputWithLabel';

import type { Color } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export type RadioGroupDirection = 'column' | 'row';

const RadioGroupWrapper = styled.div<{ $size: InputSize; $direction: RadioGroupDirection }>`
    display: inline-flex;
    flex-direction: ${({ $direction }) => $direction};
    align-items: flex-start;
    
    ${InputLabel}:not(:last-child) {
        margin-${({ $direction }) => ($direction === 'row' ? 'right' : 'bottom')}: 10px;
    }
`;

interface Props extends StyledComponentsSupportProps {
    /** Input's name. Must be unique. If not supplied, will generate random name. */
    name?: string;

    /** Currently selected value. */
    value?: any;

    /** Radio's size. */
    size?: InputSize;

    /**
     * Icon color in selected state
     */
    selectedColor?: Color;

    /**
     * Icon color in unselected state
     */
    unselectedColor?: Color;

    /** flex-direction for the radio group. */
    direction?: RadioGroupDirection;

    /** Should the label color change when selected. */
    changeLabelColor?: boolean;

    /** Should the whole Radio Group be disabled. */
    radioGroupDisabled?: boolean;

    /** Should the label be bold? */
    boldLabel?: boolean;

    /** Data automation attribute */
    dataAutomation?: string;

    /**
     * Triggered when the selected radio changes.
     *
     * @param value - the value selected.
     * @param event - the change event. `event.target.value` will return a generated value and not the one supplied
     * because it allows only strings. Use the value param instead.
     */
    onChange?(value: any, event: React.ChangeEvent<HTMLInputElement>): void;
}
export type RadioGroupProps = React.PropsWithChildren<Props>;

const RadioGroup: React.ForwardRefRenderFunction<HTMLDivElement, RadioGroupProps> = (
    {
        name,
        value,
        className,
        size = InputSize.LARGE,
        selectedColor,
        unselectedColor,
        direction = 'column',
        changeLabelColor = true,
        onChange: onChangeProp,
        radioGroupDisabled,
        boldLabel,
        dataAutomation,
        children,
    },
    ref,
) => {
    const uuid = useUUID();

    const [radios, setRadios] = useState<{ value: any; fakeValue: string }[]>([]);

    const addRadio = useCallback((realValue: any, fakeValue: string) => {
        const newRadio = { value: realValue, fakeValue };

        setRadios((currentRadios) => [...currentRadios, newRadio]);

        const removeRadio = () => {
            setRadios((currentRadios) => currentRadios.filter((radio) => radio !== newRadio));
        };
        return removeRadio;
    }, []);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const fakeValue = event.target.value;

            const checkedRadio = radios.find((radio) => radio.fakeValue === fakeValue);
            if (!checkedRadio) {
                return;
            }

            onChangeProp?.(checkedRadio.value, event);
        },
        [radios, onChangeProp],
    );

    const contextValue = useMemo<RadioGroupContextValue>(
        () => ({
            name,
            uuid,
            value,
            size,
            selectedColor,
            unselectedColor,
            changeLabelColor,
            addRadio,
            onChange,
            radioGroupDisabled,
            boldLabel,
        }),
        [
            name,
            uuid,
            value,
            size,
            selectedColor,
            unselectedColor,
            changeLabelColor,
            addRadio,
            onChange,
            radioGroupDisabled,
            boldLabel,
        ],
    );

    return (
        <RadioGroupContext.Provider value={contextValue}>
            <RadioGroupWrapper
                data-automation={dataAutomation}
                className={className}
                $direction={direction}
                $size={size}
                role="radiogroup"
                ref={ref}
            >
                {children}
            </RadioGroupWrapper>
        </RadioGroupContext.Provider>
    );
};

export default React.forwardRef(RadioGroup);
