import React, { useCallback, useContext, useMemo, useState } from 'react';

import type { FieldDefinitionSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

export interface ItemInterfaceFieldDefinitionSummaryContextData {
    summaries: FieldDefinitionSummary[];
    registerSummariesByInterfaceId: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        summaries: FieldDefinitionSummary[],
    ) => void;
}

export const ItemInterfaceFieldDefinitionSummaryContext = React.createContext<
    ItemInterfaceFieldDefinitionSummaryContextData | undefined
>(undefined);

export const useOrBuildItemInterfaceFieldDefinitionSummaryContext = () => {
    const inheritedContext = useContext(ItemInterfaceFieldDefinitionSummaryContext);
    if (inheritedContext) {
        return inheritedContext;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useBuildItemInterfaceFieldDefinitionSummaryContext();
};

export const useBuildItemInterfaceFieldDefinitionSummaryContext = () => {
    const [summariesByInterfaceId, setSummariesByInterfaceId] = useState<
        Record<TonkeanId<TonkeanType.ITEM_INTERFACE>, FieldDefinitionSummary[]>
    >({});

    const summaries = useMemo(() => {
        const allSummaries = Object.values(summariesByInterfaceId).flat();
        const distinctSummaries = Utils.distinctArrayByProperty(allSummaries, 'fieldDefinitionId');
        return distinctSummaries;
    }, [summariesByInterfaceId]);

    const registerSummariesByInterfaceId = useCallback(
        (
            itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
            currentItemInterfaceFieldDefinitionSummary: FieldDefinitionSummary[],
        ) => {
            setSummariesByInterfaceId((prevState) => ({
                ...prevState,
                [itemInterfaceId]: currentItemInterfaceFieldDefinitionSummary,
            }));
        },
        [],
    );

    return useMemo(() => {
        return {
            summaries,
            registerSummariesByInterfaceId,
        };
    }, [registerSummariesByInterfaceId, summaries]);
};
