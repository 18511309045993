import debounce from 'lodash.debounce';
import React, { useCallback, useMemo, useState } from 'react';
import type { SelectComponentsConfig, StylesConfig } from 'react-select';
import type { GroupBase } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { css } from 'styled-components';

import CustomClearIndicator from './CustomClearIndicator';
import CustomInput from './CustomInput';
import CustomMultiValueRemove from './CustomMultiValueRemove';
import CustomNoOptionsMessage from './CustomNoOptionsMessage';
import CustomPeopleSelectorArrow from './CustomPeopleSelectorArrow';
import CustomPlaceholder from './CustomPlaceholder';
import { CustomSingleValue, CustomMultiValueLabel } from './CustomValue';
import PersonOptionView from './PersonOptionView';
import useProject from '../../hooks/useProject';
import CustomMenuList from '../TUI/Select/CustomMenuList';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { PersonSummary } from '@tonkean/tonkean-entities';
import type { GetTeamMemberOptions } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export interface PeopleSelectorProps {
    onlyCreatorsInGroupId?: string;
    onlyOwnersInGroupId?: string;
    isMulti: boolean;
    includeExternal?: boolean;
    isDisabled?: boolean;
    selectedPeople?: PersonSummary[];
    onValueChange: (data: PersonSummary[]) => void;
    debounceTime?: number;
    bold?: boolean;
    isInPopover?: boolean;
    styles?: StylesConfig<PersonSummary, false>;
}

const AsyncSelectWrapper = styled.div<{
    $isInPopover: boolean;
}>`
    ${({ $isInPopover }) =>
        $isInPopover &&
        css`
            position: fixed;
            top: 7px;
            left: 14px;
            width: 90%;
        `}
`;

const getCustomStyles = (styles?: StylesConfig<PersonSummary, false>) => ({
    control: (provided, { isDisabled }) => ({
        ...provided,
        backgroundColor: isDisabled ? 'white' : 'white',
        ...styles?.control,
    }),
    multiValue: (provided, { isDisabled }) => ({
        ...provided,
        backgroundColor: `${Theme.colors.gray_200}`,
        borderRadius: '20px',
        padding: isDisabled ? '0px 5px 0px 0px' : '0px',
        ...styles?.multiValue,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        ':hover': {
            backgroundColor: 'transparent',
        },
        ...styles?.multiValueRemove,
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: '0px',
        paddingLeft: '0px',
        ...styles?.multiValueLabel,
    }),
    input: (provided) => ({
        ...provided,
        margin: '0px',
        input: {
            width: 'inherit !important', // This is in order to present the placeholder on the input
        },
        ...styles?.input,
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        ...styles?.menu,
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
        ...styles?.menuPortal,
    }),
    valueContainer: styles?.valueContainer,
    select: {
        fontSize: '30px',
    },
});

const getOptionValue = (option: PersonSummary) => option.id;

const PeopleSelector = ({
    onlyCreatorsInGroupId,
    onlyOwnersInGroupId,
    onValueChange,
    selectedPeople,
    isDisabled = false,
    includeExternal = false,
    isMulti,
    debounceTime = 300,
    bold = false,
    isInPopover = false,
    styles,
}: PeopleSelectorProps): React.ReactElement => {
    const projectManager = useProject();
    const [, getTeamMembersSummary] = useLazyTonkeanService('getTeamMembersSummary');
    const [inputValue, setInputValue] = useState('');

    const myRef = React.useRef(null);

    const loadOptions = useCallback(
        (searchTerm: string, callback) => {
            const options: GetTeamMemberOptions = {
                excludePersonIds: [],
                onlyCreatorsInGroupId,
                onlyOwnersInGroupId,
                includeExternal,
            };
            getTeamMembersSummary(projectManager.id, options, searchTerm, 20).then((data) => {
                callback(data.people);
            });
        },
        [getTeamMembersSummary, includeExternal, onlyCreatorsInGroupId, onlyOwnersInGroupId, projectManager.id],
    );

    const debouncedLoadOptions = useMemo(() => {
        if (debounceTime > 0) {
            return debounce(loadOptions, debounceTime);
        }
        return loadOptions;
    }, [debounceTime, loadOptions]);

    const closeMenu = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e && e.key === 'Escape' && myRef) {
            (myRef as any).current.blur();
            e.stopPropagation();
            e.preventDefault();
        }
    }, []);

    const customStyles = useMemo(() => getCustomStyles(styles), [styles]);

    const handleOnChange = useCallback(
        (value: PersonSummary[]) => {
            if (isMulti) {
                onValueChange(value);
            } else if (value) {
                onValueChange([value as unknown as PersonSummary]);
            } else {
                onValueChange([]);
            }
        },
        [isMulti, onValueChange],
    );

    const value = isMulti ? selectedPeople : selectedPeople?.[0];

    const components = {
        Option: PersonOptionView,
        SingleValue: CustomSingleValue,
        MultiValueLabel: CustomMultiValueLabel,
        MultiValueRemove: CustomMultiValueRemove,
        NoOptionsMessage: CustomNoOptionsMessage,
        Placeholder: CustomPlaceholder,
        DropdownIndicator: CustomPeopleSelectorArrow,
        ClearIndicator: CustomClearIndicator,
        IndicatorSeparator: null,
        Input: CustomInput,
        MenuList: CustomMenuList,
    } as SelectComponentsConfig<PersonSummary, boolean, GroupBase<PersonSummary>>;

    return (
        <AsyncSelectWrapper $isInPopover={isInPopover}>
            <AsyncSelect
                ref={myRef}
                components={components}
                loadOptions={debouncedLoadOptions}
                inputValue={inputValue}
                value={value}
                onInputChange={(val) => setInputValue(val)}
                getOptionValue={getOptionValue}
                onChange={handleOnChange}
                onKeyDown={closeMenu}
                styles={customStyles}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                isDisabled={isDisabled}
                backspaceRemovesValue={false}
                menuShouldBlockScroll={false}
                menuPosition={!isInPopover ? 'fixed' : undefined}
                data-automation="people-selector"
                placeholder=""
                menuPortalTarget={document.body}
                // Since the react select not give the option to add type with props we just pass it through hence we used ignore
                // @ts-ignore
                bold={bold}
                openMenuOnClick
                defaultOptions
                isClearable
            />
        </AsyncSelectWrapper>
    );
};

export default PeopleSelector;
