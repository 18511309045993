export enum WorkerRunLogicStatus {
    MONITORING = 'MONITORING',
    INNER_TRIGGER_ACTIVATED = 'INNER_TRIGGER_ACTIVATED',
    WASNT_ACTIVATED = 'WASNT_ACTIVATED',
    WORKER_OFF = 'WORKER_OFF',
    OFF = 'OFF',
    SKIPPED = 'SKIPPED',
    CONDITION_NOT_MET = 'CONDITION_NOT_MET',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    PARTIAL = 'PARTIAL',
}
