import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from '../../../images/chevron.svg';

import { Chevron2Icon } from '@tonkean/svg';

export enum ChevronDirection {
    UP = 'UP',
    DOWN = 'DOWN',
    RIGHT = 'RIGHT',
    LEFT = 'LEFT',
}

const chevronDirectionToDegrees: Record<ChevronDirection, number> = {
    [ChevronDirection.DOWN]: 180,
    [ChevronDirection.RIGHT]: 90,
    [ChevronDirection.UP]: 0,
    [ChevronDirection.LEFT]: 270,
};

const Chevron = styled.div.attrs({
    children: (
        <span className="tnk-icon">
            <ChevronIcon />
        </span>
    ),
})<{
    direction: ChevronDirection;
}>`
    transition: all 0.3s ease-in-out;
    transform: rotate(${({ direction }) => chevronDirectionToDegrees[direction]}deg);

    display: inline-block;
    line-height: 0;

    .tnk-icon {
        display: inline-block;
        line-height: 0;
    }
`;

export const Chevron2 = styled.div.attrs({
    children: <Chevron2Icon />,
})<{
    direction: ChevronDirection;
}>`
    transition: all 0.3s ease-in-out;
    transform: rotate(${({ direction }) => chevronDirectionToDegrees[direction] - 90}deg);

    display: inline-block;
    line-height: 0;
`;

export default Chevron;
