import styled, { css } from 'styled-components';

import { Theme, type Color, FontSize } from '@tonkean/tui-theme';

export interface TypographyProps {
    $inline?: boolean;
    $color?: Color;
    $light?: boolean;
    $underline?: boolean;
    $extraBold?: boolean;
    $bold?: boolean;
    $thin?: boolean; // opposite of bold
}

const sharedTypography = css<TypographyProps>`
    display: ${({ $inline = false }) => ($inline ? 'inline' : 'block')};
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: ${({ $extraBold, $thin, $bold }) => {
        if ($extraBold) {
            return 700;
        }
        if ($bold) {
            return 500;
        } else if ($thin) {
            return 300;
        }
        return 'normal'; // 400
    }};

    ${({ $light = false, $color = $light ? Theme.colors.gray_600 : Theme.colors.gray_800 }) =>
        $color &&
        css`
            color: ${$color};
        `}

    ${({ $underline = false }) =>
        $underline &&
        css`
            text-decoration: underline;
        `};
`;

export const H1 = styled.h1<TypographyProps>`
    ${sharedTypography};

    font-family: 'Lato', sans-serif;
    font-size: ${FontSize.XXXXLARGE_24};
    line-height: 29px;
`;

export const H2 = styled.h2<TypographyProps>`
    ${sharedTypography};

    font-size: ${FontSize.XXLARGE_20};
    line-height: 23px;
`;

export const H3 = styled.h3<TypographyProps>`
    ${sharedTypography};

    font-size: ${FontSize.LARGE_16};
    line-height: 19px;
`;

export const H4 = styled.h4<TypographyProps>`
    ${sharedTypography};
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
`;

export const Paragraph = styled.div<TypographyProps & { $small?: boolean }>`
    ${sharedTypography};

    font-size: ${({ $small = false }) => ($small ? FontSize.XSMALL_10 : FontSize.SMALL_12)};
    line-height: ${({ $small = false }) => ($small ? 12 : 14)}px;
`;
