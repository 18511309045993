import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';

import type { AnalyticsContext } from './analyticsContext';

import type { DataDogConfig } from '@tonkean/env';

interface DataDogTrackUserProps {
    id: string;
    name: string;
    email: string;
    projectIdentifier: string;
    projectName: string;
    enterpriseIdentifier: string;
    enterpriseName: string;
}

const datadogWrapper = {
    init: (envName: string, config: DataDogConfig) => {
        const defaultPrivacyLevel =
            config.defaultPrivacyLevel in DefaultPrivacyLevel
                ? (config.defaultPrivacyLevel as DefaultPrivacyLevel)
                : DefaultPrivacyLevel.MASK;

        datadogRum.init({
            applicationId: config.applicationId,
            clientToken: config.clientToken,
            site: config.site ?? 'datadoghq.com',
            service: config.service ?? 'tonkean',
            env: envName,
            sessionSampleRate: config.sampleRate ?? 100,
            sessionReplaySampleRate: config.sessionReplaySampleRate ?? 20,
            trackUserInteractions: config.trackInteractions ?? true,
            trackResources: config.trackResources ?? true,
            trackLongTasks: config.trackLongTasks ?? true,
            defaultPrivacyLevel,
        });
    },

    trackUser: ({
        id,
        name,
        email,
        projectIdentifier,
        projectName,
        enterpriseIdentifier,
        enterpriseName,
    }: DataDogTrackUserProps) => {
        datadogRum.setUser({
            id,
            name,
            email,
            projectIdentifier,
            projectName,
            enterpriseIdentifier,
            enterpriseName,
        });
    },

    timeEvent: (name: string) => {
        datadogRum.addTiming(name);
    },

    setGlobalContext: (updatedContext: Partial<AnalyticsContext>) => {
        const currGlobalContext = datadogRum.getGlobalContext();

        const combinedContext = {
            ...currGlobalContext,
            ...updatedContext,
            isFormOrInterfaceContext:
                (updatedContext.isInFormContext ?? currGlobalContext.isInFormContext ?? false) ||
                (updatedContext.isInterfaceContext ?? currGlobalContext.isInterfaceContext ?? false),
            isFormOrInterfaceDraftMode:
                (updatedContext.formContextDraftMode ?? currGlobalContext.formContextDraftMode) ||
                (updatedContext.interfaceContextDraftMode ?? currGlobalContext.interfaceContextDraftMode),
        };

        for (const [key, value] of Object.entries(combinedContext)) {
            datadogRum.setGlobalContextProperty(key, value);
        }
    },
};

export default datadogWrapper;
