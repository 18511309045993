/**
 * Additional names is used to convert additional field types into their api names.
 * For example, for letting you know that "percent" data type is actually "Number" data type in the server.
 */
export const FIELD_TYPES = {
    number: {
        apiName: 'Number',
        displayName: 'Number',
        additionalNames: {
            number: true,
            int: true,
            double: true,
            currency: true,
            percent: true,
        },
    },
    date: {
        apiName: 'Date',
        displayName: 'Date',
        additionalNames: {
            date: true,
            datetime: true,
        },
    },
    text: {
        apiName: 'String',
        displayName: 'Text',
        additionalNames: {
            string: true,
        },
    },
    longString: {
        apiName: 'LongString',
        displayName: 'Long Text',
        additionalNames: {
            LongString: true,
            longstring: true,
        },
    },
    string: {
        apiName: 'String',
        displayName: 'Text',
        additionalNames: {
            string: true,
        },
    },
    list: {
        apiName: 'List',
        displayName: 'Dropdown',
        additionalNames: {
            list: true,
            picklist: true,
            dropdown: true,
            enum: true,
            enumeration: true,
        },
    },
    boolean: {
        apiName: 'Boolean',
        displayName: 'Boolean',
        additionalNames: {
            boolean: true,
        },
    },
    url: {
        apiName: 'URL',
        displayName: 'URL',
        additionalNames: {
            url: true,
        },
    },
};

/**
 * Returns a map between additional field type name to the field type api name.
 */
export function getAdditionalNameToFieldTypeApiNameMap() {
    const additionalNameToFieldTypeApiNameMap = {};

    for (const fieldType in FIELD_TYPES) {
        if (FIELD_TYPES.hasOwnProperty(fieldType)) {
            for (const additionalName in FIELD_TYPES[fieldType].additionalNames) {
                if (FIELD_TYPES[fieldType].additionalNames.hasOwnProperty(additionalName)) {
                    additionalNameToFieldTypeApiNameMap[additionalName] = FIELD_TYPES[fieldType].apiName;
                }
            }
        }
    }

    return additionalNameToFieldTypeApiNameMap;
}
