/**
 * Defines modal animation type
 */
enum ModalAnimationType {
    ANIMATE_BOTTOM_TO_TOP = 'ANIMATE_BOTTOM_TO_TOP',
    ANIMATE_OPACITY = 'ANIMATE_OPACITY',
    ANIMATE_OPACITY_ONLY = 'ANIMATE_OPACITY_ONLY',
    /**
     * If selected there will be no default animation. It is up to the modal parent component to define an animation
     */
    ANIMATE_CUSTOM = 'ANIMATE_CUSTOM',
}

export default ModalAnimationType;
