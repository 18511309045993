import { STATES_COLORS } from './stateColors';

export const STATUSES = {
    NOT_STARTED: {
        id: 'NOT_STARTED',
        orderIndex: 3,
        label: 'Not Started',
        cssclass: 'grey',
        color: STATES_COLORS.grey,
    },
    IN_PROGRESS: {
        id: 'IN_PROGRESS',
        orderIndex: 2,
        label: 'In Progress',
        cssclass: 'primary',
        color: STATES_COLORS.green,
    },
    WARNING: {
        id: 'WARNING',
        orderIndex: 0,
        label: 'At Risk',
        cssclass: 'warning',
        color: STATES_COLORS.orange,
    },
    BLOCKED: {
        id: 'BLOCKED',
        orderIndex: 0,
        label: 'Blocked',
        cssclass: 'danger',
        color: STATES_COLORS.red,
    },
    DONE: {
        id: 'DONE',
        orderIndex: 1,
        label: 'Done',
        cssclass: 'success',
        color: STATES_COLORS.darkBlue,
    },
    ACTIVE: {
        id: 'ACTIVE',
        orderIndex: 1,
        label: 'Active',
        cssclass: 'primary',
        color: STATES_COLORS.green,
    },
};
