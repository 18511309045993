import escapeStringRegexp from 'escape-string-regexp';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { toArray } from '@tonkean/utils';

const Wrapper = styled.div<{ $inline: boolean }>`
    white-space: pre-wrap;

    ${({ $inline }) =>
        $inline &&
        css`
            display: inline;
        `}
`;

const Highlighted = styled.span`
    background: ${Theme.colors.marker};
`;

export interface HighlightableTextProps extends StyledComponentsSupportProps, React.HTMLAttributes<HTMLDivElement> {
    /** The text to highlight */
    highlightText?: string[] | string;
    /** The full text */
    text?: string;
    /** The component that will wrap the highlighted words. */
    highlightedTextWrapper?: React.ReactElement;
    /** Should the wrapping `div` have `display: inline;`? */
    inline?: boolean;
    caseSensitive?: boolean;
    /** Search for full words only*/
    fullWordsOnly?: boolean;
}

/**
 * Highlights text according to a string.
 *
 * @example of a different highlighted component:
 * const Highlighted = styled.span`
 *     background: red;
 *     border: 1px solid blue;
 *     border-radius: 10px;
 *     color: white;
 * `;
 *
 * const Component = () => {
 *     return (
 *         <HighlightableText
 *             text="Hello World!"
 *             highlightText="world"
 *             highlightedTextWrapper={<Highlighted />}
 *         />
 *     );
 * }
 */
const HighlightableText: React.ForwardRefRenderFunction<HTMLDivElement, HighlightableTextProps> = (
    {
        text = '',
        highlightText = '',
        highlightedTextWrapper = <Highlighted />,
        className,
        caseSensitive = false,
        inline = false,
        fullWordsOnly = false,
        ...props
    },
    ref,
) => {
    const compiledText = useMemo(() => {
        const highlightTextArray = toArray(highlightText).filter((singleHighlightText) => singleHighlightText !== '');
        if (!highlightText || !highlightTextArray.length) {
            return [{ textPart: text, highlighted: false, index: 0 }];
        }

        const textSplittingRegexFlags = caseSensitive ? '' : 'i';
        const escapedTexts = highlightTextArray.map((singleHighlightText) => escapeStringRegexp(singleHighlightText));

        const pattern = fullWordsOnly ? `\\b(${escapedTexts.join('|')})\\b` : `(${escapedTexts.join('|')})`;

        const textSplittingRegex = new RegExp(pattern, textSplittingRegexFlags);

        const lowerCasedHighlightedText = new Set(
            highlightTextArray.map((singleHighlightText) => singleHighlightText.toLowerCase()),
        );
        return text.split(textSplittingRegex).map((textPart, index) => {
            const highlighted = caseSensitive
                ? highlightTextArray.includes(textPart)
                : lowerCasedHighlightedText.has(textPart.toLowerCase());

            return { highlighted, textPart, index };
        });
    }, [highlightText, caseSensitive, fullWordsOnly, text]);

    return (
        <Wrapper $inline={inline} className={className} {...props} ref={ref}>
            {compiledText.map(({ highlighted, textPart, index }) =>
                highlighted ? React.cloneElement(highlightedTextWrapper, { key: index }, textPart) : textPart,
            )}
        </Wrapper>
    );
};

export default React.forwardRef(HighlightableText);
