import React from 'react';
import type { ValueContainerProps } from 'react-select';
import { components } from 'react-select';

import TnkIconSelection from './TnkIconSelection';

const CustomValueContainerSelectionWithIcon: React.FC<React.PropsWithChildren<ValueContainerProps>> = ({
    children,
    ...props
}) => {
    return (
        <components.ValueContainer {...props}>
            {props.selectProps.isMulti ? (
                children
            ) : (
                <TnkIconSelection icon={(props.getValue()?.[0] as { icon?: React.ReactElement } | undefined)?.icon}>
                    {children}
                </TnkIconSelection>
            )}
        </components.ValueContainer>
    );
};

export default CustomValueContainerSelectionWithIcon;
