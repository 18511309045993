import useBreakpoint, { Breakpoint } from './useBreakpoint';

import type { InputSize } from '@tonkean/tui-theme/sizes';

function useResponsiveInputSize(size: InputSize, mobileSize: InputSize) {
    const effectiveBreakpoint = useBreakpoint();

    // if we are in a small screen mode, we modify the size of the input
    return typeof window !== 'undefined' && effectiveBreakpoint === Breakpoint.MINIATURE_0 ? mobileSize : size;
}
export default useResponsiveInputSize;
