/**
 * Defines accordion height
 */
enum AccordionSize {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
}

export default AccordionSize;
