import styled from 'styled-components';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export const ColorCircle = styled.div<{ $selectedColor?: Color }>`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: ${({ $selectedColor = 'transparent' }) => $selectedColor};
    border: 1px solid ${Theme.colors.gray_400};
    margin-right: 6px;
`;
