/*
 * this is a wrapper on usage of mixpanel, posthog, or any other analytics tool we may want in the future.
 */

import type { AnalyticsContext } from './analyticsContext';
import datadogWrapper from './datadogWrapper';
import mixpanelWrapper from './mixpanelWrapper';
import posthogWrapper from './posthogWrapper';

const analyticsWrapper = {
    track: (eventName: string, properties?: { [index: string]: any }, callback?: () => void) => {
        mixpanelWrapper.track(eventName, properties, callback);
        posthogWrapper.track(eventName, properties);
    },

    timeEvent: (name: string) => {
        datadogWrapper.timeEvent(name);
    },

    setGlobalContext: (context: Partial<AnalyticsContext>) => {
        datadogWrapper.setGlobalContext(context);
    },
};

export default analyticsWrapper;
