/**
 * Capitalize the given string.
 *
 * @param text - the text to capitalize.
 * @returns the text with the first letter as uppercase.
 */
function capitalize(text: string | undefined): string {
    if (!text) {
        return '';
    }

    const [firstLetter, ...otherLetters] = text;
    return [firstLetter!.toUpperCase(), ...otherLetters].join('');
}

export default capitalize;
