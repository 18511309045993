import { useCallback, useEffect, useRef, useState } from 'react';

import usePrevious from './usePrevious';

import useStillMounted from '@tonkean/tui-hooks/useStillMounted';

/**
 * A custom hook to allow infinite scroll in long lists.
 * The hook returns a ref, that should be attached to the element that should trigger
 * the loading of the next page when scrolled into view.
 *
 * @example - loading the next page when the last element in the list is scrolled into view
 * const List = function() {
 *   const [[getItems], { data, hasMorePages, loadNextPage }] = useFetchManager(...);
 *   const infiniteScrollRef = useInfiniteScroll(loadNextPage, hasMorePages);
 *
 *   return data.map((item, index, array) => {
 *     <Item item={item} ref={index === array.length - 1 ? infiniteScrollRef : undefined} />
 *   });
 * }
 *
 * Important note - the rendered component must accept a ref,
 * so it should be defined using `React.forwardRef`.
 *
 * @param loadNextPage a function to load the next page of data. If left undefined, the intersection callback will not be triggered.
 * @param active whether to trigger the callback on intersection.
 */
function useInfiniteScroll<TElement extends HTMLElement>(loadNextPage: () => void | undefined, active: boolean = true) {
    const observer = useRef<IntersectionObserver | null>(null);

    const stillMounted = useStillMounted();

    const [observedNode, setObservedNode] = useState<TElement | null>(null);
    const prevNode = usePrevious(observedNode, true);

    const loadMore = useCallback<IntersectionObserverCallback>(
        (entities) => {
            if (entities.length === 0) {
                return;
            }

            /**
             * The IntersectionObserver allows monitoring multiple nodes, but in this hook we only use it to monitor a single node,
             * so we can guarantee that if the length of `entities` is not 0, it must contain 1 element.
             */
            if (entities[0]!.isIntersecting && active) {
                loadNextPage();
            }
        },
        [active, loadNextPage],
    );

    const loaderRef = useCallback(
        (node: TElement) => {
            /**
             * We must keep a reference to the last non-null node because when using callback refs that are not bound to a class,
             * React calls the ref twice - once with null to 'clean' to ref, and then with the real node - causing issues.
             * We only want to create a new IntersectionObserver when the node actually changes, hence the check.
             *
             * @see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
             */
            if (node === prevNode || !node) {
                return;
            }

            observer.current?.disconnect();

            observer.current = new IntersectionObserver(loadMore);
            observer.current.observe(node);

            setObservedNode(node);
        },
        [loadMore, prevNode],
    );

    /**
     * If the `active` state changes from `true` to `false` we must disconnect existing observers to avoid memory leak.
     */
    useEffect(() => {
        if (!active) {
            observer.current?.disconnect();
        }
    }, [active]);

    /**
     * We are not using `useEffect` to create the IntersectionObserver, so we must take care of disconnecting it ourselves when we unmount.
     */
    useEffect(() => {
        if (!stillMounted) {
            observer.current?.disconnect();
        }
    }, [stillMounted]);

    return loaderRef;
}

export default useInfiniteScroll;
