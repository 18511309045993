import React from 'react';
import styled from 'styled-components';

import ChartDisplayType from './ChartDisplayType';
import { ReactComponent as EmptyBarGraphIcon } from '../../../images/bar-chart-empty-icon.svg';
import { ReactComponent as EmptyLineGraphIcon } from '../../../images/line-graph-empty-icon.svg';

import { Theme, FontSize } from '@tonkean/tui-theme';

const NotEnoughDataEmptyState = styled.div`
    height: 100%;
    width: 100%;
    background: ${Theme.colors.white};
`;

const EmptyStateContainer = styled.div`
    position: relative;
    width: 100%;
    top: 20%;
    margin: 0 auto;
    text-align: center;
`;

const EmptyStateTitle = styled.div`
    margin-top: 10px;
    font-size: ${FontSize.XXLARGE_20};
    color: ${Theme.colors.gray_800};
`;

const EmptyStateSubTitle = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_800};
`;

interface Props {
    chartType: ChartDisplayType;
}
const ChartEmptyState: React.FC<Props> = ({ chartType }) => {
    return (
        <NotEnoughDataEmptyState>
            <EmptyStateContainer>
                {chartType === ChartDisplayType.BAR ? <EmptyBarGraphIcon /> : <EmptyLineGraphIcon />}
                <EmptyStateTitle>No data found</EmptyStateTitle>
                <EmptyStateSubTitle>
                    There is no data to display given the selected item and selected field.
                </EmptyStateSubTitle>
                <EmptyStateSubTitle>Try viewing a different item or a different field to see data.</EmptyStateSubTitle>
            </EmptyStateContainer>
        </NotEnoughDataEmptyState>
    );
};

export default ChartEmptyState;
