enum BackgroundProcessType {
    /** @deprecated changed to manual invoke initiatives **/
    INVOKE_INITIATIVES = 'INVOKE_INITIATIVES',
    SCHEDULED_TRIGGER = 'SCHEDULED_TRIGGER',
    INVOKE_EXTERNAL_ACTIVITIES = 'INVOKE_EXTERNAL_ACTIVITIES',
    INVOKE_INITIATIVES_BY_CONDITION = 'INVOKE_INITIATIVES_BY_CONDITION',
    MANUAL_INVOKE_INITIATIVES = 'MANUAL_INVOKE_INITIATIVES',
    ARCHIVE_INITIATIVES_AND_TURN_ON_MODULE_VERSION = 'ARCHIVE_INITIATIVES_AND_TURN_ON_MODULE_VERSION',
}

export const BackgroundProcessTypeToDisplayName: Record<BackgroundProcessType, string> = {
    /** @deprecated changed to manual invoke initiatives **/
    INVOKE_INITIATIVES: 'Manual',
    SCHEDULED_TRIGGER: 'Scheduled Trigger',
    INVOKE_EXTERNAL_ACTIVITIES: 'Data Source Import',
    INVOKE_INITIATIVES_BY_CONDITION: 'Items by Conditions',
    MANUAL_INVOKE_INITIATIVES: 'Manual',
    ARCHIVE_INITIATIVES_AND_TURN_ON_MODULE_VERSION: 'Archive initiatives and turn on module version',
};

export default BackgroundProcessType;
