export enum ActivitiesGroupEntityStatus {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    DELETED = 'DELETED',
    REVERTED = 'REVERTED',
}

export enum ActivitiesEntityType {
    CUSTOM_TRIGGER = 'CUSTOM_TRIGGER',
    FIELD_DEFINITION = 'FIELD_DEFINITION',
    FORM = 'FORM',
    STATE = 'STATE',
    GRAPH = 'GRAPH',
    INPUT_SOURCE = 'INPUT_SOURCE',
    LIVE_REPORT = 'LIVE_REPORT',
    AUTO_CHECKINS = 'AUTO_CHECKINS',
    DELETED_CUSTOM_TRIGGER_GROUP = 'DELETED_CUSTOM_TRIGGER_GROUP',
    DELETED_FIELD_DEFINITION_GROUP = 'DELETED_FIELD_DEFINITION_GROUP',
    DELETED_FORM_GROUP = 'DELETED_FORM_GROUP',
    TEMPLATE = 'TEMPLATE',
    INPUT_SOURCE_GROUP = 'INPUT_SOURCE_GROUP',
    REVERT = 'REVERT',
    ITEM_INTERFACE = 'ITEM_INTERFACE',
    DELETED_ITEM_INTERFACE_GROUP = 'DELETED_ITEM_INTERFACE_GROUP',
    ITEM_DETAILS = 'ITEM_DETAILS',
    PROCESS_MAPPER = 'PROCESS_MAPPER',
    DELETED_PROCESS_MAPPER_GROUP = 'DELETED_PROCESS_MAPPER_GROUP',
}

interface ActivitiesGroup {
    id: string;
    /**
     * The entity type related to the group in activities db.
     */
    entityType: string;
    count: number;
    entity?: any;
    status: ActivitiesGroupEntityStatus;
    /**
     * the activity types in case the activitiesGroup entity type is a parent of more then one group.
     * in purpose of knowing the sub splitting of activities that are not related to a specific entity.
     * for instance: auto check-ins on/off
     */
    activityTypesForGroupEntity?: string[];

    /**
     * The entity type logically related to the group.
     */
    activitiesEntityType: ActivitiesEntityType;
}

export default ActivitiesGroup;
