import React from 'react';

import type { ExtendedModalProps, ModalProps } from '../components/TUI/Modal/Modal';
import Modal from '../components/TUI/Modal/Modal';

type ExternalModalProps = keyof ModalProps;

function withModal<T>(
    Component: React.ComponentType<T>,
    modalProps: Omit<ExtendedModalProps, ExternalModalProps> = {},
): React.FC<T & Pick<ExtendedModalProps, ExternalModalProps>> {
    const WithModal: React.FC<T & Pick<ExtendedModalProps, ExternalModalProps>> = (props) => {
        return (
            <Modal open={props.open} onClose={props.onClose} {...modalProps}>
                <Component {...props} />
            </Modal>
        );
    };

    return React.memo(WithModal);
}

export default withModal;
