import React, { useMemo } from 'react';
import styled from 'styled-components';

import ButtonToggleGroupContext from './ButtonToggleGroupContext';
import { useInnerField } from '../Field';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

const GroupWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface Props extends StyledComponentsSupportProps {
    /**
     * If this component is used under formik use this prop to set the formik field name
     */
    name?: string;
    /**
     * In case this component is NOT used under formik you can use this prop to provide it its selected values
     */
    values?: any[];
    onChange?: (values: any[]) => void;
}

const ButtonToggleGroup: React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<Props>> = (
    { name, className, onChange, children, values: valuesProp },
    ref,
) => {
    const [props, , formikHelpers] = useInnerField({
        name,
        type: 'select',
        multiple: true,
        value: valuesProp,
    });
    const values = props.value;
    const contextValue = useMemo(() => {
        const valuesState = Object.fromEntries(values.map((value) => [value, { value, checked: true }]));
        return {
            valuesState,
            // Set the new values state with a toggled state for provided value key
            toggleValue: (value: any) => {
                if (!valuesState[value]) {
                    valuesState[value] = { value, checked: false };
                }
                valuesState[value].checked = !valuesState[value].checked;
                const toggledValues = Object.entries(valuesState)
                    .filter(([_, state]) => state?.checked)
                    .map(([key, state]) => state.value);
                onChange?.(toggledValues);

                formikHelpers?.setValue(toggledValues);
            },
        };
    }, [formikHelpers, onChange, values]);

    return (
        <ButtonToggleGroupContext.Provider value={contextValue}>
            <GroupWrapper ref={ref} className={className}>
                {children}
            </GroupWrapper>
        </ButtonToggleGroupContext.Provider>
    );
};

export default React.forwardRef(ButtonToggleGroup);
