import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import DragAndDropFilesSizeType from './DragAndDropFilesSizeType';
import DragAndDropValidations from './DragAndDropValidations';
import { ReactComponent as LinkImg } from '../../../../images/link-10px.svg';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const UploadFileBtn = styled(Button)`
    border: 1px solid ${Theme.current.palette.general.border};
    padding: 0 !important;
    width: 60px;
    min-width: 60px;
    border-radius: 0 3px 3px 0;
    border-left: none;
    height: 27px;
    line-height: 27px;

    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
`;

const LinkBtnIcon = styled(LinkImg)`
    margin-right: 5px;
`;

const ImportFileFromPathViewContainer = styled.div<{ sizeType: DragAndDropFilesSizeType }>`
    width: 475px;
    width: ${({ sizeType }) => (sizeType === DragAndDropFilesSizeType.FLUID ? '100%' : '475px')};
`;
const InputTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
`;

const UploadFileInput = styled.input<{ error?: boolean }>`
    border-radius: 3px 0 0 3px;
    border-right: none;
    height: 27px;

    :disabled {
        background-color: ${Theme.colors.brightestGray};
    }

    ${({ error }) =>
        error &&
        css`
            border: 1px solid ${Theme.colors.error};
        `};
`;

const Wrapper = styled.div`
    display: inline-flex;
    width: inherit;
`;

const ErrorContainer = styled.div`
    padding-top: 5px;
    height: 20px;
`;

interface Props {
    onAdding(dragAndDropFile: TonkeanUploadedFile): void;

    existingFiles: TonkeanUploadedFile[];
    acceptedFileTypes?: string | string[];
    isDisabled: boolean;
    sizeType?: DragAndDropFilesSizeType;
}

const ImportFileFromPathView: React.FC<Props> = ({
    onAdding,
    acceptedFileTypes,
    isDisabled,
    sizeType = DragAndDropFilesSizeType.FIXED,
}) => {
    const [url, setURL] = useState<string>('');
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const upload = () => {
        setLoading(true);
        fetch(url)
            .then(async (fetchedData) => {
                const type = fetchedData.headers.get('Content-Type');

                if (DragAndDropValidations.isAllowedType(acceptedFileTypes, type)) {
                    const blob = await fetchedData.blob();
                    onAdding({
                        blob,
                        name: url,
                        id: DragAndDropValidations.getNextSequentialIdentifier(),
                        url: undefined,
                    });

                    setError(undefined);
                } else {
                    setError(`File with type: ${type} not allowed here`);
                }
            })
            .catch(() => {
                setError('Could not fetch file from the given URL.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const allowedUrlPrefix = ['http://', 'https://', 'ftp://'];
    const isAllowedPrefix = allowedUrlPrefix.some((prefix) => url.startsWith(prefix));
    const isUploadBtnDisabled = isDisabled || loading || !isAllowedPrefix;
    const isInputError = (!isAllowedPrefix && url.length > 5) || !!error;

    return (
        <ImportFileFromPathViewContainer sizeType={sizeType}>
            <InputTitle>
                <LinkBtnIcon />
                You can also add link from the web
            </InputTitle>
            <Wrapper>
                <UploadFileInput
                    error={isInputError}
                    disabled={isDisabled}
                    type="text"
                    value={url}
                    className="form-control"
                    onChange={({ target }) => setURL(target.value)}
                    autoComplete="off"
                    placeholder="Copy URL"
                />
                <UploadFileBtn shape={ButtonShape.RECTANGULAR} disabled={isUploadBtnDisabled} onClick={upload}>
                    Upload
                </UploadFileBtn>
            </Wrapper>
            <ErrorContainer>{error && <div className="common-error flex-grow">{error}</div>}</ErrorContainer>
        </ImportFileFromPathViewContainer>
    );
};

export default ImportFileFromPathView;
