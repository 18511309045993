enum ItemInterfaceWidgetType {
    ACTIONS = 'ACTIONS',
    FIELDS = 'FIELDS',
    COMMENTS = 'COMMENTS',
    TIMELINE = 'TIMELINE',
    RICH_TEXT = 'RICH_TEXT',
    INNER_ITEMS = 'INNER_ITEMS',
    LINE_ITEMS = 'LINE_ITEMS',
    DETAILED_ITEMS = 'DETAILED_ITEMS',
    STATUS_PROGRESS_BAR = 'STATUS_PROGRESS_BAR',
    FIELD_CHART = 'FIELD_CHART',
    IMAGE = 'IMAGE',
    EMBED = 'EMBED',
    AI_CHAT = 'AI_CHAT',
    QUESTION = 'QUESTION',
    UPLOAD = 'UPLOAD',
    PROCESS_MAPPER = 'PROCESS_MAPPER',
    SEARCH = 'SEARCH',
    SEPARATOR = 'SEPARATOR',
    INCLUDE_INTERFACE = 'INCLUDE_INTERFACE',
    RICH_TEXT_EDITOR_INPUT = 'RICH_TEXT_EDITOR_INPUT',
    MODULE_ITEMS = 'MODULE_ITEMS',
}

export default ItemInterfaceWidgetType;
