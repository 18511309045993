/**
 * This type is for the different configurations of the blocks.
 *
 * This is different from {@type CustomTriggerType} because custom triggers have a secondaryType which requires
 * different configs even though they are under the same {@type CustomTriggerType} umbrella type.
 * So some {@type LogicBlockConfigType} can be under the same {@type CustomTriggerType}.
 */

enum LogicBlockConfigType {
    UNKNOWN = 'UNKNOWN',
    PERSON_INQUIRY = 'PERSON_INQUIRY',
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
    APPROVAL_CYCLE = 'APPROVAL_CYCLE',
    NEXT_STEPS = 'NEXT_STEPS',
    MANUAL_NEXT_STEPS = 'MANUAL_NEXT_STEPS',
    SYNC_INNER_MATCHED_ENTITY = 'SYNC_INNER_MATCHED_ENTITY',
    MANUAL_FIELD_UPDATE = 'MANUAL_FIELD_UPDATE',
    TRAIN_TRIGGER = 'TRAIN_TRIGGER',
    OCR_CONVERSION = 'OCR_CONVERSION',
    TEXT_EXTRACTOR = 'TEXT_EXTRACTOR',
    MANUAL_OWNER_UPDATE = 'MANUAL_OWNER_UPDATE',
    PERFORM_INTEGRATION_ACTION = 'PERFORM_INTEGRATION_ACTION',
    SEND_EMAIL = 'SEND_EMAIL',
    DELAY = 'DELAY',
    OUTGOING_WEBHOOK = 'OUTGOING_WEBHOOK',
    HTTP_UPLOAD = 'HTTP_UPLOAD',
    STORAGE_UPLOAD = 'STORAGE_UPLOAD',
    ASK_FIELD_UPDATE = 'ASK_FIELD_UPDATE',
    ASK_FIELD_UPDATE_ANSWERED = 'ASK_FIELD_UPDATE_ANSWERED',
    SEND_FORM = 'SEND_FORM',
    SEND_FORM_ANSWERED = 'SEND_FORM_ANSWERED',
    SEND_ITEM_INTERFACE = 'SEND_ITEM_INTERFACE',
    AUTONOMOUS = 'AUTONOMOUS',
    AUTONOMOUS_ITEM_CREATED = 'AUTONOMOUS_ITEM_CREATED',
    AUTONOMOUS_CREATED_FROM_FORM = 'AUTONOMOUS_CREATED_FROM_FORM',
    AUTONOMOUS_MATCH_CONDITIONS = 'AUTONOMOUS_MATCH_CONDITIONS',
    AUTONOMOUS_SCHEDULE = 'AUTONOMOUS_SCHEDULE',
    AUTONOMOUS_FIELD_CHANGED = 'AUTONOMOUS_FIELD_CHANGED',
    AUTONOMOUS_INTERFACE_SUBMITTED = 'AUTONOMOUS_INTERFACE_SUBMITTED',
    INNER_AUTONOMOUS = 'INNER_AUTONOMOUS',
    INNER_AUTONOMOUS_ITEM_CREATED = 'INNER_AUTONOMOUS_ITEM_CREATED',
    INNER_AUTONOMOUS_CREATED_FROM_FORM = 'INNER_AUTONOMOUS_CREATED_FROM_FORM',
    INNER_AUTONOMOUS_SCHEDULE = 'INNER_AUTONOMOUS_SCHEDULE',
    INNER_AUTONOMOUS_FIELD_CHANGED = 'INNER_AUTONOMOUS_FIELD_CHANGED',
    AUTO_CLOSE = 'AUTO_CLOSE',
    USER_INTERFACE_BUTTON_CLICK = 'USER_INTERFACE_BUTTON_CLICK',
    BOT_BUTTON_PRESSED = 'BOT_BUTTON_PRESSED',
    BOT_THREAD_REPLY = 'BOT_THREAD_REPLY',
    GATHER_UPDATE = 'GATHER_UPDATE',
    MONITOR_TRACKS = 'MONITOR_TRACKS',
    MONITOR_TRACKS_ITEM_CREATED = 'MONITOR_TRACKS_ITEM_CREATED',
    MONITOR_TRACKS_AUTONOMOUS_ITEM_CREATED = 'MONITOR_TRACKS_AUTONOMOUS_ITEM_CREATED',
    TONKEAN_ACTION = 'TONKEAN_ACTION',
    NLP_PROCESSOR = 'NLP_PROCESSOR',
    NLP_BRANCH = 'NLP_BRANCH',
    NLP_BRANCH_DEFAULT = 'NLP_BRANCH_DEFAULT',
    NLP_BRANCH_ANY_OF_THE_REST = 'NLP_BRANCH_ANY_OF_THE_REST',
    FRONT_DOOR_ACTION = 'FRONT_DOOR_ACTION',
}

export default LogicBlockConfigType;
