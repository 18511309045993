import React, { useMemo } from 'react';

import type TonkeanRouter from './TonkeanRouter';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

export const RouterContext = React.createContext<TonkeanRouter | undefined>(undefined);

interface Props {
    router: TonkeanRouter;
    children: React.ReactNode;
}

const RouterLinkContext: React.FC<Props> = ({ router, children }) => {
    const go = useConstantRefCallback(router.go);
    const getHref = useConstantRefCallback(router.getHref);
    const prefetch = useConstantRefCallback(router.prefetch);
    const contextValue = useMemo(() => ({ go, getHref, prefetch }), [go, getHref, prefetch]);

    return <RouterContext.Provider value={contextValue}>{children}</RouterContext.Provider>;
};

export default RouterLinkContext;
