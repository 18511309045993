import type PopperJS from '@popperjs/core';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import KeyboardArrowFocusSwitch from '../KeyboardArrowFocusSwitch';
import { Popover } from '../Popover';
import type { PopperChildrenRefProps } from '../Popper';

import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export const MenuContext = React.createContext<
    | {
          onClick(): void;
          thin: boolean;
      }
    | undefined
>(undefined);

const MenuPopover = styled(Popover).attrs({
    showArrow: false,
    color: TooltipColor.WHITE,
    size: TooltipSize.MEDIUM,
})`
    width: 100%;
    padding: 4px 0;
`;

const ItemsWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: column;
`;

interface Props extends StyledComponentsSupportProps {
    show: boolean;
    menuItems: React.ReactNode;
    onClose(): void;
    placement?: PopperJS.Placement;
    thin?: boolean;
    closeOnOutsideRightClick?: boolean;
    popoverSameWidthAsOpeningElement?: boolean;

    /**
     * An element ref to restore focus after menu is closed.
     */
    restoreFocusElementRef?: React.RefObject<HTMLElement>;
    initiallyFocusOnWrapper?: boolean;
    offset?: number;
}

const Menu: React.FC<React.PropsWithChildren<Props & PopperChildrenRefProps>> = ({
    children,
    nodeRef,
    className,
    menuItems,
    show,
    onClose,
    popoverSameWidthAsOpeningElement,
    closeOnOutsideRightClick = false,
    placement = 'bottom',
    thin = false,
    restoreFocusElementRef,
    initiallyFocusOnWrapper = false,
    offset,
}) => {
    const onClick = useCallback(() => {
        onClose();
    }, [onClose]);

    const contextValue = useMemo(
        () => ({
            onClick,
            thin,
        }),
        [onClick, thin],
    );

    return (
        <MenuPopover
            placement={placement}
            show={show}
            onClose={onClose}
            nodeRef={nodeRef}
            initiallyFocusOnWrapper={initiallyFocusOnWrapper}
            closeOnOutsideRightClick={closeOnOutsideRightClick}
            content={
                <MenuContext.Provider value={contextValue}>
                    <KeyboardArrowFocusSwitch>
                        <ItemsWrapper data-automation="menu-popover" className={className}>
                            {menuItems}
                        </ItemsWrapper>
                    </KeyboardArrowFocusSwitch>
                </MenuContext.Provider>
            }
            restoreFocusElementRef={restoreFocusElementRef}
            popoverSameWidthAsOpeningElement={!!popoverSameWidthAsOpeningElement}
            offset={offset}
        >
            {children}
        </MenuPopover>
    );
};

export default Menu;
