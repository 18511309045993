import type { Transition, Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import SidePane, { SIDE_PANE_ANIMATION_DURATION_SECONDS } from './SidePane';
import type SidePaneProps from './SidePaneProps';
import { MODAL_Z_INDEX } from '../Modal';

const ChildrenWrapper = styled(motion.div)`
    position: relative;
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;
`;

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: ${MODAL_Z_INDEX - 1};
`;

const persistentSidePaneTransition: Transition = {
    ease: 'easeInOut',
    duration: SIDE_PANE_ANIMATION_DURATION_SECONDS,
};

interface PersistentSidePaneProps {
    /**
     * Provide an alternative sidepane component. Useful if you need to restyle the sidepane
     */
    sidePane?: React.FC<React.PropsWithChildren<SidePaneProps>>;
    /**
     * The content to be shown inside the sidepane
     */
    sidePaneContent: React.ReactChild;
    /**
     * Switch between persistent and non persistent
     */
    disablePersist?: boolean;
    className?: string;
}

type Props = Omit<SidePaneProps, 'inline'> & PersistentSidePaneProps;

/**
 * A persistent side pane will shift the content (children) when the sidepane opens, instead of overlaying the sidpane over it
 */
const PersistentSidePane: React.FC<React.PropsWithChildren<Props>> = ({
    sidePane = SidePane,
    sidePaneContent,
    disablePersist,
    className,
    children,
    ...sidePaneProps
}) => {
    const { width, peek, open, side = 'right' } = sidePaneProps;
    const variantMarginLabel: keyof CSSProperties = side === 'right' ? 'paddingRight' : 'paddingLeft';
    const variants: Variants = {
        open: {
            [variantMarginLabel]: width,
        },
        closed: {
            [variantMarginLabel]: peek || 0,
        },
    };
    const animateState = open && !disablePersist ? 'open' : 'closed';
    const SidePaneComponent = sidePane;
    return (
        <ChildrenWrapper
            transition={persistentSidePaneTransition}
            initial={false}
            variants={variants}
            animate={animateState}
            className={className}
        >
            <SidePaneComponent inline {...sidePaneProps}>
                {sidePaneContent}
            </SidePaneComponent>
            {open && sidePaneProps.backdrop && <Backdrop />}
            {children}
        </ChildrenWrapper>
    );
};

export default PersistentSidePane;
