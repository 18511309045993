enum OperationNodeType {
    MARKETING = 'MARKETING',
    SALES = 'SALES',
    LEGAL = 'LEGAL',
    FINANCE = 'FINANCE',
    PROCUREMENT = 'PROCUREMENT',
    HR = 'HR',
    IT = 'IT',
    DEV = 'DEV',
    CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
    OTHER = 'OTHER',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

export default OperationNodeType;
