import React from 'react';
import styled from 'styled-components';

import ModalFooter from './ModalFooter';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { type ErrorResponseType, getStateError, type StyledComponentsSupportProps } from '@tonkean/utils';

const Error = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
`;

export interface ModalFooterStateProps extends StyledComponentsSupportProps {
    error?: ErrorResponseType;
    loading?: boolean;
    shadow?: boolean;
    border?: boolean;
}

const ModalFooterState: React.FC<React.PropsWithChildren<ModalFooterStateProps>> = ({
    error,
    loading,
    shadow,
    border,
    children,
    className,
}) => {
    return (
        <ModalFooter className={className} align="right" gap={8} shadow={shadow} border={border}>
            {error && <Error data-automation="modal-footer-state-error-message">{getStateError(error)}</Error>}
            <div className="flex-grow" />
            {loading && <div className="loading" data-automation="modal-footer-state-loading" />}
            {children}
        </ModalFooter>
    );
};

export default ModalFooterState;
