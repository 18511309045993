import React from 'react';
import { components } from 'react-select';
import type { GroupBase } from 'react-select';
import type { OptionProps } from 'react-select';
import styled from 'styled-components';

import TextEllipsis from '../TextEllipsis';
import { H4 } from '../TUI';

import type { PersonSummary } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const GuestIndication = styled.div`
    color: ${Theme.colors.guest_text};
    background-color: ${Theme.colors.guest_background};
    border: 1px solid ${Theme.colors.guest_border};
    border-radius: 3px;
    padding: 3px 5px 3px 5px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.06em;
    flex: 0 0 auto;
`;

const PersonTitle = styled.div`
    font-family: Roboto;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    color: ${Theme.colors.gray_600};
    margin-top: 4px;
`;

const PersonAvatar = styled.img<{ $isSelected: boolean }>`
    width: 32px;
    height: 32px;
    border-radius: 200px;
    position: relative;
    border: ${({ $isSelected }) => ($isSelected ? '2px solid rgba(47, 132, 220, 1)' : '')};
`;

const OptionContainer = styled.div`
    gap: 12px;
    display: flex;
    align-items: center;
`;

const PersonDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const PersonTitleContainer = styled.div`
    display: flex;
    height: 18px;
    align-items: center;
    gap: 4px;
`;

const wrapGetStyles = (getStyles) => {
    return (propertyName: string, props) => {
        const originalStyle = getStyles(propertyName, props);
        const focusColor = '#EEF4FF';
        const notSelectedColor = '#FFFFFFFF';
        if (props.isFocused) {
            originalStyle['backgroundColor'] = focusColor;
        } else {
            originalStyle['backgroundColor'] = props.isSelected ? focusColor : notSelectedColor;
        }
        return originalStyle;
    };
};

const PersonOptionView: React.FC<OptionProps<PersonSummary>> = (props) => {
    const { data, isSelected, getStyles } = props;
    const person = data;
    return (
        <components.Option<PersonSummary, boolean, GroupBase<PersonSummary>>
            {...props}
            getStyles={wrapGetStyles(getStyles)}
        >
            <OptionContainer data-automation="person-option-view-container">
                <PersonAvatar src={person.avatarUri} $isSelected={isSelected} />

                <PersonDataContainer>
                    <PersonTitleContainer>
                        <H4 $color={Theme.colors.gray_800} $bold>
                            <TextEllipsis numberOfLines={1} tooltip>
                                {person.name}
                            </TextEllipsis>
                        </H4>
                        {person?.isExternal && (
                            <GuestIndication data-automation="person-option-view-guest-indication">
                                Guest
                            </GuestIndication>
                        )}
                    </PersonTitleContainer>

                    <TextEllipsis numberOfLines={1} tooltip={false}>
                        <PersonTitle>{person.title}</PersonTitle>
                    </TextEllipsis>
                </PersonDataContainer>
            </OptionContainer>
        </components.Option>
    );
};

export default PersonOptionView;
