import React, { useMemo } from 'react';
import type { ActionMeta, GroupBase } from 'react-select';

import type { SimpleSelectProps, SimpleSelectSingleOption } from './SimpleSelectTypes';
import TnkSelect from './TnkSelect';
import useInnerField from '../Field/useInnerField';

type SimpleDropdownSelectProps<
    Option extends SimpleSelectSingleOption<any>,
    IsMulti extends boolean,
    IsCreatable extends boolean,
    Group extends GroupBase<Option>,
> = Omit<SimpleSelectProps<Option, IsMulti, IsCreatable, Group>, 'onChange'> & {
    onChange: (newOptions: Option[] | Option | undefined, actionMeta?: ActionMeta<Option>) => void;
};

const SimpleDropdownSelect = <
    Option extends SimpleSelectSingleOption<any> = SimpleSelectSingleOption<string>,
    Group extends GroupBase<Option> = GroupBase<Option>,
    IsMulti extends boolean = false,
    IsCreatable extends boolean = false,
>(
    props: SimpleDropdownSelectProps<Option, IsMulti, IsCreatable, Group>,
): React.ReactElement => {
    type ValueType = Option['value'];

    const [fieldProps, hasError, formikHelpers] = useInnerField({
        name: props.name,
        multiple: props.isMulti ?? false,
        type: 'select',
        value: props.value,
        onBlur: props['onBlur'],
        id: props['inputId'],
    });

    const valueToOption = useMemo(() => {
        const entries = props.options
            ?.flatMap((option) => ('options' in option ? option.options : option))
            .map((option) => [option.value, option] as const);
        return new Map(entries);
    }, [props.options]);

    const value = useMemo(() => {
        const createCustomValue = (customValue: ValueType | undefined) => {
            if (customValue && (props.isCreatable || props.showValueWhenNotInOptions)) {
                return { value: customValue, label: customValue, __isNew__: true };
            }
        };

        if (props.isMulti) {
            const valuesArray = fieldProps.value as ValueType[] | undefined;
            return valuesArray
                ?.map((singleValue) => valueToOption.get(singleValue) ?? createCustomValue(singleValue))
                .filter(Boolean);
        }

        // If we dont have a value, and we dont create a custom value, this means we want to clear the value.
        // React-select only clears the value if it is exactly null (not undefined or '')
        const clearValue = null;

        return valueToOption.get(fieldProps.value) ?? createCustomValue(fieldProps.value) ?? clearValue;
    }, [props.isMulti, props.isCreatable, props.showValueWhenNotInOptions, valueToOption, fieldProps.value]);

    return (
        <TnkSelect
            isMulti={props.isMulti as any}
            isCreatable={props.isCreatable as any}
            {...(props as any)}
            isAsync={false}
            value={value}
            onChange={props.onChange as any}
            inputId={fieldProps.id}
            onBlur={fieldProps.onBlur}
            isError={props.isError || hasError}
        />
    );
};

export default SimpleDropdownSelect;
