enum OptionsTypeTitles {
    UPDATE_FORMS = 'Update forms',
    SYSTEM_OPTIONS = 'System options',
    FORMS = 'Forms',
    EVENT_TRIGGERS = 'Event triggers',
    MATCHED_ITEM = 'Matched item',
    INTERFACES = 'Interfaces',
    INTAKE_SEQUENCES = 'Intake Sequences',
    CONTRACT_FIELDS = 'Contract interfaces',
}

export default OptionsTypeTitles;
