import React, { useMemo, useRef } from 'react';
import { components } from 'react-select';
import type { OptionProps } from 'react-select';
import type { SingleValueProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled from 'styled-components';

import DataNotUpToDateIndicator from './DataNotUpToDateIndicator';
import { ReactComponent as BuildIcon } from '../../../images/build-icon.svg';
import { ReactComponent as ProductionIcon } from '../../../images/production-icon.svg';
import ActiveIndicator from '../ActiveIndicator';
import Spacer from '../Spacer';
import CustomOptionSelectionWithIcon from '../TUI/Select/CustomOptionSelectionWithIcon';
import SimpleSelect from '../TUI/Select/SimpleSelect';
import type { SimpleSelectProps, SimpleSelectSingleOption } from '../TUI/Select/SimpleSelectTypes';
import { Tooltip } from '../TUI/Tooltip';

import type { Environment } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { EMPTY_OBJECT } from '@tonkean/utils';

interface EnvironmentOptionDisabled extends SimpleSelectSingleOption<Environment> {
    isDisabled?: boolean | string;
}

const environmentOptions: SimpleSelectSingleOption<Environment>[] = [
    { label: 'Build', value: 'build', icon: <BuildIcon /> },
    { label: 'Production', value: 'production', icon: <ProductionIcon /> },
];
export const environments = environmentOptions.map((option) => option.value);

const IndicatorContainer = styled.div`
    display: flex;
    align-items: center;
`;

interface EnvironmentSelectContainerProps {
    hasIndicator: boolean;
}

const EnvironmentSelectContainer = styled(SimpleSelect)<EnvironmentSelectContainerProps>`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    width: 150px;
    margin-right: ${({ hasIndicator }) => (hasIndicator ? 15 : 0)}px;
    text-transform: uppercase;
    flex-grow: 1;
    box-sizing: border-box;
    padding-top: 1px;
    gap: 40px;
    height: 30px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;

    &:hover {
        border: 1px solid ${Theme.colors.gray_400};
        text-decoration: none;
        background-color: ${Theme.colors.HEX_F9F9F7};
    }

    & > div {
        border: none;
        background: none;
    }
` as React.ComponentType<
    SimpleSelectProps<EnvironmentOptionDisabled, false, false, GroupBase<EnvironmentOptionDisabled>> &
        EnvironmentSelectContainerProps
>;

const EnvironmentOption: React.FC<{
    data: EnvironmentOptionDisabled;
}> = ({ data }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <Tooltip
            content={
                typeof data.isDisabled === 'string'
                    ? data.isDisabled
                    : `${data.label?.toString()} mode is only available to Module makers`
            }
            disabled={!data.isDisabled}
            nodeRef={ref}
        >
            <div ref={ref}>{data.label}</div>
        </Tooltip>
    );
};

const Option: React.FC<OptionProps<EnvironmentOptionDisabled, false, GroupBase<EnvironmentOptionDisabled>>> = (
    props,
) => {
    return (
        <CustomOptionSelectionWithIcon {...props}>
            <EnvironmentOption data={props.data} />
        </CustomOptionSelectionWithIcon>
    );
};

const SingleValue: React.FC<
    SingleValueProps<EnvironmentOptionDisabled, false, GroupBase<EnvironmentOptionDisabled>>
> = (props) => {
    return (
        <components.SingleValue {...props}>
            <EnvironmentOption data={props.data} />
        </components.SingleValue>
    );
};

interface Props
    extends StyledComponentsSupportProps,
        Omit<
            SimpleSelectProps<EnvironmentOptionDisabled, false, false, GroupBase<EnvironmentOptionDisabled>>,
            'options' | 'isMulti' | 'isCreatable'
        > {
    thin?: boolean;
    environmentIsActive?: boolean;
    name?: string;
    currentEnvironment?: Environment;

    onChanges?(newEnvironment: Environment): void;

    offModuleIndication?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    placeholder?: string;
    showActiveStatusIndicator?: boolean;

    /**
     * This maps between an environment to weather it disabled (if the value is boolean), or to disabled with tooltip
     * if the value is string.
     */
    disabledEnvironments?: Record<Environment, string | boolean>;
}

const EnvironmentIndicator: React.FC<Props> = ({
    thin,
    currentEnvironment,
    onChanges,
    environmentIsActive,
    disabledEnvironments = EMPTY_OBJECT,
    name,
    disabled = false,
    readonly = false,
    placeholder = 'Pick Environment',
    className,
    offModuleIndication,
    showActiveStatusIndicator,
    ...selectProps
}) => {
    const options = useMemo(() => {
        return environmentOptions.map((environmentOption) => ({
            ...environmentOption,
            isDisabled: disabledEnvironments[environmentOption.value],
        })) as EnvironmentOptionDisabled[];
    }, [disabledEnvironments]);

    return (
        <IndicatorContainer data-automation="environment-indicator-container" className={className}>
            <EnvironmentSelectContainer
                placeholder={placeholder}
                value={currentEnvironment}
                options={options}
                components={{
                    Option,
                    SingleValue,
                }}
                styles={{ dropdownIndicator: readonly ? () => ({ display: 'none' }) : undefined }}
                isDisabled={disabled}
                readOnly={readonly}
                onChange={onChanges}
                name={name}
                isSearchable={false}
                hasIndicator={!!showActiveStatusIndicator}
                thin
                {...selectProps}
            />
            <Spacer width={14} />
            <DataNotUpToDateIndicator show={!environmentIsActive && offModuleIndication} />
            {showActiveStatusIndicator && (
                <ActiveIndicator isActive={!!environmentIsActive} dataAutomation="history-header-environment-status" />
            )}
        </IndicatorContainer>
    );
};

export default EnvironmentIndicator;
