import React from 'react';

import type { AlertDialog, BaseDialog, ConfirmDialog } from './Dialog';

export type DialogSettings<T extends BaseDialog> = Omit<T, 'title' | 'content' | 'type'>;

export type CreateDialogPromiseType<T extends BaseDialog> = T extends ConfirmDialog
    ? boolean
    : T extends AlertDialog
      ? void
      : never;

/**
 * The dialog content and title accepts both strings and
 */
export type DialogContentType = React.ReactNode;

export interface CreateDialogFunction<T extends BaseDialog> {
    /**
     * Function to create a dialog.
     *
     * @param title - the title of the dialog.
     * @param content - the content of the dialog.
     * @param settings - settings object.
     * @returns promise that resolves with the return value of the dialog after it closes.
     */
    (
        title: DialogContentType,
        content: DialogContentType,
        settings?: DialogSettings<T>,
    ): Promise<CreateDialogPromiseType<T>>;

    /**
     * Function to create a dialog.
     *
     * @param content - the content of the dialog.
     * @param settings - settings object.
     * @returns promise that resolves with the return value of the dialog after it closes.
     */
    (content: DialogContentType, settings?: DialogSettings<T>): Promise<CreateDialogPromiseType<T>>;
}

export interface CreateDialog {
    /** Create an alert dialog - having only an OK button. */
    alert: CreateDialogFunction<AlertDialog>;
    /** Create a confirm dialog - having both OK and cancel buttons. */
    confirm: CreateDialogFunction<ConfirmDialog>;
}

/**
 * Context to store functions to create alert and confirm dialogs.
 */
const CreateDialogContext = React.createContext<CreateDialog>({} as any);
CreateDialogContext.displayName = 'CreateDialogContext';

export default CreateDialogContext;
