import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { MenuContext } from './Menu';
import { KeyboardArrowFocusSwitchContext } from '../KeyboardArrowFocusSwitch';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import type { FullClickableProps } from '@tonkean/tui-buttons/Clickable';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const StyledClickable = styled(Clickable)<{
    $selected: boolean;
    disabled?: boolean;
    $isClickable: boolean;
    $thin: boolean;
}>`
    padding: 10px;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    text-decoration: none;
    border: none;
    background: ${Theme.colors.basicBackground};
    text-align: left;
    font-weight: ${({ $selected }) => ($selected ? 500 : 'normal')};
    background: ${({ $selected }) => ($selected ? Theme.colors.gray_300 : 'transparent')};
    color: ${({ $selected }) => ($selected ? Theme.colors.gray_700 : Theme.colors.gray_800)};
    display: flex;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    ${({ $selected, $isClickable }) =>
        $isClickable &&
        !$selected &&
        css`
            &:hover,
            &:focus {
                color: ${Theme.colors.gray_800};
                background: ${Theme.colors.brightestGray};
            }
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            &,
            &:hover,
            &:focus {
                color: ${Theme.colors.gray_400};
            }
        `}

    ${({ $thin }) =>
        $thin &&
        css`
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: ${FontSize.SMALL_12};
        `}

    ${styledFocus}
`;

const IconWrapper = styled.div<{ $thin: boolean; $disabled?: boolean }>`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    height: 16px;
    width: 16px;

    .tnk-icon {
        display: inline-block;
    }

    .tnk-icon,
    svg,
    > * {
        height: 100%;
        width: 100%;
    }

    ${({ $thin }) =>
        $thin &&
        css`
            height: 14px;
            width: 14px;
        `}

    ${({ $disabled = false }) =>
        $disabled &&
        css`
            svg {
                ${colorSvg(Theme.colors.gray_400)}
            }
        `}
`;

const ContentWrapper = styled.div`
    flex-grow: 1;
`;

interface Props extends FullClickableProps {
    selected?: boolean;
    icon?: React.ReactNode;
    preventCloseMenu?: boolean;
}

const MenuItem: React.ForwardRefRenderFunction<HTMLElement, Props> = (
    {
        children,
        selected = false,
        icon,
        onClick: onClickProp,
        onKeyDown: onKeyDownProp,
        preventCloseMenu = false,
        ...props
    },
    ref,
): React.ReactElement => {
    const { onClick, thin = false } = useContext(MenuContext) || {};
    const { addNode, onKeyDown } = useContext(KeyboardArrowFocusSwitchContext);

    const isClickable = (onClickProp || props.state || props.params || props.href) && !props.disabled;

    const buttonRef = useRef<HTMLElement>(null);

    useEffect(() => {
        // If item is disabled we ignore it in the arrow navigation.
        if (!buttonRef.current || !isClickable) {
            return;
        }

        const remove = addNode(buttonRef.current as any);

        return () => remove();
    }, [addNode, isClickable]);

    useEffect(() => {
        if (selected) {
            buttonRef.current?.['focus']?.();
        }
    }, [selected]);

    const compositeOnKeyDown = useCompositeEventCallback(onKeyDownProp, onKeyDown);
    const compositeOnClick = useCompositeEventCallback(onClickProp || undefined, preventCloseMenu ? () => {} : onClick);

    const multipleRefCallback = useMultipleRefCallback(ref, buttonRef);

    return (
        <StyledClickable
            {...props}
            $selected={selected}
            onClick={compositeOnClick}
            onKeyDown={isClickable ? compositeOnKeyDown : undefined}
            ref={multipleRefCallback}
            $thin={thin}
            $isClickable={!!isClickable}
        >
            {icon && (
                <IconWrapper $thin={thin} $disabled={props.disabled}>
                    {icon}
                </IconWrapper>
            )}
            <ContentWrapper data-automation={`menu-item-inline-action-name-${children?.toString()}`}>
                {children}
            </ContentWrapper>
        </StyledClickable>
    );
};

export default React.forwardRef(MenuItem);
