import type { CombinedError } from '@urql/core';

export type ErrorResponseType =
    | {
          data:
              | { error: { message: string } }
              | {
                    data: {
                        error: {
                            cause: { message: string };
                            message: string;
                        };
                    };
                }
              | string;
      }
    | { error: string }
    | string
    | Error
    | CombinedError;

interface Options {
    /** Is the reason for the failure is a network error? */
    isNetworkError?: boolean;
    /** The error message that would be returned unless it's a network error. */
    overrideErrorMessage?: string;
    /** If no error message was found, fallback to this message. */
    fallbackErrorMessage?: string;
}

function getStateError(
    error: ErrorResponseType,
    {
        isNetworkError = typeof error !== 'string' && error['status'] === -1,
        fallbackErrorMessage = 'Error Occurred',
        overrideErrorMessage,
    }: Options = {},
): string {
    if (isNetworkError) {
        return 'No Internet Connection was found.';
    }

    if (overrideErrorMessage) {
        return overrideErrorMessage;
    }

    if (typeof error === 'string') {
        return error;
    }

    if ('graphQLErrors' in error) {
        return error.graphQLErrors.map((graphqlError) => graphqlError.message).join(', ') || fallbackErrorMessage;
    }

    if (error instanceof Error) {
        return error.message;
    }

    if ('data' in error && error.data) {
        if (typeof error.data === 'string') {
            return error.data;
        }

        if (error.data) {
            if ('data' in error.data && error.data?.data?.error?.cause?.message) {
                return error.data.data.error.cause.message;
            }

            if ('data' in error.data && error.data?.data?.error?.message) {
                return error.data.data.error.message;
            }

            if ('error' in error.data && error.data?.error?.message) {
                return error.data.error.message;
            }
        }
    }

    if ('error' in error && error.error) {
        return error.error;
    }

    return fallbackErrorMessage;
}

export default getStateError;
