import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import TextareaPreviewModal from './TextareaPreviewModal';
import { ReactComponent as ExpandInputIcon } from '../../../../images/expand-input.svg';

import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StyledTextarea = styled.textarea<{ height; width; horizontalResize }>`
    width: ${({ width }) => width};
    resize: none;
    color: ${Theme.colors.gray_600};
    background-color: ${Theme.colors.brightestGray} !important;
    border: 1px solid ${Theme.colors.gray_300} !important;
    height: ${({ height }) => `${height} !important`};
    font-size: ${FontSize.SMALL_12};

    ${({ horizontalResize }) =>
        horizontalResize &&
        css`
            resize: horizontal;
        `}
`;

const TextareaWrapper = styled.div<{ height?: string; width: string }>`
    position: sticky;
    width: ${({ width }) => width};
    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `}
`;

const StyledIconButton = styled(IconButton)`
    margin: 0 0 0 auto !important;
    position: absolute;
    right: 0;
`;

interface Props {
    width: string;

    text?: string;
    height?: string;
    horizontalResize?: boolean;
}

const DisabledTextareaWithExtendModal: React.FC<Props> = ({ text, height, width, horizontalResize = false }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TextareaWrapper width={width} height={height}>
                <StyledIconButton onClick={() => setIsModalOpen(true)} flat>
                    <ExpandInputIcon />
                </StyledIconButton>

                <StyledTextarea
                    height={height}
                    width={width}
                    className="form-control"
                    data-automation="disable-text-area-with-extend-modal-response"
                    value={text}
                    horizontalResize={horizontalResize}
                    disabled
                />
            </TextareaWrapper>

            <TextareaPreviewModal text={text} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

export default DisabledTextareaWithExtendModal;
