import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SelectNoResult } from '../../../images/select-no-results.svg';
import { H4, Paragraph } from '../TUI';

import { Theme } from '@tonkean/tui-theme';

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 35px 8px;
    text-align: center;
`;

// We can't use type from react-select because it's not supported for customNoOption
const CustomNoOptionsMessage = (props) => {
    const hasOptions = Boolean(props.options && props.options.length > 0);

    return (
        <>
            {hasOptions ? (
                <MainContainer>
                    <H4>No more users</H4>
                    <Paragraph
                        data-automation="custom-no-option-message-no-more-user-indication"
                        $color={Theme.colors.gray_600}
                        $small
                    >
                        All users had been added
                    </Paragraph>
                </MainContainer>
            ) : (
                <MainContainer>
                    <SelectNoResult />

                    <H4 data-automation="custom-no-option-message-no-options-message-indication">
                        Oops! We couldn't find “{props.selectProps.inputValue}”
                    </H4>
                    <Paragraph
                        data-automation="custom-no-option-message-no-options-message-indication-extra-details"
                        $color={Theme.colors.gray_600}
                        $small
                    >
                        Try to refine your search or add users
                    </Paragraph>
                </MainContainer>
            )}
        </>
    );
};

export default CustomNoOptionsMessage;
