import React from 'react';
import { useContext } from 'react';

export const IsTabActiveContext = React.createContext<boolean>(true);

const useIsTabActive = () => {
    return useContext(IsTabActiveContext);
};

export default useIsTabActive;
