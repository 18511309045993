/**
 * Enum that represents the different access types user can have in enterprise component.
 */
enum PersonPermissionRoleType {
    ADMIN = 'ADMIN',
    MAKER = 'MAKER',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export const personPermissionRoleToActionName: Record<PersonPermissionRoleType, string> = {
    ADMIN: 'Manage',
    MAKER: 'View',
    NOT_AUTHORIZED: 'Inaccessible',
};

export default PersonPermissionRoleType;
