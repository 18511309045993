import { FIELD_TYPES } from './fieldTypes';

import type { valueOf } from '@tonkean/utils';

/**
 * Additional names is used to convert additional field types into their display format.
 * For example, for letting you know that "percent" data type is actually "PERCENTAGE" display type in the server.
 */
export const DISPLAY_FORMATS = {
    string: {
        fieldType: FIELD_TYPES.string,
        shortLabel: 'Text',
        label: 'Text',
        apiName: 'TEXT',
        prefix: '',
        postfix: '',
    },
    longText: {
        fieldType: FIELD_TYPES.longString,
        shortLabel: 'Long Text',
        label: 'Long Text',
        apiName: 'LONG_TEXT',
        prefix: '',
        postfix: '',
    },
    html: {
        fieldType: FIELD_TYPES.longString,
        shortLabel: 'Html',
        label: 'Html',
        apiName: 'HTML',
        prefix: '',
        postfix: '',
    },
    file: {
        fieldType: FIELD_TYPES.longString,
        shortLabel: 'File',
        label: 'File',
        apiName: 'FILE',
        prefix: '',
        postfix: '',
    },
    user: {
        fieldType: FIELD_TYPES.string,
        shortLabel: 'People',
        label: 'People',
        apiName: 'USER',
        prefix: '',
        postfix: '',
    },
    number: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'Number',
        label: 'Number',
        apiName: 'NUMBER',
        prefix: '',
        postfix: '',
    },
    rawNumber: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'Raw Number',
        label: 'Unformatted Number',
        apiName: 'RAW_NUMBER',
        prefix: '',
        postfix: '',
    },
    percentage: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'Percentage',
        label: 'Percentage',
        apiName: 'PERCENTAGE',
        prefix: '',
        postfix: '%',
        additionalNames: {
            percent: true,
        },
    },
    dollar: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'US Dollar ($)',
        label: 'US Dollar ($)',
        apiName: 'CURRENCY',
        prefix: '$',
        postfix: '',
    },
    euro: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'Euro (€)',
        label: 'Euro (€)',
        apiName: 'CURRENCY',
        prefix: '',
        postfix: ' €',
    },
    pound: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'British Pound (£)',
        label: 'British Pound (£)',
        apiName: 'CURRENCY',
        prefix: '£',
        postfix: '',
    },
    DECIMAL: {
        fieldType: FIELD_TYPES.number,
        shortLabel: 'Decimal',
        label: 'Decimal',
        apiName: 'DECIMAL',
        prefix: '',
        postfix: '',
    },
    defaultDate: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Default',
        label: 'Dec 20, 2017',
        apiName: 'DEFAULT_DATE',
        prefix: '',
        postfix: '',
        dateFormat: 'mediumDate',
        dateFormatReact: 'MMM dd, yyyy',
    },
    mediumDateTime: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Date time',
        label: 'Dec 20, 2017 12:00:00 AM',
        apiName: 'MEDIUM_DATE_TIME',
        prefix: '',
        postfix: '',
        dateFormat: 'medium',
        dateFormatReact: 'MMM dd, yyyy hh:mm a',
    },
    shortDateTime: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Short date time',
        label: '12/20/17 12:00 AM',
        apiName: 'SHORT_DATE_TIME',
        prefix: '',
        postfix: '',
        dateFormat: 'short',
        dateFormatReact: 'MM/dd/yy hh:mm a',
    },
    shortDateTimeEU: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Short date time EU',
        label: '20/12/17 12:00 AM',
        apiName: 'SHORT_DATE_TIME_EU',
        prefix: '',
        postfix: '',
        dateFormat: 'dd/MM/yy hh:mm a',
        dateFormatReact: 'dd/MM/yy hh:mm a',
    },
    isoDateTime: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'ISO date time',
        label: '2017-12-20T23:59:10',
        apiName: 'ISO_DATE_TIME',
        prefix: '',
        postfix: '',
        dateFormat: "yyyy-MM-dd'T'HH:mm:ss",
        dateFormatReact: "yyyy-MM-dd'T'HH:mm:ss",
    },
    fullDate: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Full',
        label: 'Wednesday, December 20, 2017',
        apiName: 'FULL_DATE',
        prefix: '',
        postfix: '',
        dateFormat: 'fullDate',
        dateFormatReact: 'EEEE, MMMM dd, yyyy',
    },
    longDate: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Long',
        label: 'December 20, 2017',
        apiName: 'LONG_DATE',
        prefix: '',
        postfix: '',
        dateFormat: 'longDate',
        dateFormatReact: 'MMMM dd, yyyy',
    },
    shortDate: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Short date',
        label: '12/20/17',
        apiName: 'SHORT_DATE',
        prefix: '',
        postfix: '',
        dateFormat: 'shortDate',
        dateFormatReact: 'MM/dd/yy',
    },
    shortDateEU: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Short date EU',
        label: '20/12/17',
        apiName: 'SHORT_DATE_EU',
        prefix: '',
        postfix: '',
        dateFormat: 'dd/MM/yy',
        dateFormatReact: 'dd/MM/yy',
    },
    isoDate: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'ISO date',
        label: '2017-12-20',
        apiName: 'ISO_DATE',
        prefix: '',
        postfix: '',
        dateFormat: 'yyyy-MM-dd',
        dateFormatReact: 'yyyy-MM-dd',
    },
    mediumTime: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Time',
        label: '12:00:00 AM',
        apiName: 'MEDIUM_TIME',
        prefix: '',
        postfix: '',
        dateFormat: 'h:mm:ss a',
        dateFormatReact: 'h:mm:ss a',
    },
    shortTime: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Short time',
        label: '12:00 AM',
        apiName: 'SHORT_TIME',
        prefix: '',
        postfix: '',
        dateFormat: 'h:mm a',
        dateFormatReact: 'h:mm a',
    },
    meduimTime24h: {
        fieldType: FIELD_TYPES.date,
        shortLabel: '24h Medium time',
        label: '23:59:00',
        apiName: 'MEDIUM_TIME_24H',
        prefix: '',
        postfix: '',
        dateFormat: 'HH:mm:ss',
        dateFormatReact: 'HH:mm:ss',
    },
    shortTime24h: {
        fieldType: FIELD_TYPES.date,
        shortLabel: '24h Short time',
        label: '23:59',
        apiName: 'SHORT_TIME_24H',
        prefix: '',
        postfix: '',
        dateFormat: 'HH:mm',
        dateFormatReact: 'HH:mm',
    },
    dateDiffFromToday: {
        fieldType: FIELD_TYPES.date,
        shortLabel: 'Diff from today',
        label: '2 days ago',
        apiName: 'DATE_DIFF_FROM_TODAY',
        prefix: '',
        postfix: '',
        // todo: what the fuck do we do with this format?
        dateFormatReact: undefined,
    },
};

export function getDisplayFormats() {
    return structuredClone(DISPLAY_FORMATS);
}

/**
 * Retrieves the display format by the given additional name.
 */
export function getDisplayFormatByAdditionalName(givenAdditionalName: string) {
    for (const displayFormat in DISPLAY_FORMATS) {
        if (DISPLAY_FORMATS.hasOwnProperty(displayFormat)) {
            for (const additionalName in DISPLAY_FORMATS[displayFormat].additionalNames) {
                if (
                    DISPLAY_FORMATS[displayFormat].additionalNames.hasOwnProperty(additionalName) &&
                    additionalName === givenAdditionalName
                ) {
                    return DISPLAY_FORMATS[displayFormat];
                }
            }
        }
    }

    return null;
}

let apiNameToDisplayFormatMap: Record<string, valueOf<typeof DISPLAY_FORMATS>> | null = null;
export function getApiNameToDisplayFormatMap() {
    if (!apiNameToDisplayFormatMap) {
        apiNameToDisplayFormatMap = {};

        for (const key in DISPLAY_FORMATS) {
            if (DISPLAY_FORMATS.hasOwnProperty(key)) {
                apiNameToDisplayFormatMap[DISPLAY_FORMATS[key].apiName] = DISPLAY_FORMATS[key];
            }
        }
    }

    return apiNameToDisplayFormatMap;
}

/**
 * Returns a map between additional display format name to the display format api name.
 */
export function getAdditionalNameToDisplayTypeApiNameMap() {
    const additionalNameToDisplayFormatApiNameMap = {};

    for (const displayFormat in DISPLAY_FORMATS) {
        if (DISPLAY_FORMATS.hasOwnProperty(displayFormat)) {
            for (const additionalName in DISPLAY_FORMATS[displayFormat].additionalNames) {
                if (DISPLAY_FORMATS[displayFormat].additionalNames.hasOwnProperty(additionalName)) {
                    additionalNameToDisplayFormatApiNameMap[additionalName] = DISPLAY_FORMATS[displayFormat].apiName;
                }
            }
        }
    }

    return additionalNameToDisplayFormatApiNameMap;
}
