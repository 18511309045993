import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { getDeferredPromise } from '@tonkean/utils';
import type { DeferredPromise } from '@tonkean/utils';
import type { KeysThatExtend } from '@tonkean/utils';
import type { ResolveValue } from '@tonkean/utils';
import type { NoInfer } from '@tonkean/utils';

export const SKIP_PARAM = Symbol('skip param');
export const LIMIT_PARAM = Symbol('limit param');
export const NEXT_PAGE_TOKEN_PARAM = Symbol('next page token param');

export const LAST_RESPONSE_METADATA_PARAM = Symbol('last response metadata param');

// We use typeof instead of just `Symbol` because it will be of type unique symbol, so only those variables will be
// allowed and not any symbol.
type AllowedNumberSymbols = typeof SKIP_PARAM | typeof LIMIT_PARAM;
type AllowedStringSymbols = typeof NEXT_PAGE_TOKEN_PARAM | typeof LAST_RESPONSE_METADATA_PARAM;

// If it's a number param, it can be skip or limit. If it's a string, it can be next page token.
type AppendType<T> = T extends string ? T | AllowedStringSymbols : T extends number ? T | AllowedNumberSymbols : T;

// For each string or number param, accept the skip, limit and next page token symbols. If IS_ACTIVE is false, each
// param will accept undefined as well.
// prettier-ignore
type ModifyTuple<PARAMS> = PARAMS extends [infer FIRST, ...infer REST]
    ? [AppendType<FIRST>, ...ModifyTuple<REST>]
    : PARAMS;

/**
 * This is how we get the items when they didn't pro
 */
export type DefaultGetItems<RETURN_VALUE> = RETURN_VALUE extends (infer ITEM1)[]
    ? ITEM1
    : RETURN_VALUE extends { entities: (infer ITEM2)[] }
      ? ITEM2
      : never;

type CheckHasMoreSettings<RETURN_VALUE> =
    | {
          /**
           * The key in the return value that contains a boolean on whether there are more pages or not.
           */
          checkHasMore?: KeysThatExtend<NoInfer<Required<RETURN_VALUE>>, boolean>;
      }
    | {
          /**
           * A function that will determine whether there are more pages or not.
           *
           * @param returnValue - the return value of the last page loaded.
           * @param currentItemsCount - the current items count.
           * @returns true if there are more pages to load, false if not.
           */
          checkHasMore?: (returnValue: RETURN_VALUE, currentItemsCount: number) => boolean;
      };

type NextPageTokenSettings<RETURN_VALUE> = {
    /**
     * A function to get the next page token from the return value, or the key that contains it on the return
     * value object. If not set, it means that there is no next page token.
     */
    nextPageTokenMeansHasMore?: boolean;
} & (
    | {
          /**
           * The key in the return value that contains the next page token.
           */
          getNextPageToken?: KeysThatExtend<NoInfer<Required<RETURN_VALUE>>, string>;
      }
    | {
          /**
           * A function that will get the next page token fro the return value.
           *
           * @param returnValue - the return value of the last page loaded.
           * @returns the next page token, or undefined if there are none.
           */
          getNextPageToken?: (returnValue: RETURN_VALUE) => string | undefined;
      }
);

type ResponseMetadataSettings<RETURN_VALUE> =
    | {
          /**
           * The key in the return value that contains the response metadata.
           */
          getLastPollingReqMetadata?: KeysThatExtend<NoInfer<Required<RETURN_VALUE>>, string>;
      }
    | {
          /**
           * A function that will get the response metadata from the return value.
           *
           * @param returnValue - the return value of the last call.
           * @returns the response metadata, or undefined if there are none.
           */
          getLastPollingReqMetadata?: (returnValue: RETURN_VALUE, lastValue: string | undefined) => string | undefined;
      };

type GetItemsInnerSettings<RETURN_VALUE, IS_SINGLE extends boolean, ITEM> = {
    /**
     * A function that locates the items from the response.
     *
     * @param returnValue - the response.
     * @returns list of items.
     */
    getItems: (returnValue: RETURN_VALUE) => IS_SINGLE extends true ? ITEM : ITEM[];
};

// If return value is an array or an object with entities, the items can be auto discovered making getItems optional.
type GetItemsSettings<RETURN_VALUE, IS_SINGLE extends boolean, ITEM> = RETURN_VALUE extends any[] | { entities: any[] }
    ? GetItemsInnerSettings<RETURN_VALUE, IS_SINGLE, ITEM> | { getItems?: undefined }
    : GetItemsInnerSettings<RETURN_VALUE, IS_SINGLE, ITEM>;

type IsSingleSettings<IS_SINGLE extends boolean> = {
    /**
     * Do we expect to get a single item, or an array of items?
     */
    isSingle?: IS_SINGLE;
};

type GetCompareItemInnerSettings<ITEM> =
    | {
          /**
           * A function that compares two items. It's used to remove duplicates on reload.
           *
           * @param item1 - first item to compare.
           * @param item2 - second item to compare.
           * @return true if both items are the same.
           */
          compareItems: (item1: ITEM, item2: ITEM) => boolean;
      }
    | {
          /**
           * The key in the item that will be used to compare between them (for example, id).
           */
          compareItems: ITEM extends Record<string, any> ? KeysThatExtend<NoInfer<ITEM>, string | number> : never;
      };

// If the item has an id, we will use it to compare if compare items is not provided. It not, you must specify compare
// item.
type GetCompareItemSettings<ITEM> = ITEM extends { id: any }
    ? GetCompareItemInnerSettings<ITEM> | { compareItems?: undefined }
    : GetCompareItemInnerSettings<ITEM>;

type SortItemsSettings<ITEM> =
    | {
          /**
           * A function that compares two items. It's used to remove duplicates on reload.
           *
           * @param item1 - first item to compare.
           * @param item2 - second item to compare.
           * @return true if both items are the same.
           */
          sort?: (item1: ITEM, item2: ITEM) => number;
      }
    | {
          /**
           * The key in the item that will be used to compare between them (for example, id).
           */
          sort?: ITEM extends Record<string, any>
              ? { key: KeysThatExtend<NoInfer<ITEM>, string | number>; desc?: boolean }
              : never;
      };

type StaticSettings<RETURN_VALUE> = {
    /**
     * The auto reload interval, in ms. If not provided, it won't auto reload.
     */
    autoReloadInterval?: number | false;
    /**
     * The limit to use. If this request not paginates, leave it empty.
     */
    limit?: number;
    /**
     * Triggered on every server response.
     *
     * @param value - the value returned from the server.
     */
    onLoaded?(value: NoInfer<RETURN_VALUE>): void;
};

type ItemArraySettings<RETURN_VALUE, ITEM, IS_SINGLE extends boolean> = IS_SINGLE extends true
    ? {
          compareItems?: undefined;
          sort?: undefined;
          nextPageTokenMeansHasMore?: undefined;
          getNextPageToken?: undefined;
          checkHasMore?: undefined;
      }
    : NextPageTokenSettings<RETURN_VALUE> &
          CheckHasMoreSettings<RETURN_VALUE> &
          GetCompareItemSettings<ITEM> &
          SortItemsSettings<ITEM>;

export type SettingsObject<RETURN_VALUE, IS_SINGLE extends boolean, ITEM> = StaticSettings<RETURN_VALUE> &
    IsSingleSettings<IS_SINGLE> &
    GetItemsSettings<RETURN_VALUE, IS_SINGLE, ITEM> &
    ItemArraySettings<RETURN_VALUE, ITEM, IS_SINGLE> &
    ResponseMetadataSettings<RETURN_VALUE>;

enum RequestReason {
    INITIAL,
    AUTO_RELOAD,
    MANUAL_RELOAD,
    NEXT_PAGE,
}

export type LoadingState = {
    initial: boolean;
    autoReloading: boolean;
    manualReloading: boolean;
    nextPageLoading: boolean;
    any: boolean;
};

/**
 * The fetch manager is here to help you with all fetch related things in react! It handles pagination, auto and manual
 * reloads, error and loading states, etc!
 * @see https://tonkean.atlassian.net/wiki/spaces/TONKEAN/pages/600735745/How+to+use+fetch+manager
 *
 * @example
 * const tonkeanService = useAngularService('tonkeanService');
 * const [
 *     [getWorkflowFolderVersionSummaries, cancelFetcher],
 *     { data: workflowFolderVersionSummaries, hasMorePages, loading, loadNextPage, error },
 * ] = useFetchManager(tonkeanService, 'getWorkflowFolderVersionSummaries', {
 *     // Compare by the workflow folder version id.
 *     compareItems(item1, item2) {
 *         return item1.workflowFolderVersion.id === item2.workflowFolderVersion.id;
 *     },
 *     // Sort by sequential identifier, DESC.
 *     sort(item1, item2) {
 *         return item2.workflowFolderVersion.sequentialIdentifier - item1.workflowFolderVersion.sequentialIdentifier;
 *     },
 *     limit: 20,
 * );
 *
 * useEffect(() => {
 *     getWorkflowFolderVersionSummaries(
 *         workflowFolder.id,
 *         trimmedSearchTerm || undefined,
 *         filters.fromDate?.getTime(),
 *         filters.toDate?.getTime(),
 *         filters.publisherId ? [filters.publisherId] : undefined,
 *         filters.sequentialIdentifier ? [filters.sequentialIdentifier] : undefined,
 *         // The skip and limit params will be replaced with the correct value by the fetcher.
 *         SKIP_PARAM,
 *         LIMIT_PARAM,
 *     );
 *
 *     return cancelFetcher;
 *     // The fetcher will be canceled and a new one created when some of the filters change or the workflow folder id
 *     // changes.
 * , [cancelFetcher, filters, getWorkflowFolderVersionSummaries, trimmedSearchTerm, workflowFolder.id]);
 *
 * @param object - an object to call for fetch.
 * @param methodKey - key of the method to use on the object (first param), just like useLazyAsyncMethod.
 * @param settings - the fetcher settings.
 * @returns an array, the first item contains an array with start method and cancel method, and the second item
 * contains an object with useful data and methods.
 */
function useFetchManager<
    ERROR = any,
    OBJECT extends Record<string, any> = any,
    KEY extends KeysThatExtend<OBJECT> = any,
    IS_SINGLE extends boolean = false,
    ITEM = DefaultGetItems<ResolveValue<ReturnType<OBJECT[KEY]>>>,
>(object: OBJECT, methodKey: KEY, settings: SettingsObject<ResolveValue<ReturnType<OBJECT[KEY]>>, IS_SINGLE, ITEM>) {
    type RETURN_VALUE = ResolveValue<ReturnType<OBJECT[KEY]>>;
    type REAL_ITEMS_TYPE = IS_SINGLE extends true ? ITEM | undefined : ITEM[];

    const {
        nextPageTokenMeansHasMore = false,
        autoReloadInterval = false,
        limit = 50,
        checkHasMore: checkHasMoreParam,
        getNextPageToken: getNextPageTokenParam,
        getItems: getItemsParam,
        compareItems: compareItemsParam,
        sort: sortParam,
        isSingle = false as IS_SINGLE,
        onLoaded,
        getLastPollingReqMetadata: getLastPollingReqMetadataParam,
    } = settings;

    const initialValue: REAL_ITEMS_TYPE = useMemo((): any => {
        if (isSingle) {
            return undefined;
        }
        return [];
    }, [isSingle]);

    const initialState = useMemo(() => {
        return {
            loading: {
                initial: false,
                autoReloading: false,
                manualReloading: false,
                nextPageLoading: false,
                any: false,
            },
            error: undefined as ERROR | undefined,
            items: initialValue,
            skip: 0,
            nextPageToken: undefined as string | undefined,
            hasMorePages: false,
            isEmpty: false,
            isFetched: false,
            executionDate: -1,
            lastPollResponseMetadata: undefined as string | undefined,
        };
    }, [initialValue]);

    const [state, setState] = useState(initialState);
    const [args, setArgs] = useState<ModifyTuple<Parameters<OBJECT[KEY]>>>();

    const haveNextPageTokenParam = args?.some((arg) => arg === NEXT_PAGE_TOKEN_PARAM);
    const haveSkipParam = args?.some((arg) => arg === SKIP_PARAM);
    const isPaginating = haveNextPageTokenParam || haveSkipParam;

    // If the request can paginate (the args have the skip or next page token symbols), and there is no check has more
    // param, and it either doesnt have a next page token param or it does but nextPageTokenMeansHasMore is false
    // (meaning, result doesn't tells if has more pages), we need to add one to the limit, and if more items than the
    // limit count were returned, it means that there are more pages.
    const useOneMoreStrategy =
        isPaginating && (!haveNextPageTokenParam || !nextPageTokenMeansHasMore) && !checkHasMoreParam;

    // The real limit if we are using the one more strategy is one more than the requested limit, otherwise it's the
    // requested limit. We use ref to be able not to pass it to the deps array in the getArgs callback.
    const realLimitRef = useRef(0);
    realLimitRef.current = useOneMoreStrategy ? limit + 1 : limit;

    // This holds the deferred promise that's returned when starting the fetcher, and it resolves with the resolve
    // value of the first fetch. We use a ref because it should work "in between" renders and should not create a
    // render.
    const initialLoadDeferredPromiseRef = useRef<DeferredPromise<RETURN_VALUE>>();
    // This holds the symbol of the last request sent. When the request resolves, we check if the value of this ref
    // matches the symbol of the request to make sure we are handling the response of the latest request.
    const latestRequestSymbolRef = useRef<symbol>();

    const [{ loading: isLoading }, trigger] = useLazyAsyncMethod<ERROR>(object, methodKey);

    /**
     * Check if there are more pages to load.
     *
     * @param returnValue - the response from the server.
     * @param haveNextPageToken - does the response has a next page token in it.
     * @param returnItemsCount - the items count returned in the response (not sliced by the limit).
     * @param currentItemsCount - the items count after merging the returned items to the existing items list.
     * @returns true if there are more pages to load, otherwise false.
     */
    const checkHasMorePages = (
        returnValue: RETURN_VALUE,
        haveNextPageToken: boolean,
        returnItemsCount: number,
        currentItemsCount: number,
    ): boolean => {
        // If the fetcher has no paginating args, or it's a single item fetcher, we consider it as if there are no more
        // pages.
        if (!isPaginating || isSingle) {
            return false;
        }

        // If next page token means that there are more pages, then just check if there is a next page token.
        if (nextPageTokenMeansHasMore) {
            return haveNextPageToken;
        }

        if (checkHasMoreParam) {
            if (typeof checkHasMoreParam === 'function') {
                // If the check has more param is a function, use it to check.
                return checkHasMoreParam(returnValue, currentItemsCount) || false;
            } else if (typeof returnValue === 'object') {
                // If the return value is an object, and the check has more param is not undefined and not a function,
                // it means that it's the key on the return value to get the whether there are more pages.
                return returnValue[checkHasMoreParam] as any;
            }
        }

        // Otherwise, use the one more strategy result.
        if (useOneMoreStrategy) {
            return returnItemsCount > limit;
        }

        throw new Error("Can't check if has more pages.");
    };

    /**
     * Get the next page token.
     *
     * @param returnValue - the response from the server.
     * @returns the next page token.
     */
    const getNextPageToken = (returnValue: RETURN_VALUE): string | undefined => {
        // We don't auto infer the next page token, so if there is no get next page token param we consider it as if
        // there is no next token param.
        if (!getNextPageTokenParam) {
            return undefined;
        }

        // If the get next page token param is a function, use it to get the items.
        if (typeof getNextPageTokenParam === 'function') {
            return getNextPageTokenParam(returnValue);
        }

        // If the return value is an object, and the get next page token param is not undefined and not a function,
        // it means that it's the key on the return value to get the next page token param from.
        if (typeof returnValue === 'object') {
            return returnValue[getNextPageTokenParam];
        }

        throw new Error("Can't determine next page token.");
    };

    /**
     * Get the last response metadata.
     *
     * @param returnValue - the response from the server.
     * @returns the last response metadata.
     */
    const getLastPollingReqMetadata = (
        returnValue: RETURN_VALUE,
        lastValue: string | undefined,
    ): string | undefined => {
        // We don't auto infer the last response metadata, so if there is no get last response metadata param we consider it as if
        // there is no last response metadata.
        if (!getLastPollingReqMetadataParam) {
            return undefined;
        }

        // If the get last response metadata param is a function, use it to get the items.
        if (typeof getLastPollingReqMetadataParam === 'function') {
            return getLastPollingReqMetadataParam(returnValue, lastValue);
        }

        // If the return value is an object, and the get last response metadata param is not undefined and not a function,
        // it means that it's the key on the return value to get the last response metadata param from.
        if (typeof returnValue === 'object') {
            return returnValue[getLastPollingReqMetadataParam];
        }

        throw new Error("Can't determine last response metadata.");
    };

    /**
     * Get items from the response.
     *
     * @param returnValue - the response from the server.
     * @returns the item if it's a single item fetcher, otherwise list of items.
     */
    const getItems = (returnValue: RETURN_VALUE): REAL_ITEMS_TYPE => {
        if (!getItemsParam) {
            // If the response is an array and it's a single items fetcher, return the first item. Otherwise, return
            // the array.
            if (Array.isArray(returnValue)) {
                return isSingle ? returnValue[0] : returnValue;
            }

            // If the response is an object, and it has an entities key and it contains an array, and it's a single
            // items fetcher, return the first item. Otherwise, return the array.
            if (typeof returnValue === 'object' && 'entities' in returnValue) {
                const itemList = returnValue['entities'];
                if (Array.isArray(itemList)) {
                    return isSingle ? itemList[0] : itemList;
                }
            }

            throw new Error("Can't auto determine the items for the response");
        }

        // If the get items param is a function, use it to get the items.
        if (typeof getItemsParam === 'function') {
            return getItemsParam(returnValue);
        }

        // If the return value is an object, and the get items param is not undefined and not a function, it means that
        // it's the key on the return value to get the array from.
        if (typeof returnValue === 'object') {
            return returnValue[getItemsParam];
        }

        throw new Error("Can't determine the items for the response from the given getItems param");
    };

    /**
     * Compares two items. Used to detect duplicates.
     *
     * @param item1 - the item to compare with.
     * @param item2 - the item to compare to.
     * @returns true if items are duplicates.
     */
    const compareItems = (item1: ITEM, item2: ITEM): boolean => {
        const itemsAreObjects = typeof item1 === 'object';

        if (item1 == null || item2 == null) {
            return false;
        }

        if (compareItemsParam) {
            if (typeof compareItemsParam === 'function') {
                // If the compare items param has a function that compares the items.
                return compareItemsParam(item1, item2);
            } else if (itemsAreObjects) {
                // If the compare items param is a key, compare the values.
                return item1[compareItemsParam] === item2[compareItemsParam];
            }
        }

        // If compare items param is not provided, but the item has id on it, compare the ids.
        if (item1 && itemsAreObjects && 'id' in item1) {
            return item1['id'] === item2['id'];
        }

        // Otherwise, we can't compare and we will use a soft compare and hope for the best ¯\_(ツ)_/¯
        // eslint-disable-next-line eqeqeq
        return item1 == item2;
    };

    /**
     * Hack function that checks if it's a single item fetcher. We use isSingle, and ignore the _item param. We require
     * it because the return type of the function will modify the type of the given item, and if used inside an if
     * clause, it will allow to use array methods over the variable.
     *
     * @param _item - the item to convert it's type to an array of items if isSingle is false.
     * @returns true if it's not a single items fetcher, otherwise false.
     */
    const getIsMultipleItems = (_item: ITEM[] | ITEM | undefined): _item is ITEM[] => {
        return !isSingle;
    };

    /**
     * Sort items based on the sorting param.
     *
     * @param itemsToSort - list if items to sort.
     * @returns sorted list of items. If it's a single item fetcher it just returns the item.
     */
    const sortItems = (itemsToSort: REAL_ITEMS_TYPE): REAL_ITEMS_TYPE => {
        if (getIsMultipleItems(itemsToSort) && sortParam) {
            if (typeof sortParam === 'function') {
                // If the sort param is a sort function.
                return itemsToSort.sort(sortParam) as REAL_ITEMS_TYPE;
            } else {
                // If sort param is a key to sort by.
                return itemsToSort.sort((item1, item2) => {
                    // Convert values to string.
                    const item1String: string = (item1[sortParam.key] as any)?.toString() || '';
                    const item2String: string = (item2[sortParam.key] as any)?.toString() || '';

                    // Compare the values.
                    const compareResult = item1String.localeCompare(item2String, undefined, { numeric: true }) || 0;

                    // If it's DESC, change the symbol (if it's negative change it to positive).
                    return sortParam.desc ? 0 - compareResult : compareResult;
                }) as REAL_ITEMS_TYPE;
            }
        }

        // If it's a single item fetcher, just return it.
        return itemsToSort;
    };

    /**
     * Merge the items returned in the response with the existing items, and sort them. If an item already exists, the
     * new item will replace the old one in it's place. New items will be appended to the items list.
     *
     * @param returnedItems - the items returned in the response.
     * @returns a sorted list of all items.
     */
    const updateItems = (returnedItems: REAL_ITEMS_TYPE): REAL_ITEMS_TYPE => {
        if (getIsMultipleItems(returnedItems) && getIsMultipleItems(state.items)) {
            const returnedItemsArray: ITEM[] = returnedItems || [];
            const itemsArray: ITEM[] = state.items || [];

            // Existing items, with duplicate items replaced with their newer version.
            const updatedExistingItems =
                itemsArray.map((existingItem) => {
                    const sameItem = returnedItemsArray.find((returnedItem) =>
                        compareItems(returnedItem, existingItem),
                    );
                    return sameItem || existingItem;
                }) || [];

            // Only new items, without items that are duplicates.
            const newItems = returnedItemsArray.filter((returnedItem) => {
                return itemsArray.every((existingItem) => !compareItems(returnedItem, existingItem));
            });

            return sortItems([...updatedExistingItems, ...newItems] as REAL_ITEMS_TYPE);
        }

        // If it's a single item fetcher, just return it.
        return returnedItems;
    };

    /**
     * If it's not a single item fetcher, and we are using the one more strategy to check if there are more pages to
     * load, return only the requested amount in the limit param.
     *
     * @param returnedItems - the items returned in the response.
     * @returns the items to use when handling the response.
     */
    const getItemsToUse = (returnedItems: REAL_ITEMS_TYPE): REAL_ITEMS_TYPE => {
        if (getIsMultipleItems(returnedItems) && useOneMoreStrategy) {
            const sortedItems = sortItems(returnedItems) as ITEM[];
            return sortedItems.slice(0, limit) as REAL_ITEMS_TYPE;
        }

        return returnedItems;
    };

    /**
     * Count the items. We cant just use `.length` because it might be a single entity fetcher.
     *
     * @param itemsToCount - the item or items to count.
     * @returns the items count it it's not a single item fetcher, one if it is and there is an item, otherwise 0.
     */
    const countItems = (itemsToCount: REAL_ITEMS_TYPE): number => {
        if (getIsMultipleItems(itemsToCount)) {
            return itemsToCount.length;
        }

        return !!itemsToCount ? 1 : 0;
    };

    /**
     * Method that handles the response and updating the internal states.
     *
     * @param returnValue - the return value of the request.
     * @param isReload - does this request is a reload? (both auto reload and manual reload).
     * @param requestSymbol - the request symbol, to make sure it's the last request sent.
     * @param error - the reject reason if the request fails.
     */
    const handleResponse = useConstantRefCallback(
        (returnValue: RETURN_VALUE, isReload: boolean, requestSymbol: symbol, executionDate: number, error?: ERROR) => {
            // If the request symbol doesnt match the one in the ref, it means that another request has been sent and this
            // one should not be updating the items. If there are no args, it means that the fetcher was canceled and it
            // should ignore this response.
            if (requestSymbol !== latestRequestSymbolRef.current || !args) {
                return;
            }

            const noLoading = {
                initial: false,
                manualReloading: false,
                autoReloading: false,
                nextPageLoading: false,
                any: false,
            };

            // If it had an error, set it and mark as no loading.
            if (error) {
                setState((currentState) => ({ ...currentState, loading: noLoading, error }));
                return;
            }

            onLoaded?.(returnValue);

            // Get the items from the response
            const returnedItems = getItems(returnValue);
            // Remove items that should be ignored (for example, when using the "one more strategy" to check if there are
            // more pages to load). If there are items to be removed, we will sort them before.
            const returnedItemsToUse = getItemsToUse(returnedItems);
            // Join the new items into the existing items list - update duplicates, and push new ones. This also handles
            // sorting.
            const updatedItemsList = updateItems(returnedItemsToUse);

            // Update the items and loading state, and if it's not a reload, update the next page token, has more pages and
            // skip.
            setState((currentState) => {
                const updatedLoadingIsEmptyAndItems = {
                    loading: noLoading,
                    items: updatedItemsList,
                    isEmpty: Array.isArray(updatedItemsList) ? updatedItemsList.length === 0 : !updatedItemsList,
                    isFetched: true,
                    error: undefined,
                    executionDate,
                };

                const lastPollResponseMetadata = getLastPollingReqMetadata(
                    returnValue,
                    currentState.lastPollResponseMetadata,
                );

                // If it's a reload, then we ignore it when checking if there are more pages to load - we use only the
                // initial load and the next pages load.
                if (!isReload) {
                    const nextPageToken = getNextPageToken(returnValue);
                    const hasMorePages = checkHasMorePages(
                        returnValue,
                        !!nextPageToken,
                        countItems(returnedItems),
                        countItems(updatedItemsList),
                    );
                    const skip = currentState.skip + countItems(returnedItemsToUse);

                    return {
                        ...currentState,
                        ...updatedLoadingIsEmptyAndItems,
                        nextPageToken,
                        skip,
                        hasMorePages,
                        lastPollResponseMetadata,
                    };
                }

                return { ...currentState, ...updatedLoadingIsEmptyAndItems, lastPollResponseMetadata };
            });
        },
    );

    /**
     * Get list of args. If some of the args are symbols, replace them with the correct value based on the param. The
     * referance of this method changes only when args changes - when calling startFetcher or cancelFetcher.
     *
     * @param skip - the value to replace the skip param symbol with.
     * @param nextPageToken - the value to replace the next page token param symbol with.
     * @param lastPollResponseMetadata - the value to replace the last response metadata param symbol with.
     * @returns list of args with values instead of symbols if args are defined (fetcher is active) otherwise
     * undefined.
     */
    const getArgs = useCallback(
        (
            skip: number = 0,
            nextPageToken: string | undefined = undefined,
            lastPollResponseMetadata: string | undefined = undefined,
        ) => {
            return args?.map((arg) => {
                if (arg === SKIP_PARAM) {
                    return skip;
                }
                if (arg === LIMIT_PARAM) {
                    return realLimitRef.current;
                }
                if (arg === NEXT_PAGE_TOKEN_PARAM) {
                    return nextPageToken;
                }
                if (arg === LAST_RESPONSE_METADATA_PARAM) {
                    return lastPollResponseMetadata;
                }
                return arg;
            }) as Parameters<OBJECT[KEY]> | undefined;
        },
        [args],
    );

    /**
     * Send the request, set the loading state, resolve the initial deferred promise if it's the first load, and handle
     * the response. The reference of it changes when getArgs changes or when the trigger changes.
     *
     * @param reason - the reason of triggering the request.
     * @param skip - the value to replace the skip param symbol with. Ignored if it's not a next page load.
     * @param nextPageToken - the value to replace the next page token param symbol with. Ignored if it's not a next
     * page load.
     */
    const sendRequest = useCallback(
        (reason: RequestReason, skip?: number, nextPageToken?: string, lastPollResponseMetadata?: string) => {
            // We need skip and next page token only if it's next page load.
            const currentArgs =
                reason === RequestReason.NEXT_PAGE
                    ? getArgs(skip, nextPageToken, undefined)
                    : getArgs(undefined, undefined, lastPollResponseMetadata);

            // If returned undefined, it means that the fetcher is not initiated or was canceled.
            if (!currentArgs) {
                return;
            }

            // Create request symbol to make sure it's that it's the last request sent.
            const requestSymbol = Symbol('request symbol');
            latestRequestSymbolRef.current = requestSymbol;

            const executionDate = Date.now();
            // Trigger the request.
            const responsePromise = trigger(...currentArgs);

            // Update loading states.
            setState((currentState) => ({
                ...currentState,
                loading: {
                    initial: reason === RequestReason.INITIAL,
                    manualReloading: reason === RequestReason.MANUAL_RELOAD,
                    autoReloading: reason === RequestReason.AUTO_RELOAD,
                    nextPageLoading: reason === RequestReason.NEXT_PAGE,
                    any: true,
                },
            }));

            // Handle response.
            responsePromise
                .then((response) =>
                    handleResponse(
                        response,
                        [RequestReason.AUTO_RELOAD, RequestReason.MANUAL_RELOAD].includes(reason),
                        requestSymbol,
                        executionDate,
                    ),
                )
                .catch((error) =>
                    handleResponse(
                        undefined as any,
                        [RequestReason.AUTO_RELOAD, RequestReason.MANUAL_RELOAD].includes(reason),
                        requestSymbol,
                        executionDate,
                        error,
                    ),
                );

            // If it is an initial load, when completes resolve or reject the deferred promise that was returned by the
            // startFetcher function.
            if (reason === RequestReason.INITIAL) {
                const initialLoadDeferredPromise = initialLoadDeferredPromiseRef.current;
                initialLoadDeferredPromiseRef.current = undefined;

                responsePromise
                    .then((response) => initialLoadDeferredPromise?.resolve(response))
                    .catch((error) => initialLoadDeferredPromise?.reject(error));
            }
        },
        [getArgs, handleResponse, trigger],
    );

    /**
     * Initial load.
     */
    useEffect(() => {
        sendRequest(RequestReason.INITIAL);
    }, [sendRequest]);

    /**
     * Auto reload.
     * The auto reload only fetches the first page.
     */
    useEffect(() => {
        if (isLoading || !autoReloadInterval) {
            return;
        }

        const timeout = setTimeout(() => {
            sendRequest(RequestReason.AUTO_RELOAD, undefined, undefined, state.lastPollResponseMetadata);
        }, autoReloadInterval);

        return () => {
            clearTimeout(timeout);
        };
    }, [autoReloadInterval, isLoading, sendRequest, state.lastPollResponseMetadata]);

    /**
     * Trigger manual reload.
     * The manual reload only fetches the first page.
     */
    const manuallyReload = useCallback(() => {
        sendRequest(RequestReason.MANUAL_RELOAD, undefined, undefined, state.lastPollResponseMetadata);
    }, [sendRequest, state.lastPollResponseMetadata]);

    /**
     * Trigger loading next page.
     */
    const loadNextPage = useCallback(() => {
        sendRequest(RequestReason.NEXT_PAGE, state.skip, state.nextPageToken, state.lastPollResponseMetadata);
    }, [sendRequest, state.nextPageToken, state.skip, state.lastPollResponseMetadata]);

    /**
     * A function to starts the fetcher and accepts the params to pass to the given method, with symbols instead of
     * values to change it dynamically. It triggers an initial load.
     *
     * @returns the promise returned by the initial load that resolves to the first return value.
     */
    const startFetcher: (...newArgs: ModifyTuple<Parameters<OBJECT[KEY]>>) => ReturnType<OBJECT[KEY]> =
        useConstantRefCallback((...newArgs) => {
            setArgs(newArgs);
            setState(initialState);

            const deferredPromise = getDeferredPromise<RETURN_VALUE>();
            initialLoadDeferredPromiseRef.current = deferredPromise;

            return deferredPromise.promise as ReturnType<OBJECT[KEY]>;
        });

    /**
     * Cancel the fetcher. It stops all reloading if exists, and clears the items list. If you just want to stop the
     * auto reload, pass undefined to autoReloadInterval when you want it to stop.
     */
    const cancelFetcher = useCallback(() => {
        setArgs(undefined);
        setState(initialState);
    }, [initialState]);

    return [
        [startFetcher, cancelFetcher],
        {
            data: state.items,
            hasMorePages: state.hasMorePages,
            loading: state.loading,
            error: state.error,
            manuallyReload,
            loadNextPage,
            isEmpty: state.isEmpty,
            isFetched: state.isFetched,
            executionDate: state.executionDate,
        },
    ] as const;
}

export default useFetchManager;
