/**
 * This Class is used to explicitly inherit from Date. This is done
 * to avoid issues with the Date object being used by other libraries.
 * The goal of this class is to eventually replace the Date object. We
 * will be able to do this once we have removed all dependencies on
 * the old date.js library currently used in the project.
 */
class DeprecatedDate extends Date {
    static nowAsDate(): Date {
        return new Date();
    }
}

export default DeprecatedDate;
