import type React from 'react';
import { useEffect, useRef } from 'react';

/**
 * @returns a react reference containing a boolean indicating whether the component is still mounted.
 * Useful to prevent using setState after promises if the component has been unmounted.
 */
function useStillMounted(): React.RefObject<boolean> {
    const mounterRef = useRef(true);

    useEffect(() => {
        mounterRef.current = true;
        return () => {
            mounterRef.current = false;
        };
    }, []);

    return mounterRef;
}

export default useStillMounted;
