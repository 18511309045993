import React from 'react';
import styled, { css } from 'styled-components';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';
import type { InputSize } from '@tonkean/tui-theme/sizes';

const toggleSizesTheme = Theme.current.sizes.toggle;

const Container = styled.div<{ checked: boolean; disabled?: boolean; size: InputSize }>`
    display: flex;
    align-items: center;
    justify-content: ${({ checked }) => (checked ? 'flex-start' : 'flex-end')};
    height: ${({ size }) => toggleSizesTheme[size].height}px;
    width: ${({ size }) => toggleSizesTheme[size].width}px;
    background-color: ${({ checked }) => (checked ? Theme.colors.success : Theme.colors.gray_300)};
    border-radius: 100px;
    padding: ${({ size }) => toggleSizesTheme[size].padding}px;
    position: relative;
    transition:
        background-color 400ms ease-in-out,
        opacity 400ms ease-in-out;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
        `}
`;

const Label = styled.span<{ checked: boolean; size: InputSize }>`
    font-size: ${({ size }) => toggleSizesTheme[size].fontSize}px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    width: ${({ size }) =>
        toggleSizesTheme[size].width - toggleSizesTheme[size].padding * 2 - toggleSizesTheme[size].handleWidth}px;
    color: ${({ checked }) => (checked ? Theme.colors.basicBackground : Theme.colors.gray_500)};
`;

const Handle = styled.div<{ checked: boolean; size: InputSize; $focused?: boolean }>`
    position: absolute;
    top: ${({ size }) => toggleSizesTheme[size].padding}px;
    left: 0;
    bottom: ${({ size }) => toggleSizesTheme[size].padding}px;
    display: inline-block;
    height: ${({ size }) => toggleSizesTheme[size].handleHeight}px;
    width: ${({ size }) => toggleSizesTheme[size].handleWidth}px;
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    transform: translateX(
        ${({ size, checked }) =>
            checked
                ? toggleSizesTheme[size].width - toggleSizesTheme[size].handleWidth - toggleSizesTheme[size].padding
                : toggleSizesTheme[size].padding}px
    );
    transition: transform 200ms ease-in-out;

    ${styledFocus}
`;

export interface ToggleIconProps {
    checked?: boolean;
    disabled?: boolean;
    size: InputSize;
    label?: React.ReactNode;
    focused?: boolean;
}

const ToggleIcon: React.FC<ToggleIconProps> = ({ checked = true, disabled, size, label, focused }) => {
    return (
        <Container checked={checked} disabled={disabled} size={size}>
            {label && (
                <Label data-automation="toggle-icon-label" checked={checked} size={size}>
                    {label}
                </Label>
            )}
            <Handle checked={checked} size={size} $focused={focused} />
        </Container>
    );
};

export default ToggleIcon;
