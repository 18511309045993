import styled from 'styled-components';

import { Theme, FontSize } from '@tonkean/tui-theme';

const ZebraTableBodyCell = styled.td`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    height: 70px;
    max-height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;

    &:first-child {
        padding-left: 30px;
    }
`;

export default ZebraTableBodyCell;
