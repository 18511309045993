import styled from 'styled-components';

import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';

export const MODAL_Z_INDEX = 1050;

const ModalWrapper = styled.div`
    position: fixed;
    z-index: ${MODAL_Z_INDEX};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    ${childrenStyledFocus}
`;
ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
