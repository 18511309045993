import mainColorsPaletteConfiguration from './colors/mainColorsPaletteConfiguration';
import TooltipColor from './colors/TooltipColor';
import { PaletteType } from './PaletteType';
import AccordionSize from './sizes/AccordionSize';
import BreadcrumbsSize from './sizes/BreadcrumbsSize';
import ButtonSize from './sizes/ButtonSize';
import FontSize from './sizes/FontSize';
import InformationIconSize from './sizes/InformationIconSize';
import InputSize from './sizes/InputSize';
import StateMessageSize from './sizes/StateMessageSize';
import TableSize from './sizes/TableSize';
import TooltipSize from './sizes/TooltipSize';
import ButtonStyle from './styles/ButtonStyle';
import type { ThemeDefinition } from './ThemeDefinition';

/**
 * The Tonkean theme configuration
 */
export const themeConfiguration: ThemeDefinition = {
    palette: {
        [PaletteType.LIGHT]: {
            mainColors: mainColorsPaletteConfiguration[PaletteType.LIGHT],
            TUI: {
                notSelected: '#9CA6B2',
                selected: '#1FBDEA',
                notSelectedText: '#838A92',
                button: {
                    [ButtonStyle.FILLED]: {
                        backgroundColor: '#39B3D6',
                        backgroundHoverColor: '#1FBDEA',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#39B3D6',
                        borderHoverColor: '#1FBDEA',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.OUTLINED]: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: 'transparent',
                        backgroundDisabledColor: 'transparent',
                        textColor: '#39B3D6',
                        textHoverColor: '#20CDFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#39B3D6',
                        borderHoverColor: '#20CDFF',
                        borderDisabledColor: '#CDD2DB',
                    },
                    [ButtonStyle.CANCEL]: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: 'transparent',
                        backgroundDisabledColor: 'transparent',
                        textColor: '#5B636C',
                        textHoverColor: '#34393E',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#9CA6B2',
                        borderHoverColor: '#838A92',
                        borderDisabledColor: '#CDD2DB',
                    },
                    [ButtonStyle.PUBLISH_RELATED]: {
                        backgroundColor: '#12D4B8',
                        backgroundHoverColor: '#53CAB9',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#12D4B8',
                        borderHoverColor: '#53CAB9',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.WARNING]: {
                        backgroundColor: '#F0771E',
                        backgroundHoverColor: '#FF964A',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#F0771E',
                        borderHoverColor: '#FF964A',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.WARNING_OUTLINED]: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: 'transparent',
                        backgroundDisabledColor: 'transparent',
                        textColor: '#F0771E',
                        textHoverColor: '#FF964A',
                        textDisabledColor: '#E9ECF1',
                        borderColor: '#F0771E',
                        borderHoverColor: '#FF964A',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.DELETE]: {
                        backgroundColor: '#FF5454',
                        backgroundHoverColor: '#E64C4C',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#FF5454',
                        borderHoverColor: '#E64C4C',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.HIGHLIGHTED]: {
                        backgroundColor: '#2F84DC',
                        backgroundHoverColor: '#3799FF',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#2F84DC',
                        borderHoverColor: '#3799FF',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.PROMOTION]: {
                        backgroundColor: '#605CE4',
                        backgroundHoverColor: '#8793FF',
                        backgroundDisabledColor: '#E9ECF1',
                        textColor: '#FFFFFF',
                        textHoverColor: '#FFFFFF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#605CE4',
                        borderHoverColor: '#8793FF',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.PROMOTION_OUTLINED]: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: 'transparent',
                        backgroundDisabledColor: 'transparent',
                        textColor: '#605CE4',
                        textHoverColor: '#8793FF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#605CE4',
                        borderHoverColor: '#8793FF',
                        borderDisabledColor: '#E9ECF1',
                    },
                    [ButtonStyle.HIGHLIGHTED_OUTLINED]: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: 'transparent',
                        backgroundDisabledColor: 'transparent',
                        textColor: '#2F84DC',
                        textHoverColor: '#3799FF',
                        textDisabledColor: '#9CA6B2',
                        borderColor: '#2F84DC',
                        borderHoverColor: '#3799FF',
                        borderDisabledColor: '#9CA6B2',
                    },
                },
                tooltip: {
                    [TooltipColor.WHITE]: {
                        backgroundColor: '#FFFFFF',
                        borderWidth: 1,
                        borderColor: '#E9ECF1',
                        textColor: mainColorsPaletteConfiguration[PaletteType.LIGHT].gray_800,
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.09)',
                    },
                    [TooltipColor.DARK]: {
                        backgroundColor: '#333333',
                        borderWidth: 0,
                        borderColor: 'transparent',
                        textColor: '#FFFFFF',
                        boxShadow: 'none',
                    },
                },
            },
            general: {
                primary: '#3FA7B8',
                blueLight: '#E2EBF5',
                blueDark: '#396394',
                border: '#CDD2D8',
            },
            modal: {
                secondaryText: '#838A92',
            },
            regex: {
                expression: {
                    group: '#b3f072',
                    charsGroup: '#fac080',
                    backslash: '#aaaaaa',
                    regexOperator: '#89b6ff',
                    char: 'transparent',
                },
                groups: [
                    '#c6e3ff',
                    '#c5e89d',
                    '#f5aba4',
                    '#f9bf6d',
                    '#c1cbeb',
                    '#d7fde3',
                    '#e3ffac',
                    '#e88dee',
                    '#cfdd67',
                    '#f9abc0',
                    '#84d6ee',
                ],
            },
            tabs: {
                selected: '#2f84dc',
                selectedBackground: '#f3f8fd',
                railwayColor: '#e9ecf1',
            },
            dropdownSelect: {
                menuItem: {
                    hover: '#EDF7F9',
                },
                menu: {
                    border: 'lightgrey',
                    borderFocus: '#66afe9',
                },
            },
            formula: {
                wrapperBorder: '#D8D8D8',
                buttons: {
                    types: {
                        CONST: {
                            background: '#ddfade',
                            border: '#ddfade',
                            text: '#429850',
                            fadedBackground: '#eefcef',
                            fadedBorder: '#eefcef',
                            fadedText: '#a5cba9',
                        },
                        EMPTY: {
                            background: 'transparent',
                            border: '#4a1b79',
                            text: '#a4a4a4',
                            fadedBackground: 'transparent',
                            fadedBorder: '#a68fbd',
                            fadedText: '#d2d2d2',
                        },
                        TREE: {
                            background: '#ededeb',
                            border: '#ededeb',
                            text: '#4a1b79',
                            fadedBackground: '#f6f6f5',
                            fadedBorder: '#f6f6f5',
                            fadedText: '#a68fbd',
                        },
                        VARIABLE: {
                            background: '#cee3fd',
                            border: '#cee3fd',
                            text: '#1f5887',
                            fadedBackground: '#e7f1fe',
                            fadedBorder: '#e7f1fe',
                            fadedText: '#92acc3',
                        },
                        ARRAY: {
                            background: '#ffe0a3',
                            border: '#ffe0a3',
                            text: '#ff7700',
                            fadedBackground: '#fff0d2',
                            fadedBorder: '#fff0d2',
                            fadedText: '#ffbd91',
                        },
                    },
                    errorBorder: '#D10027',
                },
            },
            history: {
                workerRunsList: {
                    backgroundColor: '#FFFFFF',
                    borderColor: '#E9ECF1',
                    tableHeaderColor: '#9CA6B2',
                    dateTextColor: '#838A92',
                    textStatusRunning: '',
                    textStatusError: '#FF5454',
                    filterIcon: {
                        background: 'rgba(233, 236, 241, 0.6)',
                        backgroundHover: '#E9ECF1',
                        backgroundPressed: '#E9ECF1',
                        iconPressed: '#5B636C',
                    },
                },
                status: {
                    good: '#12D4B8',
                    bad: '#FF5454',
                    nothing: '#9CA6B2',
                    warn: '#F0771E',
                },
            },
            navigation: {
                floatingReturnTo: {
                    background: '#34393E',
                    backgroundHover: '#23262A',
                    innerBorder: '#5B636C',
                },
            },
            colorPicker: {
                HEX_FF5454: '#FF5454',
                HEX_F0611E: '#F0611E',
                HEX_FFCA7A: '#FFCA7A',
                HEX_12D4B8: '#12D4B8',
                HEX_20C5F5: '#20C5F5',
                HEX_2F84DC: '#2F84DC',
                HEX_605CE4: '#605CE4',
                HEX_D76BD9: '#D76BD9',
                HEX_FFFFFF: '#FFFFFF',
                HEX_5B636C: '#5B636C',
                HEX_34393E: '#34393E',
                HEX_39B3D6: '#39B3D6',
            },
            actionOutputType: {
                readOnlyLabel: {
                    background: '#f7f8fa',
                },
            },
            collect: {
                collectNowButton: {
                    fill: '#39b3d6',
                },
            },
        },
    },
    sizes: {
        checkbox: {
            SMALL: {
                size: 12,
                padding: 1,
            },
            MEDIUM: {
                size: 14,
                padding: 1,
            },
            MEDIUM_LARGE: {
                size: 16,
                padding: 2,
            },
            LARGE: {
                size: 18,
                padding: 2,
            },
            XLARGE: {
                size: 24,
                padding: 5,
            },
        },
        toggle: {
            [InputSize.SMALL]: {
                height: 14,
                width: 24,
                padding: 2,
                handleHeight: 10,
                handleWidth: 10,
                fontSize: 8,
            },
            [InputSize.MEDIUM]: {
                height: 18,
                width: 36,
                padding: 2,
                handleHeight: 14,
                handleWidth: 14,
                fontSize: 8,
            },
            [InputSize.XMEDIUM_LARGE]: {
                height: 18,
                width: 36,
                padding: 2,
                handleHeight: 14,
                handleWidth: 14,
                fontSize: 8,
            },
            [InputSize.LARGE]: {
                height: 24,
                width: 46,
                padding: 2,
                handleHeight: 20,
                handleWidth: 20,
                fontSize: 8,
            },
            [InputSize.XLARGE]: {
                height: 24,
                width: 46,
                padding: 2,
                handleHeight: 20,
                handleWidth: 20,
                fontSize: 8,
            },
        },
        input: {
            [InputSize.MEDIUM]: {
                height: 26,
                lineHeight: 14,
                fontSize: 12,
                paddingRightLeft: 8,
                paddingTopBottom: 0,
                radius: 3,
            },
            [InputSize.MEDIUM_LARGE]: {
                height: 30,
                lineHeight: 14,
                fontSize: 12,
                paddingRightLeft: 8,
                paddingTopBottom: 0,
                radius: 3,
            },
            [InputSize.XMEDIUM_LARGE]: {
                height: 32,
                lineHeight: 14,
                fontSize: 12,
                paddingRightLeft: 8,
                paddingTopBottom: 0,
                radius: 3,
            },
            [InputSize.LARGE]: {
                height: 40,
                lineHeight: 16,
                fontSize: 14,
                paddingRightLeft: 16,
                paddingTopBottom: 12,
                radius: 6,
            },
            [InputSize.XLARGE]: {
                height: 36,
                lineHeight: 16,
                fontSize: 16,
                paddingRightLeft: 10,
                paddingTopBottom: 0,
                radius: 6,
            },
        },
        inputButton: {
            [InputSize.MEDIUM]: { height: 10, width: 14 },
            [InputSize.MEDIUM_LARGE]: { height: 12, width: 15.5 },
            [InputSize.XMEDIUM_LARGE]: { height: 13, width: 17 },
            [InputSize.LARGE]: { height: 14, width: 18.5 },
            [InputSize.XLARGE]: { height: 15, width: 20 },
        },
        tooltip: {
            [TooltipSize.MEDIUM]: {
                arrowSize: 10,
                paddingRightLeft: 12,
                paddingTopBottom: 12,
            },
            [TooltipSize.SMALL]: {
                arrowSize: 6,
                paddingRightLeft: 10,
                paddingTopBottom: 5,
            },
        },
        button: {
            [ButtonSize.HUGE]: {
                borderWidth: 1,
                height: 40,
                paddingRightLeft: 20,
                fontSize: FontSize.MEDIUM_14,
                minWidth: 200,
                icon: {
                    size: 14,
                    marginRight: 6,
                },
            },
            [ButtonSize.BIG]: {
                borderWidth: 1,
                height: 30,
                paddingRightLeft: 20,
                fontSize: FontSize.MEDIUM_14,
                minWidth: 90,
                icon: {
                    size: 12,
                    marginRight: 6,
                },
            },
            [ButtonSize.XMEDIUM_BIG]: {
                borderWidth: 1,
                height: 30,
                paddingRightLeft: 16,
                fontSize: FontSize.SMALL_12,
                minWidth: 60,
                icon: {
                    size: 10,
                    marginRight: 4,
                },
            },
            [ButtonSize.MEDIUM_BIG]: {
                borderWidth: 1,
                height: 30,
                paddingRightLeft: 16,
                fontSize: FontSize.SMALL_12,
                minWidth: 60,
                icon: {
                    size: 10,
                    marginRight: 4,
                },
            },
            [ButtonSize.MEDIUM]: {
                borderWidth: 1,
                height: 26,
                paddingRightLeft: 15,
                fontSize: FontSize.SMALL_12,
                minWidth: 60,
                icon: {
                    size: 10,
                    marginRight: 4,
                },
            },
            [ButtonSize.MEDIUM_SNUG]: {
                borderWidth: 1,
                height: 26,
                paddingRightLeft: 8,
                fontSize: FontSize.SMALL_12,
                minWidth: 0,
                icon: {
                    size: 10,
                    marginRight: 4,
                },
            },
            [ButtonSize.SMALL]: {
                borderWidth: 1,
                height: 20,
                paddingRightLeft: 10,
                fontSize: FontSize.SMALL_12,
                minWidth: 60,
                icon: {
                    size: 10,
                    marginRight: 4,
                },
            },
        },
        formBuilder: {
            standardMarginTop: 30,
        },
        closeButton: {
            [ButtonSize.SMALL]: {
                width: 15,
                height: 15,
                svg: {
                    width: 9,
                    height: 9,
                },
            },
            [ButtonSize.MEDIUM_SNUG]: {
                width: 22,
                height: 22,
                svg: {
                    width: 12,
                    height: 12,
                },
            },
            [ButtonSize.MEDIUM]: {
                width: 22,
                height: 22,
                svg: {
                    width: 12,
                    height: 12,
                },
            },
            [ButtonSize.MEDIUM_BIG]: {
                width: 22,
                height: 22,
                svg: {
                    width: 12,
                    height: 12,
                },
            },
            [ButtonSize.XMEDIUM_BIG]: {
                width: 22,
                height: 22,
                svg: {
                    width: 12,
                    height: 12,
                },
            },
            [ButtonSize.BIG]: {
                width: 28,
                height: 28,
                svg: {
                    width: 12,
                    height: 12,
                },
            },
            [ButtonSize.HUGE]: {
                width: 28,
                height: 28,
                svg: {
                    width: 16,
                    height: 16,
                },
            },
        },
        informationTooltip: {
            [InformationIconSize.SMALL]: {
                width: 10,
            },
            [InformationIconSize.MEDIUM]: {
                width: 12,
            },
        },
        accordion: {
            [AccordionSize.XSMALL]: {
                height: 40,
            },
            [AccordionSize.SMALL]: {
                height: 50,
            },
            [AccordionSize.MEDIUM]: {
                height: 60,
            },
            [AccordionSize.LARGE]: {
                height: 70,
            },
        },
        table: {
            [TableSize.SMALL]: {
                headerHeight: 30,
                cellHeight: 44,
            },
            [TableSize.MEDIUM]: {
                headerHeight: 36,
                cellHeight: 60,
            },
        },
        stateMessage: {
            [StateMessageSize.SMALL]: {
                description: {
                    marginTop: 15,
                    lineHeight: 12,
                },
                secondary: {
                    marginTop: 9,
                    lineHeight: 7,
                },
            },
            [StateMessageSize.MEDIUM]: {
                description: {
                    marginTop: 30,
                    lineHeight: 23,
                },
                secondary: {
                    marginTop: 10,
                    lineHeight: 14,
                },
            },
        },
        breadcrumbs: {
            [BreadcrumbsSize.MEDIUM]: {
                fontSize: FontSize.MEDIUM_14,
            },
            [BreadcrumbsSize.SMALL]: {
                fontSize: FontSize.SMALL_12,
            },
        },
    },
};
