import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../TUI';

import type { PersonAvatar } from '@tonkean/tonkean-entities';
import { getPersonInitials } from '@tonkean/utils';

const Avatar = styled.div<{ $avatarUrl?: string; $color?: string }>`
    width: 32px;
    height: 32px;
    color: white;
    background-color: ${(props) => (props.$color ? props.$color : '#00371f')};
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    background-image: url(${(props) => (props.$avatarUrl ? props.$avatarUrl : '')});
    background-size: cover;
    border-radius: 50%;
    border: 1px solid #fff;
`;

const OuterBorder = styled.div<{ $borderColor?: string; $showBorder?: boolean }>`
    border: 2px solid ${(props) => (props.$borderColor && props.$showBorder ? props.$borderColor : '#fff')};
    border-radius: 50%;
`;

interface Props {
    person: PersonAvatar;
    disableTooltip?: boolean;
    themeColor?: string;
    showBorder?: boolean;
}

const PersonAvatarDisplay: React.FC<Props> = ({ person, disableTooltip, themeColor, showBorder }) => {
    return (
        <Tooltip data-automation="person-tooltip" content={person.name} placement="bottom" disabled={disableTooltip}>
            <OuterBorder data-automation="person-border" $borderColor={themeColor} $showBorder={showBorder}>
                {person.avatarUrl ? (
                    <Avatar data-automation="person-avatar" $avatarUrl={person.avatarUrl} $color={themeColor} />
                ) : (
                    <Avatar $color={themeColor} data-automation="person-initials">
                        {person.name ? getPersonInitials(person.name) : ''}
                    </Avatar>
                )}
            </OuterBorder>
        </Tooltip>
    );
};

export default PersonAvatarDisplay;
