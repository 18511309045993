/**
 * It will convert a single value to an array with the single value as the first element. If the value is an array,
 * it will return itself. It's useful when a field might be an array but for simplicity, a single value is also allowed.
 *
 * @example
 * class Book {
 *     public author: string | string[];
 *
 *     // Check if someone is the author if the book
 *     public isAuthor(authorName: string) {
 *         return toArray(this.author).includes(authorName);
 *     }
 * }
 *
 * const book1 = new Book();
 * book1.author = 'John Doe';
 * book1.isAuthor('Vlad Gincher') // False
 * book1.isAuthor('John Doe') // True
 *
 * const book2 = new Book();
 * book2.author = ["Ploni Almoni", "J.K. Rowling"];
 * book1.isAuthor('Vlad Gincher') // False
 * book1.isAuthor('J.K. Rowling') // True
 *
 * @param arrayOrSingle - either an array of values or a single value
 * @return an array of same values
 */
export function toArray<T>(arrayOrSingle: T[] | T): T[] {
    if (Array.isArray(arrayOrSingle)) {
        return arrayOrSingle;
    }
    return [arrayOrSingle];
}
