enum PresetItemInterfaceWidgetType {
    ALL_FIELDS = 'ALL_FIELDS',
    COMMENTS = 'COMMENTS',
    TIMELINE = 'TIMELINE',
    RICH_TEXT = 'RICH_TEXT',
    ACTIONS = 'ACTIONS',
    INNER_ITEMS = 'INNER_ITEMS',
    LINE_ITEMS = 'LINE_ITEMS',
    DETAILED_ITEMS = 'DETAILED_ITEMS',
    MATCHED_ENTITY = 'MATCHED_ENTITY',
    STATUS_UPDATE = 'STATUS_UPDATE',
    BASIC_FIELDS = 'BASIC_FIELDS',
    GLOBAL_FIELDS = 'GLOBAL_FIELDS',
    AGGREGATION_FIELDS = 'AGGREGATION_FIELDS',
    FORMULA_FIELDS = 'FORMULA_FIELDS',
    MANUAL_FIELDS = 'MANUAL_FIELDS',
    FIELD_MANAGEMENT = 'FIELD_MANAGEMENT',
    EMPTY_FIELDS = 'EMPTY_FIELDS',
    STATUS_PROGRESS_BAR = 'STATUS_PROGRESS_BAR',
    FIELD_CHART = 'FIELD_CHART',
    IMAGE = 'IMAGE',
    EMBED = 'EMBED',
    AI_CHAT = 'AI_CHAT',
    QUESTION_TEXT = 'QUESTION_TEXT',
    QUESTION_NUMBER = 'QUESTION_NUMBER',
    QUESTION_DATE = 'QUESTION_DATE',
    QUESTION_SELECT = 'QUESTION_SELECT',
    QUESTION_LIST = 'QUESTION_LIST',
    UPLOAD = 'UPLOAD',
    PROCESS_MAPPER = 'PROCESS_MAPPER',
    SEARCH = 'SEARCH',
    INCLUDE_INTERFACE = 'INCLUDE_INTERFACE',
    SEPARATOR = 'SEPARATOR',
    RICH_TEXT_EDITOR_INPUT = 'RICH_TEXT_EDITOR_INPUT',
    MODULE_ITEMS = 'MODULE_ITEMS',
}

export default PresetItemInterfaceWidgetType;
