import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Theme, type Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export const LoadingWrapper = styled.div<{ centered?: boolean; fullHeight?: boolean }>`
    ${({ centered }) =>
        centered &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}
    ${({ fullHeight }) =>
        fullHeight &&
        css`
            height: 100%;
        `}
`;

const loadingRotateAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div<{
    $large: boolean;
    $white: boolean;
    $color: Color | undefined;
    $inheritColor: boolean;
    $thin: boolean;
    $invisible?: boolean;
}>`
    animation-name: ${loadingRotateAnimation};
    animation-iteration-count: infinite;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    width: 1em;
    height: 1em;
    border-style: solid;
    border-width: ${({ $thin }) => ($thin ? 1 : 3)}px;
    border-color: ${({ $inheritColor, $white, $color }) => {
        if ($inheritColor) {
            return undefined;
        }
        if ($white) {
            return Theme.colors.basicBackground;
        }
        if ($color) {
            return $color;
        }
        return Theme.current.palette.general.primary;
    }};
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;

    ${({ $large }) =>
        $large &&
        css`
            margin: 40px;
            font-size: 80px;
            border-width: 10px;
        `}

    ${({ $invisible }) =>
        $invisible &&
        css`
            display: none;
        `}
`;

interface Props extends StyledComponentsSupportProps {
    centered?: boolean;
    fullHeight?: boolean;
    large?: boolean;
    white?: boolean;
    color?: Color;
    inheritColor?: boolean;
    thin?: boolean;
    invisible?: boolean;
}

const LoadingCircle: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    {
        className,
        centered,
        fullHeight,
        color,
        inheritColor = false,
        thin = false,
        large = false,
        white = false,
        invisible = false,
    },
    ref,
) => {
    const loadingCircle = (
        <Loading
            data-automation="loading-circle"
            $large={large}
            $white={white}
            className={className}
            ref={ref}
            $color={color}
            $inheritColor={inheritColor}
            $thin={thin}
            $invisible={invisible}
        />
    );

    if (centered || fullHeight) {
        return (
            <LoadingWrapper data-automation="loading-circle" centered={centered} fullHeight={fullHeight}>
                {loadingCircle}
            </LoadingWrapper>
        );
    }

    return loadingCircle;
};

export default React.forwardRef(LoadingCircle);
