import React from 'react';
import styled from 'styled-components';

import type ClickableProps from './ClickableProps';
import DisableableButton from '../Button/DisableableButton';

import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import styledFocus from '@tonkean/tui-basic/styledFocus';

const StyledElement = styled.button`
    background: transparent;
    border: none;
    display: inline-block;
    padding: 0;
    text-align: unset;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
        color: unset;
    }

    ${styledFocus}
`;

function getComponentProps({
    state,
    params,
    dontChangeState,
    onClick,
    openInNewTab,
    disabled,
    href,
    options,
    buttonAsDiv,
    buttonAsLabel,
    replace,
    type,
}: ClickableProps) {
    const isStateLink = !!state || !!params;
    const isDisabledStateLink = isStateLink && disabled;
    const isActiveStateLink = isStateLink && !disabled;
    const typeSubmit = type === 'submit';

    const isAnchor = !isStateLink && !!href;
    const isDisabledAnchor = isAnchor && disabled;
    const isActiveAnchor = isAnchor && !disabled;

    const isButton = !isStateLink && !isAnchor && (!!onClick || typeSubmit);

    if (buttonAsLabel) {
        return {
            as: 'label',
            type: 'label',
            onClick,
            disabled,
            buttonAsDiv,
        } as const;
    }

    if (isButton || isDisabledStateLink || isDisabledAnchor) {
        return {
            as: DisableableButton,
            type: type || 'button',
            onClick,
            disabled,
            buttonAsDiv,
        } as const;
    }

    if (isActiveStateLink) {
        return {
            as: RouterLink,
            path: state,
            query: params,
            replace: replace ?? options?.location === 'replace',
            onClick,
            openInNewTab,
            dontChangeState,
            options,
        } as const;
    }

    if (isActiveAnchor) {
        return {
            as: 'a',
            href,
            onClick,
            ...(openInNewTab ? { target: '_blank' } : {}),
        } as const;
    }

    return {
        as: 'div',
    } as const;
}

export type FullClickableProps = Omit<React.HTMLAttributes<HTMLElement>, keyof ClickableProps> & ClickableProps;

const Clickable: React.ForwardRefRenderFunction<HTMLElement, FullClickableProps> = (
    {
        state,
        params,
        onClick,
        href,
        dontChangeState,
        openInNewTab = false,
        disabled = false,
        options,
        buttonAsDiv,
        buttonAsLabel,
        replace,
        type,
        ...props
    },
    ref,
) => {
    const clickableElementProps = getComponentProps({
        state,
        params,
        dontChangeState,
        onClick,
        href,
        openInNewTab,
        disabled,
        options,
        buttonAsDiv,
        buttonAsLabel,
        replace,
        type,
    });

    return <StyledElement {...(props as any)} {...clickableElementProps} ref={ref} />;
};

export default React.forwardRef(Clickable);
