import React from 'react';
import styled from 'styled-components';

import { Theme, FontSize } from '@tonkean/tui-theme';
import { StateMessageSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div`
    flex-grow: 1;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div<{ titleColor?: string }>`
    color: ${({ titleColor }) => titleColor || Theme.colors.gray_500};
`;

const Description = styled.div<{ size: StateMessageSize }>`
    margin-top: ${({ size }) => Theme.sizes.stateMessage[size].description.marginTop}px;
    line-height: ${({ size }) => Theme.sizes.stateMessage[size].description.lineHeight}px;
    text-align: center;
    font-size: ${FontSize.XLARGE_18};
    color: ${Theme.colors.gray_500};
`;

const Secondary = styled.div<{ size: StateMessageSize; secondaryColor?: string }>`
    margin-top: ${({ size }) => Theme.sizes.stateMessage[size].secondary.marginTop}px;
    line-height: ${({ size }) => Theme.sizes.stateMessage[size].secondary.lineHeight}px;
    font-size: ${FontSize.SMALL_12};
    color: ${({ secondaryColor }) => (secondaryColor ? secondaryColor : Theme.colors.gray_800)};
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    /** If title is set, the children will have smaller font size. If not, the children will have the styling of the title. */
    title?: React.ReactNode;
    icon: React.ReactNode;
    size?: StateMessageSize;
    titleColor?: string;
    secondaryColor?: string;
}

const StateMessage: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    className,
    dataAutomation,
    icon,
    title,
    size = StateMessageSize.MEDIUM,
    titleColor,
    secondaryColor,
}) => {
    return (
        <Wrapper data-automation={dataAutomation} className={className}>
            {icon}

            <Description data-automation="state-message-empty-state-error" size={size}>
                {title ? (
                    <Title titleColor={titleColor}>
                        {title}
                        <Secondary size={size} secondaryColor={secondaryColor}>
                            {children}
                        </Secondary>
                    </Title>
                ) : (
                    children
                )}
            </Description>
        </Wrapper>
    );
};

export default StateMessage;
