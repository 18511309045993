import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
`;

const StyledProgress = styled.progress`
    /* Reset general styling */
    appearance: none;

    /* Reset WebKit (Chrome, Safari, Opera) styling */
    -webkit-appearance: none;

    /* Reset FireFox styling */
    -moz-appearance: none;
    border: none;

    &::-webkit-progress-value {
        border-radius: 5px;
        background-color: ${Theme.colors.success};
    }

    &::-moz-progress-bar {
        background-color: ${Theme.colors.success};
    }

    &::-webkit-progress-bar {
        border-radius: 5px;
        background-color: ${Theme.colors.gray_300};
    }

    /* For IE10 */
    color: ${Theme.colors.success};

    height: 4px;
`;

const Percent = styled.span`
    margin-left: 10px;
    color: ${Theme.colors.success};
    min-width: 30px;
`;

interface Props extends React.ProgressHTMLAttributes<HTMLProgressElement> {
    showProgressPercent?: boolean;
    value: number;
    max: number;
}

const Progress: React.FC<Props> = ({ id, value, max, showProgressPercent = true }) => {
    return (
        <Wrapper>
            <StyledProgress id={`progress-${id}`} value={value} max={max} />
            {showProgressPercent && <Percent>{Math.floor((value / max) * 100)}%</Percent>}
        </Wrapper>
    );
};

export default Progress;
