import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';
import styled, { css, keyframes } from 'styled-components';

import DragAndDropFilesPreview from './DragAndDropFilesPreview';
import DragAndDropFilesSizeType from './DragAndDropFilesSizeType';
import DragAndDropValidations from './DragAndDropValidations';
import LoadingCircle from '../../LoadingCircle';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const DraggableWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DraggableContainer = styled.div<{
    $focused?: boolean;
    $sizeType: DragAndDropFilesSizeType;
    $padding?: string;
    $isError?: boolean;
}>`
    width: ${({ $sizeType }) => ($sizeType === DragAndDropFilesSizeType.FLUID ? '100%' : '475px')};
    overflow: auto;
    height: ${({ $sizeType }) => ($sizeType === DragAndDropFilesSizeType.FLUID ? '100%' : '200px')};
    background-color: ${Theme.colors.brightestGray};
    border: 1px dashed ${({ $isError }) => ($isError ? Theme.colors.error : Theme.colors.gray_400)};
    border-radius: 6px;

    ${({ $focused }) =>
        $focused &&
        css`
            border: 1px dashed ${Theme.current.palette.colorPicker.HEX_2F84DC};
        `};

    ${styledFocus}

    ${({ $padding }) =>
        $padding &&
        css`
            padding: ${$padding};
        `};
`;

const BrowseFilesButton = styled.button`
    margin-top: 10px;
    color: ${Theme.colors.primary};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
    ${styledFocus}
`;

const DragMainText = styled.div`
    color: ${Theme.colors.gray_600};
    font-weight: 400;
    font-size: ${FontSize.LARGE_16};
    margin-top: 20px;
`;

const LimitationsDescriptionText = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.XSMALL_10};
    margin-top: 10px;
    margin-bottom: 10px;
`;

const DragSubText = styled.div`
    margin-top: 10px;
    color: ${Theme.colors.gray_400};
    font-style: italic;
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
`;

const DraggableEmptyContainer = styled.div`
    padding-top: 40px;
    text-align: center;
`;

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(10deg);
    }
`;

const LogoContainer = styled.div<{ isHover: boolean }>`
    ${({ isHover }) =>
        isHover &&
        css`
            animation: ${rotate} 0.2s ease-out forwards;
        `};
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BrowseFilesButtonContainer = styled.div`
    padding-top: 10px;
    text-align: center;
`;

interface Props {
    // Main text to show inside the drag zone
    mainText: string;
    // When no files exist in the drop zone this svg will be shown
    dragAndDropLogo: React.ReactNode;
    // If empty all files will be allowed
    acceptedFileTypes?: string | string[];
    // When dragging image type file should the component show a minimize copy of the photo?
    isImageUpload: boolean;
    // Maximum files in DropZone
    maximumFiles?: number;
    // Trigger after file dropped inside the zone will bring only the accepted files
    onDragged?: (allAcceptedFiles: TonkeanUploadedFile[]) => void;
    // The existing file list state you should provide with useState()
    existingFiles: TonkeanUploadedFile[];
    // *NOT RECOMMENDED* to use outside of this component because if you do u don't have the validation that in 'onDrop'
    setExistingFiles: (dragAndDropFile: TonkeanUploadedFile[]) => void;
    // set error state
    setErrorText: (err?: string) => void;
    maxFileSizeMB: number;
    // Is the component in preview mode and should disable clicks and upload
    isInPreview?: boolean;
    limitationsDescriptionText?: string;
    isLoading?: boolean;
    sizeType?: DragAndDropFilesSizeType;
    padding?: string;
    isError?: boolean;
    dataAutomation?: string;
}

const DragAndDropFiles: React.FC<Props> = ({
    mainText,
    dragAndDropLogo,
    acceptedFileTypes,
    isImageUpload,
    maximumFiles,
    onDragged,
    existingFiles,
    setExistingFiles,
    setErrorText,
    maxFileSizeMB,
    isInPreview = false,
    limitationsDescriptionText,
    isLoading,
    sizeType = DragAndDropFilesSizeType.FIXED,
    padding,
    isError,
    dataAutomation,
}) => {
    const deleteFile = (file: TonkeanUploadedFile) => {
        setExistingFiles(existingFiles.filter((existingFile) => existingFile !== file));
    };

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (isInPreview) {
                return;
            }

            const dropzoneError = fileRejections.length > 0 ? 'Unsupported files have been removed.' : undefined;

            if (!acceptedFiles.length) {
                setErrorText(dropzoneError);
                return;
            }

            const addedFiles: TonkeanUploadedFile[] = acceptedFiles.map((file) => ({
                blob: file,
                id: DragAndDropValidations.getNextSequentialIdentifier(),
                name: file.name,
                size: file.size,
                url: undefined,
            }));

            const allowedFiles = DragAndDropValidations.onFilesAddedGetNewFiles(
                existingFiles,
                addedFiles,
                maximumFiles,
                maxFileSizeMB,
            );

            // Join the errors
            const errorText = [dropzoneError, ...allowedFiles.errors].filter(Boolean).join(', ') || undefined;
            setErrorText(errorText);

            setExistingFiles(allowedFiles.files);
            onDragged?.(allowedFiles.files);
        },
        [isInPreview, existingFiles, maximumFiles, maxFileSizeMB, setErrorText, setExistingFiles, onDragged],
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        accept: acceptedFileTypes,
    });

    const isFileExists = existingFiles.length > 0;
    return (
        <DraggableWrapper>
            <DraggableContainer
                $focused={isDragActive}
                {...getRootProps()}
                $sizeType={sizeType}
                $padding={padding}
                $isError={isError}
            >
                <input {...getInputProps()} />
                {!isFileExists && (
                    <DraggableEmptyContainer>
                        <LogoContainer isHover={isDragActive && !isInPreview}>{dragAndDropLogo}</LogoContainer>

                        {(!isDragActive || isInPreview) && (
                            <>
                                <DragMainText>{mainText}</DragMainText>
                            </>
                        )}

                        {!isInPreview && isDragActive && <DragMainText>Drag in here</DragMainText>}
                    </DraggableEmptyContainer>
                )}

                {isFileExists && (
                    <DragAndDropFilesPreview
                        existingFiles={existingFiles}
                        isImageUpload={isImageUpload}
                        onDelete={deleteFile}
                        isInPreview={isInPreview}
                    />
                )}

                <LimitationsDescriptionText>{limitationsDescriptionText}</LimitationsDescriptionText>
                {isLoading && (
                    <LoadingWrapper>
                        <LoadingCircle />
                    </LoadingWrapper>
                )}
            </DraggableContainer>
            <BrowseFilesButtonContainer>
                <BrowseFilesButton
                    className="inline-button mod-outline"
                    type="button"
                    onClick={open}
                    disabled={isInPreview}
                    data-automation={`${dataAutomation}-browse-files-button`}
                >
                    Browse Files
                </BrowseFilesButton>
            </BrowseFilesButtonContainer>
        </DraggableWrapper>
    );
};

export default DragAndDropFiles;
