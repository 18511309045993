import type { WorkerRunLogicStatus } from './WorkerRunLogicStatus';

export const workerRunLogicStatusToDataAutomationSuffix: Record<WorkerRunLogicStatus, string> = {
    MONITORING: 'monitoring',
    INNER_TRIGGER_ACTIVATED: 'inner-trigger-activated',
    WASNT_ACTIVATED: 'wasnt-activated',
    WORKER_OFF: 'worker-off',
    OFF: 'off',
    SKIPPED: 'skipped',
    CONDITION_NOT_MET: 'condition-not-met',
    ERROR: 'error',
    PENDING: 'pending',
    SUCCESS: 'success',
    PARTIAL: 'partial',
};
