import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import type BreadcrumbsSettings from './BreadcrumbsSettings';
import TextEllipsis from '../../TextEllipsis';
import Chevron, { ChevronDirection } from '../Chevron';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';

import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import type { BreadcrumbsSize } from '@tonkean/tui-theme/sizes';

const StyledClickableLink = styled(ClickableLink)<{ $isBold?: boolean; $size: BreadcrumbsSize; $white: boolean }>`
    display: flex;
    align-items: center;

    line-height: 20px;
    font-size: ${({ $size }) => Theme.sizes.breadcrumbs[$size].fontSize};
    font-weight: ${({ $isBold }) => ($isBold ? 500 : 400)};

    &,
    &:hover,
    &:focus {
        color: ${({ $isBold, $white }) => {
            if ($white) {
                return Theme.colors.basicBackground;
            }
            return $isBold ? Theme.colors.gray_800 : Theme.colors.gray_500;
        }};
    }
`;

const PrefixIconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 6px;
`;

const DisplayName = styled.div<{ capitalize: boolean }>`
    ${({ capitalize }) =>
        capitalize &&
        css`
            text-transform: capitalize;
        `}
`;

const StyledChevron = styled(Chevron)`
    margin-left: 4px;
`;

interface Props {
    breadcrumb: BreadcrumbsSettings;
    size: BreadcrumbsSize;
    white: boolean;
    bold: boolean;
    disabled?: boolean;
}

/**
 * Component for creating custom breadcrumbs.
 * A single BreadCrumb have 3 states:
 *      1. hardcoded text.
 *      2. clickable button with all the ClickableProps.
 *      3. onClick opens a menu with other options dropdown.
 * Important: Note that you can't use 2 and 3 together.
 * Therefore you can't implement the clickableProps with the otherOptions props!!
 */
const SingleBreadcrumb: React.FC<Props> = ({ breadcrumb, size, white, bold, disabled = false }) => {
    const [openedOtherOptionSelectId, setOpenedOtherOptionSelectId] = useState<string | undefined>();

    const breadCrumbsNode =
        typeof breadcrumb === 'string'
            ? {
                  id: breadcrumb,
                  displayName: breadcrumb,
              }
            : breadcrumb;

    return (
        <StyledClickableLink
            $white={white}
            $isBold={!!breadCrumbsNode.otherOptionsSettings || bold}
            $size={size}
            {...breadCrumbsNode.clickable}
            onClick={
                breadCrumbsNode.otherOptionsSettings?.otherOptions?.length
                    ? () => setOpenedOtherOptionSelectId(breadCrumbsNode.id)
                    : breadCrumbsNode.clickable?.onClick
            }
            disabled={disabled}
            data-automation={`single-breadcrumb-${breadCrumbsNode.id}`}
        >
            {breadCrumbsNode.prefixIcon && <PrefixIconWrapper>{breadCrumbsNode.prefixIcon}</PrefixIconWrapper>}

            <DisplayName capitalize={!!breadCrumbsNode.capitalize}>
                <TextEllipsis numberOfLines={1} tooltip>
                    {breadCrumbsNode.displayName}
                </TextEllipsis>
            </DisplayName>

            {breadCrumbsNode.otherOptionsSettings && breadCrumbsNode.otherOptionsSettings.otherOptions.length > 0 && (
                <Menu
                    menuItems={breadCrumbsNode.otherOptionsSettings.otherOptions.map((otherOption) => (
                        <MenuItem
                            key={otherOption.id}
                            onClick={() => breadCrumbsNode.otherOptionsSettings?.onOtherOptionClicked(otherOption.id)}
                        >
                            {otherOption.displayName}
                        </MenuItem>
                    ))}
                    show={!!openedOtherOptionSelectId}
                    onClose={() => setOpenedOtherOptionSelectId(undefined)}
                >
                    <StyledChevron
                        key={breadCrumbsNode.id}
                        direction={
                            openedOtherOptionSelectId === breadCrumbsNode.id
                                ? ChevronDirection.UP
                                : ChevronDirection.DOWN
                        }
                    />
                </Menu>
            )}
        </StyledClickableLink>
    );
};

export default SingleBreadcrumb;
