import { css } from 'styled-components';

export const focusOutlineStyle = [
    '5px auto rgba(59, 153, 252, 0.7)',
    '5px auto -moz-mac-focusring',
    '5px auto -webkit-focus-ring-color',
];

export const focusOutlineStyleCss = focusOutlineStyle.map((outlineStyle) => `outline: ${outlineStyle};`).join('');

const styledFocus = css<Record<string, any>>`
    // Handle fake focus (the focused prop). Show if the prop value is true and it's a keyboard focus.
    ${({ focused, $focused, theme }) =>
        focused || $focused || (focused && theme.isKeyboardFocus && focusOutlineStyleCss)};

    :focus {
        // If it's not a keyboard focus, hide the outline focus ring.
        ${({ theme }) =>
            !theme.isKeyboardFocus &&
            css`
                outline: none;
            `}
    }
`;

export const childrenStyledFocus = css<Record<string, any>>`
    &,
    a,
    button,
    input,
    textarea,
    [tabindex] {
        ${styledFocus}
    }
`;

export default styledFocus;
