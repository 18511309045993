export class streamFetchUtils {
    public async fetchAndParse(
        response: any,
        onReadFunction: (...args: any[]) => any,
        onDoneFunction?: (...args: any[]) => any,
        splitBy: string = '\n',
    ) {
        if (!response) {
            return;
        }
        // Get the ReadableStream from the response
        const stream = response.body;

        // Create a new TextDecoder to decode the stream data as it arrives
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let finalResponse = '';

        // Check if the stream is already locked to a reader
        if (stream.locked) {
            console.log('stream is already locked');
            return;
        }
        // Create a reader to read data from the stream
        const reader = stream.getReader();

        // Define a function to read and process data from the stream
        async function read() {
            // Read data from the stream
            const { value, done } = await reader.read();

            // If the stream is done, we call to onDone and stop
            if (done) {
                if (onDoneFunction) {
                    onDoneFunction(finalResponse);
                }
                return;
            }

            // Decode the stream data as text
            const text = decoder.decode(value, { stream: true });
            buffer += text;

            // Check if the buffer contains multiple chunks
            const chunks = buffer.split(splitBy); // Assuming chunks are separated by a newline character

            if (chunks.length > 1) {
                // Process all chunks except the last one
                for (let i = 0; i < chunks.length - 1; i++) {
                    const chunk = chunks[i];

                    // Process the JSON data as needed
                    const data = 'data: "';
                    const index = chunk?.indexOf(data) || 0;
                    const length = data.length;
                    const result = chunk?.slice(index + length, -1) || '';

                    const trimmedResult = result.replaceAll('\\n', '\n');
                    onReadFunction((currentVal) => currentVal + trimmedResult);
                    finalResponse += trimmedResult;
                }

                // Update the buffer with the last chunk
                buffer = chunks?.[chunks.length - 1] || '';
            }

            // Continue reading from the stream
            return read();
        }

        // Start reading from the stream
        return read();
    }
}

const streamUtils = new streamFetchUtils();
export default streamUtils;
