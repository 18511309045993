enum PreviewFindWordsHighlightType {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
    EXPECTED_VALUE = 'EXPECTED_VALUE',
    ARBITRARY_ANCHOR = 'ARBITRARY_ANCHOR',
    NLP_POSITIVE_EXAMPLE = 'NLP_POSITIVE_EXAMPLE',
    NLP_NEGATIVE_EXAMPLE = 'NLP_NEGATIVE_EXAMPLE',
}

export default PreviewFindWordsHighlightType;
