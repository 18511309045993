import type EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition from '../ProjectIntegrationAction/EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition';
import type TonkeanExpressionDefinition from '../TonkeanExpressionDefinition';
import type TonkeanId from '../TonkeanId';
import type TonkeanType from '../TonkeanType';

export enum EnterpriseComponentAuthorizationType {
    NONE = 'NONE',
    API_KEY = 'API_KEY',
    OAUTH2 = 'OAUTH2',
}

interface BaseEnterpriseComponentAuthorization {
    type: EnterpriseComponentAuthorizationType;
    baseUrl: TonkeanExpressionDefinition;
    certificationType: EnterpriseComponentCertificateType;
    integrationRequestInterceptorParameters: IntegrationRequestInterceptorParameter[];
    certificationDetails: CertificationDetails;
}

export interface IntegrationRequestInterceptorParameter {
    id: string;
    key: TonkeanExpressionDefinition;
    value: TonkeanExpressionDefinition;
    addTo: IntegrationRequestInterceptorOption;
}

export enum OAuth2GrantType {
    AUTHORIZATION_CODE = 'AUTHORIZATION_CODE',
    ACCOUNT_CREDENTIALS = 'ACCOUNT_CREDENTIALS',
    CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS',
    CUSTOM = 'CUSTOM',
}

export interface BaseOauth2 extends BaseEnterpriseComponentAuthorization {
    type: EnterpriseComponentAuthorizationType.OAUTH2;
    grantType: OAuth2GrantType;
    variableValueAssignmentResponseHandlingDefinition: EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition;
}

export interface OAuth2AuthorizationCode extends BaseOauth2 {
    grantType: OAuth2GrantType.AUTHORIZATION_CODE;
    authUrl: TonkeanExpressionDefinition;
    accessTokenUrl: TonkeanExpressionDefinition;
    clientId: TonkeanExpressionDefinition;
    clientSecret: TonkeanExpressionDefinition;
    scope: TonkeanExpressionDefinition;
    clientAuthenticationLocation: TonkeanExpressionDefinition;
    refreshActionId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
}

export interface OAuth2ClientCredentials extends BaseOauth2 {
    grantType: OAuth2GrantType.CLIENT_CREDENTIALS;
    accessTokenUrl: TonkeanExpressionDefinition;
    clientId: TonkeanExpressionDefinition;
    clientSecret: TonkeanExpressionDefinition;
    clientAuthenticationLocation: TonkeanExpressionDefinition;
}

export interface OAuth2AccountCredential extends BaseOauth2 {
    type: EnterpriseComponentAuthorizationType.OAUTH2;
    grantType: OAuth2GrantType.ACCOUNT_CREDENTIALS;
    authUrl: TonkeanExpressionDefinition;
    clientId: TonkeanExpressionDefinition;
    clientSecret: TonkeanExpressionDefinition;
    accountId: TonkeanExpressionDefinition;
}

export interface OAuth2Custom extends BaseOauth2 {
    type: EnterpriseComponentAuthorizationType.OAUTH2;
    grantType: OAuth2GrantType.CUSTOM;
    customAuthenticationActionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
}

export enum OAuth2ClientAuthenticationLocation {
    HEADER = 'HEADER',
    BODY = 'BODY',
}

export const OAuth2ClientAuthenticationLocationToDisplayName = {
    [OAuth2ClientAuthenticationLocation.HEADER]: 'Send as Basic Auth header',
    [OAuth2ClientAuthenticationLocation.BODY]: 'Send client credentials in body',
};

export const AuthorizationTypeToDisplayName = {
    [EnterpriseComponentAuthorizationType.NONE]: 'No Authentication',
    [EnterpriseComponentAuthorizationType.API_KEY]: 'API Key',
    [EnterpriseComponentAuthorizationType.OAUTH2]: 'OAuth 2.0',
};

export interface ApiKeyAuthorization extends BaseEnterpriseComponentAuthorization {
    type: EnterpriseComponentAuthorizationType.API_KEY;
}

interface NoneAuthorization extends BaseEnterpriseComponentAuthorization {
    type: EnterpriseComponentAuthorizationType.NONE;
}

export enum IntegrationRequestInterceptorOption {
    HEADER = 'HEADER',
    QUERY_PARAMS = 'QUERY_PARAMS',
}

export enum EnterpriseComponentCertificateType {
    NONE = 'NONE',
    TWO_WAY_TLS = 'TWO_WAY_TLS',
}

export const CertificationTypeToDisplayName = {
    [EnterpriseComponentCertificateType.NONE]: 'No Certification',
    [EnterpriseComponentCertificateType.TWO_WAY_TLS]: '2 Way TLS',
};

export interface NoCertification extends CertificationDetails {
    certificationType: EnterpriseComponentCertificateType.NONE;
}

export interface TwoWayCertification extends CertificationDetails {
    certificationType: EnterpriseComponentCertificateType.TWO_WAY_TLS;
    privateKey: TonkeanExpressionDefinition;
    certificate: TonkeanExpressionDefinition;
    caCertificate: TonkeanExpressionDefinition;
}

export interface CertificationDetails {
    certificationType: EnterpriseComponentCertificateType;
}

type EnterpriseComponentAuthorization =
    | ApiKeyAuthorization
    | NoneAuthorization
    | OAuth2AuthorizationCode
    | OAuth2ClientCredentials
    | OAuth2AccountCredential
    | OAuth2Custom;

export default EnterpriseComponentAuthorization;
