import React from 'react';
import { components } from 'react-select';
import type { GroupBase } from 'react-select';
import type { PlaceholderProps } from 'react-select';
import styled from 'styled-components';

import { ReactComponent as SelectPlaceholder } from '../../../images/select-placeholder.svg';
import { H4 } from '../TUI';

import type { PersonSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const MainContainer = styled.span`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const CustomPlaceholder: React.FC<PlaceholderProps<PersonSummary>> = (props) => {
    return (
        <components.Placeholder<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
            <MainContainer data-automation="custom-placeholder-initial-placeholder">
                <SelectPlaceholder />
                <H4 $color={Theme.colors.gray_600}>Search User</H4>
            </MainContainer>
        </components.Placeholder>
    );
};

export default CustomPlaceholder;
