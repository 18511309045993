export interface DocStrings {
    baseUrl: string;
    pages: Record<string, string>;
}

// Add DOC_STRINGS to the window interface
declare global {
    interface Window {
        DOC_STRINGS: DocStrings;
    }
}

const KnowledgeBase: DocStrings = globalThis.DOC_STRINGS ?? {
    baseUrl: '',
    pages: {},
};

export default KnowledgeBase;
