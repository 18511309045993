import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import { LoadingWrapper } from './LoadingCircle';

import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { range } from '@tonkean/utils';

const StyledLoadingWrapper = styled(LoadingWrapper)<{ size: number }>`
    font-size: ${({ size }) => size}px;
    display: flex;
    gap: 0.5em;
`;

const DotsAnimationKeyframes = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0.4;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Dot = styled.div<{ index: number }>`
    width: 1em;
    height: 1em;
    line-height: 1em;

    // We animate a pseudo-element because we are changing the scale. So the regular element is a placholder while the pseudo-element is animated
    &:before {
        content: ' ';
        display: block;
        width: 1em;
        height: 1em;
        animation-delay: ${({ index }) => 160 * index}ms;
        animation-name: ${DotsAnimationKeyframes};
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        background: ${Theme.colors.promotion};
        border-radius: 50%;
    }
`;

interface Props extends StyledComponentsSupportProps {
    centered?: boolean;
    fullHeight?: boolean;
    size?: number;
    numberOfDots?: number;
}

const LoadingDotsSmooth: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { className, centered, fullHeight, size = 12.5, numberOfDots = 3 },
    ref,
) => {
    const loadingElement = useMemo(
        () => (
            <>
                {range(numberOfDots).map((i) => (
                    <Dot key={i} index={i} />
                ))}
            </>
        ),
        [numberOfDots],
    );

    return (
        <StyledLoadingWrapper ref={ref} className={className} centered={centered} fullHeight={fullHeight} size={size}>
            {loadingElement}
        </StyledLoadingWrapper>
    );
};

export default React.forwardRef(LoadingDotsSmooth);
