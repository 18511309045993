import { useContext } from 'react';

import CreateDialogContext from './CreateDialogContext';

/**
 * React hook for creating dialogs.
 * Dialogs are wrapper components and methods for the Modal component, but they have limitations. **Although
 * dialogs accept JSX in the title and in the content, it won't re-render**. For more advanced modals, use
 * the Modal component.
 *
 * @example
 * const DontClick = () => {
 *     const {confirm, alert} = useCreateDialog();
 *
 *     const onClick = async () => {
 *         const confirmed = await confirm("are you sure?");
 *         if (!confirmed) {
 *             return;
 *         }
 *
 *         alert("Just kidding", "This button does nothing, haha.", {okLabel: 'Cool bro'});
 *     }
 *
 *     return (
 *         <BigRedButton onClick={onClick}>Don't click on me!</BigRedButton>
 *     )
 * }
 */
function useCreateDialog() {
    return useContext(CreateDialogContext);
}

export default useCreateDialog;
