enum IntegrationActionType {
    UNKNOWN = 'UNKNOWN',
    CUSTOM_ACTIONS = 'CUSTOM_ACTIONS',
    CREATE_NEW_ITEM = 'CREATE_NEW_ITEM',
    CREATE_OR_UPDATE_ITEM = 'CREATE_OR_UPDATE_ITEM',
    MANUAL_FIELD_UPDATE = 'MANUAL_FIELD_UPDATE',
    GENERIC_INTEGRATION_ACTION = 'GENERIC_INTEGRATION_ACTION',
    PROJECT_INTEGRATION_ACTION = 'PROJECT_INTEGRATION_ACTION',
}

export default IntegrationActionType;
