enum InterfaceCTAActionType {
    OPEN_FORM = 'OPEN_FORM',
    OPEN_INTERFACE = 'OPEN_INTERFACE',
    TRIGGER_WORKFLOW = 'TRIGGER_WORKFLOW',
    CLONE_ITEM = 'CLONE_ITEM',
    DELETE_ITEM = 'DELETE_ITEM',
    OPEN_MATCHED_ITEM = 'OPEN_MATCHED_ITEM',
    OPEN_CONTRACT_FIELD = 'OPEN_CONTRACT_FIELD',
    OPEN_INTAKE_SEQUENCE = 'OPEN_INTAKE_SEQUENCE',
}

export default InterfaceCTAActionType;
