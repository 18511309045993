import React from 'react';

export interface ButtonRadioGroupContextValue {
    equalWidth: boolean;
}

const ButtonRadioGroupContext = React.createContext<ButtonRadioGroupContextValue>({
    equalWidth: false,
});

export default ButtonRadioGroupContext;
