import React from 'react';
import styled from 'styled-components';

import XCloseButton from '../CloseButton/XCloseButton';
import { Modal } from '../Modal';

import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StyledXCloseButton = styled(XCloseButton)`
    margin: 8px 8px 0 auto;
`;

const Wrapper = styled.div`
    padding: 0 20px 20px 20px;
    height: 100%;
`;

const StyledTextarea = styled.textarea`
    width: 100% !important;
    color: ${Theme.colors.gray_600};
    background-color: ${Theme.colors.brightestGray} !important;
    border: 1px solid ${Theme.colors.gray_300} !important;
    font-size: ${FontSize.SMALL_12};
    height: inherit;
    resize: none;
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    text?: string;
}

const TextareaPreviewModal: React.FC<Props> = ({ isOpen, onClose, text }) => {
    return (
        <Modal open={isOpen} onClose={onClose} fullScreen>
            <StyledXCloseButton size={ButtonSize.MEDIUM} onClick={onClose} />

            <Wrapper>
                <StyledTextarea value={text || ''} readOnly />
            </Wrapper>
        </Modal>
    );
};

export default TextareaPreviewModal;
