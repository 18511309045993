import React, { useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';

import type PickerListItem from './PickerListItem';
import type { PickerListItems } from './PickerListItems';
import { ITEM_HEIGHT, PickerListPart, RowCheckbox, RowText } from './pickerListSharedStyles';
import PickerRow from './PickerRow';
import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '../TUI/Placeholder';

import { FontSize, Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const PickerListHeader = styled(PickerListPart)<{ defaultHeader: boolean; titleShadow?: boolean }>`
    border-bottom: 1px solid ${Theme.current.palette.mainColors.gray_300};

    ${({ titleShadow }) =>
        titleShadow &&
        css`
            border: none;
            box-shadow: 0px 3px 3px -1px rgba(0, 0, 0, 8%);
        `};

    ${({ defaultHeader }) =>
        defaultHeader &&
        css`
            font-size: ${FontSize.SMALL_12};
            min-height: 34px;
        `};
`;

const PickerListItemsWrapper = styled.div`
    overflow: auto;
    flex-grow: 1;
`;

const PaddedPlaceholderGrid = styled(PlaceholderGrid)`
    padding: 11px 0;
`;

interface Props<T> {
    itemsById?: PickerListItems<T>;
    onCheckChanged?(changedItems: PickerListItems<T>);
    onSelectChanged?(selectedItem: PickerListItem<T>);
    loading?: boolean;
    title?: string;
    customTitle?: React.ReactNode;
    customEmptyMessage?: React.ReactNode;
    titleCounter?: boolean;
    showCheckboxes?: boolean;
    highlightText?: string;
    customPadding?: number;
    titleShadow?: boolean;
    onClickSelect?: boolean;
    selectedId?: string;
}

const PickerList = <T,>({
    itemsById = {},
    onCheckChanged,
    onSelectChanged,
    loading = false,
    title = 'All Items',
    customTitle,
    customEmptyMessage,
    titleCounter = true,
    showCheckboxes = true,
    highlightText,
    customPadding,
    titleShadow,
    onClickSelect = false,
    selectedId,
}: Props<T>) => {
    const itemIds = useMemo(() => Object.keys(itemsById), [itemsById]);
    const allChecked = useMemo(
        () => itemIds.length > 0 && Object.values(itemsById).every((item) => item.checked),
        [itemIds, itemsById],
    );

    const onAllChecked = () => {
        const changedItems = Object.fromEntries(
            Object.entries(itemsById).map(([id, item]) => [id, { ...item, checked: !allChecked }]),
        );
        onCheckChanged?.(changedItems);
    };

    const hasNoItems = itemIds.length === 0;

    const itemData = useMemo(
        () => ({
            pickerListItemIds: itemIds,
            itemsById,
            onCheckChanged,
            onSelectChanged,
            showCheckboxes,
            highlightText,
            customPadding,
            onClickSelect,
            selectedId,
        }),
        [
            customPadding,
            highlightText,
            itemIds,
            itemsById,
            onCheckChanged,
            onClickSelect,
            onSelectChanged,
            selectedId,
            showCheckboxes,
        ],
    );

    return (
        <Wrapper>
            <PickerListHeader
                defaultHeader={!customTitle}
                customPadding={customPadding}
                titleShadow={titleShadow}
                buttonAsLabel={showCheckboxes}
                clickable={showCheckboxes}
            >
                {showCheckboxes && <RowCheckbox checked={allChecked} onChange={onAllChecked} />}
                {customTitle ? (
                    customTitle
                ) : (
                    <RowText greyed={hasNoItems}>
                        {title}
                        {titleCounter && ` (${itemIds.length})`}
                    </RowText>
                )}
            </PickerListHeader>
            {!!customEmptyMessage && itemIds.length === 0 && !loading && customEmptyMessage}
            <PickerListItemsWrapper>
                {loading ? (
                    <PaddedPlaceholderGrid direction={PlaceholderGridDirection.ROWS} gap="22px">
                        {range(7).map((i) => (
                            <PlaceholderGrid
                                key={i}
                                direction={PlaceholderGridDirection.COLUMNS}
                                template={
                                    showCheckboxes
                                        ? `${(customPadding || 14) - 6}px 12px 1fr`
                                        : `${(customPadding || 14) - 6}px 1fr`
                                }
                            >
                                <Placeholder $width="0" $circle />
                                {showCheckboxes && <Placeholder $width="12px" $height="12px" $borderRadius="2px" />}
                                <Placeholder $height={FontSize.SMALL_12} $width="150px" />
                            </PlaceholderGrid>
                        ))}
                    </PaddedPlaceholderGrid>
                ) : (
                    <AutoSizer disableWidth>
                        {({ height }) => (
                            <FixedSizeList
                                height={height}
                                width="100%"
                                itemCount={itemIds.length}
                                itemSize={ITEM_HEIGHT}
                                overscanCount={10}
                                itemData={itemData}
                                itemKey={(index, data) => data.pickerListItemIds[index]!}
                            >
                                {PickerRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </PickerListItemsWrapper>
        </Wrapper>
    );
};

export default PickerList;
