import type PopperJS from '@popperjs/core';
import React, { useEffect, useState } from 'react';

import PopoverElement from './PopoverElement';
import CloseCallbackContext from '../CloseCallback/CloseCallbackContext';
import type { PopperChildrenRefProps } from '../Popper';
import Popper from '../Popper';

import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export interface PopoverProps extends StyledComponentsSupportProps {
    /** The content of the tooltip. */
    content: React.ReactNode;
    /** Where to place the tooltip in relative to the reference element. */
    placement?: PopperJS.Placement;
    /** Whether the initially focused element be the wrapper. Otherwise, the first focusable element will be used. */
    initiallyFocusOnWrapper?: boolean;
    /** Whether the original focus be restored when closing the popover. */
    restoreFocusWhenClosed?: boolean;
    /** Should the tooltip have an arrow? */
    showArrow?: boolean;
    /** Popover's color */
    color?: TooltipColor;
    /** Popover's size */
    size?: TooltipSize;
    /** Should the tooltip be closed when using right click outside of the popover? Otherwise, only left click will close. */
    closeOnOutsideRightClick?: boolean;
    /** Whether the popover should have padding around it */
    noPadding?: boolean;
    /** If the popover is opened by hover, it won't be closed by clicking outside and won't be added to the stack. */
    openedByHover?: boolean;
    /** Should the tooltip be shown? */
    show: boolean;
    /** Should manage the focus functionality */
    manageFocus?: boolean;

    /** Callback function to be triggered when the tooltip should be closed */
    onClose(): void;

    /** Popover's width size */
    width?: string;
    /** Should the overflow be `visible` instead of `auto`? This should not be used, only if the popover contains an angular element with select and you can't use react select. */
    overflowVisible?: boolean;
    /** Element to restore focus to when Popover is closed. */
    restoreFocusElementRef?: React.RefObject<HTMLElement>;
    /** Should the popover be the same width as the opening (reference) element */
    popoverSameWidthAsOpeningElement?: boolean;
    offset?: number;
}

const Popover: React.FC<React.PropsWithChildren<PopoverProps & PopperChildrenRefProps>> = ({
    children,
    content,
    restoreFocusWhenClosed = true,
    initiallyFocusOnWrapper = true,
    placement = 'bottom',
    showArrow = true,
    color = TooltipColor.WHITE,
    size = TooltipSize.MEDIUM,
    closeOnOutsideRightClick = false,
    noPadding = false,
    openedByHover = false,
    className,
    show: showProp,
    onClose,
    nodeRef,
    manageFocus = true,
    width,
    overflowVisible = false,
    restoreFocusElementRef,
    popoverSameWidthAsOpeningElement,
    offset,
}) => {
    // This is to let native events like onClick be triggered first, to prevent a bug that the click event on the
    // trigger button comes after the onClose that was triggered because the onClickOutside, so it thought that the
    // popover should be re-opened.
    const delayedOnClose = () => {
        setTimeout(() => onClose());
    };

    // This is an internal state that handles the shown prop in the popper.
    const [popoverShown, setPopoverShown] = useState(false);

    // When the show prop changes to true, set popoverShown to true.
    useEffect(() => {
        if (showProp) {
            setPopoverShown(true);
        }
    }, [showProp]);

    // When the show prop changes to false, the tooltip element fades out, and when completes, it emits onExisted.
    // When it emits it, we set popoverShown to false to close the popper element.
    const onExited = () => {
        setPopoverShown(false);
    };

    return (
        <Popper
            shown={popoverShown}
            placement={placement}
            popoverSameWidthAsOpeningElement={!!popoverSameWidthAsOpeningElement}
            offset={offset}
            // eslint-disable-next-line react/no-unstable-nested-components
            popper={(setPopperElement, popperStyles, popperAttributes, setArrowElement, arrowStyles) => (
                <CloseCallbackContext.Provider value={onClose}>
                    <PopoverElement
                        show={showProp}
                        onClose={delayedOnClose}
                        onExited={onExited}
                        popperStyles={popperStyles}
                        popperAttributes={popperAttributes}
                        showArrow={showArrow}
                        initiallyFocusOnWrapper={initiallyFocusOnWrapper}
                        restoreFocusWhenClosed={restoreFocusWhenClosed}
                        color={color}
                        size={size}
                        width={width}
                        noPadding={noPadding}
                        openedByHover={openedByHover}
                        arrowStyles={arrowStyles}
                        setArrowElement={setArrowElement}
                        closeOnOutsideRightClick={closeOnOutsideRightClick}
                        className={className}
                        manageFocus={manageFocus}
                        overflowVisible={overflowVisible}
                        ref={setPopperElement}
                        restoreFocusElementRef={restoreFocusElementRef}
                    >
                        {content}
                    </PopoverElement>
                </CloseCallbackContext.Provider>
            )}
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            nodeRef={nodeRef!}
        >
            {children}
        </Popper>
    );
};

export default Popover;
