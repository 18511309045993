enum ProjectIntegrationFieldListValueOutputType {
    OBJECT = 'OBJECT',
    STRING = 'STRING',
}

export const fieldListValueOutputTypeToDisplayName: Record<ProjectIntegrationFieldListValueOutputType, string> = {
    [ProjectIntegrationFieldListValueOutputType.OBJECT]: 'Object',
    [ProjectIntegrationFieldListValueOutputType.STRING]: 'String',
};

export default ProjectIntegrationFieldListValueOutputType;
