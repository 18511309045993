import React from 'react';
import styled, { css } from 'styled-components';

import { ColorCircle } from './ColorCircle';
import type { CustomColor } from './CustomColor';
import { ReactComponent as CheckedIcon } from '../../../../images/check-lg.svg';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';
import { colorSvg } from '@tonkean/utils';

const StyledCustomColorsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const StyledCustomColor = styled.div<{ $isSelected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 14px;
    &:hover {
        background-color: ${Theme.colors.gray_200};
        cursor: pointer;
    }

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background-color: ${Theme.colors.gray_200};
        `}
`;

const StyledCustomColorNameAndColor = styled.div<{ $isSelected?: boolean }>`
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${Theme.colors.gray_700};

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            font-weight: bold;
        `}
`;

const StyledCheckedIcon = styled(CheckedIcon)`
    ${colorSvg(Theme.colors.gray_700)};
    width: 12px;
    height: 12px;
`;

interface InlineCustomColorsPickerProps extends DataAutomationSupportProps {
    customColors: CustomColor[];
    onChange(newColor: Color, customColorId?: string): void;
    selectedCustomColorId?: string;
}

const CustomColorListPicker: React.FC<InlineCustomColorsPickerProps> = ({
    customColors,
    onChange,
    selectedCustomColorId,
}) => {
    return (
        <StyledCustomColorsList>
            {customColors.map((color) => (
                <StyledCustomColor
                    key={color.id}
                    onClick={() => onChange(color.color, color.id)}
                    $isSelected={color.id === selectedCustomColorId}
                    data-automation="custom-color-list-picker-color-option"
                    data-automation-label={color.id}
                >
                    <StyledCustomColorNameAndColor
                        $isSelected={color.id === selectedCustomColorId}
                        data-automation="custom-color-list-picker-color-option-data"
                    >
                        <ColorCircle
                            $selectedColor={color.color}
                            data-automation="custom-color-list-picker-color-circle"
                        />
                        {color.label}
                    </StyledCustomColorNameAndColor>
                    {color.id === selectedCustomColorId && (
                        <StyledCheckedIcon data-automation="custom-color-list-picker-selected-color-icon" />
                    )}
                </StyledCustomColor>
            ))}
        </StyledCustomColorsList>
    );
};

export default CustomColorListPicker;
