/**
 * The Async api job possible statuses.
 */
export enum JobStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    HAS_ERROR = 'HAS_ERROR',
    COMPLETED_SUCCESSFULLY = 'COMPLETED_SUCCESSFULLY',
}

/**
 * An interface that represents the job status response.
 */
interface JobStatusResponse<T> {
    jobId: string;
    status: JobStatus;
    response: T;
}

export default JobStatusResponse;
