import type { ChangeEvent } from 'react';
import React from 'react';

import type { Color } from '@tonkean/tui-theme';
import type { InputSize } from '@tonkean/tui-theme/sizes';

export interface RadioGroupContextValue {
    /** Radio group name. */
    name?: string;

    /** Radio group uuid. Used as name if not given. (sent separately to be able to distinguish between manual and auto generated name for formik.) */
    uuid: string;

    /** Radio group's value. Used to check if validated. */
    value?: any;

    /** The size of the radio button */
    size: InputSize;

    /**
     * Icon color in selected state
     */
    selectedColor?: Color;

    /**
     * Icon color in unselected state
     */
    unselectedColor?: Color;

    /** Should the label color change based on if selected? */
    changeLabelColor: boolean;

    /** Should the whole Radio Group be disabled. */
    radioGroupDisabled?: boolean;

    /** Should the label be bold? */
    boldLabel?: boolean;

    /**
     * A function to add a radio button to the group.
     *
     * @prop value - the value passed to the radio button.
     * @prop fakeValue - the auto generated value set to the HTMLElement. It should be unique to the radio group.
     * @returns a function that when called will remove the radio button from the group.
     */
    addRadio(value: any, fakeValue: string): () => void;

    /**
     * Function to trigger when the selection changes.
     *
     * @param event - the change event.
     */
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
}

const RadioGroupContext = React.createContext<RadioGroupContextValue>({} as any);

export default RadioGroupContext;
