import React, { useEffect, useMemo, useRef } from 'react';
import type { ControlProps } from 'react-select';
import { components } from 'react-select';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';

const CustomControl: React.FC<React.PropsWithChildren<ControlProps>> = ({
    children,
    innerRef,
    innerProps: innerPropsProp,
    ...props
}) => {
    const dataAutomationLabel = useMemo(() => {
        if (!props.selectProps.value) {
            return '';
        }

        return props.selectProps.isMulti
            ? (props.selectProps.value as { label?: string }[]).map((option) => option.label || '').join(',')
            : (props.selectProps.value as { label?: string } | null)?.label;
    }, [props.selectProps.isMulti, props.selectProps.value]);

    const innerProps = {
        ...innerPropsProp,
        'data-automation': props.selectProps['dataAutomation'] ?? 'tnk-select-control',
        'data-automation-label': dataAutomationLabel,
    };

    const ref = useRef<HTMLDivElement>(null);
    const sharedRefCallback = useMultipleRefCallback(ref, innerRef);

    useEffect(() => {
        const currentRef = ref.current;
        if (!currentRef) {
            return;
        }

        const eventHandler = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };

        currentRef.addEventListener('keydown', eventHandler);
        return () => currentRef.removeEventListener('keydown', eventHandler);
    }, []);

    return (
        <components.Control {...props} innerProps={innerProps} innerRef={sharedRefCallback}>
            {children}
        </components.Control>
    );
};

export default CustomControl;
