import React from 'react';

/**
 * A dropdown component that handles click outside automatically and renders whatever it surrounds.
 * Inspiration: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 *
 * Expects the following props:
 *      onClickOutside: (mandatory) a callback for when the dropdown is outside-clicked.
 *                      Should remove the dropdown from view (Usually be setting a new state to the parent).
 *      classes: (optional) desired classes to be set on the dropdown.
 */
export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        // Listen to all clicks on the document (so we can remove ourselves on click outside).
        setTimeout(() => {
            document.addEventListener('click', this.handleClickOutside);
        });
    }

    componentWillUnmount() {
        // Remove the click listener when un-mounting.
        document.removeEventListener('click', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref.
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Handle click-outside and decide if we should trigger the callback (that hides the dropdown).
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.button !== 2) {
            // button : 2 is right click
            // Stop event propagation so if the user re-clicks the toggle target it won't re-open to the dropdown.
            event.stopPropagation();
            // Call the click-outside callback we have received.
            this.props.onClickOutside();
        }
    }

    render() {
        // Set the dropdown-menu class and add any requested classes.
        let className = 'dropdown-menu ';
        className += this.props.classes || '';

        return (
            <ul ref={this.setWrapperRef} className={className} tnk-dnd-undraggable="true">
                {this.props.children}
            </ul>
        );
    }
}
