import React, { useId, useMemo } from 'react';
import Select from 'react-select';
import type { SelectComponentsConfig } from 'react-select';
import type { GroupBase } from 'react-select';
import type { Props as StateManagerProps } from 'react-select';
import type { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import type { AsyncProps } from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import type { AsyncCreatableProps } from 'react-select/async-creatable';
import type SelectClass from 'react-select/base';
import CreatableSelect from 'react-select/creatable';
import type { CreatableProps } from 'react-select/creatable';

import CustomClearIndicator from './CustomClearIndicator';
import CustomControl from './CustomControl';
import CustomInput from './CustomInput';
import CustomMenuList from './CustomMenuList';
import CustomMultiValueContainerSelection from './CustomMultiValueContainerSelection';
import CustomOptionSelectionWithIcon from './CustomOptionSelectionWithIcon';
import CustomValueContainerSelectionWithIcon from './CustomValueContainerSelectionWithIcon';
import useResponsiveInputSize from '../../../hooks/useResponsiveInputSize';
import useSSRDocument from '../../../hooks/useSSRDocument';
import { getBorderColor } from '../Input';
import { MODAL_Z_INDEX } from '../Modal';
import { ROOT_PORTAL_CONTAINER_ID } from '../portalConsts';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';

function getComponent(isAsync: boolean, isCreatable: boolean): any {
    if (isAsync) {
        return isCreatable ? AsyncCreatableSelect : AsyncSelect;
    }

    return isCreatable ? CreatableSelect : Select;
}

export function tnkSelectHoverOrFocus(isFocused): string {
    return isFocused ? '&' : '&:hover';
}

const DESCRIPTION_EXTRACTION_PATTERN = /```([\s\S]*?)```/;
export const buildTnkSelectOption = (value: string) => {
    const label = value.replace(/```[\s\S]*?```/, '');

    const matches = DESCRIPTION_EXTRACTION_PATTERN.exec(value);
    const description = matches?.[1];

    return {
        value,
        label,
        description,
    };
};

type ComponentProps<
    IsAsync extends boolean,
    IsCreatable extends boolean,
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
> = IsAsync extends true
    ? IsCreatable extends true
        ? AsyncCreatableProps<Option, IsMulti, Group>
        : AsyncProps<Option, IsMulti, Group>
    : IsCreatable extends true
      ? CreatableProps<Option, IsMulti, Group>
      : StateManagerProps<Option, IsMulti, Group>;

export type TnkSelectProps<
    IsAsync extends boolean,
    IsCreatable extends boolean,
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
> = ComponentProps<IsAsync, IsCreatable, Option, IsMulti, Group> & {
    isAsync?: IsAsync;
    isCreatable?: IsCreatable;
    isMulti?: IsMulti;
    thin?: boolean;
    bold?: boolean;
    size?: InputComponentSizes;
    mobileSize?: InputComponentSizes;
    readOnly?: boolean;
    isError?: boolean;
    isHighlighted?: boolean;
    dataAutomation?: string;
    shouldFocus?: boolean;
    forceFindPortalContainer?: boolean;
};

/**
 * A wrapper directive for react-select component
 * https://react-select.com/home
 * A FEW THINGS TO TAKE IN MIND:
 * 1. There's a known bug with animating the react-select tags with makeAnimated() hook.
 *    https://github.com/JedWatson/react-select/issues/3684
 *    It will not work with the defaultValue prop on a multi-select component.
 *    DON'T ADD IT!
 * 2. We use Select and CreatableSelect according to a scope variable named isCreatable.
 * 3. All other scope variables are propagated to the props of those elements.
 * 4. We override the default theme so it will look in the same Tonkean language
 */
const TnkSelect = <
    Option,
    Group extends GroupBase<Option>,
    IsAsync extends boolean = false,
    IsCreatable extends boolean = false,
    IsMulti extends boolean = false,
>(
    {
        isAsync = false as IsAsync,
        isCreatable = false as IsCreatable,
        thin = false,
        bold = false,
        size = thin ? InputSize.MEDIUM : InputSize.LARGE,
        mobileSize = InputSize.XLARGE,
        menuPlacement = 'auto',
        menuPosition = 'fixed',
        menuShouldBlockScroll = true,
        styles,
        components,
        readOnly = false,
        placeholder = readOnly ? '' : 'Select...',
        isError = false,
        isHighlighted = false,
        dataAutomation,
        shouldFocus = true,
        forceFindPortalContainer = false,
        ...props
    }: TnkSelectProps<IsAsync, IsCreatable, Option, IsMulti, Group>,
    ref: React.ForwardedRef<SelectClass<Option, IsMulti, Group>>,
): React.ReactElement => {
    const inputSize = useResponsiveInputSize(size, mobileSize);

    const customStyles = useMemo<StylesConfig<Option, IsMulti, Group>>(
        () => ({
            ...styles,
            placeholder: (provided, state) => ({
                ...provided,
                color: Theme.colors.gray_500,
                ...styles?.placeholder?.(provided, state),
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                width: '22px',
                height: '22px',
                padding: '0 4px',
                marginRight: '2px',
                marginTop: '1px',
                ...styles?.clearIndicator?.(provided, state),
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                width: '22px',
                height: '22px',
                padding: '0 4px',
                marginLeft: '2px',
                marginTop: '1px',
                marginRight: inputSize === InputSize.LARGE ? '13px' : '2px',
                ...(state.isDisabled && {
                    color: 'transparent',
                }),
                ...styles?.dropdownIndicator?.(provided, state),
            }),
            multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: Theme.colors.gray_300,
                borderRadius: '100px',
                padding: '2px 6px',
                margin: '6px 2px',
                height: `${bold ? 30 : 20}px`,
                lineHeight: `${bold ? 30 : 20}px`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                ...styles?.multiValue?.(provided, state),
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                backgroundColor: Theme.colors.gray_300,
                color: Theme.colors.gray_800,
                fontWeight: `${bold ? 500 : 400}`,
                fontSize: `${bold ? FontSize.XXXXLARGE_24 : FontSize.SMALL_12}px`,
                ...(bold && {
                    lineHeight: '30px',
                }),
                textOverflow: undefined,
                whiteSpace: undefined,
                borderRadius: '100px',
                height: `${bold ? 30 : 20}px`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                ...styles?.multiValueLabel?.(provided, state),
            }),
            multiValueRemove: (provided, state) => ({
                ...provided,
                backgroundColor: Theme.colors.gray_300,
                color: Theme.current.palette.colorPicker.HEX_34393E,
                borderRadius: '100px',
                ...(state.isDisabled && {
                    color: 'transparent',
                }),
                ...styles?.multiValueRemove?.(provided, state),
            }),
            control: (provided, state) => ({
                ...provided,
                boxShadow: 'none',
                borderRadius: `${Theme.sizes.input[inputSize].radius}px`,
                cursor: state.isDisabled || readOnly ? 'default' : 'pointer',
                minHeight: `${Theme.sizes.input[inputSize].height}px`,
                fontSize: `${
                    bold ? Theme.sizes.input[inputSize].fontSize + 8 : Theme.sizes.input[inputSize].fontSize
                }px`,
                lineHeight: `${
                    bold ? Theme.sizes.input[inputSize].fontSize + 8 : Theme.sizes.input[inputSize].fontSize
                }px`,
                '&,:hover': {
                    borderColor: getBorderColor(isError, isHighlighted, false),
                },
                ...(bold && {
                    fontWeight: 500,
                }),
                ...(!state.isDisabled &&
                    state.isFocused && {
                        borderColor: `${getBorderColor(isError, isHighlighted, state.isFocused)}!important`,
                    }),
                ...(state.isDisabled && {
                    backgroundColor: Theme.colors.disabled,
                }),
                ...styles?.control?.(provided, state),
            }),
            option: (provided, state) => ({
                ...provided,
                wordBreak: 'normal',
                overflowWrap: 'anywhere',
                fontWeight: state.isSelected ? 500 : 'normal',
                cursor: state.isDisabled ? 'default' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: state.isSelected ? Theme.colors.gray_800 : Theme.colors.gray_700,
                background: state.isSelected ? Theme.colors.gray_300 : Theme.colors.basicBackground,
                [tnkSelectHoverOrFocus(state.isFocused)]: {
                    color: Theme.colors.gray_800,
                    background: state.isSelected ? Theme.colors.gray_300 : Theme.colors.gray_200,
                },
                ':active': {
                    background: Theme.colors.gray_300,
                },
                minHeight: `${Theme.sizes.input[inputSize].height}px`,
                fontSize: `${Theme.sizes.input[inputSize].fontSize}px`,
                lineHeight:
                    inputSize === InputSize.LARGE
                        ? `${Theme.sizes.input[inputSize].lineHeight + 8}px`
                        : `${Theme.sizes.input[inputSize].lineHeight}px`,
                ...styles?.option?.(provided, state),
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: 3, // It's 3 to override Bootstrap's form-control which overlays the options
                ...styles?.menu?.(provided, state),
            }),
            menuPortal: (provided, state) => ({
                ...provided,
                zIndex: MODAL_Z_INDEX,
                ...styles?.menuPortal?.(provided, state),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                // All content inside the value container has margin of 2, so to match the padding of the input, we
                // should subtract it by two.
                padding:
                    inputSize === InputSize.LARGE
                        ? `0 ${Theme.current.sizes.input[inputSize].paddingRightLeft}px`
                        : `0 ${Theme.current.sizes.input[inputSize].paddingRightLeft - 2}px`,
                ...styles?.valueContainer?.(provided, state),
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                ...(inputSize === InputSize.MEDIUM
                    ? {
                          marginTop: '4px',
                          marginBottom: '4px',
                      }
                    : {}),
                ...styles?.indicatorSeparator?.(provided, state),
            }),
            singleValue: (provided, state) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
                ...styles?.singleValue?.(provided, state),
            }),
        }),
        [bold, inputSize, isError, isHighlighted, readOnly, styles],
    );

    const rootPortalContainer = useSSRDocument(`#${ROOT_PORTAL_CONTAINER_ID}`, forceFindPortalContainer);

    const customComponents = useMemo(() => {
        return {
            IndicatorSeparator: () => null,
            Option: CustomOptionSelectionWithIcon,
            ClearIndicator: CustomClearIndicator,
            ValueContainer: CustomValueContainerSelectionWithIcon,
            MultiValue: CustomMultiValueContainerSelection,
            Control: CustomControl,
            MenuList: CustomMenuList,
            Input: CustomInput,
            ...components,
        } as SelectComponentsConfig<Option, IsMulti, Group>;
    }, [components]);

    // Render a creatable select or regular select according to isCreatable
    const SelectComponent = getComponent(isAsync, isCreatable);

    const readOnlyProps = readOnly
        ? {
              isClearable: false,
              isSearchable: false,
              openMenuOnClick: false,
              menuIsOpen: false,
          }
        : {};

    const instanceId = useId();

    return (
        <SelectComponent
            defaultOptions
            {...props}
            menuPlacement={menuPlacement}
            instanceId={instanceId}
            menuPosition={menuPosition}
            menuShouldBlockScroll={menuShouldBlockScroll}
            menuPortalTarget={rootPortalContainer}
            components={customComponents}
            styles={customStyles}
            placeholder={placeholder}
            shouldFocus={shouldFocus}
            {...readOnlyProps}
            // We pass the thin prop even thought it doesn't exist in ReactSelect to get it in selectProps which passed
            // to the custom inner components.
            thin={thin}
            readOnly={readOnly}
            dataAutomation={dataAutomation}
            ref={ref}
        />
    );
};

export type TnkSelectComponentType = <
    Option,
    Group extends GroupBase<Option> = GroupBase<Option>,
    IsAsync extends boolean = false,
    IsCreatable extends boolean = false,
    IsMulti extends boolean = false,
>(
    props: TnkSelectProps<IsAsync, IsCreatable, Option, IsMulti, Group> &
        React.RefAttributes<SelectClass<Option, IsMulti, Group>>,
) => React.ReactElement;

export default React.memo(React.forwardRef(TnkSelect)) as TnkSelectComponentType;
