import React from 'react';

import Message, { MessageType } from './Message';
import { ReactComponent as WarningIcon } from '../../../../images/history-error.svg';
import { ReactComponent as XIcon } from '../../../../images/red-x.svg';

import { WarningTriangleIcon } from '@tonkean/svg';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

export enum ErrorType {
    SYSTEM,
    GENERAL,
}

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    errorType?: ErrorType;
    warn?: boolean;
}

const ErrorMessage: React.FC<React.PropsWithChildren<Props>> = ({
    dataAutomation,
    children,
    errorType = ErrorType.SYSTEM,
    className,
    warn,
}) => {
    let icon = <></>;
    if (warn) {
        icon = (
            <span className="tnk-icon">
                <WarningTriangleIcon />
            </span>
        );
    } else if (errorType === ErrorType.SYSTEM) {
        icon = (
            <span className="tnk-icon">
                <WarningIcon />
            </span>
        );
    } else {
        icon = (
            <span className="tnk-icon">
                <XIcon />
            </span>
        );
    }

    return (
        <Message
            dataAutomation={dataAutomation}
            type={warn ? MessageType.WARNING : MessageType.ERROR}
            className={className}
            icon={icon}
        >
            {children}
        </Message>
    );
};

export default ErrorMessage;
