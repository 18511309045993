import type { MouseEvent } from 'react';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const KeyValueWrapper = styled.div<{ $isHighlighted: boolean }>`
    width: fit-content;

    border: ${({ $isHighlighted }) => `1px solid ${$isHighlighted ? Theme.colors.primaryHighlight : 'transparent'}`};
`;

const ObjectKeyValueWrapper = styled(KeyValueWrapper)<{ depth: number }>`
    margin: 1px 1px 1px ${({ depth }) => (depth + 1) * 12}px;
`;

const ArrayValueWrapper = styled(KeyValueWrapper)`
    margin-left: 6px;
`;

const ClickableKeyNode = styled(Clickable)<{ $isHighlighted?: boolean; $disabled: boolean }>`
    color: #a31515;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover,
            &:focus {
                color: #a31515;
            }
        `}
`;

const ClickableNumberNode = styled(Clickable)<{ disabled: boolean }>`
    color: #098658;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover,
            &:focus {
                color: #098658;
            }
        `}
`;

const ClickableNullNode = styled(Clickable)<{ disabled: boolean }>`
    color: #0451a5;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover,
            &:focus {
                color: #0451a5;
            }
        `}
`;

const ClickableStringNode = styled(Clickable)<{ disabled: boolean }>`
    color: #0451a5;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover,
            &:focus {
                color: #0451a5;
            }
        `}
`;
const ClickableBooleanNode = styled(Clickable)`
    color: #0451a5;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover,
            &:focus {
                color: #0451a5;
            }
        `}
`;

export type JsonPickerAllowedTypes = number | string | any[] | null | Record<string, any> | undefined | boolean;

export interface JsonPickerProps {
    json: JsonPickerAllowedTypes;
    onClick: (path: string) => void;

    depth?: number;
    path?: string;

    disabled?: boolean;
}

const JsonPicker: React.FC<JsonPickerProps> = ({ json, onClick, depth = 0, path = '$', disabled = false }) => {
    const [localHoverKey, setLocalHoverKey] = useState<string>();

    // OnHovering an element we want to set the local selected key and stop the event to avoid fire parent event
    const setHover = (event: MouseEvent<HTMLElement>, key: string) => {
        setLocalHoverKey(key);
        event.stopPropagation();
    };

    const setUnHover = (event: MouseEvent<HTMLElement>, key: string) => {
        if (localHoverKey === key) {
            setLocalHoverKey(undefined);
        }
        event.stopPropagation();
    };

    // Simplify the parent onClick callback on clicking a value
    const onClickValue = () => onClick(path);

    switch (typeof json) {
        case 'string':
            return (
                <ClickableStringNode onClick={onClickValue} disabled={disabled}>
                    "{json}"
                </ClickableStringNode>
            );
        case 'boolean':
            return (
                <ClickableBooleanNode onClick={onClickValue} disabled={disabled}>
                    {json.toString()}
                </ClickableBooleanNode>
            );
        case 'number':
        case 'bigint':
            return (
                <ClickableNumberNode onClick={onClickValue} disabled={disabled}>
                    {json.toString()}
                </ClickableNumberNode>
            );
        case 'undefined':
        case 'object': {
            if (json === null || json === undefined) {
                return (
                    <ClickableNullNode onClick={onClickValue} disabled={disabled}>
                        null
                    </ClickableNullNode>
                );
            }
            if (Array.isArray(json)) {
                return (
                    <>
                        [
                        {json.map((node, index) => {
                            return (
                                // Ignoring keyboard interactions because its only for the ui
                                // eslint-disable-next-line react/no-array-index-key,styled-components-a11y/mouse-events-have-key-events,styled-components-a11y/no-static-element-interactions
                                <ArrayValueWrapper
                                    key={path + index}
                                    $isHighlighted={!disabled && localHoverKey === path + index}
                                    onMouseOver={(event) => !disabled && setHover(event, path + index)}
                                    onMouseOut={(event) => !disabled && setUnHover(event, path + index)}
                                >
                                    <JsonPicker
                                        onClick={disabled ? () => {} : onClick}
                                        json={node}
                                        depth={depth + 1}
                                        path={`${path}[${index}]`}
                                        disabled={disabled}
                                    />
                                    {index !== json.length - 1 && <>,</>}
                                </ArrayValueWrapper>
                            );
                        })}
                        ]
                    </>
                );
            }
            return (
                <>
                    {'{'}
                    {Object.entries(json).map(([key, value], index, array) => {
                        const joinedPath = `${path}.${key}`;
                        return (
                            // Ignoring keyboard interactions because its only for the ui
                            // eslint-disable-next-line styled-components-a11y/mouse-events-have-key-events,styled-components-a11y/no-static-element-interactions
                            <ObjectKeyValueWrapper
                                key={key}
                                depth={depth}
                                $isHighlighted={!disabled && localHoverKey === key}
                                onMouseOver={(event) => !disabled && setHover(event, key)}
                                onMouseOut={(event) => !disabled && setUnHover(event, key)}
                            >
                                <ClickableKeyNode
                                    onClick={() => {
                                        onClick(joinedPath);
                                    }}
                                    $disabled={disabled}
                                >
                                    "{key}"
                                </ClickableKeyNode>
                                :{' '}
                                <JsonPicker
                                    onClick={disabled ? () => {} : onClick}
                                    json={value}
                                    depth={depth + 1}
                                    path={joinedPath}
                                    disabled={disabled}
                                />
                                {array.length - 1 !== index && ','}
                            </ObjectKeyValueWrapper>
                        );
                    })}
                    {'}'}
                </>
            );
        }
        default:
            return <></>;
    }
};

export default React.memo(JsonPicker);
