import styled from 'styled-components';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

const Fab = styled.button.attrs({ children: '+' })`
    width: 55px;
    height: 55px;
    background: #1f8ded;
    border: none;
    border-radius: 100%;
    color: ${Theme.colors.basicBackground};
    font-size: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${styledFocus}
`;

export default Fab;
