import React from 'react';
import { components } from 'react-select';
import type { OptionProps } from 'react-select';
import type { GroupBase } from 'react-select';

const CustomClearIndicator = <
    ClearIndicator = unknown,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<ClearIndicator> = GroupBase<ClearIndicator>,
>({
    children,
    ...props
}: OptionProps<ClearIndicator, IsMulti, Group>) => {
    const innerProps = {
        ...props.innerProps,
        'data-automation': 'tnk-select-clear-indicator',
    };

    return <components.ClearIndicator {...props} innerProps={innerProps} />;
};

export default CustomClearIndicator;
