import React, { useEffect, useRef } from 'react';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { classNames } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

export interface ButtonPropsWithRef extends React.ButtonHTMLAttributes<HTMLButtonElement>, DataAutomationSupportProps {
    buttonAsDiv?: false;
    ref?: React.ForwardedRef<HTMLButtonElement>;
}

export interface DivPropsWithRef extends React.HTMLAttributes<HTMLDivElement>, DataAutomationSupportProps {
    buttonAsDiv: true;
    type?: string;
    disabled?: boolean;

    onClick?(event: React.MouseEvent<HTMLDivElement>): void;

    ref?: React.ForwardedRef<HTMLDivElement>;
}

type PropsWithRef = ButtonPropsWithRef | DivPropsWithRef;

/**
 * A button component that handles disabled state internally and not passing disabled to the dom. This fixes
 * the bug that doesn't emits onMouseOut when the button is disabled, which breaks the tooltip.
 */
const DisableableButton: React.ForwardRefRenderFunction<HTMLElement, Omit<PropsWithRef, 'ref'>> = (
    {
        buttonAsDiv = false,
        disabled,
        tabIndex: tabIndexProp,
        'aria-disabled': ariaDisabledProp,
        children,
        className: classNameProp,
        // We want props not to include type so we would be able to pass it only if it's button
        type,
        ...props
    },
    ref,
) => {
    const elementRef = useRef<HTMLElement>(null);

    /**
     * If the button is disabled, prevent the default actions and propagation of the click, key up, key down and key
     * press events.
     */
    useEffect(() => {
        const elem = elementRef.current;
        if (!elem || !disabled) {
            return;
        }

        const preventEvent = (event: MouseEvent | KeyboardEvent) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        };
        const preventKeyboardEvent = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === ' ') {
                preventEvent(event);
            }
        };

        elem.addEventListener('click', preventEvent, true);
        elem.addEventListener('keydown', preventKeyboardEvent, true);
        elem.addEventListener('keyup', preventKeyboardEvent, true);
        elem.addEventListener('keypress', preventKeyboardEvent, true);

        return () => {
            elem.removeEventListener('click', preventEvent, true);
            elem.removeEventListener('keydown', preventKeyboardEvent, true);
            elem.removeEventListener('keyup', preventKeyboardEvent, true);
            elem.removeEventListener('keypress', preventKeyboardEvent, true);
        };
    }, [disabled]);

    const sharedRef = useMultipleRefCallback(elementRef, ref);

    const defaultTabIndex = buttonAsDiv ? 0 : undefined;
    const tabIndex = tabIndexProp ?? disabled ? -1 : defaultTabIndex;

    const defaultAriaDisabled = disabled ? true : undefined;
    const ariaDisabled = ariaDisabledProp ?? defaultAriaDisabled;

    const className = classNames(classNameProp, disabled && 'cursor-default');

    // Used to trigger onClick when focusing and pressing on enter on a div
    const onDivKeyDown = useCompositeEventCallback(props.onKeyDown as DivPropsWithRef['onKeyDown'], (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            props.onClick?.(event as any);
        }
    });

    const sharedProps = {
        'aria-disabled': ariaDisabled,
        tabIndex,
        className,
        ...props,
        ref: sharedRef,
    };

    return (
        <>
            {buttonAsDiv ? (
                <div role="button" {...(sharedProps as any)} onKeyDown={onDivKeyDown}>
                    {children}
                </div>
            ) : (
                // eslint-disable-next-line react/button-has-type
                <button {...(sharedProps as any)} type={type}>
                    {children}
                </button>
            )}
        </>
    );
};

export default React.forwardRef(DisableableButton) as React.FC<PropsWithRef>;
