import React, { useState } from 'react';
import styled from 'styled-components';

import ContextWrapper from './ContextWrapper';
import Dialogs from './Dialogs/Dialogs';
import type { OptimizedImageProps } from './OptimizedImage';
import { MODAL_Z_INDEX } from './TUI/Modal/ModalWrapper';
import { ROOT_PORTAL_CONTAINER_ID } from './TUI/portalConsts';
import { IsTabActiveContext } from '../hooks/useIsTabActive/useIsTabActive';
import useSyncIsTabActiveFromDocument from '../hooks/useIsTabActive/useSyncIsTabActiveFromDocument';

import type { TonkeanRouter } from '@tonkean/tui-basic/RouterLink';

const PortalContainer = styled.div<{ $fixed: boolean }>`
    position: ${({ $fixed }) => ($fixed ? 'fixed' : 'absolute')};
    z-index: ${MODAL_Z_INDEX};
    top: 0;
    left: 0;
    right: 0;
`;

export const ReactRootPortalContext = React.createContext<(value: boolean) => void>(() => {});

interface Props {
    router: TonkeanRouter;
    optimizedImage: React.ComponentType<OptimizedImageProps> | undefined;
}

const ReactRoot: React.FC<React.PropsWithChildren<Props>> = ({ children, router, optimizedImage }) => {
    const [fixedPositionPortal, setFixedPositionPortal] = useState(false);

    const isTabIsActive = useSyncIsTabActiveFromDocument();

    return (
        <React.StrictMode>
            <ReactRootPortalContext.Provider value={setFixedPositionPortal}>
                <IsTabActiveContext.Provider value={isTabIsActive}>
                    <ContextWrapper optimizedImage={optimizedImage} router={router}>
                        {children}
                        <Dialogs />
                    </ContextWrapper>
                </IsTabActiveContext.Provider>
            </ReactRootPortalContext.Provider>
            <PortalContainer id={ROOT_PORTAL_CONTAINER_ID} $fixed={fixedPositionPortal} />
        </React.StrictMode>
    );
};

export default ReactRoot;
