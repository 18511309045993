import type { FieldType } from '../../FieldDefinition';
import { FieldDefinitionType } from '../FieldDefinitionType';
import type FormulaAllowedParams from '../FormulaAllowedParams';
import type { FormulaFieldArray, FormulaSingleField } from '../FormulaField';
import type FormulaField from '../FormulaField';
import type FormulaFieldsMetadata from '../FormulaFieldsMetadata';
import type FormulaTreeNodeBase from '../formulaTreeNodes/FormulaTreeNodeBase';
import { OperatorFamily } from '../OperatorFamily';
import type { OperatorKey } from '../OperatorKey';
import type ValidationError from '../validationErrors/ValidationError';

import { toArray } from '@tonkean/utils';
import { memoize } from '@tonkean/utils';

abstract class FormulaOperatorDefinitionBase {
    /**
     * Counter to create a unique id for each node.
     */
    private static idCount: number = 0;
    /**
     * Operator's unique id, to be used as key in react.
     */
    public id: number = FormulaOperatorDefinitionBase.idCount++;

    /** Key to identify the operator */
    public abstract readonly key: OperatorKey;
    /** The operator family of the operator */
    public abstract readonly family: OperatorFamily;
    /** The return type of the operator. **If it is a function, it will be called only of the validation has succeeded** */
    public abstract readonly dataType: FieldType | ((operands: FormulaTreeNodeBase[]) => FieldType | undefined);
    /** How the users sees this operator inside the formula */
    public abstract readonly sign: string;
    /** The name of the operator that will be displayed to the user */
    public abstract readonly displayName: string;
    /** The description for the formula that the user will see */
    public abstract readonly description: string;

    /** Whether this formula is only for aggregation */
    public readonly aggregationOnly: boolean = false;

    /** Whether this formula is deprecated */
    public readonly deprecated: boolean = false;

    /**
     * Does the component has a specific editor?
     * If set to true, you must add it to the `switch` in the `SpecificEditor.tsx` component.
     */
    public readonly specificEditor: boolean = false;

    /**
     * List of formula field definitions to be used in the formula, ordered by their appearance. If a formula has
     * a dynamic field length, this property should contain a field array, surrounded by the non-dynamic fields.
     * A formula cannot have more than one field array, but can contain only single fields or both single fields and
     * a single array field.
     */
    public abstract readonly fields: (FormulaSingleField | FormulaFieldArray)[];

    /**
     * Formula fields metadata
     */
    public fieldsMetadata: FormulaFieldsMetadata;

    /**
     * Validates a formula. **This method is being called after the fields have been validated and there are no empty fields.**
     * Should be used only if the dataType validation and the matching symbol validation is not enough - but in most cases they are enough.
     *
     * @param operands - list of operands
     * @returns true if the formula is valid, string is not
     */
    public validate(operands: FormulaTreeNodeBase[]): true | ValidationError[];
    public validate(): true | ValidationError[] {
        return true;
    }

    /**
     * Function that convert the operator to a formula string with placeholders in the fields
     *
     * @returns the formula string template
     */
    public toString(): string {
        const fields = this.getFieldsList().map((field) => this.fieldToStringPlaceholder(field));

        switch (this.family) {
            case OperatorFamily.LOGICAL:
            case OperatorFamily.ARITHMETIC: {
                const [firstField, secondField] = fields;
                return `(${firstField} ${this.sign} ${secondField})`;
            }

            case OperatorFamily.FUNCTION: {
                const operands = fields.join(', ');
                return `${this.sign}(${operands})`;
            }
        }
    }

    public isAllowed(_: FormulaAllowedParams): boolean {
        return true;
    }

    /**
     * Gets list of field definitions with metadata.
     *
     * @param operandsCount - the amount of operands in the formula
     * @returns list of formula field definitions.
     */
    public getFieldsList = memoize((operandsCount?: number): [FormulaField, ...FormulaField[]] => {
        this.createMetadata();

        const operandsCountWithFallback = operandsCount ?? this.fieldsMetadata.defaultOperatorsCount;

        // Creating the field list, going over all field definitions (Single field or array field) and create a list fields.
        const fields = this.fields.flatMap((field) => {
            if (field.fieldDefinitionType === FieldDefinitionType.SINGLE) {
                return {
                    ...field,
                    metadata: { fieldDefinitionType: FieldDefinitionType.SINGLE },
                };
            }

            // Calculating the number of array operands
            const numberOfUsedTuples = Math.ceil(
                (operandsCountWithFallback - this.fieldsMetadata.singleFieldsCount) /
                    this.fieldsMetadata.arrayTupleSize,
            );
            const numberOfTuplesOrMinimum = Math.max(numberOfUsedTuples, field.minRepeats);

            // Go over the number of tuples in the
            return Array.from({ length: numberOfTuplesOrMinimum }).flatMap((_, arrayIndex) => {
                const fields = toArray(field.generator(arrayIndex + 1));

                // Go over the fields in the tuple
                return fields.map((field, fieldIndex) => {
                    const firstInTuple = fieldIndex === 0;
                    const firstInArray = firstInTuple && arrayIndex === 0;
                    const lastInTuple = fieldIndex === fields.length - 1;
                    const lastInArray = lastInTuple && arrayIndex === numberOfTuplesOrMinimum - 1;

                    const metadata = {
                        fieldDefinitionType: FieldDefinitionType.ARRAY,
                        inArrayIndex: arrayIndex,
                        inTupleIndex: fieldIndex,
                        firstInArray,
                        lastInArray,
                        firstInTuple,
                        lastInTuple,
                    };

                    return {
                        ...field,
                        metadata,
                    };
                });
            });
        });

        return fields.map((field, index) => ({
            ...field,
            metadata: {
                ...field.metadata,
                inOperandIndex: index,
                firstInOperand: index === 0,
                lastInOperand: index === fields.length - 1,
            },
        })) as [FormulaField, ...FormulaField[]];
    });

    /**
     * Calculates fields metadata. Runs only once.
     */
    private createMetadata() {
        if (this.fieldsMetadata) {
            return;
        }

        const arrayFieldIndex = this.fields.findIndex(
            (field) => field.fieldDefinitionType === FieldDefinitionType.ARRAY,
        );
        const hasArrayField = arrayFieldIndex !== -1;

        const fieldsBeforeArray = hasArrayField ? arrayFieldIndex : this.fields.length;
        const arrayField = hasArrayField ? (this.fields[arrayFieldIndex] as FormulaFieldArray) : undefined;
        const fieldsAfterArray = hasArrayField ? this.fields.length - (fieldsBeforeArray + 1) : 0;

        const singleFieldsCount = fieldsAfterArray + fieldsBeforeArray;
        const arrayTupleSize = toArray(arrayField?.generator(0) || []).length;
        const defaultOperatorsCount =
            singleFieldsCount + ((arrayField && arrayField.defaultRepeats * arrayTupleSize) || 0);
        const minOperands = singleFieldsCount + ((arrayField && arrayField.minRepeats * arrayTupleSize) || 0);

        this.fieldsMetadata = {
            arrayTupleSize,
            defaultOperatorsCount,
            singleFieldsCount,
            arrayField,
            fieldsBeforeArray,
            fieldsAfterArray,
            minOperands,
        };
    }

    /**
     * Converts a field to a string placeholder (the display name, surrounded by curly braces,
     * to look like a formula variable).
     *
     * @param field - the field of the placeholder.
     * @returns the placeholder.
     */
    private fieldToStringPlaceholder(field: FormulaField): string {
        return `{${field.displayName}}`;
    }
}

export default FormulaOperatorDefinitionBase;
