import React from 'react';
import styled, { css } from 'styled-components';

import DatepickerInput from '../DatepickerInput';
import { Field } from '../Field';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Datepicker = styled(DatepickerInput)<{ width: DatepickerWidth }>`
    width: ${(props) => props.width}px;
`;

const DateSeparator = styled.div<{ horizontalLayout?: boolean }>`
    margin: 10px 0;
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    color: ${Theme.colors.gray_500};

    ${({ horizontalLayout }) =>
        horizontalLayout &&
        css`
            margin: auto 10px;
        `}
`;

export enum DatepickerWidth {
    short = 160,
    wide = 180,
}

interface Props {
    fromDate?: Date | undefined | null;
    onFromDateChange?(newDate: Date | null): void;
    toDate?: Date | undefined | null;
    onToDateChange?(newDate: Date | null): void;
    showTimeSelect?: boolean;
    width?: DatepickerWidth;
    fromName?: string;
    toName?: string;
    horizontalLayout?: boolean;
    fromPlaceholder?: string;
    toPlaceholder?: string;
}

const FilterDateRange: React.FC<Props> = ({
    fromDate,
    toDate,
    onFromDateChange,
    onToDateChange,
    showTimeSelect,
    width = DatepickerWidth.wide,
    fromName,
    toName,
    horizontalLayout,
    fromPlaceholder,
    toPlaceholder,
}) => {
    return (
        <>
            <Field>
                <Datepicker
                    width={width}
                    selected={fromDate}
                    onChange={onFromDateChange}
                    maxDate={toDate}
                    showTimeSelect={showTimeSelect}
                    name={fromName}
                    placeholderText={fromPlaceholder}
                />
            </Field>

            <DateSeparator horizontalLayout={horizontalLayout}>To</DateSeparator>

            <Field>
                <Datepicker
                    width={width}
                    selected={toDate}
                    onChange={onToDateChange}
                    minDate={fromDate}
                    showTimeSelect={showTimeSelect}
                    name={toName}
                    placeholderText={toPlaceholder}
                />
            </Field>
        </>
    );
};

export default FilterDateRange;
