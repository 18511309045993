enum ActionType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    CREATE_OR_UPDATE = 'CREATE_OR_UPDATE',
    CUSTOM = 'CUSTOM',
    OAUTH2_REFRESH_AUTHENTICATION = 'OAUTH2_REFRESH_AUTHENTICATION',
    FETCHING = 'FETCHING',
    METADATA = 'METADATA',
    UPLOAD = 'UPLOAD',
    DOWNLOAD = 'DOWNLOAD',
    CUSTOM_AUTHENTICATION = 'CUSTOM_AUTHENTICATION',
    STREAM_IMAGE = 'STREAM_IMAGE',
}

export default ActionType;
