enum UserMessagePreference {
    NONE = 0,
    NO_ALL = 1,
    NO_ACTIVITY_DIGEST = 2,
    NO_NOTIFICATION = 4,
    NO_QUESTION = 8,
    NO_DISCOVERY = 16,
    NO_UNANSWERED_REMINDER = 32,
    NO_GATHER_UPDATE = 64,
    NO_SET_OWNER = 128,
    NO_OVERDUE = 256,
    NO_INITIATIVE_NOT_ANSWERED = 512,
    NO_SUMMARY_REPORT = 1024,
    NO_DIGEST_READY_MESSAGE = 2048,
    NO_SCHEDULED_REPORT = 4096,
}
export default UserMessagePreference;
