import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import HighlightableText from '../HighlightableText';
import { Checkbox, KeyboardArrowFocusSwitchContext } from '../TUI';

import { ButtonShape } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const RowWrapper = styled(Clickable)<{ $topBorder: boolean; $highlighted: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    min-height: 64px;
    ${({ $topBorder }) => $topBorder && `border-top: 1px solid ${Theme.colors.gray_300}`};
    ${({ $highlighted }) => $highlighted && `background-color: ${Theme.colors.gray_100}`};
    padding: 0 10px;

    justify-content: space-between;
`;

const StyledHighlightableText = styled(HighlightableText)`
    flex-grow: 1;
`;

type Props<T extends string> = {
    id: T;
    isHighlighted: boolean;
    topBorder: boolean;
    disabled: boolean | undefined;
    onHover: () => void;
    onMouseLeave: () => void;
    onClick: () => void;
    checked: boolean;
    showCheckbox: boolean;
    text: string;
    highlightText: string | undefined;
};

const FieldsImporterRow = <T extends string>({
    id,
    disabled,
    topBorder,
    isHighlighted,
    onHover,
    onMouseLeave,
    onClick,
    showCheckbox,
    checked,
    text,
    highlightText,
}: Props<T>) => {
    const ref = useRef<HTMLElement>(null);
    const { addNode, onKeyDown } = useContext(KeyboardArrowFocusSwitchContext);

    useEffect(() => {
        if (ref.current === null) return;

        const remove = addNode(ref.current);

        return () => {
            remove();
        };
    }, [addNode]);

    return (
        <RowWrapper
            ref={ref}
            data-automation={`fields-importer-row-id-${id}`}
            $highlighted={isHighlighted}
            $topBorder={topBorder}
            onMouseEnter={onHover}
            onMouseLeave={onMouseLeave}
            onKeyDown={onKeyDown}
            key={id}
            onClick={onClick}
            disabled={disabled}
            buttonAsDiv
        >
            <StyledHighlightableText
                data-automation="fields-importer-row-name"
                text={text}
                highlightText={highlightText}
            />

            {showCheckbox && (
                <Checkbox
                    dataAutomation="fields-importer-row-checkbox"
                    shape={ButtonShape.ROUND}
                    onChange={onClick}
                    size={InputSize.LARGE}
                    disabled={disabled}
                    checked={checked}
                />
            )}
        </RowWrapper>
    );
};

export default FieldsImporterRow;
