import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { ClickableButton } from './Button';

import { CONTRAST_GUIDELINES_LIGHT_TEXT, getTextColorByBackgroundColor } from '@tonkean/tui-basic/utils';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

interface ButtonColors {
    color: string;
    background: string;
    hoverColor: string;
    hoverBackground: string;
}

/**
 * Calculates the colors and hover colors for the button.
 *
 * The colors reside on a spectrum, where the contrast threshold is defined to be between A and B.
 * A | B
 *
 * Colors in A need to be darkened, while in B need to be lightened on hover.
 *
 *  There is a zone where that rule does not apply, because if we lighten a color that is in the A zone but is close to
 *  the threshold the text color would flip and would look weird.
 *  So the actual spectrum looks like
 *  color:                  A | Am | Bm | B
 *  relative hover color   -> | <- | -> | <-
 *
 *  where in the modified areas you reverse the direction of the hover color
 *
 *  ** I simplified it by only looking at it as one axis, but its true to both color axises
 */
const getUserThemedButtonColors = (background: string): ButtonColors => {
    const color = getTextColorByBackgroundColor(background);
    const underContrastThreshold = color === CONTRAST_GUIDELINES_LIGHT_TEXT;

    // Calcualte the hover color initially, being optimistic we are in the A or B zone
    let hoverBackground = underContrastThreshold ? lighten(0.12, background) : darken(0.06, background);
    let hoverColor = getTextColorByBackgroundColor(hoverBackground);

    // Check if we crossed the contrast threshold by checking if the text color has flipped
    if (color !== hoverColor) {
        // if so calculate the new hover colors by using the opposite method
        hoverBackground = underContrastThreshold ? darken(0.06, background) : lighten(0.12, background);
        hoverColor = getTextColorByBackgroundColor(hoverBackground);
    }

    return { color, background, hoverColor, hoverBackground };
};

const getAlternativeUserThemedButtonColors = (primaryColor: string): ButtonColors => {
    return {
        color: primaryColor,
        background: Theme.colors.basicBackground,
        hoverColor: primaryColor,
        hoverBackground: '#F9F9F7',
    };
};

const UserThemedClickableButton = styled(ClickableButton)<{
    background?: string;
    disabled?: boolean;
    $useAlternativeColors?: boolean;
    $dontShowAsDisabled?: boolean;
    $autoSvgColor?: boolean;
    openFormsInModal?: boolean;
}>`
    align-items: center;
    justify-content: center;

    // When disabled, use default disabled behaviour, otherwise use colors according to provided props
    ${({ disabled, $dontShowAsDisabled, background, $useAlternativeColors, $autoSvgColor }) => {
        const ignoreDisabled = disabled && !$dontShowAsDisabled;
        if (ignoreDisabled || !background) {
            return;
        }

        const {
            color,
            background: finalBackground,
            hoverColor,
            hoverBackground,
        } = $useAlternativeColors
            ? getAlternativeUserThemedButtonColors(background)
            : getUserThemedButtonColors(background);

        return css`
            background-color: ${finalBackground};
            color: ${color};
            border-color: ${color};

            ${$autoSvgColor &&
            color &&
            css`
                svg {
                    ${colorSvg(color)}
                }
                ${colorSvg(color)}
            `}

            &:hover,
            &:focus {
                color: ${hoverColor};
                border-color: ${hoverColor};
                ${$autoSvgColor &&
                hoverColor &&
                css`
                    svg {
                        ${colorSvg(hoverColor)}
                    }
                    ${colorSvg(hoverColor)}
                `}
                background-color: ${hoverBackground};
            }
        `;
    }}
`;

export default UserThemedClickableButton;
