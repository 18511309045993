import { useCallback, useEffect, useMemo, useState } from 'react';

import useCloseCallback from '../components/TUI/CloseCallback/useCloseCallback';

import { filtersHelper } from '@tonkean/utils';

/**
 * React hook that helps with managing the filters form. This if useful when you have clear and submit buttons on the
 * filters popover.
 *
 * @param savedFilters - the currently used filters.
 * @param defaultFilters - the filters to restore when clearing.
 * @param onChange - the function to call when submitting or clearing the filters.
 * @returns an array, with the first item as object that have onClear, canClear, onSubmit and canSubmit, the second and
 * third items are the inner filters state and it's setter.
 */
function useSubmittableFilters<T extends Record<string, any>>(
    savedFilters: T,
    defaultFilters: T,
    onChange: (filters: T) => void,
) {
    const closePopover = useCloseCallback();

    const [filters, setFilters] = useState(savedFilters);

    useEffect(() => {
        setFilters(savedFilters);
    }, [savedFilters]);

    const initialFiltersHelper = useMemo(() => {
        return filtersHelper(defaultFilters);
    }, [defaultFilters]);

    const currentlyActiveFiltersHelper = useMemo(() => {
        return filtersHelper(savedFilters);
    }, [savedFilters]);

    const canClear = useMemo(() => {
        return initialFiltersHelper.hadChanged(savedFilters);
    }, [savedFilters, initialFiltersHelper]);

    const onClear = useCallback(() => {
        onChange(defaultFilters);
        closePopover();
    }, [closePopover, defaultFilters, onChange]);

    const canSubmit = useMemo(() => {
        return currentlyActiveFiltersHelper.hadChanged(filters);
    }, [currentlyActiveFiltersHelper, filters]);

    const onSubmit = useCallback(() => {
        onChange(filters);
        closePopover();
    }, [closePopover, filters, onChange]);

    return [{ canClear, onClear, canSubmit, onSubmit }, filters, setFilters] as const;
}

export default useSubmittableFilters;
