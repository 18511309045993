import * as Yup from 'yup';
import type { Message } from 'yup/lib/types';

function yupTonkeanExpression(message?: Message<{ values: any }>) {
    return Yup.object({
        originalExpression: message ? Yup.string().required(message) : Yup.string(),
        evaluatedExpression: message ? Yup.string().required(message) : Yup.string(),
        isStripHtmlDisabled: Yup.boolean(),
    });
}

export default yupTonkeanExpression;
