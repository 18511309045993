enum ActivityType {
    INITIATIVE_CREATED = 'INITIATIVE_CREATED',
    INITIATIVE_DESCRIPTION_CHANGED = 'INITIATIVE_DESCRIPTION_CHANGED',
    INITIATIVE_DUE_DATE_CHANGED = 'INITIATIVE_DUE_DATE_CHANGED',
    INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED = 'INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED',
    INITIATIVE_LEADER_CHANGED = 'INITIATIVE_LEADER_CHANGED',
    INITIATIVE_STATUS_CHANGED = 'INITIATIVE_STATUS_CHANGED',
    INITIATIVE_TAGS_CHANGED = 'INITIATIVE_TAGS_CHANGED',
    INITIATIVE_TITLE_CHANGED = 'INITIATIVE_TITLE_CHANGED',
    NEW_COMMENT = 'NEW_COMMENT',
    NEW_INITIATIVE = 'NEW_INITIATIVE',
    INITIATIVE_MANUAL_FIELD_VALUE_CHANGED = 'INITIATIVE_MANUAL_FIELD_VALUE_CHANGED',
    BOT_GATHER = 'BOT_GATHER',
    ITEM_INTERFACE_CREATED = 'ITEM_INTERFACE_CREATED',
    ITEM_INTERFACE_UPDATED = 'ITEM_INTERFACE_UPDATED',
    ITEM_INTERFACE_DELETED = 'ITEM_INTERFACE_DELETED',
    ITEM_INTERFACE_WIDGET_CREATED = 'ITEM_INTERFACE_WIDGET_CREATED',
    ITEM_INTERFACE_WIDGET_UPDATED = 'ITEM_INTERFACE_WIDGET_UPDATED',
    ITEM_INTERFACE_WIDGET_DELETED = 'ITEM_INTERFACE_WIDGET_DELETED',
    ITEM_INTERFACE_SET_AS_DEFAULT = 'ITEM_INTERFACE_SET_AS_DEFAULT',
    ITEM_INTERFACE_REMOVED_AS_DEFAULT = 'ITEM_INTERFACE_REMOVED_AS_DEFAULT',
    ITEM_INTERFACE_LOGO_ADDED = 'ITEM_INTERFACE_LOGO_ADDED',
    ITEM_INTERFACE_LOGO_REMOVED = 'ITEM_INTERFACE_LOGO_REMOVED',
    ITEM_DETAILS_UPDATED = 'ITEM_DETAILS_UPDATED',
    MODULE_CREATED_FROM_MODULE_DUPLICATED = 'MODULE_CREATED_FROM_MODULE_DUPLICATED',
    ITEM_DETAILS_CREATED = 'ITEM_DETAILS_CREATED',
    FORM_SUBMITTED = 'FORM_SUBMITTED',
    ITEM_INTERFACE_SUBMITTED = 'ITEM_INTERFACE_SUBMITTED',
    ITEM_INTERFACE_BUTTON_CLICKED = 'ITEM_INTERFACE_BUTTON_CLICKED',
    INNER_INITIATIVE_CREATED = 'INNER_INITIATIVE_CREATED',
    INITIATIVE_FILE_UPLOADED = 'INITIATIVE_FILE_UPLOADED',
    ITEM_INTERFACE_WIDGETS_SWAPPED = 'ITEM_INTERFACE_WIDGETS_SWAPPED',
    SOLUTION_SITE_PAGE_WIDGETS_SWAPPED = 'SOLUTION_SITE_PAGE_WIDGETS_SWAPPED',
}

export default ActivityType;
