import type TransitionCallbacks from '../TransitionCallbacks';
import type TransitionClassNames from '../TransitionClassNames';
import TransitionSubState from '../TransitionSubState';
import capitalize from '../utils/capitalize';
import transitionSubStateToPostfix from '../utils/transitionSubStateToPostfix';

abstract class TransitionState {
    public abstract readonly stateName: string;

    /**
     * Sets the sub state as start and triggers the start callbacks.
     *
     * @param setSubState - a function that sets the sub state.
     * @param callbacks - object with the callbacks (onEnter, onEntering, etc).
     */
    public setStartSubState(setSubState: (value: TransitionSubState) => void, callbacks: TransitionCallbacks): void {
        setSubState(TransitionSubState.START);
        this.setStartSubStateImplementation(callbacks);
    }

    /**
     * Sets the sub state as active and triggers the active callbacks.
     *
     * @param setSubState - a function that sets the sub state.
     * @param callbacks - object with the callbacks (onEnter, onEntering, etc).
     */
    public setActiveSubState(setSubState: (value: TransitionSubState) => void, callbacks: TransitionCallbacks): void {
        setSubState(TransitionSubState.ACTIVE);
        this.setActiveSubStateImplementation(callbacks);
    }

    /**
     * Sets the sub state as done, triggers the done callbacks and unmounts if it's an exit transition and unmountOnExit
     * is true.
     *
     * @param setSubState - a function that sets the sub state.
     * @param callbacks - object with the callbacks (onEnter, onEntering, etc).
     * @param shouldUnmountOnExit - should unmount the component on exit?
     * @param triggerUnmount - a function that triggers an unmount.
     */
    public setDoneSubState(
        setSubState: (value: TransitionSubState) => void,
        callbacks: TransitionCallbacks,
        shouldUnmountOnExit: boolean,
        triggerUnmount: () => void,
    ): void {
        setSubState(TransitionSubState.DONE);
        this.setDoneSubStateImplementation(callbacks, shouldUnmountOnExit, triggerUnmount);
    }

    public getClassName(
        classNamesObjectOrPrefix: string | TransitionClassNames | undefined,
        subState: TransitionSubState,
    ): string {
        const className =
            classNamesObjectOrPrefix && typeof classNamesObjectOrPrefix === 'object'
                ? this.getClassNameFromObject(classNamesObjectOrPrefix, subState)
                : this.generateClassName(classNamesObjectOrPrefix, subState);

        if (subState === TransitionSubState.ACTIVE) {
            const startClassName = this.getClassName(classNamesObjectOrPrefix, TransitionSubState.START);
            return [startClassName, className].filter(Boolean).join(' ');
        }

        return className || '';
    }

    /**
     * Generate a class name based on the sub state and the optional prefix.
     *
     * @param optionalClassNamePrefix - the optional prefix to append to the class name.
     * @param subState - the current sub state.
     * @returns the relevant class name, prefixed with the optionalClassNamePrefix if given.
     */
    private generateClassName(optionalClassNamePrefix: string | undefined, subState: TransitionSubState): string {
        const optionalSubStatePostfix = transitionSubStateToPostfix[subState];
        return [optionalClassNamePrefix, this.stateName, optionalSubStatePostfix].filter(Boolean).join('-');
    }

    /**
     * Get the class name from the provided class name object based on the sub state.
     *
     * @param classNamesObject - the given class names object.
     * @param subState - the current sub state.
     * @returns the relevant class name from the classNamesObject, or undefined.
     */
    private getClassNameFromObject(
        classNamesObject: TransitionClassNames,
        subState: TransitionSubState,
    ): string | undefined {
        const subStatePostfix = capitalize(transitionSubStateToPostfix[subState]);
        const key = `${this.stateName}${subStatePostfix}` as keyof TransitionClassNames;

        return classNamesObject[key];
    }

    protected abstract setStartSubStateImplementation(callbacks: TransitionCallbacks): void;
    protected abstract setActiveSubStateImplementation(callbacks: TransitionCallbacks): void;
    protected abstract setDoneSubStateImplementation(
        callbacks: TransitionCallbacks,
        shouldUnmountOnExit: boolean,
        triggerUnmount: () => void,
    ): void;
}

export default TransitionState;
