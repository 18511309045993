import { css } from 'styled-components';

function colorSvg(color: string, transition: boolean = false) {
    return css`
        [fill] {
            fill: ${color};
        }
        [stroke] {
            stroke: ${color};
        }

        ${transition &&
        css`
            [fill],
            [stroke] {
                transition: all 0.3s ease-in-out;
            }
        `}
    `;
}

export default colorSvg;
