import styled, { css } from 'styled-components';

import StateLink from '@tonkean/tui-basic/StateLink';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

const SideMenuItem = styled(StateLink)<{ selected?: boolean; secondary?: string }>`
    display: flex;
    align-items: center;

    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: ${Theme.colors.gray_700};

    border: none;
    background: transparent;
    padding: 0;
    margin: 0;

    ${styledFocus}

    ${({ selected }) =>
        selected &&
        css`
            font-size: 12px;
            font-weight: 500;
            color: ${Theme.colors.primaryHighlight};
        `};

    &:not(:first-child) {
        margin-top: 16px;
    }

    &:hover,
    &:focus {
        color: ${Theme.colors.primaryHighlight};
    }

    &:focus:not(:hover) {
        text-decoration: none;
    }
`;

export default SideMenuItem;
