import { useEffect, useState } from 'react';

import { ROOT_PORTAL_CONTAINER_ID } from '../components/TUI/portalConsts';

const containersCache: Record<string, HTMLElement> = {};

function useSSRDocument(
    containerSelector: string = `#${ROOT_PORTAL_CONTAINER_ID}`,
    forceFindPortalContainer: boolean = false,
) {
    const [defaultContainer, setDefaultContainer] = useState(containersCache[containerSelector]);

    useEffect(() => {
        if (!defaultContainer || forceFindPortalContainer) {
            const newDefaultContainer = document.querySelector(containerSelector) as HTMLDivElement;
            setDefaultContainer(newDefaultContainer);
            containersCache[containerSelector] = newDefaultContainer;
        }
    }, [containerSelector, defaultContainer, forceFindPortalContainer]);

    return defaultContainer;
}

export default useSSRDocument;
