import React from 'react';
import styled from 'styled-components';

import EnvironmentIndicator from './EnvironmentIndicator';
import Header from '../Headers/Header';

import type { Environment } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const EnvironmentWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    margin-right: 20px;
`;

const CloseButton = styled(Button)`
    margin-left: 20px;
`;

interface Props extends StyledComponentsSupportProps {
    onCloseClick?: () => void;
    closeButtonDataAutomation?: string;
    /**
     * If set to true then the right padding of the environment indicator will be 60px otherwise 20px.
     */
    widePadding?: boolean;
    environmentIsActive?: boolean;
    currentEnvironment?: Environment;
    onEnvironmentChange?: (environment: Environment) => void;
    disabledEnvironments?: Record<Environment, string | boolean>;
}

const EnvironmentHeader: React.FC<React.PropsWithChildren<Props>> = ({
    onCloseClick,
    closeButtonDataAutomation,
    className,
    children,
    environmentIsActive,
    currentEnvironment,
    onEnvironmentChange = () => {},
    disabledEnvironments,
}) => {
    return (
        <Header
            className={className}
            title={children}
            rightSideOfHeader={
                currentEnvironment && (
                    <EnvironmentWrapper>
                        <EnvironmentIndicator
                            environmentIsActive={environmentIsActive}
                            currentEnvironment={currentEnvironment}
                            onChanges={onEnvironmentChange}
                            disabledEnvironments={disabledEnvironments}
                            showActiveStatusIndicator
                        />
                        {onCloseClick && (
                            <CloseButton
                                type="button"
                                onClick={onCloseClick}
                                data-automation={closeButtonDataAutomation}
                                cancel
                            >
                                Close
                            </CloseButton>
                        )}
                    </EnvironmentWrapper>
                )
            }
        />
    );
};

export default EnvironmentHeader;
