import WorkerRunStage from './WorkerRunStage';

const workerRunStageToPathParam: Record<WorkerRunStage, string> = {
    [WorkerRunStage.DATA_SOURCE]: 'data-source',
    [WorkerRunStage.FLOW_RUN]: 'flow-run',
    [WorkerRunStage.MODULE_ITEM]: 'module-item',
};

export const pathParamToWorkerRunStage: Record<string, WorkerRunStage> = Object.fromEntries(
    Object.entries(workerRunStageToPathParam).map(
        ([workerRunStage, pathParam]) => [pathParam, workerRunStage as WorkerRunStage] as const,
    ),
);

export default workerRunStageToPathParam;
