/**
 * Debounces functions so only the latest will operate (in the given interval).
 *
 * @example
 * const alertDebounce = debounce(100);
 * document.querySelector("input").onChange = (event) => {
 *     alertDebounce(() => alert(`You typed: ${event.target.value}`)
 * });
 *
 * @param interval - the amount of time, in milliseconds, to wait from the last callback change before triggering it
 * @returns a function to trigger every time you want to reset the debounce, and add the function to debounce as the first param.
 * If you want to skip the debouncing for a specific call you can also pass true as the second param, to run the callback immediately.
 */
function debounce(interval: number = 100) {
    let timeout: ReturnType<typeof setTimeout>;

    return (callback: () => void, runNow?: boolean) => {
        if (timeout) {
            // Clear the existing interval
            clearTimeout(timeout);
        }

        if (runNow) {
            callback();
        } else {
            // Set a timeout that will fire the callback when the time passes
            timeout = setTimeout(() => {
                callback();
            }, interval);
        }
    };
}

export default debounce;
