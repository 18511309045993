import { useContext, useEffect } from 'react';

import RadioGroupContext from './RadioGroupContext';
import useUUID from '../../../hooks/useUUID';
import useInnerField from '../Field/useInnerField';

function useRadio(value?: any) {
    const {
        addRadio,
        name,
        size,
        changeLabelColor,
        value: checkedValue,
        onChange: onChangeGroup,
        radioGroupDisabled,
        selectedColor,
        unselectedColor,
        boldLabel,
    } = useContext(RadioGroupContext);

    const fakeValue = useUUID();

    const [props, , formikHelpers] = useInnerField({
        name,
        value,
        type: 'radio',
        multiple: false,
        checked: checkedValue === undefined ? undefined : value === checkedValue,
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeGroup?.(event);

        if (event.target.value === fakeValue) {
            formikHelpers?.setValue(value);
        }
    };

    useEffect(() => {
        const remove = addRadio(value, fakeValue);

        return () => {
            remove();
        };
    }, [addRadio, fakeValue, value]);

    return {
        props,
        onChange,
        radioGroupDisabled,
        fakeValue,
        changeLabelColor,
        size,
        selectedColor,
        unselectedColor,
        boldLabel,
    };
}

export default useRadio;
