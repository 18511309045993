import type { TElement } from '@udecode/plate';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { Node } from 'slate';

import { toArray } from './toArray';

export class UtilsClass {
    protected readonly commonWords = [
        'the',
        'be',
        'to',
        'of',
        'and',
        'a',
        'in',
        'that',
        'have',
        'I',
        'it',
        'for',
        'not',
        'on',
        'with',
        'he',
        'as',
        'you',
        'do',
        'at',
        'this',
        'but',
        'his',
        'by',
        'from',
        'they',
        'we',
        'say',
        'her',
        'she',
        'or',
        'an',
        'will',
        'my',
        'one',
        'all',
        'would',
        'there',
        'their',
        'what',
        'so',
        'up',
        'out',
        'if',
        'about',
        'who',
        'get',
        'which',
        'go',
        'me',
        'is',
    ];

    protected readonly commonDomains = [
        /* Default domains included */
        'aol.com',
        'att.net',
        'comcast.net',
        'facebook.com',
        'gmail.com',
        'gmx.com',
        'googlemail.com',
        'hotmail.com',
        'hotmail.co.uk',
        'mac.com',
        'me.com',
        'mail.com',
        'msn.com',
        'live.com',
        'sbcglobal.net',
        'verizon.net',
        'yahoo.com',
        'yahoo.co.uk',

        /* Other global domains */
        'email.com',
        'games.com' /* AOL */,
        'gmx.net',
        'hush.com',
        'hushmail.com',
        'icloud.com',
        'inbox.com',
        'lavabit.com',
        'love.com' /* AOL */,
        'outlook.com',
        'pobox.com',
        'rocketmail.com' /* Yahoo */,
        'safe-mail.net',
        'wow.com' /* AOL */,
        'ygm.com' /* AOL */,
        'ymail.com' /* Yahoo */,
        'zoho.com',
        'fastmail.fm',

        /* United States ISP domains */
        'bellsouth.net',
        'charter.net',
        'comcast.net',
        'cox.net',
        'earthlink.net',
        'juno.com',

        /* British ISP domains */
        'btinternet.com',
        'virginmedia.com',
        'blueyonder.co.uk',
        'freeserve.co.uk',
        'live.co.uk',
        'ntlworld.com',
        'o2.co.uk',
        'orange.net',
        'sky.com',
        'talktalk.co.uk',
        'tiscali.co.uk',
        'virgin.net',
        'wanadoo.co.uk',
        'bt.com',

        /* Domains used in Asia */
        'sina.com',
        'qq.com',
        'naver.com',
        'hanmail.net',
        'daum.net',
        'nate.com',
        'yahoo.co.jp',
        'yahoo.co.kr',
        'yahoo.co.id',
        'yahoo.co.in',
        'yahoo.com.sg',
        'yahoo.com.ph',

        /* French ISP domains */
        'hotmail.fr',
        'live.fr',
        'laposte.net',
        'yahoo.fr',
        'wanadoo.fr',
        'orange.fr',
        'gmx.fr',
        'sfr.fr',
        'neuf.fr',
        'free.fr',

        /* German ISP domains */
        'gmx.de',
        'hotmail.de',
        'live.de',
        'online.de',
        't-online.de' /* T-Mobile */,
        'web.de',
        'yahoo.de',

        /* Russian ISP domains */
        'mail.ru',
        'rambler.ru',
        'yandex.ru',
        'ya.ru',
        'list.ru',

        /* Belgian ISP domains */
        'hotmail.be',
        'live.be',
        'skynet.be',
        'voo.be',
        'tvcablenet.be',
        'telenet.be',

        /* Argentinian ISP domains */
        'hotmail.com.ar',
        'live.com.ar',
        'yahoo.com.ar',
        'fibertel.com.ar',
        'speedy.com.ar',
        'arnet.com.ar',

        /* Domains used in Mexico */
        'hotmail.com',
        'gmail.com',
        'yahoo.com.mx',
        'live.com.mx',
        'yahoo.com',
        'hotmail.es',
        'live.com',
        'hotmail.com.mx',
        'prodigy.net.mx',
        'msn.com',

        /* Temp emails */
        'mailinator.com',
    ];

    // Look for: '@' then all the characters until whitespace or end of string
    protected readonly AT_MENTION_REGEX = new RegExp('@.*?(\\s|$)', 'g');

    /**
     * Is an dictionary, array is empty.
     * @param obj can be a dictionary or array
     * @return false if the obj is a dictionary or array with data, otherwise true.
     */
    public isEmpty(obj: Record<any, any> | any[]): boolean {
        if (Array.isArray(obj) && obj.length) {
            return false;
        }

        if (typeof obj === 'object') {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    return false;
                }
            }
            return true;
        }

        return true;
    }

    /**
     * Returns whether given map is empty.
     */
    public isMapEmpty(map: Record<any, any>): boolean {
        if (!map) {
            return true;
        }
        for (const key in map) {
            if (map.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if an obj is null, undefined or an empty string and returns true or false accordingly.
     * @param obj - the object to check if null or empty.
     */
    public isNullOrEmpty(obj: any): boolean {
        return obj === null || obj === undefined || obj === '';
    }

    /**
     * Checks if an obj is null or if it has no custom properties.
     * @param obj - the object to check.
     * @returns {boolean}
     */
    public isNullOrNoProperties(obj: any): boolean {
        if (this.isNullOrUndefined(obj)) {
            return true;
        }

        // Check if this object has properties, only if this is an object.
        if (obj instanceof Object) {
            for (const property in obj) {
                if (obj.hasOwnProperty(property)) {
                    return false;
                }
            }
        }

        // Didn't find any properties in the object (or it's not an object), return that this is null.
        return true;
    }

    /**
     * Checks if an obj is null or undefined and returns true or false accordingly.
     * @param obj - the object to check if null or undefined.
     */
    public isNullOrUndefined(obj: unknown): boolean {
        return obj === null || obj === undefined;
    }

    /**
     * Checks if an obj is null or undefined and returns true or false accordingly.
     * @param obj - the object to check if null or undefined.
     */
    public isNotNullOrUndefined(obj: unknown): boolean {
        return !this.isNullOrUndefined(obj);
    }

    /**
     * Check whether the given array contains duplicate values.
     */
    public hasDuplicatesValues(array: unknown[]): boolean {
        return new Set(array).size !== array.length;
    }

    /**
     * Generate a new GUID.
     */
    public guid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replaceAll(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c == 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line eqeqeq
            return v.toString(16);
        });
    }

    /**
     * Returns a container id used in React to later on place angular elements inside it.
     * @param prefix - required. A prefix for the container id.
     * @param id - required. A unique id for this container.
     * @param id2 - optional. A second id, used to make this container id even more unique.
     * @param id3 - optional. A second id, used to make this container id even more unique.
     */
    public getReactAngularContainerId(prefix: string, id: string, id2?: string, id3?: string): string {
        let containerId = `${prefix}-${id}`;

        if (id2) {
            containerId += `-${id2}`;
        }
        if (id3) {
            containerId += `-${id3}`;
        }

        return containerId;
    }

    /**
     * Returns a company email domain if not common email provider.
     * @param email {String} an email
     * @return a company email domain if not common email provider
     */
    public getCompanyEmailDomain(email: string): string | null {
        if (typeof email === 'string') {
            const domain = email.split('@')[1];
            return domain && !this.isCommonDomain(domain) ? domain : null;
        }
        return null;
    }

    /**
     * Returns true if email is valid
     * @param email {String} an email
     * @return true if email is valid otherwise false
     */
    public isValidEmail(email: string): boolean {
        // Source: http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    /**
     * Checks if the domain is a common domain
     */
    public isCommonDomain(domain: string): boolean {
        return this.commonDomains.includes(domain);
    }

    /**
     * Returns true if the browser is Internet Explorer, false otherwise.
     */
    public isIE(): boolean {
        // MSIE used to detect old browsers and Trident used to newer ones.
        return navigator.userAgent.includes('MSIE ') || navigator.userAgent.includes('Trident/');
    }

    /**
     * Gets a regex that matches all words from the query string.
     * @param query
     */
    public getMultiWordRegex(query: string): RegExp {
        const words = query.split(' ');
        const nonCommonQuery = words
            .filter((w) => {
                return !this.commonWords.includes(w.toLocaleLowerCase());
            })
            .join(' ');

        // Regex: capture the whole query string and replace it with the string that will be used to match
        // the results, for example if the capture is "a" the result will be \a
        return new RegExp(nonCommonQuery.replaceAll(/([.?*+^$[\]\\(){}|-])/g, '\\$1').replaceAll(' ', '|'), 'gi'); // This addition puts an "OR" rule between all words.
    }

    /**
     * Aggregate similar activities in the first activity of that type in an array called aggregatedItems
     * @param activities the array of activities
     * @return the agragated list.
     */
    public aggregateActivityItems<T extends { type: string }>(activities: T[]): T[] {
        // States which activity type should be filtered by what field
        const aggregateBy = {
            INSIGHT_PLUS_ONE: 'reference1',
        };
        // Helper map
        const aggragationData = {};

        const aggregated = activities.filter(function (item) {
            const fieldName = aggregateBy[item.type];
            if (fieldName) {
                const typeAggragation = (aggragationData[item.type] = aggragationData[item.type] || {});
                const id = typeof item[fieldName] === 'string' ? item[fieldName] : item[fieldName].id;
                const firstSeenItem = (typeAggragation[id] = typeAggragation[id] || item);
                if (firstSeenItem !== item) {
                    const aggregatedItems = (firstSeenItem.aggregatedItems = firstSeenItem.aggregatedItems || []);
                    aggregatedItems.push(item);
                    return false;
                }
            }
            return true;
        });
        return aggregated;
    }

    /**
     * Gets the first item that answers the predicate
     * @param array - input array
     * @param obj - the object to compare with.€
     * @param property - the property to do comparison on.
     * @return The first item or undefined
     */
    public findFirstCompareProperties<T>(array: T[], obj: T, property: keyof T): T | undefined {
        for (let i = 0, l = array.length; i < l; i++) {
            if (isEqual(array[i]?.[property], obj[property])) {
                return array[i];
            }
        }
    }

    /**
     * Gets the index of the item that answers the predicate
     * @param array - input array
     * @param obj - the object to compare with.
     * @param property - the property to do comparison on.
     * @return {number} The index of the item or undefined
     */
    public findIndexCompareProperties<T extends Record<any, any>>(
        array: any[],
        obj: T,
        property: keyof T,
    ): number | undefined {
        for (let i = 0, l = array.length; i < l; i++) {
            if (isEqual(array[i][property], obj[property])) {
                return i;
            }
        }
    }

    /**
     * @deprecated use array.find
     *
     * Gets the first item that answers the predicate
     * @param array Input array
     * @param predicate A predicate function
     * @return The first item or undefined
     */
    public findFirst<T>(array: T[], predicate: (item: T) => boolean): T | null {
        if (!array || !predicate) {
            return null;
        }

        for (let i = 0, l = array.length; i < l; i++) {
            if (predicate(array[i]!)) {
                return array[i]!;
            }
        }

        return null;
    }

    /**
     * Gets the first item with a given Id
     * @param array Input array
     * @param id The id to look for
     * @return The first item or undefined
     */
    public findFirstById<T extends { id: any }>(array: T[], id: T['id']): T | null {
        return this.findFirst(array, function (item) {
            return item.id === id;
        });
    }

    /**
     * Returns true of false if the predicate is answered.
     * @param array - Input array.
     * @param predicate - A predicate function to match on.
     * @return true if found and false otherwise.
     */
    public existsInArray<T>(array: T[], predicate: (item: T) => boolean): boolean {
        for (let i = 0, l = array.length; i < l; i++) {
            if (predicate(array[i]!)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Resets an array to have the same items as the given original items array, without changing the array's reference.
     * This function changes the original array and returns it as well.
     * @param array - the array to reset (without changing its reference).
     * @param originalItemsArray - an array with the original items to reset to.
     */
    public resetArrayValues<T>(array: T[], originalItemsArray: T[]): void {
        // We clear the array and re-fill it.
        array.splice(0, array.length); // clear
        for (const element of originalItemsArray) {
            // re-fill
            array.push(element); // push is faster than unshift.
        }
    }

    /**
     * Returns true if given param is an object. False otherwise.
     */
    public isObject(param: any): param is Record<string, any> {
        return param instanceof Object;
    }

    /**
     * Gets the index of the item that answers the predicate
     * @param array Input array
     * @param predicate A predicate function
     * @return {number} The index or -1
     */
    public indexOf<T>(array: T[], predicate: (item: T) => boolean): number {
        return array.findIndex(predicate);
    }

    /**
     * Removes the first item in the array the matches the given predicate.
     * @param array - the array to search and remove the item from.
     * @param predicate - the predicate for matching the wanted element in the array.
     */
    public removeFirst<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];

                if (predicate(item!)) {
                    const removedItems = array.splice(i, 1);
                    return removedItems[0];
                }
            }
        }
    }

    /**
     * Replaces an item in an array.
     * @param array The array
     * @param item The item to be added
     * @param predicate A predicate function that identifies the item to be replaced.
     * @return {Number} The index of item in the array.
     */
    public replaceInArray<T>(array: T[], item: T, predicate: (item: T) => boolean): number {
        if (Array.isArray(array) && item && typeof predicate === 'function') {
            for (let i = 0; i < array.length; i++) {
                if (predicate(array[i]!)) {
                    array[i] = item;
                    return i;
                }
            }
        }

        return -1;
    }

    /**
     * Replaces an item in an array by Id
     * @param array The array
     * @param item The item to be added
     * @return {Number} The index of item in the array.
     */
    public replaceInArrayById<T extends { id: any }>(array: T[], item: T): number | undefined {
        if (Array.isArray(array) && item) {
            return this.replaceInArray(array, item, function (i) {
                return i.id === item.id;
            });
        }
    }

    /**
     * Replaces an item in an array by comparing the given field name.
     * @param array - The array to do the replacement on.
     * @param item - The item to be added.
     * @param fieldName - the field name to use for comparison.
     * @return {Number} The index of item in the array, -1 if no found.
     */
    public replaceInArrayCompareFields<T extends {}>(array: T[], item: T, fieldName: keyof T): number | undefined {
        if (Array.isArray(array) && item && fieldName) {
            return this.replaceInArray(array, item, function (i) {
                return i[fieldName] === item[fieldName];
            });
        }
    }

    /**
     * Join list of names..
     * If names = Moshe, Haim, Amit, Topaz, Vered, and limit name is 3, we will return: "Moshe, Haim, Amit and 2 more..."
     * If limit is 6 we will return "Moshe, Haim, Amit, Topaz and Vered"
     * @param array {string[]} The array of names\strings
     * @param limit {number=} Optional, if present will limit the output to this number of names
     * @returns {string}
     */
    public joinNames(array: string[], limit?: number): string | null {
        if (!array || array.length === 0) {
            return null;
        }
        if (!limit) {
            limit = array.length;
        }

        const min = Math.max(1, Math.min(array.length - 1, limit));
        const names = array.slice(0, min);
        let namesString = names.join(', ');
        if (array.length > 1) {
            if (array.length <= limit) {
                namesString = `${namesString} and ${array[array.length - 1]}`;
            } else {
                namesString = `${namesString} and ${array.length - limit} more...`;
            }
        }

        return namesString;
    }

    /**
     * Adds or replaces an item in an array.
     * @param array The array
     * @param item The item to be added
     * @param predicate A predicate function that identifies the item to be replaced.
     * @return {Number} The index of item in the array.
     */
    public addOrReplace<T>(array: T[], item: T, predicate: (item: T) => boolean): number | undefined {
        if (this.replaceInArray(array, item, predicate) === -1) {
            return array.push(item) - 1;
        }
    }

    /**
     * Adds or replaces an item in an array by Id
     * @param array The array
     * @param item The item to be added
     * @return {Number} The index of item in the array.
     */
    public addOrReplaceById<T extends { id: any }>(array: T[], item: T): number | undefined {
        return this.addOrReplace(array, item, function (i) {
            return i.id === item.id;
        });
    }

    /**
     * toArray returns a key,value obj as an array of {key: key, value: value}
     */
    public objToArray<T extends Record<any, any>>(obj: T): { key: keyof T; value: T[keyof T] }[] {
        if (!(obj instanceof Object)) {
            return obj;
        }

        return Object.entries(obj).map(([key, value]: [keyof T, T[keyof T]]) => ({ key, value }));
    }

    /**
     * Returns a distinct array by given property
     * @param array
     */
    public distinctArrayByProperty<T>(array: T[], property: string = 'id'): T[] {
        const tempMap: Record<any, any> = {};
        const distinctArray: T[] = [];
        for (const element of array) {
            if (!tempMap[element[property]]) {
                tempMap[element[property]] = 1;
                distinctArray.push(element);
            }
        }

        return distinctArray;
    }

    /**
     * Returns a distinct array by the items ids
     * @param array
     */
    public distinctArrayById<T = unknown>(array: T[]): T[] {
        return this.distinctArrayByProperty(array, 'id');
    }

    /**
     * Takes an array of objects and turns it into an object (which is a dictionary).
     *
     * @param array {T[]} - the array of objects to turn into an object (a dict).
     * @param propertyName {$Keys<T>} - the property name in each object in the array, to extract
     * @param newValue {any=} - optional. A value to set to each new property (key) in the new obj (dict).
     * @param valueProperty {$Keys<T>=} - optional. If given, will set the value to be the value of valueProperty in the dictionary. Otherwise, will set the object as the value.
     */
    public createMapFromArray<T extends Record<any, any>, Z extends keyof T>(
        array: T[],
        propertyName: Z,
    ): Record<T[Z], T>;
    public createMapFromArray<T extends Record<any, any>, Z extends string | number | symbol>(
        array: T[],
        predicate: (item: T) => Z,
    ): Record<Z, T>;
    public createMapFromArray<T extends Record<any, any>, Z extends keyof T, X>(
        array: T[],
        propertyName: Z,
        newValue: X,
    ): Record<T[Z], X>;
    public createMapFromArray<T extends Record<any, any>, Z extends string | number | symbol, X>(
        array: T[],
        predicate: (item: T) => Z,
        newValue: X,
    ): Record<Z, X>;
    public createMapFromArray<T extends Record<any, any>, Z extends keyof T, X extends keyof T>(
        array: T[],
        propertyName: Z,
        newValue: undefined | null,
        valueProperty: X,
    ): Record<T[Z], T[X]>;
    public createMapFromArray<T extends Record<any, any>, Z extends string | number | symbol, X extends keyof T>(
        array: T[],
        predicate: (item: T) => Z,
        newValue: undefined | null,
        valueProperty: X,
    ): Record<Z, T[X]>;
    public createMapFromArray<
        T extends Record<any, any>,
        Z extends keyof T,
        A extends string | number | symbol,
        Y,
        X extends keyof T,
    >(array: T[], propertyNameOrFunc: Z | ((item: T) => A), newValue?: Y | undefined | null, valueProperty?: X) {
        const keyValuePairs = array
            ?.filter((obj) => !!obj)
            .map((obj) => {
                const key =
                    typeof propertyNameOrFunc === 'function' ? propertyNameOrFunc(obj) : obj[propertyNameOrFunc];
                if (key) {
                    if (newValue) {
                        return [key, newValue];
                    }
                    if (valueProperty) {
                        return [key, obj[valueProperty]];
                    }
                    return [key, obj];
                }
            })
            .filter(Boolean);

        return Object.fromEntries(keyValuePairs as any);
    }

    public replaceStringAt(text: string, replacement: string, startIndex: number, endIndex: number = startIndex) {
        return text.slice(0, Math.max(0, startIndex)) + replacement + text.slice(endIndex);
    }

    /**
     * @deprecated use some
     * Returns if any element in the given array matched the given predicate.
     */
    public anyMatch<T>(array: T[], predicate: (item: T) => boolean): boolean {
        if (!array || !predicate) {
            return false;
        }

        for (const element_ of array) {
            const element = element_;

            if (predicate(element)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns if all elements in the given array match the given predicate.
     */
    public allMatch<T>(array: T[], predicate: (item: T) => boolean): boolean {
        if (!array || !predicate) {
            return false;
        }

        for (const element_ of array) {
            const element = element_;

            if (!predicate(element)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Takes a string and return a new string representing the @mention tag.
     * For example: tamir -> @tamir, avi yossi -> @avi-yossi
     * @param name
     * @returns {string}
     */
    public createAtMentionString(name: string): string {
        return `@${name.replaceAll(new RegExp(' ', 'g'), '-').toLowerCase()}`;
    }

    /**
     * Removes all @mentions from the given text.
     * Also uses trim after the replace.
     * @param text {String}
     * @returns {String}
     */
    public removeAtMentionsFromText(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(this.AT_MENTION_REGEX, '').trim();
    }

    /**
     * Gets the unique values in given array.
     */
    public getUniqueValues<T extends string>(array: T[]): T[] {
        if (!array?.length) {
            return array;
        }

        return this.objKeys(this.arrayToSet(array));
    }

    /**
     * Takes an array of strings and turns it into an object (which is a dictionary).
     * @param array - the array of strings to turn into an object (a dict).
     * @param lowerCaseKeys - Optional. If supplied all keys are lower cased.
     */
    public arrayToSet<T extends string>(array?: T[], lowerCaseKeys?: false | undefined): Record<T, true>;
    public arrayToSet<T extends string>(array: T[], lowerCaseKeys: true): Record<string, true>;
    public arrayToSet<T extends string>(array?: T[], lowerCaseKeys?: boolean): Record<string, true> {
        const newObj = {} as Record<string, true>;

        if (array?.length) {
            for (const element of array) {
                const key = element;
                const formattedKey = lowerCaseKeys && key.toLowerCase ? key.toLowerCase() : key;
                newObj[formattedKey] = true;
            }
        }

        return newObj;
    }

    /**
     * Returns the intersection between two sets.
     * Will loop through aSet items, so you should have the first argument, aSet, be the smaller set between the two.
     * @param aSet Set.
     * @param bSet Set.
     */
    public intersectSets<T extends {}>(aSet: T, bSet: T): (keyof T)[] {
        if (!aSet || !bSet) {
            return [];
        }

        // This array will hold the intersection results.
        const intersection: (keyof T)[] = [];

        for (const item in aSet) {
            if (aSet.hasOwnProperty(item) && bSet[item]) {
                intersection.push(item);
            }
        }

        return intersection;
    }

    /**
     * Splits the given array into chunks of given chunkSize.
     * Last chunk will be smaller or equal to the chunkSize.
     * @param array - The array we want to split into chunks.
     * @param chunkSize - The size of each chunk.
     */
    public splitArrayToChunks<T>(array: T[], chunkSize: number): T[][] {
        if (!array?.length || !chunkSize) {
            return [];
        }

        const chunksArray: T[][] = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            chunksArray.push(array.slice(i, i + chunkSize));
        }

        return chunksArray;
    }

    public size(obj: Record<any, any> | any[] | string): number {
        if (!(obj instanceof Object)) {
            return obj.length;
        }
        let count = 0;
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                count += 1;
            }
        }
        return count;
    }

    /**
     * do same as array.join for an inner property of each item
     */
    public joinObjArray<T extends Record<any, any>>(array: T[], prop: keyof T, delimiter: string): string | null {
        if (!Array.isArray(array)) {
            return null;
        }
        let str = '';
        for (let i = 0; i < array.length; i++) {
            if (array[i]![prop]) {
                str = str + array[i]![prop];
                if (i < array.length - 1) {
                    str = str + delimiter;
                }
            }
        }
        return str;
    }

    /**
     * Copies fields (a.k.a properties) from the "from" object to the "to" object.
     * @param from - the object to copy fields FROM.
     * @param to - the object to copy fields TO.
     * @param excludedPropertiesSet - a set or field names. A field with the given name will be excluded from the copy flow.
     * @param evenIfEmpty - when true, empty fields will be copied as well.
     * @param deleteMissingFields - when true, fields that don't exist in the "from" object will be deleted from the "to" object.
     */
    public copyEntityFields<T extends Record<any, any>>(
        from: T,
        to: Record<any, any>,
        excludedPropertiesSet?: Record<keyof T, boolean>,
        evenIfEmpty?: boolean,
        deleteMissingFields?: boolean,
    ): void {
        for (const property in from) {
            if (from.hasOwnProperty(property) && (!excludedPropertiesSet || !excludedPropertiesSet[property])) {
                const field = from[property];

                if (evenIfEmpty || this.isFullObj(field)) {
                    to[property] = from[property];
                }
            }
        }

        if (deleteMissingFields) {
            // Go over the properties of "to" and check which ones don't exist in the "from" object.
            for (const property in to) {
                if (
                    to.hasOwnProperty(property) &&
                    !from.hasOwnProperty(property) &&
                    !this.isAngularProperty(property)
                ) {
                    // This property exists only in the "to" object and not in "from", so it should be deleted.
                    delete to[property];
                }
            }
        }
    }

    /**
     * Checks if the given property name is an angular property, by checking if it starts with a '$' character.
     * @returns {boolean}
     */
    public isAngularProperty(propertyName: string): boolean {
        return propertyName ? propertyName.indexOf('$') === 0 : true;
    }

    public isFullObj(obj: any[] | Record<any, any>): boolean {
        if (Array.isArray(obj)) {
            // if array, only update if inner are full
            // assumption: if the first one is full, rest of them will be full
            return !obj.length || this.objHasFields(obj[0]);
        }

        if (obj instanceof Object) {
            // if doesn't have id, then it's not our object
            if (!obj['id']) {
                return true;
            }

            return this.objHasFields(obj);
        }

        return true;
    }

    public objHasFields(obj: Record<any, any>): boolean {
        if (!(obj instanceof Object)) {
            return true;
        }

        // only relevant to our objects
        if (obj['id']) {
            let fieldsLength = 0;
            // count to see if there is more than 1 fields
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    fieldsLength += 1;
                }
                if (fieldsLength > 2) {
                    break;
                }
            }

            return fieldsLength > 1;
        }
        return true;
    }

    /**
     * Finds if there is a property in the object that its value answers the predicate
     * @param obj the object to search
     * @param predicate the predicate to run on each property's value
     * @returns true if a value matched the predicate
     */
    public existsInObj<T extends Record<any, any>>(obj: T, predicate: (item: T[keyof T]) => boolean): boolean {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop) && predicate(obj[prop])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Finds if there is a key in the object that its value answers the predicate.
     * @param obj - the object to run the search on.
     * @param predicate - the predicate to run on each key's value.
     * @returns the matching key-value pair or null if there's no match.
     */
    public findInObj<T extends Record<any, any>>(
        obj: T,
        predicate: (item: T[keyof T]) => boolean,
    ): { key: keyof T; value: T[keyof T] } | null {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && predicate(obj[key])) {
                return { key, value: obj[key] };
            }
        }

        return null;
    }

    /**
     * Finds all keys in the object that their values answer the predicate, and returns them all.
     * @param obj - the object to run the search on.
     * @param predicate - the predicate to run on each key's value.
     * @returns array containing the matching objects.
     */
    public findAllInObj<T extends Record<any, any>>(obj: T, predicate: (item: T[keyof T]) => boolean): T[keyof T][] {
        const foundObjects: T[keyof T][] = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key) && predicate(obj[key])) {
                foundObjects.push(obj[key]);
            }
        }

        return foundObjects;
    }

    /**
     * Reverses given map. Keys become values and values turn into keys.
     * Please note that if your values are not unique, they will be overridden, and not aggregated.
     * @param map Map to reverse.
     */
    public reverseMap<T extends Record<any, any>>(map: T): Record<T[keyof T], keyof T> {
        if (!map) {
            return map;
        }

        const reversedMap = {} as Record<T[keyof T], keyof T>;

        for (const key in map) {
            if (map.hasOwnProperty(key)) {
                const value = map[key];

                reversedMap[value] = key;
            }
        }

        return reversedMap;
    }

    /**
     * Creates an array of strings from the given object's keys.
     * @deprecated
     */
    public objKeys<T extends Record<any, any>>(obj: T): (keyof T)[] {
        if (!(obj instanceof Object)) {
            return [];
        }

        return Object.keys(obj);
    }

    /**
     * Creates an array of strings from the given object's values.
     * @deprecated
     */
    public objValues<T extends Record<any, any>>(obj: T): T[keyof T][] {
        if (!(obj instanceof Object)) {
            return [];
        }

        return Object.values(obj);
    }

    /**
     * Goes over the given object's values and updates the given property in each value to the new value supplied.
     * @param dict - the dictionary to update.
     * @param propertiesNames - an array of the names of the properties that should be updated with the new value.
     * @param newValue - the new value to set to the requested property.
     */
    public updateDictionaryValuesProperty<T extends Record<any, any>>(
        dict: T,
        propertiesNames: string[],
        newValue: any,
    ): void {
        for (const property in dict) {
            if (dict.hasOwnProperty(property)) {
                for (const propertiesName of propertiesNames) {
                    const propertyName = propertiesName;
                    dict[property][propertyName] = newValue;
                }
            }
        }
    }

    /**
     * Merges two objects into one by copying the seconds objects' properties to the first one.
     * If the first object already holds that key, the key is skipped and not copied.
     * This function is useful to when you want to 'concatenate' two dictionaries.
     * The function returns a new merged object.
     *
     * NOTE: this function uses cloneDeep which is not very fast. So use it with caution!
     *       Consider using the clone function used in httpInterceptor for better performance (if suitable).
     *
     */
    public mergeObjects<T extends Record<any, any>, Z extends Record<any, any>>(objA: T, objB: Z): T & Z {
        const merged = {} as T & Z;

        // First, copy all of objA's keys and values (we use cloneDeep so we don't copy the reference itself).
        for (const propA in objA) {
            if (objA.hasOwnProperty(propA)) {
                merged[propA] = cloneDeep(objA[propA]);
            }
        }

        // Now go over objB's keys, and copy new ones (that don't exist in merged).
        for (const propB in objB) {
            if (objB.hasOwnProperty(propB) && !merged.hasOwnProperty(propB)) {
                merged[propB] = cloneDeep(objB[propB]);
            }
        }

        return merged;
    }

    public getInitials(name: string): string {
        const parts = name.toUpperCase().split(' ');
        if (parts.length > 1) {
            return parts[0].charAt(0) + parts[1]!.charAt(0);
        }
        return parts[0].charAt(0) + parts[0].charAt(1).toLowerCase();
    }

    public generateTempID(): string {
        return `TEMP${`0000${Math.trunc(Math.random() * 36 ** 4).toString(36)}`.slice(-4)}`;
    }

    /**
     * @deprecated
     * Should not be used, it returns an odd array. Should do `[...new Array(length)]`, but not changing for backwards compatibility.
     */
    public generateArray(length: number): any[] {
        return new Array(length);
    }

    public getUsecasesTitles<T extends Record<any, string[]>>(usecasesTitles: T, usecases: (keyof T)[]): string {
        // Default is taking the first option from every usecase title.
        const usecasesTitlesKeys: (keyof T)[] = Object.keys(usecasesTitles);
        let firstPart = `${usecasesTitles[usecasesTitlesKeys[0]][1]}, ${usecasesTitles[usecasesTitlesKeys[1]][1]}`;
        let lastPart = usecasesTitles[usecasesTitlesKeys[2]][1];

        if (usecases && usecases.length > 0) {
            firstPart = usecasesTitles[usecases[0]][1];

            switch (usecases.length) {
                case 1: {
                    // First item of the titles array for the usecase is always the full sentence.
                    return usecasesTitles[usecases[0]][0];
                }
                case 2: {
                    lastPart = usecasesTitles[usecases[1]][1];

                    break;
                }
                case 3: {
                    firstPart += `, ${usecasesTitles[usecases[1]][1]}`;
                    lastPart = usecasesTitles[usecases[2]][1];

                    break;
                }
                default: {
                    break;
                }
            }
        }

        return `${firstPart} or ${lastPart}`;
    }

    public getElementStyle(el: HTMLElement, styleProp: string): string | undefined {
        let value;
        const defaultView = (el.ownerDocument || document).defaultView;
        // W3C standard way:
        if (defaultView && defaultView.getComputedStyle) {
            // sanitize property name to css notation
            // (hypen separated words eg. font-Size)
            styleProp = styleProp.replaceAll(/([A-Z])/g, '-$1').toLowerCase();
            return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
        } else if (el['currentStyle']) {
            // IE
            // sanitize property name to camelCase
            styleProp = styleProp.replaceAll(/-(\w)/g, function (_str, letter) {
                // eslint-disable-line no-useless-escape
                return letter.toUpperCase();
            });
            value = el['currentStyle'][styleProp];
            // convert other units to pixels on IE
            if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
                return (function (value) {
                    const oldLeft = el.style.left;
                    const oldRsLeft = el['runtimeStyle'].left;
                    el['runtimeStyle'].left = el['currentStyle'].left;
                    el.style.left = value || 0;
                    value = `${el.style['pixelLeft']}px`;
                    el.style.left = oldLeft;
                    el['runtimeStyle'].left = oldRsLeft;
                    return value;
                })(value);
            }
            return value;
        }
    }

    public shadeHexColor(color: string, percent: number): string {
        const f = Number.parseInt(color.slice(1), 16);
        const t = percent < 0 ? 0 : 255;
        const p = percent < 0 ? percent * -1 : percent;
        const R = f >> 16;
        const G = (f >> 8) & 0x00_ff;
        const B = f & 0x00_00_ff;
        return `#${(
            0x1_00_00_00 +
            (Math.round((t - R) * p) + R) * 0x1_00_00 +
            (Math.round((t - G) * p) + G) * 0x1_00 +
            (Math.round((t - B) * p) + B)
        )
            .toString(16)
            .slice(1)}`;
    }

    /**
     * Transforms hex color to rgb color.
     */
    public hexToRgb(hex: string): [number, number, number] | undefined {
        let c;
        if (/^#([A-Fa-f\d]{3}){1,2}$/.test(hex)) {
            c = hex.slice(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
        }
    }

    /**
     * Translates the given rgba color to hex.
     * @param r - red. A number between 0 and 255.
     * @param g - green. A number between 0 and 255.
     * @param b - blue. A number between 0 and 255.
     * @param a - alpha. A number between 0 and 255.
     * @returns {string} - the new hex color.
     */
    public rgbaToHex(r: number, g: number, b: number, a: number): string {
        // https://stackoverflow.com/questions/9765618/javascript-shifting-issue-rgb-and-rgba-to-hex
        // Invalid color.
        if (r > 255 || g > 255 || b > 255 || a > 255) {
            return '#FFFFFF';
        }
        return `#${(256 + r).toString(16).slice(1)}${(((1 << 24) + (g << 16)) | (b << 8) | a).toString(16).slice(1)}`;
    }

    /**
     * Sets the given opacity to the given hex color, by translating it to rgb and adding the given opacity.
     * @param hexColor - the 6 digit hex color to apply opacity to.
     * @param opacity - the desired opacity. A float between 0 and 1.
     * @returns {*} - a string of the form 'rgba(r,g,b,a)';
     */
    public hexToRgbaWithOpacity(hexColor: string, opacity: number): string {
        let newColor = hexColor;
        const rgbColor = this.hexToRgb(hexColor);

        // Make sure the given opacity is within bounds.
        if (opacity > 1) {
            opacity = 1;
        } else if (opacity < 0) {
            opacity = 0;
        }

        if (rgbColor) {
            newColor = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity})`;
        }

        return newColor;
    }

    /**
     * Sorts the ui-select groups in ascending order, and making sure undefined names are at the top.
     */
    public sortSelectGroups<T extends { name: string }>(groups: T[]): T[] {
        const results = groups.sort(function (groupA, groupB) {
            if (!groupA.name) {
                return 1;
            }

            if (groupA.name && !groupB.name) {
                return -1;
            }

            return groupA.name.toLowerCase() > groupB.name.toLowerCase() ? -1 : 1;
        });

        return results;
    }

    /**
     * Tries to copy the text in the given INPUT element to the clipboard.
     * @param inputElement - the input element to copy the text from.
     * @deprecated use `useCopyToClipboard`
     */
    public copyToClipboardFromInput(inputElement: HTMLInputElement | HTMLTextAreaElement): boolean {
        // Fail safe.
        if (inputElement) {
            // Copy to clipboard.
            try {
                inputElement.select();
                inputElement.focus();
                return document.execCommand('copy');
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return false;
    }

    /**
     * Tries to copy the given text by creating new DOM element and copy its value to the clipboard.
     * @param stringText - the text to copy from.
     * @deprecated use `useCopyToClipboardFromInput`
     */
    public copyToClipboardFromText(stringText: string): boolean {
        const el = document.createElement('textarea');
        el.value = stringText;
        document.body.append(el);
        const isCopied = this.copyToClipboardFromInput(el);
        el.remove();
        return isCopied;
    }

    public moveCaretToEnd(e: Event) {
        const target = e.target as HTMLInputElement;

        const tempValue = target.value;
        target.value = '';
        target.value = tempValue;
    }

    public eventStopAllPropagations(event: Event): false {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        return false;
    }

    public eventStopPropagation(event: Event): false {
        event.stopPropagation();
        return false;
    }

    /**
     * Focuses the element with the given element id.
     */
    public focus(elementId: string): void {
        if (elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                element.focus();
            }
        }
    }

    /**
     * Returns the current time in epoch milliseconds.
     */
    public now(): number {
        return Date.now();
    }

    public getTimeDiffFromNow(daysFromNow: number, hour: number, minutes?: number): number {
        const now = new Date();
        now.setDate(now.getDate() + daysFromNow);
        if (hour > 0) {
            now.setHours(hour);
            if (minutes) {
                now.setMinutes(minutes);
            } else {
                now.setMinutes(0);
            }
        }

        const time = now.getTime();
        return time;
    }

    public formatRelativeTimeFromNow(date: number): string {
        return dayjs(date).fromNow();
    }

    /**
     * Wrapps shallowEqual and instructs it to not check if the reference of both objects is equal.
     * This is handy when comparing arrays (as an example).
     */
    public shallowEqualIgnoreRef<T extends Record<any, any>>(a: T, b: T): boolean {
        return this.shallowEqual(a, b, true);
    }

    /**
     * Performs a shallow equal (1 properties level deep) on a and b.
     * Notice: objects who hold the same reference are of course considered as equal.
     * Returns true if a and be a shallowly equal, false otherwise.
     * Follows the JS engine sameValue algorithm (implemented in this.isEqual).
     */
    public shallowEqual<T extends Record<any, any>>(a: T, b: T, ignoreRef: boolean): boolean {
        // If we find they are equal (by reference or by value) return true.
        if (!ignoreRef && this.areEqual(a, b)) {
            return true;
        }

        // They are not initially equal, we should check their properties if non are null and both are objects.
        if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
            // Check if the number of keys (properties) is the same.
            const aKeys = Object.keys(a);
            if (aKeys.length !== Object.keys(b).length) {
                return false;
            }

            // The number of properties is the same, let's check them for equality.
            for (const aKey of aKeys) {
                const key = aKey;
                // If b doesn't have the key or if they're values are not the same, the object are not equal.
                if (!b.hasOwnProperty(key) || !this.areEqual(a[key], b[key])) {
                    return false;
                }
            }

            // Both are object which are not null with the same first level properties.
            return true;
        }

        // One of is either null or not an object (and prior equality check has failed).
        return false;
    }

    /**
     * Checks if two object are equal (by reference or by value). Return true if they are and false otherwise.
     * Follows the JS engine sameValue algorithm (ECMA): https://www.ecma-international.org/ecma-262/5.1/#sec-9.12
     * 1. If Type(x) is different from Type(y), return false.
     * 2. If Type(x) is Undefined, return true.
     * 3. If Type(x) is Null, return true.
     * 4. If Type(x) is Number, then.
     *      a. If x is NaN and y is NaN, return true.
     *      b. If x is +0 and y is -0, return false.
     *      c. If x is -0 and y is +0, return false.
     *      d. If x is the same Number value as y, return true.
     *      e. Return false.
     * 5. If Type(x) is String, then return true if x and y are exactly the same sequence of characters (same length and same characters in corresponding positions); otherwise, return false.
     * 6. If Type(x) is Boolean, return true if x and y are both true or both false; otherwise, return false.
     * 7. Return true if x and y refer to the same object. Otherwise, return false.
     */
    public areEqual(a: any, b: any): boolean {
        // Checks off 1,2,3,4.d, 5,6,7.
        if (a === b) {
            // Deal with 4.b and 4.c.
            if ((Object.is(a, +0) && Object.is(b, -0)) || (Object.is(a, -0) && Object.is(b, +0))) {
                // eslint-disable-line no-compare-neg-zero
                return false;
            }

            return true;
        } else if (typeof a === 'number' && typeof b === 'number' && Number.isNaN(a) && Number.isNaN(b)) {
            // We get here if either a and b are different or they are both numbers and both NaN (4.a).
            // If they are both NaN return true. Otherwise (they are different) and we will pass.
            return true;
        }

        return false;
    }

    /**
     * Copies all properties from given 'from' parameter to the given 'to' parameter.
     * No extra logic is taken into consideration, other than copying the keys from 'from' to 'to'.
     */
    public flatCopyObjectProperties(from: Record<any, any>, to: Record<any, any>): void {
        for (const key in from) {
            if (from.hasOwnProperty(key)) {
                to[key] = from[key];
            }
        }
    }

    /**
     * Returns a dictionary of group to elements of the group.
     *
     * @param array - Array of elements to group by.
     * @param groupByPredicate - Predicate that gets an array element and returns the value to group
     * the element by or a string as the key to group by.
     * @param mapValues - Modify items inside the array
     */
    public groupBy<T extends Record<any, any>, Z extends keyof T, Y = T>(
        array: T[],
        groupByPredicate: Z,
        mapValues?: (item: T) => Y,
    ): { [key in T[Z] extends any[] ? T[Z][number] : T[Z]]?: Y[] };
    public groupBy<T extends Record<any, any>, Z extends string | number | symbol, Y = T>(
        array: T[],
        groupByPredicate: (item: T) => Z[] | Z,
        mapValues?: (item: T) => Y,
    ): { [key in Z]?: Y[] };
    public groupBy<T extends Record<any, any>, Y = T>(
        array: T[],
        groupByPredicate: string | ((item: T) => string),
        mapValues: (item: T) => Y = (item) => item,
    ): Record<any, Y[]> {
        if (!array || !groupByPredicate) {
            return {};
        }

        return array.reduce((groupedMap, item) => {
            const groupBy = typeof groupByPredicate === 'string' ? item[groupByPredicate] : groupByPredicate(item);
            const groupByArray = toArray(groupBy);

            groupByArray.forEach((singleGroupBy) => {
                if (!groupedMap[singleGroupBy]) {
                    groupedMap[singleGroupBy] = [];
                }
                groupedMap[singleGroupBy].push(mapValues(item));
            });

            return groupedMap;
        }, {});
    }

    /**
     * Copies properties FROM one object TO another while preserving references for arrays and objects.
     * Note that for arrays, it only preserves the first level of references and all array elements will be replaced.
     * @param from - the item to copy the new properties FROM.
     * @param to - the original item to copy properties TO.
     */
    public copyObjectProperties(from: Record<any, any>, to: Record<any, any>) {
        for (const property in from) {
            if (from.hasOwnProperty(property)) {
                const value = from[property];

                if (!to[property]) {
                    // If the original object doesn't have this property, just add it.
                    to[property] = value;
                } else {
                    // The original object already has this property and it needs to get updated without changing the reference.
                    if (Array.isArray(value)) {
                        // Empty the array, so its reference can be re-filled.
                        to[property].splice(0, value.length);
                        // Now go over the new items and push them into this empty array.
                        // Yes, this is only one level down copy for arrays.
                        for (const element of value) {
                            to[property].push(element);
                        }
                    } else if (value instanceof Object) {
                        // This is a full-scaled object. We should copy it as well recursively.
                        this.copyObjectProperties(value, to[property]);
                    } else {
                        // The object is a primitive type. We can just replace it. There's no reference to keep.
                        to[property] = value;
                    }
                }
            }
        }
    }

    /**
     * Get the Date of the most recent weekday relative to a start date
     * @param start Optional, defaults to today. A date to start from
     * @param dayIndex Optional, defaults to 1 (monday). 0 - 6, sunday to saturday just like Date.getDay().
     * @returns {Date}
     */
    public getLatestWeekDay(start?: Date, dayIndex?: number): Date {
        dayIndex = dayIndex === undefined || dayIndex === null ? 1 : dayIndex; // if doesn't exist assign monday as default
        let d = start ? new Date(start) : new Date();
        d = new Date(d.setDate(d.getDate()));
        const day = d.getDay();
        const diff = d.getDate() - day + (dayIndex > day ? dayIndex - 7 : dayIndex); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    public getToday(): Date {
        return new Date();
    }

    /**
     * Returns true if the keyboard event is on the Esc key
     * @param $event
     * @returns {boolean}
     */
    public isEscapeKey($event: KeyboardEvent): boolean {
        return $event.code === 'Escape' || $event.keyCode === 27;
    }

    /**
     * Capitalizes the first character in the given string.
     * @returns {String}
     */
    public capitalize(string: string | undefined): string {
        if (string && string.length > 0) {
            if (string.length === 1) {
                return string.charAt(0).toUpperCase();
            }
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        // We've got an empty string.
        return string || '';
    }

    /**
     * Returns all the indexes where the a string is inside a string.
     */
    public getIndices(stringToSearchOn: string, whatToSearchFor: string): number[] {
        const returns: number[] = [];
        let position = 0;

        while (stringToSearchOn.includes(whatToSearchFor, position)) {
            const index = stringToSearchOn.indexOf(whatToSearchFor, position);
            returns.push(index);
            position = index + whatToSearchFor.length;
        }

        return returns;
    }

    public flexIncludes(text: string, searchQuery: string) {
        const trimText = (textToTrim: string) => textToTrim.toLowerCase().replaceAll(' ', '');

        return trimText(text).includes(trimText(searchQuery));
    }

    /**
     * Copied from lodash https://github.com/lodash/lodash/blob/master/unzip.js
     * Creates an array of grouped elements, the first of which contains the first elements of the given arrays,
     * the second of which contains the second elements of the given arrays, and so on.
     * @param arrays - The arrays to zip
     * @returns {*}
     */
    public zipArrays(...arrays: any[]): any[] {
        if (!arrays?.length) {
            return [];
        }
        let length = 0;
        arrays = arrays.filter((group) => {
            // @ts-ignore
            if (typeof group === 'object' && group !== null && typeof value !== 'function' && group.length >= 0) {
                length = Math.max(group.length, length);
                return true;
            }
        });
        let index = -1;
        const result = new Array(length);
        while (++index < length) {
            result[index] = arrays.map((singleArray) => singleArray[index] || undefined);
        }
        return result;
    }

    /**
     * Convert a number to a notation which indicates the zeros number 10K, 5M etc.
     * @param num - The number to format
     * @returns A string representing the number with the correct notation
     */
    public nFormatter(num: number): string {
        if (this.isNullOrEmpty(num)) {
            return '';
        }
        if (num >= 1_000_000_000) {
            return `${(num / 1_000_000_000).toFixed(1).replace(/\.0$/, '')}G`;
        }
        if (num >= 1_000_000) {
            return `${(num / 1_000_000).toFixed(1).replace(/\.0$/, '')}M`;
        }
        if (num >= 1000) {
            return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
        }
        return num.toString();
    }

    /**
     * Formats a date.
     *
     * @param date - the date to format.
     * @param showTime - should we show the time? If not, it will only show the date.
     * @param todayPrefix - should we show "Today" instead of the date? If string provided, it will be used as the
     * prefix. If true, "Today" will be the prefix.
     * @param yesterdayPrefix - Should we show "Yesterday" instead of the date? If string provided, it will be used as
     * the prefix. If true, "Yesterday" will be the prefix.
     * @param hideDateToday - should we hide the date if it's today and only show the time? If show time is false,
     * this is ignored. If todayPrefix or yesterdayPrefix is true and match the date, they will be shown instead of
     * the date.
     * @param timeFormat - the time format. If show time if false, it's ignored.
     * @param dateFormat - the time format.
     * @returns the formatted date string.
     */
    public formatDate(
        date: number | string | Date,
        showTime: boolean,
        todayPrefix: string | boolean = false,
        yesterdayPrefix: string | boolean = false,
        hideDateToday: boolean = false,
        timeFormat: string = 'hh:mm A',
        dateFormat: string = 'MM.DD.YY',
    ): string {
        const dateInstance = dayjs(date);
        const time = showTime ? dateInstance.format(timeFormat) : undefined;

        let prefix: string | undefined;
        if (dateInstance.isToday() && (todayPrefix || hideDateToday)) {
            if (todayPrefix) {
                prefix = todayPrefix === true ? 'Today' : todayPrefix;
            }
        } else if (dateInstance.isYesterday() && yesterdayPrefix) {
            prefix = yesterdayPrefix === true ? 'Yesterday' : yesterdayPrefix;
        } else {
            prefix = dateInstance.format(dateFormat);
        }

        return [prefix, time].filter(Boolean).join(', ');
    }

    public numberWithCommas(number: number, decimalCount: number = 0) {
        return number.toLocaleString('en-US', { maximumFractionDigits: decimalCount });
    }

    /**
     * Function which sorts the objects by its keys, recursively.
     * This is helpful when we want to compare two objects which have the same keys in different order.
     * @param object - The object to sort
     */
    public sortObject(object) {
        if (!object) {
            return;
        }

        const isArray = Array.isArray(object);
        let sortedObj = {};
        if (isArray) {
            sortedObj = object.map((item) => this.sortObject(item));
        } else {
            const keys = Object.keys(object);
            keys.sort(function (key1, key2) {
                const canonizedKey1 = key1.toLowerCase();
                const canonizedKey2 = key2.toLowerCase();
                if (canonizedKey1 < canonizedKey2) return -1;
                if (canonizedKey1 > canonizedKey2) return 1;
                return 0;
            });

            keys.forEach((_key) => {
                const key = _key || '';
                if (typeof object[key] == 'object') {
                    sortedObj[key] = this.sortObject(object[key]);
                } else {
                    sortedObj[key] = object[key];
                }
            });
        }

        return sortedObj;
    }

    /**
     * Takes our rich text editor elements and serializes them to plain text
     * @param richText - The given rich text object from the editor
     */
    public richTextToPlainText(richText: TElement[]): string {
        return richText.map((n) => Node.string(n)).join('\n');
    }

    public plainTextToRichText(plainText: string): TElement[] {
        return [
            {
                type: 'p',
                children: [
                    {
                        text: plainText,
                    },
                ],
            },
        ] as TElement[];
    }

    /**
     * Checks whether the given string is a valid date
     * @param dateString - The given string to check
     */
    public isDateStringValid(dateString: string): boolean {
        const date = new Date(dateString);
        return date && date.toString() !== 'Invalid Date';
    }

    public toBase64 = (text: string): string => {
        return this.bytesToBase64(new TextEncoder().encode(text));
    };

    public fromBase64 = (text: string): string => {
        return new TextDecoder().decode(this.base64ToBytes(text));
    };

    private base64ToBytes = (base64: string) => {
        const binString = atob(base64);
        return Uint8Array.from(binString, (m) => m.codePointAt(0) as number);
    };

    private bytesToBase64 = (bytes: Uint8Array) => {
        const binString = Array.from(bytes, (byte: number) => String.fromCodePoint(byte)).join('');
        return btoa(binString);
    };
}

const utils = new UtilsClass();
export default utils;
