import React from 'react';
import styled from 'styled-components';

import DragAndDropImagePreview from './DragAndDropImagePreview';
import { ReactComponent as GreyError } from '../../../../images/grey-error.svg';
import FileIcon from '../../icons/FileIcon';
import TextEllipsis from '../../TextEllipsis';
import Separator from '../Separator';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ImageContainer = styled.div`
    height: 70px;
    width: 70px;
    margin-left: 20px;
    margin-top: 20px;
`;

const FilesContainer = styled.div``;

const FileContainer = styled.div`
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
`;

const FileName = styled.div`
    margin: 0 10px;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_700};
    flex-grow: 1;
    flex-shrink: 1;
`;

interface Props {
    isInPreview: boolean;
    isImageUpload: boolean;
    existingFiles: TonkeanUploadedFile[];
    onDelete(file: TonkeanUploadedFile): void;
}

const DragAndDropFilesPreview: React.FC<Props> = ({ isImageUpload, existingFiles, onDelete, isInPreview }) => {
    return (
        <>
            {isImageUpload ? (
                <ImagesContainer>
                    {existingFiles.map((dragDropFile) => (
                        <ImageContainer key={dragDropFile.id}>
                            <DragAndDropImagePreview
                                file={dragDropFile}
                                isInPreview={isInPreview}
                                onDelete={() => onDelete(dragDropFile)}
                            />
                        </ImageContainer>
                    ))}
                </ImagesContainer>
            ) : (
                <FilesContainer>
                    {existingFiles.map((dragDropFile, index) => (
                        <React.Fragment key={dragDropFile.id}>
                            {index !== 0 && <Separator />}

                            <FileContainer>
                                <FileIcon name={dragDropFile.name} size={40} />
                                <FileName>
                                    <TextEllipsis>{dragDropFile.name}</TextEllipsis>
                                </FileName>

                                {!isInPreview && (
                                    <Clickable onClick={() => onDelete(dragDropFile)}>
                                        <GreyError />
                                    </Clickable>
                                )}
                            </FileContainer>
                        </React.Fragment>
                    ))}
                </FilesContainer>
            )}
        </>
    );
};

export default DragAndDropFilesPreview;
