enum RecurrencePeriodType {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Hourly = 'Hourly',
    Every3Hours = 'Every3Hours',
    EveryXMinutes = 'EveryXMinutes',
    EveryXHours = 'EveryXHours',
    Quarterly = 'Quarterly',
}

export default RecurrencePeriodType;
