import React from 'react';
import styled from 'styled-components';

import { Filter2Icon } from '@tonkean/svg';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const FiltersCounter = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    letter-spacing: -0.5px;
    color: ${Theme.colors.invertedText};
    border-radius: 50%;
    background: ${Theme.colors.primaryActive};
    text-align: center;
`;

const FilterIcon = styled(Filter2Icon)<{ disabled?: boolean }>`
    opacity: ${(props) => (props.disabled ? '0.6' : 1)};
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    activeFiltersCount?: number;
    filtersOpen?: boolean;
}

const FiltersTrigger: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    { activeFiltersCount, filtersOpen = false, disabled = false, ...props },
    ref,
) => {
    return (
        <IconButton {...props} disabled={disabled} pressed={filtersOpen} ref={ref}>
            {!!activeFiltersCount && <FiltersCounter>{activeFiltersCount}</FiltersCounter>}

            <FilterIcon disabled={disabled} />
        </IconButton>
    );
};

export default React.forwardRef(FiltersTrigger);
