import React from 'react';
import styled, { css } from 'styled-components';

import { useToggle } from '../../hooks';
import { Input } from '../TUI';

import type { EnterpriseComponentVariable } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const VariableLabel = styled.label`
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin: 0;
`;

const StyledInput = styled(Input)<{ takeTwoColumns: boolean }>`
    ${({ takeTwoColumns }) =>
        takeTwoColumns &&
        css`
            grid-column: span 2;
        `}
`;

interface Props {
    enterpriseComponentVariable: EnterpriseComponentVariable;
    value: string;
    isSafeEdit: boolean;

    onUpdate(newValue?: string): void;
}

const EnterpriseComponentVariableRow: React.FC<Props> = ({
    enterpriseComponentVariable,
    value,
    onUpdate,
    isSafeEdit,
}) => {
    const [isEditable, toggleIsEditable] = useToggle(!isSafeEdit);

    const onClickEdit = () => {
        // if the old status is editable and now its not editable reset the value.
        if (isEditable) {
            onUpdate(undefined);
        } else {
            // if it wasn't editable and now its editable set empty string as value to remove the censored data
            onUpdate('');
        }

        toggleIsEditable();
    };

    return (
        <>
            <VariableLabel htmlFor={enterpriseComponentVariable.id}>
                {enterpriseComponentVariable.displayName} {isSafeEdit}
            </VariableLabel>

            <StyledInput
                takeTwoColumns={!isSafeEdit}
                id={enterpriseComponentVariable.id}
                value={value}
                type={isEditable && enterpriseComponentVariable.isEncrypted ? 'password' : 'text'}
                disabled={!isEditable}
                onChange={({ target: { value: inputValue } }) => {
                    onUpdate(inputValue);
                }}
                placeholder="Insert Value"
            />

            {isSafeEdit && (
                <Button size={ButtonSize.SMALL} onClick={onClickEdit}>
                    {isEditable ? <>Undo</> : <>Edit</>}
                </Button>
            )}
        </>
    );
};

export default EnterpriseComponentVariableRow;
