import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import DoNotUseNumberInputArrowButton from './DoNotUseNumberInputArrowButton';
import useBreakpoint, { Breakpoint } from '../../../hooks/useBreakpoint';
import { useInnerField } from '../Field';
import Input, { getBorderColor } from '../Input/Input';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { Theme } from '@tonkean/tui-theme';
import { InputSize, type InputComponentSizes } from '@tonkean/tui-theme/sizes';

const InputContainer = styled.div<{
    $isError: boolean;
    $isHighlighted: boolean;
    $disabled: boolean;
}>`
    display: ${({ $disabled }) => ($disabled ? 'block' : 'flex')};
    border-radius: 4px;
    border: 1px solid ${({ $isError, $isHighlighted }) => getBorderColor($isError, $isHighlighted, false)};

    &::placeholder {
        color: ${Theme.colors.gray_500};
    }
    &:focus-within {
        border: 1px solid ${({ $isError, $isHighlighted }) => getBorderColor($isError, $isHighlighted, true)};
    }
`;

const StyledInput = styled(Input)`
    border-radius: 4px;
    border: 0px;

    &:focus {
        border: 0px;
        outline: none;
    }
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button,
    -webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const ButtonsContainer = styled.div`
    margin: 3px 3px 3px 0px;
`;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
    isError?: boolean;
    size?: InputComponentSizes;
    isHighlighted?: boolean;
    onChange?(value: string | undefined, event: React.SyntheticEvent<any> | undefined): void;
}

const DoNotUseNumberInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        size = InputSize.MEDIUM as InputComponentSizes,
        isError: hasErrorProp,
        isHighlighted = false,
        disabled = false,
        onChange: onChangeProp,
        ...props
    },
    ref,
) => {
    const [{ value, ...fieldProps }, hasError, fieldHelper] = useInnerField({
        name: props.name,
        type: 'number',
        multiple: false,
        value: props.value,
        onBlur: props.onBlur,
        id: props.id,
    });

    const newRef = useRef<HTMLInputElement>();
    const multipleRef = useMultipleRefCallback(ref, newRef);

    const onChange = useCallback(
        (value: string | undefined, event: React.SyntheticEvent<any> | undefined) => {
            onChangeProp?.(value, event);
            fieldHelper?.setValue(value);
        },
        [fieldHelper, onChangeProp],
    );

    const [changeValueNumber, setChangeValueNumber] = useState<number>(0);
    const effectiveBreakpoint = useBreakpoint();
    const step: number = props.step ? Number(props.step) : 1;

    // if we are in a small screen mode, we modify the size of the input
    const smallScreen = useMemo<boolean>(() => {
        return Number(effectiveBreakpoint) === Breakpoint.MINIATURE_0;
    }, [effectiveBreakpoint]);

    const customSize = useMemo<InputComponentSizes>(() => {
        return smallScreen ? InputSize.XLARGE : size;
    }, [size, smallScreen]);

    const onArrowButtonClickEvent = useCallback((number: number) => {
        setChangeValueNumber(number);
    }, []);

    useEffect(() => {
        if (newRef && changeValueNumber !== 0) {
            const interval = setInterval(() => {
                if (changeValueNumber > 0) {
                    newRef.current?.stepUp();
                } else {
                    newRef.current?.stepDown();
                }
                onChange(newRef.current?.value, undefined);
            }, 100);
            return () => clearInterval(interval);
        }
    });

    return (
        <>
            <InputContainer
                $isError={hasError || hasErrorProp || false}
                $isHighlighted={isHighlighted}
                $disabled={disabled}
            >
                <StyledInput
                    type="number"
                    disabled={disabled}
                    size={customSize}
                    {...props}
                    {...fieldProps}
                    ref={multipleRef}
                    onChange={(e) => {
                        onChange(e.target.value, e);
                    }}
                />
                {!disabled && !smallScreen && (
                    <ButtonsContainer>
                        <DoNotUseNumberInputArrowButton
                            size={customSize}
                            number={step}
                            onArrowButtonClickEvent={onArrowButtonClickEvent}
                        />
                        <DoNotUseNumberInputArrowButton
                            size={customSize}
                            number={-step}
                            transform="rotate(270deg)"
                            onArrowButtonClickEvent={onArrowButtonClickEvent}
                        />
                    </ButtonsContainer>
                )}
            </InputContainer>
        </>
    );
};
