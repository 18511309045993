import { getMonth, getYear } from 'date-fns';
import range from 'lodash.range';
import React from 'react';
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import styled from 'styled-components';

import { DateMonths } from './DateEnums';

const LOWEST_YEAR = 1900;
const years: number[] = range(LOWEST_YEAR, getYear(new Date()) + 50, 1);
const months: string[] = Object.values(DateMonths);

const DatePickerHeader = styled.div`
    margin: 5px;
    display: flex;
    justify-content: center;
`;

const DatePickerCustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <DatePickerHeader>
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} data-automation="prev-month-btn">
            {'<'}
        </button>
        <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(Number(value))}
            data-automation="select-year"
        >
            {years.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
        <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            data-automation="select-month"
        >
            {months.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} data-automation="next-month-btn">
            {'>'}
        </button>
    </DatePickerHeader>
);

export default DatePickerCustomHeader;
