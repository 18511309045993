import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { NoEntry } from '@tonkean/svg';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme, FontSize } from '@tonkean/tui-theme';

interface Props {}

const OopsTitle = styled.div`
    font-size: ${FontSize.XXXXLARGE_24};
    color: ${Theme.colors.gray_500};
    font-weight: 700;
    margin-bottom: 17px;
`;

const NoAccessWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NoAccessZIndex = styled.div`
    z-index: 15;
`;

const NavigationButtonsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
`;

const NoAccessContent = styled.div`
    margin-top: 34px;
`;

const StyledContent = styled.div`
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 18px;
    color: ${Theme.colors.gray_700};
`;

const UserNoAccess: React.FC<Props> = ({}) => {
    const as = useAngularService('authenticationService');
    const projectId = as.getCurrentUser()?.projectContext?.projectId;

    return (
        <div>
            <NavigationButtonsContainer>
                <div>
                    <ClickableLink href="boards">Change board</ClickableLink>
                    <span className="margin-normal-h">|</span>
                    <ClickableLink onClick={() => as.logout()}>Logout</ClickableLink>
                </div>
            </NavigationButtonsContainer>

            <NoAccessWrapper>
                <NoAccessZIndex>
                    <NoEntry />
                    <NoAccessContent>
                        <OopsTitle data-automation="user-no-access">
                            Oops, you don't have permissions to see this
                        </OopsTitle>
                        <StyledContent>
                            Please visit
                            <a href={`home/${projectId || ''}`}> home page </a>
                            or contact your Tonkean administrator
                        </StyledContent>
                    </NoAccessContent>
                </NoAccessZIndex>
            </NoAccessWrapper>
        </div>
    );
};

export default UserNoAccess;
