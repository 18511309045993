import React from 'react';
import type { InputProps } from 'react-select';
import { components } from 'react-select';

const CustomInput: React.FC<InputProps> = (props) => {
    const { children, innerRef, ...rest } = props;

    // Using innerRef to attach the data-automation attribute
    const modifiedRef = (instance: HTMLInputElement | null) => {
        if (innerRef) {
            // Attach the data-automation attribute to the input element
            if (instance) {
                instance.dataset.automation = 'tnk-select-search-input';
            }
            // Call the original innerRef function if provided
            innerRef(instance);
        }
    };

    return (
        <components.Input {...props} innerRef={modifiedRef}>
            {children}
        </components.Input>
    );
};

export default CustomInput;
