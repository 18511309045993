import * as React from 'react';

import { SimpleSelect } from './TUI';

import type { TonkeanId } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import type { SolutionMapper } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    value?: TonkeanId<TonkeanType.SOLUTION_MAPPER>;
    onChange: (value: TonkeanId<TonkeanType.SOLUTION_MAPPER>) => void;
    solutionMappers: SolutionMapper[];
    loading?: boolean;
}

const SolutionMapperSelector: React.FC<Props> = ({ value, onChange, solutionMappers, loading = false, className }) => {
    return (
        <SimpleSelect
            placeholder="Select process mapper..."
            value={value}
            options={solutionMappers.map((entity) => {
                return {
                    value: entity.id,
                    label: entity.displayName,
                };
            })}
            isLoading={loading}
            onChange={onChange}
            className={className}
            dataAutomation="solution-mapper-selector"
            thin
        />
    );
};

export default SolutionMapperSelector;
