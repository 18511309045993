export enum BoardSettingsTab {
    // General
    PROFILE = 'profile',
    PERMISSIONS = 'permissions',
    GENERAL = 'preferences',
    THEME = 'theme',

    // Enterprise Components
    CONNECTIONS_PERMISSIONS = 'connections-permissions',
    USAGE = 'usage',

    // Subscription
    LICENSE_OVERVIEW = 'overview',
    MANAGE_SEATS = 'members',
    INVOICES = 'invoices',
    UPGRADE = 'upgrade',

    // Edit
    EDIT = 'edit',

    // Users and Groups
    IDENTITY_PROVIDER = 'identity-provider',
    PROCESS_CONTRIBUTORS = 'process-contributors',
    GUEST = 'guest',
    SYSTEM_USERS = 'system-users',

    // Billing
    LICENSE_PLAN = 'license-plan',
}

interface BoardSettingsTabData {
    value: string;
    title: string;
    displayName: string;
}

const BoardSettingsTabs: Record<BoardSettingsTab, BoardSettingsTabData> = {
    [BoardSettingsTab.PROFILE]: {
        value: BoardSettingsTab.PROFILE,
        title: 'Profile & Notifications',
        displayName: 'Profile',
    },
    [BoardSettingsTab.PERMISSIONS]: {
        value: BoardSettingsTab.PERMISSIONS,
        title: 'People Settings',
        displayName: 'Permissions',
    },
    [BoardSettingsTab.THEME]: {
        value: BoardSettingsTab.THEME,
        title: 'Theme',
        displayName: 'Theme',
    },
    [BoardSettingsTab.CONNECTIONS_PERMISSIONS]: {
        value: BoardSettingsTab.CONNECTIONS_PERMISSIONS,
        title: 'Connection Permissions',
        displayName: 'Connection Permissions',
    },
    [BoardSettingsTab.USAGE]: {
        value: BoardSettingsTab.USAGE,
        title: 'Usage',
        displayName: 'Usage',
    },
    [BoardSettingsTab.GENERAL]: {
        value: BoardSettingsTab.GENERAL,
        title: 'Preferences',
        displayName: 'General',
    },
    [BoardSettingsTab.EDIT]: {
        value: BoardSettingsTab.EDIT,
        title: 'Edit Project',
        displayName: '',
    },
    [BoardSettingsTab.LICENSE_OVERVIEW]: {
        value: BoardSettingsTab.LICENSE_OVERVIEW,
        title: 'Overview',
        displayName: 'Overview',
    },
    [BoardSettingsTab.MANAGE_SEATS]: {
        value: BoardSettingsTab.MANAGE_SEATS,
        title: 'Subscription Members',
        displayName: 'Manage Seats',
    },
    [BoardSettingsTab.INVOICES]: {
        value: BoardSettingsTab.INVOICES,
        title: 'Subscription Invoices',
        displayName: 'Invoices',
    },
    [BoardSettingsTab.UPGRADE]: {
        value: BoardSettingsTab.UPGRADE,
        title: 'Upgrade Subscription',
        displayName: 'Upgrade',
    },
    [BoardSettingsTab.IDENTITY_PROVIDER]: {
        value: BoardSettingsTab.IDENTITY_PROVIDER,
        title: 'Identity Provider',
        displayName: 'Identity Provider',
    },
    [BoardSettingsTab.PROCESS_CONTRIBUTORS]: {
        value: BoardSettingsTab.PROCESS_CONTRIBUTORS,
        title: 'Process Contributors',
        displayName: 'Process Contributors',
    },
    [BoardSettingsTab.GUEST]: {
        value: BoardSettingsTab.GUEST,
        title: 'Guest Users',
        displayName: 'Guest Users',
    },
    [BoardSettingsTab.SYSTEM_USERS]: {
        value: BoardSettingsTab.SYSTEM_USERS,
        title: 'System Users',
        displayName: 'System Users',
    },
    [BoardSettingsTab.LICENSE_PLAN]: {
        value: BoardSettingsTab.LICENSE_PLAN,
        title: 'License Plan',
        displayName: 'License Plan',
    },
};

export default BoardSettingsTabs;
