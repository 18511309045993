enum ButtonStyle {
    FILLED,
    OUTLINED,
    CANCEL,
    PUBLISH_RELATED,
    WARNING,
    DELETE,
    HIGHLIGHTED,
    HIGHLIGHTED_OUTLINED,
    WARNING_OUTLINED,
    PROMOTION,
    PROMOTION_OUTLINED,
}

export default ButtonStyle;
