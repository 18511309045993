export enum FieldType {
    String = 'String',
    Number = 'Number',
    Date = 'Date',
    List = 'List',
    Boolean = 'Boolean',
    LongString = 'LongString',
    URL = 'URL',
}

export const fieldTypeToFieldDisplayName: Record<FieldType, string> = {
    [FieldType.String]: 'Text',
    [FieldType.Date]: 'Date',
    [FieldType.Number]: 'Number',
    [FieldType.Boolean]: 'Boolean',
    [FieldType.LongString]: 'Long string',
    [FieldType.List]: 'List',
    [FieldType.URL]: 'URL',
};
