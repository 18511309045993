enum NavigationCategory {
    CURRENT_MODULE_BUILD_ITEMS = 'CURRENT_MODULE_BUILD_ITEMS',
    CURRENT_MODULE_PRODUCTION_ITEMS = 'CURRENT_MODULE_PRODUCTION_ITEMS',
    CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS = 'CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS',
    CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS = 'CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS',
    CURRENT_MODULE_USED_DATA_SOURCES = 'CURRENT_MODULE_USED_DATA_SOURCES',
    CURRENT_MODULE_SOLUTION_DATA_SOURCES = 'CURRENT_MODULE_SOLUTION_DATA_SOURCES',
    ALL_MODULES_IN_PROJECT = 'ALL_MODULES_IN_PROJECT',
    ALL_SOLUTIONS_IN_PROJECT = 'ALL_SOLUTIONS_IN_PROJECT',
    ALL_DATA_SOURCES_IN_PROJECT = 'ALL_DATA_SOURCES_IN_PROJECT',
    ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT = 'ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT',
    CURRENT_DATA_SOURCE_ACCESSIBLE_SOLUTIONS = 'CURRENT_DATA_SOURCE_ACCESSIBLE_SOLUTIONS',
    CURRENT_DATA_SOURCE_ACCESSIBLE_MODULES = 'CURRENT_DATA_SOURCE_ACCESSIBLE_MODULES',
    ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT = 'ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT',
    ALL_ITEM_INTERFACES_IN_PROJECT = 'ALL_ITEM_INTERFACES_IN_PROJECT',
    ALL_CREATE_FORMS_IN_PROJECT = 'ALL_CREATE_FORMS_IN_PROJECT',
    ALL_SOLUTION_SITE_PAGES_IN_PROJECT = 'ALL_SOLUTION_SITE_PAGES_IN_PROJECT',
    ALL_HOMEPAGE_INTAKES_IN_PROJECT = 'ALL_HOMEPAGE_INTAKES_IN_PROJECT',
}

export default NavigationCategory;
