import type EncryptedField from './EncryptedField';
import type EntityBase from '../EntityBase';
import type { ProjectIntegrationEntityResponseHandlingDefinition } from '../ProjectIntegrationAction';
import type { BasicQueryDefinition } from '../QueryDefinition';
import type TonkeanId from '../TonkeanId';
import type TonkeanType from '../TonkeanType';

interface ProjectIntegrationEntity extends ProjectIntegrationEntitySummary, EntityBase {
    description: string;
    entityType: string;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    collectDefinition: ProjectIntegrationEntityCollectDefinition;
    entityFetcherDefinition: ProjectIntegrationEntityFetcherDefinition;
    entityWebhookDefinition: ProjectIntegrationEntityWebhookDefinition;
    metadataApiDefinition: ProjectIntegrationEntityMetadataApiDefinition;
    encryptedFields: EncryptedField[];
}

export interface ProjectIntegrationEntityWebhookDefinition {
    isEnabled: boolean;
    shouldAcceptAll: boolean;
    fetchingMethod: WebhookPayloadHandlingType;
    queryDefinition: BasicQueryDefinition;
    externalActivityResponseHandlingDefinition: ProjectIntegrationEntityResponseHandlingDefinition;
}

export enum WebhookPayloadHandlingType {
    ID = 'ID',
    ENTITY = 'ENTITY',
}

interface ProjectIntegrationEntityCollectDefinition {
    collectActionId: string;
    isEnabled: boolean;
    isAddRecordsEnabled: boolean;
    turnOffSingleCollect: boolean;
}

interface ProjectIntegrationEntityMetadataApiDefinition {
    metadataApiActionId: string;
}

interface ProjectIntegrationEntityFetcherDefinition {
    fetcherActionId: string;
}

export interface ProjectIntegrationEntitySummary {
    displayName: string;
    id: string;
    description: string;
    isCollectEnabled: boolean;
    isAddRecordsEnabled: boolean;
    turnOffSingleCollect: boolean;
}

export interface ProjectIntegrationEntitySummaryWithIsImported extends ProjectIntegrationEntitySummary {
    isImported: boolean;
}

export default ProjectIntegrationEntity;
