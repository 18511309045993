import { useAngularService } from 'angulareact';
import React, { useContext, useMemo, useRef } from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

import DatePickerCustomHeader from './DatePickerCustomHeader';
import { useFocusTrap } from '../../../hooks';
import useEscapeCallback from '../../../hooks/useEscapeCallback';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useInnerField } from '../Field';
import { MODAL_Z_INDEX } from '../Modal/ModalWrapper';
import { ROOT_PORTAL_CONTAINER_ID } from '../portalConsts';
import TooltipElement from '../Tooltip/TooltipElement';

import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { Theme } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import { ButtonStyle } from '@tonkean/tui-theme/styles';

const DatepickerRefContext = React.createContext<React.RefObject<ReactDatePicker | null>>({ current: null });

const tooltipTheme = Theme.current.palette.TUI.tooltip[TooltipColor.WHITE];
const buttonTheme = Theme.current.palette.TUI.button;

const StyledTooltipElement = styled(TooltipElement)`
    display: inline-block;
    position: relative;
    padding: 0;
    width: max-content;
    overflow: hidden;

    .react-datepicker__header {
        border-bottom: ${tooltipTheme.borderWidth}px solid ${tooltipTheme.borderColor};
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 90px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 1px;
        padding: 3px;
        box-sizing: content-box;
        outline: none;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__time-list-item--selected {
        background: ${buttonTheme[ButtonStyle.FILLED].backgroundColor} !important;
        color: ${buttonTheme[ButtonStyle.FILLED].textColor} !important;

        &:hover {
            background: ${buttonTheme[ButtonStyle.FILLED].backgroundHoverColor} !important;
            color: ${buttonTheme[ButtonStyle.FILLED].textHoverColor} !important;
        }

        &:not(.react-datepicker__time-list-item--selected) {
            padding: 1px;
            border: 2px solid ${buttonTheme[ButtonStyle.FILLED].borderColor};

            &:hover {
                border: 2px solid ${buttonTheme[ButtonStyle.FILLED].borderHoverColor};
            }
        }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
        border: 2px solid ${buttonTheme[ButtonStyle.OUTLINED].borderColor};
        background: ${buttonTheme[ButtonStyle.OUTLINED].backgroundColor};
        color: ${buttonTheme[ButtonStyle.OUTLINED].textColor};
        padding: 1px;

        &:hover {
            border: 2px solid ${buttonTheme[ButtonStyle.OUTLINED].borderHoverColor};
            background: ${buttonTheme[ButtonStyle.OUTLINED].backgroundHoverColor};
            color: ${buttonTheme[ButtonStyle.OUTLINED].textHoverColor};
        }
    }

    .react-datepicker__day-names {
        margin-top: 3px;
    }
`;

const CalendarContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // Move the focus trap to the datepicker when its open.
    // Solves an issue in firefox, where the select elements are not working properly
    const focusTrap = useFocusTrap(
        true,
        undefined,
        // If we use default behavior to restore focus, we get stuck in a loop where when you click outside the datepicker
        // it focuses the input which opens the datepicker so we just disabled the restore focus feature
        false,
    );
    return (
        <StyledTooltipElement
            ref={focusTrap}
            color={TooltipColor.WHITE}
            size={TooltipSize.MEDIUM}
            showArrow={false}
            popover
        >
            {children}
        </StyledTooltipElement>
    );
};

const StyledPortalContainer = styled.div`
    z-index: ${MODAL_Z_INDEX};
    position: absolute;
`;

const PopperContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const datepickerRef = useContext(DatepickerRefContext);
    const calendarShown = !!children;

    const close = () => datepickerRef.current?.setOpen(false);
    const clickOutsideRef = useOnClickOutside(close, calendarShown);
    useEscapeCallback(close, calendarShown);

    return <StyledPortalContainer ref={clickOutsideRef}>{children}</StyledPortalContainer>;
};

export interface DatepickerProps<SelectRange extends boolean>
    extends Omit<ReactDatePickerProps, 'onChange' | 'selectsRange'> {
    selectsRange?: SelectRange;

    onChange?(
        date: (SelectRange extends true ? [Date, Date] : Date) | null,
        event: React.SyntheticEvent<any> | undefined,
    ): void;
    customButton?: React.ReactNode;
    usePortal?: boolean;
}

const Datepicker = <SelectRange extends boolean = false>(
    {
        className,
        showTimeSelect,
        dateFormat = `MM/dd/yyyy${showTimeSelect ? ' h:mm aa' : ''}`,
        onChange: onChangeProp,
        name,
        onBlur: onBlurProp,
        selected,
        showTimeSelectOnly,
        customInput,
        timeIntervals,
        usePortal = true,
        startOpen,
        ...props
    }: DatepickerProps<SelectRange>,
    ref: React.ForwardedRef<ReactDatePicker>,
) => {
    const utils = useAngularService('utils');

    const [{ value, ...fieldProps }, hasError, fieldHelper] = useInnerField({
        type: 'date',
        multiple: false,
        name,
        value: selected,
        onBlur: onBlurProp,
    });

    const onChange = (
        date: (SelectRange extends true ? [Date, Date] : Date) | null,
        event: React.SyntheticEvent<any> | undefined,
    ) => {
        onChangeProp?.(date, event);
        fieldHelper?.setValue(date);
    };

    const datepickerRef = useRef<ReactDatePicker>(null);
    const isInitializedRef = useRef<boolean>(false);
    const sharedRefCallback = useMultipleRefCallback(ref, datepickerRef, (innerRef) => {
        // Due to a bug in react-datepicker with portal and startOpen, which calculates the position incorrectly,
        // We override the startOpen prop with our own implementation for opening the datepicker on the second render
        if (startOpen && !isInitializedRef?.current) {
            setTimeout(() => {
                innerRef?.setOpen(true);
                isInitializedRef.current = true;
            });
        }
    });

    const selectedValue = useMemo(() => {
        return utils.isDateStringValid(value) ? new Date(value) : undefined;
    }, [utils, value]);

    return (
        <DatepickerRefContext.Provider value={datepickerRef}>
            <ReactDatePicker
                {...props}
                {...fieldProps}
                selected={selectedValue}
                onChange={onChange}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                dateFormat={dateFormat}
                calendarContainer={CalendarContainer}
                popperContainer={PopperContainer}
                wrapperClassName={className}
                popperModifiers={
                    [
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: true,
                                padding: 10,
                            },
                        },
                    ] as const
                }
                timeIntervals={timeIntervals || 15}
                portalId={usePortal ? ROOT_PORTAL_CONTAINER_ID : ''}
                ref={sharedRefCallback}
                customInput={customInput}
                renderCustomHeader={DatePickerCustomHeader}
            />
        </DatepickerRefContext.Provider>
    );
};

type ComponentType = <SelectRange extends boolean = false>(
    props: DatepickerProps<SelectRange> & React.RefAttributes<ReactDatePicker>,
) => React.ReactElement;

export default React.forwardRef(Datepicker) as ComponentType;
