import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const ZebraTableHeader = styled.thead<{ border?: boolean }>`
    margin: 0;
    padding-bottom: 12px;

    ${({ border = false }) =>
        border &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}
`;

export default ZebraTableHeader;
