import React, { useMemo } from 'react';

import IconRadioGroupContext from './IconRadioGroupContext';
import { RadioGroup } from '../RadioButton';
import type { RadioGroupProps } from '../RadioButton/RadioGroup';

interface Props extends RadioGroupProps {}

/**
 * A IconRadioGroup component.
 * Used to create a list of icons with labels RadioGroup.
 */
const IconRadioGroup: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({ ...props }, ref) => {
    const contextValue = useMemo(() => ({}), []);

    return (
        <IconRadioGroupContext.Provider value={contextValue}>
            <RadioGroup ref={ref} {...props} />
        </IconRadioGroupContext.Provider>
    );
};

export default React.forwardRef(IconRadioGroup);
