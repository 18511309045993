import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { TabsContext } from './Tabs';
import TabsBorderType from './TabsBorderType';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable, type ClickableProps } from '@tonkean/tui-buttons/Clickable';
import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';
import { colorSvg } from '@tonkean/utils';

const StyledTab = styled(Clickable)<{
    $width?: string;
    $isSelected: boolean;
    $showBackground: boolean;
    disabled: boolean;
    $small: boolean;
    $selectedColor: Color;
    $notSelectedColor: Color;
}>`
    position: relative;
    border: none;
    padding: 10px 20px;
    font-size: ${({ $small }) => ($small ? FontSize.SMALL_12 : FontSize.MEDIUM_14)};
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ $width = 'auto' }) => $width};
    transition: all 0.3s ease-in-out;

    background-color: ${({ $isSelected, $showBackground, disabled }) => {
        if (!$showBackground || disabled) {
            return 'unset';
        }
        return $isSelected ? Theme.current.palette.tabs.selectedBackground : Theme.colors.basicBackground;
    }};

    &,
    &:hover,
    &:focus {
        ${({ $isSelected, disabled, $selectedColor, $notSelectedColor }) => {
            let textColor;
            if (disabled) {
                textColor = Theme.colors.gray_400;
            } else if ($isSelected) {
                textColor = $selectedColor;
            } else {
                textColor = $notSelectedColor;
            }

            return css`
                color: ${textColor};
                ${colorSvg(textColor)};
            `;
        }};
    }

    ${({ disabled }) =>
        !disabled &&
        css`
            cursor: pointer;
            &:hover {
                background-color: ${Theme.colors.tabsHover};
            }
        `}

    ${styledFocus}
`;

const TabsIndicatorStyled = styled(motion.div)<{
    borderType: TabsBorderType;
    $indicatorHeight: number;
    $indicatorColor: Color;
}>`
    height: ${({ $indicatorHeight }) => $indicatorHeight}px;
    background-color: ${({ $indicatorColor }) => $indicatorColor};
    position: absolute;
    bottom: ${({ borderType, $indicatorHeight }) => (borderType === TabsBorderType.NONE ? 0 : -$indicatorHeight)}px;
    left: 0;
    right: 0;
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps, ClickableProps {
    /** The value to compare to the value prop in Tabs component to check if this component is the one selected */
    label: number | string;
    width?: string;
    dataAutomation?: string;
}

const Tab: React.ForwardRefRenderFunction<HTMLElement, React.PropsWithChildren<Props>> = (
    { label, children, onClick, width, className, dataAutomation, disabled = false, ...props },
    ref,
) => {
    const {
        value,
        setValue,
        indicatorClassName,
        showIndicator,
        showBackground,
        small,
        borderType,
        uuid,
        indicatorHeight,
        selectedColor,
        notSelectedColor,
        indicatorColor,
        disableIndicatorAnimation,
    } = useContext(TabsContext);

    const compositeOnClick = useCompositeEventCallback(onClick || undefined, () => setValue?.(label));

    const selected = value === label;

    return (
        <StyledTab
            data-automation={`${dataAutomation}-${label}`}
            className={className}
            onClick={compositeOnClick}
            disabled={disabled}
            $width={width}
            $isSelected={selected}
            $showBackground={showBackground}
            $small={small}
            $selectedColor={selectedColor}
            $notSelectedColor={notSelectedColor}
            ref={ref}
            {...props}
        >
            {children}
            {showIndicator && selected && (
                <TabsIndicatorStyled
                    borderType={borderType}
                    $indicatorHeight={indicatorHeight}
                    $indicatorColor={indicatorColor}
                    className={indicatorClassName}
                    layoutId={disableIndicatorAnimation ? undefined : `tabs-indicator-${uuid}`}
                />
            )}
        </StyledTab>
    );
};

export default React.memo(React.forwardRef(Tab));
