import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as CSVIcon } from '../../../images/CSV.svg';
import { ReactComponent as DOCIcon } from '../../../images/DOC.svg';
import { ReactComponent as GenericFileIcon } from '../../../images/file.svg';
import { ReactComponent as PDFIcon } from '../../../images/pdf.svg';
import { ReactComponent as TXTIcon } from '../../../images/text-file.svg';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Icon = styled.svg`
    flex-shrink: 0;
`;

interface Props extends StyledComponentsSupportProps {
    name?: string;
    size?: number;
}

const FileIcon: React.FC<Props> = ({ name, className, size = 20 }) => {
    const extension = useMemo(() => {
        const parts = name?.split('.');
        return parts?.[parts.length - 1]?.toUpperCase();
    }, [name]);

    const sharedProps = {
        style: { height: size, width: size },
        className,
    };

    switch (extension) {
        case 'CSV':
        case 'XLSX':
            return <Icon as={CSVIcon} {...sharedProps} />;
        case 'PDF':
            return <Icon as={PDFIcon} {...sharedProps} />;
        case 'DOC':
            return <Icon as={DOCIcon} {...sharedProps} />;
        case 'TXT':
            return <Icon as={TXTIcon} {...sharedProps} />;
        default:
            return <Icon as={GenericFileIcon} {...sharedProps} />;
    }
};

export default FileIcon;
