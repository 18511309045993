import TransitionSubState from '../TransitionSubState';

/**
 * Map with the keys as the sub state and the value as the class name postfix. If undefined, it means that the
 * sub state doesn't have a postfix. This mapping is done to match the `CSSTransition` api.
 */
const transitionSubStateToPostfix: Record<TransitionSubState, string | undefined> = {
    [TransitionSubState.START]: undefined,
    [TransitionSubState.ACTIVE]: 'active',
    [TransitionSubState.DONE]: 'done',
};

export default transitionSubStateToPostfix;
