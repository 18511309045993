export interface SingleBackgroundProcessStatusInfo {
    key: string;
    displayName: string;
    isError: boolean;
    showHistoryButton: boolean;
}

enum BackgroundProcessStatus {
    /**
     * @deprecated Use CREATED instead
     */
    QUEUED = 'QUEUED',

    /**
     * @deprecated Use INITIALIZING or EXECUTING instead
     */
    RUNNING = 'RUNNING',

    DONE = 'DONE',
    STOPPED = 'STOPPED',
    ERROR = 'ERROR',

    CREATED = 'CREATED',

    VALIDATED = 'VALIDATED',
    INVALID = 'INVALID',

    INITIALIZING = 'INITIALIZING',
    INITIALIZED = 'INITIALIZED',
    INITIALIZATION_FAILED = 'INITIALIZATION_FAILED',

    EXECUTING = 'EXECUTING',
    EXECUTION_FAILED = 'EXECUTION_FAILED',

    INITIALIZATION_MAX_RETRY_EXCEEDED = 'INITIALIZATION_MAX_RETRY_EXCEEDED',
}

export const BackgroundProcessStatusInfo: Record<BackgroundProcessStatus, SingleBackgroundProcessStatusInfo> = {
    /**
     * @deprecated Use CREATED instead
     */
    QUEUED: {
        key: 'QUEUED',
        displayName: 'Created',
        isError: false,
        showHistoryButton: false,
    },

    /**
     * @deprecated Use INITIALIZING or EXECUTING instead
     */
    RUNNING: { key: 'RUNNING', displayName: 'Running', isError: false, showHistoryButton: true },

    DONE: { key: 'DONE', displayName: 'Done', isError: false, showHistoryButton: true },
    STOPPED: { key: 'STOPPED', displayName: 'Stopped', isError: false, showHistoryButton: true },
    ERROR: { key: 'ERROR', displayName: 'Error', isError: true, showHistoryButton: true },

    CREATED: { key: 'CREATED', displayName: 'Created', isError: false, showHistoryButton: false },

    VALIDATED: { key: 'VALIDATED', displayName: 'Created', isError: false, showHistoryButton: false },
    INVALID: { key: 'INVALID', displayName: 'Validation failed', isError: true, showHistoryButton: false },

    INITIALIZING: { key: 'INITIALIZING', displayName: 'Initializing', isError: false, showHistoryButton: false },
    INITIALIZED: { key: 'INITIALIZED', displayName: 'Initializing', isError: false, showHistoryButton: false },
    INITIALIZATION_FAILED: {
        key: 'INITIALIZATION_FAILED',
        displayName: 'Initializing',
        isError: false,
        showHistoryButton: false,
    }, // Not an error because for the users it's shows as "initializing"

    EXECUTING: { key: 'EXECUTING', displayName: 'Running', isError: false, showHistoryButton: true },
    EXECUTION_FAILED: { key: 'EXECUTION_FAILED', displayName: 'Error', isError: true, showHistoryButton: true },

    INITIALIZATION_MAX_RETRY_EXCEEDED: {
        key: 'INITIALIZATION_MAX_RETRY_EXCEEDED',
        displayName: 'Initialization Failed',
        isError: true,
        showHistoryButton: false,
    },
} as const;

export default BackgroundProcessStatus;
