import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import styled from 'styled-components';

import TonkeanAvatar from '../../../TonkeanAvatar';
import type { SimpleSelectSingleOption } from '../SimpleSelectTypes';

import type { Person } from '@tonkean/tonkean-entities';

const PersonOptionWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const PersonOptionAvatar = styled.div`
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 10px;
`;

const GuestIndication = styled.span`
    color: #dc6803;
    font-style: italic;
    margin-right: 5px !important;
`;

type PersonOption = SimpleSelectSingleOption<string> & Person;

export const PersonOption: React.FC<{
    selectProps: OptionProps<PersonOption>;
    component: React.ComponentType<React.PropsWithChildren<{}>>;
}> = ({ selectProps, component: Component }) => {
    const person = selectProps.data;

    const isOption = Component === components.Option;
    // We show avatar only if it's option (inside the dropdown) or if it's not thin.
    const showAvatar = isOption || !selectProps.selectProps['thin'];

    return (
        <Component {...selectProps}>
            <PersonOptionWrapper>
                {showAvatar && (
                    <PersonOptionAvatar>
                        <TonkeanAvatar owner={person} />
                    </PersonOptionAvatar>
                )}
                {person.projectContext?.isExternal ? (
                    <div>
                        {person.label} <GuestIndication> (Guest) </GuestIndication>
                    </div>
                ) : (
                    <div>{person.label}</div>
                )}
            </PersonOptionWrapper>
        </Component>
    );
};

export const Option: React.FC<OptionProps<PersonOption>> = (props) => {
    return <PersonOption selectProps={props} component={components.Option} />;
};

export const SingleValue: React.FC<OptionProps<PersonOption>> = (props) => {
    return <PersonOption selectProps={props} component={components.SingleValue} />;
};

export const MultiValueLabel: React.FC<OptionProps<PersonOption>> = (props) => {
    return <PersonOption selectProps={props} component={components.MultiValueLabel} />;
};
