// @ts-nocheck
/*
 * this is a wrapper on usage of posthog to make sure it is fail-safe and that it only used after initialized.
 */
const posthogWrapper = {
    init: (id, authenticationService, projectManager, $rootScope, $log, $timeout) => {
        try {
            posthog.init(id, {
                api_host: 'https://app.posthog.com',
                disable_session_recording: false,
                autocapture: false,
                session_recording: {
                    maskAllInputs: true,
                    maskTextSelector:
                        '.fs-censored, .fs-censored-masked, .track-item-text, textarea, .initiative-title-col, .initiative-description-box, .activity-item-title-text, \
                        .activity-item-content, .activity-item-comments, .summary-card-item-simple, .summary-card-item-hoverable, .track-week-item, .view-initiative-summary, \
                        .initiative-updatetext-box, .track-title, .digest-calendar-item-text, .digest-calendar-item-invitees, .initiative-update-text-box, \
                        .tnk-tooltip-text',
                },
            });

            const userAgent = navigator.userAgent;
            posthog.register({ 'User Agent': userAgent });
            if (userAgent.endsWith('DatadogSynthetics')) {
                posthog.opt_out_capturing();
            }

            posthog.register({
                noBounce: false,
            });

            $rootScope.$on('newUserCreated', function (e, userId) {
                const user = authenticationService.currentUser;
                if (user?.id) {
                    posthog.alias(user.id, userId);
                }
            });

            const trackUser = function () {
                const user = authenticationService.currentUser;
                if (user?.id) {
                    posthog.identify(user.id, {
                        email: user.email,
                        name: user.name,
                        personId: user.id,
                        created: new Date(user.created),
                        last_login: new Date(),
                        first_name: user.firstName,
                        avatar: user.avatarUri,
                        phone: user.metadata?.phoneNumber ? user.metadata.phoneNumber : null,
                        title: user.title,
                        userCreated: new Date(user.created),
                    });

                    if ($rootScope.mobileApp) {
                        posthog.register({
                            mobileApp: true,
                        });
                    }
                }
            };
            trackUser();
            $rootScope.$on('currentUserChanged', trackUser);
            const trackProject = function () {
                const project = projectManager.project;
                if (project) {
                    const projectContext = authenticationService.currentUser.projectContexts[project.id];

                    // to all events
                    posthog.register({
                        projectId: project.id,
                        projectName: project.name,
                        isFullUser: !!projectContext?.isLicensed,
                    });
                    const user = authenticationService.currentUser;

                    if (user?.id) {
                        posthog.identify(user.id, {
                            email: user.email,
                            name: user.name,
                            personId: user.id,
                            created: new Date(user.created),
                            last_login: new Date(),
                            first_name: user.firstName,
                            avatar: user.avatarUri,
                            phone: user.metadata?.phoneNumber ? user.metadata.phoneNumber : null,
                            title: user.title,
                            userCreated: new Date(user.created),
                            projectId: project.id,
                            projectName: project.name,
                            isOwner: !!projectManager.isOwner,
                            isFullUser: !!projectContext?.isLicensed,
                            isFullUserPreview: !!projectManager.isFullUserPreview,
                            usecases: project.usecases?.length ? project.usecases.join(', ') : null,
                            trial_expiration: project.expirationDate ? new Date(project.expirationDate) : null,
                            is_trial_expired: !!project.isExpired,
                            licensed: !!project.license,
                            limited: !!project.isLimitedLicense,
                            seats: project.license ? project.license.seats : 1,
                            plan: project.license ? project.license.plan : project.expirationDate ? 'TRIAL' : 'FREE',
                            monthly_spend: project.license ? project.license.seats * 69.99 : 0,
                        });
                    }
                }
            };
            trackProject();
            $rootScope.$on('currentProjectChanged', trackProject);

            $rootScope.$on('emailLoginSent', function (e, email) {
                posthog.register({
                    email,
                });
            });

            const logPageViewed = function (stateName) {
                posthog.capture('Page Viewed', {
                    title: document.title,
                    label: stateName,
                    page: window.location.pathname,
                    url: window.location,
                });
            };

            // track state change as a "view" even
            $rootScope.$on('$stateChangeSuccess', function (e, toState) {
                logPageViewed(toState.name);
            });

            // if session is more than 5 seconds add "long session" to all future events
            $timeout(function () {
                // console.log('no bounce');
                posthog.register({
                    noBounce: true,
                });
                // log page view with the new flag just in case
                logPageViewed($rootScope.currentRouteStateName);
            }, 5000);
        } catch (error) {
            $log.error(error);
        }
    },

    track: (eventName: string, properties?: { [index: string]: any }) => {
        console.log('track', eventName, properties);
        try {
            if (!!posthog?.capture) {
                return posthog.capture(eventName, properties);
            }
        } catch (error: any) {
            console.error(`posthog.capture threw error: ${error}`);
        }
        return null;
    },
};

export default posthogWrapper;
