import React from 'react';
import styled from 'styled-components';

import { IconInputWithLabel } from '../IconInputWithLabel';
import useRadio from '../RadioButton/useRadio';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const IconContainer = styled.div<{ focused?: boolean }>`
    display: inline-flex;
    ${styledFocus}
`;

interface Props extends DataAutomationSupportProps, StyledComponentsSupportProps {
    disabled?: boolean;
    value: any;
    label: React.ReactNode;
    icon?: React.ReactComponentElement<any>;
    highlighted?: boolean;
}

const IconRadioGroupButton: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithRef<Props>> = (
    { className, dataAutomation, value, disabled = false, label, icon, highlighted = true },
    ref,
) => {
    const { props, onChange, radioGroupDisabled, fakeValue, size } = useRadio(value);

    return (
        <IconInputWithLabel
            {...props}
            type="radio"
            className={className}
            disabled={disabled || radioGroupDisabled}
            ref={ref}
            size={size}
            icon={<IconContainer>{icon || <></>}</IconContainer>}
            value={fakeValue}
            labelAfter={label}
            dataAutomation={dataAutomation}
            onChange={onChange}
            highlighted={highlighted}
            changeLabelColor
        />
    );
};

export default React.forwardRef(IconRadioGroupButton);
