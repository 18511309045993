export interface ValidationResult {
    validation: Validation;
    errorMessage?: string;
}

export type ValidationState = {
    trueValidation: Validation;
    visibleValidation: Validation;
    setForceShowValidation?: (value: boolean) => void;
};

export enum Validation {
    INVALID = 'INVALID',
    VALID = 'VALID',
    NOTHING_TO_VALIDATE = 'NOTHING_TO_VALIDATE',
}
