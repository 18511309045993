enum FormDefinitionType {
    MANUAL = 'MANUAL',
    SQL = 'SQL',
    EXTERNAL = 'EXTERNAL',
    GOOGLE = 'GOOGLE',
    JIRA_COUNT_FILTER = 'JIRA_COUNT_FILTER',
    AGGREGATE_QUERY = 'AGGREGATE_QUERY',
    SMARTSHEET = 'SMARTSHEET',
    TNK_COLUMN_AGGREGATE = 'TNK_COLUMN_AGGREGATE',
    TNK_COLUMN_FORMULA = 'TNK_COLUMN_FORMULA',
    ROOT = 'ROOT',
    MATCH_TONKEAN_ITEM = 'MATCH_TONKEAN_ITEM',
}

export default FormDefinitionType;
