type AccessControlDefinition =
    | EmailInboxAccessControlDefinition
    | WebhookAccessControlDefinition
    | NativeIntegrationAccessControlDefinition;

export enum AccessControlType {
    EMAIL_INBOX = 'EMAIL_INBOX',
    WEBHOOK = 'WEBHOOK',
    NATIVE_INTEGRATION = 'NATIVE_INTEGRATION',
}

interface BaseAccessControlDefinition<AccessControlType> {
    accessControlType: AccessControlType;
}

export interface EmailInboxAccessControlDefinition extends BaseAccessControlDefinition<AccessControlType.EMAIL_INBOX> {
    fromEmailAddresses: FromEmailAddresses;
    fromEmailDomains: FromEmailDomains;
}

export interface WebhookAccessControlDefinition extends BaseAccessControlDefinition<AccessControlType.WEBHOOK> {
    ipRestrictionGroups: IpRestrictionGroups;
    tokenRestriction: TokenRestriction;
}

export interface NativeIntegrationAccessControlDefinition
    extends BaseAccessControlDefinition<AccessControlType.NATIVE_INTEGRATION> {}

interface FromEmailAddresses {
    isEnabled: boolean;
    emailAddresses: AllowedMailOrDomain[];
}

interface FromEmailDomains {
    isEnabled: boolean;
    emailDomains: AllowedMailOrDomain[];
}

interface AllowedMailOrDomain {
    value: string;
}

interface IpRestrictionGroups {
    isEnabled: boolean;
    ipRestrictions: IpRestriction[];
}

interface IpRestriction {
    displayName: string;
    ipRangeValues: IpRangeValue[];
}

export interface IpRangeValue {
    fromIp: string;
    toIp: string;
    ipRestrictionType: IpRestrictionType;
}

interface TokenRestriction {
    token: string;
    headerName: string;
    isEnabled: boolean;
}

export enum IpRestrictionType {
    SINGLE_IP = 'SINGLE_IP',
    IP_RANGE = 'IP_RANGE',
}

export interface AccessControlPageDefinition {
    accessControl: AccessControlDefinition;
    encryptionKey?: string;
}

export default AccessControlDefinition;
