enum MarketplaceItemType {
    SOLUTION = 'SOLUTION',
    MODULE = 'MODULE',
    DATA_SOURCE = 'DATA_SOURCE',
    PAGE = 'PAGE',
    CONTRACT = 'CONTRACT',
}

export const marketplaceItemTypeDisplayName: Record<MarketplaceItemType, string> = {
    [MarketplaceItemType.SOLUTION]: 'Solution',
    [MarketplaceItemType.MODULE]: 'Module',
    [MarketplaceItemType.DATA_SOURCE]: 'Data source',
    [MarketplaceItemType.PAGE]: 'Page',
    [MarketplaceItemType.CONTRACT]: 'Contract',
};

export default MarketplaceItemType;
