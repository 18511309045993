import React from 'react';
import styled, { css } from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StepContainer = styled.section`
    display: flex;
    min-height: 116px;
`;

const OrderContainer = styled.span`
    width: 15px;
    margin-left: 4px;
    margin-right: 10px;
    position: relative;
`;

const OrderLine = styled.div`
    width: 1px;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 7px;
    right: 7px;
    background-color: ${Theme.colors.gray_400};
    position: absolute;
`;

const OrderNumber = styled.div<{ muted?: boolean }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: ${FontSize.XSMALL_10};
    line-height: 1;
    color: ${Theme.colors.basicBackground};
    background-color: ${Theme.colors.primaryHighlight};
    position: absolute;
    z-index: 2;

    ${({ muted }) =>
        muted &&
        css`
            background-color: ${Theme.colors.gray_300};
            z-index: 0;
        `}
`;

const StepWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const notNullChildren = React.Children.toArray(children).filter((child) => child !== null);
    return (
        <>
            {notNullChildren.map((child, index) => {
                return (
                    <StepContainer key={index}>
                        <OrderContainer>
                            <OrderNumber>{index + 1}</OrderNumber>
                            {index + 1 < notNullChildren.length && <OrderLine />}
                        </OrderContainer>
                        {child}
                    </StepContainer>
                );
            })}
        </>
    );
};

export default StepWrapper;
