enum CustomTriggerActionType {
    MANUAL_FIELD_UPDATE = 'MANUAL_FIELD_UPDATE',
    MOVE_INITIATIVE = 'MOVE_INITIATIVE',
    DELAY = 'DELAY',
    PERSON_INQUIRY = 'PERSON_INQUIRY',
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
    PERFORM_INTEGRATION_ACTION = 'PERFORM_INTEGRATION_ACTION',
    NEXT_STEPS = 'NEXT_STEPS',
    MANUAL_NEXT_STEPS = 'MANUAL_NEXT_STEPS',
    ASK_FIELD_UPDATE = 'ASK_FIELD_UPDATE',
    APPROVAL_CYCLE = 'APPROVAL_CYCLE',
    MANUAL_OWNER_UPDATE = 'MANUAL_OWNER_UPDATE',
    GATHER_UPDATE = 'GATHER_UPDATE',
    MONITOR_TRACKS = 'MONITOR_TRACKS',
    BOT_BUTTON_PRESSED = 'BOT_BUTTON_PRESSED',
    CLAIM = 'CLAIM',
    SEND_EMAIL = 'SEND_EMAIL',
    BOT_THREAD_REPLY = 'BOT_THREAD_REPLY',
    POST_ACTION_STATE_UPDATE = 'POST_ACTION_STATE_UPDATE',
    POST_ACTION_FIELDS_UPDATE = 'POST_ACTION_FIELDS_UPDATE',
    POST_ACTION_SEND_NOTIFICATION = 'POST_ACTION_SEND_NOTIFICATION',
    OUTGOING_WEBHOOK = 'OUTGOING_WEBHOOK',
    UNKNOWN = 'UNKNOWN',
    TONKEAN_ACTION = 'TONKEAN_ACTION',
    NLP_PROCESSOR = 'NLP_PROCESSOR',
    NLP_BRANCH = 'NLP_BRANCH',
    NLP_BRANCH_DEFAULT = 'NLP_BRANCH_DEFAULT',
    SEND_FORM = 'SEND_FORM',
    SEND_FORM_ANSWERED = 'SEND_FORM_ANSWERED',
    SEND_ITEM_INTERFACE = 'SEND_ITEM_INTERFACE',
    TRAIN_TRIGGER = 'TRAIN_TRIGGER',
    HTTP_UPLOAD = 'HTTP_UPLOAD',
    SYNC_INNER_MATCHED_ENTITY = 'SYNC_INNER_MATCHED_ENTITY',
    ASK_FIELD_UPDATE_ANSWERED = 'ASK_FIELD_UPDATE_ANSWERED',
    OCR_CONVERSION = 'OCR_CONVERSION',
    TEXT_EXTRACTOR = 'TEXT_EXTRACTOR',
    NLP_BRANCH_ANY_OF_THE_REST = 'NLP_BRANCH_ANY_OF_THE_REST',
}

export default CustomTriggerActionType;
