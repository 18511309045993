import SavingState from '../SavingState';

enum FormBuilderSections {
    DisplayName = 'DisplayName',
    WebThemeDesign = 'WebThemeDesign',
    Subtitle = 'Subtitle',
    ExpandableDescription = 'ExpandableDescription',
    ExpandableDescriptionLabel = 'ExpandableDescriptionLabel',

    /* Create Form */
    CommunicationIntegrationCommands = 'CommunicationIntegrationCommands',
    NewTrackLocation = 'NewTrackLocation',
    AIAccess = 'AIAccess',
    HomepageAccess = 'HomepageAccess',

    /* Update Form */
    FormType = 'FormType',
    FormAvailableOn = 'FormAvailableOn',

    /* Collect Inner Items Form */
    ItemsAmountValidation = 'ItemsAmountValidation',
    CollectionFormSelector = 'CollectionFormSelector',

    /* Upload Files */
    CanSkipUpload = 'CanSkipUpload',
    DataStorageId = 'DataStorageId',
    DestinationFolderId = 'DestinationFolderId',
    FieldDefinitionToSaveFileId = 'FieldDefinitionToSaveFileId',
    Builder = 'Builder',
}

export type FormBuilderEditedSectionsState = Record<FormBuilderSections, SavingState>;
export const FormBuilderEditedSectionsInitialState: FormBuilderEditedSectionsState = {
    DisplayName: SavingState.NONE,
    WebThemeDesign: SavingState.NONE,
    Subtitle: SavingState.NONE,
    ExpandableDescription: SavingState.NONE,
    ExpandableDescriptionLabel: SavingState.NONE,

    /* Create Form */
    CommunicationIntegrationCommands: SavingState.NONE,
    NewTrackLocation: SavingState.NONE,
    AIAccess: SavingState.NONE,
    HomepageAccess: SavingState.NONE,

    /* Update Form */
    FormType: SavingState.NONE,
    FormAvailableOn: SavingState.NONE,

    /* Collect Inner Items Form */
    ItemsAmountValidation: SavingState.NONE,
    CollectionFormSelector: SavingState.NONE,

    /* Upload Files */
    CanSkipUpload: SavingState.NONE,
    DataStorageId: SavingState.NONE,
    DestinationFolderId: SavingState.NONE,
    FieldDefinitionToSaveFileId: SavingState.NONE,

    Builder: SavingState.NONE,
};

export default FormBuilderSections;
