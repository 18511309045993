enum TonkeanType {
    INTEGRATION = 'INTEGRATION',
    PROJECT = 'PROJECT',
    PERSON = 'PERSON',
    AUTH_TOKEN = 'AUTH_TOKEN',
    MESSAGE_SEND = 'MESSAGE_SEND',
    ACTIVITY = 'ACTIVITY',
    COMMENT = 'COMMENT',
    ORGANIZATION = 'ORGANIZATION',
    PROJECT_INTEGRATION = 'PROJECT_INTEGRATION',
    INITIATIVE = 'INITIATIVE',
    FUNCTION = 'FUNCTION',
    DELIVERABLE = 'DELIVERABLE',
    GROUP = 'GROUP',
    WAIT_MESSAGE = 'WAIT_MESSAGE',
    GATHER_HISTORY = 'GATHER_HISTORY',
    FIELD_DEFINITION = 'FIELD_DEFINITION',
    FIELD = 'FIELD',
    INITIATIVE_LINK = 'INITIATIVE_LINK',
    TILE = 'TILE',
    HIGHLIGHT = 'HIGHLIGHT',
    HIGHLIGHT_REPORT = 'HIGHLIGHT_REPORT',
    SYNC_CONFIG = 'SYNC_CONFIG',
    ADMIN_COMMENT = 'ADMIN_COMMENT',
    SCHEDULED_REPORT = 'SCHEDULED_REPORT',
    CALENDAR_FEED_SUBSCRIPTION = 'CALENDAR_FEED_SUBSCRIPTION',
    OUTGOING_WEBHOOK = 'OUTGOING_WEBHOOK',
    INCOMING_WEBHOOK = 'INCOMING_WEBHOOK',
    CUSTOM_TRIGGER = 'CUSTOM_TRIGGER',
    GROUP_ACCESS_LINK = 'GROUP_ACCESS_LINK',
    STATE = 'STATE',
    CUSTOM_TRIGGER_ACTION = 'CUSTOM_TRIGGER_ACTION',
    ENTERPRISE = 'ENTERPRISE',
    WORKER_RUN = 'WORKER_RUN',
    WORKER_RUN_LOGIC = 'WORKER_RUN_LOGIC',
    WORKER_RUN_LOGIC_ACTION = 'WORKER_RUN_LOGIC_ACTION',
    WORKER_RUN_LOGIC_SCHEDULED = 'WORKER_RUN_LOGIC_SCHEDULED',
    FORM = 'FORM',
    CONFIGURATION = 'CONFIGURATION',
    WORKFLOW_VERSION = 'WORKFLOW_VERSION',
    WORKER_RUN_ERROR = 'WORKER_RUN_ERROR',
    ADMIN = 'ADMIN',
    TEMPLATE = 'TEMPLATE',
    CATEGORY = 'CATEGORY',
    TRAINED_KEYWORD = 'TRAINED_KEYWORD',
    WORKER_STATIC_ASSET = 'WORKER_STATIC_ASSET',
    WORKFLOW_FOLDER = 'WORKFLOW_FOLDER',
    WORKFLOW_FOLDER_VERSION = 'WORKFLOW_FOLDER_VERSION',
    API_JOB = 'API_JOB',
    DEPENDENCY = 'DEPENDENCY',
    BACKGROUND_PROCESS = 'BACKGROUND_PROCESS',
    OPERATION_NODE = 'OPERATION_NODE',
    OPERATION_EDGE = 'OPERATION_EDGE',
    PROJECT_INTEGRATION_ACTION = 'PROJECT_INTEGRATION_ACTION',
    FIELD_FORMULA_DATA = 'FIELD_FORMULA_DATA',
    OPERATION_CONFIGURATION = 'OPERATION_CONFIGURATION',
    PEOPLE_DIRECTORY = 'PEOPLE_DIRECTORY',
    PEOPLE_DIRECTORY_INSTANCE = 'PEOPLE_DIRECTORY_INSTANCE',
    AUDIT_LOG = 'AUDIT_LOG',
    TRAINING_SET = 'TRAINING_SET',
    TRAINING_SET_FIELD = 'TRAINING_SET_FIELD',
    TRAINING_SET_MODEL = 'TRAINING_SET_MODEL',
    TRAINING_SET_ITEM = 'TRAINING_SET_ITEM',
    USER_GROUP = 'USER_GROUP',
    SOLUTION_BUSINESS_REPORT = 'SOLUTION_BUSINESS_REPORT',
    ITEM_INTERFACE = 'ITEM_INTERFACE',
    ITEM_INTERFACE_WIDGET = 'ITEM_INTERFACE_WIDGET',
    ENTERPRISE_COMPONENT_VARIABLE = 'ENTERPRISE_COMPONENT_VARIABLE',
    ENTERPRISE_COMPONENT_VARIABLE_VALUE = 'ENTERPRISE_COMPONENT_VARIABLE_VALUE',
    PROJECT_INTEGRATION_ENTITY = 'PROJECT_INTEGRATION_ENTITY',
    PROJECT_INTEGRATION_ENTITY_FIELD_METADATA = 'PROJECT_INTEGRATION_ENTITY_FIELD_METADATA',
    PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG = 'PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG',
    WORKFLOW_FOLDER_CATEGORY = 'WORKFLOW_FOLDER_CATEGORY',
    SOLUTION_MAPPER = 'SOLUTION_MAPPER',
    PROCESS_PARTICIPANT = 'PROCESS_PARTICIPANT',
    MARKETPLACE_ITEM = 'MARKETPLACE_ITEM',
    SOLUTION_SITE = 'SOLUTION_SITE',
    SOLUTION_SITE_PAGE = 'SOLUTION_SITE_PAGE',
    ENTITY_VERSION = 'ENTITY_VERSION',
    ITEM_DETAILS = 'ITEM_DETAILS',
    PROCESS_MAPPER = 'PROCESS_MAPPER',
    PROCESS_MAPPER_NODE = 'PROCESS_MAPPER_NODE',
    PROCESS_MAPPER_EDGE = 'PROCESS_MAPPER_EDGE',
    CONTRACT = 'CONTRACT',
    CONTRACT_FIELD = 'CONTRACT_FIELD',
}

export default TonkeanType;
