import { useCallback } from 'react';

import useCurrentlyAuthenticatedUser from '../useCurrentlyAuthenticatedUser';
import useModifiableList, { type LIST_CHANGE_TYPE } from '../useModifiableList';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    EntityRef,
    Group,
    NewActivityData,
    Project,
    TonkeanId,
    TonkeanType,
    Activity,
    Comment,
} from '@tonkean/tonkean-entities';
import { ActivityType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

type ModifiableCommentsReturnType = [
    modifiableComments: Activity[],
    modifingFuncs: {
        onCommentDelete(
            commentId: TonkeanId<TonkeanType.ACTIVITY | TonkeanType.COMMENT>,
            targetId?: TonkeanId<TonkeanType.ACTIVITY>,
        ): void;
        onCommentAdded(newActivity: NewActivityData, saveAsPrivateComment?: boolean): void;
        reset: (typesToReset: LIST_CHANGE_TYPE[]) => void;
    },
];

function useModifiableComments(
    comments: Activity[],
    group?: EntityRef<Group> | undefined,
    project?: EntityRef<Project> | undefined,
): ModifiableCommentsReturnType {
    const [{ error: postCommentError }, postComment] = useLazyTonkeanService('postComment');
    const [{ error: postCommentWithInitiativeError }, postCommentWithInitiative] =
        useLazyTonkeanService('postCommentWithInitiative');

    const [modifiableComments, { onCreate, onUpdate, onDelete, reset }] = useModifiableList(comments);

    const onCommentDelete = useCallback(
        (
            commentId: TonkeanId<TonkeanType.ACTIVITY | TonkeanType.COMMENT>,
            targetId?: TonkeanId<TonkeanType.ACTIVITY>,
        ) => {
            if (targetId) {
                // deletion of a comment from a thread
                const parentComment = modifiableComments?.find((activity) => activity?.id === targetId);
                if (!parentComment) {
                    return;
                }

                const updatedCommentList = parentComment.comments?.filter((comment) => comment.id !== commentId) || [];
                onUpdate(parentComment.id, { comments: updatedCommentList });
            } else {
                onDelete(commentId);
            }
        },
        [modifiableComments, onDelete, onUpdate],
    );

    const currentUser = useCurrentlyAuthenticatedUser();
    const onCommentAdded = useCallback(
        async (data: NewActivityData, filterOnOtherTargets: boolean) => {
            if (!group || !project) {
                return;
            }

            let filterOnOtherTargetsToSave = filterOnOtherTargets;

            // check if the target id is initiative or activity to know whether to add it to the main comments list, or to existing activity (a comment on thread)
            const commentedOn = modifiableComments.find(
                (existingComment) => existingComment.id === data.targetEntityId,
            );
            const uniqueId = utils.guid();
            const newCommentId = !commentedOn ? `ACTI${uniqueId}` : `CMNT${uniqueId}`;

            if (!commentedOn) {
                // create an activity from the returned comment
                const newCommentActivity = {
                    id: newCommentId,
                    type: ActivityType.NEW_COMMENT,
                    actor: currentUser,
                    group,
                    project,
                    created: Date.now(),
                    reference1: data,
                } as Activity;

                onCreate(newCommentActivity);
            } else {
                filterOnOtherTargetsToSave = commentedOn.reference1?.filterOnOtherTargets ?? filterOnOtherTargets;
                // update the comments list of an existing activity (a comment on thread)
                const newCommentOnThread = {
                    id: newCommentId,
                    commenter: currentUser,
                    project,
                    created: Date.now(),
                    text: data.text,
                    target: { id: data.targetEntityId },
                } as Comment;

                const updatedCommentList = [...(commentedOn.comments || []), newCommentOnThread];
                onUpdate(commentedOn.id, { comments: updatedCommentList });
            }

            // todo: change updateInitiativeId
            const postPromise = data.updateInitiativeId
                ? postCommentWithInitiative(
                      data.targetEntityId,
                      data.text,
                      data.updateInitiativeId || '',
                      data.atMentionIds,
                      filterOnOtherTargetsToSave,
                  )
                : postComment(data.targetEntityId, data.text, data.updateInitiativeId || '', data.atMentionIds);

            return postPromise.then((response) => {
                // todo: error
                if (!commentedOn && response.activity) {
                    onDelete(newCommentId);
                    const activityToCreate = {
                        ...response.activity,
                        reference1: response.comment,
                        actor: currentUser,
                    };
                    onCreate(activityToCreate);
                }

                if (commentedOn && response.comment) {
                    const commentToCreate = {
                        ...response.comment,
                        commenter: currentUser,
                    } as Comment;

                    const updatedCommentList = [
                        ...(commentedOn.comments?.filter((comment) => comment.id !== newCommentId) || []),
                        commentToCreate,
                    ];

                    onUpdate(commentedOn.id, { comments: updatedCommentList });
                }
            });
        },
        [
            currentUser,
            group,
            modifiableComments,
            onCreate,
            onDelete,
            onUpdate,
            postComment,
            postCommentWithInitiative,
            project,
        ],
    );

    return [modifiableComments, { onCommentAdded, onCommentDelete, reset }];
}

export default useModifiableComments;
