import React, { useState } from 'react';
import styled from 'styled-components';

import type { AddFieldsReducerAction, FieldOption } from './entities';
import FieldsImporterRow from './FieldsImporterRow';
import { getAllCheckerData } from './utils/getAllCheckerData';
import SearchBox from '../SearchBox';
import { H3, Paragraph } from '../TUI';
import KeyboardArrowFocusSwitch from '../TUI/KeyboardArrowFocusSwitch';

import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const Table = styled.form`
    display: flex;
    flex-direction: column;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    overflow: auto;
`;

const TableHeaderBox = styled.div`
    padding: 7px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Theme.colors.gray_300};

    justify-content: space-between;
`;

const RowsBox = styled.div`
    overflow: auto;
`;

type Props<T extends string = string> = {
    fieldsState: FieldOption<T>[];
    dispatcher: (action: AddFieldsReducerAction<T>) => void;
};

const FieldsImporter = <T extends string>({ fieldsState, dispatcher }: Props<T>) => {
    const [hoveredFieldId, setHoveredFieldId] = useState<T>();
    const [searchText, setSearchText] = useState('');
    const isAllDisabled = fieldsState.every((field) => field.disabled);

    const allCheckerData = getAllCheckerData(fieldsState);

    const filteredFields = fieldsState.filter((field) => {
        return field.displayName.toLowerCase().includes(searchText.toLowerCase());
    });
    return (
        <>
            <SearchBox
                data-automation="fields-importer-search-box"
                $width="50%"
                $borderRadius="4px"
                value={searchText}
                onChange={({ target: { value: newText } }) => {
                    setSearchText(newText);
                }}
            />

            {filteredFields.length === 0 && (
                <H3 data-automation="fields-importer-no-fields">
                    No fields found {!!searchText && `for the search term '${searchText}'`}
                </H3>
            )}

            {filteredFields.length > 0 && (
                <Table>
                    <TableHeaderBox>
                        <Paragraph>Field Name</Paragraph>

                        {!isAllDisabled && (
                            <ClickableLink
                                data-automation="fields-importer-check-uncheck-all"
                                onClick={() => {
                                    if (allCheckerData?.action) dispatcher({ action: allCheckerData.action });
                                }}
                            >
                                {allCheckerData?.label}
                            </ClickableLink>
                        )}
                    </TableHeaderBox>

                    <KeyboardArrowFocusSwitch>
                        <RowsBox>
                            {filteredFields.map(({ id, isSelected, displayName, disabled }, index) => (
                                <FieldsImporterRow
                                    key={id}
                                    id={id}
                                    text={displayName}
                                    disabled={disabled}
                                    checked={isSelected}
                                    highlightText={searchText}
                                    showCheckbox={hoveredFieldId === id || isSelected}
                                    onClick={() => dispatcher({ id, action: isSelected ? 'unchecked' : 'checked' })}
                                    onHover={() => setHoveredFieldId(id)}
                                    isHighlighted={hoveredFieldId === id}
                                    topBorder={index !== 0}
                                    onMouseLeave={() =>
                                        setHoveredFieldId((prevId) => (prevId === id ? undefined : prevId))
                                    }
                                />
                            ))}
                        </RowsBox>
                    </KeyboardArrowFocusSwitch>
                </Table>
            )}
        </>
    );
};

export default FieldsImporter;
