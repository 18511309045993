import styled, { css } from 'styled-components';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const theme = Theme.current.palette.TUI;

function sizeToPx(size: InputSize) {
    switch (size) {
        case InputSize.LARGE:
            return '18';
        case InputSize.MEDIUM_LARGE:
            return '16';
        default:
            return '12';
    }
}

const RadioIcon = styled.div<{
    $checked?: boolean;
    $disabled?: boolean;
    $size: InputSize;
    $focused?: boolean;
    $selectedColor?: Color;
    $unselectedColor?: Color;
}>`
    height: ${({ $size }) => sizeToPx($size)}px;
    width: ${({ $size }) => sizeToPx($size)}px;
    padding: 2px;
    margin: 0;

    transition: all 200ms;
    background: transparent;
    border: ${({ $size }) => ($size === InputSize.LARGE ? 2 : 1)}px solid
        ${({ $unselectedColor }) => $unselectedColor ?? Theme.colors.gray_700};
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    ::after {
        content: '';
        transition: transform 200ms;
        transform: scale(0);
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: ${Theme.colors.gray_700};
    }

    ${styledFocus} /* TODO handle disabled state */ ${({ $checked = false, $selectedColor }) =>
        $checked &&
        css`
            border-color: ${$selectedColor ?? theme.selected};

            ::after {
                transform: scale(1);
                background: ${$selectedColor ?? theme.selected};
            }
        `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            border-color: ${Theme.colors.gray_500};

            ::after {
                background: ${Theme.colors.gray_500};
            }
        `}
`;

export default RadioIcon;
