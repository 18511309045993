import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

interface Props {
    direction?: 'horizontal' | 'vertical';
    color?: string;
    $margin?: string;
}

const Separator = styled.div<Props>`
    ${({ direction = 'horizontal', $margin = '0' }) =>
        direction === 'horizontal'
            ? css`
                  height: 1px;
                  margin-top: ${$margin};
                  margin-bottom: ${$margin};
              `
            : css`
                  width: 1px;
                  height: 100%;
                  margin-left: ${$margin};
                  margin-right: ${$margin};
              `}
    background: ${({ color = Theme.colors.gray_300 }) => color};
`;

export default Separator;
