import memoize from './memoize';

/**
 * Decodes an encoded html string using the DOMParser.
 * It will fully decode html, and will miss nothing, but it uses the DOM api for that, making this operation slower
 * than using regex replace. This function is memoized.
 *
 * @param encodedHtml - the encoded string.
 * @returns unencoded string.
 */
function htmlDecode(encodedHtml: string) {
    const { documentElement } = new DOMParser().parseFromString(encodedHtml, 'text/html');
    return documentElement.textContent;
}

export default memoize(htmlDecode);
