import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

interface StyledComponentsTheme {
    current: typeof Theme.current;
    isKeyboardFocus: boolean;
}

const StyledComponentsThemeManager: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [isKeyboardFocus, setIsKeyboardFocus] = useState(false);
    useEffect(() => {
        const onMousedown = () => {
            setIsKeyboardFocus(false);
        };
        const onKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Tab') {
                setIsKeyboardFocus(true);
            }
        };

        document.addEventListener('mousedown', onMousedown);
        document.addEventListener('keydown', onKeydown);

        return () => {
            document.removeEventListener('mousedown', onMousedown);
            document.removeEventListener('keydown', onKeydown);
        };
    }, []);

    const currentTheme = Theme.current;

    const theme = useMemo(
        () => ({
            current: currentTheme,
            isKeyboardFocus,
        }),
        [currentTheme, isKeyboardFocus],
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledComponentsThemeManager;

declare module 'styled-components' {
    interface DefaultTheme extends StyledComponentsTheme {}
}
