import React, { useContext } from 'react';

import OptimizedImageContext from './OptimizedImageContext';
import type { OptimizedImageProps } from './OptimizedImageProps';

const OptimizedImage: React.ForwardRefRenderFunction<HTMLImageElement, OptimizedImageProps> = (props, ref) => {
    const Component = useContext(OptimizedImageContext) ?? 'img';
    return <Component {...props} ref={ref} />;
};

export default React.forwardRef(OptimizedImage);
