import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as XIcon } from '../../../../images/x.svg';
import useCloseCallback from '../CloseCallback/useCloseCallback';

import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const sizes = Theme.sizes.closeButton;

const defaultSize = ButtonSize.BIG;
const CloseIconWrapper = styled.button<Props>`
    cursor: pointer;
    display: grid;
    place-content: center;
    width: ${({ size = defaultSize }) => sizes[size].width}px;
    height: ${({ size = defaultSize }) => sizes[size].height}px;
    border: none;
    background-color: transparent;
    transition: background-color 0.2s linear;
    border-radius: 3px;

    ${({ autoMarginLeft }) =>
        autoMarginLeft &&
        css`
            margin-left: auto;
        `}

    &:active, &:hover {
        background-color: ${Theme.colors.gray_300};
        transition: background-color 0.2s linear;
    }

    svg {
        path {
            fill: ${({ fillColor }) => (fillColor ? fillColor : Theme.colors.gray_500)};
        }

        width: ${({ size = defaultSize }) => sizes[size].svg.width}px;
        height: ${({ size = defaultSize }) => sizes[size].svg.height}px;
    }
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: ButtonSize;
    autoMarginLeft?: boolean;
    fillColor?: string;
}

const XCloseButton: React.FC<Props> = ({ size = defaultSize, onClick, ...props }) => {
    const onClose = useCloseCallback();

    return (
        <CloseIconWrapper
            data-automation="x-close-button"
            type="button"
            {...props}
            onClick={onClick || onClose}
            size={size}
        >
            <XIcon />
        </CloseIconWrapper>
    );
};

export default XCloseButton;
