import { STATUSES } from './statuses';

export const FIELD_HIGHLIGHT_COLORS = [
    {
        label: 'Green',
        color: STATUSES.IN_PROGRESS.color,
        isWarn: false,
    },
    {
        label: 'Orange',
        color: STATUSES.WARNING.color,
        isWarn: false,
    },
    {
        label: 'Red',
        color: STATUSES.BLOCKED.color,
        isWarn: true,
    },
    {
        label: 'Blue',
        color: STATUSES.DONE.color,
        isWarn: false,
    },
    {
        label: 'Purple',
        color: '#6C58A7',
        isWarn: false,
    },
    {
        label: 'Grey',
        color: STATUSES.NOT_STARTED.color,
        isWarn: false,
    },
];

export function getFieldHighlightColors() {
    return structuredClone(FIELD_HIGHLIGHT_COLORS);
}
