enum FieldDefinitionDisplayFormat {
    // Number formats
    RAW_NUMBER = 'RAW_NUMBER',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    CURRENCY = 'CURRENCY',
    DECIMAL = 'DECIMAL',

    // Date formats
    DEFAULT_DATE = 'DEFAULT_DATE',
    MEDIUM_DATE_TIME = 'MEDIUM_DATE_TIME',
    SHORT_DATE_TIME = 'SHORT_DATE_TIME',
    FULL_DATE = 'FULL_DATE',
    LONG_DATE = 'LONG_DATE',
    SHORT_DATE = 'SHORT_DATE',
    MEDIUM_TIME = 'MEDIUM_TIME',
    SHORT_TIME = 'SHORT_TIME',
    DATE_DIFF_FROM_TODAY = 'DATE_DIFF_FROM_TODAY',

    // Text formats
    USER = 'USER',
    TEXT = 'TEXT',

    // LongString Formats
    HTML = 'HTML',
    LONG_TEXT = 'LONG_TEXT',
    FILE = 'FILE',
}

export default FieldDefinitionDisplayFormat;
