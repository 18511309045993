import { saturate } from 'polished';
import styled, { css } from 'styled-components';

import type ButtonProps from './ButtonProps';
import ButtonShape from './ButtonShape';
import DisableableButton from './DisableableButton';
import Clickable from '../Clickable/Clickable';

import StateLink from '@tonkean/tui-basic/StateLink';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { ButtonStyle } from '@tonkean/tui-theme/styles';
import { colorSvg } from '@tonkean/utils';

const propsToExclude: Set<keyof ButtonProps> = new Set([
    'size',
    'shape',
    'flex',
    'outlined',
    'cancel',
    'publishRelated',
    'warning',
    'warningOutlined',
    'highlighted',
    'promotion',
    'deleteButton',
]);

function propsToStyle(props: ButtonProps): ButtonStyle {
    if (props.cancel) {
        return ButtonStyle.CANCEL;
    }

    if (props.warning) {
        return ButtonStyle.WARNING;
    }

    if (props.warningOutlined) {
        return ButtonStyle.WARNING_OUTLINED;
    }

    if (props.deleteButton) {
        return ButtonStyle.DELETE;
    }

    if (props.publishRelated) {
        return ButtonStyle.PUBLISH_RELATED;
    }

    if (props.highlighted && props.outlined) {
        return ButtonStyle.HIGHLIGHTED_OUTLINED;
    }

    if (props.promotion && props.outlined) {
        return ButtonStyle.PROMOTION_OUTLINED;
    }

    if (props.outlined) {
        return ButtonStyle.OUTLINED;
    }

    if (props.highlighted) {
        return ButtonStyle.HIGHLIGHTED;
    }

    if (props.promotion) {
        return ButtonStyle.PROMOTION;
    }

    return ButtonStyle.FILLED;
}

const theme = (props: ButtonProps) => Theme.current.palette.TUI.button[propsToStyle(props)];
const sizes = ({ size = ButtonSize.BIG }: ButtonProps) => Theme.sizes.button[size];

const Button = styled(DisableableButton)
    .attrs((props) => ({ type: props.type ?? 'button' }))
    .withConfig({
        shouldForwardProp: (prop: any) => !propsToExclude.has(prop),
    })<ButtonProps>`
    ${({ flex = false }) =>
        flex
            ? css<ButtonProps>`
                  display: flex;
                  align-items: center;
              `
            : css<ButtonProps>`
                  display: inline-block;
              `};

    line-height: ${(props) => sizes(props).height - sizes(props).borderWidth * 2}px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    border-width: ${(props) => sizes(props).borderWidth}px;
    border-style: solid;
    border-color: ${(props) => props.backgroundColor || theme(props).borderColor};
    border-radius: ${({ shape = ButtonShape.ROUND }) => (shape === ButtonShape.ROUND ? '100px' : '3px')};
    background: ${(props) => props.backgroundColor || theme(props).backgroundColor};
    font-size: ${(props) => sizes(props).fontSize};
    height: ${(props) => sizes(props).height}px;
    padding: 0 ${(props) => sizes(props).paddingRightLeft}px;
    min-width: ${(props) => sizes(props).minWidth}px;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;

    &,
    &:focus {
        color: ${(props) => (props.textColor ? saturate(0.25, props.textColor.toString()) : theme(props).textColor)};
        text-decoration: none;
    }

    svg {
        ${(props) => colorSvg(theme(props).textColor, true)}
    }

    &:hover {
        text-decoration: none;
        color: ${(props) =>
            props.textColor ? saturate(0.25, props.textColor.toString()) : theme(props).textHoverColor};
        background: ${(props) =>
            props.backgroundColor
                ? saturate(0.25, props.backgroundColor.toString())
                : theme(props).backgroundHoverColor};
        border-color: ${(props) =>
            props.backgroundColor ? saturate(0.25, props.backgroundColor.toString()) : theme(props).borderHoverColor};

        svg {
            ${(props) => colorSvg(theme(props).textHoverColor)}
        }
    }
    
    ${({ disabled }) =>
        disabled &&
        css<ButtonProps>`
            &,
            &:hover,
            &:focus {
                color: ${(props) => theme(props).textDisabledColor};
                background: ${(props) => theme(props).backgroundDisabledColor};
                border-color: ${(props) => theme(props).borderDisabledColor};

                svg {
                    ${(props) => colorSvg(theme(props).textDisabledColor)}
                }
            }
        `}

    svg {
        display: inline-block;
        vertical-align: middle;

        height: ${(props) => sizes(props).icon.size}px;
        width: ${(props) => sizes(props).icon.size}px;
        margin-right: ${(props) => sizes(props).icon.marginRight}px;
    }

    ${styledFocus}
`;

export default Button;
export const ClickableButton = styled(Button).attrs({ as: Clickable })``;
export const AnchorButton = styled(Button).attrs({ as: 'a' })``;
export const StateLinkButton = styled(Button).attrs({ as: StateLink })``;
