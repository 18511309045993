import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalBody, ModalHeader, ModalSize } from './TUI/Modal';
import ModalFooterActions from './TUI/Modal/ModalFooterActions';
import { Tooltip } from './TUI/Tooltip';
import { ReactComponent as PencilIcon } from '../../images/edit-pencil.svg';

import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { ErrorResponseType, StyledComponentsSupportProps } from '@tonkean/utils';

const Input = styled.input`
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: ${FontSize.MEDIUM_14};
    padding: 8px;
    color: ${Theme.colors.HEX_333333};
    width: 100%;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
`;

export const CharCount = styled.div.attrs<{ currentCount: number; maxCount: number }>((props) => ({
    children: `${props.currentCount}/${props.maxCount}`,
}))<{ currentCount: number; maxCount: number }>`
    color: ${(props) => (props.currentCount >= props.maxCount ? Theme.colors.error : Theme.colors.gray_500)};
    text-align: end;
    font-size: 10px;
    margin-top: 4px;
`;

const EditModuleNameButton = styled(DisableableButton)`
    height: 20px;
    width: 20px;
    border: none;
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    background: rgb(233, 236, 241, 0.6);
    border-radius: 3px;
`;

interface Props extends StyledComponentsSupportProps {
    title: string;
    inputPlaceholder: string;
    maxLength: number;
    name: string;
    onNameChange: (name: string) => Promise<void>;
    editingDisabled: boolean;
    editingDisabledTooltip: string;
    isModalOpen?: boolean;
    hideOpenButton?: boolean;
    onCloseModal?: () => void;
}

const EditNameModal: React.FC<Props> = ({
    title,
    inputPlaceholder,
    maxLength,
    name: nameProp,
    onNameChange,
    editingDisabled,
    editingDisabledTooltip,
    className,
    isModalOpen,
    hideOpenButton,
    onCloseModal,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<ErrorResponseType>();
    const [isOpen, setIsOpen] = useState<boolean>(!!isModalOpen);
    const [name, setName] = useState<string>(nameProp);

    useEffect(() => {
        setName(nameProp);
    }, [nameProp]);

    const inputRef = useRef<HTMLInputElement>(null);

    const updateDisplayName = () => {
        if (name.length > maxLength) {
            return;
        }

        if (name.trim() === nameProp.trim()) {
            setIsOpen(false);
            return;
        }

        setIsLoading(true);
        setErrorMessage(undefined);

        onNameChange(name)
            .then(() => setIsOpen(false))
            .catch((error) => setErrorMessage(error))
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {!hideOpenButton && (
                <Tooltip content={editingDisabledTooltip} disabled={!editingDisabled}>
                    <EditModuleNameButton
                        onClick={() => setIsOpen(true)}
                        disabled={editingDisabled}
                        aria-label="Edit name"
                        className={className}
                    >
                        <PencilIcon />
                    </EditModuleNameButton>
                </Tooltip>
            )}

            <Modal
                open={isOpen}
                onClose={() => {
                    onCloseModal && onCloseModal();
                    setIsOpen(false);
                }}
                size={ModalSize.SMALL}
                initiallyFocusedElementRef={inputRef}
                fixedWidth
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        return updateDisplayName();
                    }}
                >
                    <ModalHeader $border={false}>{title}</ModalHeader>

                    <ModalBody>
                        <Input
                            ref={inputRef}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={inputPlaceholder}
                            maxLength={maxLength}
                            required
                        />
                        <CharCount currentCount={name.length} maxCount={maxLength} />
                    </ModalBody>

                    <ModalFooterActions
                        onCancel={() => {
                            onCloseModal && onCloseModal();
                            setIsOpen(false);
                        }}
                        saveLabel="Rename"
                        loading={isLoading}
                        error={errorMessage}
                    />
                </form>
            </Modal>
        </>
    );
};

export default EditNameModal;
