enum SCIMTonkeanRole {
    NO_ACCESS = 'NO_ACCESS',
    SYSTEM_USER = 'SYSTEM_USER',
    PROCESS_CONTRIBUTOR = 'PROCESS_CONTRIBUTOR',
    GUEST = 'GUEST',
}

export const SCIMTonkeanRoleDisplayName: Record<SCIMTonkeanRole, string> = {
    [SCIMTonkeanRole.NO_ACCESS]: 'No Access',
    [SCIMTonkeanRole.SYSTEM_USER]: 'System User',
    [SCIMTonkeanRole.PROCESS_CONTRIBUTOR]: 'Process Contributor',
    [SCIMTonkeanRole.GUEST]: 'Guest',
};

export default SCIMTonkeanRole;
