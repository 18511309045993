import React from 'react';
import styled from 'styled-components';

const SidePaneSectionTitle = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;

    &:not(:first-child) {
        margin-top: 30px;
    }
`;

const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

interface Props {
    title: string;
}

const SideMenuSection: React.FC<React.PropsWithChildren<Props>> = ({ title, children }) => {
    return (
        <>
            <SidePaneSectionTitle>{title}</SidePaneSectionTitle>
            <ItemsWrapper>{children}</ItemsWrapper>
        </>
    );
};

export default SideMenuSection;
