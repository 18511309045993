import React, { useState } from 'react';
import styled from 'styled-components';

import { Popover } from './TUI/Popover';
import useEventListener from '../hooks/useEventListener';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';

const ReferenceElement = styled.div`
    position: fixed;
    height: 0;
    width: 0;
    overflow: hidden;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    popoverContent: React.ComponentType<{ selectedText: string; onClose: () => void }>;
    active?: boolean;
    popoverNoPadding?: boolean;
}

const TextSelectionPopover: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { popoverContent: PopoverContent, onMouseUp: onMouseUpProps, active = true, popoverNoPadding, children, ...props },
    ref,
) => {
    const [placementConfig, setPlacementConfig] = useState<{
        top: number;
        left: number;
        height: number;
        selectedText: string;
    }>();

    const updateSelection = () => {
        const selection = window.getSelection();
        const selectedText = selection?.toString().trim();

        if (!selectedText || !selection) {
            setPlacementConfig(undefined);
            return;
        }

        const boundingClientRect = selection.getRangeAt(0).getBoundingClientRect();

        setPlacementConfig({
            top: boundingClientRect.y + 5,
            height: boundingClientRect.height - 10,
            left: boundingClientRect.x + 0.5 * boundingClientRect.width,
            selectedText,
        });
    };
    const onMouseUp = useCompositeEventCallback(onMouseUpProps, updateSelection);
    const onClose = () => setPlacementConfig(undefined);

    useEventListener('mouseup', updateSelection, false, !!placementConfig);

    return (
        <>
            <Popover
                content={
                    !!placementConfig && (
                        <PopoverContent selectedText={placementConfig?.selectedText} onClose={onClose} />
                    )
                }
                show={!!placementConfig && active}
                onClose={onClose}
                placement="bottom"
                manageFocus={false}
                noPadding={popoverNoPadding}
            >
                <ReferenceElement
                    style={{ top: placementConfig?.top, left: placementConfig?.left, height: placementConfig?.height }}
                />
            </Popover>

            <div onMouseUp={onMouseUp} {...props} ref={ref}>
                {children}
            </div>
        </>
    );
};

export default React.forwardRef(TextSelectionPopover);
