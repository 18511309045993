import type React from 'react';
import { useCallback, useState } from 'react';

const useToggle = (initialState: boolean): [boolean, () => void, React.Dispatch<boolean>] => {
    const [toggle, setToggle] = useState<boolean>(initialState);

    const updateToggle = useCallback(() => {
        setToggle((prevToggle) => !prevToggle);
    }, []);

    return [toggle, updateToggle, setToggle];
};

export default useToggle;
