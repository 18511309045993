enum SendToGroupOfPeopleAs {
    EACH_INDIVIDUAL = 'EACH_INDIVIDUAL',
    PEOPLE_GROUP = 'PEOPLE_GROUP',
}

export const SendToGroupOfPeopleAsDisplayName: Record<SendToGroupOfPeopleAs, string> = {
    [SendToGroupOfPeopleAs.EACH_INDIVIDUAL]: 'Direct message to each recipient',
    [SendToGroupOfPeopleAs.PEOPLE_GROUP]: 'Group message',
};

export default SendToGroupOfPeopleAs;
