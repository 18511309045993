export enum FormulaTreeNodeType {
    TREE = 'TREE',
    CONST = 'CONST',
    VARIABLE = 'VARIABLE',

    // Fake value for UI only
    EMPTY = 'EMPTY',

    // Fake value for showing in react only
    ARRAY = 'ARRAY',
}
