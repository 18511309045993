import React from 'react';
import styled from 'styled-components';

import SuccessFeedback from './SuccessFeedback';
import { useCopyToClipboard, useEphemeralState } from '../hooks';

import { CopyIconSVG } from '@tonkean/svg';
import { IconButton, type IconButtonProps } from '@tonkean/tui-buttons/Button';

const StyledIcon = styled.svg`
    grid-area: icon;
    width: 12px;
    height: 12px;
`;

interface Props extends IconButtonProps {
    link: string;
    tooltip: string;
    icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}
const CopyLinkButton: React.FC<React.PropsWithChildren<Props>> = ({ link, tooltip, icon = CopyIconSVG, ...props }) => {
    const copyToClipboard = useCopyToClipboard();

    const [copied, setCopied] = useEphemeralState(false, 2000);

    return (
        <SuccessFeedback successful={copied} tooltip={tooltip} tooltipSuccessful="Copied!">
            <IconButton
                onClick={async () => {
                    const copiedSuccessfully = await copyToClipboard(link);
                    setCopied(copiedSuccessfully);
                }}
                aria-label="Copy link to preview"
                flat
                {...props}
            >
                <StyledIcon as={icon} />
            </IconButton>
        </SuccessFeedback>
    );
};

export default CopyLinkButton;
