import { useContext, useEffect, useState } from 'react';

import IsSSRContext from './IsSSRContext';

function useIsSSR() {
    const [isSSR, setIsSSR] = useState(true);

    const isSSRContext = useContext(IsSSRContext);
    /**
     * This is being done in a useEffect and not just returned directly to allow first render to match
     * the render done in the server - otherwise react hydration will throw an error.
     */
    useEffect(() => {
        setIsSSR(isSSRContext);
    }, [isSSRContext]);

    return isSSR;
}

export default useIsSSR;
