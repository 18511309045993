import React, { useMemo } from 'react';
import { getTrackBackground, Range } from 'react-range';
import styled from 'styled-components';

import { useInnerField } from '../Field';

import { Theme } from '@tonkean/tui-theme';
import { toArray } from '@tonkean/utils';

const SliderTruck = styled.div`
    display: flex;
    height: 2px;
    width: 100%;
`;

const TruckContent = styled.div<{ valueArray: any[]; min: number; max: number }>`
    height: 2px;
    width: 100%;
    border-radius: 4px;
    background: ${({ valueArray, min, max }) =>
        getTrackBackground({
            values: valueArray,
            colors: [Theme.colors.gray_800, Theme.colors.gray_400],
            min,
            max,
        })};
    align-self: center;
`;

const SliderThumb = styled.div`
    height: 16px;
    width: 16px;
    background-color: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 50%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
`;

interface Props {
    step?: number;
    min: number;
    max: number;
    /** Name to pass to the form field */
    name?: string;
    value?: number;
    onChange?(value: number | undefined): void;
}

const Slider: React.FC<Props> = ({ step = 1, min, max, name, value, onChange }) => {
    const [fieldProps, hasError, formikHelpers] = useInnerField({
        name,
        multiple: false,
        type: 'slider',
        value,
    });

    const valueArray = useMemo(() => {
        return toArray(fieldProps.value || min);
    }, [fieldProps.value, min]);

    const setTouched = () => {
        formikHelpers?.setTouched(true);
    };

    return (
        <Range
            step={step}
            min={min}
            max={max}
            values={valueArray}
            onChange={(values) => {
                formikHelpers?.setValue(values[0]);
                onChange?.(values[0]);
            }}
            renderTrack={({ props, children }) => (
                <SliderTruck
                    style={{ ...props.style }}
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    onBlur={setTouched}
                    onMouseUp={setTouched}
                    onTouchEnd={setTouched}
                    onTouchCancel={setTouched}
                >
                    <TruckContent ref={props.ref} valueArray={valueArray} min={min} max={max}>
                        {children}
                    </TruckContent>
                </SliderTruck>
            )}
            renderThumb={({ props }) => <SliderThumb {...props} style={{ ...props.style }} />}
        />
    );
};

export default Slider;
