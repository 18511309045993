import type MainColorsPaletteDefinition from './colors/MainColorsPaletteDefinition';
import { PaletteType } from './PaletteType';
import { themeConfiguration } from './themeConfiguration';
import type { ThemeDefinition } from './ThemeDefinition';

/**
 * Tonkean's theme manager
 */
export class Theme {
    /**
     * The currently selected palette
     */
    public static currentPaletteType: PaletteType = PaletteType.LIGHT;

    /**
     * The theme with the selected palette
     */
    public static current = Theme.getTheme();
    public static colors: MainColorsPaletteDefinition = Theme.current.palette.mainColors;
    public static sizes: ThemeDefinition['sizes'] = Theme.current.sizes;

    /**
     * Sets a new palette as selected
     * @param palette - the palette to be set
     */
    public static setPalette(palette: PaletteType) {
        this.currentPaletteType = palette;
        this.current = Theme.getTheme();
        this.colors = Theme.current.palette.mainColors;
        this.sizes = Theme.current.sizes;
    }

    /**
     * Generates a new theme object, based on `themeConfiguration`,
     * but with the selected palette configuration and without the map
     *
     * @returns the theme, with the selected palette definition in `palette`.
     */
    private static getTheme() {
        return {
            ...themeConfiguration,
            palette: themeConfiguration.palette[Theme.currentPaletteType],
        };
    }
}
