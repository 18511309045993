import FormulaPopoverTab from './FormulaPopoverTab';

interface FormulaTabConfiguration {
    hasSearch: boolean;
    searchItemLabel?: string;
}

const formulaTabConfiguration: Record<FormulaPopoverTab, FormulaTabConfiguration> = {
    [FormulaPopoverTab.FORMULA]: {
        hasSearch: true,
        searchItemLabel: 'formula',
    },
    [FormulaPopoverTab.VARIABLE]: {
        hasSearch: true,
        searchItemLabel: 'variable',
    },
    [FormulaPopoverTab.CONST]: {
        hasSearch: false,
    },
    [FormulaPopoverTab.PRESET]: {
        hasSearch: true,
        searchItemLabel: 'preset',
    },
};

export default formulaTabConfiguration;
