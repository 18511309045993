import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { debouncer } from '@tonkean/utils';

/**
 * Hook to handle internal state and emit with debounce when it changes.
 *
 * @example
 * function SomeComp() {
 *   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
 *   const [searchTerm, setSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);
 * }
 *
 * @param currentExternalValue - the current external value.
 * @param updateExternalValue - a callback function to update the external value.
 * @param debounceInterval - the debounce interval, in milliseconds.
 * @returns the same as a useState - array with the current internal value and a setter for the new internal value.
 */
function useDebouncedState<T extends Record<string, any> | number | string | boolean | undefined>(
    currentExternalValue: T,
    updateExternalValue: React.Dispatch<React.SetStateAction<T>> | ((newValue: T) => void),
    debounceInterval: number = 200,
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [internalValue, setInternalValue] = useState(currentExternalValue);

    const searchTermDebounce = useMemo(() => {
        return debouncer(debounceInterval);
    }, [debounceInterval]);

    const updateExternalValueRef = useRef(updateExternalValue);
    updateExternalValueRef.current = updateExternalValue;

    /**
     * Function to update when the internal value changes. If requested, it will emit with debounce a change event.
     *
     * @param newValueAction - the new value, or a function that accepts the current value as param and returns the new
     * value (just like setState).
     * @param shouldEmitChange - should we call `updateExternalValue` to update the external value with the new
     * internal value?
     */
    const updateValue = useCallback(
        (newValueAction: React.SetStateAction<T>, shouldEmitChange: boolean = true) => {
            setInternalValue((currentValue) => {
                const newValue = typeof newValueAction === 'function' ? newValueAction(currentValue) : newValueAction;

                // We are adding a callback to the debounce even if should emit change is false to make sure we are not
                // emitting an old value.
                searchTermDebounce(() => {
                    if (shouldEmitChange) {
                        updateExternalValueRef.current(newValue);
                    }
                });

                return newValue;
            });
        },
        [searchTermDebounce],
    );

    /**
     * Monitor changes in the external value, and update the internal when it changes.
     */
    useEffect(() => {
        updateValue(currentExternalValue, false);
    }, [currentExternalValue, updateValue]);

    // todo: trigger on unmount

    return [internalValue, updateValue as React.Dispatch<React.SetStateAction<T>>];
}

export default useDebouncedState;
