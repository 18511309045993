import React from 'react';
import { components } from 'react-select';
import type { SingleValueProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled, { css } from 'styled-components';

import { H1, H4 } from '../TUI';

import type { PersonSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const GuestIndication = styled.div`
    color: ${Theme.colors.guest_text};
    background-color: ${Theme.colors.guest_background};
    border: 1px solid ${Theme.colors.guest_border};
    border-radius: 3px;
    padding: 3px 5px 3px 5px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.06em;
    min-width: 40px;
    flex: 0 0 auto;
`;

const PersonAvatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 62px;
`;

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${Theme.colors.gray_200};
    padding: 2px 6px 2px 4px;
    gap: 6px;
    border-radius: 20px;
    max-width: max-content;
`;

const PersonTitleContainer = styled.div`
    gap: 4px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
`;

const BaseTitleStyle = css<{ $isSingle: boolean }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    padding-right: ${({ $isSingle }) => ($isSingle ? 4 : 0)}px;
`;

const Title = styled(H4)<{ $isSingle: boolean }>`
    ${BaseTitleStyle}
`;

const BoldTitle = styled(H1)<{ $isSingle: boolean }>`
    ${BaseTitleStyle}
`;

const PersonValue = ({ person, bold }: { person: PersonSummary; isSingle?: boolean; bold?: boolean }) => {
    return (
        <MainContainer data-automation="people-selector-custom-value-main-container">
            <PersonAvatar src={person?.avatarUri} />
            <PersonTitleContainer data-automation="people-selector-custom-value-title-container">
                {bold ? (
                    <BoldTitle
                        $color={Theme.colors.gray_800}
                        data-automation="people-selector-custom-value-title"
                        $isSingle
                    >
                        {person?.name}
                    </BoldTitle>
                ) : (
                    <Title
                        $color={Theme.colors.gray_800}
                        data-automation="people-selector-custom-value-title"
                        $isSingle
                    >
                        {person?.name}
                    </Title>
                )}
                {person?.isExternal && (
                    <GuestIndication data-automation="people-selector-custom-value-guest-indication">
                        Guest
                    </GuestIndication>
                )}
            </PersonTitleContainer>
        </MainContainer>
    );
};

const CustomSingleValue: React.FC<SingleValueProps<PersonSummary>> = (props) => {
    const person: PersonSummary = props.data;
    const { bold } = props.selectProps as { bold?: boolean };
    return (
        <components.SingleValue<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
            <PersonValue person={person} bold={bold} isSingle />
        </components.SingleValue>
    );
};

const CustomMultiValueLabel: React.FC<SingleValueProps<PersonSummary>> = (props) => {
    const person = props.data;
    const { bold } = props.selectProps as { bold?: boolean };
    return (
        <components.MultiValueLabel<PersonSummary, boolean, GroupBase<PersonSummary>> {...props}>
            <PersonValue person={person} bold={bold} />
        </components.MultiValueLabel>
    );
};

export { CustomSingleValue, CustomMultiValueLabel };
