enum EntityResponseHandlingDefinitionType {
    MODULE_OUTPUT_FIELDS = 'MODULE_OUTPUT_FIELDS',
    DATA_SOURCE_EXTERNAL_ACTIVITIES = 'DATA_SOURCE_EXTERNAL_ACTIVITIES',
    DATA_SOURCE_EXTERNAL_ACTIVITIES_IDS = 'DATA_SOURCE_EXTERNAL_ACTIVITIES_IDS',
    METADATA_FIELDS = 'METADATA_FIELDS',
    ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT = 'ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT',
    UPLOAD_FILE_INFORMATION = 'UPLOAD_FILE_INFORMATION',
}

export default EntityResponseHandlingDefinitionType;
