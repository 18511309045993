enum EnterpriseComponentVariableValueType {
    TEXT = 'TEXT',
    INTERNAL_SECRET_TEXT = 'INTERNAL_SECRET_TEXT',
}

export const EnterpriseComponentVariableValueTypeToDisplayName = {
    [EnterpriseComponentVariableValueType.TEXT]: 'Text',
    [EnterpriseComponentVariableValueType.INTERNAL_SECRET_TEXT]: 'Internal secret text',
};

export const ValueTypeOptions = Object.keys(EnterpriseComponentVariableValueTypeToDisplayName).map((key) => {
    return { label: EnterpriseComponentVariableValueTypeToDisplayName[key], value: key };
});

export default EnterpriseComponentVariableValueType;
