enum ButtonSize {
    SMALL = 'SMALL',
    MEDIUM_SNUG = 'MEDIUM_SNUG',
    MEDIUM = 'MEDIUM',
    MEDIUM_BIG = 'MEDIUM_BIG',
    XMEDIUM_BIG = 'XMEDIUM_BIG',
    BIG = 'BIG',
    HUGE = 'HUGE',
}

export default ButtonSize;
