import React, { useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentVariableRow from './EnterpriseComponentVariableRow';
import { useProject } from '../../hooks';
import useEnterpriseComponentVariableValues from '../../hooks/useEnterpriseComponentVariableValues';
import { SimpleErrorStateMessage } from '../StateMessages';
import { H4, Placeholder } from '../TUI';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import type {
    EnterpriseComponentId,
    EnterpriseComponentVariableRelatedEntityId,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { range } from '@tonkean/utils';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 70px;
    grid-gap: 16px;
    align-items: center;
    padding-top: 8px;
`;

const EmptyStateMessage = styled.div``;

interface Props {
    enterpriseComponentId: EnterpriseComponentId;
    relatedEntityId: EnterpriseComponentVariableRelatedEntityId;
    onSaveRef: React.RefObject<() => Promise<boolean>>;
    hideHeader?: boolean;
}

const EnterpriseComponentVariables: React.FC<Props> = ({
    enterpriseComponentId,
    relatedEntityId,
    onSaveRef,
    hideHeader = false,
}) => {
    const emitToast = useToastMessage();
    const { id: projectId } = useProject();

    const { loading, variables, variablesValues, error } = useEnterpriseComponentVariableValues(
        projectId,
        enterpriseComponentId,
        relatedEntityId,
    );

    // Lazy call to update the enterprise component variables.
    const [, updateEnterpriseComponentVariablesValues] = useLazyTonkeanService(
        'updateEnterpriseComponentVariablesValues',
    );

    // current state of all the updated parameters.
    const [updatedValuesMap, setUpdatedValuesMap] = useState<
        Record<TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE_VALUE>, string>
    >({});

    // Saving the changed parameters
    const onSave = useConstantRefCallback(async () => {
        try {
            await updateEnterpriseComponentVariablesValues(projectId, enterpriseComponentId, updatedValuesMap);
            return true;
        } catch {
            emitToast('Unable to update enterprise component variables', 'danger');
            return false;
        }
    });

    const sortedVariables = useMemo(() => {
        return variables?.entities.sort((variableA, variableB) =>
            (variableA?.index ?? 0) > (variableB?.index ?? 0) ? 1 : -1,
        );
    }, [variables?.entities]);

    useImperativeHandle(onSaveRef, () => onSave, [onSave]);

    if (error) {
        return <SimpleErrorStateMessage error={error} showSmallError />;
    }

    return (
        <Wrapper>
            {/* Table Headers */}
            {!hideHeader && (loading || !!variables?.entities?.length) && (
                <>
                    <H4 $bold>Parameter Name</H4>
                    <H4 $bold>Parameter Value</H4>
                </>
            )}

            {/* Loading State */}
            {loading && range(6).map((index) => <Placeholder key={index} $height="26px" $width="auto" $circle />)}

            {/* Empty State */}
            {variables && variables.entities.length === 0 && (
                <EmptyStateMessage>No variables created yet.</EmptyStateMessage>
            )}

            {/* Table columns */}
            {variables &&
                variablesValues &&
                sortedVariables?.map((variable) => (
                    <React.Fragment key={variable.id}>
                        <EnterpriseComponentVariableRow
                            enterpriseComponentVariable={variable}
                            value={
                                updatedValuesMap[variable.id] === undefined
                                    ? variablesValues.enterpriseComponentIdToVariableValue[variable.id] || ''
                                    : updatedValuesMap[variable.id]
                            }
                            onUpdate={(newValue) => {
                                setUpdatedValuesMap((currValuesMap) => ({
                                    ...currValuesMap,
                                    [variable.id]: newValue,
                                }));
                            }}
                            isSafeEdit={variable.isEncrypted}
                        />
                    </React.Fragment>
                ))}
        </Wrapper>
    );
};

export default EnterpriseComponentVariables;
