import React, { useContext } from 'react';
import styled from 'styled-components';

import ButtonToggleGroupContext from './ButtonToggleGroupContext';

import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledButton = styled(Button)`
    min-width: auto;
    border-radius: 4px;

    &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

interface Props extends StyledComponentsSupportProps {
    /**
     * Currently only supports uniquely stringable values (strings, numbers etc)
     */
    value: any;
}

const ButtonToggleGroupButton: React.ForwardRefRenderFunction<HTMLButtonElement, React.PropsWithChildren<Props>> = (
    { className, value, children, ...props },
    ref,
) => {
    const { toggleValue, valuesState } = useContext(ButtonToggleGroupContext);
    const checked = valuesState[value]?.checked;
    return (
        <StyledButton
            ref={ref}
            onClick={() => toggleValue(value)}
            className={className}
            cancel={!checked}
            highlighted={checked}
            size={ButtonSize.MEDIUM}
            {...props}
        >
            {children}
        </StyledButton>
    );
};

export default React.forwardRef(ButtonToggleGroupButton);
