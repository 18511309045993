import React from 'react';
import styled from 'styled-components';

import ErrorMessage from '../TUI/Message/ErrorMessage';

import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div`
    flex-grow: 1;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    z-index: 0;
`;

const Error = styled(ErrorMessage)`
    width: 100%;
    margin-bottom: 100px;
`;

const Icon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    icon: JSX.Element;
}

const ErrorStateMessage: React.FC<React.PropsWithChildren<Props>> = ({ children, className, dataAutomation, icon }) => {
    return (
        <Wrapper data-automation={dataAutomation} className={className}>
            <Error>{children}</Error>

            <Icon>{icon}</Icon>
        </Wrapper>
    );
};

export default ErrorStateMessage;
