import React, { useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const ResizeObserverContext = React.createContext<
    (node: Element, callback: (entry: ResizeObserverEntry) => void) => () => void
>({} as any);

const ResizeObserverManager: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [observed, setObserved] = useState<Map<Element, ((entry: ResizeObserverEntry) => void)[]>>(new Map());
    const [resizeObserver, setResizeObserver] = useState<ResizeObserver>();

    const observedRef = useRef<Map<Element, ((entry: ResizeObserverEntry) => void)[]>>(new Map());
    observedRef.current = observed;

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setTimeout(() => {
                    observedRef.current.get(entry.target)?.forEach((callback) => callback(entry));
                });
            });
        });

        setResizeObserver(resizeObserver);

        return () => {
            resizeObserver.disconnect();
            setResizeObserver(undefined);
        };
    }, []);

    const addObserved = useCallback(
        (node: Element, callback: (entry: ResizeObserverEntry) => void): (() => void) => {
            setObserved((observed) => {
                const newMap = new Map(observed);
                newMap.set(node, [...(newMap.get(node) || []), callback]);
                return newMap;
            });
            resizeObserver?.observe(node);

            return () => {
                setObserved((observed) => {
                    const newMap = new Map(observed);
                    const callbacks = newMap.get(node)?.filter((innerCallback) => innerCallback !== callback);
                    newMap.set(node, callbacks || []);

                    return newMap;
                });
                resizeObserver?.unobserve(node);
            };
        },
        [resizeObserver],
    );

    return <ResizeObserverContext.Provider value={addObserved}>{children}</ResizeObserverContext.Provider>;
};

export default ResizeObserverManager;
