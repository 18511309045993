import { useMemo, useRef, useState } from 'react';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

export enum ADD_DIRECTION {
    START,
    END,
}

export enum LIST_CHANGE_TYPE {
    DELETED,
    CREATED,
    UPDATED,
}

const EMPTY_ARRAY = [];
export type PartialListItem<T> = Partial<Omit<T, 'id'>>;
type PartialItemFunc<T> = PartialListItem<T> | ((current: T) => PartialListItem<T>);

export type ModifiableListReturnType<T> = [
    modifiedItems: T[],
    modifyingFuncs: {
        onCreate(item: T, direction?: ADD_DIRECTION): void;
        onCreateAt(item: T, index: number): void;
        onUpdate(itemId: string, partialItemFunc: PartialItemFunc<T>): void;
        onDelete(itemId: string): void;
        onRestoreDeleted(itemId: string): void;
        reset(): void;
    },
];

function useModifiableList<T extends { id: string }>(items: T[] | undefined): ModifiableListReturnType<T>;
function useModifiableList<T>(items: T[] | undefined, getId: (item: T) => string): ModifiableListReturnType<T>;

function useModifiableList<T>(
    items: T[] = EMPTY_ARRAY,
    getId: (item: T) => string = (item) => item['id'],
): ModifiableListReturnType<T> {
    const [deletedItemIds, setDeletedItemIds] = useState<string[]>([]);
    const [createdPrefixItems, setCreatedPrefixItems] = useState<T[]>([]);
    const [createdPostfixItems, setCreatedPostfixItems] = useState<T[]>([]);
    const [itemIdToPartialUpdatedItem, setItemIdToPartialUpdatedItem] = useState<
        Record<string, Partial<Omit<T, 'id'>>>
    >({});

    const getIdConstantRef = useConstantRefCallback(getId);
    const modifiedItems = useMemo(() => {
        return [...createdPrefixItems, ...items, ...createdPostfixItems]
            .filter((item) => !deletedItemIds.includes(getIdConstantRef(item)))
            .map((item) => {
                if (itemIdToPartialUpdatedItem[getIdConstantRef(item)]) {
                    return { ...item, ...itemIdToPartialUpdatedItem[getIdConstantRef(item)] };
                }
                return item;
            });
    }, [createdPrefixItems, createdPostfixItems, deletedItemIds, getIdConstantRef, itemIdToPartialUpdatedItem, items]);

    const modifiedItemsRef = useRef(modifiedItems);
    modifiedItemsRef.current = modifiedItems;

    const reset = useConstantRefCallback((typesToReset?: LIST_CHANGE_TYPE[]) => {
        if (!typesToReset) {
            setDeletedItemIds([]);
            setCreatedPrefixItems([]);
            setCreatedPostfixItems([]);
            setItemIdToPartialUpdatedItem({});
        } else {
            if (typesToReset.includes(LIST_CHANGE_TYPE.DELETED)) {
                setDeletedItemIds([]);
            }
            if (typesToReset.includes(LIST_CHANGE_TYPE.CREATED)) {
                setCreatedPrefixItems([]);
                setCreatedPostfixItems([]);
            }
            if (typesToReset.includes(LIST_CHANGE_TYPE.DELETED)) {
                setItemIdToPartialUpdatedItem({});
            }
        }
    });

    const onDelete = useConstantRefCallback((itemId: string) => {
        setDeletedItemIds((currentDeletedItemIds) => [...currentDeletedItemIds, itemId]);
    });

    const onRestoreDeleted = useConstantRefCallback((itemId: string) => {
        setDeletedItemIds((currentDeletedItemIds) =>
            currentDeletedItemIds.filter((deletedItemId) => deletedItemId !== itemId),
        );
    });

    const onCreate = useConstantRefCallback((item: T, direction: ADD_DIRECTION = ADD_DIRECTION.START) => {
        if (direction === ADD_DIRECTION.START) {
            setCreatedPrefixItems((currentCreatedItems) => [item, ...currentCreatedItems]);
        } else {
            setCreatedPostfixItems((currentCreatedItems) => [...currentCreatedItems, item]);
        }
    });

    const onCreateAt = useConstantRefCallback((item: T, index: number) => {
        setCreatedPrefixItems((currentCreatedItems) => {
            return [...currentCreatedItems.slice(0, index), item, ...currentCreatedItems.slice(index)];
        });
    });

    const onUpdate = useConstantRefCallback((itemId: string, partialItemFunc: PartialItemFunc<T>) => {
        setItemIdToPartialUpdatedItem((currentItemIdToPartialUpdatedItem) => {
            const currentItem = modifiedItemsRef.current.find((item) => getIdConstantRef(item) === itemId);
            if (!currentItem) {
                return currentItemIdToPartialUpdatedItem;
            }

            const partialItem = typeof partialItemFunc === 'function' ? partialItemFunc(currentItem) : partialItemFunc;

            if ('id' in partialItem) {
                // if the id has changed we need to delete the existing item and to create a new one
                console.error(
                    "you are attempting to update item's id, which doesn't work as expected. It is recommended to use onDelete and onCreate instead.",
                );
            }

            return {
                ...currentItemIdToPartialUpdatedItem,
                [itemId]: {
                    ...currentItemIdToPartialUpdatedItem[itemId],
                    ...partialItem,
                },
            };
        });
    });

    return [modifiedItems, { onCreate, onCreateAt, onUpdate, onDelete, onRestoreDeleted, reset }];
}

export default useModifiableList;
