import { useCallback, useRef } from 'react';

function useConstantRefCallback<T extends (...args: any[]) => any>(callback?: T): T {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const constantRefCallback = useCallback((...args: Parameters<T>) => callbackRef.current?.(...args), []);

    return constantRefCallback as T;
}

export default useConstantRefCallback;
