import isPromise from 'is-promise';
import React, { useState } from 'react';
import styled from 'styled-components';

import DragAndDropFiles from './DragAndDropFiles';
import type DragAndDropModalProps from './DragAndDropModalProps';
import DragAndDropValidations from './DragAndDropValidations';
import ImportFileFromPathView from './ImportFileFromPathView';
import { ReactComponent as ImportFileIcon } from '../../../../images/import-file.svg';
import XCloseButton from '../CloseButton/XCloseButton';
import useCloseCallback from '../CloseCallback/useCloseCallback';
import { ModalFooter, ModalHeader } from '../Modal';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 12px;
    top: 12px;
`;

const CancelButton = styled(Button)`
    margin-right: 5px;
`;

const ModalBody = styled.div`
    margin: auto;
`;
const DragDropFooter = styled(ModalFooter)`
    padding-top: 10px;
`;

const ImportFileFromPathViewContainer = styled.div`
    margin-top: 17px;
`;

const ModalText = styled.h2`
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: ${Theme.colors.gray_800};
`;

interface Props extends DragAndDropModalProps {
    onLoadingChanged(loading: boolean): void;
    isModalMountedRef: React.RefObject<boolean>;
}

const DragAndDropModalContent: React.FC<Props> = ({
    modalHeader = 'Upload File',
    dragAndDropMainText = 'Drag file here to upload',
    dragAndDropLogo = <ImportFileIcon />,
    isImageUpload = false,
    acceptedFileTypes = isImageUpload ? ['image/jpeg', 'image/png'] : undefined,
    maxFileSizeMB = 5,
    maximumFiles,
    onDragged,
    shouldAllowUploadFromUrl = isImageUpload,
    onSave: onSaveProp,
    loading,
    onLoadingChanged,
    isModalMountedRef,
    limitationsDescriptionText,
}) => {
    const [existingFiles, setExistingFiles] = useState<TonkeanUploadedFile[]>([]);
    const [uploadErrorText, setUploadErrorText] = useState<string>();

    const onClose = useCloseCallback();

    const onSave = () => {
        if (!existingFiles.length) {
            return;
        }

        const result = onSaveProp(existingFiles as [TonkeanUploadedFile, ...TonkeanUploadedFile[]]);

        // If on save returned a promise, we will show loading spinner until it resolves, then trigger an onClose.
        if (isPromise(result)) {
            onLoadingChanged(true);
            result
                .then(onClose)
                .catch(() => {})
                .finally(() => {
                    if (isModalMountedRef.current) {
                        onLoadingChanged(false);
                    }
                });
        }
    };

    const onBtnCloseClicked = () => {
        onClose();
        setExistingFiles([]);
    };

    return (
        <>
            <ModalHeader $border={false}>
                <ModalText>{modalHeader}</ModalText>
                <ModalCloseButton size={ButtonSize.MEDIUM} onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <DragAndDropFiles
                    acceptedFileTypes={acceptedFileTypes}
                    mainText={dragAndDropMainText}
                    dragAndDropLogo={dragAndDropLogo}
                    maximumFiles={maximumFiles}
                    onDragged={onDragged}
                    existingFiles={existingFiles}
                    setExistingFiles={setExistingFiles}
                    isImageUpload={isImageUpload}
                    setErrorText={setUploadErrorText}
                    maxFileSizeMB={maxFileSizeMB}
                    isInPreview={loading}
                    limitationsDescriptionText={limitationsDescriptionText}
                />

                {shouldAllowUploadFromUrl && (
                    <ImportFileFromPathViewContainer>
                        <ImportFileFromPathView
                            isDisabled={existingFiles.length === maximumFiles}
                            acceptedFileTypes={acceptedFileTypes}
                            existingFiles={existingFiles}
                            onAdding={(dragAndDropFile) => {
                                setExistingFiles(
                                    DragAndDropValidations.onFilesAddedGetNewFiles(
                                        existingFiles,
                                        [dragAndDropFile],
                                        maximumFiles,
                                        maxFileSizeMB,
                                    ).files,
                                );
                            }}
                        />
                    </ImportFileFromPathViewContainer>
                )}
            </ModalBody>

            <DragDropFooter align="right" gap={8}>
                {uploadErrorText && <div className="common-error flex-grow">{uploadErrorText}</div>}
                {loading && <div className="loading" />}

                <CancelButton disabled={loading} onClick={onBtnCloseClicked} cancel>
                    Cancel
                </CancelButton>
                <Button disabled={loading || existingFiles.length === 0} onClick={onSave}>
                    Save
                </Button>
            </DragDropFooter>
        </>
    );
};

export default DragAndDropModalContent;
