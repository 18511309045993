import type PopperJS from '@popperjs/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import TooltipElement from './TooltipElement';
import useUUID from '../../../hooks/useUUID';
import Checkbox from '../Checkbox/Checkbox';
import type { PopperChildrenRefProps } from '../Popper';
import Popper from '../Popper';
import Radio from '../RadioButton/Radio';

import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const componentToTooltipRefPropMap: Map<React.ComponentType, string> = new Map([
    [Radio, 'tooltipRef'],
    [Checkbox, 'tooltipRef'],
]);

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    /** The content of the tooltip. */
    content: React.ReactNode;
    /** Where to place the tooltip in relative to the reference element. */
    placement?: PopperJS.Placement;
    /** Should the tooltip have an arrow? */
    showArrow?: boolean;
    /** Popover's size */
    color?: TooltipColor;
    /** Popover's size */
    size?: TooltipSize;
    /** Should the text be aligned to the left? */
    textAlignLeft?: boolean;
    /** Disable the tooltip */
    disabled?: boolean;
    /** If true the width of the tooltip will be limited to 40vw */
    limitWidth?: number;
}

const Tooltip: React.FC<React.PropsWithChildren<Props & PopperChildrenRefProps>> = ({
    children: childrenProp,
    content,
    placement = 'top',
    showArrow = true,
    textAlignLeft = false,
    size = TooltipSize.SMALL,
    color = TooltipColor.DARK,
    className,
    disabled = false,
    nodeRef,
    limitWidth,
    dataAutomation,
}) => {
    // When set to false, it start to fade out, and emit onExited when complete
    const [tooltipShown, setTooltipShown] = useState(false);
    // This will be set to false when onExited completes
    const [popperShown, setPopperShown] = useState(false);

    // Will contain the element ref of the children, if node ref is not supplied.
    const elementRef = useRef<HTMLElement>(null);

    const tooltipId = useUUID();

    useEffect(() => {
        const element = nodeRef ? nodeRef.current : elementRef.current;
        if (!element) {
            return;
        }

        const showTooltip = () => {
            element.setAttribute('aria-describedby', tooltipId);
            setTooltipShown(true);
        };
        const hideTooltip = () => {
            element.removeAttribute('aria-describedby');
            setTooltipShown(false);
        };

        element.addEventListener('mouseenter', showTooltip);
        element.addEventListener('mouseover', showTooltip);
        element.addEventListener('mouseleave', hideTooltip);
        element.addEventListener('focus', showTooltip);
        element.addEventListener('blur', hideTooltip);

        return () => {
            element.removeEventListener('mouseenter', showTooltip);
            element.removeEventListener('mouseover', showTooltip);
            element.removeEventListener('mouseleave', hideTooltip);
            element.removeEventListener('focus', showTooltip);
            element.removeEventListener('blur', hideTooltip);
        };
    }, [nodeRef, tooltipId]);

    useEffect(() => {
        if (tooltipShown) {
            setPopperShown(true);
        }
    }, [tooltipShown]);

    const onExited = useCallback(() => {
        setPopperShown(false);
    }, []);

    const popperCreator = useCallback(
        (setPopperElement, popperStyles, popperAttributes, setArrowElement, arrowStyles) => (
            <TooltipElement
                role="tooltip"
                id={tooltipId}
                className={className}
                shown={tooltipShown}
                onExited={onExited}
                popperStyles={popperStyles}
                popperAttributes={popperAttributes}
                showArrow={showArrow}
                color={color}
                size={size}
                textAlignLeft={textAlignLeft}
                arrowStyles={arrowStyles}
                setArrowElement={setArrowElement}
                limitWidth={limitWidth}
                ref={setPopperElement}
                data-automation={dataAutomation}
            >
                {content}
            </TooltipElement>
        ),
        [
            className,
            content,
            dataAutomation,
            limitWidth,
            onExited,
            showArrow,
            size,
            textAlignLeft,
            tooltipId,
            tooltipShown,
            color,
        ],
    );

    const defaultTabIndex = disabled ? undefined : 0;

    const children = !nodeRef && (childrenProp as React.ReactComponentElement<any>);
    const modifiedChildren = children
        ? React.cloneElement(children, {
              tabIndex: children.props.tabIndex ?? defaultTabIndex,
              ...(!nodeRef ? { [componentToTooltipRefPropMap.get(children.type) ?? 'ref']: elementRef } : {}),
          })
        : childrenProp;

    return (
        <Popper
            shown={!disabled && popperShown}
            placement={placement}
            popper={popperCreator}
            nodeRef={nodeRef || elementRef}
        >
            {modifiedChildren}
        </Popper>
    );
};

export default Tooltip;
