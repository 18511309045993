enum CollectStatusType {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    REQUEST_SENT = 'REQUEST_SENT',
    IN_PROGRESS = 'IN_PROGRESS',
    STOPPED = 'STOPPED',
    SUCCESSFULLY_EXECUTED = 'SUCCESSFULLY_EXECUTED',
    ERROR_OCCURRED = 'ERROR_OCCURRED',
    INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
    MAX_PAGES_REACHED = 'MAX_PAGES_REACHED',
    SUB_ENTITY_COLLECT_NOT_CONFIGURED = 'SUB_ENTITY_COLLECT_NOT_CONFIGURED',
}

export default CollectStatusType;
