import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import TextEllipsis from '../TextEllipsis';
import type { SimpleSelectSingleOption } from '../TUI';
import { DragAndDropFiles, Modal, ModalBody, ModalFooterActions, ModalHeader, ModalSize, SimpleSelect } from '../TUI';

import { ImportFileIcon as ImportFileIcon } from '@tonkean/svg';
import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const FieldsDefinitionMappings = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-items: stretch;
    align-items: center;
`;

const DragAndDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const MappingHeader = styled.span<{ column }>`
    grid-row: 1;
    grid-column: ${({ column }) => column};
`;

const CSVHeader = styled.div<{ row }>`
    grid-column: 1;
    grid-row: ${({ row }) => row};
`;

const FieldSelect = styled(SimpleSelect)<{ row }>`
    grid-column: 2;
    grid-row: ${({ row }) => row};
`;

const ErrorText = styled.span`
    color: ${Theme.colors.error};
`;

type ImportStage = 'uploadFile' | 'mapping' | 'importing' | 'imported';

interface Props {
    isOpen: boolean;
    toggleOpen(): void;
    formName: string;
    fieldIdsToFieldNames: Record<string, string>;
    onMappingsCreated(newInitiatives: Record<string, string>[]): Promise<number>;
    onExited(): void;
}

const ImportInnerItemsFromCSVModal: React.FC<Props> = ({
    isOpen,
    toggleOpen,
    formName,
    fieldIdsToFieldNames,
    onMappingsCreated,
    onExited,
}) => {
    const csvHelper = useAngularService('csvHelper');
    const [csvColumns, setCsvColumns] = useState<string[]>([]);
    const [mappings, setMappings] = useState<Record<string, number>>({});
    const [files, setFiles] = useState<TonkeanUploadedFile[]>([]);
    const [uploadError, setUploadError] = useState<string>('');
    const [csvRows, setCsvRows] = useState<string[][]>([]);
    const [importStage, setImportStage] = useState<ImportStage>('uploadFile');
    const [createdInitiativesCount, setCreatedInitiativesCount] = useState<number>(0);

    const onFilesSelected = useCallback(
        (uploadedFiles: TonkeanUploadedFile[]) => {
            setFiles(uploadedFiles);
            setImportStage('mapping');
            if (uploadedFiles.length === 0) {
                setUploadError('No file uploaded');
                return;
            }

            if (uploadedFiles.length > 1) {
                setUploadError('You can only upload a single file');
                return;
            }

            csvHelper.parseFile(
                uploadedFiles[0]?.blob,
                (table) => {
                    const actualRows = table.filter((row) => !utils.isNullOrEmpty(row) && typeof row === typeof []);

                    if (actualRows.length === 0) {
                        setUploadError('csv is empty');
                        return;
                    }

                    setCsvColumns(table[0]);
                    setCsvRows(table.slice(1));
                },
                (error) => setUploadError(`Can't parse file: ${error}`),
            );
        },
        [csvHelper],
    );

    const csvColumnsOptions: SimpleSelectSingleOption<number>[] = useMemo(
        () =>
            csvColumns
                // map before filter to keep the index order
                .map((column, index) => ({
                    value: index,
                    label: column,
                })),
        [csvColumns],
    );

    const selectMappings = useCallback(
        (fieldId, selectedCsvHeaderIndex) =>
            setMappings((oldMappings) => ({ ...oldMappings, [fieldId]: selectedCsvHeaderIndex })),
        [],
    );

    const importCSV = useCallback(() => {
        setImportStage('importing');

        const initiativesData = csvRows.map((row) => {
            return Object.fromEntries(
                Object.entries(mappings)
                    .filter(([, csvHeaderIndex]) => !utils.isNullOrEmpty(csvHeaderIndex) && row[csvHeaderIndex])
                    .map(([fieldId, csvHeaderIndex]) => [fieldId, row[csvHeaderIndex] as string]),
            );
        });
        onMappingsCreated(initiativesData).then((initiativesCount) => {
            setCreatedInitiativesCount(initiativesCount);
            setImportStage('imported');
        });
    }, [csvRows, mappings, onMappingsCreated, setImportStage]);

    const done = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    return (
        <Modal
            open={isOpen}
            onClose={toggleOpen}
            onExited={onExited}
            size={ModalSize.MEDIUM}
            backdropCloses={false}
            fixedWidth
        >
            <ModalHeader>Import Items to {formName}</ModalHeader>

            <ModalBody>
                {importStage === 'uploadFile' && (
                    <DragAndDropWrapper>
                        <DragAndDropFiles
                            mainText="Drag CSV here to upload"
                            dragAndDropLogo={<ImportFileIcon />}
                            isImageUpload={false}
                            existingFiles={files}
                            setExistingFiles={onFilesSelected}
                            maximumFiles={1}
                            setErrorText={(err) => setUploadError(err || '')}
                            maxFileSizeMB={1000}
                            acceptedFileTypes="text/csv"
                        />
                        {uploadError && <ErrorText>{uploadError}</ErrorText>}
                    </DragAndDropWrapper>
                )}

                {['mapping', 'importing'].includes(importStage) && (
                    <FieldsDefinitionMappings>
                        <MappingHeader column={1}>Field Name</MappingHeader>
                        <MappingHeader column={2}>CSV Header</MappingHeader>
                        {Object.entries(fieldIdsToFieldNames).map(([fieldId, fieldName], index) => (
                            <React.Fragment key={fieldId}>
                                {/* Row index +2 to have room for header row*/}
                                <CSVHeader row={index + 2}>
                                    <TextEllipsis tooltip>
                                        <span>{fieldName}</span>
                                    </TextEllipsis>
                                </CSVHeader>
                                {/* Row index +2 to have room for header row*/}
                                <FieldSelect
                                    row={index + 2}
                                    options={csvColumnsOptions}
                                    value={
                                        Object.entries(mappings).find(([, mappingIndex]) => mappingIndex === index)?.[1]
                                    }
                                    onChange={(selectedCsvHeaderIndex) =>
                                        selectMappings(fieldId, selectedCsvHeaderIndex)
                                    }
                                    isDisabled={importStage === 'importing'}
                                    isClearable
                                    isSearchable
                                />
                            </React.Fragment>
                        ))}
                    </FieldsDefinitionMappings>
                )}

                {importStage === 'imported' && (
                    <div>
                        imported {createdInitiativesCount} of {csvRows.length} rows
                    </div>
                )}
            </ModalBody>

            <ModalFooterActions
                saveLabel={importStage === 'imported' ? 'Done' : 'Import'}
                onSave={importStage === 'imported' ? done : importCSV}
                saveDisabled={['uploadFiles', 'importing'].includes(importStage)}
                loading={importStage === 'importing'}
                showCancel={importStage !== 'imported'}
            />
        </Modal>
    );
};

export default ImportInnerItemsFromCSVModal;
