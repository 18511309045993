import type { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

interface Props {
    $shadow?: boolean;
    $border?: boolean;
    $flex?: boolean;
    $justifyContent?: CSSProperties['justifyContent'];
    $padding?: number;
}

const ModalHeader = styled.div<Props>`
    position: relative;
    z-index: 1;
    padding: ${({ $padding = 20 }) => `${$padding}px`};
    font-size: ${FontSize.XXLARGE_20};
    line-height: 22px;
    color: ${Theme.colors.gray_800};

    ${({ $shadow = false }) =>
        $shadow &&
        css`
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
        `}
    ${({ $shadow, $border = !$shadow }) =>
        $border &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_400};
        `}

    ${({ $flex }) =>
        $flex &&
        css`
            display: flex;
            align-items: center;
        `}

    justify-content: ${({ $justifyContent }) => $justifyContent}
`;

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
