import { useContext } from 'react';

import CloseCallbackContext from './CloseCallbackContext';

/**
 * @returns a callback function that when triggered will close the modal.
 */
function useCloseCallback() {
    return useContext(CloseCallbackContext);
}

export default useCloseCallback;
