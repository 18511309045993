import type { ForwardedRef } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import InvisibleInput from '../InvisibleInput';

import type { Color } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps } from '@tonkean/utils';

export const InputLabel = styled.label<{ $disabled?: boolean; $labelAfterFillSpace?: boolean }>`
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    display: ${({ $labelAfterFillSpace }) => ($labelAfterFillSpace ? 'flex' : 'inline-flex')};
    justify-content: flex-start;
    align-items: center;
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`;

type LabelProps = {
    $changeLabelColor: boolean;
    $checked: boolean;
    $size: InputSize;
    $side: 'right' | 'left';
    $boldLabel: boolean;
    $highlighted: boolean;
    $disabled?: boolean;
    $disabledColorOrOpacity?: Color | number;
    $fillSpace?: boolean;
};

const LabelText = styled.div<LabelProps>`
  ${({ $fillSpace }) =>
      $fillSpace
          ? css`
                flex-grow: 1;
            `
          : {}};

  transition: color 200ms;
  margin-${({ $side, $size }) =>
      `${$side === 'left' ? 'right' : 'left'}: ${
          $size === InputSize.XLARGE || $size === InputSize.LARGE || $size === InputSize.MEDIUM_LARGE ? 10 : 5
      }px`};
color: ${({ $changeLabelColor, $checked, $disabled, $disabledColorOrOpacity, $highlighted }) => {
    if ($disabled) {
        if ($disabledColorOrOpacity) {
            if (typeof $disabledColorOrOpacity === 'string') {
                return $disabledColorOrOpacity;
            }
        } else {
            return Theme.colors.gray_500;
        }
    }

    const color = $highlighted ? Theme.colors.primaryHighlight : Theme.colors.gray_800;

    return $checked || !$changeLabelColor ? color : Theme.colors.gray_700;
}};

  opacity: ${({ $disabled, $disabledColorOrOpacity }) => {
      if ($disabled && typeof $disabledColorOrOpacity === 'number') {
          return $disabledColorOrOpacity;
      }

      return 1;
  }};

  font-size: ${({ $size }) => {
      if ($size === InputSize.LARGE || $size === InputSize.MEDIUM_LARGE) {
          return FontSize.MEDIUM_14;
      }

      if ($size === InputSize.XLARGE) {
          return FontSize.XLARGE_18;
      }

      return FontSize.SMALL_12;
  }};
  line-height: normal;
  font-weight: ${({ $boldLabel }) => ($boldLabel ? 500 : 400)};
`;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>, DataAutomationSupportProps {
    changeLabelColor: boolean;
    checked?: boolean;
    size: InputSize;
    icon: React.ReactComponentElement<any>;
    labelBefore?: React.ReactNode;
    labelBeforeChecked?: boolean;
    labelAfter?: React.ReactNode;
    labelAfterChecked?: boolean;
    labelAfterFillSpace?: boolean;
    boldLabel?: boolean;
    indeterminate?: boolean;
    highlighted?: boolean;
    disabledLabelColorOrOpacity?: Color | number;
    tooltipRef?: ForwardedRef<HTMLLabelElement>;
}

const IconInputWithLabel: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        changeLabelColor,
        className,
        checked = false,
        size,
        children,
        icon,
        labelBefore,
        labelBeforeChecked,
        labelAfter,
        labelAfterChecked,
        labelAfterFillSpace,
        disabled,
        dataAutomation,
        boldLabel = true,
        indeterminate = false,
        highlighted = false,
        disabledLabelColorOrOpacity,
        tooltipRef,
        ...props
    },
    ref,
) => {
    const dataAutomationValue = dataAutomation || props['data-automation'];
    return (
        <InputLabel
            className={className}
            $disabled={disabled}
            data-automation={`icon-input-with-label-${dataAutomationValue}`}
            data-automation-label={labelAfterChecked}
            data-automation-checked={checked}
            $labelAfterFillSpace={labelAfterFillSpace}
            ref={tooltipRef}
        >
            {labelBefore && (
                <LabelText
                    $changeLabelColor={changeLabelColor}
                    $checked={labelBeforeChecked ?? checked}
                    $size={size}
                    $side="left"
                    $boldLabel={boldLabel}
                    $disabled={disabled}
                    $disabledColorOrOpacity={disabledLabelColorOrOpacity}
                    $highlighted={highlighted}
                    data-automation={`icon-input-with-label-${dataAutomationValue}-label`}
                >
                    {labelBefore}
                </LabelText>
            )}

            <InvisibleInput
                {...props}
                data-automation={dataAutomationValue}
                checked={checked}
                disabled={disabled}
                ref={ref}
                indeterminate={indeterminate}
            >
                {icon}
            </InvisibleInput>

            {labelAfter && (
                <LabelText
                    $changeLabelColor={changeLabelColor}
                    $checked={labelAfterChecked ?? checked}
                    $size={size}
                    $side="right"
                    $boldLabel={boldLabel}
                    $disabled={disabled}
                    $disabledColorOrOpacity={disabledLabelColorOrOpacity}
                    $fillSpace={labelAfterFillSpace}
                    $highlighted={highlighted}
                    data-automation={`icon-input-with-label-${dataAutomationValue}-label`}
                >
                    {labelAfter}
                </LabelText>
            )}
        </InputLabel>
    );
};

export default React.forwardRef(IconInputWithLabel);
