import InitiativeStatus from './InitiativeStatus';

const states: Record<
    Exclude<InitiativeStatus, InitiativeStatus.FUTURE | InitiativeStatus.ARCHIVE>,
    { type: InitiativeStatus; label: string }
> = {
    [InitiativeStatus.ACTIVE]: {
        type: InitiativeStatus.ACTIVE,
        label: 'Active',
    },
    [InitiativeStatus.WARN]: {
        type: InitiativeStatus.WARN,
        label: 'Warning',
    },
    [InitiativeStatus.ON_HOLD]: {
        type: InitiativeStatus.ON_HOLD,
        label: 'On hold',
    },
    [InitiativeStatus.DONE]: {
        type: InitiativeStatus.DONE,
        label: 'Done',
    },
    [InitiativeStatus.INTAKE]: {
        type: InitiativeStatus.INTAKE,
        label: 'Intake',
    },
};
export default states;
