import React from 'react';
import styled from 'styled-components';

import { Tooltip } from './Tooltip';
import { ReactComponent as XIcon } from '../../../images/x.svg';

import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const RemoveButton = styled(DisableableButton).attrs({ children: <XIcon /> })<{
    disabled?: boolean;
}>`
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;

    svg {
        width: 10px;
        height: 10px;
    }

    svg path {
        fill: ${({ disabled = false }) => (disabled ? Theme.colors.gray_400 : Theme.colors.gray_600)};
    }
`;

interface Props {
    noItemsMessage?: string;
    disabled?: boolean;
    onClick: () => void;
}

const RemoveItemButton: React.FC<Props> = ({ noItemsMessage, disabled = false, onClick }) => {
    return (
        <Tooltip content={noItemsMessage} disabled={!disabled}>
            <RemoveButton disabled={disabled} onClick={onClick} type="button" />
        </Tooltip>
    );
};

export default RemoveItemButton;
