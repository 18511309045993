import React, { useMemo, useState } from 'react';

import type { CreateDialogFunction, DialogContentType, DialogSettings } from './CreateDialogContext';
import CreateDialogContext from './CreateDialogContext';
import type { AlertDialog, BaseDialog, ConfirmDialog, InternalDialog } from './Dialog';
import { DialogType } from './Dialog';
import DialogsContext from './DialogsContext';

import { getDeferredPromise } from '@tonkean/utils';

/**
 * A helper function to get the title, content and setting values from an overloaded function's arguments.
 *
 * @param titleOrContent - the title or the content of the modal.
 * @param contentOrSettings - the content or the settings of the modal.
 * @param settingsOptional - the settings of the modal.
 * @returns the title, content and settings of the modal.
 */
function getParams<T extends BaseDialog>(
    titleOrContent: DialogContentType,
    contentOrSettings?: DialogContentType | DialogSettings<T>,
    settingsOptional?: DialogSettings<T>,
) {
    const contentOrSettingsIsContent =
        contentOrSettings && (typeof contentOrSettings === 'string' || React.isValidElement(contentOrSettings));

    const title = contentOrSettingsIsContent ? titleOrContent : undefined;
    const content = contentOrSettingsIsContent ? contentOrSettings : titleOrContent;
    const settings = (settingsOptional ||
        (!contentOrSettingsIsContent && contentOrSettings) ||
        {}) as DialogSettings<T>;

    return {
        title,
        content,
        settings,
    };
}

/**
 * Counter to create a unique id
 */
let dialogIdCounter = 0;

/**
 * A helper function for creating a dialog creation function.
 *
 * @param setDialogs - a set state of the dialogs list.
 * @param type - the type of the modal.
 */
function createCreateDialogFunction<T extends BaseDialog>(
    setDialogs: React.Dispatch<React.SetStateAction<InternalDialog[]>>,
    type: T['type'],
): CreateDialogFunction<T> {
    return (
        titleOrContent: DialogContentType,
        contentOrSettings?: DialogContentType | DialogSettings<T>,
        settings?: DialogSettings<T>,
    ) => {
        const config = getParams<T>(titleOrContent, contentOrSettings, settings);
        const { promise, resolve: callback } = getDeferredPromise<any>();

        dialogIdCounter += 1;
        const id = dialogIdCounter;
        const remove = () => {
            setDialogs((dialogs) => dialogs.filter((dialogFromList) => dialogFromList.id !== id));
        };

        const dialog: InternalDialog = {
            ...config,
            ...config.settings,
            type: type as any,
            id,
            remove,
            callback,
        };

        setDialogs((dialogs) => [...dialogs, dialog]);
        return promise;
    };
}

const DialogsManager: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [dialogs, setDialogs] = useState<InternalDialog[]>([]);

    const createDialogFunctions = useMemo(
        () => ({
            alert: createCreateDialogFunction<AlertDialog>(setDialogs, DialogType.ALERT),
            confirm: createCreateDialogFunction<ConfirmDialog>(setDialogs, DialogType.CONFIRM),
        }),
        [],
    );

    return (
        <DialogsContext.Provider value={dialogs}>
            <CreateDialogContext.Provider value={createDialogFunctions}>{children}</CreateDialogContext.Provider>
        </DialogsContext.Provider>
    );
};

export default DialogsManager;
