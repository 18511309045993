import type PopperJS from '@popperjs/core';
import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SearchTooltipIcon } from '../../../../images/search-tooltip.svg';
import Tooltip from '../Tooltip/Tooltip';

import { Theme } from '@tonkean/tui-theme';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const sizes = Theme.sizes.informationTooltip;

const TooltipWrapper = styled.div<{ $iconSize?: InformationIconSize }>`
    display: flex;

    ${({ $iconSize }) =>
        $iconSize &&
        css`
            width: ${sizes[$iconSize].width}px;

            svg {
                width: 100%;
                height: 100%;
            }
        `};
`;

interface Props extends StyledComponentsSupportProps {
    textAlignLeft?: boolean;
    placement?: PopperJS.Placement;
    iconSize?: InformationIconSize;
    tooltipLimitWidth?: number;
}

const InformationTooltip: React.FC<React.PropsWithChildren<Props>> = ({
    textAlignLeft,
    placement,
    children,
    className,
    iconSize = InformationIconSize.MEDIUM,
    tooltipLimitWidth,
}) => {
    return (
        <Tooltip content={children} textAlignLeft={textAlignLeft} placement={placement} limitWidth={tooltipLimitWidth}>
            <TooltipWrapper className={className} $iconSize={iconSize}>
                <SearchTooltipIcon />
            </TooltipWrapper>
        </Tooltip>
    );
};

export default InformationTooltip;
