import styled from 'styled-components';

const ModalBackdrop = styled.div<{ background?: string }>`
    position: fixed;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: ${({ background }) => (background ? background : 'rgba(249, 249, 247, 0.95)')};
`;

ModalBackdrop.displayName = 'ModalBackdrop';

export default ModalBackdrop;
