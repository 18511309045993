import TrackDispatcher from './TrackDispatcher';

/**
 * A map for action type names.
 */
export const TrackActionTypes = {
    TRACK_CREATE_DONE: 'TRACK_CREATE_DONE',
    FIELD_DEFINITIONS_UPDATED: 'FIELD_DEFINITIONS_UPDATED',
    NEW_FULL_DATA_UPDATED: 'NEW_FULL_DATA_UPDATED',
    TRACK_DATA_UPDATED: 'TRACK_DATA_UPDATED',
    TRACK_DUE_DATE_UPDATED: 'TRACK_DUE_DATE_UPDATED',
    TRACK_START_TIME_UPDATED: 'TRACK_START_TIME_UPDATED',
    TRACK_OWNER_UPDATED: 'TRACK_OWNER_UPDATED',
    TRACK_FUNCTION_UPDATED: 'TRACK_FUNCTION_UPDATED',
    TRACK_TAGS_UPDATED: 'TRACK_TAGS_UPDATED',
    TRACK_STATUS_UPDATED: 'TRACK_STATUS_UPDATED',
    TRACK_STATUS_UPDATED_COMPLETED: 'TRACK_STATUS_UPDATED_COMPLETED',
    TRACK_FIELD_UPDATED: 'TRACK_FIELD_UPDATED',
    TRACK_RELATED_ITEMS_UPDATED: 'TRACK_RELATED_ITEMS_UPDATED',
    TOGGLE_TRACK_EXPANDED: 'TOGGLE_TRACK_EXPANDED',
    TOGGLE_TRACK_SET_REMINDER_DROPDOWN: 'TOGGLE_TRACK_SET_REMINDER_DROPDOWN',
    TRACK_SWIPE_IN_MOBILE_TOGGLE: 'TRACK_SWIPE_IN_MOBILE_TOGGLE',
    TRACKS_TRACK_QUICK_ADD_HOVER_TOGGLE: 'TRACKS_TRACK_QUICK_ADD_HOVER_TOGGLE',
    TRACKS_TRACK_QUICK_ADD_TOOLTIP_TOGGLE: 'TRACKS_TRACK_QUICK_ADD_TOOLTIP_TOGGLE',
    TOGGLE_TRACK_CONTEXT_MENU: 'TOGGLE_TRACK_CONTEXT_MENU',
    TRACKS_BULK_SELECT_TOGGLE: 'TRACKS_BULK_SELECT_TOGGLE',
    TRACK_BULK_SELECTION_STATE_CHANGED: 'TRACK_BULK_SELECTION_STATE_CHANGED',
    TRACKS_BULK_SELECT_ALL: 'TRACKS_BULK_SELECT_ALL',
    TRACKS_BULK_SELECT_NONE: 'TRACKS_BULK_SELECT_NONE',
    TRACK_TITLE_EDIT_VALUE_UPDATED: 'TRACK_TITLE_EDIT_VALUE_UPDATED',
    TRACK_EDIT_TITLE_TOGGLE: 'TRACK_EDIT_TITLE_TOGGLE',
    TRACK_TITLE_UPDATED: 'TRACK_TITLE_UPDATED',
    TRACK_DONE_RELATED_ITEMS_UPDATED: 'TRACK_DONE_RELATED_ITEMS_UPDATED',
    TRACK_DESCRIPTION_UPDATED: 'TRACK_DESCRIPTION_UPDATED',
    TRACK_ARCHIVED: 'TRACK_ARCHIVED',
    TRACK_VISIBLE_TO_OWNER_UPDATED: 'TRACK_VISIBLE_TO_OWNER_UPDATED',
    TRACK_NEXT_GATHER_UPDATES_UPDATED: 'TRACK_NEXT_GATHER_UPDATES_UPDATED',
    TRACK_NEXT_GATHER_UPDATE_COMPLETED: 'TRACK_NEXT_GATHER_UPDATE_COMPLETED',
    TRACK_EDIT_FIELD_TOGGLE: 'TRACK_EDIT_FIELD_TOGGLE',
    EDITOR_COLUMN_WIDTH_UPDATED: 'EDITOR_COLUMN_WIDTH_UPDATED',
    TRACK_MOVING: 'TRACK_MOVING',
};

/**
 * Our flux actions.
 * The API requires the user to send the trackHelper with every action triggered.
 */
export const TrackActions = {
    trackCreateDone(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_CREATE_DONE,
            id,
        });
    },
    newFullDataUpdate() {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.NEW_FULL_DATA_UPDATED,
        });
    },
    fieldDefinitionsUpdated() {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.FIELD_DEFINITIONS_UPDATED,
        });
    },
    trackMoving(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_MOVING,
            id,
            editorId,
            isTrue,
        });
    },
    trackDataUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_DATA_UPDATED,
            id,
        });
    },
    trackTitleUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_TITLE_UPDATED,
            id,
        });
    },
    trackDoneRelatedItemsUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_DONE_RELATED_ITEMS_UPDATED,
            id,
        });
    },
    trackDescriptionUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_DESCRIPTION_UPDATED,
            id,
        });
    },
    trackArchived(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_ARCHIVED,
            id,
        });
    },
    trackVisibleToOwnerUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_VISIBLE_TO_OWNER_UPDATED,
            id,
        });
    },
    trackNextGatherUpdatesUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_NEXT_GATHER_UPDATES_UPDATED,
            id,
        });
    },
    trackNextGatherUpdateCompleted(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_NEXT_GATHER_UPDATE_COMPLETED,
            id,
        });
    },
    trackDueDateUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_DUE_DATE_UPDATED,
            id,
        });
    },
    trackStartTimeUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_START_TIME_UPDATED,
            id,
        });
    },
    trackOwnerUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_OWNER_UPDATED,
            id,
        });
    },
    trackFunctionUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_FUNCTION_UPDATED,
            id,
        });
    },
    trackTagsUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_TAGS_UPDATED,
            id,
        });
    },
    trackStatusUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_STATUS_UPDATED,
            id,
        });
    },
    trackStatusUpdatedCompleted(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_STATUS_UPDATED_COMPLETED,
            id,
        });
    },
    trackFieldUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_FIELD_UPDATED,
            id,
        });
    },
    trackRelatedItemsUpdated(id) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_RELATED_ITEMS_UPDATED,
            id,
        });
    },
    toggleTrackExpanded(id, editorId) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TOGGLE_TRACK_EXPANDED,
            id,
            editorId,
        });
    },
    toggleTrackSetReminderDropdown(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TOGGLE_TRACK_SET_REMINDER_DROPDOWN,
            id,
            editorId,
            isTrue,
        });
    },
    toggleTrackQuickAddHover(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACKS_TRACK_QUICK_ADD_HOVER_TOGGLE,
            id,
            editorId,
            isTrue,
        });
    },
    toggleTrackQuickAddTooltip(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACKS_TRACK_QUICK_ADD_TOOLTIP_TOGGLE,
            id,
            editorId,
            isTrue,
        });
    },
    toggleTracksBulkSelect(editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACKS_BULK_SELECT_TOGGLE,
            editorId,
            isTrue,
        });
    },
    setTrackBulkSelectionState(id, editorId, selectionState) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_BULK_SELECTION_STATE_CHANGED,
            id,
            editorId,
            selectionState,
        });
    },
    bulkSelectAllTracks(editorId) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACKS_BULK_SELECT_ALL,
            editorId,
        });
    },
    bulkSelectNoTracks(editorId) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACKS_BULK_SELECT_NONE,
            editorId,
        });
    },
    trackTitleEditValueUpdated(id, editorId, titleEditedValue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_TITLE_EDIT_VALUE_UPDATED,
            id,
            editorId,
            titleEditedValue,
        });
    },
    toggleTrackTitleEdit(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_EDIT_TITLE_TOGGLE,
            id,
            editorId,
            isTrue,
        });
    },
    toggleTrackFieldEdit(id, editorId, fieldDefinitionId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_EDIT_FIELD_TOGGLE,
            id,
            editorId,
            fieldDefinitionId,
            isTrue,
        });
    },
    toggleTrackSwipeInMobile(id, editorId, isTrue) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.TRACK_SWIPE_IN_MOBILE_TOGGLE,
            id,
            editorId,
            isTrue,
        });
    },
    editorColumnWidthUpdated(editorId) {
        TrackDispatcher.dispatch({
            type: TrackActionTypes.EDITOR_COLUMN_WIDTH_UPDATED,
            editorId,
        });
    },
};

export default TrackActions;
