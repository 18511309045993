import React, { useContext } from 'react';
import styled from 'styled-components';

import BreadcrumbsContext from './BreadcrumbsContext';
import type BreadcrumbsSettings from './BreadcrumbsSettings';
import SingleBreadcrumb from './SingleBreadcrumb';
import IconSvg from '../IconSvg';

import { Chevron2Icon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import { BreadcrumbsSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

const Separator = styled.div<{ size: BreadcrumbsSize }>`
    font-size: ${({ size }) => Theme.sizes.breadcrumbs[size].fontSize};
    font-weight: 500;
`;

const Wrapper = styled.div<{ white: boolean }>`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 5px;
    align-items: center;

    color: ${({ white }) => (white ? Theme.colors.basicBackground : Theme.colors.gray_500)};
`;

export interface BreadcrumbsProps extends StyledComponentsSupportProps, DataAutomationSupportProps {
    settings?: BreadcrumbsSettings[];
    size?: BreadcrumbsSize;
    white?: boolean;
    lastIsBold?: boolean;
    disabled?: boolean;
    chevronSeparator?: boolean;
}

/**
 * Component for creating custom breadcrumbs.
 * A single BreadCrumb have 3 states:
 *      1. hardcoded text.
 *      2. clickable button with all the ClickableProps.
 *      3. onClick opens a menu with other options dropdown.
 * Important: Note that you can't use 2 and 3 together.
 * Therefore you can't implement the clickableProps with the otherOptions props!!
 */
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    settings: settingsProp,
    className,
    white = false,
    size = BreadcrumbsSize.SMALL,
    lastIsBold = false,
    disabled = false,
    chevronSeparator,
    dataAutomation,
}) => {
    const contextSettings = useContext(BreadcrumbsContext);

    const settings = settingsProp ?? contextSettings;

    const displayableBreadCrumbs = settings.filter(
        (breadcrumb) => typeof breadcrumb === 'string' || breadcrumb.displayName || breadcrumb.prefixIcon,
    );

    const lastIndex = displayableBreadCrumbs.length - 1;

    return (
        <Wrapper className={className} white={white} data-automation={dataAutomation}>
            {displayableBreadCrumbs.map((breadcrumb, index) => (
                <React.Fragment key={typeof breadcrumb === 'string' ? breadcrumb + index : breadcrumb.id}>
                    <SingleBreadcrumb
                        breadcrumb={breadcrumb}
                        white={white}
                        size={size}
                        bold={lastIsBold && index === lastIndex}
                        disabled={disabled}
                    />
                    {index !== lastIndex &&
                        (chevronSeparator ? (
                            <IconSvg as={Chevron2Icon} color={Theme.colors.gray_400} />
                        ) : (
                            <Separator size={size}>/</Separator>
                        ))}
                </React.Fragment>
            ))}
        </Wrapper>
    );
};

export default Breadcrumbs;
