import type { CSSProperties } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import Clickable from '../Clickable/Clickable';
import type ClickableProps from '../Clickable/ClickableProps';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const LeftIconWrapper = styled.div<{ $iconMargin?: number }>`
    margin-right: ${({ $iconMargin }) => $iconMargin || 8}px;
`;

const RightIconWrapper = styled.div<{ $iconMargin?: number }>`
    margin-left: ${({ $iconMargin }) => $iconMargin || 8}px;
`;

interface StyledIconButtonProps {
    $pressed: boolean;
    $flat: boolean;
    $noBackgroundColor: boolean;
    $hasText: boolean;
    $hasLeftIcon: boolean;
    $hasRightIcon: boolean;
    $iconColor?: string;
    $horizontalAlignment?: CSSProperties['justifyContent'];
    $iconSize: number;
    $showBorder?: boolean;
}

const StyledIconButton = styled(Clickable)<StyledIconButtonProps>`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: ${({ $horizontalAlignment }) => $horizontalAlignment};

    background: ${({ $flat, $noBackgroundColor }) =>
        $flat || $noBackgroundColor ? 'transparent' : `${Theme.colors.gray_200}`};

    border: ${({ $showBorder }) => ($showBorder ? `1px solid ${Theme.colors.gray_400}` : 'none')};
    border-radius: 4px;

    height: 28px;
    width: ${({ $hasText }) => ($hasText ? 'auto' : '28px')};
    margin: 0;
    padding: ${({ $hasText, $hasLeftIcon, $hasRightIcon }) =>
        $hasText ? `0 ${$hasRightIcon ? 10 : 16}px 0 ${$hasLeftIcon ? 10 : 16}px` : 0};

    opacity: ${({ disabled = false }) => (disabled ? '0.6' : 1)};

    color: ${Theme.colors.gray_600};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    svg {
        ${({ $iconColor }) =>
            $iconColor
                ? css`
                      ${colorSvg($iconColor)}
                  `
                : css`
                      ${colorSvg(Theme.colors.gray_600)}
                  `}

        max-width: ${({ $iconSize }) => $iconSize}px;
        max-height: ${({ $iconSize }) => $iconSize}px;
    }

    ${({ disabled = false }) =>
        !disabled &&
        css<StyledIconButtonProps>`
            ${({ $pressed }) => $pressed && '&, '} &:hover, &:focus {
                background: ${({ $flat, $noBackgroundColor }) =>
                    $noBackgroundColor ? 'transparent' : $flat ? Theme.colors.gray_200 : Theme.colors.gray_300};
                color: ${Theme.colors.gray_700};

                svg {
                    ${({ $iconColor }) =>
                        $iconColor
                            ? css`
                                  ${colorSvg($iconColor)}
                              `
                            : css`
                                  ${colorSvg(Theme.colors.gray_700)}
                              `}
                }
            }
        `}
    .tnk-icon {
        display: inline-block;
        line-height: 0;
        vertical-align: middle;
    }

    ${styledFocus}
`;

export interface IconButtonProps extends ClickableProps, DataAutomationSupportProps {
    pressed?: boolean;
    flat?: boolean;
    noBackgroundColor?: boolean;
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
    iconColor?: string;
    iconMargin?: number;
    iconSize?: number;
    horizontalAlignment?: CSSProperties['justifyContent'];
    showBorder?: boolean;
}

const IconButton: React.ForwardRefRenderFunction<HTMLElement, React.PropsWithChildren<IconButtonProps>> = (
    {
        pressed = false,
        flat = false,
        noBackgroundColor = false,
        rightIcon,
        leftIcon,
        iconColor,
        iconMargin,
        iconSize = 16,
        horizontalAlignment = 'center',
        showBorder = false,
        children,
        dataAutomation,
        ...props
    },
    ref,
) => {
    return (
        <StyledIconButton
            {...props}
            $pressed={pressed}
            $flat={flat}
            $noBackgroundColor={noBackgroundColor}
            $hasText={!!leftIcon || !!rightIcon}
            $hasLeftIcon={!!leftIcon}
            $hasRightIcon={!!rightIcon}
            $iconColor={iconColor}
            $horizontalAlignment={horizontalAlignment}
            $iconSize={iconSize}
            $showBorder={showBorder}
            {...(!!dataAutomation ? { 'data-automation': dataAutomation } : {})}
            ref={ref}
        >
            {leftIcon && <LeftIconWrapper $iconMargin={iconMargin}>{leftIcon}</LeftIconWrapper>}
            {children}
            {rightIcon && <RightIconWrapper $iconMargin={iconMargin}>{rightIcon}</RightIconWrapper>}
        </StyledIconButton>
    );
};

export default React.forwardRef(IconButton);
