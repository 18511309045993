export interface DeferredPromise<T> {
    promise: Promise<T>;
    resolve(value: T): void;
    reject(reason?: any): void;
    convert(promise: Promise<T>): void;
    isPending(): boolean;
}

/**
 * Creates a promise that can be resolved from outside of it.
 *
 * @returns an object with the promise and callbacks functions to resolve and reject it.
 */
function getDeferredPromise<T = any>(): DeferredPromise<T> {
    const promiseCallbacks: { resolve(value: T): void; reject(value: any): void; pending: boolean } = {
        resolve: () => {},
        reject: () => {},
        pending: true,
    };

    const promise = new Promise<T>((resolve, reject) => {
        promiseCallbacks.resolve = resolve;
        promiseCallbacks.reject = reject;
        promiseCallbacks.pending = true;
    });

    return {
        promise,
        isPending: () => promiseCallbacks.pending,
        resolve(value: T) {
            if (promiseCallbacks.pending) {
                promiseCallbacks.resolve(value);
                promiseCallbacks.pending = false;
            }
        },
        reject(value?: any) {
            if (promiseCallbacks.pending) {
                promiseCallbacks.reject(value);
                promiseCallbacks.pending = false;
            }
        },
        // The deferred promise will be resolved or rejected based on the result of the promise passed here as a param.
        convert(promiseToConvert: Promise<T>) {
            promiseToConvert.then(this.resolve).catch(this.reject);
        },
    };
}

export default getDeferredPromise;
