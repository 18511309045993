import React from 'react';

import type { FieldDefinition, Initiative, WorkflowVersion } from '@tonkean/tonkean-entities';

export interface TonkeanExpressionEditorContextType {
    fields?: Record<string, FieldDefinition>;
    initiative?: Initiative;
    workflowVersion?: WorkflowVersion;
}

const TonkeanExpressionEditorContext = React.createContext<TonkeanExpressionEditorContextType>({});
export default TonkeanExpressionEditorContext;
