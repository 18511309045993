// This Is partial list of the special fields of initiative, fill free to add all of them here!
enum SpecialFieldsKey {
    TITLE = 'TNK_TITLE',
    STATUS = 'TNK_STAGE',
    OWNER = 'TNK_OWNER_ID',
    DUE_DATE = 'TNK_DUE_DATE',
    CREATED_DATE = 'TNK_CREATED_DATE',
}

export default SpecialFieldsKey;
