import shallowArrayCompare from './shallowArrayCompare';

/**
 * memoizes a function by caching the args and the return type, and when calling the returned function it will
 * search the cache and have shallow compare between the args and the args in the cache and if they match it will
 * return the cached return value, otherwise execute the function and cache it's result.
 *
 * @example
 * const heavyCalculationFunction = (name: string, age: number) => {
 *     // OMG this is a very heavy function don't run if not needed!!!1
 * }
 * const memoized = memoize(heavyCalculationFunction);
 *
 * // HAHA I can run it as many times as I want
 * setInterval(() => memoized('Omry', 26), 100);
 *
 * @param func - the function to memoize
 * @returns memoize function
 */
function memoize<T extends (...args: any[]) => any>(func: T): (...args: Parameters<T>) => ReturnType<T> {
    const previousExecutions: PreviousExecution<Parameters<T>, ReturnType<T>>[] = [];

    function memoized(...args: Parameters<T>): ReturnType<T> {
        const previousExecution = previousExecutions.find((previousExecution) =>
            shallowArrayCompare(previousExecution.params, args),
        );
        if (previousExecution) {
            return previousExecution.returns;
        }

        const returns = func(...args);
        previousExecutions.push({
            params: args,
            returns,
        });

        return returns;
    }

    // Setting a name for the memoized function for the devtools.
    if (func.name) {
        const memoizedFunctionName = `${func.name}Memoized`;
        Object.defineProperty(memoized, 'name', { value: memoizedFunctionName, writable: false });
    }

    return memoized;
}

interface PreviousExecution<T, Z> {
    params: T;
    returns: Z;
}

export default memoize;
