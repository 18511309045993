enum SolutionSitePageType {
    CUSTOM = 'CUSTOM',
    EXTERNAL = 'EXTERNAL',
}
export default SolutionSitePageType;

export const solutionSitePageTypeToLabel: Record<SolutionSitePageType, string> = {
    [SolutionSitePageType.CUSTOM]: 'Custom',
    [SolutionSitePageType.EXTERNAL]: 'External',
};
