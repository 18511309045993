import { colord } from 'colord';
import React, { useCallback, useMemo } from 'react';
import { HexColorInput, RgbaColorPicker, RgbColorPicker } from 'react-colorful';
import styled, { css } from 'styled-components';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { useInnerField } from '../Field';
import type { InputStylingProps } from '../Input';
import { InputStyling } from '../Input';

import type { Color } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

const PickerStyle = css`
    &.react-colorful {
        width: 100%;
    }

    .react-colorful__pointer {
        width: 20px;
        height: 20px;
        outline: 2px solid #fff;
        border: none;
        box-shadow: 0 0 1px 3px rgb(0 0 0 / 30%);
    }

    .react-colorful__saturation {
        margin-bottom: 16px;
        border-radius: 2px;
        border-bottom: none;
        width: 100%;
    }

    .react-colorful__hue {
        margin-bottom: 16px;
        height: 16px;
        border-radius: 2px;
        width: 100%;
    }

    .react-colorful__hue-pointer,
    .react-colorful__alpha-pointer {
        width: 16px;
        height: 16px;
        outline: 2px solid #fff;
        border: none;
        box-shadow: 0 0 1px 3px rgb(0 0 0 / 30%);
    }
`;

const StyledReactColorfulPickWithoutAlpha = styled(RgbColorPicker)`
    ${PickerStyle}
`;

const StyledReactColorfulPick = styled(RgbaColorPicker)`
    ${PickerStyle}
    .react-colorful__alpha {
        height: 16px;
        margin-bottom: 16px;
        border-radius: 2px;
        width: 100%;
    }

    .react-colorful__alpha-pointer {
        width: 16px;
        height: 16px;
        outline: 2px solid #fff;
        border: none;
        box-shadow: 0 0 1px 3px rgb(0 0 0 / 30%);
    }
`;

const ColorTextInput = styled(HexColorInput)<InputStylingProps>`
    ${InputStyling}
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    /**
     * The initial color of the color picker - must be in hex format,
     * starting with # and having 6 or 8 chars
     */
    value?: Color;

    /**
     * Function that will run everytime the color changes
     * @param newColor - The new color
     */
    onChange?(newColor: Color): void;

    /**
     * Name to pass to the form field
     */
    name?: string;

    /**
     * Id to pass to the form field
     */
    id?: string;

    /**
     * Is picker with alpha
     */
    alpha?: boolean;
}

const HexColorPicker: React.FC<Props> = ({ onChange, alpha = false, className, dataAutomation, ...props }) => {
    const [{ value: fieldValue, ...fieldProps }, hasError, fieldHelper] = useInnerField({
        type: 'color',
        multiple: false,
        ...props,
    });
    const rgbaValue = useMemo(() => colord(fieldValue).toRgb(), [fieldValue]);
    const [pickerColor, setPickerColor] = useDebouncedState(rgbaValue, (newRgbaValue) => {
        const newHex = colord(newRgbaValue).toHex();
        onHexChange(newHex);
    });

    const onHexChange = useCallback(
        (newHex: string) => {
            onChange?.(newHex);
            fieldHelper?.setValue(newHex);
        },
        [onChange, fieldHelper],
    );

    const ColorPickerComponent = alpha ? StyledReactColorfulPick : StyledReactColorfulPickWithoutAlpha;

    return (
        <div className={className}>
            <ColorPickerComponent color={pickerColor} onChange={setPickerColor} />
            <ColorTextInput
                aria-label="selected hex color"
                $size={InputSize.MEDIUM}
                color={fieldValue}
                $isError={hasError}
                alpha={alpha}
                {...fieldProps}
                onChange={(newColor) => onHexChange(newColor)}
                data-automation={dataAutomation}
                prefixed
            />
        </div>
    );
};

export default HexColorPicker;
