import React from 'react';
import styled from 'styled-components';

import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

function getIndicatorColor({ isActive, isWarning }) {
    if (isActive) {
        return Theme.colors.success;
    } else if (isWarning) {
        return Theme.colors.error;
    }

    return Theme.colors.gray_500;
}

const Text = styled.span<{ isActive: boolean; isWarning: boolean }>`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${getIndicatorColor};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Dot = styled.div<{ isActive: boolean; isWarning: boolean }>`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 4px;
    background: ${getIndicatorColor};
`;

interface Props extends StyledComponentsSupportProps {
    isActive: boolean;
    onText?: string;
    offText?: string;
    dataAutomation?: string;
    isWarning?: boolean;
}

const ActiveIndicator: React.FC<Props> = ({
    isActive,
    onText = 'ON',
    offText = 'OFF',
    dataAutomation,
    className,
    isWarning = false,
}) => {
    return (
        <Wrapper className={className}>
            <Dot isActive={isActive} isWarning={isWarning} />
            <Text isActive={isActive} data-automation={dataAutomation} isWarning={isWarning}>
                {isActive ? onText : offText}
            </Text>
        </Wrapper>
    );
};

export default ActiveIndicator;
