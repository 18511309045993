enum FindWordsFindStepType {
    SEARCH_TEXT_BEFORE = 'SEARCH_TEXT_BEFORE',
    // SEARCH_TEXT_AFTER = 'SEARCH_TEXT_AFTER',
    WORD_TYPE = 'WORD_TYPE',
    SKIP_X_LINES = 'SKIP_X_LINES',
    SKIP_X_LINES_BACKWARDS = 'SKIP_X_LINES_BACKWARDS',
    SKIP_X_WORDS = 'SKIP_X_WORDS',
    SKIP_X_WORDS_BACKWARDS = 'SKIP_X_WORDS_BACKWARDS',
    SKIP_X_CHARACTERS = 'SKIP_X_CHARACTERS',
    SKIP_X_CHARACTERS_BACKWARDS = 'SKIP_X_CHARACTERS_BACKWARDS',
}

export default FindWordsFindStepType;
