import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

interface Props {
    content: (string | undefined)[];
    metaDescription?: string;
    image?: string;
    disableMetaTags?: boolean;
}

const LibraryHead: React.FC<Props> = ({
    content,
    metaDescription = 'Add a ready to go components from our predefined blueprints and templates',
    image = 'https://tracks.tonkean.com/images/libraryLinks/tonkean.png',
    disableMetaTags = false,
}) => {
    const router = useRouter();
    const title = [...content, 'Tonkean Library'].filter(Boolean).join(' - ');
    const fullUrl = useMemo(() => {
        return `https://library.tonkean.com${router.asPath}`;
    }, [router.asPath]);

    return (
        <Head>
            {/* HTML Meta Tags */}
            <title>{title}</title>

            {!disableMetaTags && (
                <>
                    <meta name="description" content={metaDescription} />
                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content={fullUrl} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content={image} />
                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="library.tonkean.com" />
                    <meta property="twitter:url" content={fullUrl} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={metaDescription} />
                    <meta name="twitter:image" content={image} />
                </>
            )}
        </Head>
    );
};

export default LibraryHead;
