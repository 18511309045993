import React from 'react';

import type { FormBuilderEditedSectionsState } from '@tonkean/tonkean-entities';
import type { FormBuilderSections } from '@tonkean/tonkean-entities';
import { FormBuilderEditedSectionsInitialState } from '@tonkean/tonkean-entities';

interface FormBuilderContext {
    editedSections: FormBuilderEditedSectionsState;
    setSectionLoading: (section: FormBuilderSections) => void;
    isEditable: boolean;
    currentError: string | undefined;
}

const FormBuilderContext = React.createContext<FormBuilderContext>({
    editedSections: FormBuilderEditedSectionsInitialState,
    setSectionLoading: () => {},
    isEditable: false,
    currentError: undefined,
});

export default FormBuilderContext;
