import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import Tooltip from './TUI/Tooltip/Tooltip';

import { CheckIconFilledSVG as CheckIconSVG } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const CheckWrapper = styled(motion.span)`
    height: 28px;
    width: 28px;

    display: grid;
    place-items: center;
`;

const CheckIcon = styled(CheckIconSVG)`
    width: 12px;
    height: 12px;

    ${colorSvg(Theme.colors.success)}
`;

interface Props {
    successful: boolean;
    tooltip?: string;
    tooltipSuccessful?: string;
}

const SuccessFeedback: React.FC<React.PropsWithChildren<Props>> = ({
    successful,
    tooltip,
    tooltipSuccessful,
    children,
}) => {
    return (
        <AnimatePresence exitBeforeEnter>
            <Tooltip key={successful.toString()} content={successful ? tooltipSuccessful || tooltip : tooltip}>
                {successful ? (
                    <CheckWrapper key="check" exit={{ opacity: 0 }} initial={{ opacity: 1 }}>
                        <CheckIcon aria-label="successful" />
                    </CheckWrapper>
                ) : (
                    <motion.span key="successful" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {children}
                    </motion.span>
                )}
            </Tooltip>
        </AnimatePresence>
    );
};

export default SuccessFeedback;
