import { useEffect, useRef } from 'react';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import type { createSymbolStack } from '@tonkean/utils';

type AddToSymbolStack = ReturnType<typeof createSymbolStack>;

/**
 * This function has the following overload:
 * If `onlyIfNewest` is not false (either true or boolean), addToStack is required.
 * If `onlyIfNewest` is false, addToStack is optional.
 */

/**
 * React hook for adding a global event listener.
 *
 * @param eventType - The event type to listen to.
 * @param callback - The callback function to trigger.
 * @param active - Should the listener be active? If not, it will also remove itself from the stack, to let elements
 * that handles.
 * @param onlyIfNewest - If true, it will call the callback only if this is the 'newest' useEscapeCallback.
 * for example, if you have modal inside modal, it an escape will be triggered only in the child modal.
 * @param addToStack - Function to add to the symbol stack. **Calling `symbolStack()` *MUST* happen outside of the
 * hook!**, otherwise it will re-create the stack on every use of the hook, so it will never have more then one
 * symbol in the stack, and all hook users will be considered 'newest', and it will re-run the useEffect on every
 * render. This is a required parameter if `onlyIfNewest` is true.
 * @param capture - should events of this type be dispatched this callback before being dispatched to any other
 * event listener?
 */
function useEventListener<T extends keyof DocumentEventMap>(
    eventType: T,
    callback: (event: DocumentEventMap[T]) => void,
    capture: boolean | undefined,
    active: boolean | undefined,
    onlyIfNewest: boolean,
    addToStack: AddToSymbolStack,
);
/**
 * React hook for adding a global event listener.
 *
 * @param eventType - The event type to listen to.
 * @param callback - The callback function to trigger.
 * @param active - Should the listener be active? If not, it will also remove itself from the stack, to let elements
 * that handles.
 * @param onlyIfNewest - If true, it will call the callback only if this is the 'newest' useEscapeCallback.
 * for example, if you have modal inside modal, it an escape will be triggered only in the child modal.
 * @param addToStack - Function to add to the symbol stack. **Calling `symbolStack()` *MUST* happen outside of the
 * hook!**, otherwise it will re-create the stack on every use of the hook, so it will never have more then one
 * symbol in the stack, and all hook users will be considered 'newest', and it will re-run the useEffect on every
 * render. This is a required parameter if `onlyIfNewest` is true.
 * @param capture - should events of this type be dispatched this callback before being dispatched to any other
 * event listener?
 */
function useEventListener<T extends keyof DocumentEventMap>(
    eventType: T,
    callback: (event: DocumentEventMap[T]) => void,
    capture?: boolean,
    active?: boolean,
    onlyIfNewest?: false,
    addToStack?: AddToSymbolStack | false | undefined,
);

function useEventListener<T extends keyof DocumentEventMap>(
    eventType: T,
    callback: (event: DocumentEventMap[T]) => void,
    capture: boolean = false,
    active: boolean = true,
    onlyIfNewest: boolean = false,
    addToStack: AddToSymbolStack | false | undefined = false,
) {
    const callbackConstantRef = useConstantRefCallback(callback);

    const onlyIfNewestRef = useRef(onlyIfNewest);
    onlyIfNewestRef.current = onlyIfNewest;

    // We don't use the useEffect and it's cleanup function to do this, because we want it first to run on the parent and then on the child
    const stackItem = useRef<ReturnType<AddToSymbolStack>>();
    if (stackItem.current && (!active || !addToStack)) {
        stackItem.current.remove();
        stackItem.current = undefined;
    } else if (!stackItem.current && active && addToStack) {
        stackItem.current = addToStack();
    }
    // Remove on unmount
    useEffect(() => {
        return () => stackItem.current?.remove();
    }, []);

    useEffect(() => {
        if (!active) {
            return;
        }

        const onKeyDown = (event: DocumentEventMap[T]) => {
            if (onlyIfNewestRef.current && stackItem.current && !stackItem.current.isLast()) {
                return;
            }

            callbackConstantRef(event);
        };

        document.addEventListener(eventType, onKeyDown, capture);

        return () => {
            document.removeEventListener(eventType, onKeyDown, capture);
        };
    }, [active, callbackConstantRef, capture, eventType]);
}

export default useEventListener;
