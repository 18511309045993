import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

interface Props {
    /** To which side align the content? */
    align?: 'left' | 'right' | 'center' | 'space';
    shadow?: boolean;
    border?: boolean;
    /** The gap between items (margin to the sides). */
    gap?: number;
}

const ModalFooter = styled.div<Props>`
    padding: 14px;
    position: relative;
    z-index: 1;

    ${({ shadow = false }) =>
        shadow &&
        css`
            box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
        `};
    ${({ border }) =>
        border &&
        css`
            border-top: 1px solid ${Theme.colors.gray_400};
        `};

    ${({ align = 'left', gap }: Props) => {
        if (!align) {
            return;
        }

        const sharedAlignCss = css`
            display: flex;
            flex-direction: row;
            align-items: center;

            ${gap &&
            css`
                > *:not(:last-child) {
                    margin-right: ${gap}px;
                }
            `}
        `;

        switch (align) {
            case 'center': {
                return css`
                    justify-content: center;
                    ${sharedAlignCss}
                `;
            }
            case 'left': {
                return css`
                    justify-content: flex-start;
                    ${sharedAlignCss}
                `;
            }

            case 'right': {
                return css`
                    justify-content: flex-end;
                    ${sharedAlignCss}
                `;
            }

            case 'space': {
                return css`
                    justify-content: space-between;
                    ${sharedAlignCss}
                `;
            }
        }
    }};
`;

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
