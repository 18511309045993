import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
    color: string;
}

const Rotate = keyframes`
    0% {  
        transform: rotate(0);
    }
    100% { 
        transform: rotate(360deg);
    }
`;

const Loader = styled.span<{ color: string; rgbColor: string }>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: linear-gradient(0deg, rgba(${({ rgbColor }) => rgbColor}, 0.2) 33%, ${({ color }) => color} 100%);
    box-sizing: border-box;
    animation: ${Rotate} 1s linear infinite;

    &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: white;
    }
`;

const BrandedLoading: React.FC<Props> = ({ color }) => {
    const hexToRgb = (hex) => {
        // Remove the "#" symbol if present
        hex = hex.replace('#', '');

        // Convert the hex value to RGB
        const r = Number.parseInt(hex.slice(0, 2), 16);
        const g = Number.parseInt(hex.slice(2, 4), 16);
        const b = Number.parseInt(hex.slice(4, 6), 16);

        // Return the RGB color value
        return `${r}, ${g}, ${b}`;
    };

    return <Loader color={color} rgbColor={hexToRgb(color)} />;
};

export default BrandedLoading;
