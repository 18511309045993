import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import XCloseButton from '../CloseButton/XCloseButton';

import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const FileUploadWrapper = styled.div`
    display: contents;
`;

const UploadIndicationLabelWrapper = styled.div`
    margin-bottom: 16px;
`;

const UploadButtonWrapper = styled.div`
    margin-right: 8px;
    margin-bottom: 16px;
`;

const ButtonLabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FileName = styled.span`
    color: ${Theme.colors.HEX_333333};
    margin-right: 5px;
`;

const UploadIndicationLabel = styled.span`
    color: ${Theme.colors.gray_500};
    margin: 16px 4px 0 0;
`;

const HiddenInput = styled.input`
    height: 0px;
    width: 0px;
    visibility: hidden;
`;

interface Props {
    onChange(file: string | ArrayBuffer | null): void;
    label?: string;
}

const FileUpload: React.FC<Props> = ({ onChange, label = 'Upload', ...props }) => {
    const [fileName, setFileName] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    const onChangeInner = (file: File | null | undefined) => {
        if (file) {
            const reader: FileReader = new FileReader();

            setFileName(file.name);
            reader.onloadend = () => {
                onChange(reader.result);
            };

            reader.readAsArrayBuffer(file);
        } else {
            setFileName('');
            onChange(null);
        }
    };

    return (
        <FileUploadWrapper>
            <HiddenInput
                type="file"
                onChange={(event) => onChangeInner(event.currentTarget.files?.item(0))}
                ref={inputRef}
            />
            <UploadButtonWrapper>
                <Button onClick={() => inputRef.current?.click()} size={ButtonSize.MEDIUM} outlined {...props}>
                    <ButtonLabelWrapper>{label}</ButtonLabelWrapper>
                </Button>
            </UploadButtonWrapper>
            {fileName && (
                <UploadIndicationLabelWrapper>
                    <UploadIndicationLabel>Image Uploaded:</UploadIndicationLabel>
                    <FileName>{fileName}</FileName>
                    <XCloseButton size={ButtonSize.SMALL} onClick={() => onChangeInner(null)} />
                </UploadIndicationLabelWrapper>
            )}
        </FileUploadWrapper>
    );
};

export default FileUpload;
