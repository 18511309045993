import type { Environment } from '../Environment';

enum WorkflowVersionType {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export const workflowVersionTypeToEnvironment: Record<string, Environment> = {
    [WorkflowVersionType.DRAFT]: 'build',
    [WorkflowVersionType.PUBLISHED]: 'production',
};

export function workflowVersionTypeToDisplayName(workflowVersionType: WorkflowVersionType): string {
    return workflowVersionType === WorkflowVersionType.DRAFT ? 'Build' : 'Published';
}

export default WorkflowVersionType;
