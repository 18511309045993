import React from 'react';
import styled from 'styled-components';

import ErrorStateMessage from './ErrorStateMessage';
import StateMessage from './StateMessage';
import { ReactComponent as NetworkErrorIcon } from '../../../images/network-error.svg';
import { ReactComponent as ReRunIcon } from '../../../images/re-run.svg';
import ErrorMessage from '../TUI/Message/ErrorMessage';

import { Button } from '@tonkean/tui-buttons/Button';
import {
    type DataAutomationSupportProps,
    type ErrorResponseType,
    getStateError,
    type StyledComponentsSupportProps,
} from '@tonkean/utils';

const ReloadButton = styled(Button)`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    min-width: 100px;
    align-self: center;
    align-items: center;

    .tnk-icon {
        display: inline-flex;
    }
`;

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    /** the icon to show in big message. */
    icon?: JSX.Element;
    /** The error object or message. */
    error: ErrorResponseType;
    /**
     * Should we show the small message? Recommended to check if there are items, and pass true when there are,
     * otherwise false.
     */
    showSmallError?: boolean;
    /**
     * Whether the error is a network error.
     */
    isNetworkError?: boolean;
    /**
     * The error message to show. If it's a network error, it will be ignored.
     */
    errorMessage?: string;
    /**
     * Triggered when pressing on the reload button when the network error is shown.
     */
    reload?(): void;

    /** The className prop affects only the small error message */
}

const SimpleErrorStateMessage: React.FC<Props> = ({
    className,
    icon = (
        <span className="tnk-icon">
            <NetworkErrorIcon />
        </span>
    ),
    showSmallError = false,
    error,
    reload,
    errorMessage: errorMessageProp,
    isNetworkError = typeof error !== 'string' && error['status'] === -1,
    dataAutomation,
}) => {
    const errorMessage = getStateError(error, { isNetworkError, overrideErrorMessage: errorMessageProp });

    if (showSmallError) {
        return (
            <ErrorMessage dataAutomation={dataAutomation} className={className}>
                {errorMessage}
            </ErrorMessage>
        );
    }

    if (isNetworkError) {
        return (
            <StateMessage
                dataAutomation={dataAutomation}
                className={className}
                icon={
                    <span className="tnk-icon">
                        <NetworkErrorIcon />
                    </span>
                }
                title="No Internet Connection"
            >
                <div>
                    {errorMessage} check your network cables and WiFi, then try to{!reload && ' reload the page.'}
                </div>

                {reload && (
                    <ReloadButton onClick={reload}>
                        <span className="tnk-icon">
                            <ReRunIcon />
                        </span>
                        <span>Reload</span>
                    </ReloadButton>
                )}
            </StateMessage>
        );
    }

    return (
        <ErrorStateMessage dataAutomation={dataAutomation} icon={icon} className={className}>
            {errorMessage}
        </ErrorStateMessage>
    );
};

export default SimpleErrorStateMessage;
