import groupBy from 'lodash.groupby';
import React, { useMemo } from 'react';
import type { GroupBase, StylesConfig } from 'react-select';
import styled from 'styled-components';

import type { SimpleSelectProps } from './SimpleSelectTypes';
import type { SimpleSelectSingleOption } from './SimpleSelectTypes';
import SimpleSelect from '../Select/SimpleSelect';

import type { State } from '@tonkean/tonkean-entities';
import { InitiativeStatus } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';

const StatusColorSquare = styled.span<{ color: string }>`
    background: ${({ color }) => color};
    border-radius: 100%;
    margin-right: 0px;
    width: 8px;
    height: 8px;
`;

function getStatusBackgroundColor(currentStateOption: State | undefined, isDisabled: boolean) {
    if (!currentStateOption || currentStateOption?.color === '') {
        return isDisabled ? Theme.colors.disabled : Theme.colors.basicBackground;
    } else {
        return currentStateOption.color;
    }
}

type StatusOption = SimpleSelectSingleOption<string> & State;

export enum StaticStatusSelectPresentationMode {
    COLORED_BACKGROUND = 'COLORED_BACKGROUND',
    COLORED_TEXT = 'COLORED_TEXT',
}
interface StaticStatusSelectProps {
    placeholder?: string;
    size?: InputComponentSizes;
    mobileSize?: InputComponentSizes;
    presentationMode?: StaticStatusSelectPresentationMode;
}

// exporting this type to use in StatusSelect as well
export type SharedStatusSelectProps = StaticStatusSelectProps &
    Omit<
        SimpleSelectProps<StatusOption, false, false, GroupBase<StatusOption>>,
        keyof StaticStatusSelectProps | 'isSearchable' | 'hideSelectedOptions' | 'options'
    >;

// Separate interface with mandatory states property
interface StatesStatusSelectProps {
    states: State[];
}

type Props = StatesStatusSelectProps & SharedStatusSelectProps;

const StaticStatusSelect: React.FC<Props> = ({
    states,
    presentationMode,
    placeholder = 'Select an option',
    ...props
}) => {
    const statesOptions = useMemo<StatusOption[]>(() => {
        return states.map((mappedState) => {
            return {
                ...mappedState,
                value: mappedState.id,
                icon: mappedState.color ? <StatusColorSquare color={mappedState.color} /> : undefined,
            };
        });
    }, [states]);

    const groupedOptions = useMemo(() => {
        return Object.entries(
            groupBy(statesOptions, ({ type }) =>
                type === InitiativeStatus.INTAKE ? 'Intake' : 'Triage and Coordination',
            ),
        ).map(([label, options]) => ({ label, options }));
    }, [statesOptions]);

    const componentStyling = useMemo(() => {
        const coloredTextMode = presentationMode === StaticStatusSelectPresentationMode.COLORED_TEXT;
        const styles: StylesConfig<StatusOption, false, GroupBase<StatusOption>> = {
            control: (_ignored, state) => ({
                span: {
                    display: coloredTextMode ? 'flex' : 'none',
                },
                ...(state.getValue()[0]?.color && !props.isError && !props.isHighlighted && !state.isFocused
                    ? {
                          '&,:hover': {
                              borderColor: coloredTextMode ? 'none' : state.getValue()[0]?.color,
                          },
                      }
                    : {}),

                ...(coloredTextMode
                    ? {
                          border: 'none',
                      }
                    : {}),
                backgroundColor: coloredTextMode
                    ? 'none'
                    : getStatusBackgroundColor(state.getValue()[0], props.isDisabled ?? false),
                ...(state.isFocused
                    ? {
                          borderColor: coloredTextMode ? 'none' : 'rgb(0 0 0 / 30%) !important;',
                      }
                    : {}),
                ...props.styles?.control,
            }),
            dropdownIndicator: (_ignored, state) => ({
                ...(props.isDisabled && {
                    display: 'none',
                }),
                color: coloredTextMode
                    ? state.getValue()[0]?.color
                    : state.getValue()[0]?.color && Theme.colors.basicBackground,
                ':hover': {
                    color: coloredTextMode
                        ? state.getValue()[0]?.color
                        : state.getValue()[0]?.color && Theme.colors.basicBackground,
                },
                ...props.styles?.dropdownIndicator,
            }),
            singleValue: (_ignored, state) => ({
                color: coloredTextMode
                    ? state.getValue()[0]?.color
                    : state.getValue()[0]?.color && Theme.colors.basicBackground,
                ...props.styles?.singleValue,
            }),
            clearIndicator: () => ({
                color: Theme.colors.basicBackground,
                ...props.styles?.clearIndicator,
            }),
            valueContainer: props.styles?.valueContainer,
        };
        return styles;
    }, [props.isDisabled, props.isError, props.isHighlighted, presentationMode, props.styles]);

    return (
        <SimpleSelect<StatusOption>
            {...props}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isCreatable={false}
            options={groupedOptions}
            styles={componentStyling}
            placeholder={placeholder}
            hideSelectedOptions
        />
    );
};

export default StaticStatusSelect;
