import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import type TinyImage from './TinyImage';
import { ReactComponent as CheckedCheckboxIcon } from '../../../../images/checked-checkbox.svg';
import Chevron, { ChevronDirection } from '../Chevron';
import { Popover } from '../Popover';

import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.colors;

const Wrapper = styled(DisableableButton)`
    height: 26px;
    width: 40px;
    margin-left: 6px;
    padding: 0 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 3px;

    cursor: pointer;
    background-color: transparent;

    .tnk-icon {
        display: inline-flex;
    }

    &:hover {
        background-color: ${theme.gray_300};
    }

    &[disabled] {
        background-color: ${theme.brightestGray};
    }
`;

interface ColorIconProps {
    isSelected?: boolean;
    backgroundImage: string;
    backgroundColor: string;
}

const ImageIcon = styled.button<ColorIconProps>`
    width: 20px;
    height: 20px;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-image: url('${({ backgroundImage }) => backgroundImage}');
    background-size: 12px;
    border-radius: 4px;
    border: none;
    padding: 0;

    ${({ isSelected }) =>
        isSelected &&
        css`
            transform: scale(1.2);
        `};

    &:hover {
        ${({ isSelected }) =>
            !isSelected &&
            css`
                transform: scale(1.2);
            `};
    }

    &:focus {
        ${({ isSelected, theme }) =>
            !isSelected &&
            theme.isKeyboardFocus &&
            css`
                transform: scale(1.2);
            `};
    }

    .checkmark-icon {
        height: 100%;
        width: 100%;

        .tnk-icon {
            padding: 4px;
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }
`;

const ColorDisplay = styled.span<ColorIconProps>`
    height: 16px;
    width: 16px;
    border: none;
    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-image: url('${({ backgroundImage }) => backgroundImage}');
    background-size: 12px;
`;

const TinyImages = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    grid-gap: 8px;
    gap: 8px;
`;

export interface TinyImagePickerProps {
    options: TinyImage[];
    value: TinyImage;
    onChange: (option: TinyImage) => void;
}

const TinyImagePicker: React.FC<TinyImagePickerProps> = ({ options, value, onChange: onTinyImageChosen }) => {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            placement="bottom-start"
            content={
                <TinyImages>
                    {options.map((option) => (
                        <ImageIcon
                            backgroundColor={option.backgroundColor}
                            key={option.css}
                            onClick={() => {
                                onTinyImageChosen(option);
                                setPopoverOpen(false);
                            }}
                            backgroundImage={option.backgroundImage}
                            isSelected={option.css === value.css}
                        >
                            {option.css === value.css && (
                                <span className="checkmark-icon">
                                    <span className="tnk-icon">
                                        <CheckedCheckboxIcon />
                                    </span>
                                </span>
                            )}
                        </ImageIcon>
                    ))}
                </TinyImages>
            }
            showArrow={false}
        >
            <Wrapper onClick={() => setPopoverOpen(true)}>
                <ColorDisplay backgroundColor={value.backgroundColor} backgroundImage={value.backgroundImage} />
                <Chevron direction={popoverOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
            </Wrapper>
        </Popover>
    );
};

export default TinyImagePicker;
