enum TonkeanFieldCondition {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    LessThan = 'LessThan',
    GreaterThan = 'GreaterThan',
    Contains = 'Contains',
    NotContains = 'NotContains',
    Match = 'Match',
    NotMatch = 'NotMatch',
    IsEmpty = 'IsEmpty',
    IsNotEmpty = 'IsNotEmpty',

    // Number only conditions
    Between = 'Between',
    IncreasedBy = 'IncreasedBy',
    DecreasedBy = 'DecreasedBy',

    // Time only conditions
    Before = 'Before',
    After = 'After',
    Past = 'Past',
    Next = 'Next',
    InThis = 'InThis',
    OlderThan = 'OlderThan',
    FurtherThan = 'FurtherThan',
    BeforeNow = 'BeforeNow',
    AfterNow = 'AfterNow',

    Exists = 'Exists',
    EqualsFreeText = 'EqualsFreeText',
}

export default TonkeanFieldCondition;
