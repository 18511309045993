import React from 'react';
import type { ForwardedRef } from 'react';
import styled from 'styled-components';

import CheckboxIcon from './CheckboxIcon';
import useInnerField from '../Field/useInnerField';
import IconInputWithLabel from '../IconInputWithLabel/IconInputWithLabel';

import { ButtonShape } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { CheckboxComponentSizes } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps } from '@tonkean/utils';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const LoaderWrapper = styled.div<{ size: CheckboxComponentSizes; $color: Color }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ size }) => Theme.sizes.checkbox[size].size}px;
    width: ${({ size }) => Theme.sizes.checkbox[size].size}px;
    .loading-small {
        border-color: ${({ $color }) => $color};
        border-right-color: transparent;
    }
`;
export interface CheckboxProps
    extends StyledComponentsSupportProps,
        DataAutomationSupportProps,
        Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    /** Does the checkbox disabled? */
    disabled?: boolean;

    /** Customized color or opacity level of the label when checkbox is disabled */
    disabledLabelColorOrOpacity?: Color | number;

    /** Should modify visualization of the icon for disabled state */
    applyDisabledStyleOnIcon?: boolean;

    /** Should the checkbox label changes color to lighter gray when it's not checked? */
    changeLabelColor?: boolean;

    /** The checkbox size */
    size?: CheckboxComponentSizes;

    /** The checkbox shape */
    shape?: ButtonShape;

    /** Name to pass to the input */
    name?: string;

    /** Is the checkbox checked? */
    checked?: boolean;

    /** Will be triggered when the checkbox state changes. */
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;

    /** Should the label be bold? */
    boldLabel?: boolean;

    /**
     * Should the label text try to take as much space as possible (flex-grow)
     */
    labelFillSpace?: boolean;

    /** Used for formik when there is an array */
    value?: string;

    /**
     * If the checkbox is checked but with partial check. e.g. some items in list are selected, so the select all checkbox is partial.
     */
    indeterminate?: boolean;
    /**
     * If the highlighted is true, the checkbox will be in highlighted color theme
     */
    highlighted?: boolean;

    /**
     * A custom color for the box, if provided takes priority over other props
     */
    customColor?: Color;

    /**
     * A custom color for the box in an unselected state, if provided takes priority over other props
     */
    customUncheckedColor?: Color;

    /**
     * If the update of the value is loading show loader
     */
    loading?: boolean;

    /**
     * A ref for the label to show a tooltip
     */
    tooltipRef?: ForwardedRef<HTMLLabelElement>;
}

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<CheckboxProps>> = (
    {
        className,
        children,
        disabled = false,
        disabledLabelColorOrOpacity,
        applyDisabledStyleOnIcon = true,
        changeLabelColor = true,
        size = InputSize.SMALL,
        shape = ButtonShape.RECTANGULAR,
        name,
        checked,
        indeterminate = false,
        onChange,
        dataAutomation,
        boldLabel = true,
        labelFillSpace,
        value,
        id,
        highlighted = false,
        customColor = undefined,
        customUncheckedColor = undefined,
        loading = false,
        tooltipRef,
    },
    ref,
) => {
    const [props, hasError] = useInnerField({
        name,
        type: 'checkbox',
        multiple: false,
        checked,
        onChange,
        value,
        id,
    });

    const color = customColor || (highlighted ? Theme.colors.primaryHighlight : Theme.colors.primary);

    return (
        <IconInputWithLabel
            type="checkbox"
            className={className}
            disabled={disabled}
            disabledLabelColorOrOpacity={disabledLabelColorOrOpacity}
            tooltipRef={tooltipRef}
            ref={ref}
            size={size}
            changeLabelColor={changeLabelColor}
            icon={
                loading ? (
                    <LoaderWrapper size={size} $color={color}>
                        <span className="loading-small" />
                    </LoaderWrapper>
                ) : (
                    <CheckboxIcon
                        disabled={disabled && applyDisabledStyleOnIcon}
                        checked={props.checked}
                        size={size}
                        shape={shape}
                        indeterminate={indeterminate}
                        color={color}
                        uncheckedColor={customUncheckedColor}
                    />
                )
            }
            labelAfter={children}
            labelAfterFillSpace={labelFillSpace}
            boldLabel={boldLabel}
            dataAutomation={dataAutomation}
            indeterminate={indeterminate}
            {...props}
        />
    );
};

export default React.forwardRef(Checkbox);
