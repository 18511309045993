import styled, { css } from 'styled-components';

import { classNames } from '@tonkean/utils';

const Placeholder = styled.div.attrs((props) => ({ className: classNames(props.className, 'loading-placeholder') }))<{
    $width: string;
    $height?: string;
    $borderRadius?: string;
    $circle?: boolean;
    $noAnimation?: boolean;
}>`
    ${({ $noAnimation }) =>
        $noAnimation &&
        css`
            animation: none;
        `}

    animation-iteration-count: infinite;
    display: block;
    height: ${({ $circle = false, $width, $height = $circle ? $width : '12px' }) => $height};
    width: ${({ $width }) => $width};
    ${({ $borderRadius }) =>
        $borderRadius &&
        css`
            border-radius: ${$borderRadius};
        `};
`;

export default Placeholder;
