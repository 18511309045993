import TonkeanType from '../TonkeanType';

/**
 * Enforcing that enterprise component type will contain only tonkean types.
 */
type TypeEnforcer<T extends TonkeanType> = T;

type EnterpriseComponentType = TypeEnforcer<
    TonkeanType.TRAINING_SET | TonkeanType.PROJECT_INTEGRATION | TonkeanType.PEOPLE_DIRECTORY
>;

export const enterpriseComponentTypeDisplayName: Record<EnterpriseComponentType, { singular: string; plural: string }> =
    {
        [TonkeanType.PROJECT_INTEGRATION]: {
            singular: 'data source',
            plural: 'data sources',
        },
        [TonkeanType.PEOPLE_DIRECTORY]: {
            singular: 'business group',
            plural: 'business groups',
        },
        [TonkeanType.TRAINING_SET]: {
            singular: 'training set',
            plural: 'training sets',
        },
    };

export default EnterpriseComponentType;
