/**
 * Shallow compares an array.
 *
 * @example
 * const successObj = {success: true};
 * const arr1 = [1, 'hello', successObj];
 * const arr2 = [1, 'hello', successObj];
 * const arr3 = [1, 'hello', {success: true}];
 *
 * shallowArrayCompare(arr1, arr2); // Returns true
 * shallowArrayCompare(arr1, arr3); // Returns false, the success object has different reference
 *
 * @param a - first array to compare.
 * @param b - second array to compare.
 * @returns true if they shallow matching.
 */
function shallowArrayCompare<T extends any[]>(a: T, b: T): boolean {
    // If refs matching
    if (a === b) {
        return true;
    }

    if (a.length !== b.length) {
        return false;
    }

    return a.every((aField, index) => Object.is(aField, b[index]));
}

export default shallowArrayCompare;
