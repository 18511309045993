import type { BubbleDataPoint, ChartData, ChartOptions, ScatterDataPoint } from 'chart.js';
import { Chart, BarController, BarElement } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

import type ChartDisplayType from './ChartDisplayType';
import ChartEmptyState from './ChartEmptyState';

Chart.register(BarController, BarElement);

const LineChartContainer = styled.div<{ $height?: number }>`
    position: relative;
    height: ${({ $height }) => $height ?? 35}vh;
    max-width: 120vh;
    margin: 0 auto;
`;

interface Props {
    options?: ChartOptions<'bar'>;
    data: ChartData<'bar', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> | null | undefined;
    height?: number;
    chartType: ChartDisplayType;
}
const BarChart: React.FC<Props> = ({ options, data, height, chartType }) => {
    return (
        <LineChartContainer $height={height}>
            {data ? <Bar options={options} data={data} /> : <ChartEmptyState chartType={chartType} />}
        </LineChartContainer>
    );
};

export default BarChart;
