enum WorkerRunReason {
    RUN_SCHEDULED_AUTONOMOUS_TRIGGER_NOW = 'RUN_SCHEDULED_AUTONOMOUS_TRIGGER_NOW',
    AUTONOMOUS_TRIGGER_MATCH = 'AUTONOMOUS_TRIGGER_MATCH',
    NO_CHANGE_IN_AUTONOMOUS_TRIGGER = 'NO_CHANGE_IN_AUTONOMOUS_TRIGGER',
    CHANGE_BUT_NO_AUTONOMOUS_TRIGGERS_MATCHED = 'CHANGE_BUT_NO_AUTONOMOUS_TRIGGERS_MATCHED',
    FIELD_CHANGE_AUTONOMOUS_TRIGGER_MATCH = 'FIELD_CHANGE_AUTONOMOUS_TRIGGER_MATCH',
    SCHEDULED_AUTONOMOUS_TRIGGER = 'SCHEDULED_AUTONOMOUS_TRIGGER',
    MONITOR_FORM_TRIGGER_MATCH = 'MONITOR_FORM_TRIGGER_MATCH',
    INITIATIVE_CREATED_BY_FORM = 'INITIATIVE_CREATED_BY_FORM',
    CREATED_EXTERNAL_ACTIVITY_SYNC_MET = 'CREATED_EXTERNAL_ACTIVITY_SYNC_MET',
    UPDATED_EXTERNAL_ACTIVITY_SYNC_MET = 'UPDATED_EXTERNAL_ACTIVITY_SYNC_MET',
    CREATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET = 'CREATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET',
    UPDATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET = 'UPDATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET',
    CREATED_INITIATIVE_BY_SYNC = 'CREATED_INITIATIVE_BY_SYNC',
    MANUALLY_CREATED_INITIATIVE = 'MANUALLY_CREATED_INITIATIVE',
    SYNC_INITIATIVE_CREATION_FAILED = 'SYNC_INITIATIVE_CREATION_FAILED',
    INITIATIVE_CREATION_PREVENTED_DUPLICATE_TITLE = 'INITIATIVE_CREATION_PREVENTED_DUPLICATE_TITLE',
    INITIATIVE_CREATION_PREVENTED_EXTERNAL_ID = 'INITIATIVE_CREATION_PREVENTED_EXTERNAL_ID',
    INITIATIVE_UPDATED = 'INITIATIVE_UPDATED',
    AUTONOMOUS_TRIGGER_MATCH_BUT_ALREADY_MARKED = 'AUTONOMOUS_TRIGGER_MATCH_BUT_ALREADY_MARKED',
    NEW_MONITORED_THREAD_MESSAGE = 'NEW_MONITORED_THREAD_MESSAGE',
    NEW_BUTTON_CLICK = 'NEW_BUTTON_CLICK',
    NEW_UPDATE_FORM_ANSWERED = 'NEW_UPDATE_FORM_ANSWERED',
    CREATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION = 'CREATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION',
    UPDATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION = 'UPDATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION',
    TEST_MANUAL_INITIATIVE_CREATED = 'TEST_MANUAL_INITIATIVE_CREATED',
    INITIATIVE_UPDATED_EDIT_MODE = 'INITIATIVE_UPDATED_EDIT_MODE',
    INITIATIVE_CREATED_FROM_EMAIL_WEBHOOK = 'INITIATIVE_CREATED_FROM_EMAIL_WEBHOOK',
    INITIATIVE_CREATED_USING_TONKEAN_ACTION = 'INITIATIVE_CREATED_USING_TONKEAN_ACTION',
    INITIATIVE_CREATED_FROM_EXTERNAL = 'INITIATIVE_CREATED_FROM_EXTERNAL',
    AUTONOMOUS_TRIGGER_DOES_NOT_MATCH = 'AUTONOMOUS_TRIGGER_DOES_NOT_MATCH',
    INITIATIVE_CREATED_FROM_SUB_TRACKS_DIALOG = 'INITIATIVE_CREATED_FROM_SUB_TRACKS_DIALOG',
    INITIATIVE_CREATED_FROM_AT_MENTIONS_ACTIONS_DIALOG = 'INITIATIVE_CREATED_FROM_AT_MENTIONS_ACTIONS_DIALOG',
    INITIATIVE_CREATED_DUE_TO_RUN_SCHEDULED_WORKER_NOW = 'INITIATIVE_CREATED_DUE_TO_RUN_SCHEDULED_WORKER_NOW',
    INITIATIVE_CREATED_FROM_WORKER_INNER_ITEMS_CREATION = 'INITIATIVE_CREATED_FROM_WORKER_INNER_ITEMS_CREATION',
    INITIATIVE_FIELDS_UPDATED_IN_POPULATION = 'INITIATIVE_FIELDS_UPDATED_IN_POPULATION',
    EXTERNAL_ACTIVITY_SYNC_MET = 'EXTERNAL_ACTIVITY_SYNC_MET',
    EXTERNAL_ACTIVITY_SYNC_NOT_MET = 'EXTERNAL_ACTIVITY_SYNC_NOT_MET',
    EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION = 'EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION',
    INITIATIVE_UPDATED_COMMENT = 'INITIATIVE_UPDATED_COMMENT',
    INITIATIVE_UPDATED_RUN_ON_EXISTING_ITEM_AGAIN = 'INITIATIVE_UPDATED_RUN_ON_EXISTING_ITEM_AGAIN',
    INITIATIVE_UPDATED_MANUAL_ADMIN_POPULATION = 'INITIATIVE_UPDATED_MANUAL_ADMIN_POPULATION',
    INITIATIVE_UPDATED_BY_FORM_FROM_SLACK = 'INITIATIVE_UPDATED_BY_FORM_FROM_SLACK',
    INITIATIVE_UPDATED_BY_FORM_FROM_APP = 'INITIATIVE_UPDATED_BY_FORM_FROM_APP',
    INITIATIVE_UPDATED_INNER_INITIATIVE_UPDATED = 'INITIATIVE_UPDATED_INNER_INITIATIVE_UPDATED',
    INITIATIVE_UPDATED_FROM_SYNC = 'INITIATIVE_UPDATED_FROM_SYNC',
    INITIATIVE_CREATED_FROM_LOGIC = 'INITIATIVE_CREATED_FROM_LOGIC',
    INITIATIVE_UPDATED_MOVE_INITIATIVE = 'INITIATIVE_UPDATED_MOVE_INITIATIVE',
    INITIATIVE_CREATED_FROM_BOT = 'INITIATIVE_CREATED_FROM_BOT',
    INITIATIVE_UPDATED_DUE_DATE_FROM_BOT = 'INITIATIVE_UPDATED_DUE_DATE_FROM_BOT',
    INITIATIVE_UPDATE_FUNCTION = 'INITIATIVE_UPDATE_FUNCTION',
    INITIATIVE_UPDATE_DELETE_LINK = 'INITIATIVE_UPDATE_DELETE_LINK',
    INITIATIVE_UPDATE_CREATE_LINK = 'INITIATIVE_UPDATE_CREATE_LINK',
    INITIATIVE_CREATED_FROM_SYNC = 'INITIATIVE_CREATED_FROM_SYNC',
    INITIATIVE_UPDATED_INTEGRATION_FILTER = 'INITIATIVE_UPDATED_INTEGRATION_FILTER',
    INITIATIVE_UPDATED_FIELD_UPDATED = 'INITIATIVE_UPDATED_FIELD_UPDATED',
    INITIATIVE_UPDATED_FIELD_CREATED = 'INITIATIVE_UPDATED_FIELD_CREATED',
    INITIATIVE_UPDATED_TAGS = 'INITIATIVE_UPDATED_TAGS',
    INITIATIVE_UPDATED_DUE_DATE = 'INITIATIVE_UPDATED_DUE_DATE',
    INITIATIVE_UPDATED_TITLE = 'INITIATIVE_UPDATED_TITLE',
    INITIATIVE_UPDATED_DESCRIPTION = 'INITIATIVE_UPDATED_DESCRIPTION',
    INITIATIVE_UPDATED_ASSOCIATE_INITIATIVE = 'INITIATIVE_UPDATED_ASSOCIATE_INITIATIVE',
    INITIATIVE_UPDATED_ARCHIVED = 'INITIATIVE_UPDATED_ARCHIVED',
    INITIATIVE_UPDATED_UNARCHIVED = 'INITIATIVE_UPDATED_UNARCHIVED',
    INITIATIVE_UPDATED_ARCHIVED_DETACHED = 'INITIATIVE_UPDATED_ARCHIVED_DETACHED',
    INITIATIVE_UPDATED_OWNER = 'INITIATIVE_UPDATED_OWNER',
    INITIATIVE_UPDATED_STATE = 'INITIATIVE_UPDATED_STATE',
    AUTONOMOUS_TRIGGER_DISABLED = 'AUTONOMOUS_TRIGGER_DISABLED',
    TEST_EXTERNAL_INITIATIVE_CREATED = 'TEST_EXTERNAL_INITIATIVE_CREATED',
    FORM_FILLED_CREATED_INITIATIVE = 'FORM_FILLED_CREATED_INITIATIVE',
    RERUN_FAILED_CUSTOM_TRIGGERS = 'RERUN_FAILED_CUSTOM_TRIGGERS',
    RESUMED_DELAY_ACTION = 'RESUMED_DELAY_ACTION',
    DELETED_EXTERNAL_ACTIVITY = 'DELETED_EXTERNAL_ACTIVITY',
    INITIATIVE_UPDATED_ARCHIVED_BACKGROUND_PROCESS = 'INITIATIVE_UPDATED_ARCHIVED_BACKGROUND_PROCESS',
    USER_CLICKED_BUTTON_IN_INTERFACE = 'USER_CLICKED_BUTTON_IN_INTERFACE',
    MANUALLY_DUPLICATED_INITIATIVE = 'MANUALLY_DUPLICATED_INITIATIVE',
    ACTION_TRIGGERED_ON_INTERFACE_LOAD = 'ACTION_TRIGGERED_ON_INTERFACE_LOAD',

    /** @deprecated */
    UNKNOWN = 'UNKNOWN',
    /** @deprecated */
    RESUMED_WORKER = 'RESUMED_WORKER',
    /** @deprecated */
    PERSON_INQUIRY_RESUMED = 'PERSON_INQUIRY_RESUMED',
    /** @deprecated */
    ASK_FIELD_UPDATE_RESUMED = 'ASK_FIELD_UPDATE_RESUMED',
    /** @deprecated */
    RUN_SCHEDULED_WORKER_NOW = 'RUN_SCHEDULED_WORKER_NOW',
    /** @deprecated */
    TEST_RUN = 'TEST_RUN',
    /** @deprecated */
    NEW_CREATED_INITIATIVE = 'NEW_CREATED_INITIATIVE',
    /** @deprecated */
    ACTION_ITEMS_RESUMED = 'ACTION_ITEMS_RESUMED',
    /** @deprecated */
    NEW_CREATED_INNER_INITIATIVE = 'NEW_CREATED_INNER_INITIATIVE',
    /** @deprecated */
    CHANGE_BUT_NO_NEW_AUTONOMOUS_TRIGGERS_MATCHED = 'CHANGE_BUT_NO_NEW_AUTONOMOUS_TRIGGERS_MATCHED',
    /** @deprecated */
    SCHEDULED_WORKER = 'SCHEDULED_WORKER',
    /** @deprecated */
    FORM_ANSWERED = 'FORM_ANSWERED',
}

export default WorkerRunReason;
