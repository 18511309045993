import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import TextEllipsis from '../../TextEllipsis';
import { Placeholder } from '../Placeholder';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const TableWrapper = styled.div<{ firstColumnWidth: number }>`
    display: grid;
    grid-template-columns: ${(props) => props.firstColumnWidth}px auto;
`;

const CommonTableCell = styled.div<{ tableCellHeight: number }>`
    padding-left: 10px;

    height: ${(props) => `${props.tableCellHeight}px`};
    line-height: ${(props) => `${props.tableCellHeight}px`};
    vertical-align: middle;
    display: inline-flex;
`;

const HeaderTableCell = styled(CommonTableCell)`
    color: ${Theme.colors.gray_500};
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};

    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const TableCell = styled(CommonTableCell)`
    font-size: ${FontSize.SMALL_12};

    color: ${Theme.colors.gray_600};
    font-weight: 400;
    border-bottom: 1px solid ${Theme.colors.gray_300};
`;

const TableCellPlaceholder = styled(Placeholder)`
    margin: auto 0 auto 0;
`;

interface Props {
    keyValueRecord: Record<string, ReactNode>;
    keyColumnWidth: number;
    tableCellHeight?: number;
    keyHeaderName?: string;
    valueHeaderName?: string;

    isLoading?: boolean;
    loadingPlaceholderCount?: number;
}

const BasicKeyValueTable: React.FC<Props> = ({
    keyValueRecord,
    keyColumnWidth,

    tableCellHeight = 30,
    keyHeaderName = 'Key',
    valueHeaderName = 'Value',
    isLoading = false,
    loadingPlaceholderCount = 5,
}) => {
    const entriesOfKeyValueRecord = useMemo(() => Object.entries(keyValueRecord), [keyValueRecord]);

    return (
        <>
            <TableWrapper firstColumnWidth={keyColumnWidth}>
                <HeaderTableCell tableCellHeight={tableCellHeight}>{keyHeaderName}</HeaderTableCell>
                <HeaderTableCell tableCellHeight={tableCellHeight}>{valueHeaderName}</HeaderTableCell>

                {isLoading &&
                    range(loadingPlaceholderCount).map((i) => (
                        <React.Fragment key={i}>
                            <TableCell tableCellHeight={tableCellHeight}>
                                <TableCellPlaceholder $height={`calc(${tableCellHeight}px / 3)`} $width="90%" />
                            </TableCell>
                            <TableCell tableCellHeight={tableCellHeight}>
                                <TableCellPlaceholder $height={`calc(${tableCellHeight}px / 3)`} $width="90%" />
                            </TableCell>
                        </React.Fragment>
                    ))}

                {keyValueRecord &&
                    !isLoading &&
                    entriesOfKeyValueRecord.map(([key, value]) => {
                        return (
                            <React.Fragment key={key + value?.toString()}>
                                <TableCell tableCellHeight={tableCellHeight}>
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {key}
                                    </TextEllipsis>
                                </TableCell>

                                {typeof value === 'string' ? (
                                    <TableCell tableCellHeight={tableCellHeight}>
                                        <TextEllipsis numberOfLines={1} tooltip>
                                            {value}
                                        </TextEllipsis>
                                    </TableCell>
                                ) : (
                                    <TableCell tableCellHeight={tableCellHeight}>{value}</TableCell>
                                )}
                            </React.Fragment>
                        );
                    })}
            </TableWrapper>
        </>
    );
};
export default BasicKeyValueTable;
