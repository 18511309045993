import styled, { css } from 'styled-components';

import type { Color } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

/**
 * A generic styled icon. With baseline configurations
 * Should be use like:
 *
 * import { ReactComponent as TestIcon } from '../images/icons/test.svg';
 * <IconSvg as={TestIcon} size={36} />;
 */
const IconSvg = styled.svg<{ size?: number; color?: Color }>`
    ${({ size }) =>
        size &&
        css`
            width: ${size}px;
            height: ${size}px;
        `}

    ${({ color }) => color && colorSvg(color)}
`;

export default IconSvg;
