import styled from 'styled-components';

const ModalBody = styled.div<{ removePadding?: boolean }>`
    position: relative;
    z-index: 0;
    padding: ${({ removePadding }) => (removePadding ? '0' : '20px')};
    flex: 1 1 auto;
    overflow: auto;
`;

ModalBody.displayName = 'ModalBody';

export default ModalBody;
