import React, { useState } from 'react';
import styled from 'styled-components';

import KebabMenuButton from '../../KebabMenuButton';
import XCloseButton from '../CloseButton/XCloseButton';
import Menu from '../Menu/Menu';

import { ButtonSize } from '@tonkean/tui-theme/sizes';

const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
`;

const StyledKebabMenuButton = styled(KebabMenuButton)`
    margin-right: 14px;
`;

interface Props {
    menuItems?: React.ReactElement;
}

const ModalHeaderActionsButtons: React.FC<React.PropsWithChildren<Props>> = ({ menuItems, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <HeaderButtons>
            {menuItems && (
                <Menu show={isOpen} menuItems={menuItems} onClose={() => setIsOpen(false)}>
                    <StyledKebabMenuButton onClick={() => setIsOpen(true)} pressed={isOpen} horizontal flat />
                </Menu>
            )}
            {children}
            <XCloseButton size={ButtonSize.MEDIUM} />
        </HeaderButtons>
    );
};

export default ModalHeaderActionsButtons;
