import React from 'react';
import { components } from 'react-select';
import type { OptionProps } from 'react-select';
import type { GroupBase } from 'react-select';
import styled from 'styled-components';

import TnkIconSelection from './TnkIconSelection';

import { Theme } from '@tonkean/tui-theme';

const Description = styled.div`
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    color: ${Theme.colors.gray_600};
`;

const CustomOptionSelectionWithIcon = <
    Option = unknown,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: OptionProps<Option, IsMulti, Group>) => {
    const { label, icon, description } = props.data as {
        label?: string;
        icon?: React.ReactElement;
        description?: string;
    };

    const innerProps = {
        ...props.innerProps,
        'data-automation': 'tnk-select-option',
        'data-automation-label': label,
    };

    return (
        <components.Option {...props} innerProps={innerProps}>
            <TnkIconSelection icon={icon}>
                {children}
                {description && <Description>{description}</Description>}
            </TnkIconSelection>
        </components.Option>
    );
};

export default CustomOptionSelectionWithIcon;
