enum ItemsHierarchyFilter {
    GROUP_ITEMS = 'GROUP_ITEMS',
    INNER_ITEMS = 'INNER_ITEMS',
    ROOT_ITEMS = 'ROOT_ITEMS',
    SINGLE_ITEM = 'SINGLE_ITEM',
}

export default ItemsHierarchyFilter;

export const ItemsHierarchyFilterToLabels: Record<ItemsHierarchyFilter, string> = {
    GROUP_ITEMS: 'All module items',
    INNER_ITEMS: 'Only inner items',
    ROOT_ITEMS: 'Only root items',
    SINGLE_ITEM: 'Root monitored item',
};
