/**
 * Function to create an array to iterate on, same as range in python.
 *
 * @param max - the maximum number, exclusive.
 * @returns a numbers array
 */
function range(max: number): number[];
/**
 * Function to create an array to iterate on, same as range in python.
 *
 * @param min - the minimum number, inclusive.
 * @param max - the maximum number, exclusive.
 * @returns a numbers array
 */
function range(min: number, max: number): number[];
/**
 * Function to create an array to iterate on, same as range in python.
 *
 * @param min - the minimum number, inclusive.
 * @param max - the maximum number, exclusive.
 * @param skips - the number to skip in each 'jump'.
 * @returns a numbers array
 */
function range(min: number, max: number, skips: number): number[];
function range(min: number, max?: number, skips: number = 1): number[] {
    const array: number[] = [];

    const maxIsUndefined = max !== undefined;
    const from = maxIsUndefined ? min : 0;
    const to = maxIsUndefined ? max : min;

    for (let i = from; i < to; i += skips) {
        array.push(i);
    }

    return array;
}

export default range;
