import type { ForwardedRef } from 'react';
import React from 'react';

import RadioIcon from './RadioIcon';
import useRadio from './useRadio';
import IconInputWithLabel from '../IconInputWithLabel/IconInputWithLabel';

import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface Props extends DataAutomationSupportProps, StyledComponentsSupportProps {
    /** Is this radio disabled? */
    disabled?: boolean;

    /**
     * The value of the radio. It is not the value that will be passed to the input, because the input only supports
     * strings. It will generate a random fake value, and will return the value supplied in the onChange.
     */
    value: any;

    /**
     * A ref for the label to show a tooltip
     */
    tooltipRef?: ForwardedRef<HTMLLabelElement>;
}

const Radio: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<Props>> = (
    { className, dataAutomation, value, disabled = false, tooltipRef, children },
    ref,
) => {
    const {
        props,
        onChange,
        radioGroupDisabled,
        fakeValue,
        changeLabelColor,
        size,
        selectedColor,
        unselectedColor,
        boldLabel,
    } = useRadio(value);

    return (
        <IconInputWithLabel
            type="radio"
            disabled={disabled || radioGroupDisabled}
            ref={ref}
            tooltipRef={tooltipRef}
            changeLabelColor={changeLabelColor}
            className={className}
            size={size}
            boldLabel={boldLabel}
            icon={
                <RadioIcon
                    $disabled={disabled || radioGroupDisabled}
                    $checked={props.checked}
                    $size={size}
                    $selectedColor={selectedColor}
                    $unselectedColor={unselectedColor}
                />
            }
            labelAfter={children}
            dataAutomation={dataAutomation}
            data-automation-checked={props.checked}
            {...props}
            value={fakeValue}
            onChange={onChange}
        />
    );
};

export default React.forwardRef(Radio);
