import React, { useMemo } from 'react';
import styled from 'styled-components';

import ProjectIntegrationIcon from './ProjectIntegrationIcon';
import { Tooltip } from './TUI';

import {
    CalculatorIcon,
    CalendarIcon,
    ChevronCircleIcon,
    CreateFormIcon,
    EmptyBlockIcon,
    HashtagIcon,
    InterfaceIcon,
    ItemDetailsIcon,
    ProcessMapperIcon,
    TextIcon,
    UpdateFormIcon,
    VersionsIcon,
} from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { classNames } from '@tonkean/utils';

const fieldTypeToIcon = {
    NUMBER: <HashtagIcon />,
    DATE: <CalendarIcon />,
    LIST: <ChevronCircleIcon />,
    FORMULA: <CalculatorIcon />,
};

const formTypeToIcon = {
    CREATE: <CreateFormIcon />,
    UPDATE: <UpdateFormIcon />,
};
const UNKNOWN_ACTION_IDENTIFIER = 'UNKNOWN';

function getLogicIcon(
    fieldType?: string,
    logicBlockType?: any,
    definitionSource?: string,
    formType?: string,
    revert?: boolean,
    itemInterface?: boolean,
    itemDetails?: boolean,
    processMapper?: boolean,
) {
    if (fieldType) {
        return getFieldIconClass(fieldType, definitionSource);
    }

    if (logicBlockType) {
        return getCustomTriggerIconClass(logicBlockType?.secondaryType, logicBlockType?.iconClass);
    }

    if (formType) {
        return formTypeToIcon[formType];
    }

    if (revert) {
        return <VersionsIcon />;
    }

    if (itemInterface) {
        return <InterfaceIcon />;
    }

    if (itemDetails) {
        return <ItemDetailsIcon />;
    }

    if (processMapper) {
        return <ProcessMapperIcon />;
    }

    return UNKNOWN_ACTION_IDENTIFIER;
}

function getFieldIconClass(fieldType?: string, definitionSource?: string) {
    if (definitionSource === 'MANUAL') {
        const iconClass = fieldType ? fieldTypeToIcon[fieldType.toUpperCase()] : undefined;
        return iconClass ? iconClass : <TextIcon />;
    }

    return UNKNOWN_ACTION_IDENTIFIER;
}

function getCustomTriggerIconClass(secondaryType?: string, blockIconClass?: string): string {
    if (secondaryType) {
        let iconClass: string = '';
        switch (secondaryType) {
            case 'AUTONOMOUS_ITEM_CREATED':
                iconClass = 'fa fa-flag fa-lg';
                break;
            case 'MONITOR_TRACKS_ITEM_CREATED':
                iconClass = 'fa fa-flag fa-lg';
                break;
            case 'AUTONOMOUS_SCHEDULE':
                iconClass = 'fa fa-clock-o fa-lg';
                break;
            case 'AUTONOMOUS_FIELD_CHANGED':
                iconClass = 'fa fa-eye fa-lg';
                break;
            case 'AUTONOMOUS_CREATED_FROM_FORM':
                iconClass = 'mod-send-form';
                break;
            case 'AUTONOMOUS_INTERFACE_SUBMITTED':
                iconClass = 'mod-item-interface';
                break;
            case 'AUTONOMOUS_MATCH_CONDITIONS':
                iconClass = 'mod-condition';
                break;
            case 'MONITOR_TRACKS_MATCH_CONDITIONS':
                iconClass = 'mod-condition';
                break;
            case 'USER_INTERFACE_BUTTON_CLICK':
                iconClass = 'mod-button-click';
                break;
            case 'AUTO_CLOSE':
                iconClass = 'fa fa-lock fa-lg';
                break;
        }

        return classNames('logic-template-icon', iconClass);
    }

    if (blockIconClass) {
        return classNames('logic-template-icon', blockIconClass);
    }

    return UNKNOWN_ACTION_IDENTIFIER;
}

const LogicIconSvg = styled.div<{
    size: number;
}>`
    flex-shrink: 0;

    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};

    // Reason for !important here is that we take the logic icon class as its defined in _workerLogic.scss, and the default there is 30px for trigger icons.
    // We could have used the old method of "mod-xxs", "mod-xs", etc, but here we'd like to have dynamic size that is given by the user of the component,
    // so we can't hard-code the size into a mode. Therefore, we must handle the background size being overriden to 30px by default, and that is done
    // by setting to contain with !important.
    background-size: contain !important;

    .tnk-icon svg {
        width: 14px;
        height: 14px;
    }
`;

const LogicIconStyled = styled.div<{
    size: number;
}>`
    display: flex;
    align-items: center;
    flex-shrink: 0;

    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};

    // Reason for !important here is that we take the logic icon class as its defined in _workerLogic.scss, and the default there is 30px for trigger icons.
    // We could have used the old method of "mod-xxs", "mod-xs", etc, but here we'd like to have dynamic size that is given by the user of the component,
    // so we can't hard-code the size into a mode. Therefore, we must handle the background size being overriden to 30px by default, and that is done
    // by setting to contain with !important.
    background-size: contain !important;

    color: ${Theme.colors.gray_500};
`;

export interface LogicIconProps extends StyledComponentsSupportProps {
    integrationType?: string;
    logicBlockType?: any;
    definitionSource?: string;
    fieldType?: string;
    formType?: string;
    revert?: boolean;
    projectIntegrationId?: string;
    size?: number;
    disableTooltip?: boolean;
    itemInterface?: boolean;
    itemDetails?: boolean;
    processMapper?: boolean;
    iconUrl?: string;
}

const LogicIcon: React.FC<LogicIconProps> = ({
    integrationType,
    logicBlockType,
    definitionSource,
    fieldType,
    formType,
    revert,
    className,
    projectIntegrationId,
    size = 14,
    disableTooltip = false,
    itemInterface,
    itemDetails,
    processMapper,
    iconUrl,
}) => {
    const iconClass = useMemo(
        () =>
            getLogicIcon(
                fieldType,
                logicBlockType,
                definitionSource,
                formType,
                revert,
                itemInterface,
                itemDetails,
                processMapper,
            ),
        [fieldType, logicBlockType, definitionSource, formType, revert, itemInterface, itemDetails, processMapper],
    );
    const isProjectIntegrationIcon = projectIntegrationId || integrationType;
    const iconUnknown = iconClass === UNKNOWN_ACTION_IDENTIFIER && !isProjectIntegrationIcon;
    return (
        <>
            {iconUnknown ? (
                <LogicIconSvg className={className} size={size}>
                    <span className="tnk-icon">
                        <EmptyBlockIcon />
                    </span>
                </LogicIconSvg>
            ) : isProjectIntegrationIcon ? (
                <ProjectIntegrationIcon
                    projectIntegrationId={projectIntegrationId}
                    integrationType={integrationType}
                    width={size}
                    height={size}
                    tooltipContent={logicBlockType?.title}
                    className={className}
                    iconUrl={iconUrl}
                />
            ) : logicBlockType ? (
                <Tooltip placement="top" content={<>{logicBlockType.title}</>} disabled={disableTooltip}>
                    <LogicIconStyled className={classNames(iconClass, className)} size={size} />
                </Tooltip>
            ) : (
                <LogicIconSvg className={className} size={size}>
                    <span className="tnk-icon">{iconClass}</span>
                </LogicIconSvg>
            )}
        </>
    );
};

export default LogicIcon;
