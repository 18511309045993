import 'react-responsive-carousel/lib/styles/carousel.min.css';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import 'wicg-inert';
import type { AppProps } from 'next/app';
import { resolveHref } from 'next/dist/shared/lib/router/router';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createGlobalStyle } from 'styled-components';

import LibraryHead from '../components/LibraryHead';

import { analyticsWrapper } from '@tonkean/analytics';
import { ReactRoot, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { TonkeanRouter } from '@tonkean/tui-basic/RouterLink';
import { Theme } from '@tonkean/tui-theme';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button, a {
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }

  html {
    background: ${Theme.colors.basicBackground};
  }
`;

const cache = createCache({ key: 'next' });

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const analyticsEventNameAttribute = 'data-analytics-event';

    useEffect(() => {
        const analyticsEvents = [...document.querySelectorAll(`[${analyticsEventNameAttribute}]`)] as HTMLElement[];

        const removeFunctions = analyticsEvents.map((elem) => {
            const onClick = () => {
                analyticsWrapper.track(elem.getAttribute(analyticsEventNameAttribute)!);
            };
            elem.addEventListener('click', onClick);
            return () => elem.removeEventListener('click', onClick);
        });

        const onPageView = (url: string) => {
            (window as any).dataLayer.push({
                event: 'pageview',
                page: url,
            });

            const params = router.query;
            analyticsWrapper.track('library-pageview', {
                url,
                params,
            });
        };

        router.events.on('routeChangeComplete', onPageView);
        return () => {
            router.events.off('routeChangeComplete', onPageView);
            removeFunctions.map((removeFunction) => removeFunction());
        };
    }, [router.events, router.query]);

    const getHref = (path: string | undefined, query: Record<string, string> | undefined) => {
        const [href, as] = resolveHref(
            router,
            {
                pathname: !path || path === '.' ? router.pathname : path,
                query,
            },
            true,
        ) as unknown as [string, string];

        return { href, as };
    };

    const tonkeanRouter: TonkeanRouter = {
        getHref: (path, query) => {
            return getHref(path, query).as;
        },
        go: (path, query = {}, replace, options = {}) => {
            const { href, as } = getHref(path, query);

            if (replace) {
                router.replace(href, as, options);
            } else {
                router.push(href, as, options);
            }
        },
        prefetch(path, query = {}) {
            const { href, as } = getHref(path, query);

            return router.prefetch(href, as).catch((error) => {
                if (process.env.NODE_ENV !== 'production') {
                    // rethrow to show invalid URL errors
                    throw error;
                }
            });
        },
    };

    const fallbackComponent = useCallback(({ error }) => <SimpleErrorStateMessage error={error} showSmallError />, []);

    return (
        <ReactRoot optimizedImage={Image} router={tonkeanRouter}>
            <CacheProvider value={cache}>
                <Script
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                              })(window,document,'script','dataLayer','GTM-W5CWLPZ');`,
                    }}
                />
                <GlobalStyles />
                <LibraryHead content={[]} disableMetaTags />

                <ErrorBoundary
                    fallbackRender={fallbackComponent}
                    onError={(error, info) => {
                        console.log(error);
                        console.log(info);
                    }}
                >
                    <Component {...pageProps} />
                </ErrorBoundary>
            </CacheProvider>
        </ReactRoot>
    );
}

export default MyApp;
