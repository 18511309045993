import type SolutionBusinessReportGroupFilters from './SolutionBusinessReportGroupFilters';

export enum SolutionBusinessReportSavedFilterType {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}
export interface SolutionBusinessReportSavedFilter {
    id: string;
    displayName: string;
    projectId: string;
    solutionBusinessReportId: string;
    groupId: string;
    creatorId: string;
    created: number;
    type: SolutionBusinessReportSavedFilterType;
    definition: SolutionBusinessReportGroupFilters;
}
