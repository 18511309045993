import React, { useContext, useEffect } from 'react';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

export const OnBeforeCloseContext = React.createContext<
    React.Dispatch<React.SetStateAction<(() => boolean | Promise<boolean>)[]>>
>(undefined as any);

/**
 * Hook for adding functions to decide whether to stay or close the modal.
 *
 */
function useAddOnBeforeClose(onBeforeClose: () => boolean | Promise<boolean>): void {
    const setOnBeforeClose = useContext(OnBeforeCloseContext);

    const onBeforeCloseConstantRef = useConstantRefCallback(onBeforeClose);

    useEffect(() => {
        const onBeforeCloseFromRef = () => onBeforeCloseConstantRef();

        setOnBeforeClose((currentOnBeforeCloseList) => [...currentOnBeforeCloseList, onBeforeCloseFromRef]);

        return () =>
            setOnBeforeClose((currentOnBeforeCloseList) =>
                currentOnBeforeCloseList.filter(
                    (onBeforeCloseFunction) => onBeforeCloseFunction !== onBeforeCloseFromRef,
                ),
            );
    }, [onBeforeCloseConstantRef, setOnBeforeClose]);
}

export default useAddOnBeforeClose;
